import React from "react";
import Select from "react-select";

function AtlasSelect(props) {
  const { onChange, options, value, placeholder } = props;
  const maxWidth = props.width ? props.width : "290px";
  const minWidth = props.width ? props.width : "290px";
  return (
    <Select
      unstyled
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      value={value}
      isSearchable={false}
      styles={{
        container: (baseStyle, state) => ({
          ...baseStyle,
          color: "white",
          maxWidth: maxWidth,
          minWidth: minWidth,
        }),
        control: (baseStyle, state) => ({
          ...baseStyle,
          border: "1px solid #1C3A4A",
          borderRadius: "15px",
          padding: "0 15px",
          cursor: "pointer",
        }),
        indicatorsContainer: (baseStyle, state) => ({
          ...baseStyle,
          color: "#8D9CA6",
          marginLeft: "10px",
        }),
        menu: (baseStyle, state) => ({
          ...baseStyle,
          background: "#112936",
          border: "1px solid #1C3A4A",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.45)",
          borderRadius: "14px",
          width: "max-content",
          minWidth: "100%",
          padding: "15px",
        }),
        singleValue: (baseStyle, state) => ({
          ...baseStyle,
          padding: "5px",
          margin: "0 10px",
        }),
        option: (baseStyle, state) => ({
          ...baseStyle,
          margin: "10px 0",
          fontWeight: state.isFocused ? "bold" : "400",
          cursor: "pointer",
        }),
      }}
      classNames={{
        menuList: (state) => "hs-scrollable-container",
      }}
    />
  );
}

export default AtlasSelect;
