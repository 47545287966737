import { config } from "../config";
import { UserManager as ClientUserManager } from "oidc-client";

export const HS_AUTH_CONFIG = {
  authority: config.AUTHORITY,
  client_id: config.CLIENT_ID,
  redirect_uri:
    config.BASE_URL +
    (config.APP == "healthsocial" ? "/hs/callback" : "/pollverse/callback"),
  response_type: config.RESPONSE_TYPE,
  scope: config.SCOPE,
  post_logout_redirect_uri:
    config.BASE_URL + (config.APP == "healthsocial" ? "/hs" : "/pollverse"),
};

var mgr: ClientUserManager = new ClientUserManager(HS_AUTH_CONFIG);
export const UserManager: ClientUserManager = mgr;

export const CHANGE_PASSWORD_ROUTE = config.CHANGE_PASSWORD_ROUTE || "";
