import { connect } from "react-redux";
import { updateFilters } from "redux/actions";
import FiltersBar from "./FiltersBar";

const mapStateToProps = (state: AtlasMach.StoreState, ownProps) => {
  if (!state.ui.topic)
    throw new Error("Can't use FiltersBar Component with uninitialized topic");
  if (!state.ui.filters)
    throw new Error(
      "Can't use FiltersBar Component with uninitialized filters"
    );

  console.log("data.filter", ownProps.filters);

  return {
    topic: state.ui.topic,
    filters: ownProps.filters ? ownProps.filters : state.ui.filters,
    dataSources: state.data.sources,
    dataAudiences: state.data.audiences,
    dataThemes: state.data.themes,
    dataBrands: state.data.brands,
    dataBrandAccounts: state.data.brandAccounts,
  };
};

const mapDispatchToProps = {
  updateFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersBar);
