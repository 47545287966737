import React from "react";

import { config } from "../../config";

import backgroundImage from "assets/images/normal-back.png";

import { Helmet } from "react-helmet";

import "./styles.scss";

// The BasePage shared by all page on HS.
function BasePage({ children }) {
  return (
    <>
      <Helmet>
        <title>{config.BRANDING.TITLE}</title>
        <link rel="icon" href={config.BRANDING.FAVICON} />
      </Helmet>
      <div
        className="hs-page-container"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {children}
      </div>
    </>
  );
}

export default BasePage;
