import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setIsLoading, setAudienceAnalysis } from "redux/actions";

import PageTemplate from "pages/PageTemplate";
import DOLTabbedPageContent from "./DOLTabbedPageContent";
import FiltersBar from "components/FiltersBar";
import AtlasTable from "components/AtlasTable";
import ModuleNotAvailable from "components/ModuleNotAvailable";

import AudienceAnalysis from "./AudienceAnalysis";
import AccountPopup from "./AccountPopup";

import SvgInfo from "assets/images/icons/icon-info.svg";

import { config } from "../../config.js";

import { ReactComponent as VeryLikelyIcon } from "assets/icons/engagement_very_likely.svg";
import { ReactComponent as LikelyIcon } from "assets/icons/engagement_likely.svg";
import { ReactComponent as NeutralIcon } from "assets/icons/engagement_neutral.svg";
import { ReactComponent as UnlikelyIcon } from "assets/icons/engagement_unlikely.svg";
import { ReactComponent as VeryUnlikelyIcon } from "assets/icons/engagement_very_unlikely.svg";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";

import {
  API_DOL_PATHWAYS,
  API_DOL_PATHWAYS_HSREC,
  API_STAR_PROFILE,
} from "constants/routes";

interface IPathwaysScreenProps {
  setIsLoading: (loading: boolean) => void;
  client: AtlasMach.IClient;
  topic: AtlasMach.ITopic;
  dateRange: AtlasMach.IDateRange;
  starAccounts: AtlasMach.IStarredAccount[];
  filters: AtlasMach.UIFilters;
  authToken: string;
  audienceAnalysisData: AtlasMach.IAASection;
  setAudienceAnalysis: (aa: AtlasMach.IAASection) => void;
  dataAudiences: AtlasMach.IAudience[];
}

function PathwaysScreen({
  setIsLoading,
  starAccounts,
  client,
  topic,
  filters,
  dateRange,
  authToken,
  audienceAnalysisData,
  setAudienceAnalysis,
  dataAudiences,
}: IPathwaysScreenProps) {
  const [pathData, setPathData] = useState<{
    [key: string]: AtlasMach.IPathways[];
  }>({
    All: [],
    "HS Rec": [],
    "Quick Wins": [],
    "Top 25": [],
  });

  const [activeTab, setActiveTab] = useState<string>("All");

  const [popupPathway, setPopupPathway] = useState<
    AtlasMach.IPathways | undefined
  >(undefined);

  const [data, setData] = useState<{ id: string }[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [orderBy, setOrderBy] = useState<{ column: string; desc: boolean }>({
    column: "Centricity Score",
    desc: true,
  });

  const [starredAccounts, setStarredAccounts] = useState<string[]>([]);

  const loadStarredAccounts = () => {
    const apiUrl = API_STAR_PROFILE.replace("$1", topic.id).replace(
      "$2",
      filters.source.id
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          alert("Failed to load the saved answers due to an unexpected error");
        }
        return res.json();
      })
      .then((jsonData) => {
        setStarredAccounts(jsonData.map((x) => x.accountId));
      });
  };

  useEffect(() => {
    loadStarredAccounts();
  }, [authToken]);

  const highlightMessage = (msg: string) => {
    const msgLower = msg.toLowerCase();
    return client.clientBrands?.some((cb) => {
      // TODO: we should limit this to the on-topic brands.
      return cb.keywords.some((key) => msgLower.includes(key.toLowerCase()));
    });
  };

  const handleExport = (setCsvData, setDownloadName, csvLink) => {
    const csv: string[][] = [
      [
        "Account",
        "Audience",
        "Centricity Score",
        "Engagement Likelihood",
        "Category",
      ],
    ];
    const exportData = { ...pathData, Starred: starAccounts };
    //for(let category of ['HS Rec', 'Quick Wins', 'Top 25', 'Starred']) {
    for (let category of ["HS Rec", "Quick Wins", "Top 25"]) {
      for (let row of exportData[category]) {
        if (category === "Starred") {
          csv.push([
            String(row.account.accountName),
            "",
            String(`${row.score.toFixed(2)}%`),
            "",
            category,
          ]);
        } else {
          csv.push([
            String(row.account),
            String(row.audience),
            String(`${row.score.toFixed(2)}%`),
            engagementToLabel(row.likelihood),
            category,
          ]);
        }
      }
    }
    setCsvData(csv);
    const fileName = `Atlas[${topic.name}][${filters.source.name}]_Pathways`;
    setDownloadName(fileName);
    setTimeout(() => {
      csvLink.current.link.click();
    }, 500);
  };

  const updateTableData = () => {
    var newData = [...pathData[activeTab]];

    // Sort is needed for top 25 because we override the sort parameter in order to get the top25 by centricity, but we
    // then still want to sort by the selected column, within that 25 results.
    if (activeTab == "Top 25")
      newData.sort((x, y) => {
        var res = 0;
        if (orderBy.column == "Account")
          res = x.account < y.account ? -1 : x.account > y.account ? 1 : 0;
        if (orderBy.column == "Centricity Score") res = x.score - y.score;
        if (orderBy.column == "Engagement Likelihood")
          res = x.likelihood - y.likelihood;

        if (orderBy.desc) return -res;
        else return res;
      });
    setData(
      newData
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        .map((x) => ({ ...x, id: x.account }))
    );
  };

  useEffect(() => {
    setCurrentPage(1);
    setTotalPages(Math.ceil((pathData[activeTab].length * 1.0) / itemsPerPage));
  }, [pathData, itemsPerPage, filters, activeTab]);

  useEffect(() => {
    updateTableData();
  }, [pathData, currentPage, itemsPerPage, orderBy, filters, activeTab]);

  const engagementToLabel = (v: number) => {
    if (v == 5) return "Very Unlikely";
    if (v == 4) return "Unlikely";
    if (v == 3) return "Neutral";
    if (v == 2) return "Likely";
    if (v == 1) return "Very Likely";
    return "";
  };
  const engagementToIcon = (v: number) => {
    if (v == 5) return <VeryUnlikelyIcon />;
    if (v == 4) return <UnlikelyIcon />;
    if (v == 3) return <NeutralIcon />;
    if (v == 2) return <LikelyIcon />;
    if (v == 1) return <VeryLikelyIcon />;
    return <div />;
  };

  const columns = [
    {
      name: "Account",
      selector: (x) => (
        <span onClick={() => setPopupPathway(x)} style={{ cursor: "pointer" }}>
          {x.account}{" "}
          {starredAccounts.includes(x.accountId) ? (
            <StarIcon fill="#FFD74A" />
          ) : undefined}
        </span>
      ),
      sortable: false,
    },
    { name: "Audience", selector: (x) => x.audience, sortable: false },
    {
      name: "Centricity Score",
      selector: (x) => x.score.toFixed(2) + "%",
      sortable: true,
    },
    {
      name: "Engagement Likelihood",
      selector: (x) => (
        <div>
          <span
            style={{ marginRight: "10px", position: "relative", top: "5px" }}
          >
            {engagementToIcon(x.likelihood)}
          </span>
          {engagementToLabel(x.likelihood)}
        </div>
      ),
      sortable: true,
    },
  ];

  const buildUrl = (
    baseUrl,
    source,
    orderByColumn,
    orderByDesc,
    audiences,
    engagementsLikelihood
  ) => {
    var url =
      baseUrl.replace("$1", topic.id) +
      `?sourceId=${source.id}&orderBy=${orderByColumn}&orderByDesc=${orderByDesc}`;
    audiences.forEach((a) => {
      url += `&audienceFilters=${a.id}`;
    });
    engagementsLikelihood.forEach((eng) => {
      url += `&engagementLikelihoodFilters=${eng}`;
    });
    return url;
  };

  useEffect(() => {
    setIsLoading(true);

    var orderByColumn = "Centricity";
    if (orderBy.column == "Account") orderByColumn = "Account";
    else if (orderBy.column == "Centricity Score") orderByColumn = "Centricity";
    else if (orderBy.column == "Engagement Likelihood")
      orderByColumn = "EngagementLikelihood";

    let endPoints = [
      {
        tab: "All",
        endpoint: buildUrl(
          API_DOL_PATHWAYS,
          filters.source,
          orderByColumn,
          orderBy.desc,
          filters.audiences,
          filters.engagementsLikelihood
        ),
      },
      {
        tab: "HS Rec",
        endpoint: buildUrl(
          API_DOL_PATHWAYS_HSREC,
          filters.source,
          orderByColumn,
          orderBy.desc,
          filters.audiences,
          filters.engagementsLikelihood
        ),
      },
      {
        // The quickwins endpoint is basically a default filter of 'very likely' and 'likely' (1 and 2) for
        // engagement likelihood. We have to handle the case where the filters are set for disjoint filters,
        // in which case we skip the query (undefined endpoint) as we know there won't be any result.
        tab: "Quick Wins",
        endpoint:
          filters.engagementsLikelihood.length > 0 &&
          filters.engagementsLikelihood.every((x) => x != 1 && x != 2)
            ? undefined
            : buildUrl(
                API_DOL_PATHWAYS,
                filters.source,
                orderByColumn,
                orderBy.desc,
                filters.audiences,
                filters.engagementsLikelihood.length == 0
                  ? [1, 2]
                  : filters.engagementsLikelihood.filter(
                      (x) => x == 1 || x == 2
                    )
              ),
      },
      {
        tab: "Top 25",
        // The top 25 query is the 25 accounts with the highest centricity under the current
        // filters. This can be done by forcing these filters and then applying the local order
        // on the result.
        endpoint:
          buildUrl(
            API_DOL_PATHWAYS,
            filters.source,
            "Centricity",
            true,
            filters.audiences,
            filters.engagementsLikelihood
          ) + "&limit=25",
      },
    ];
    let promises = endPoints.map((ep) => {
      if (!ep.endpoint)
        return setPathData((prev) => {
          let updated = { ...prev };
          updated[ep.tab] = [];
          return updated;
        });

      return fetch(ep.endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((res) => res.json())
        .then((res) =>
          setPathData((prev) => {
            let updated = { ...prev };
            updated[ep.tab] = res.map((x) => ({ ...x, id: x.account }));
            return updated;
          })
        );
    });
    Promise.all(promises).then(() => setIsLoading(false));
  }, [topic, filters, orderBy]);

  var popupColor: string | undefined = undefined;
  if (popupPathway) {
    let audience = dataAudiences.find((a) => a.name == popupPathway.audience);
    popupColor = audience ? audience.color : "rgb(173, 216, 230)";
  }

  // Currently instagram has anonymous users so we cannot support this module.
  const isModuleAvailable = filters.source.name != "Instagram";

  return (
    <PageTemplate title="Digital Opinion Leaders" onExport={handleExport}>
      <DOLTabbedPageContent tab="pathways">
        <div className="pathways-screen-container">
          {audienceAnalysisData.name !== "" && (
            <AudienceAnalysis
              onClose={() =>
                setAudienceAnalysis({
                  name: "",
                  profileImage: "",
                  Sections: [],
                })
              }
            />
          )}
          <FiltersBar
            disableBrands={true}
            disableThemes={true}
            disableSentiments={true}
            enableEngagementsLikelihood={true}
          />
          {!isModuleAvailable && <ModuleNotAvailable />}
          {isModuleAvailable && (
            <>
              <div className="pathways-tabs">
                <div
                  className={
                    "pathways-tab" + (activeTab == "All" ? " active" : "")
                  }
                  onClick={() => setActiveTab("All")}
                >
                  All{" "}
                  <span className="pathways-tab-total-accounts">
                    {pathData["All"].length}
                  </span>
                </div>
                <div
                  className={
                    "pathways-tab" + (activeTab == "HS Rec" ? " active" : "")
                  }
                  onClick={() => setActiveTab("HS Rec")}
                >
                  {config.BRANDING.PATHWAYS_REC}{" "}
                  <span className="pathways-tab-total-accounts">
                    {pathData["HS Rec"].length}
                  </span>
                </div>
                <div
                  className={
                    "pathways-tab" +
                    (activeTab == "Quick Wins" ? " active" : "")
                  }
                  onClick={() => setActiveTab("Quick Wins")}
                >
                  Quick Wins{" "}
                  <span className="pathways-tab-total-accounts">
                    {pathData["Quick Wins"].length}
                  </span>
                </div>
                <div
                  className={
                    "pathways-tab" + (activeTab == "Top 25" ? " active" : "")
                  }
                  onClick={() => setActiveTab("Top 25")}
                >
                  Top 25{" "}
                  <span className="pathways-tab-total-accounts">
                    {pathData["Top 25"].length}
                  </span>
                </div>

                {<div className={"pathways-client-label"}>{client.name}</div>}
              </div>
              {pathData[activeTab].length > 0 && (
                <>
                  <AtlasTable
                    columns={columns}
                    data={data}
                    currentPage={currentPage}
                    onUpdateCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    onUpdateItemsPerPage={setItemsPerPage}
                    orderBy={orderBy}
                    onUpdateOrderBy={setOrderBy}
                    withPaginationFooter={true}
                    withoutTopRoundedBorder={true}
                    totalPages={totalPages}
                  />
                  {popupPathway && popupColor && (
                    <AccountPopup
                      topic={topic}
                      source={filters.source}
                      dateRange={dateRange}
                      node={{
                        accountId: popupPathway.accountId,
                        color: popupColor,
                        sizePercent: popupPathway.score,
                      }}
                      isStarred={starredAccounts.includes(
                        popupPathway.accountId
                      )}
                      onToggleStar={() => {
                        if (starredAccounts.includes(popupPathway.accountId))
                          setStarredAccounts(
                            starredAccounts.filter(
                              (x) => x != popupPathway.accountId
                            )
                          );
                        else
                          setStarredAccounts([
                            ...starredAccounts,
                            popupPathway.accountId,
                          ]);
                      }}
                      highlightMessage={highlightMessage}
                      setIsLoading={setIsLoading}
                      authToken={authToken}
                      position={{ x: 250, y: 400 }}
                      onClose={() => {
                        setPopupPathway(undefined);
                      }}
                    />
                  )}
                </>
              )}
              <div className="module-footnote">
                <img src={SvgInfo} alt="info" />
                <span>
                  The centricity scores displayed are over the most recent month
                </span>
              </div>
            </>
          )}
        </div>
      </DOLTabbedPageContent>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.data.client)
    throw new Error("client must be set to initialize this component");
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    client: state.data.client,
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    starAccounts: state.data.starAccounts,
    audienceAnalysisData: state.data.audienceAnalysis,
    authToken: state.data.auth_token,
    dataAudiences: state.data.audiences,
  };
};

const mapDispatchToProps = {
  setIsLoading,
  setAudienceAnalysis,
};

export default connect(mapStateToProps, mapDispatchToProps)(PathwaysScreen);
