import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { setIsLoading } from "redux/actions";
import Select from "react-select";
import {
  API_DOL_MATRIX_AUTHORS,
  API_DOL_MATRIX_MENTIONS,
  API_DOL_ACCOUNT_MENTIONS,
  API_MATRIX_THEME_MESSAGE,
  API_STAR_PROFILE,
} from "constants/routes";
import { convertToDt } from "utils/convertData";

import SvgInfo from "assets/images/icons/icon-info.svg";

import PageTemplate from "pages/PageTemplate";
import DOLTabbedPageContent from "./DOLTabbedPageContent";
import ChartPopup from "./ChartPopup";
import FiltersBar from "components/FiltersBar";
import AccountPopup from "./AccountPopup";
import { AtlasHorinzontalBarChart } from "components/AtlasCharts";
import AtlasPagination from "components/AtlasPagination";
import ModuleNotAvailable from "components/ModuleNotAvailable";

interface IProps {
  loading: boolean;
  topic: AtlasMach.ITopic;
  filters: AtlasMach.UIFilters;
  dateRange: AtlasMach.IDateRange;
  authToken: string;
  setIsLoading: (loading: boolean) => void;
}

function calcBestPosition(position) {
  const bounds = document.getElementById("table-body")?.getBoundingClientRect();

  if (!bounds) return position;

  return {
    x: Math.min(bounds.width - 310, position.x - bounds.left),
    y: Math.min(bounds.height - 500, position.y - bounds.top),
  };
}

const SenderMatrix = ({
  noThemeError,
  dataAuthorsForChart,
  handleSelectAccountTheme,
  selectedTheme,
  accountThemeMessages,
  themePopupPosition,
  setAccountThemeMessages,
  setThemePopupPosition,
}: {
  noThemeError: any;
  dataAuthorsForChart: any;
  handleSelectAccountTheme: any;
  selectedTheme: any;
  accountThemeMessages: any;
  themePopupPosition: any;
  itemsPerPage: any;
  setItemsPerPage: any;
  rangePages: any;
  currentPage: any;
  setCurrentPage: any;
  setAccountThemeMessages: any;
  setThemePopupPosition: any;
}) => (
  <>
    {noThemeError && (
      <p className="p">No themes available for the selected topic.</p>
    )}
    {!noThemeError && (
      <div className="matrix-wrapper">
        <div className="matrix-table-wrapper">
          <div className="matrix-table" id="table-body">
            {dataAuthorsForChart && (
              <AtlasHorinzontalBarChart
                data={dataAuthorsForChart}
                handleSelectAccountTheme={handleSelectAccountTheme}
                isMention={false}
              />
            )}
            {selectedTheme && accountThemeMessages && themePopupPosition && (
              <ChartPopup
                position={themePopupPosition}
                data={selectedTheme}
                msgData={accountThemeMessages}
                handleClose={() => {
                  setAccountThemeMessages(undefined);
                  setThemePopupPosition(undefined);
                }}
                handleMessage={() => {} /*handleThemeMessage*/}
              />
            )}
          </div>
        </div>
      </div>
    )}
  </>
);

const MentionMatrix = ({
  noThemeMentionsError,
  dataMentionsForChart,
  handleSelectAccountTheme,
  selectedTheme,
  accountThemeMessages,
  themePopupPosition,
  setAccountThemeMessages,
  setThemePopupPosition,
}: {
  noThemeMentionsError: any;
  dataMentionsForChart: any;
  handleSelectAccountTheme: any;
  selectedTheme: any;
  accountThemeMessages: any;
  themePopupPosition: any;
  itemsPerPage: any;
  setItemsPerPage: any;
  rangePages: any;
  currentPage: any;
  setCurrentPage: any;
  setAccountThemeMessages: any;
  setThemePopupPosition: any;
}) => (
  <>
    {noThemeMentionsError && (
      <p className="p">No themes available for the selected topic.</p>
    )}
    {!noThemeMentionsError && (
      <div className="matrix-wrapper">
        <div className="matrix-table-wrapper">
          <div className="matrix-table" id="table-body">
            {dataMentionsForChart && (
              <AtlasHorinzontalBarChart
                data={dataMentionsForChart}
                handleSelectAccountTheme={handleSelectAccountTheme}
                isMention={true}
              />
            )}
            {selectedTheme && accountThemeMessages && themePopupPosition && (
              <ChartPopup
                position={themePopupPosition}
                data={selectedTheme}
                msgData={accountThemeMessages}
                handleClose={() => {
                  setAccountThemeMessages(undefined);
                  setThemePopupPosition(undefined);
                }}
                handleMessage={() => {} /*handleThemeMessage*/}
              />
            )}
          </div>
        </div>
      </div>
    )}
  </>
);

const ConversationalMatrixScreen = (props: IProps) => {
  const { loading, topic, filters, dateRange, authToken, setIsLoading } = props;
  const [noThemeError, setNoThemeError] = useState<boolean>(false);
  const [noThemeMentionsError, setNoThemeMentionsError] =
    useState<boolean>(false);

  const [selectedTheme, setSelectedTheme] = useState<AtlasMach.IPieData>();
  const [accountThemeMessages, setAccountThemeMessages] =
    useState<AtlasMach.IPieMessages[]>();
  const [loadingAccountThemeMessages, setLoadingAccountThemeMessages] =
    useState<boolean>(false);

  const [themePopupPosition, setThemePopupPosition] = useState<
    { x: number; y: number } | undefined
  >(undefined);

  // TODO: ideally the mention-related data should be in a separate component
  // we should end up with two local components in this file, one for mention and one sender
  const [columnsAuthors, setColumnsAuthors] = useState<AtlasMach.IPieData[]>(
    []
  );
  const [columnsMentions, setColumnsMentions] = useState<AtlasMach.IPieData[]>(
    []
  );

  const [dataAuthors, setDataAuthors] = useState<any>([]);
  const [dataMentions, setDataMentions] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<"SENDER" | "MENTIONED">("SENDER");

  const [loadingAuthorsMatrix, setLoadingAuthorsMatrix] = useState(false);
  const [loadingMentionsMatrix, setLoadingMentionsMatrix] = useState(false);

  const [accountPopup, setAccountPopup] = useState<
    { accountId: string } | undefined
  >(undefined);

  // Currently instagram has anonymous users so we cannot support this module.
  const isModuleAvailable = filters.source.name != "Instagram";

  const dataAuthorsForChart = useMemo<
    AtlasMach.IBarDataMatrix | undefined
  >(() => {
    if (!dataAuthors?.length || !columnsAuthors?.length) return undefined;

    return {
      labels: dataAuthors.map((accountData) => accountData[1]["Account"]),
      accountIds: dataAuthors.map((accountData) => accountData[0]["AccountId"]),
      datasets: columnsAuthors.map((column, columnIndex) => ({
        label: column.label,
        labelId: column.id,
        data: dataAuthors.map(
          (accountData) =>
            typeof accountData[columnIndex + 2][column.label] === "number"
              ? accountData[columnIndex + 2][column.label]
              : 0 // Is offset by the first two values: AccountId and Account
        ),
        // Data assumes that the column order from res.columns is the same as the column orders in each row.
        backgroundColor: column.color,
        barThickness: 18,
        borderSkipped: true,
        borderColor: column.color,
        borderWidth: {
          top: 0,
          bottom: 1,
          left: 1,
          right: 1,
        },
      })),
      id: "an id",
    };
  }, [dataAuthors, columnsAuthors]);

  const dataMentionsForChart = useMemo<
    AtlasMach.IBarDataMatrix | undefined
  >(() => {
    if (!dataMentions?.length || !columnsMentions?.length) return undefined;

    return {
      labels: dataMentions.map((accountData) => accountData[1]["Account"]),
      accountIds: dataMentions.map(
        (accountData) => accountData[0]["AccountId"]
      ),
      datasets: columnsMentions.map((column, columnIndex) => ({
        label: column.label,
        labelId: column.id,
        data: dataMentions.map(
          (accountData) =>
            typeof accountData[columnIndex + 2][column.label] === "number"
              ? accountData[columnIndex + 2][column.label]
              : 0 // Is offset by the first two values: AccountId and Account
        ),
        // Data assumes that the column order from res.columns is the same as the column orders in each row.
        backgroundColor: column.color,
        barThickness: 18,
        borderSkipped: true,
        borderColor: column.color,
        borderWidth: {
          top: 0,
          bottom: 1,
          left: 1,
          right: 1,
        },
      })),
      id: "an id",
    };
  }, [dataMentions, columnsMentions]);

  const [starredAccounts, setStarredAccounts] = useState<string[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loadData, setLoadData] = useState<number>(0);

  const rangePages = useMemo<number[]>(() => {
    var range: number[] = [];

    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(totalPages, startPage + 4);

    for (var i = startPage; i <= endPage; i++) range.push(i);
    return range;
  }, [totalPages, currentPage]);

  // var rangePages: number[] = [];

  const loadStarredAccounts = () => {
    const apiUrl = API_STAR_PROFILE.replace("$1", topic.id).replace(
      "$2",
      filters.source.id
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          alert("Failed to load the saved answers due to an unexpected error");
        }
        return res.json();
      })
      .then((jsonData) => {
        setStarredAccounts(jsonData.map((x) => x.accountId));
      });
  };

  const handleChangeActiveTab = (action: "SENDER" | "MENTIONED") => {
    setActiveTab(action);
  };

  useEffect(() => {
    loadStarredAccounts();
  }, [authToken, topic]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  useEffect(() => {
    setIsLoading(
      loadingAuthorsMatrix ||
        loadingMentionsMatrix ||
        loadingAccountThemeMessages
    );
  }, [
    loadingAuthorsMatrix,
    loadingMentionsMatrix,
    loadingAccountThemeMessages,
  ]);

  useEffect(() => {
    setDataAuthors(undefined);
    setDataMentions(undefined);

    // When a filter changes, we go page to page 1. If we are already at page 1, we need to force the data reload.
    if (currentPage === 1) return setLoadData(Math.random());

    setCurrentPage(1); // By changing the page to the first 1, we also trigger a data reload
  }, [activeTab]);

  useEffect(() => {
    // When a filter changes, we go page to page 1. If we are already at page 1, we need to force the data reload.
    if (currentPage === 1) return setLoadData(Math.random());

    setCurrentPage(1); // By changing the page to the first 1, we also trigger a data reload
  }, [filters]);

  useEffect(() => {
    setLoadData(Math.random());
  }, [authToken, topic, dateRange, currentPage, itemsPerPage]);

  function loadAuthorsMatrix() {
    setLoadingAuthorsMatrix(true);

    const dt = convertToDt(dateRange.from, dateRange.to);

    // Fetch info for both matrixes, so that switching tabs is fast
    fetch(
      API_DOL_MATRIX_AUTHORS.replace("$1", topic.id) +
        `?sourceId=${filters.source.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: filters.audiences.map((a) => a.id),
          starAccountOnly: filters.onlyStarredAccounts,
          start: (currentPage - 1) * itemsPerPage,
          take: itemsPerPage,
          filter: "",
        }),
      }
    )
      .then((res) => {
        setLoadingAuthorsMatrix(false);

        if (res.status == 412) {
          setNoThemeError(true);
          return new Response();
        }
        if (res.status !== 200) {
          console.error("unexpected response:");
          console.log(res);
          alert("something went wrong when fetching matrix data");
        }
        return res.json();
      })
      .then((res) => {
        setDataAuthors(res.data);
        setColumnsAuthors(res.columns);
        setTotalPages(res.totalPages);
      });
  }

  function loadMentionsMatrix() {
    setLoadingMentionsMatrix(true);

    const dt = convertToDt(dateRange.from, dateRange.to);

    fetch(
      API_DOL_MATRIX_MENTIONS.replace("$1", topic.id) +
        `?sourceId=${filters.source.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: filters.audiences.map((a) => a.id),
          starAccountOnly: filters.onlyStarredAccounts,
          start: (currentPage - 1) * itemsPerPage,
          take: itemsPerPage,
          filter: "",
        }),
      }
    )
      .then((res) => {
        setLoadingMentionsMatrix(false);
        if (res.status == 412) {
          setNoThemeMentionsError(true);
          return new Response();
        }
        if (res.status !== 200) {
          console.error("unexpected response:");
          console.log(res);
          alert("something went wrong when fetching matrix data");
        }
        return res.json();
      })
      .then((res) => {
        setColumnsMentions(res.columns);
        setDataMentions(res.data);
        setTotalPages(res.totalPages);
      });
  }

  useEffect(() => {
    if (activeTab === "SENDER" && !loadingAuthorsMatrix) loadAuthorsMatrix();
    if (activeTab === "MENTIONED" && !loadingMentionsMatrix)
      loadMentionsMatrix();
  }, [loadData]);

  const loadAccountThemeMessages = async (
    topic,
    from,
    to,
    accountId,
    sourceId,
    themeId,
    isMention
  ) => {
    if (loadingAccountThemeMessages) return;

    setLoadingAccountThemeMessages(true);
    var baseApi = isMention
      ? API_DOL_ACCOUNT_MENTIONS.replace("$1", topic.id).replace(
          "$2",
          accountId
        ) + `?sourceId=${sourceId}&themeId=${themeId}`
      : API_MATRIX_THEME_MESSAGE.replace("$1", topic.id)
          .replace("$2", sourceId)
          .replace("$3", accountId)
          .replace("$4", themeId);
    const response = await fetch(baseApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        startDate: from,
        endDate: to,
        starAccountOnly: false,
        start: 0,
        take: itemsPerPage,
        orderBy: "",
        isDesc: true,
        filter: "",
      }),
    });
    const data = await response.json();
    setLoadingAccountThemeMessages(false);
    setAccountThemeMessages(data);
  };

  const handleSelectAccountTheme = async (
    accountId: string,
    col: AtlasMach.IPieData,
    isMention: boolean,
    position: { x: number; y: number }
  ) => {
    const bounds = document
      .getElementById("table-body")
      ?.getBoundingClientRect();

    setThemePopupPosition(calcBestPosition(position));
    setSelectedTheme(col);

    const dt = convertToDt(dateRange.from, dateRange.to);
    await loadAccountThemeMessages(
      topic,
      dt.dt_from,
      dt.dt_to,
      accountId,
      filters.source.id,
      col.id,
      isMention
    );
  };

  var popupColor: string | undefined = undefined;
  if (accountPopup) {
    //let audience = dataAudiences.find(a => a.name == popupPathway.audience)
    //popupColor = audience ? audience.color : 'rgb(173, 216, 230)'
    popupColor = "rgb(173, 216, 230)";
  }

  const options = [
    { value: "SENDER", label: "Authors" },
    { value: "MENTIONED", label: "Mentioned" },
  ];

  return (
    <PageTemplate title="Digital Opinion Leaders">
      <DOLTabbedPageContent tab="conversational-matrix">
        <div className="conversational-matrix-container">
          <FiltersBar
            disableBrands={true}
            disableThemes={true}
            disableSentiments={true}
            enableStarredAccounts={true}
          />
          {!isModuleAvailable && <ModuleNotAvailable />}
          {isModuleAvailable && (
            <>
              <div className="tabs-wrapper">
                <Select
                  unstyled
                  defaultValue={{ value: "SENDER", label: "Authors" }}
                  onChange={(newValue) => {
                    if (
                      newValue &&
                      (newValue.value === "SENDER" ||
                        newValue.value === "MENTIONED")
                    )
                      handleChangeActiveTab(newValue.value);
                  }}
                  options={options}
                  isSearchable={false}
                  styles={{
                    container: (baseStyle, state) => ({
                      ...baseStyle,
                      color: "white",
                    }),
                    control: (baseStyle, state) => ({
                      ...baseStyle,
                      border: "1px solid #1C3A4A",
                      borderRadius: "10px",
                      padding: "15px",
                      cursor: "pointer",
                    }),
                    indicatorsContainer: (baseStyle, state) => ({
                      ...baseStyle,
                      color: "#8D9CA6",
                      marginLeft: "10px",
                    }),
                    menu: (baseStyle, state) => ({
                      ...baseStyle,
                      background: "#112936",
                      border: "1px solid #1C3A4A",
                      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.45)",
                      borderRadius: "14px",
                      width: "max-content",
                      minWidth: "100%",
                      padding: "15px",
                    }),
                    singleValue: (baseStyle, state) => ({
                      ...baseStyle,
                      padding: "5px",
                      margin: "0 10px",
                    }),
                    option: (baseStyle, state) => ({
                      ...baseStyle,
                      margin: "10px 0",
                      fontWeight: state.isFocused ? "bold" : "400",
                      cursor: "pointer",
                    }),
                  }}
                />
              </div>
              <div className="matrix-border">
                {activeTab === "SENDER" && (
                  <SenderMatrix
                    noThemeError={noThemeError}
                    dataAuthorsForChart={dataAuthorsForChart}
                    handleSelectAccountTheme={handleSelectAccountTheme}
                    selectedTheme={selectedTheme}
                    accountThemeMessages={accountThemeMessages}
                    themePopupPosition={themePopupPosition}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    rangePages={rangePages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setAccountThemeMessages={setAccountThemeMessages}
                    setThemePopupPosition={setThemePopupPosition}
                  />
                )}
                {activeTab === "MENTIONED" && (
                  <MentionMatrix
                    noThemeMentionsError={noThemeMentionsError}
                    dataMentionsForChart={dataMentionsForChart}
                    handleSelectAccountTheme={handleSelectAccountTheme}
                    selectedTheme={selectedTheme}
                    accountThemeMessages={accountThemeMessages}
                    themePopupPosition={themePopupPosition}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    rangePages={rangePages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setAccountThemeMessages={setAccountThemeMessages}
                    setThemePopupPosition={setThemePopupPosition}
                  />
                )}
                <AtlasPagination
                  defaultItemsPerPage={itemsPerPage}
                  defaultCurrentPage={currentPage}
                  rangePages={rangePages}
                  callback={(newItemsPerPage, newCurrentPage) => {
                    setItemsPerPage(newItemsPerPage);
                    setCurrentPage(newCurrentPage);
                  }}
                />
              </div>
              <div className="module-footnote">
                {" "}
                <img src={SvgInfo} alt="info" />{" "}
                <span>
                  {" "}
                  Accounts sorted by highest centricity (computed over the most
                  recent month){" "}
                </span>{" "}
              </div>
            </>
          )}
          {accountPopup && popupColor && (
            <AccountPopup
              topic={topic}
              source={filters.source}
              dateRange={dateRange}
              node={{
                accountId: accountPopup.accountId,
                color: popupColor,
                sizePercent: undefined,
              }}
              isStarred={starredAccounts.includes(accountPopup.accountId)}
              onToggleStar={() => {
                if (starredAccounts.includes(accountPopup.accountId))
                  setStarredAccounts(
                    starredAccounts.filter((x) => x != accountPopup.accountId)
                  );
                else
                  setStarredAccounts([
                    ...starredAccounts,
                    accountPopup.accountId,
                  ]);
              }}
              setIsLoading={setIsLoading}
              authToken={authToken}
              position={{ x: 250, y: 400 }}
              onClose={() => {
                setAccountPopup(undefined);
              }}
            />
          )}
        </div>
      </DOLTabbedPageContent>
    </PageTemplate>
  );
};

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    loading: state.ui.isLoading,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {
  setIsLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationalMatrixScreen);
