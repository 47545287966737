import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { ReactComponent as StarIcon } from "assets/icons/star.svg";

import { convertToDt } from "utils/convertData";

import PageTemplate from "pages/PageTemplate";

import "./styles.scss";

interface IEvent {
  name: string;
  description: string;
  location: string;
  dateFrom: Date;
  dateTo: Date;
  color: string;
}

interface ICalendarDate {
  date: number;
  isGrayedOut: boolean;
}

interface ICalendarMonth {
  // The number of days in the month.
  days: number;
  // The day of the week (0-6, Sunday-Saturday) of the first of the month.
  firstDayOfWeek: number;
}

interface ICalendar {
  months: ICalendarMonth[];
}

const MONTHS_FULL = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const MONTHS = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

function formatDateRange(dateFrom: Date, dateTo: Date) {
  var fromMonth = MONTHS[dateFrom.getMonth()];
  var fromYear = dateFrom.getFullYear();
  return `${fromMonth} ${dateFrom.getDate()} - ${dateTo.getDate()}, ${fromYear}`;
}

function calendarMonthRows(
  previousMonth: ICalendarMonth,
  month: ICalendarMonth
): ICalendarDate[][] {
  var rows: ICalendarDate[][] = [];

  // The initial offset for the first day of the month, if it's monday, there's no offset (0, or left aligned), then
  // it increases by 1 each time. On sunday it's the maximum offset (which needs a special case because sunday is 0-indexed).
  var initialOffset: number =
    month.firstDayOfWeek == 0 ? 6 : month.firstDayOfWeek - 1;
  var currentDay: number =
    ((previousMonth.days - initialOffset) % previousMonth.days) + 1;

  var grayedOut = currentDay != 1;

  // First row, we must use previous month days as the day when we reset to 1.
  var col: ICalendarDate[] = [];
  for (let j = 0; j < 7; j += 1) {
    col.push({ date: currentDay, isGrayedOut: grayedOut });
    currentDay += 1;
    if (currentDay > previousMonth.days) {
      currentDay = 1;
      grayedOut = false;
    }
  }
  rows.push(col);

  for (let i = 1; i < 6; i += 1) {
    col = [];
    for (let j = 0; j < 7; j += 1) {
      col.push({ date: currentDay, isGrayedOut: grayedOut });
      currentDay += 1;
      if (currentDay > month.days) {
        currentDay = 1;
        grayedOut = true;
      }
    }
    rows.push(col);
  }
  return rows;
}

function calendarYearRows(
  previousYear: ICalendar,
  year: ICalendar
): ICalendarDate[][][] {
  var res: ICalendarDate[][][] = [];

  for (let m = 0; m < 12; m += 1) {
    let previousMonth = m == 0 ? previousYear.months[11] : year.months[m - 1];
    let month = year.months[m];
    res.push(calendarMonthRows(previousMonth, month));
  }
  return res;
}

function generateCalendar(year: number) {
  var months: ICalendarMonth[] = [];
  for (let i = 0; i < 12; i += 1) {
    months.push({
      firstDayOfWeek: new Date(year, i, 1).getDay(),
      days: i == 11 ? 31 : new Date(year, i + 1, 0).getDate(),
    });
  }
  return {
    months: months,
  };
}

const CALENDARS = {
  2022: generateCalendar(2022),
  2023: generateCalendar(2023),
  2024: generateCalendar(2024),
  2025: generateCalendar(2025),
};

const CALENDARS_ROWS: { [key: number]: ICalendarDate[][][] } = {
  2023: calendarYearRows(CALENDARS[2022], CALENDARS[2023]),
  2024: calendarYearRows(CALENDARS[2023], CALENDARS[2024]),
  2025: calendarYearRows(CALENDARS[2024], CALENDARS[2025]),
};

function EventsCalendarScreen({ topic, authToken }) {
  const [year, setYear] = useState<number>(2023);

  var calendarRows = CALENDARS_ROWS[year];

  const [events, setEvents] = useState<IEvent[]>([
    {
      name: "AAAAI",
      description: "American Academy of Asthma, Allergy, and Immunology",
      location: "San Antonio",
      dateFrom: new Date("2023-02-23"),
      dateTo: new Date("2023-02-27"),
      color: "#FF0000",
    },
    {
      name: "AAAAI",
      description: "American Academy of Asthma, Allergy, and Immunology",
      location: "San Antonio",
      dateFrom: new Date("2023-03-23"),
      dateTo: new Date("2023-03-27"),
      color: "#FFFF00",
    },
    {
      name: "AAAAI",
      description: "American Academy of Asthma, Allergy, and Immunology",
      location: "San Antonio",
      dateFrom: new Date("2023-06-28"),
      dateTo: new Date("2023-07-2"),
      color: "#FF00FF",
    },
    {
      name: "AAAAI",
      description: "American Academy of Asthma, Allergy, and Immunology",
      location: "San Antonio",
      dateFrom: new Date("2023-09-20"),
      dateTo: new Date("2023-09-25"),
      color: "#00FFFF",
    },
  ]);

  return (
    <PageTemplate title="Events Calendar">
      <div className="events-calendar-container">
        <div className="events-calendar-header">
          <div className="events-calendar-header-year">{year}</div>
          <h2>Events Calendar in the {topic.name} disease space</h2>
        </div>
        <div className="events-calendar-body">
          <div className="events-calendar-list-view">
            <h3>Events</h3>
            <div>
              {events.map((ev) => (
                <div className="events-calendar-list-item">
                  <div
                    className="events-calendar-list-item-color"
                    style={{ backgroundColor: ev.color }}
                  ></div>
                  <div>{ev.name}</div>
                  <div className="events-calendar-list-item-description">
                    {ev.description}
                  </div>
                  <div>{ev.location}</div>
                  <div>{formatDateRange(ev.dateFrom, ev.dateTo)}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="events-calendar-calendar-view">
            {calendarRows.map((month, i) => (
              <div className="events-calendar-calendar-month">
                <h3>{MONTHS_FULL[i]}</h3>
                <div className="events-calendar-calendar-month-row">
                  {["M", "T", "W", "T", "F", "S", "S"].map((day) => (
                    <div className="events-calendar-calendar-month-date disabled">
                      {day}
                    </div>
                  ))}
                </div>
                {month.map((row) => (
                  <div className="events-calendar-calendar-month-row">
                    {row.map((date) => (
                      <div
                        className={
                          "events-calendar-calendar-month-date" +
                          (date.isGrayedOut ? " disabled" : "")
                        }
                      >
                        {date.date}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");

  return {
    topic: state.ui.topic,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsCalendarScreen);
