/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import TabbedPageContent from "components/TabbedPageContent";

import "./styles.scss";

function ListeningTabbedPageContent({ tab, children }) {
  const tabs = [
    { id: "summary", label: "Summary", withCalendar: true },
    { id: "content-feed", label: "Content Feed", withCalendar: true },
    { id: "themes-fit-gap", label: "Themes Fit Gap", withCalendar: true },
    { id: "benchmarking", label: "Benchmarking", withCalendar: true },
  ];
  const activeTab = tabs.findIndex((t) => t.id === tab);

  return (
    <TabbedPageContent tabs={tabs} activeTab={activeTab}>
      {children}
    </TabbedPageContent>
  );
}

export default ListeningTabbedPageContent;
