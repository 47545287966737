import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setIsLoading, setAudienceAnalysis } from "redux/actions";

import { API_STAR_PROFILE } from "constants/routes";

import PageTemplate from "pages/PageTemplate";
import DOLTabbedPageContent from "./DOLTabbedPageContent";
import AudienceAnalysis from "./AudienceAnalysis";
import AccountCard from "./AccountCard";
import FiltersBar from "components/FiltersBar";

import { ReactComponent as BackIcon } from "assets/icons/back.svg";

interface Props {
  topic: AtlasMach.ITopic;
  filters: AtlasMach.UIFilters;
  dateRange: AtlasMach.IDateRange;
  authToken: string;
  dataAudiences: AtlasMach.IAudience[];
  audienceAnalysisData: AtlasMach.IAASection;
  setAudienceAnalysis: (aa: AtlasMach.IAASection) => void;
  setIsLoading: (loading: boolean) => void;
  isLoading: boolean;
}

function StarredAccountsScreen({
  topic,
  filters,
  dateRange,
  authToken,
  dataAudiences,
  audienceAnalysisData,
  setAudienceAnalysis,
  setIsLoading,
  isLoading,
}: Props) {
  const [starredAccounts, setStarredAccounts] = useState<
    AtlasMach.IStarredAccount[]
  >([]);
  const [isAccountStarred, setIsAccountStarred] = useState<boolean[]>([]);

  const loadStarredAccounts = () => {
    if (isLoading) return;
    setIsLoading(true);

    var apiUrl =
      API_STAR_PROFILE.replace("$1", topic.id).replace(
        "$2",
        filters.source.id
      ) + "?";

    filters.audiences.forEach((a) => (apiUrl += `audiences=${a.id}&`));

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setIsLoading(false);

        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          alert("Failed to load the saved answers due to an unexpected error");
        }
        return res.json();
      })
      .then((jsonData) => {
        var isStarred: boolean[] = jsonData.map((d) => true);
        setStarredAccounts(jsonData);
        setIsAccountStarred(isStarred);
      });
  };

  useEffect(() => {
    loadStarredAccounts();
  }, [filters]);

  function audienceColor(starredAccount: AtlasMach.IStarredAccount) {
    let audience: AtlasMach.IAccountAudience | undefined =
      starredAccount.account.audiences.find((a) => a.topicId == topic.id);

    if (!audience) return "rgb(173, 216, 230)";

    let audienceObject = dataAudiences.find(
      (da) => da.id == audience?.topicAudienceId
    );
    if (!audienceObject) return "rgb(173, 216, 230)";

    return audienceObject.color;
  }

  return (
    <PageTemplate title="Digital Opinion Leaders">
      <DOLTabbedPageContent tab="starred-accounts">
        <div className="hs-starred-accounts-screen">
          <FiltersBar
            disableBrands={true}
            disableThemes={true}
            disableSentiments={true}
          />
          {audienceAnalysisData.name !== "" && (
            <AudienceAnalysis
              onClose={() =>
                setAudienceAnalysis({
                  name: "",
                  profileImage: "",
                  Sections: [],
                })
              }
            />
          )}
          <div className="hs-starred-accounts">
            {starredAccounts.map((starredAccount, idx) => (
              <AccountCard
                topic={topic}
                source={filters.source}
                accountId={starredAccount.accountId}
                npi={starredAccount.account.healthCareProviderNpi}
                isStarred={isAccountStarred[idx]}
                onToggleStar={() => {
                  setIsAccountStarred((old) => {
                    var n = [...old];
                    n[idx] = !n[idx];
                    return n;
                  });
                }}
                color={audienceColor(starredAccount)}
                sizePercent={starredAccount.score}
                dateRange={dateRange}
                setIsLoading={() => {
                  console.log("loading");
                }}
                authToken={authToken}
                setAudienceAnalysis={setAudienceAnalysis}
              />
            ))}
          </div>
        </div>
      </DOLTabbedPageContent>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    audienceAnalysisData: state.data.audienceAnalysis,
    authToken: state.data.auth_token,
    dataAudiences: state.data.audiences,
    isLoading: state.ui.isLoading,
  };
};

const mapDispatchToProps = {
  setIsLoading,
  setAudienceAnalysis,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StarredAccountsScreen);
