import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import {
  API_VOICES_SAVED_ANSWERS,
  API_VOICES_SAVED_ANSWER,
} from "constants/routes";

import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as DotsIcon } from "assets/icons/dots.svg";

import PageTemplate from "pages/PageTemplate";

interface ISavedExamplePost {
  text: string;
}
interface ISavedAnswerAnswer {
  answer: string;
  examplePosts: ISavedExamplePost[];
}
interface ISavedAnswerQuestion {
  question: string;
  audience: string;
  dateFrom: string;
  dateTo: string;
}
interface ISavedAnswer {
  id: string;
  answeredOn: Date;
  question: ISavedAnswerQuestion;
  answer: ISavedAnswerAnswer;
}

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function formatDate(stringDate: string) {
  const dt = new Date(stringDate);

  return `${MONTHS[dt.getMonth()]} ${dt.getFullYear()}`;
}

function SavedAnswersScreen({ topic, authToken }) {
  const [savedAnswers, setSavedAnswers] = useState<ISavedAnswer[]>([]);

  const [showPosts, setShowPosts] = useState<boolean[]>([]);
  const [showDelete, setShowDelete] = useState<boolean[]>([]);

  const navigate = useNavigate();

  const loadSavedAnswers = () => {
    const apiUrl = API_VOICES_SAVED_ANSWERS.replace("$1", topic.id);
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          alert("Failed to load the saved answers due to an unexpected error");
        }
        return res.json();
      })
      .then((jsonData) => {
        setSavedAnswers(jsonData);
      });
  };

  const handleDeleteSavedAnswer = (savedAnswer: ISavedAnswer) => {
    const apiUrl = API_VOICES_SAVED_ANSWER.replace("$1", topic.id).replace(
      "$2",
      savedAnswer.id
    );
    fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status !== 204) {
        console.error("Unexpected response code for query: " + apiUrl);
        console.log(res);
      }
    });
    setSavedAnswers(savedAnswers.filter((sa) => sa.id != savedAnswer.id));
  };

  useEffect(() => {
    loadSavedAnswers();
  }, []);

  useEffect(() => {
    setShowPosts(savedAnswers.map((x) => false));
  }, [savedAnswers]);

  return (
    <PageTemplate title="Voices">
      <div className="voices-saved-screen">
        <h2>
          <BackIcon
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />{" "}
          SAVED
        </h2>
        <div className="voices-saved-answers">
          {savedAnswers.map((savedAnswer, idx) => (
            <div className="voices-saved-answer">
              <div className="voices-saved-answer-date">
                Saved on {new Date(savedAnswer.answeredOn).toLocaleDateString()}
                <DotsIcon
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() =>
                    setShowDelete((o) => {
                      var n = [...o];
                      n[idx] = !showDelete[idx];
                      return n;
                    })
                  }
                />
                {showDelete[idx] && (
                  <div
                    className="hs-dropdown"
                    style={{
                      position: "absolute",
                      top: "30px",
                      right: "-60px",
                    }}
                  >
                    <div
                      className="hs-dropdown-item"
                      onClick={() => {
                        setShowDelete((o) => {
                          var n = [...o];
                          n[idx] = false;
                          return n;
                        });
                        handleDeleteSavedAnswer(savedAnswer);
                      }}
                    >
                      Remove
                    </div>
                  </div>
                )}
              </div>
              <div className="voices-saved-answer-type">
                {savedAnswer.question.audience}
              </div>

              <div
                className={
                  "voices-saved-answer-answer hs-scrollable-container" +
                  (showPosts[idx] ? " expanded" : "")
                }
              >
                <div className="voices-saved-answer-question-label">
                  Question
                </div>
                <div className="voices-saved-answer-question-text">
                  {savedAnswer.question.question}
                </div>
                <div className="voices-saved-answer-period-label">Period</div>
                <div className="voices-saved-answer-period-text">
                  {`${formatDate(
                    savedAnswer.question.dateFrom
                  )} to ${formatDate(savedAnswer.question.dateTo)}`}
                </div>
                <div className="voices-saved-answer-answer-label">Answer</div>
                <div className="voices-saved-answer-answer-text">
                  {savedAnswer.answer.answer}
                </div>

                {showPosts[idx] && (
                  <div className="voices-saved-answer-posts">
                    <div className="voices-saved-answer-posts-label">
                      Example posts
                    </div>
                    {savedAnswer.answer.examplePosts.map((examplePost) => (
                      <div className="voices-saved-answer-post-text">
                        {examplePost.text}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {!showPosts[idx] && (
                <div className="voices-saved-answer-show-posts-container">
                  <div
                    className="voices-saved-answer-show-posts"
                    onClick={() =>
                      setShowPosts((o) => {
                        var n = [...o];
                        n[idx] = true;
                        return n;
                      })
                    }
                  >
                    See Example posts
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize SummaryScreen component");

  return {
    topic: state.ui.topic,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SavedAnswersScreen);
