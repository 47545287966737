import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select, {
  components,
  ValueContainerProps,
  PlaceholderProps,
} from "react-select";
import { connect } from "react-redux";
import { Tweet } from "react-twitter-widgets";

import AtlasCalendarSelect from "components/AtlasCalendarSelect";
import { API_VOICES_PATIENTS } from "constants/routes";

import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import { ReactComponent as ArrowSubmit } from "assets/icons/arrow-submit.svg";

import { convertToDt } from "utils/convertData";

import ModuleNotAvailable from "components/ModuleNotAvailable";
import PageTemplate from "pages/PageTemplate";
import Typing from "../../components/Typing";

import "./styles.scss";

interface IExamplePost {
  permalink: string;
  text: string;
}
interface IAudienceResponse {
  response: string;
  examplePosts: IExamplePost[];
  totalPosts: number;
}

function AudienceVoicesScreen({ topic, dateRange, audiences, authToken }) {
  const [selectedAudience, setSelectedAudience] = useState<{
    id: string;
    name: string;
  }>(audiences[0]);

  console.log(audiences);

  const [question, setQuestion] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [responseNegative, setResponseNegative] = useState<
    IAudienceResponse | undefined
  >(undefined);
  const [responseNeutral, setResponseNeutral] = useState<
    IAudienceResponse | undefined
  >(undefined);
  const [responsePositive, setResponsePositive] = useState<
    IAudienceResponse | undefined
  >(undefined);

  const [showExamplePosts, setShowExamplePosts] = useState<
    IAudienceResponse | undefined
  >(undefined);

  const sendQuestion = (sentiment: string) => {
    const dt = convertToDt(dateRange.from, dateRange.to);
    const apiUrl = API_VOICES_PATIENTS.replace("$1", topic.id); // + `?startDate=${dt.dt_from}&endDate=${dt.dt_to}`
    return fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        topicId: topic.id,
        audience: selectedAudience.name,
        question: question,
        sentiment: sentiment,
        dateFrom: dt.dt_from,
        dateTo: dt.dt_to,
      }),
    });
  };
  const submitQuestion = () => {
    if (submitting) return;

    setResponseNegative(undefined);
    setResponseNeutral(undefined);
    setResponsePositive(undefined);
    setSubmitting(true);

    const dt = convertToDt(dateRange.from, dateRange.to);
    const apiUrl = API_VOICES_PATIENTS.replace("$1", topic.id); // + `?startDate=${dt.dt_from}&endDate=${dt.dt_to}`
    sendQuestion("negative")
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
          alert("unexpected error, please reload the page");
          return;
        }
        return res.json();
      })
      .then((jsonData) => {
        setResponseNegative(jsonData);
        sendQuestion("neutral")
          .then((res) => {
            if (res.status !== 200) {
              console.error("Unexpected response code for query: " + apiUrl);
              console.log(res);
              // TODO: what to return and how to redirect?
              alert("unexpected error, please reload the page");
              return;
            }
            return res.json();
          })
          .then((jsonData) => {
            setResponseNeutral(jsonData);
            sendQuestion("positive")
              .then((res) => {
                if (res.status !== 200) {
                  console.error(
                    "Unexpected response code for query: " + apiUrl
                  );
                  console.log(res);
                  // TODO: what to return and how to redirect?
                  alert("unexpected error, please reload the page");
                  return;
                }
                return res.json();
              })
              .then((jsonData) => {
                setResponsePositive(jsonData);
                setSubmitting(false);
              });
          });
      });
  };

  const permalinkToTweetId = (permalink: string) => {
    const parts = permalink.split("/");
    const tweetId = parts[parts.length - 1];
    return tweetId;
  };

  const handleInputChange = (e) => {
    setQuestion(e.target.value.replace("\n", ""));
  };
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && !submitting) submitQuestion();
  };

  return (
    <PageTemplate title="Voices">
      <div className="voices2-container">
        <div className="voices2-sidebar">
          <div className="voices2-sidebar-tabs">
            <div className="voices2-sidebar-tab active">Audiences</div>
            <Link
              to={"/hs/voices/starred-accounts-2"}
              className={"voices2-sidebar-tab"}
            >
              Individual
            </Link>
          </div>
          <div className="voices2-sidebar-calendar">
            <AtlasCalendarSelect
              style={{ display: "flex", marginLeft: "auto" }}
            />
          </div>
          <div className="voices2-sidebar-bots">
            {audiences.map((a) => (
              <div
                key={a.id}
                className={
                  "voices2-sidebar-bot " +
                  (a.id === selectedAudience.id ? "selected" : "")
                }
                onClick={() => setSelectedAudience(a)}
              >
                {a.name}
              </div>
            ))}
          </div>
        </div>

        <div className="voices2-body">
          <div className="voices2-body-lead">
            Hi, I’m an AI engine representing {selectedAudience.name}s
          </div>

          <div className="voices2-question-input">
            <input
              type="text"
              disabled={submitting}
              value={question}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder="Ask your question"
            />

            <div
              className="voices2-question-submit"
              onClick={() => {
                if (!submitting) submitQuestion();
              }}
            >
              <ArrowSubmit />
            </div>
          </div>
          {submitting && <Typing label={"typing"} />}
          {responseNegative && responseNeutral && responsePositive && (
            <div>
              <div className="voices2-body-answer">
                <h4>
                  {Math.round(
                    (100 * responsePositive.totalPosts) /
                      (responsePositive.totalPosts +
                        responseNeutral.totalPosts +
                        responseNegative.totalPosts)
                  )}
                  % of respondents said
                </h4>
                <div>{responsePositive.response}</div>
                <div className="voices2-body-answer-show-tweets">
                  <button
                    onClick={() => setShowExamplePosts(responsePositive)}
                    className="hs-button primary"
                  >
                    View Tweets
                  </button>
                </div>
              </div>
              <div className="voices2-body-answer">
                <h4>
                  {Math.round(
                    (100 * responseNeutral.totalPosts) /
                      (responsePositive.totalPosts +
                        responseNeutral.totalPosts +
                        responseNegative.totalPosts)
                  )}
                  % of respondents said
                </h4>
                <div>{responseNeutral.response}</div>
                <div className="voices2-body-answer-show-tweets">
                  <button
                    onClick={() => setShowExamplePosts(responseNeutral)}
                    className="hs-button primary"
                  >
                    View Tweets
                  </button>
                </div>
              </div>
              <div className="voices2-body-answer">
                <h4>
                  {Math.round(
                    (100 * responseNegative.totalPosts) /
                      (responsePositive.totalPosts +
                        responseNeutral.totalPosts +
                        responseNegative.totalPosts)
                  )}
                  % of respondents said
                </h4>
                <div>{responseNegative.response}</div>
                <div className="voices2-body-answer-show-tweets">
                  <button
                    onClick={() => setShowExamplePosts(responseNegative)}
                    className="hs-button primary"
                  >
                    View Tweets
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="voices2-tweets-placeholder">
          {showExamplePosts && showExamplePosts.examplePosts.length > 0 && (
            <div className="voices2-tweets">
              <div className="voices2-tweets-label">ORIGINAL POSTS</div>
              <div className="voices-patients-example-posts">
                {showExamplePosts.examplePosts.map((p) => (
                  <Tweet
                    tweetId={permalinkToTweetId(p.permalink)}
                    options={{ width: 700, theme: "dark" }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize SummaryScreen component");
  if (!state.ui.dateRange)
    throw new Error("Filters must be set to initialize this component");

  const individualBots = state.ui.topic.topicBots
    .map((tb) => tb.bot)
    .filter((b) => !b.topicAudienceId);

  return {
    topic: state.ui.topic,
    bots: individualBots,
    dateRange: state.ui.dateRange,
    audiences: state.data.audiences,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AudienceVoicesScreen);
