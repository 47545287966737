import { connect } from "react-redux";
import {
  updateTopic,
  updateFilters,
  setSources,
  setAudiences,
  setThemes,
} from "redux/actions";
import MenuPage from "./MenuPage";

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize MenuPage component");
  if (state.data.topics.length == 0)
    throw new Error(
      "Need at least one topic to be able to initialize MenuPage component"
    );

  return {
    topics: state.data.topics,
    topic: state.ui.topic,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {
  updateTopic,
  updateFilters,
  setSources,
  setAudiences,
  setThemes,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage);
