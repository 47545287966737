import { connect } from "react-redux";
import PageTemplate from "./PageTemplate";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  loading: state.ui.isLoading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageTemplate);
