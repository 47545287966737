import React, { useState, useEffect } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Select from "react-select";
import { connect } from "react-redux";

import { config } from "../../../config.js";
import AtlasCalendarSelect from "components/AtlasCalendarSelect";

import AtlasFullScreenPopup from "components/AtlasFullScreenPopup";
import PageTemplate from "pages/PageTemplate";
import PollVerseTabbedPageContent from "./PollVerseTabbedPageContent";
import Typing from "../../../components/Typing";
import AtlasSelect from "../../../components/AtlasSelect";
import FiltersBar from "components/Pollverse/FiltersBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy as faCopyRegular } from "@fortawesome/free-regular-svg-icons";

const STATES_TO_POLITICIANS = {
  tennessee: [],
  colorado: [],
  connecticut: [],
};

const POLITICIANS = [
  {
    value: "laurenboebert-colorado-twitter",
    label: "@Lauren Boebert",
    name: "Lauren Boebert",
  },
];

const POLITICAL_TOPICS = [];

interface IExamplePost {
  permalink: string;
  text: string;
}
interface IAudienceResponse {
  response: any;
  examplePosts: IExamplePost[];
  percentage: number;
}

function ResponseCard({ response }) {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <div className="pollverse-response-card">
      <div>
        <div className="pollverse-response-card-response">
          <div className="title">{response.percentage}% said this</div>
          <div>{response.response}</div>
        </div>
        {response.examplePosts.length > 0 && (
          <div className="pollverse-example-posts">
            <button
              className="hs-button primary"
              onClick={() => setShowPopup(true)}
            >
              See Example Posts
            </button>
          </div>
        )}
      </div>
      {showPopup && (
        <AtlasFullScreenPopup onClose={() => setShowPopup(false)}>
          <div className="pollverse-inspire-me-popup">
            <h2>Example Posts</h2>
            <div>
              <div className="pollverse-example-posts">
                {response.examplePosts.map((p) => (
                  <a
                    key={p.permalink}
                    href={p.permalink}
                    target="_blank"
                    className="pollverse-voices-example-post"
                  >
                    {p.text}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </AtlasFullScreenPopup>
      )}
    </div>
  );
}

function VoicesCompareScreen({ topic, filters, brands, authToken }) {
  var disclaimer = "";

  const [showLandingPage, setShowLandingPage] = useState<boolean>(true);
  const [selectedBrand, setSelectedBrand] = useState<string>("");
  const [selectedAudience, setSelectedAudience] = useState<string>("");

  const selectedStatesPoliticians = filters.usStates?.reduce(
    (result, usState) => result.concat(STATES_TO_POLITICIANS[usState.value]),
    []
  );

  const shortlistedPoliticians = POLITICIANS.filter((politician) =>
    selectedStatesPoliticians?.includes(politician.value)
  );

  const options =
    shortlistedPoliticians.length === 0
      ? [
          {
            value: "No State selected",
            label: "No State selected",
            name: "No State selected",
          },
        ]
      : [
          ...shortlistedPoliticians,
          {
            value: "voters-guns",
            label: "Voters",
            name: "Voters",
          },
          {
            value: "all",
            label: "All Accounts",
            name: "All Accounts",
          },
        ];

  const [selectedAccounts, setSelectedAccounts] = useState<
    {
      value: string;
      label: string;
    }[]
  >([options[0]]);

  const [question, setQuestion] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [responses, setResponses] = useState<IAudienceResponse[] | []>([]);

  const [savedAnswerId, setSavedAnswerId] = useState<string>("");

  const [showInspireMePopup, setShowInspireMePopup] = useState<boolean>(false);

  const dt_from = new Date(topic.startDate);
  const initialFromDate = { year: 2015, month: 0 };
  const [fromDate, setFromDate] = useState<{
    value: string;
    label: string;
    payload: AtlasMach.IDate;
  }>(dateToOption(initialFromDate));
  const dt_to = new Date(topic.endtDate);
  const initialToDate = { year: 2023, month: 11 };
  const [toDate, setToDate] = useState<{
    value: string;
    label: string;
    payload: AtlasMach.IDate;
  }>(dateToOption(initialToDate));

  const isDateRangeValid =
    fromDate.payload.year < toDate.payload.year ||
    (fromDate.payload.year == toDate.payload.year &&
      fromDate.payload.month <= toDate.payload.month);

  let welcomeText =
    "I am an AI Persona Generator representing the online posts of " +
    (selectedAccounts.length === 1
      ? selectedAccounts[0].label
      : "All Accounts") +
    " over the period of ";

  useEffect(() => {
    setSelectedAccounts([options[0]]);
  }, [filters]);

  async function submitQuestion() {
    if (submitting || !question) return;

    setResponses([]);
    setSubmitting(true);

    setTimeout(() => {
      setResponses([
        {
          // First one is the summary
          response: (
            <>
              <p>
                Based on the opinions provided, here's a breakdown and
                comparison:
              </p>

              <p>
                1. The first opinion is highly positive. The user reports that
                Aimovig has significantly reduced the frequency and severity of
                their migraines, allowing them to cut back on other pain
                management methods. They acknowledge that self-administration of
                the shot is a downside, but believe it's a small price to pay
                for the relief it brings.
              </p>

              <p>
                2. The second opinion is cautiously optimistic. The user
                appreciates the promising real-world data and long-term safety
                studies, but is concerned about reports suggesting low sustained
                long-term efficacy in refractory chronic migraine patients. They
                also express concern about potential changes to the Aimovig
                coupon program, which could affect affordability.
              </p>

              <p>
                3. The third opinion is mixed to negative. The user reports that
                Aimovig didn't work for them and even increased their pain. They
                also experienced side effects and express concern about
                potential cardiovascular risks. They call for more comprehensive
                studies to ensure the safety and efficacy of Aimovig.
              </p>

              <p>
                In summary, while Aimovig has been a game-changer for some,
                others have had less positive experiences, citing increased
                pain, side effects, and potential long-term risks. Affordability
                is also a concern for some users.
              </p>
            </>
          ),
          examplePosts: [],
          percentage: 100,
        },
        {
          response: (
            <>
              <p>
                Aimovig has been a game-changer for me. As a chronic migraine
                sufferer, I've tried just about everything out there, and
                nothing seemed to work. It was a miserable existence, having
                migraines more than half the week. But then I started on
                Aimovig, and it's made a world of difference.
              </p>

              <p>
                It did take a few months to kick in, but once it did, the
                frequency and severity of my migraines reduced significantly.
                I've even been able to cut back on opioids and other pain
                management visits. It's not a walk in the park, you have to
                administer the shot yourself, but it's a small price to pay for
                the relief it brings.
              </p>

              <p>
                I'm not saying it's a miracle cure, but it's given me a chance
                at a more normal life. I've been able to get more work done on
                my good days, and the bad days are fewer and far between. It's
                been a huge relief, and I'm grateful for it.
              </p>
            </>
          ),
          examplePosts: [],
          percentage: 47,
        },
        {
          response: (
            <>
              <p>
                As a patient with chronic migraine, I've been closely following
                the discussions and studies around erenumab, also known as
                Aimovig. The real-world data and long-term safety studies have
                been quite promising, showing that it's effective and
                well-tolerated in patients like me who've had previous failures
                with other migraine prevention medications.{" "}
              </p>

              <p>
                However, I've also seen some reports suggesting that the
                sustained long-term efficacy of erenumab in refractory chronic
                migraine patients is low. This is a bit concerning, and I think
                it's important to continue researching and understanding the
                effects of this and other CGRP mAbs on different patient groups.
              </p>

              <p>
                I've also heard about the Aimovig coupon program possibly
                changing, with some seeing a $122 increase. This is definitely
                something I'm keeping an eye on, as affordability is a key
                factor in treatment decisions.
              </p>

              <p>
                Overall, I'm cautiously optimistic about Aimovig. It's great to
                see new treatments being developed for chronic migraine, but
                it's also crucial to understand their long-term effects and
                ensure they're accessible to all patients.
              </p>
            </>
          ),
          examplePosts: [],
          percentage: 29,
        },
        {
          response: (
            <>
              <p>
                I've had a mixed bag of experiences with Aimovig. On one hand,
                it's one of the few treatments specifically designed for chronic
                migraine, which is a step in the right direction. However, it
                didn't work for me and even increased my pain. I also had to
                deal with some side effects. I'm concerned about the
                fast-tracking of such medications without thorough scrutiny. The
                company's own report mentioned potential cardiovascular risks,
                which is worrying. I hope more comprehensive studies are
                conducted to ensure the safety and efficacy of these treatments.
              </p>
            </>
          ),
          examplePosts: [],
          percentage: 24,
        },
      ]);
      setSubmitting(false);
    }, 1500);
  }

  const handleInputChange = (e) => {
    setQuestion(e.target.value.replace("\n", ""));
  };
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && !isSubmitDisabled) submitQuestion();
  };
  const handleChange = (option) => {
    setResponses([]);
    if (option.value === "all") {
      setSelectedAccounts(options.filter((o) => o.label.startsWith("@")));
    } else {
      setSelectedAccounts([option]);
    }
  };

  const isVoiceSupported: boolean = true;
  const isSubmitDisabled = submitting || !isDateRangeValid;

  const dateOptions = generateDateRange(initialFromDate, initialToDate).map(
    (d) => dateToOption(d)
  );

  console.log(selectedBrand);
  if (showLandingPage) {
    return (
      <PageTemplate title={"Voices"}>
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              width: "fit-content",
              background: "#112936",
              borderRadius: "15px",
              padding: "15px 20px",
              paddingTop: "15px",
              height: "40px",
              margin: "auto",
              marginTop: "300px",
            }}
          >
            <AtlasCalendarSelect
              style={{ display: "flex", marginLeft: "auto" }}
            />
            <AtlasSelect
              placeholder={"Brands/Companies"}
              options={[
                { label: "Aimovig", value: "Aimovig" },
                { label: "Botox", value: "Botox" },
                { label: "Maxalt", value: "Maxalt" },
                { label: "Nurofen", value: "Nurtec" },
                { label: "Ajovy", value: "Ajovy" },
                { label: "Topamax", value: "Topamax" },
                { label: "Excedrin", value: "Excedrin" },
                { label: "Emgality", value: "Emgality" },
                { label: "Nurtec", value: "Nurtec" },
              ]}
              onChange={(opt) => {
                setSelectedBrand(opt);
              }}
              value={selectedBrand}
            />
            <AtlasSelect
              placeholder={"Audiences"}
              options={[
                { label: "Caregiver", value: "Caregiver" },
                { label: "HCP", value: "HCP" },
                { label: "PAG", value: "PAG" },
                { label: "Patient", value: "Patient" },
                { label: "Research", value: "Research" },
              ]}
              onChange={(opt) => {
                setSelectedAudience(opt);
              }}
              value={selectedAudience}
            />
          </div>
          <button
            style={{ marginTop: "60px", height: "60px" }}
            className="hs-button primary"
            onClick={() => setShowLandingPage(false)}
          >
            Build your Audience
          </button>
        </div>
      </PageTemplate>
    );
  }
  return (
    <PageTemplate title={"Voices"}>
      <PollVerseTabbedPageContent tab={"compare"}>
        <>
          <FiltersBar
            disableSources={true}
            disableAudiences={false}
            disableBrands={false}
            disableThemes={true}
            disableSentiments={true}
            disableStates={true}
            enableStarredAccounts={false}
            filters={{
              audiences: [
                {
                  id: "patient",
                  name: "Patient",
                  payload: {
                    id: "patient",
                    name: "Patient",
                  },
                },
              ],
              brandAccounts: [],
              brands: [
                {
                  id: "aimovig",
                  name: "Aimovig",
                  payload: {
                    id: "aimovig",
                    name: "Aimovig",
                  },
                },
              ],
              engagementsLikelihood: [],
              sentiments: [],
              source: {
                id: "8392c05d-9a21-4547-98a6-cc9d1c1e94de",
                name: "Twitter",
                color: "#FF0000",
              },
              sources: [],
              themes: [],
              usStates: [],
            }}
          />
          <div
            className="pollverse-voices-screen-container"
            id="pollverse-voices-compare"
          >
            {isVoiceSupported && (
              <div className="pollverse-voices-body">
                <div className="pollverse-voices-question-answer">
                  <div className="pollverse-voices-question">
                    <select
                      className="pollverse-voices-question-input"
                      onChange={handleInputChange}
                    >
                      <option disabled selected>
                        Select a question
                      </option>
                      <option value="question-1">
                        What do you think of Aimovig?
                      </option>
                      <option value="see-more">See More</option>
                    </select>
                    <button
                      className={
                        "pollverse-voices-question-submit" +
                        (isSubmitDisabled ? " disabled" : "")
                      }
                      onClick={() => {
                        if (!isSubmitDisabled) submitQuestion();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  {submitting && <Typing label={"typing"} />}
                  {responses.length > 0 && (
                    <>
                      <div className="section-title">Summary</div>
                      <div className="pollverse-voices-summary">
                        <div>{responses[0].response}</div>
                      </div>
                      <div className="section-title">
                        Representative answers (about 1% of all patients with
                        migraine said this)
                      </div>
                      <div className="pollverse-response-card-wrapper">
                        {responses.slice(1).map((response, index) => (
                          <ResponseCard response={response} />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      </PollVerseTabbedPageContent>
    </PageTemplate>
  );
}

function InspireMePopup({ onClose, setQuestion }) {
  const options = Object.values(POLITICAL_TOPICS);

  const [selectedPoliticalIssue, setSelectedPoliticalIssue] = useState<{
    value: string;
    label: string;
    topics: Array<String>;
  }>(options[0]);

  const handleChange = (option) => {
    setSelectedPoliticalIssue(option);
  };

  return (
    <AtlasFullScreenPopup onClose={onClose}>
      <div className="pollverse-inspire-me-popup">
        <h2>Subjects of Discussion</h2>
        <div>
          <div className="select-wrapper">
            <AtlasSelect
              width={320}
              placeholder={
                selectedPoliticalIssue
              } /** TODO: allow for default value in AtlasSelect */
              options={options}
              onChange={handleChange}
              value={selectedPoliticalIssue}
            />
          </div>
          {Object.keys(selectedPoliticalIssue["topics"]).map((topic) => (
            <TopicDropdown
              key={topic}
              setQuestion={(question) => {
                setQuestion(question);
                onClose();
              }}
              topic={topic}
              questions={selectedPoliticalIssue["topics"][topic]}
            />
          ))}
        </div>
      </div>
    </AtlasFullScreenPopup>
  );
}

function TopicDropdown({ topic, questions, setQuestion }) {
  const [showQuestions, setShowQuestions] = useState<boolean>(false);

  return (
    <>
      <div
        onClick={() => setShowQuestions(!showQuestions)}
        className="pollverse-inspire-me-popup-topic"
      >
        <span>{topic}</span> <i>&#8964;</i>
      </div>
      {showQuestions && (
        <ol className="pollverse-inspire-me-popup-questions">
          {questions.map((question) => (
            <li onClick={() => setQuestion(question)}>
              <div>
                <div>{question}</div>
                <div>
                  <FontAwesomeIcon icon={faCopyRegular} />
                </div>
              </div>
            </li>
          ))}
        </ol>
      )}
    </>
  );
}

const SelectDate = ({ options, value, onChange }) => {
  return (
    <Select
      unstyled
      onChange={onChange}
      options={options}
      value={value}
      isSearchable={false}
      styles={{
        container: (baseStyle, state) => ({
          ...baseStyle,
          display: "inline-block",
        }),
        control: (baseStyle, state) => ({
          ...baseStyle,
          margin: "0",
          padding: "0 5px",
          border: "1px solid #1C3A4A",
          borderRadius: "15px",
          cursor: "pointer",
        }),
        indicatorsContainer: (baseStyle, state) => ({
          ...baseStyle,
          color: "#8D9CA6",
          marginLeft: "5px",
        }),
        menu: (baseStyle, state) => ({
          ...baseStyle,
          background: "#112936",
          border: "1px solid #1C3A4A",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.45)",
          borderRadius: "14px",
          width: "max-content",
          minWidth: "100%",
          padding: "15px",
        }),
        option: (baseStyle, state) => ({
          ...baseStyle,
          margin: "10px 0",
          fontWeight: state.isFocused ? "bold" : "400",
          cursor: "pointer",
        }),
        singleValue: (baseStyle, state) => ({
          ...baseStyle,
          color: "#78D2F1",
          textDecoration: "underline",
          cursor: "pointer",
        }),
      }}
    />
  );
};

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function dateToOption(date: AtlasMach.IDate): {
  value: string;
  label: string;
  payload: AtlasMach.IDate;
} {
  return {
    value: `${MONTHS[date.month]} ${date.year}`,
    label: `${MONTHS[date.month]} ${date.year}`,
    payload: date,
  };
}

// Generate a list of all month starting at fromDate and ending at toDate (inclusive).
function generateDateRange(
  fromDate: AtlasMach.IDate,
  toDate: AtlasMach.IDate
): AtlasMach.IDate[] {
  let res: AtlasMach.IDate[] = [];
  for (var year = fromDate.year; year <= toDate.year; year++) {
    for (var month = 0; month <= 11; month++) {
      if (year == fromDate.year && month < fromDate.month) continue;
      if (year == toDate.year && month > toDate.month) continue;
      res.push({ year, month });
    }
  }
  return res;
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    brands: state.data.brands,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoicesCompareScreen);
