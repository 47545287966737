import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";

import { UserManager } from "utils/authConst";

import WithLoggedIn from "components/WithLoggedIn";

import MenuPage from "pages/MenuPage";

import SummaryScreen from "pages/ListeningModule/SummaryScreen";
import ContentFeedScreen from "pages/ListeningModule/ContentFeedScreen";
import ThemesFitGapScreen from "pages/ListeningModule/ThemesFitGapScreen";
import BenchmarkingScreen from "pages/ListeningModule/BenchmarkingScreen";

import NetworkMapScreen from "pages/DOLModule/NetworkMapScreen";
import PathwaysScreen from "pages/DOLModule/PathwaysScreen";
import ConversationalMatrixScreen from "pages/DOLModule/ConversationalMatrixScreen";
import CentricityReportScreen from "pages/DOLModule/CentricityReportScreen";
import StarredAccountsScreen from "pages/DOLModule/StarredAccountsScreen";

import PatientsScreen from "pages/VoicesModule/PatientsScreen";
import HCPsScreen from "pages/VoicesModule/HCPsScreen";
import IndividualAccountScreen from "pages/VoicesModule/IndividualAccountScreen";
import DebateScreen from "pages/VoicesModule/DebateScreen";
import SavedAnswersScreen from "pages/VoicesModule/SavedAnswersScreen";

import AudienceVoices2Screen from "pages/VoicesModule2/AudienceVoicesScreen";
import IndividualAccount2Screen from "pages/VoicesModule2/IndividualAccountScreen";

import EmailGeneratorScreen from "pages/ContentStudioModule/EmailGeneratorScreen";

import PollVerseExtraVoicesScreen from "pages/PollVerseModule/ExtraVoicesModule/VoicesScreen";
import PollVerseVoicesScreen from "pages/PollVerseModule/VoicesModule/VoicesScreen";
import PollVerseCompareScreen from "pages/PollVerseModule/CompareModule/CompareScreen";
import PollVerseNetworkMapScreen from "pages/PollVerseModule/NetworkMapModule/NetworkMapScreen";
import PollVerseHeatMapScreen from "pages/PollVerseModule/HeatMapModule/HeatMapScreen";
import PollVerseMenuPage from "pages/PollVerseModule/MenuPage";

import VoicesCompareScreen from "pages/PollVerseModule/VoicesCompareModule/VoicesCompareScreen";

import FashionVerseCompareScreen from "pages/PollVerseModule/FashionDebateModule/CompareScreen";

import EventsCalendarScreen from "pages/EventsModule/EventsCalendarScreen";

import Page404 from "pages/Page404";

function HealthSocialApp({ topic }) {
  const audienceBots = topic.topicBots
    .map((tb) => tb.bot)
    .filter((b) => b.topicAudienceId)
    .map((b) => ({
      id: b.id,
      audience: topic.audiences.find((a) => a.id == b.topicAudienceId).name,
    }));
  return (
    <Routes>
      <Route path={"/"} element={<MenuPage />} />
      <Route path={"/home"} element={<MenuPage />} />

      <Route
        path={"/listening"}
        element={<Navigate to="/hs/listening/summary" />}
      />
      <Route path={"/listening/summary"} element={<SummaryScreen />} />
      <Route path={"/listening/content-feed"} element={<ContentFeedScreen />} />
      <Route
        path={"/listening/themes-fit-gap"}
        element={<ThemesFitGapScreen />}
      />
      <Route
        path={"/listening/benchmarking"}
        element={<BenchmarkingScreen />}
      />

      <Route path={"/dol"} element={<Navigate to="/hs/dol/network-map" />} />
      <Route path={"/dol/network-map"} element={<NetworkMapScreen />} />
      <Route path={"/dol/pathways"} element={<PathwaysScreen />} />
      <Route
        path={"/dol/conversational-matrix"}
        element={<ConversationalMatrixScreen />}
      />
      <Route
        path={"/dol/centricity-report"}
        element={<CentricityReportScreen />}
      />
      <Route
        path={"/dol/starred-accounts"}
        element={<StarredAccountsScreen />}
      />

      <Route path={"/voices"} element={<Navigate to="/hs/voices/patient" />} />
      {audienceBots.map((ab) => (
        <>
          <Route
            path={"/voices/" + ab.audience.toLowerCase()}
            element={<PatientsScreen botId={ab.id} audience={ab.audience} />}
          />
        </>
      ))}
      <Route
        path={"/voices/starred-accounts"}
        element={<IndividualAccountScreen />}
      />
      <Route path={"/voices/debate"} element={<DebateScreen />} />
      <Route path={"/voices/saved"} element={<SavedAnswersScreen />} />

      <Route path={"/voices/audiences-2"} element={<AudienceVoices2Screen />} />
      <Route
        path={"/voices/starred-accounts-2"}
        element={<IndividualAccount2Screen />}
      />

      <Route path={"/voices/compare"} element={<VoicesCompareScreen />} />

      <Route
        path={"/content-studio"}
        element={<Navigate to="/hs/content-studio/message-generator" />}
      />
      <Route
        path={"/content-studio/message-generator"}
        element={<EmailGeneratorScreen />}
      />

      <Route
        path={"/events/events-calendar"}
        element={<EventsCalendarScreen />}
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

function PollverseApp() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/pollverse/home" />} />

      <Route path={"/voices/legislators"} element={<PollVerseVoicesScreen />} />
      <Route
        path={"/compare/debate-showdown"}
        element={<PollVerseCompareScreen />}
      />
      <Route path={"/network-map"} element={<PollVerseNetworkMapScreen />} />
      <Route path={"/heat-map"} element={<PollVerseHeatMapScreen />} />
      <Route path={"/home"} element={<PollVerseMenuPage />} />

      <Route path={"/extra-voices"} element={<PollVerseExtraVoicesScreen />} />

      <Route
        path={"/fashion/debate-showdown"}
        element={<FashionVerseCompareScreen />}
      />

      <Route path="/pollverse/*" element={<Page404 />} />
    </Routes>
  );
}

const LoggedInHealthSocialApp = WithLoggedIn(HealthSocialApp);
const LoggedInPollverseApp = WithLoggedIn(PollverseApp);

// Note that IdentityServer suggests the callback URL (the first two components below)
// Could just use a new UserMnager with response_mode: "query" (without the rest of settings)
// and then do a full reload of the page with window.location = "/". As this would reload the
// page, it doesn't matter that we re-instantiate the same user manager again instead of using
// the global one, so maybe that is cleaner? Currently we share the same global UserManager.
export const HSLoginCallback = () => {
  const navigate = useNavigate();
  console.log("hs login callback");
  useEffect(() => {
    UserManager.signinRedirectCallback()
      .then(() => {
        navigate("/hs/home", { replace: true });
      })
      .catch((e) => {
        console.error(e);
        UserManager.signoutRedirect();
        UserManager.removeUser();
        UserManager.revokeAccessToken();
        UserManager.clearStaleState();
      });
  }, []);
  return <div>Redirecting</div>;
};

export const PollverseLoginCallback = () => {
  const navigate = useNavigate();
  console.log("pollverse login callback");
  useEffect(() => {
    UserManager.signinRedirectCallback()
      .then(() => {
        navigate("/pollverse", { replace: true });
      })
      .catch((e) => {
        console.error(e);
        UserManager.signoutRedirect();
        UserManager.removeUser();
        UserManager.revokeAccessToken();
        UserManager.clearStaleState();
      });
  }, []);
  return <div>Redirecting</div>;
};

// This is a bit of a hack, but the goal of this page is to behave as a login page, but since
// login is outside our control (on identity server) we need to redirect there with the right redirect URI.
// In order to do that, we log out first, then this redirects to /hs, which itself will check if we are logged
// in (we are not) and will do the right redirect to the identity server. We don't technically need to log out,
// but we do it because we share credentials between atlas and HS, and if an Atlas user were to click log in
// on HS website and be already logged in in Atlas, they would just land in the app, which we want to avoid, so we
// force a logout when you visit the /hs/login page.
export const HSLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    UserManager.getUser().then((user) => {
      if (user) {
        UserManager.signoutRedirect();
      } else {
        navigate("/hs");
      }
    });
  }, []);
  return <div>Redirecting</div>;
};

function router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/hs/login" element={<HSLogin />} />
        <Route path="/hs/callback" element={<HSLoginCallback />} />
        <Route
          path="/pollverse/callback"
          element={<PollverseLoginCallback />}
        />

        <Route path="/hs/*" element={<LoggedInHealthSocialApp />} />
        <Route path="/pollverse/*" element={<LoggedInPollverseApp />} />

        <Route path="/" element={<Navigate to="/hs" />} />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default router;
