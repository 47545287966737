import { connect } from "react-redux";
import AudienceAnalysis from "./AudienceAnalysis";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  aaData: state.data.audienceAnalysis,
  authToken: state.data.auth_token,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceAnalysis);
