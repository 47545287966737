import React, { useMemo } from "react";

const radius = 13;

interface ICircleProgress {
  percent: number;
  color: string;
}

export default function CircleProgress({ percent, color }: ICircleProgress) {
  const arcPos = useMemo(() => {
    const angle = (2 * Math.PI * percent) / 100;
    return {
      dx: Math.sin(angle) * radius,
      dy: 13 - Math.cos(angle) * radius,
    };
  }, [percent]);
  return (
    <div className="circle-progress">
      <svg height="30" width="30">
        <circle
          cx="15"
          cy="15"
          r="14"
          stroke={color}
          strokeWidth="1"
          fill="transparent"
        />
        <circle
          cx="15"
          cy="15"
          r="12"
          stroke={color}
          strokeWidth="1"
          fill="transparent"
        />
        {percent === 100 ? (
          <circle
            cx="15"
            cy="15"
            r="13"
            stroke={color}
            strokeWidth="2"
            fill="transparent"
          />
        ) : (
          <path
            d={`M 15,2 a13,13 0 ${percent > 50 ? 1 : 0},0 ${-arcPos.dx},${
              arcPos.dy
            }`}
            style={{ fill: "none", stroke: color, strokeWidth: 2 }}
          />
        )}
      </svg>
    </div>
  );
}
