import React, { useState, useEffect } from "react";
import AtlasSelect from "../../components/AtlasSelect";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { ReactComponent as StarIcon } from "assets/icons/star.svg";

import { convertToDt } from "utils/convertData";

import PageTemplate from "pages/PageTemplate";
import ContentStudioTabbedPageContent from "./ContentStudioTabbedPageContent";

import Typing from "../../components/Typing";

const EMAIL_TEMPLATES = {
  "ae2d6866-9467-4eca-9278-b598ebc4e252": {
    "co-creation": `Dear $ACCOUNT_NAME,

    We appreciate your openness in sharing your experiences with Chronic Migraine on social media. As a company dedicated to providing relief for patients like you, we would like to offer you information on our latest medication/treatment option for Chronic Migraine.

    We understand that you have tried various medications, supplements, devices, and lifestyle changes to manage your condition. Our medication/treatment, $DRUG, may be able to offer additional symptom relief that you have not yet experienced.

    We would like to collaborate with you to co-create $CONTENT_TYPE $CONTENT_OBJECTIVE. We believe that your perspective as a patient influencer is incredibly valuable in helping other patients understand what it is like to live with Chronic Migraine and how $DRUG can potentially help them.

    During our collaboration, we would provide you with more information on $DRUG, its benefits, potential side effects, and the expected outcomes of treatment. Additionally, we would be happy to discuss the science behind the medication/treatment, as we know you are interested in learning more about the latest innovations in Chronic Migraine treatment.

    We understand your concern about collaborations with pharmaceutical companies and would like to assure you that our primary goal is to provide patients with accurate and up-to-date information on their treatment options. Our hope is that by collaborating with you, we can provide patients with valuable insights and resources that help them make informed decisions about their health.

    If you are interested in collaborating with us, we would be happy to discuss the details further. We are open to any ideas you may have on how we can work together to promote $DRUG in an ethical and responsible manner.

    Thank you for your time and consideration. We hope to hear back from you soon.

    Best regards,
    [Sender Name]
    [Sender Title]`,

    "event-invitation": `Dear $ACCOUNT_NAME,

    We appreciate your openness in sharing your experiences with Chronic Migraine on social media. As a company dedicated to providing relief for patients like you, we are excited to invite you to our upcoming $EVENT_NAME on $EVENT_DATE at $EVENT_LOCATION. 

    This event will be an opportunity to learn more about $DRUG, and to hear from healthcare professionals and patients who have already experienced the benefits of our medication/treatment.

    During the event, we will provide information on $DRUG, its benefits, potential side effects, and the expected outcomes of treatment. Additionally, we will have healthcare professionals available to discuss the science behind the medication/treatment, as we know you are interested in learning more about the latest innovations in Chronic Migraine treatment.

    We understand your concerns about promoting pharmaceutical companies and would like to assure you that our primary goal is to provide patients with accurate and up-to-date information on their treatment options. Our hope is that by inviting you to this event, you can learn more about $DRUG and make an informed decision about whether it is the right choice for you.

    We are open to collaborations that help to inform and educate patients. If you have any ideas on how we can work together, we would be happy to discuss them further.

    Thank you for your time and consideration. We hope to hear back from you soon and look forward to potentially meeting you at $EVENT_NAME.

    Best regards,
    [Sender Name]
    [Sender Title]`,
  },
  "58dc0f0e-ed3e-4d12-863c-97ce8d92319b": {
    "co-creation": `Dear $ACCOUNT_NAME,

    I hope this email finds you well. As a neurologist with a passion for advocating for patients and developing curriculum for medical students, we believe that you would be an ideal partner to collaborate with on the development of $CONTENT_TYPE content for $DRUG, our innovative medication for migraine.

    We are currently seeking to create $CONTENT_TYPE content that will help patients and healthcare providers better understand the benefits of $DRUG in preventing migraine and managing its symptoms. Specifically, we are interested in developing $CONTENT_OBJECTIVE.

    Given your expertise in the field and your interest in patient education, we believe that you would be an excellent partner to co-create this content with us. As a partner, you would have the opportunity to contribute your insights and expertise to the development of the $CONTENT_TYPE content and to help shape the messaging and tone to ensure that it resonates with healthcare providers and patients alike.

    We believe that this collaboration could be an exciting opportunity for us to work together to improve patient outcomes and to provide valuable educational resources to the migraine community. If you are interested in learning more about this opportunity, please let us know, and we would be happy to provide more information and discuss next steps.

    Thank you for your time and consideration, and we look forward to hearing from you soon.

    Best regards,
    [Sender Name]
    [Sender Title]`,

    "event-invitation": `Dear $ACCOUNT_NAME,

    I hope this email finds you well. I am writing on behalf of [Pharma Company Name], and I wanted to extend an invitation to you to attend the upcoming $EVENT_NAME, which will take place from $EVENT_LOCATION in $EVENT_LOCATION.

    As a general neurologist with a passion for headache and migraine, we believe that you would find the conference particularly relevant and informative. This year's conference will feature a diverse range of speakers and sessions that will cover the latest research, treatments, and best practices for managing and treating migraine and headache disorders.

    Some of the highlights of this year's conference include:

    Keynote presentations by leading experts in the field, including [Keynote Speaker Name], who will be discussing [Keynote Speaker Theme/ e.g., the latest developments in preventive therapies for migraine].

    Panel discussions on topics such as [Panel Discussion Topics/ e.g., managing medication overuse and the use of technology in headache and migraine care].

    Interactive workshops and case-based learning sessions that will provide practical insights and strategies for managing challenging cases.

    In addition to the scientific program, the conference will also provide an excellent opportunity to network and connect with other healthcare providers who share your interest in headache and migraine care. We believe that your participation would be valuable and would contribute to the success of the conference.

    If you are interested in attending the conference, we would be happy to provide more information and to assist with registration and travel arrangements. Please let us know if you have any questions or if we can provide any additional information.

    Thank you for your time and consideration, and we hope to hear from you soon.

    Best regards,
    [Sender Name]
    [Sender Title]`,
  },
};

function fillTemplate(
  template,
  account,
  company,
  drug,
  eventName,
  eventDate,
  eventLocation,
  contentType,
  contentObjective
) {
  return template
    .replaceAll("$ACCOUNT_NAME", account)
    .replaceAll("$COMPANY", company)
    .replaceAll("$DRUG", drug)
    .replaceAll("$EVENT_NAME", eventName)
    .replaceAll("$EVENT_DATE", eventDate)
    .replaceAll("$EVENT_LOCATION", eventLocation)
    .replaceAll("$CONTENT_TYPE", contentType.toLowerCase())
    .replaceAll("$CONTENT_OBJECTIVE", contentObjective.toLowerCase());
}

function emailTextToHtml(email) {
  const paragraphs = email.split("\n\n");
  return (
    <>
      {paragraphs.map((p) => (
        <p>
          {p.split("\n").map((line) => (
            <span>
              {line}
              <br />
            </span>
          ))}
        </p>
      ))}
    </>
  );
}

function EmailGeneratorScreen({ topic, filters, authToken }) {
  const [generatedEmail, setGeneratedEmail] = useState<string>("");

  const accountOptions = [
    {
      value: "ae2d6866-9467-4eca-9278-b598ebc4e252",
      label: "@beth_morton (Patient)",
      name: "Beth Morton",
    },
    {
      value: "58dc0f0e-ed3e-4d12-863c-97ce8d92319b",
      label: "@CSWhiteMD (HCP)",
      name: "Dr Courtney S White",
    },
  ];
  const [selectedAccount, setSelectedAccount] = useState<{
    value: string;
    label: string;
    name: string;
  }>(accountOptions[0]);

  const ctaOptions = [
    {
      value: "co-creation",
      label: "Invite to collaborate",
    },
    {
      value: "event-invitation",
      label: "Invite to an event",
    },
  ];
  const [selectedCta, setSelectedCta] = useState<{
    value: string;
    label: string;
  }>(ctaOptions[0]);

  const [eventName, setEventName] = useState<string>(
    "Resident Education Program"
  );
  const [eventDate, setEventDate] = useState<string>("Oct 13-15, 2023");
  const [eventLocation, setEventLocation] = useState<string>("Los Angeles, CA");
  const [contentType, setContentType] = useState<string>("educational");
  const [contentObjective, setContentObjective] = useState<string>(
    "Content that highlights the functional symptoms of migraine and the role that preventive medications can play in reducing the frequency and severity of these symptoms"
  );

  const [submitting, setSubmitting] = useState<boolean>(false);

  const isSubmitDisabled = submitting;

  const handleChangeAccount = (op) => {
    if (op.value != selectedAccount.value) setGeneratedEmail("");
    setSelectedAccount(op);
  };
  const handleChangeCta = (op) => {
    if (op.value != selectedCta.value) setGeneratedEmail("");
    setSelectedCta(op);
  };
  const generateEmail = () => {
    const template = EMAIL_TEMPLATES[selectedAccount.value][selectedCta.value];
    setSubmitting(true);
    setGeneratedEmail("");
    setTimeout(() => {
      setSubmitting(false);
      setGeneratedEmail(
        fillTemplate(
          template,
          selectedAccount.name,
          "Amgen",
          "Aimovig",
          eventName,
          eventDate,
          eventLocation,
          contentType,
          contentObjective
        )
      );
    }, 3500);
  };

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(generatedEmail).then(
      function () {
        toast("Email copied", {
          position: "bottom-right",
          autoClose: 1000,
          type: toast.TYPE.INFO,
        });
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  return (
    <PageTemplate title="Message Generator">
      <ContentStudioTabbedPageContent tab="message-generator">
        <div className="content-studio-email-generator-container">
          <div className="content-studio-email-generator-settings">
            <label>Recipient</label>
            <div style={{ marginBottom: "20px" }}>
              <AtlasSelect
                placeholder={"Choose an account"}
                options={accountOptions}
                onChange={handleChangeAccount}
                value={selectedAccount}
                width={"100%"}
              />
            </div>
            <label>Call to action</label>
            <div style={{ marginBottom: "20px" }}>
              <AtlasSelect
                placeholder={"Choose a call-to-action"}
                options={ctaOptions}
                onChange={handleChangeCta}
                value={selectedCta}
                width={"100%"}
              />
            </div>
            {selectedCta.value == "co-creation" && (
              <>
                <label>Content output</label>
                <input
                  placeholder={
                    "Type the type of content you want to collaborate on here"
                  }
                  className="hs-input"
                  value={contentType}
                  onChange={(ev) => setContentType(ev.target.value)}
                />
                <label>Content objective</label>
                <textarea
                  placeholder={
                    "Type the objective of the content you want to collaborate on here"
                  }
                  className="hs-textarea"
                  value={contentObjective}
                  onChange={(ev) => setContentObjective(ev.target.value)}
                />
              </>
            )}
            {selectedCta.value == "event-invitation" && (
              <>
                <label>Event name:</label>
                <input
                  placeholder={"Type the event name here"}
                  className="hs-input"
                  value={eventName}
                  onChange={(ev) => setEventName(ev.target.value)}
                />
                <label>Event date:</label>
                <input
                  placeholder={"Type the event date here"}
                  className="hs-input"
                  value={eventDate}
                  onChange={(ev) => setEventDate(ev.target.value)}
                />
                <label>Event location:</label>
                <input
                  placeholder={"Type the event location here"}
                  className="hs-input"
                  value={eventLocation}
                  onChange={(ev) => setEventLocation(ev.target.value)}
                />
              </>
            )}

            <div className="content-studio-email-generator-button">
              <button
                className={
                  "hs-button primary" + (isSubmitDisabled ? " disabled" : "")
                }
                onClick={() => {
                  if (!isSubmitDisabled) generateEmail();
                }}
              >
                Generate Email
              </button>
            </div>
          </div>

          <div className="content-studio-email-generator-result">
            {submitting && (
              <div style={{ width: "150px", margin: "auto" }}>
                <Typing label={"generating"} />
              </div>
            )}
            {generatedEmail && (
              <>
                <label>Generated email</label>
                <div className="content-studio-email-generator-email">
                  {emailTextToHtml(generatedEmail)}
                </div>
                <button
                  className="hs-button secondary"
                  onClick={handleCopyEmail}
                >
                  Copy
                </button>
              </>
            )}
          </div>
        </div>
      </ContentStudioTabbedPageContent>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailGeneratorScreen);
