import React, { useState, ReactElement } from "react";
import { useLocation, Link } from "react-router-dom";

import AtlasCalendarSelect from "components/AtlasCalendarSelect";

import "./styles.scss";

interface ITabbedPageContent {
  tabs: {
    id: string;
    label: string;
    withCalendar?: boolean;
    widget?: React.ReactNode;
    disabled?: boolean;
  }[];
  activeTab: number;
  // We have two modes, one is deprecated with the list of children, the other is the current refactoring with a single child and the navigation handled outside.
  children?: React.ReactNode;
}

/*
 * This provides a re-usable template for any page that should contain tabs in the main body.
 * This implements the active tab and a consistent base style for the tabs and content border.
 */
function TabbedPageContent({ tabs, activeTab, children }: ITabbedPageContent) {
  //const [activeTab, setActiveTab] = useState<string>(tabs[initialTab])

  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const pathPrefix = pathParts.slice(0, pathParts.length - 1).join("/");

  let childrenArray = React.Children.toArray(children);

  // TODO: Would be nice if the tabs props where embeded in the children props, so that we don't need to keep them in sync?
  if (childrenArray.length != tabs.length && childrenArray.length != 1) {
    console.error(
      "Invalid TabbedPagedContent component - The number of children must match the number of tabs in props"
    );
    // TODO: crash?
    return <></>;
  }

  if (activeTab < 0 || activeTab >= tabs.length) {
    console.error("Invalid active tab index: " + activeTab);
    // TODO: crash?
    return <></>;
  }

  return (
    <div className="tabbed-content-container">
      <div className="tabbed-content-labels-wrapper">
        <div>
          {tabs.map((tab) =>
            tab.disabled ? (
              <a key={tab.id} className="tabbed-content-label disabled">
                {tab.label}
              </a>
            ) : (
              <Link
                to={pathPrefix + "/" + tab.id}
                key={tab.id}
                className={`tabbed-content-label${
                  tabs[activeTab].id === tab.id ? " active" : ""
                }`}
              >
                {tab.label}
              </Link>
            )
          )}
        </div>
        {tabs[activeTab].withCalendar && (
          <AtlasCalendarSelect
            style={{ display: "flex", marginLeft: "auto" }}
          />
        )}
        {tabs[activeTab].widget && (
          <div className="tabbed-content-widget">{tabs[activeTab].widget}</div>
        )}
      </div>
      <div className="tabbed-content-body-container">
        <div className="tabbed-content-body-border">
          {childrenArray.length == 1
            ? childrenArray[0]
            : childrenArray[activeTab]}
        </div>
      </div>
    </div>
  );
}

export default TabbedPageContent;
