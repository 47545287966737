import React, { useState, useEffect, useRef } from "react";

import h337 from "heatmap.js";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
//import "leaflet/dist/images/marker-shadow.png";
//import "leaflet/dist/images/marker-icon-2x.png";
import HeatmapOverlay from "leaflet-heatmap";

import { API_DOL_HCPS_AGGREGATE } from "constants/routes";

interface Coordinate {
  latitude: number;
  longitude: number;
}
interface HCPHeatMapProps {
  markerCoordinate: Coordinate;
  markerName: string;
  authToken: string;
}
function HCPHeatMap({
  markerCoordinate,
  markerName,
  authToken,
}: HCPHeatMapProps) {
  const domElement = useRef<any>(null);
  var [initialized, setInitialized] = useState(false);

  const showMap = (coordinates: Coordinate[]) => {
    var baseLayer = L.tileLayer(
      "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );
    var cfg = {
      //radius should be small ONLY if scaleRadius is true (or small radius is intended)
      // if scaleRadius is false it will be the constant radius used in pixels
      radius: 2,
      maxOpacity: 0.8,
      // scales the radius based on map zoom
      scaleRadius: true,
      // if set to false the heatmap uses the global maximum for colorization
      // if activated: uses the data maximum within the current map boundaries
      //   (there will always be a red spot with useLocalExtremas true)
      useLocalExtrema: false,
      // which field name in your data represents the latitude - default "lat"
      latField: "lat",
      // which field name in your data represents the longitude - default "lng"
      lngField: "lng",
      // which field name in your data represents the data value - default "value"
      //valueField: "count",
    };
    const heatmapLayer = new HeatmapOverlay(cfg);
    const map = L.map("map", {
      dragging: false,
      zoomControl: false,
      scrollWheelZoom: false,
      layers: [baseLayer, heatmapLayer],
    }).setView([37.8, -96], 4);

    var data = {
      min: 0,
      max: 10,
      data: coordinates.map((coord) => ({
        lat: coord.latitude,
        lng: coord.longitude,
        value: 1,
      })),
    };
    heatmapLayer.setData(data);

    // THe cleanest is probably to include the icon from node_modules
    //const defaultIcon = new L.icon({
    //  iconUrl: require("../node_modules/leaflet/dist/images/marker-icon.png"),
    //  iconSize: [8, 8],
    //  iconAnchor: [2, 2],
    //  popupAnchor: [0, -2],
    //});
    const icon = L.icon({
      iconUrl:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=",
      iconSize: [25, 41],
      iconAnchor: [25, 20],
      popupAnchor: [-12, 0],
    });
    //const marker3 = L.marker([37.7749, -122.4194], { icon: icon }).addTo(map);
    const hcpMarker = L.marker(
      [markerCoordinate.latitude, markerCoordinate.longitude],
      { icon: icon }
    )
      .bindPopup(markerName)
      .addTo(map);
  };

  useEffect(() => {
    //console.log(window.HeatmapOverlay);
    if (!domElement) return;
    if (initialized) return;
    setInitialized(initialized);

    fetch(
      API_DOL_HCPS_AGGREGATE.replace(
        "$1",
        "A393E845-D667-4E98-977E-9EE91096AD5E"
      ),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
      .then((res) => {
        //setIsLoading(false);

        if (res.status !== 200) {
          console.error("Unexpected response code for query");
          console.log(res);
        }
        return res.json();
      })
      .then((jsonData) => {
        console.log(jsonData);
        showMap(jsonData);
      });
  }, [domElement]);

  return (
    <div
      id="map"
      style={{
        margin: "40px auto",
        width: "750px",
        height: "380px",
        border: "1px black solid",
        borderRadius: "20px",
      }}
      ref={domElement}
    ></div>
  );
}

export default HCPHeatMap;
