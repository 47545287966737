/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";

import TabbedPageContent from "components/TabbedPageContent";
import NetworkMapScreen from "./NetworkMapScreen";
import PathwaysScreen from "./PathwaysScreen";
import ConversationalMatrixScreen from "./ConversationalMatrixScreen";
import CentricityReportScreen from "./CentricityReportScreen";

import "./styles.scss";

function DOLTabbedPageContent(props) {
  const { tab } = useParams();

  const [triggerExport, setTriggerExport] = useState(false);

  const tabs = [
    {
      id: "network-map",
      label: "Network Map",
      withCalendar: true,
      onExport: undefined,
    },
    {
      id: "pathways",
      label: "Pathways",
      withCalendar: false,
      onExport: undefined,
    },
    {
      id: "conversational-matrix",
      label: "Conversational Matrix",
      withCalendar: true,
      onExport: undefined,
    },
    {
      id: "centricity-report",
      label: "Centricity Report",
      withCalendar: true,
      onExport: undefined,
    },
    {
      id: "starred-accounts",
      label: "Starred Accounts",
      withCalendar: false,
      onExport: undefined,
    },
  ];
  const activeTab = tabs.findIndex(
    (t) => (props.tab && t.id === props.tab) || t.id === tab
  );

  return (
    <TabbedPageContent tabs={tabs} activeTab={activeTab}>
      {props.children}
    </TabbedPageContent>
  );
}

export default DOLTabbedPageContent;
