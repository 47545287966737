import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  setIsLoading,
  pushChart,
  setAudienceAnalysis,
  updateTopic,
} from "redux/actions";
import { Range, getTrackBackground } from "react-range";

import { config } from "../../../config";

import debounce from "lodash/debounce";
import { Chart, FontLoader } from "regraph";
import { pageRank } from "regraph/analysis";

import LZString from "lz-string";
import { ToastContainer, toast } from "react-toastify";

import { API_CHARTDATA } from "constants/routes";
import { convertToDt, compressJson } from "utils/convertData";
import moment, { fn } from "moment";

import PageTemplate from "pages/PageTemplate";
// import DOLTabbedPageContent from "../DOLModule/DOLTabbedPageContent";
import AudienceAnalysis from "../../DOLModule/AudienceAnalysis/index";
import FiltersBar from "components/Pollverse/FiltersBar";
import CircleProgress from "../../DOLModule/CircleProgress";
import AccountPopup from "../../DOLModule/AccountPopup";

import SearchIcon from "assets/images/icons/search.svg";
import AccountIcon from "assets/images/icons/account.svg";
import ArrowUpIcon from "assets/images/icons/arrow-up-big.svg";
import ArrowDownIcon from "assets/images/icons/arrow-down-big.svg";
import LegendArrows from "assets/images/icons/legend-arrows.svg";
import LegendCircles from "assets/images/icons/legend-circles.svg";
import LegendThick from "assets/images/icons/legend-thick-arrow.svg";

import "./styles.scss";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "react-toastify/dist/ReactToastify.css";
import PollVerseTabbedPageContent from "./PollVerseTabbedPageContent";

const HEADER_ZOOM_GROUP_CT = 10;

interface INetworkMapScreenProps {
  setIsLoading: (loading: boolean) => void;
  topic: AtlasMach.ITopic;
  topics: AtlasMach.ITopic[];
  filters: AtlasMach.UIFilters;
  dateRange: AtlasMach.IDateRange;
  chartStack: { [key: string]: AtlasMach.IChartData };
  pushChart: (key: string, chart: AtlasMach.IChartData) => void;
  authToken: string;
  audienceAnalysisData: AtlasMach.IAASection;
  setAudienceAnalysis: (aa: AtlasMach.IAASection) => void;
  updateTopic: (topic: AtlasMach.ITopic) => void;
}

function PollVerseNetworkMapScreen({
  setIsLoading,
  topic,
  topics,
  filters,
  dateRange,
  chartStack,
  pushChart,
  authToken,
  audienceAnalysisData,
  setAudienceAnalysis,
  updateTopic,
}: INetworkMapScreenProps) {
  // TODO: move to props
  const dataAudiences = useSelector<
    AtlasMach.StoreState,
    AtlasMach.IAudience[]
  >((state) => state.data.audiences);

  // The account that we are focusing on. When set, we animate by focusing (centering) the map on the account, once
  // done, we clear it again (user is free to move so this is no longer relevant). This is different from the "selected"
  // account which remains highlighted even when the user moves around the map.
  const [focusingAccount, setFocusingAccount] = useState<
    AtlasMach.INode | undefined
  >(undefined);

  const [currentChartData, setCurrentChartData] =
    useState<AtlasMach.IChartData>();
  const [graphData, setGraphData] = useState<{ [key: string]: any }>({});

  const [positions, setPositions] = useState({});
  const [selection, setSelection] = useState({});
  const [zoom, setZoom] = useState(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [popupNode, setPopupNode] = useState<AtlasMach.INode>();

  // The annotation position for the account popup, in world coordinates (the coordinates of the account selected).
  const [annotationPosition, setAnnotationPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [suggestionList, setSuggestionList] = useState<AtlasMach.INode[]>([]);
  const [currentView, setCurrentView] = useState<any>(undefined);

  const [currentPosition, setCurrentPosition] = useState();

  const [profileCount, updateProfileCount] = useState(0);
  const [percent, setPercent] = useState(HEADER_ZOOM_GROUP_CT);
  const [sliderValue, setSliderValue] = useState<number>(HEADER_ZOOM_GROUP_CT);
  const [keyword, setKeyword] = useState("");

  const [isComputingNodesPercent, setIsComputingNodesPercent] = useState(false);
  const [isLoadingChartPost, setIsLoadingChartPost] = useState(false);
  const [isGeneratingGraphData, setIsGeneratingGraphData] = useState(false);
  const [isRenderingMap, setIsRenderingMap] = useState(false);

  const chartRef = useRef<any>();

  const handleSetAccount = (account: AtlasMach.INode) => {
    setKeyword(account.label);
    setSuggestionList([]);
    setFocusingAccount(account);
    setSelection({ [account.key]: true });
  };

  const handleExport = (setCsvData, setDownloadName, csvLink) => {
    const csv = [
      [
        "Name",
        "Account Name",
        `${filters.source.name} URL`,
        "Audience",
        "Starred (Y/N)",
        "Centricity Score",
      ],
    ];
    if (!currentChartData) return;

    const nodes = (
      filters.audiences.length === 0
        ? currentChartData.nodes
        : currentChartData.nodes.filter(
            (node) =>
              filters.audiences.findIndex((ad) => ad.id === node.audienceId) >
              -1
          )
    ).filter(
      (node) =>
        node.orderID >=
        currentChartData.percentSteps[HEADER_ZOOM_GROUP_CT - percent]
    );

    nodes.forEach((node) => {
      const ad = dataAudiences.find((da) => da.id === node.audienceId);
      csv.push([
        node.label.replace(/\n|\r/g, "").replace(/"/g, '""'),
        node.key,
        node.profileLink,
        ad ? ad.name : "",
        node.isStarred ? "Y" : "N",
        node.sizePercent.toString(),
      ]);
    });

    setCsvData(csv);
    const dt = convertToDt(dateRange.from, dateRange.to);
    const fileName = `Atlas[${filters.source.name}][${topic.name}][${moment(
      dt.dt_from
    ).format("YYYY-MM")}][${moment(dt.dt_to).format("YYYY-MM")}]`;
    setDownloadName(fileName);
    setTimeout(() => {
      csvLink.current.link.click();
      chartRef.current.export({ type: "png" }).then((exportedImage) => {
        exportedImage.download(`${fileName}.png`);
      });
    }, 500);
  };

  const calculateSizeForEachNode = async (
    items,
    data
  ): Promise<AtlasMach.IChartData[]> => {
    const normalize = function (
      nonOutlierMax,
      outlierMax,
      outlierBoundary,
      current
    ) {
      var scale = 2;
      var result = 0;
      if (current > outlierBoundary) {
        //Scale outliers to 2-8
        result = (current * 4) / outlierMax;
        result = result + 2;
      } else {
        //Scale non-outliers to 1-2
        result = (current * 2) / nonOutlierMax;
        result = result < 1 ? 1 : result;
      }
      return result / scale;
    };
    await Promise.all(
      items.map(async (itemList, i) => {
        const filtered = await pageRank(itemList);
        const filteredValues = Object.values(filtered);

        filteredValues.sort();
        var Q1pos = Math.floor((1 * filteredValues.length) / 4);
        var Q3pos = Math.floor((3 * filteredValues.length) / 4);
        var Q1 = filteredValues[Q1pos];
        var Q3 = filteredValues[Q3pos];
        var IQR = Q3 - Q1;
        var outlierBoundary = Q3 + 1.5 * IQR;

        var nonOutlierMax = 0;
        var outlierMax = Math.max(...filteredValues);
        for (var j: number = 0; j < filteredValues.length; j++) {
          if (
            filteredValues[j] > nonOutlierMax &&
            filteredValues[j] <= outlierBoundary
          ) {
            nonOutlierMax = filteredValues[j];
          }
        }

        data.chartData[i].nodes = data.chartData[i].nodes
          .map((node: AtlasMach.INode) => {
            let id = node.key;

            node.size = normalize(
              nonOutlierMax,
              outlierMax,
              outlierBoundary,
              filtered[id]
            );

            node.sizePercent = filtered[id] * 100;

            return node;
          })
          .sort(
            (a: AtlasMach.INode, b: AtlasMach.INode) =>
              a.sizePercent - b.sizePercent
          )
          .map((node, i) => ({
            ...node,
            orderID: i,
          }));
      })
    );

    data.chartData = data.chartData.map((cd: AtlasMach.IChartData) => {
      const stepsValue: number[] = [0];

      let sum = 0;
      let indicator = 1;
      for (let i = 0; i < cd.nodes.length; ++i) {
        const node = cd.nodes[i];
        sum += node.sizePercent;
        if (sum >= (100 / HEADER_ZOOM_GROUP_CT) * indicator) {
          stepsValue.push(node.orderID - 1);
          indicator++;
        }
      }

      return {
        ...cd,
        percentSteps: stepsValue,
      };
    });

    return data.chartData;
  };
  const generateGraphData = (data: { chartData: AtlasMach.IChartData[] }) => {
    if (!data || !data.chartData) {
      return;
    }
    // for each different source, we need to calculate the pageRank
    const items = data.chartData.map((sourceData) => {
      let item: { [key: string]: any } = {};

      let nodesNoDuplicate = new Map(
        sourceData.nodes.map((node) => [node.key, node])
      );

      nodesNoDuplicate.forEach((node) => {
        let gNode = {
          label: {
            text: node.label,
          },
        };
        item[node.key] = gNode;
      });

      sourceData.links.forEach((link) => {
        item[`${link.id1}-${link.id2}`] = {
          id1: link.id1,
          id2: link.id2,
        };
      });
      return item;
    });

    return items;
  };

  const loadChartPost = async (topic, from, to) => {
    if (isLoadingChartPost) return;

    setIsLoadingChartPost(true);
    const response = await fetch(API_CHARTDATA.replace("$1", topic.id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        startDate: from,
        endDate: to,
      }),
    });
    const data = await response.json();

    const items = generateGraphData(data);
    const chartdata = await calculateSizeForEachNode(items, data);
    chartdata.forEach((cd) => {
      const key = compressJson({
        topic: topic.id,
        source: cd.id,
        dateFrom: dateRange.from,
        dateTo: dateRange.to,
      });
      pushChart(key, cd);
    });
    setIsLoadingChartPost(false);

    if (chartdata.length === 0) {
      setIsLoading(false);
      toast("Data does not exists for selected dates", {
        position: "top-left",
        type: toast.TYPE.WARNING,
      });
    }
  };

  useEffect(() => {
    const dt = convertToDt(dateRange.from, dateRange.to);
    loadChartPost(topic, dt.dt_from, dt.dt_to);
    setPercent(HEADER_ZOOM_GROUP_CT);
  }, [topic, dateRange]);

  const handleSliderChange = debounce((v: number) => {
    setPercent(v);
  }, 200);

  const filteredNodes = useMemo(() => {
    if (!currentChartData) return [];

    const fNodes =
      filters.audiences.length > 0
        ? currentChartData.nodes.filter(
            (node) =>
              filters.audiences.findIndex((fda) => fda.id === node.audienceId) >
              -1
          )
        : currentChartData.nodes;

    return fNodes;
  }, [currentChartData, filters]);

  const shownNodesPercent = useMemo(() => {
    if (!currentChartData || !filteredNodes.length) {
      setIsComputingNodesPercent(false);
      return;
    }
    setIsComputingNodesPercent(true);

    let nodesAbovePercent = filteredNodes.filter(
      (node) =>
        node.orderID >=
        currentChartData.percentSteps[HEADER_ZOOM_GROUP_CT - percent]
    );

    const sum = nodesAbovePercent.reduce(
      (sum, node) => sum + node.sizePercent,
      0
    );
    // The final sum might potentially overflow 100 if there are approximation due to a large number of
    // nodes and very small centricity scores for each of them. To be safe we cap it at 100.

    setIsComputingNodesPercent(false);
    return Math.min(sum, 100).toFixed(2);
  }, [filteredNodes, percent]);

  useEffect(() => {
    const key = compressJson({
      topic: topic.id,
      source: filters.source.id,
      dateFrom: dateRange.from,
      dateTo: dateRange.to,
    });
    setCurrentChartData(chartStack[key]);
  }, [chartStack, topic, filters.source, dateRange.from, dateRange.to]);

  useEffect(() => {
    if (
      isComputingNodesPercent ||
      isLoadingChartPost ||
      isGeneratingGraphData ||
      isRenderingMap
    ) {
      setIsRenderingMap(true);
    }
  }, [
    isComputingNodesPercent,
    isLoadingChartPost,
    isGeneratingGraphData,
    isRenderingMap,
  ]);

  useEffect(() => setIsLoading(isRenderingMap), [isRenderingMap]);

  useEffect(() => {
    if (!currentChartData) {
      // setGraphData({});
      //updateProfileCount(0)
      return;
    }
    setIsGeneratingGraphData(true);

    const generateGraphData = (
      data: AtlasMach.IChartData,
      audiences,
      starred,
      percent: number
    ) => {
      //const fDA = dataAudiences.filter(da => audiences.findIndex(ad => da.id === ad) > -1)
      const items: { [key: string]: any } = {};
      const labelStyle = {
        backgroundColor: "rgba(0,0,0,0)",
        color: "white",
        center: false,
      };

      const fNodes = (
        audiences.length > 0
          ? data.nodes.filter(
              (node) =>
                audiences.findIndex((fda) => fda === node.audienceId) > -1 ||
                (starred && node.isStarred === starred)
            )
          : starred
          ? data.nodes.filter((node) => node.isStarred)
          : data.nodes
      ).filter(
        (node) =>
          node.orderID >= data.percentSteps[HEADER_ZOOM_GROUP_CT - percent]
      );

      let percentSum = 0;
      fNodes.forEach((node) => {
        let gNode: any = {
          label: {
            ...labelStyle,
            text: node.label,
          },
          size: node.size,
          color: node.color,
          sizePercent: node.sizePercent,
        };
        percentSum += node.sizePercent;
        if (node.isStarred) {
          gNode = {
            ...gNode,
            glyphs: [
              {
                position: "se",
                size: 1.5,
                color: "#FFA500",
                fontIcon: { text: "fa-star", color: "white" },
              },
            ],
          };
        }
        items[node.key] = gNode;
      });

      data.links.forEach((link) => {
        items[`${link.id1}-${link.id2}`] = {
          id1: link.id1,
          id2: link.id2,
          color: link.lineColor,
          lineStyle: link.lineStyle,
          width: link.width,
          end2: {
            arrow: link.end2HasArrow,
          },
        };
      });

      return {
        accountCount: fNodes.length,
        graphData: items,
        percent: Math.round(100 * percentSum) / 100,
      };
    };

    const gData = generateGraphData(
      currentChartData,
      filters.audiences.map((a) => a.id),
      filters.onlyStarredAccounts,
      percent
    );
    setGraphData(gData.graphData);
    updateProfileCount(gData.accountCount);

    //const keyObj: AtlasMach.UISideOption = {
    //  ...sideOption,
    //  audiences: sideOption.audiences.slice().sort(),
    //  // starred: sideOption.audiences.length === 0 ? false : sideOption.starred
    //}
    //const keyValue = LZString.compressToEncodedURIComponent(JSON.stringify({
    //  ...keyObj,
    //  percent: percent
    //}))

    //const storedPosition = localStorage.getItem(`graph-${keyValue}`)
    //// const storedPosition = chartPositionStack[keyValue]
    //if (storedPosition) {
    //  const existPosition = LZString.decompressFromEncodedURIComponent(storedPosition)
    //  if (existPosition != null) {
    //    const existPositionObj = JSON.parse(existPosition)
    //    if (currentChartData.accountCount === existPositionObj.accountCount
    //      && currentChartData.messageCount === existPositionObj.messageCount) {
    //        console.log('found in position stack', existPositionObj)
    //        setCurrentPosition(existPositionObj.position)
    //        setPositions(existPositionObj.position)
    //        return
    //    }
    //  }
    //}
    //console.log('not found in position stack')
    //setCurrentPosition(undefined)
    //setPositions({})
    setIsGeneratingGraphData(false);
  }, [currentChartData, filters, percent, topic]);

  useEffect(() => {
    const handleResize = () => {
      setSelection((prev) => {
        if (firstKey(prev) == null) {
          return prev;
        }
        return {};
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (focusingAccount && chartRef.current) {
      chartRef.current.fit([focusingAccount.key]);
      chartRef.current.ping(focusingAccount.key, {
        haloWidth: 100,
        haloRadius: 100,
        repeat: 2,
        color: "rgb(255, 255, 255)",
      });
      window.setTimeout(() => chartRef.current.zoom("in"), 500);
      window.setTimeout(() => setFocusingAccount(undefined), 1000);
    }
  }, [focusingAccount, graphData]);

  function firstKey(record) {
    if (record == null) {
      return null;
    }
    const keys = Object.keys(record);
    return keys[0];
  }

  function isNode(item) {
    return item && item.id1 == null && item.id2 == null;
  }

  const onDblClickHandler = async ({ id: newID, x, y }) => {
    const chart = chartRef.current;

    if (newID != null) {
      const selectedItemId = newID;
      const selectedItem = graphData[selectedItemId];
      if (isNode(selectedItem)) {
        setSelection({ [selectedItemId]: true });
        const worldCoordinates = chart.worldCoordinates(x, y);
        setAnnotationPosition(worldCoordinates);
        //console.log({x, y})
        //console.log(chart.viewCoordinates(worldCoordinates.x, worldCoordinates.y))
        //console.log(chart.viewCoordinates(x, y))
        //console.log(chart.worldCoordinates(x, y))
        //setAnnotationPosition(chart.viewCoordinates(
        //  x,//positions[selectedItemId].x,
        //  y, //positions[selectedItemId].y,
        //))
        //console.log(x)
        //console.log(y)
        //console.log(chart.viewCoordinates(0, 0))
        //console.log(chart.viewCoordinates(100, 100))
        //console.log(chart.viewCoordinates(1000, 1000))

        const node = currentChartData?.nodes.find((node) => node.key === newID);
        if (!node) return;
        setPopupNode(node);
      }
    }
  };
  const accountPopupPosition = annotationPosition
    ? chartRef.current.viewCoordinates(
        annotationPosition.x,
        annotationPosition.y
      )
    : undefined;

  const onChangeHandler = ({
    positions: newPositions,
    selection: newSelection,
    why,
  }: any) => {
    //if (newPositions == null && newSelection == null) {
    //  return;
    //}
    //const chart = chartRef.current;
    //if (chart == null) {
    //  return;
    //}
    //const nextPositions = newPositions || positions;
    //let nextSelection = selection;
    //let nextAnnotationPosition = annotationPosition;
    //if (newSelection) {
    //  const selectedItemId = firstKey(newSelection);
    //  if (selectedItemId == null) {
    //    nextSelection = {};
    //  }
    //} else if (annotationPosition == null && nextPositions != null) {
    //}
    //setAnnotationPosition(nextAnnotationPosition);
    //setSelection(nextSelection);
    //setPositions(nextPositions);
    //const keyObj: AtlasMach.UISideOption = {
    //  ...sideOption,
    //  audiences: sideOption.audiences.slice().sort()
    //}
    //const keyValue = LZString.compressToEncodedURIComponent(JSON.stringify({
    //  ...keyObj,
    //  percent: percent
    //}))
    //const storedPosition = localStorage.getItem(`graph-${keyValue}`)
    //if (storedPosition) {
    //  // const existPosition = chartPositionStack[keyValue]
    //  const existPosition = LZString.decompressFromEncodedURIComponent(storedPosition)
    //  if (existPosition != null) {
    //    const existPositionObj = JSON.parse(existPosition)
    //    if (why === 'auto') {
    //      console.log('auto')
    //      if (currentChartData?.accountCount === existPositionObj.accountCount
    //        && currentChartData?.messageCount === existPositionObj.messageCount) {
    //          setCurrentPosition(existPositionObj.position)
    //          setPositions(existPositionObj.position)
    //          chartRef.current.fit('all')
    //      }
    //      return
    //    } else if (why === 'user') {
    //      console.log('drag & drop', keyValue)
    //      if (!newPositions) return
    //      pushChartPosition(keyValue, newPositions)
    //      localStorage.setItem(`graph-${keyValue}`, compressJson({
    //        accountCount: currentChartData?.accountCount,
    //        messageCount: currentChartData?.messageCount,
    //        position: newPositions
    //      }))
    //      return
    //    }
    //  }
    //}
    //console.log('add new position state', keyValue)
    //pushChartPosition(keyValue, newPositions)
    //localStorage.setItem(`graph-${keyValue}`, compressJson({
    //  accountCount: currentChartData?.accountCount,
    //  messageCount: currentChartData?.messageCount,
    //  position: newPositions
    //}))
  };

  const onDragHandler = ({ type, x, y, draggedItems }) => {
    if (type !== "node" || firstKey(draggedItems) !== firstKey(selection)) {
      return;
    }

    setIsDragging(true);
    // TODO: in theory we should move the annotation position now because we are potentially moving the popup node.
  };

  const onDragEndHandler = ({ defaultPrevented }) => {
    const chart = chartRef.current;
    const selectedItemId = firstKey(selection);
    if (
      !defaultPrevented ||
      chart == null ||
      selectedItemId == null ||
      positions[selectedItemId] == null
    ) {
      setIsDragging(false);
    } else {
      const annotationPosition = chart.viewCoordinates(
        positions[selectedItemId].x,
        positions[selectedItemId].y
      );
      setIsDragging(false);
    }
  };

  const onPointerDownHandler = ({ id, x, y }) => {
    //const item = graphData[id];
    //const chart = chartRef.current;
    //if (id == null || !isNode(item) || chart == null) {
    //  return;
    //}
    //// console.log(positions, id)
    //const position = chart.viewCoordinates(positions[id].x, positions[id].y);
    //const offset = {
    //  x: x - position.x,
    //  y: y - position.y,
    //};
    //setOffset(offset)
  };

  const onViewChangeHandler = (ev) => {
    const z = ev.zoom;
    const chart = chartRef.current;
    if (chart == null) {
      return;
    }

    const selectedItemId = firstKey(selection);
    if (
      zoom === z &&
      (selectedItemId == null || positions[selectedItemId] == null)
    ) {
      return;
    }

    if (selectedItemId != null && positions[selectedItemId] != null) {
      const nextAnnotationPosition = chart.viewCoordinates(
        positions[selectedItemId].x,
        positions[selectedItemId].y
      );
      //setAnnotationPosition({
      //  x: nextAnnotationPosition.x + 280,
      //  y: nextAnnotationPosition.y + 90,
      //});
    }

    setCurrentView(ev);
  };

  const handleChangeInput = (v) => {
    setKeyword(v);
    if (!currentChartData || v === "") setSuggestionList([]);
    else
      setSuggestionList(
        filteredNodes.filter(
          (node) =>
            node.label.toLowerCase().startsWith(v.toLowerCase()) ||
            node.key.toLowerCase().startsWith(v.toLowerCase())
        )
      );
  };

  // Currently instagram has anonymous users so we cannot support this module.
  const isModuleAvailable = filters.source.name != "Instagram";

  const selectedItemId = firstKey(selection);
  if (
    topic.name !== "PollVerse" &&
    topics.findIndex((t) => t.name === "PollVerse") > -1
  ) {
    updateTopic(topics[topics.findIndex((t) => t.name === "PollVerse")]);
    // setCurrentChartData(undefined);
    return (
      <>
        <PageTemplate title="PollVerse">
          <PollVerseTabbedPageContent tab={"network-map"}>
            <div className="network-map-screen-container"></div>
          </PollVerseTabbedPageContent>
        </PageTemplate>
      </>
    );
  }

  return (
    <PageTemplate title={config.POLLVERSE.BRANDING.TITLE}>
      <PollVerseTabbedPageContent tab={"network-map"}>
        <div className="network-map-screen-container">
          {isModuleAvailable && <LegendMenu dataAudiences={dataAudiences} />}
          {audienceAnalysisData.name !== "" && popupNode && (
            <AudienceAnalysis
              onClose={() =>
                setAudienceAnalysis({
                  name: "",
                  profileImage: "",
                  Sections: [],
                })
              }
            />
          )}
          <FiltersBar
            disableBrands={true}
            disableThemes={false}
            disableSentiments={true}
            enableStarredAccounts={true}
            filters={{
              audiences: [],
              brandAccounts: [],
              brands: [],
              engagementsLikelihood: [],
              sentiments: [],
              source: {
                id: "8392c05d-9a21-4547-98a6-cc9d1c1e94de",
                name: "Twitter",
                color: "#FF0000",
              },
              sources: [],
              themes: [
                {
                  id: "3d7e684e-5e75-4992-9c62-e67d95d17bc2",
                  name: "Gun Control",
                  color: "rgba(228,121,121,1)",
                },
              ],
              usStates: filters.usStates,
            }}
          />

          {/* {!isModuleAvailable && <ModuleNotAvailable />} */}
          {isModuleAvailable && (
            <>
              <div className="network-map-body" style={{ height: "900px" }}>
                <div className="search-bar">
                  <img src={SearchIcon} alt="search" />
                  <input
                    className="atlas-input"
                    placeholder="Search Accounts"
                    value={keyword}
                    onChange={(e) => handleChangeInput(e.target.value)}
                  />
                  {suggestionList.length > 0 && (
                    <div className="suggestion-list">
                      {suggestionList.map((account, idx) => (
                        <div
                          className="suggestion-item"
                          key={`suggestion-item-${idx}`}
                          onClick={() => handleSetAccount(account)}
                        >
                          {account.label} ({account.key})
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="status">
                  <div className="progress-status">
                    <div className="slider-wrapper">
                      <Range
                        step={1}
                        min={1}
                        max={HEADER_ZOOM_GROUP_CT}
                        values={[sliderValue]}
                        onChange={(values) => {
                          setSliderValue(values[0]);
                          handleSliderChange(values[0]);
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: "36px",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              ref={props.ref}
                              style={{
                                height: "5px",
                                width: "100%",
                                borderRadius: "4px",
                                background: getTrackBackground({
                                  values: [sliderValue],
                                  colors: ["#78D2F1", "#ccc"],
                                  min: 0,
                                  max: HEADER_ZOOM_GROUP_CT,
                                }),
                                alignSelf: "center",
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ props }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "24px",
                              width: "24px",
                              borderRadius: "12px",
                              backgroundColor: "#78D2F1",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div
                      className="circle-wrapper"
                      data-tip="Total centricity score in current view"
                    >
                      <CircleProgress
                        percent={Number(shownNodesPercent)}
                        color="#78D2F1"
                      />
                      <span className="span-progress">
                        {shownNodesPercent}%
                      </span>
                    </div>
                  </div>
                  <div
                    className="users-status"
                    data-tip="Total Accounts in current view"
                  >
                    <img src={AccountIcon} alt="account" />
                    <span>{profileCount}</span>
                  </div>
                </div>
                <FontLoader
                  config={{ custom: { families: ["Font Awesome 6 Free"] } }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "calc(100% - 40px)",
                      left: "0",
                      top: "40px",
                    }}
                  >
                    <Chart
                      ref={chartRef}
                      style={{
                        width: "100%",
                        height: "100%",
                        left: "0",
                        top: "0",
                        boxSizing: "border-box",
                        position: "relative",
                      }}
                      items={graphData}
                      selection={selection}
                      positions={currentPosition}
                      onDoubleClick={onDblClickHandler}
                      onChange={(event) => {
                        if (typeof currentChartData !== "undefined") {
                          setIsRenderingMap(false);
                        }
                      }}
                      onDrag={onDragHandler}
                      onDragEnd={onDragEndHandler}
                      onPointerDown={onPointerDownHandler}
                      onViewChange={onViewChangeHandler}
                      options={{
                        navigation: {
                          position: "se",
                        },
                        overview: false,
                        backgroundColor: "rgba(0,0,0,0)",
                        iconFontFamily: "Font Awesome 6 Free",
                        minZoom: 0.005,
                        fit: "all",
                        selection: {
                          color: "rgb(255,255,255)",
                          labelColor: "black",
                        },
                      }}
                      onWheel={({ preventDefault }) => {
                        if (isDragging) preventDefault();
                      }}
                    />
                  </div>
                </FontLoader>
                {accountPopupPosition && popupNode && (
                  <AccountPopup
                    topic={topic}
                    source={filters.source}
                    dateRange={dateRange}
                    node={popupNode}
                    isStarred={popupNode.isStarred}
                    onToggleStar={() => {
                      setCurrentChartData((prev) => {
                        if (!prev) return prev;
                        const updatedChartData = {
                          ...prev,
                          nodes: prev.nodes.map((node) =>
                            node.accountId === popupNode?.accountId
                              ? { ...node, isStarred: !popupNode.isStarred }
                              : node
                          ),
                        };
                        return updatedChartData;
                      });
                      setPopupNode({
                        ...popupNode,
                        isStarred: !popupNode.isStarred,
                      });
                    }}
                    setIsLoading={setIsLoading}
                    authToken={authToken}
                    position={{
                      x: accountPopupPosition.x + 50,
                      y: accountPopupPosition.y,
                    }}
                    onClose={() => setPopupNode(undefined)}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </PollVerseTabbedPageContent>
    </PageTemplate>
  );
}

interface ILegendMenu {
  dataAudiences: AtlasMach.IAudience[];
}

function LegendMenu({ dataAudiences }: ILegendMenu) {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className="legend-container">
      <div className="shown-menu" onClick={() => setShow(true)}>
        <span>Legend</span>
        <img src={ArrowUpIcon} alt="arrow" />
      </div>
      {show && (
        <div className="hidden-menu">
          <div className="legend-indicator" onClick={() => setShow(false)}>
            <img src={ArrowDownIcon} alt="arrow" />
          </div>
          <div className="symbol-container">
            <div className="symbol">
              <img src={LegendArrows} alt="legend arrow" />
              <div className="symbol-text">
                <div className="symbol-title">Arrows</div>
                <div className="symbol-desc">
                  Shows direction of the relationship
                </div>
              </div>
            </div>
            <div className="symbol">
              <img src={LegendThick} alt="legend arrow" />
              <div className="symbol-text">
                <div className="symbol-title">Arrow Thickness</div>
                <div className="symbol-desc">Represents volume of messages</div>
              </div>
            </div>
            <div className="symbol">
              <img src={LegendCircles} alt="legend arrow" />
              <div className="symbol-text">
                <div className="symbol-title">Circle Size</div>
                <div className="symbol-desc">
                  Calculated based on centricity
                </div>
              </div>
            </div>
          </div>
          <div className="color-container">
            {dataAudiences.map((da) => (
              <div className="color-item" key={`legend-${da.id}`}>
                <div className="color-box" style={{ background: da.color }} />
                <div className="color-name">{da.name}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");

  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    topics: state.data.topics,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    chartStack: state.data.chartStack,
    audienceAnalysisData: state.data.audienceAnalysis,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {
  setIsLoading,
  pushChart,
  setAudienceAnalysis,
  updateTopic,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PollVerseNetworkMapScreen);
