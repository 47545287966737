import React, { useState, useEffect } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Select, {
  components,
  ValueContainerProps,
  PlaceholderProps,
} from "react-select";
import { connect } from "react-redux";

import {
  API_VOICES_PATIENTS,
  API_VOICES_SAVED_ANSWERS,
  API_VOICES_SAVED_ANSWER,
} from "constants/routes";

import { ReactComponent as StarIcon } from "assets/icons/star.svg";

import { convertToDt } from "utils/convertData";

import ModuleNotAvailable from "components/ModuleNotAvailable";
import PageTemplate from "pages/PageTemplate";
import VoicesTabbedPageContent from "./VoicesTabbedPageContent";
import Typing from "../../components/Typing";

interface IExamplePost {
  permalink: string;
  text: string;
}
interface IAudienceResponse {
  response: string;
  examplePosts: IExamplePost[];
}

function AudienceVoicesScreen({
  selectWidget,
  isGlobal,
  audience,
  tab,
  welcomeText,
  examplePostsLead,
  disclaimer,
  isSavedEnabled,
  inspireMe,
  inspireMeLead,
  inspireMeThemes,
  topic,
  filters,
  brands,
  authToken,
}) {
  const [question, setQuestion] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [response, setResponse] = useState<IAudienceResponse | undefined>(
    undefined
  );

  const [showInspireMe, setShowInspireMe] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [savedAnswerId, setSavedAnswerId] = useState<string>("");

  const dt_from = new Date(topic.startDate);
  const initialFromDate = {
    year: dt_from.getFullYear(),
    month: dt_from.getMonth(),
  };
  const [fromDate, setFromDate] = useState<{
    value: string;
    label: string;
    payload: AtlasMach.IDate;
  }>(dateToOption(initialFromDate));
  const dt_to = new Date(topic.endtDate);
  const initialToDate = { year: dt_to.getFullYear(), month: dt_to.getMonth() };
  const [toDate, setToDate] = useState<{
    value: string;
    label: string;
    payload: AtlasMach.IDate;
  }>(dateToOption(initialToDate));

  const isDateRangeValid =
    fromDate.payload.year < toDate.payload.year ||
    (fromDate.payload.year == toDate.payload.year &&
      fromDate.payload.month <= toDate.payload.month);

  //let welcomeText = undefined;
  //if (topic.id in welcomeTexts) welcomeText = welcomeTexts[topic.id];
  //else if ("default" in welcomeTexts) welcomeText = welcomeTexts["default"];

  const disease = topic.name;
  const treatments = brands.filter((b) => !b.isCompany).map((b) => b.name);
  const companies = brands.filter((b) => b.isCompany).map((b) => b.name);

  const submitQuestion = () => {
    if (submitting) return;

    setResponse(undefined);
    setSubmitting(true);

    const dt = convertToDt(fromDate.payload, toDate.payload);
    const apiUrl = API_VOICES_PATIENTS.replace("$1", topic.id); // + `?startDate=${dt.dt_from}&endDate=${dt.dt_to}`
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        topicId: isGlobal ? undefined : topic.id,
        audience: audience,
        question: question,
        dateFrom: dt.dt_from,
        dateTo: dt.dt_to,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
          setSubmitting(false);
          setResponse({
            response:
              "Sorry, I'm a bit overwhelmed right now, can you ask again later?",
            examplePosts: [],
          });
        }
        return res.json();
      })
      .then((jsonData) => {
        setSubmitting(false);
        setResponse(jsonData);
        setIsSaved(false);
      });
  };

  const handleSave = () => {
    if (isSaved) return deleteAnswer();
    else return saveAnswer();
  };

  const saveAnswer = () => {
    if (!response) return;

    setIsSaved(true);
    const dt = convertToDt(fromDate.payload, toDate.payload);
    const apiUrl = API_VOICES_SAVED_ANSWERS.replace("$1", topic.id);
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        question: {
          topicId: topic.id,
          audience: audience,
          question: question,
          dateFrom: dt.dt_from,
          dateTo: dt.dt_to,
        },
        answer: {
          answer: response.response,
          examplePosts: response.examplePosts,
        },
      }),
    })
      .then((res) => {
        if (res.status !== 201) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          setIsSaved(false);
          alert("Failed to save the answer due to an unexpected error");
        }
        return res.json();
      })
      .then((res) => {
        setSavedAnswerId(res.id);
      });
  };
  const deleteAnswer = () => {
    const apiUrl = API_VOICES_SAVED_ANSWERS.replace("$1", topic.id).replace(
      "$2",
      savedAnswerId
    );
    fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status !== 204) {
        console.error("Unexpected response code for query: " + apiUrl);
        console.log(res);
      }
    });
    setIsSaved(false);
    setSavedAnswerId("");
  };

  const handleInputChange = (e) => {
    setQuestion(e.target.value.replace("\n", ""));
  };
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && !isSubmitDisabled) submitQuestion();
  };

  const isVoiceSupported: boolean = true;
  //welcomeTexts[topic.id] !== undefined || "default" in welcomeTexts;
  const isSubmitDisabled = submitting || !isDateRangeValid;

  const dateOptions = generateDateRange(initialFromDate, initialToDate).map(
    (d) => dateToOption(d)
  );

  return (
    <PageTemplate title="Voices">
      <VoicesTabbedPageContent tab={tab}>
        <>
          <div className="voices-patients-screen-container">
            {selectWidget && (
              <div
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                {selectWidget}
              </div>
            )}
            <div className="voices-patients-welcome-text">
              {!isVoiceSupported && <ModuleNotAvailable />}
              {isVoiceSupported && (
                <>
                  {welcomeText}
                  <SelectDate
                    options={dateOptions}
                    value={fromDate}
                    onChange={(d) => setFromDate(d)}
                  />
                  and{" "}
                  <SelectDate
                    options={dateOptions}
                    value={toDate}
                    onChange={(d) => setToDate(d)}
                  />
                  <br />
                  <br />
                  Ask me anything
                </>
              )}
            </div>

            {isVoiceSupported && (
              <div className="voices-patients-body">
                <div className="voices-patients-body-left-margin"></div>
                <div className="voices-patients-question-answer">
                  <div className="voices-patients-question">
                    <TextareaAutosize
                      disabled={submitting}
                      className="voices-patients-question-input"
                      placeholder="Ask your question"
                      value={question}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                    />
                    <button
                      className={
                        "voices-patients-question-submit" +
                        (isSubmitDisabled ? " disabled" : "")
                      }
                      onClick={() => {
                        if (!isSubmitDisabled) submitQuestion();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  {submitting && <Typing label={"typing"} />}
                  {response && (
                    <div>
                      <div className="voices-patients-answer">
                        <div className="voices-patients-answer-text">
                          {response.response}
                        </div>
                        {isSavedEnabled &&
                          // TODO: Ideally these responses should return a 4XX response and the message should be set in the front end.
                          response.response.trim() !=
                            "Sorry, I don't have enough context to answer." &&
                          response.response.trim() != "I don't know." && (
                            <div
                              className="voices-patients-answer-star"
                              onClick={handleSave}
                            >
                              <StarIcon
                                fill={isSaved ? "#78D2F1" : "#8D9CA6"}
                              />
                            </div>
                          )}
                      </div>
                      {response.examplePosts.length > 0 && (
                        <div className="voices-patients-example-posts">
                          <p>{examplePostsLead}</p>
                          {response.examplePosts.map((p) => (
                            <a
                              key={p.permalink}
                              href={p.permalink}
                              target="_blank"
                              className="voices-patients-example-post"
                            >
                              {p.text}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="voices-patients-disclaimer">{disclaimer}</div>
                </div>
                <div className="voices-patients-inspire-me">
                  {inspireMeThemes.length > 0 && (
                    <>
                      <div>
                        <p>Not sure what to ask?</p>
                        <button
                          onClick={() => setShowInspireMe(!showInspireMe)}
                        >
                          Inspire me
                        </button>
                      </div>
                      {showInspireMe && (
                        <div className="voices-patients-inspire-me-themes">
                          <div className="voices-patients-inspire-me-themes-lead">
                            {inspireMeLead}
                          </div>
                          {inspireMeThemes.map((thm) => (
                            <div className="voices-patients-inspire-me-themes-theme">
                              {" "}
                              <a
                                onClick={() =>
                                  setQuestion(
                                    inspireMe(
                                      disease,
                                      thm,
                                      treatments,
                                      companies
                                    )
                                  )
                                }
                              >
                                {thm}
                              </a>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      </VoicesTabbedPageContent>
    </PageTemplate>
  );
}

const SelectDate = ({ options, value, onChange }) => {
  return (
    <Select
      unstyled
      onChange={onChange}
      options={options}
      value={value}
      isSearchable={false}
      styles={{
        container: (baseStyle, state) => ({
          ...baseStyle,
          display: "inline-block",
        }),
        control: (baseStyle, state) => ({
          ...baseStyle,
          margin: "0",
          padding: "0 5px",
          border: "1px solid #1C3A4A",
          borderRadius: "15px",
          cursor: "pointer",
        }),
        indicatorsContainer: (baseStyle, state) => ({
          ...baseStyle,
          color: "#8D9CA6",
          marginLeft: "5px",
        }),
        menu: (baseStyle, state) => ({
          ...baseStyle,
          background: "#112936",
          border: "1px solid #1C3A4A",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.45)",
          borderRadius: "14px",
          width: "max-content",
          minWidth: "100%",
          padding: "15px",
        }),
        option: (baseStyle, state) => ({
          ...baseStyle,
          margin: "10px 0",
          fontWeight: state.isFocused ? "bold" : "400",
          cursor: "pointer",
        }),
        singleValue: (baseStyle, state) => ({
          ...baseStyle,
          color: "#78D2F1",
          textDecoration: "underline",
          cursor: "pointer",
        }),
      }}
    />
  );
};

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function dateToOption(date: AtlasMach.IDate): {
  value: string;
  label: string;
  payload: AtlasMach.IDate;
} {
  return {
    value: `${MONTHS[date.month]} ${date.year}`,
    label: `${MONTHS[date.month]} ${date.year}`,
    payload: date,
  };
}

// Generate a list of all month starting at fromDate and ending at toDate (inclusive).
function generateDateRange(
  fromDate: AtlasMach.IDate,
  toDate: AtlasMach.IDate
): AtlasMach.IDate[] {
  let res: AtlasMach.IDate[] = [];
  for (var year = fromDate.year; year <= toDate.year; year++) {
    for (var month = 0; month <= 11; month++) {
      if (year == fromDate.year && month < fromDate.month) continue;
      if (year == toDate.year && month > toDate.month) continue;
      res.push({ year, month });
    }
  }
  return res;
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    brands: state.data.brands,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AudienceVoicesScreen);
