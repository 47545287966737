import React, { useState, useEffect } from "react";

import moment from "moment";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faXTwitter,
  faInstagram,
  faLinkedinIn,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";
import AtlasFullScreenPopup from "components/AtlasFullScreenPopup";

import StarFilled from "assets/images/icons/star-filled.svg";
import StarEmpty from "assets/images/icons/star-empty.svg";

import HCPHeatMap from "./HCPHeatMap";
import CircleProgress from "./CircleProgress";

import { convertToDt } from "utils/convertData";
import {
  API_MESSAGE,
  API_STAR,
  API_UNSTAR,
  API_ENHANCED_PROFILE,
  API_ACCOUNTS_AUDIENCE_ANALYSIS_REQUEST,
  API_DOL_ACCOUNT_HCP,
} from "constants/routes";

interface AccountCardProps {
  topic: AtlasMach.ITopic;
  source: AtlasMach.ISource;
  accountId: string;
  npi: number | undefined;
  isStarred: boolean;
  onToggleStar: () => void;
  color: string;
  sizePercent: number | undefined;
  dateRange: AtlasMach.IDateRange;
  setIsLoading: (x: boolean) => void;
  authToken: string;
  setAudienceAnalysis: (aa: AtlasMach.IAASection) => void;
  highlightMessage?: (msg: string) => boolean;
}

function AccountCard({
  topic,
  source,
  accountId,
  npi,
  isStarred,
  onToggleStar,
  color,
  sizePercent,
  dateRange,
  setIsLoading,
  authToken,
  setAudienceAnalysis,
  highlightMessage,
}: AccountCardProps) {
  // TODO: re-use IRawMessage instead of this custom struct
  //const [messages, setMessages] = useState<IRawMessage[]>()
  const [messages, setMessages] = useState<AtlasMach.IAccountMessages>();

  const [audienceAnalysisStatus, setAudienceAnalysisStatus] = useState<
    "completed" | "in progress" | "not started" | "error" | undefined
  >(undefined);

  const showAAButton = source.name === "Twitter";

  const [hcpData, setHcpData] = useState<HCP | undefined>(undefined);

  const loadHCPData = (accountId) => {
    setIsLoading(true);

    var apiUrl = API_DOL_ACCOUNT_HCP.replace("$1", topic.id).replace(
      "$2",
      accountId
    );

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setIsLoading(false);

        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
        }
        return res.json();
      })
      .then((jsonData) => {
        console.log(jsonData);
        setHcpData(jsonData);
      });
  };

  const handleMessage = (msg) => {
    if (msg.ingestionMeta.permaLink) window.open(msg.ingestionMeta.permaLink);
  };

  const checkAudienceAnalysisStatus = () => {
    fetch(API_ACCOUNTS_AUDIENCE_ANALYSIS_REQUEST.replace("$1", accountId), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((requestStatus) => {
        if (requestStatus == 99) {
          console.log("not started");
          setAudienceAnalysisStatus("not started");
        } else if (requestStatus == 1) {
          console.log("completed");
          setAudienceAnalysisStatus("completed");
        } else if (requestStatus == 0 || requestStatus == 2) {
          console.log("In progress");
          setAudienceAnalysisStatus("in progress");
        } else {
          console.log("error with audience analysis");
          setAudienceAnalysisStatus("error");
        }
      });
  };
  const preparingAnalysisMessage = (
    <p>
      Preparing Audience Analysis - this may take up to 1 hour, or may not
      return results if the account:
      <ul>
        <li>doesn’t have at least 3,000 followers</li>
        <li>has too many fake/bot followers</li>
      </ul>
    </p>
  );
  useEffect(() => {
    checkAudienceAnalysisStatus();
  }, [authToken, accountId]);

  const handleAA = async (e) => {
    if (!messages || audienceAnalysisStatus == "in progress") return;

    e.stopPropagation();
    setIsLoading(true);
    const response = await fetch(
      API_ENHANCED_PROFILE.replace("$1", accountId).replace("$2", topic.id),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    const dataAA = await response.json();
    setIsLoading(false);
    if (audienceAnalysisStatus == "not started")
      setAudienceAnalysisStatus("in progress");

    if (dataAA.message && !dataAA.url) {
      if (dataAA.status === 3 || dataAA.status === 4 || dataAA.status === 5) {
        toast(dataAA.message, {
          position: "top-left",
          type: toast.TYPE.ERROR,
        });
      } else if (dataAA.status === 0 || dataAA.status === 2) {
        toast(preparingAnalysisMessage, {
          position: "top-left",
          autoClose: 7000,
          type: toast.TYPE.INFO,
        });
      }
    }

    if (dataAA.url) {
      // handle profile popup
      const sectionResp = await fetch(dataAA.url, {
        method: "GET",
      });
      const aaData = await sectionResp.json();
      setAudienceAnalysis({
        ...aaData,
        name: messages.account.name,
        profileImage: messages.account.profileImage,
      });
    }
  };

  const loadMessages = (topic, from, to, accountId, sourceId) => {
    setIsLoading(true);

    //const apiUrl = `${API_LISTENING_RAW_MESSAGES.replace('$1', topic.id)}?sourceId=${source.id}&startDate=${from}&endDate=${to}&accountFilters=${accountId}&limit=15`
    const apiUrl = API_MESSAGE.replace("$1", topic.id);
    fetch(apiUrl, {
      //  method: 'GET',
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        dateRange: {
          startDate: from,
          endDate: to,
        },
        accountId: accountId,
        sourceId: sourceId,
      }),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => setMessages(jsonData));
  };

  const handleStarred = () => {
    onToggleStar();
    const starLink = isStarred ? API_UNSTAR : API_STAR;
    fetch(
      starLink
        .replace("$1", topic.id)
        .replace("$2", source.id)
        .replace("$3", accountId),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      // TODO: handle failure to star
      if (res.status === 200) {
      }
    });
  };

  const handleClickNpi = () => {
    loadHCPData(accountId);
    //loadHCPData("58dc0f0e-ed3e-4d12-863c-97ce8d92319b");
  };
  const handleCloseNpi = () => {
    setHcpData(undefined);
  };

  useEffect(() => {
    const dt = convertToDt(dateRange.from, dateRange.to);
    loadMessages(topic, dt.dt_from, dt.dt_to, accountId, source.id);
  }, [authToken, topic, dateRange, source, accountId]);

  if (!messages) return <div />;

  return (
    <div
      className="hs-account-card"
      style={{
        background: color,
      }}
    >
      {hcpData && (
        <HCPPopup
          hcp={hcpData}
          onClose={handleCloseNpi}
          authToken={authToken}
        />
      )}
      <div className="hs-account-card-body">
        <div className="account-name">{messages.account.name}</div>
        {messages.account.location && <div>{messages.account.location}</div>}

        {npi && (
          <div onClick={handleClickNpi} className="account-card-extra-button">
            +
          </div>
        )}

        {sizePercent && (
          <div className="account-progress-status">
            <CircleProgress percent={sizePercent} color="#1C3D4D" />
            <span>{sizePercent.toFixed(2)}%</span>
          </div>
        )}

        <div className="message-wrapper hs-scrollable-container">
          {messages.messages.map((msg) => (
            <div
              className={
                "message-item" +
                (highlightMessage && highlightMessage(msg.message)
                  ? " message-item-highlighted"
                  : "")
              }
              key={msg.id}
              onClick={(e) => {
                e.stopPropagation();
                handleMessage(msg);
              }}
            >
              <div className="message-header">
                <span>{messages.account.accountName}</span>
                <span style={{ marginLeft: 10 }}>
                  {moment(msg.ingestionMeta.messageDate).format("MM/DD/YYYY")}
                </span>
              </div>
              <div className="message-content">{msg.message}</div>
            </div>
          ))}
        </div>

        <div className="account-card-footer">
          <div className={`social-links`}>
            {messages.account.facebookUrl && (
              <a
                href={messages.account.facebookUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            )}
            {messages.account.instagramUrl && (
              <a
                href={messages.account.instagramUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            )}
            {messages.account.twitterUrl && (
              <a
                href={messages.account.twitterUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
            )}
            {messages.account.linkedInUrl && (
              <a
                href={messages.account.linkedInUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            )}
            {messages.account.redditUrl && (
              <a
                href={messages.account.redditUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faReddit} />
              </a>
            )}
          </div>
          <div
            className={`account-card-star ${isStarred ? "star" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              handleStarred();
            }}
          >
            {isStarred ? (
              <img
                style={{ width: 36, height: 36 }}
                src={StarFilled}
                alt="star"
              />
            ) : (
              <img
                style={{ width: 36, height: 36 }}
                src={StarEmpty}
                alt="star"
              />
            )}
          </div>
        </div>
      </div>

      {showAAButton && audienceAnalysisStatus === "not started" && (
        <div className="button-analysis" onClick={handleAA}>
          <div className="button-text">
            <span>Request Audience Analysis</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      )}
      {showAAButton && audienceAnalysisStatus === "in progress" && (
        <div className="button-analysis disabled">
          <div className="button-text">
            <span>Audience Analysis in Progress</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      )}
      {showAAButton && audienceAnalysisStatus === "completed" && (
        <div className="button-analysis" onClick={handleAA}>
          <div className="button-text">
            <span>See Audience Analysis</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      )}
      {showAAButton && audienceAnalysisStatus === "error" && (
        <div className="button-analysis disabled">
          <div className="button-text">
            <span>No Audience Analysis Available</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      )}
    </div>
  );
}

interface HCPTaxonomy {
  typeDescription: string;
}

interface HCPLicense {
  taxonomy: HCPTaxonomy;
}
interface HCPPayment {
  amount: string;
  company: string;
}
interface HCP {
  npi: number;
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: number;
  longitude: number;
  licenses: HCPLicense[];
  payments: HCPPayment[];
}

interface HCPPopupProps {
  hcp: HCP;
  onClose: () => void;
  authToken: string;
}
function HCPPopup({ hcp, onClose, authToken }: HCPPopupProps) {
  var totalPayments = 0;
  var companyPayments = {};
  hcp.payments.forEach((payment) => {
    if (!companyPayments[payment.company]) companyPayments[payment.company] = 0;

    companyPayments[payment.company] += parseFloat(payment.amount);
    totalPayments += parseFloat(payment.amount);
  });
  var payments = Object.keys(companyPayments).map((company) => ({
    company: company,
    amount: companyPayments[company],
    percent: 100 * (companyPayments[company] / totalPayments),
  }));
  payments.sort(function (a, b) {
    if (a.amount < b.amount) return 1;
    if (a.amount > b.amount) return -1;
    return 0;
  });

  return (
    <AtlasFullScreenPopup onClose={onClose}>
      <div className="hcp-popup">
        <div className="hcp-popup-profile">
          {/*<div className="hcp-popup-profile-picture"></div>*/}
          <div className="hcp-popup-profile-info">
            <div className="hcp-popup-profile-name">
              {hcp.firstName} {hcp.lastName}
            </div>
            <div className="hcp-popup-profile-npi">NPI {hcp.npi}</div>
            {hcp.licenses.length > 0 && (
              <div className="hcp-popup-profile-specialty">
                {hcp.licenses[0].taxonomy.typeDescription}
              </div>
            )}
            <div className="hcp-popup-profile-location">
              {hcp.city}, {hcp.state} {hcp.zipCode}
            </div>
          </div>
        </div>

        <div className="hcp-popup-payments">
          <div className="hcp-popup-payments-header">
            Top companies making general payments
            <div className="hcp-popup-payments-total">
              Total: ${formatAmount(totalPayments)}
            </div>
          </div>
          <div className="hcp-popup-payments-list hs-scrollable-container">
            {payments.map((payment) => (
              <div className="hcp-popup-payments-row">
                <div
                  className="hcp-popup-payments-row-percent"
                  style={{ width: payment.percent + "%" }}
                ></div>
                <div className="hcp-popup-payments-row-amount">
                  ${formatAmount(payment.amount)}
                </div>
                <div className="hcp-popup-payments-row-company">
                  {payment.company}
                </div>
              </div>
            ))}
          </div>
          <div className="hcp-popup-payments-footer">
            Data aggregated over the period of 2020-2021.
          </div>
        </div>
        <HCPHeatMap
          markerCoordinate={{
            latitude: hcp.latitude,
            longitude: hcp.longitude,
          }}
          markerName={hcp.firstName + " " + hcp.lastName}
          authToken={authToken}
        />
      </div>
    </AtlasFullScreenPopup>
  );
}

function formatAmount(n: number) {
  return Math.floor(n)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default AccountCard;
