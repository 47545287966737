import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

import {
  HS_LISTENING_SUMMARY,
  HS_LISTENING_CONTENT_FEED,
  HS_LISTENING_THEMES_FIT_GAP,
  HS_LISTENING_BENCHMARKING,
  HS_DOL_NETWORK_MAP,
  HS_DOL_PATHWAYS,
  HS_DOL_CONVERSATIONAL_MATRIX,
  HS_DOL_CENTRICITY_REPORT,
  HS_DOL_STARRED_ACCOUNTS,
  HS_VOICES_PATIENTS,
  HS_VOICES_HCPS,
  HS_VOICES_STARRED_ACCOUNTS,
  HS_VOICES_DEBATE,
  HS_CONTENT_STUDIO_MESSAGE_GENERATOR,
} from "constants/routes";

import { config } from "../../config.js";

import debateData from "pages/VoicesModule/data";

import PageTemplate from "pages/PageTemplate";

// Top-level modules
import { ReactComponent as ListeningIcon } from "assets/icons/analyze_track.svg";
import { ReactComponent as KolDolIcon } from "assets/icons/kol_dol.svg";
import { ReactComponent as VoicesIcon } from "assets/icons/voices.svg";
import { ReactComponent as ContentStudioIcon } from "assets/icons/paint.svg";

// Sub-modules
import { ReactComponent as GridIcon } from "assets/icons/grid.svg";
import { ReactComponent as SocialMixIcon } from "assets/icons/social_mix.svg";
import { ReactComponent as ContentIcon } from "assets/icons/content.svg";
import { ReactComponent as ContentFitGapIcon } from "assets/icons/content_fit_gap.svg";
import { ReactComponent as BenchmarkingIcon } from "assets/icons/benchmarking.svg";
import { ReactComponent as NetworkMapIcon } from "assets/icons/network_map.svg";
import { ReactComponent as PathwaysIcon } from "assets/icons/pathways.svg";
import { ReactComponent as CentricityIcon } from "assets/icons/bar_chart.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/people.svg";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import { ReactComponent as MessageGeneratorIcon } from "assets/icons/message_generator.svg";
import { ReactComponent as AgencyBriefIcon } from "assets/icons/agency_brief.svg";
import { ReactComponent as ContentEnhancerIcon } from "assets/icons/content_enhancer.svg";

import "./styles.scss";
const ICON_COLOR = "#78D2F1";
const ICON_COLOR_DISABLED = "#8D9CA6";

interface IMenuPageProps {
  topics: AtlasMach.ITopic[];
  topic: AtlasMach.ITopic;
  setSources: (sources: AtlasMach.ISource[]) => void;
  setAudiences: (sources: AtlasMach.IAudience[]) => void;
  setThemes: (sources: AtlasMach.ITheme[]) => void;
  updateTopic: (topic: AtlasMach.ITopic) => void;
  updateFilters: (filters: AtlasMach.UIFilters | undefined) => void;
}

function MenuPage({
  topics,
  topic,
  updateTopic,
  updateFilters,
  setSources,
  setAudiences,
  setThemes,
}: IMenuPageProps) {
  const topicsOptions = topics.map((t) => ({
    value: t.id,
    label: t.name,
    payload: t,
  }));
  const onChange = (t) => {
    if (t && t.payload.id != topic.id) {
      localStorage.setItem("topic", t.payload.id);
      // When we update the topic, we need to reset all the data that were scoped by the theme.
      // Note that in Redux, dispatch happens synchronously, so all these are guaranteed to be
      // executed before the updateTopic. Once updateTopic is executed, the HOC component WithLoggedIn
      // will automatically re-initialize all data scoped by the new topic (and nothing will be rendered until that
      // happens).
      setSources([]);
      setThemes([]);
      setAudiences([]);
      updateFilters(undefined);
      updateTopic(t.payload);
    }
  };

  const individualBots = topic.topicBots
    .map((tb) => tb.bot)
    .filter((b) => !b.topicAudienceId);
  const audienceBots = topic.topicBots
    .map((tb) => tb.bot)
    .filter((b) => b.topicAudienceId)
    .map((b) => ({
      id: b.id,
      audience: topic.audiences.find((a) => a.id == b.topicAudienceId)?.name,
    }));

  return (
    <PageTemplate title={config.BRANDING.TITLE}>
      <div className="hs-menu-container">
        <div className="hs-menu-header">
          <Select
            unstyled
            onChange={onChange}
            options={topicsOptions}
            defaultValue={topicsOptions.find((o) => o.value === topic.id)}
            isSearchable={false}
            styles={{
              container: (baseStyle, state) => ({
                ...baseStyle,
                color: "white",
                width: "max-content",
                maxWidth: "300px",
                position: "relative",
                left: "0",
                top: "15px",
              }),
              control: (baseStyle, state) => ({
                ...baseStyle,
                padding: "0",
                cursor: "pointer",
              }),
              indicatorsContainer: (baseStyle, state) => ({
                ...baseStyle,
                color: "#8D9CA6",
                marginLeft: "15px",
              }),
              menu: (baseStyle, state) => ({
                ...baseStyle,
                background: "#112936",
                border: "1px solid #1C3A4A",
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.45)",
                borderRadius: "14px",
                width: "max-content",
                minWidth: "100%",
                padding: "15px",
              }),
              option: (baseStyle, state) => ({
                ...baseStyle,
                margin: "10px 0",
                fontWeight: state.isFocused ? "bold" : "400",
                cursor: "pointer",
              }),
            }}
          />
        </div>
        <div className="hs-menu-modules">
          <div className="hs-menu-module">
            <ListeningIcon width="25" height="25" />
            <h2>
              <Link to={HS_LISTENING_SUMMARY}>Listening</Link>
            </h2>
            <div className="hs-menu-module-hr">
              <hr />
              <span className="circle" />
            </div>
            <div className="hs-menu-submodules">
              <div className="hs-menu-submodule">
                <SocialMixIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_LISTENING_SUMMARY}>Summary</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Quick snap-shot of social metrics
                </div>
              </div>
              <div className="hs-menu-submodule">
                <ContentIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_LISTENING_CONTENT_FEED}>Content Feed</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Browse most relevant social posts
                </div>
              </div>
              <div className="hs-menu-submodule">
                <ContentFitGapIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_LISTENING_THEMES_FIT_GAP}>Themes Fit Gap</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Compare themes across audiences
                </div>
              </div>
              <div className="hs-menu-submodule">
                <BenchmarkingIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_LISTENING_BENCHMARKING}>Benchmarking</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Create and compare data sets
                </div>
              </div>
            </div>
          </div>
          <div className="hs-menu-module">
            <KolDolIcon width="25" height="25" />
            <h2>
              <Link to={HS_DOL_NETWORK_MAP}>Digital Opinion Leaders</Link>
            </h2>
            <div className="hs-menu-module-hr">
              <hr />
              <span className="circle" />
            </div>
            <div className="hs-menu-submodules">
              <div className="hs-menu-submodule">
                <NetworkMapIcon fill={ICON_COLOR} width="25" height="25" />
                <h4>
                  <Link to={HS_DOL_NETWORK_MAP}>Network Map</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Discover the universe of DOLs
                </div>
              </div>
              <div className="hs-menu-submodule">
                <PathwaysIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_DOL_PATHWAYS}>Pathways</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Prioritize DOLs engagement
                </div>
              </div>
              <div className="hs-menu-submodule">
                <GridIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_DOL_CONVERSATIONAL_MATRIX}>
                    Conversational Matrix
                  </Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Personalize content themes
                </div>
              </div>
              <div className="hs-menu-submodule">
                <CentricityIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_DOL_CENTRICITY_REPORT}>Centricity Report</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Track and monitor influence
                </div>
              </div>
              <div className="hs-menu-submodule">
                <StarIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_DOL_STARRED_ACCOUNTS}>Starred Accounts</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Dive deep into selected accounts
                </div>
              </div>
            </div>
          </div>
          {(audienceBots.length > 0 || individualBots.length > 0) && (
            <div className="hs-menu-module">
              <VoicesIcon width="25" height="25" />
              <h2>
                <Link to={HS_VOICES_PATIENTS}>Voices</Link>
              </h2>
              <div className="hs-menu-module-hr">
                <hr />
                <span className="circle" />
              </div>
              <div className="hs-menu-submodules">
                {audienceBots.map((ab) => (
                  <div className="hs-menu-submodule">
                    <PeopleIcon fill={ICON_COLOR} />
                    <h4>
                      <Link to={"/hs/voices/" + ab.audience?.toLowerCase()}>
                        {ab.audience}
                      </Link>
                    </h4>
                    <div className="hs-menu-submodule-description">
                      Interview {ab.audience} assisted by AI
                    </div>
                  </div>
                ))}
                {individualBots.length > 0 && (
                  <div className="hs-menu-submodule">
                    <PeopleIcon fill={ICON_COLOR} />
                    <h4>
                      <Link to={HS_VOICES_STARRED_ACCOUNTS}>
                        Starred Accounts (Beta)
                      </Link>
                    </h4>
                    <div className="hs-menu-submodule-description">
                      Interview selected individual accounts
                    </div>
                  </div>
                )}
                {topic.id in debateData && (
                  <div className="hs-menu-submodule">
                    <PeopleIcon fill={ICON_COLOR} />
                    <h4>
                      <Link to={HS_VOICES_DEBATE}>Debate Showdown</Link>
                    </h4>
                    <div className="hs-menu-submodule-description">
                      Debate selected individual accounts
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {config.ENABLE_CONTENT_STUDIO && (
            <div className="hs-menu-module">
              <ContentStudioIcon width="25" height="25" fill="#FFF" />
              <h2>
                <Link to={HS_CONTENT_STUDIO_MESSAGE_GENERATOR}>
                  Content Studio
                </Link>
              </h2>
              <div className="hs-menu-module-hr">
                <hr />
                <span className="circle" />
              </div>
              <div className="hs-menu-submodules">
                <div className="hs-menu-submodule">
                  <MessageGeneratorIcon fill={ICON_COLOR} />
                  <h4>
                    <Link to={HS_CONTENT_STUDIO_MESSAGE_GENERATOR}>
                      Message Generator (Beta)
                    </Link>
                  </h4>
                  <div className="hs-menu-submodule-description">
                    Instantly generate personalized content
                  </div>
                </div>
                <div className="hs-menu-submodule disabled">
                  <ContentEnhancerIcon fill={ICON_COLOR_DISABLED} />
                  <h4>Content Enhancer</h4>
                  <div className="hs-menu-submodule-description">
                    Coming soon
                  </div>
                </div>
                <div className="hs-menu-submodule disabled">
                  <AgencyBriefIcon fill={ICON_COLOR_DISABLED} />
                  <h4>Agency Brief</h4>
                  <div className="hs-menu-submodule-description">
                    Coming soon
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageTemplate>
  );
}

export default MenuPage;
