import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { config } from "../../../config.js";
import { setIsLoading } from "redux/actions";

import { ReactComponent as BackIcon } from "assets/icons/back.svg";

import { politicians, interviewsDb, comparisonsDb } from "../data";

import PageTemplate from "pages/PageTemplate";
import PollVerseTabbedPageContent from "./PollVerseTabbedPageContent";
import AtlasSelect from "../../../components/AtlasSelect";
import FiltersBar from "components/Pollverse/FiltersBar";

interface IComparisonSummary {
  introduction: string;
  positions: string[];
  conclusion: string;
}
interface IExamplePost {
  permalink: string;
  text: string;
}
interface IAudienceResponse {
  response: string;
  examplePosts: IExamplePost[];
}

function PoliticianComparison({
  selectedTopic,
  firstPolitician,
  secondPolitician,
}) {
  const yearFirstPolitician =
    firstPolitician === "" ||
    secondPolitician === "" ||
    firstPolitician === secondPolitician
      ? 2020
      : undefined;

  const interviewKeyFirstPolitician =
    firstPolitician +
    (yearFirstPolitician ? " (" + yearFirstPolitician + ")" : "");
  const firstPoliticianObject = politicians.find(
    (p) => p.name == firstPolitician
  );
  const questionsFirstPolitician =
    interviewsDb[interviewKeyFirstPolitician][selectedTopic]["questions"];

  const yearSecondPolitician =
    firstPolitician === "" ||
    secondPolitician === "" ||
    firstPolitician === secondPolitician
      ? 2024
      : undefined;

  const interviewKeySecondPolitician =
    secondPolitician +
    (yearSecondPolitician ? " (" + yearSecondPolitician + ")" : "");
  const secondPoliticianObject = politicians.find(
    (p) => p.name == secondPolitician
  );
  const questionsSecondPolitician =
    interviewsDb[interviewKeySecondPolitician][selectedTopic]["questions"];

  if (!firstPoliticianObject || !secondPoliticianObject) return <div></div>;

  return (
    <div className="pollverse-compare-wrapper">
      <div className="pollverse-compare-row">
        <div className="pollverse-background-left">
          <h3>
            {firstPolitician}{" "}
            {yearFirstPolitician && <>({yearFirstPolitician})</>}
          </h3>
        </div>
        <div className="pollverse-background-right">
          <h3>
            {secondPolitician} {yearSecondPolitician && <>({2023})</>}
          </h3>
        </div>
      </div>
      <div className="pollverse-compare-row">
        <div className="pollverse-politician-introduction">
          <img src={firstPoliticianObject.picture} width={240} />
          {interviewsDb[interviewKeyFirstPolitician][selectedTopic]["summary"]
            .split("\n")
            .map((p) => (
              <p>{p}</p>
            ))}
        </div>
        <div className="pollverse-politician-introduction">
          <img src={secondPoliticianObject.picture} width={240} />
          {interviewsDb[interviewKeySecondPolitician][selectedTopic]["summary"]
            .split("\n")
            .map((p) => (
              <p>{p}</p>
            ))}
        </div>
      </div>
      <div className="pollverse-compare-row">
        <div>
          <h4>Simulated Interview</h4>
        </div>
        <div>
          <h4>Simulated Interview</h4>
        </div>
      </div>
      {questionsFirstPolitician.map(
        // We assume both politicians have the same questions
        (question, index) => (
          <div className="pollverse-compare-row">
            <div className="pollverse-compare-individual-interview">
              {question.answer && (
                <>
                  <div className="pollverse-compare-individual-interview-label">
                    Question
                  </div>
                  <div>{question.question}</div>
                  <div className="pollverse-compare-individual-interview-label">
                    Answer
                  </div>
                  <div>{question.answer}</div>

                  <SimulatedInterviewSources
                    politician={firstPolitician}
                    posts={question.posts}
                  />
                </>
              )}
            </div>
            <div className="pollverse-compare-individual-interview">
              {questionsSecondPolitician[index].answer && (
                <>
                  <div className="pollverse-compare-individual-interview-label">
                    Question
                  </div>
                  <div>{questionsSecondPolitician[index].question}</div>
                  <div className="pollverse-compare-individual-interview-label">
                    Answer
                  </div>
                  <div>{questionsSecondPolitician[index].answer}</div>

                  <SimulatedInterviewSources
                    politician={secondPolitician}
                    posts={questionsSecondPolitician[index].posts}
                  />
                </>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
}

function CompareScreen({ topic, filters, brands, setIsLoading, authToken }) {
  const navigate = useNavigate();
  const availableTopics: string[] = [];
  for (topic in interviewsDb["Marsha Blackburn"]) {
    availableTopics.push(topic);
  }
  const topicOptions = availableTopics.map((t) => ({
    value: t,
    label: t,
  }));

  const [firstPolitician, setFirstPolitician] = useState<string>("");
  const [secondPolitician, setSecondPolitician] = useState<string>("");
  const [selectedTopic, setSelectedTopic] = useState<string>("");

  const [showComparison, setShowComparison] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(true);

  const handleSelectPolitician = (p: string) => {
    if (firstPolitician == p) {
      setFirstPolitician("");
    } else if (secondPolitician == p) {
      setSecondPolitician("");
    } else {
      if (firstPolitician == "") {
        setFirstPolitician(p);
      } else if (secondPolitician == "") {
        setSecondPolitician(p);
      }
    }
  };

  const getComparison = () => {
    var key =
      firstPolitician == ""
        ? secondPolitician + " (2020)" + secondPolitician + " (2024)"
        : secondPolitician == ""
        ? firstPolitician + " (2020)" + firstPolitician + " (2024)"
        : firstPolitician < secondPolitician
        ? firstPolitician + secondPolitician
        : secondPolitician + firstPolitician;
    if (comparisonsDb[key]) return comparisonsDb[key][selectedTopic];
    return undefined;
  };

  const onSelectTopic = (t: string) => {
    setSelectedTopic(t);
  };

  const onCompare = () => {
    if (!getComparison()) {
      alert("no data available for this pair");
      return;
    }
    setIsLoading(true);
    setTimeout(() => {
      setShowComparison(true);
      window.scrollTo(0, 0);
      setIsLoading(false);
    }, 3000);
  };

  const summary: IComparisonSummary | undefined = selectedTopic
    ? getComparison()
    : undefined;

  const handleResetSelection = () => {
    setFirstPolitician("");
    setSecondPolitician("");
    setSelectedTopic("");
    setShowComparison(false);
  };

  const selectionScreen = (
    <div className="pollverse-compare-selector-container">
      <div className="pollverse-compare-select-topic">
        <AtlasSelect
          placeholder={"Choose a topic"}
          options={topicOptions}
          onChange={(opt) => onSelectTopic(opt.value)}
          value={
            selectedTopic
              ? { label: selectedTopic, value: selectedTopic }
              : undefined
          }
        />
      </div>
      <div className="pollverse-candidates-selection">
        <div className={firstPolitician ? "selected" : ""}>
          {firstPolitician || "Choose Candidate 1"}
        </div>
        <span>vs</span>
        <div className={secondPolitician ? "selected" : ""}>
          {secondPolitician || "Choose Candidate 2"}
        </div>
      </div>
      <div className={"pollverse-compare-compare-button"}>
        <button
          onClick={() => {
            if ((firstPolitician || secondPolitician) && selectedTopic)
              onCompare();
          }}
          className={
            "hs-button primary" +
            ((firstPolitician || secondPolitician) && selectedTopic
              ? ""
              : " inactive")
          }
        >
          Compare
        </button>
      </div>
      <div>
        <div className="pollverse-compare-choose-instructions">
          Select up to 2 Congressmen from Tennessee to Debate
        </div>
        {politicians.map((politician) => (
          <div
            className={
              "pollverse-compare-choose-politician" +
              (firstPolitician == politician.name ||
              secondPolitician == politician.name
                ? " chosen"
                : "")
            }
            onClick={() => handleSelectPolitician(politician.name)}
          >
            <img src={politician.picture} width={250} />
            <div className="pollverse-compare-choose-politician-name">
              {politician.formalName}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const comparisonScreen = summary && (
    <div className="pollverse-compare-summary-container">
      <h2 style={{ textAlign: "left" }}>
        <BackIcon
          style={{ marginRight: "10px", cursor: "pointer" }}
          onClick={() => handleResetSelection()}
        />{" "}
        Comparison Result
      </h2>
      <div className="pollverse-compare-summary-heading">
        {firstPolitician != "" && secondPolitician != "" && (
          <>
            Summary of the positions of <strong>{firstPolitician}</strong> and{" "}
            <strong>{secondPolitician}</strong> on the topic of{" "}
            <strong>{selectedTopic}</strong>
          </>
        )}
        {(firstPolitician == "" || secondPolitician == "") && (
          <>
            Summary of the evoluation of the position of{" "}
            <strong>
              {firstPolitician}
              {secondPolitician}
            </strong>{" "}
            from <strong>2020</strong> to <strong>2023</strong> on the topic of{" "}
            <strong>{selectedTopic}</strong>
          </>
        )}
      </div>
      <div className="pollverse-compare-summary-text">
        <h3>Introduction</h3>
        {summary.introduction}
        <h3>Positions</h3>
        {summary.positions.map((position) => (
          <p>{position}</p>
        ))}
        <h3>Conclusion</h3>
        {summary.conclusion}
      </div>

      {!showDetails && (
        <button
          className="hs-button primary"
          onClick={() => setShowDetails(true)}
        >
          Show details
        </button>
      )}
      {showDetails && (
        <>
          <div className="pollverse-compare-details">
            <PoliticianComparison
              firstPolitician={firstPolitician || secondPolitician}
              secondPolitician={secondPolitician || firstPolitician}
              selectedTopic={selectedTopic}
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <PageTemplate title={config.POLLVERSE.BRANDING.TITLE}>
      <PollVerseTabbedPageContent tab={"debate-showdown"}>
        <div className="pollverse-compare-screen-container">
          <FiltersBar
            disableSources={true}
            disableAudiences={true}
            disableBrands={true}
            disableThemes={true}
            disableSentiments={true}
            enableStarredAccounts={false}
            filters={{
              audiences: [],
              brandAccounts: [],
              brands: [],
              engagementsLikelihood: [],
              sentiments: [],
              source: {
                id: "8392c05d-9a21-4547-98a6-cc9d1c1e94de",
                name: "Twitter",
                color: "#FF0000",
              },
              sources: [],
              themes: [
                {
                  id: "3d7e684e-5e75-4992-9c62-e67d95d17bc2",
                  name: "Gun Control",
                  color: "rgba(228,121,121,1)",
                },
              ],
              usStates: filters.usStates,
            }}
          />
          {!showComparison && selectionScreen}
          {showComparison && comparisonScreen}
        </div>
      </PollVerseTabbedPageContent>
    </PageTemplate>
  );
}

function SimulatedInterview({ politician, questions }) {
  return (
    <>
      <h4>Simulated Interview</h4>
      {questions.map(
        (question) =>
          question.answer && (
            <div className="pollverse-compare-individual-interview">
              <div className="pollverse-compare-individual-interview-label">
                Question
              </div>
              <div>{question.question}</div>
              <div className="pollverse-compare-individual-interview-label">
                Answer
              </div>
              <div>{question.answer}</div>

              <SimulatedInterviewSources
                politician={politician}
                posts={question.posts}
              />
            </div>
          )
      )}
    </>
  );
}

function SimulatedInterviewSources({ politician, posts }) {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <>
      {!expanded && (
        <div
          className="pollverse-compare-individual-interview-sources-show"
          onClick={() => setExpanded(true)}
        >
          Show sources &#8250;
        </div>
      )}
      {expanded && (
        <div className="pollverse-compare-individual-interview-sources">
          <>
            <h6 onClick={() => setExpanded(false)}>
              Answer synthesized based on the following online posts from{" "}
              {politician}
            </h6>
            {posts.map((post) => (
              <a href={post.permalink} target="_blank">
                {post.text}
              </a>
            ))}
          </>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    brands: state.data.brands,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareScreen);
