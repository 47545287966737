import React from "react";

import "./Typing.scss";

const Typing = ({ label }) => {
  return (
    <div className="typing-wrapper">
      <div>
        <span>{label}</span>
        <div className="typing-animation">
          <div className="typing__dot"></div>
          <div className="typing__dot"></div>
          <div className="typing__dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Typing;
