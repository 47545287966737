import React, { useMemo } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IChartPopup {
  position: {
    x: number;
    y: number;
  };
  data: AtlasMach.IPieData;
  msgData: AtlasMach.IPieMessages[];
  handleClose: () => void;
  handleMessage: (msg: AtlasMach.IMessage) => void;
}

// TODO: This name is bad, it was inheritated from a historical choice of naming this
// after the page on which it would appear (called ChartScreen), but then was moved around pages and
// never renamed. We need to unify it with the AccountPopup.
export default function ChartPopup({
  position,
  data,
  msgData,
  handleClose,
  handleMessage,
}: IChartPopup) {
  const messages = useMemo(() => {
    return msgData.find((md) => md.name === data.label)?.messages;
  }, [data, msgData]);

  return (
    <div
      className="graph-popup"
      style={{
        top: `${position.y}px`,
        left: `${position.x}px`,
        background: data.color,
        minHeight: 453,
      }}
    >
      <div className="account-name">{data.label}</div>

      <div className="message-wrapper chart hs-scrollable-container">
        {messages &&
          messages.map((msg) => (
            <a
              href={msg.ingestionMeta.permaLink}
              target="_blank"
              className="message-item"
              key={msg.id}
              onClick={(e) => {
                e.stopPropagation();
                //handleMessage(msg);
              }}
            >
              <div className="message-header">
                <div className="message-avatar">
                  {/*msg.ingestionMeta.from.profileImage &&
                <img src={msg.ingestionMeta.from.profileImage} alt='' />
              */}
                </div>
                <span>{msg.ingestionMeta.from.name}</span>
                {/* <span style={{ marginLeft: 10 }}>{moment(msg.ingestionMeta.messageDate).format('MM/DD/YYYY')}</span> */}
              </div>
              <div className="message-content">{msg.message}</div>
            </a>
          ))}
      </div>
      <div
        className="popup-close"
        style={{
          color: data.color,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (handleClose) handleClose();
        }}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>
    </div>
  );
}
