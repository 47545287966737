import React, { useCallback, useEffect, useMemo } from "react";
import Select, {
  components,
  ValueContainerProps,
  PlaceholderProps,
} from "react-select";

import CustomCalendar from "./CustomCalendar";
import CalendarIcon from "assets/images/icons/calendar.svg";
import ArrowIcon from "assets/images/icons/arrow-down-small.svg";

import "./styles.scss";

interface FiltersBarProps {
  topic: AtlasMach.ITopic;
  filters: AtlasMach.UIFilters;
  updateFilters: (filters: AtlasMach.UIFilters) => void;

  // If this callback is set, the filter bar will include a save
  // button which will call when clicked. If this is not set, then
  // the button will not be rendered.
  onButtonClicked?: () => void;
  onCancel?: () => void;

  dataSources: AtlasMach.ISource[];
  dataAudiences: AtlasMach.IAudience[];
  dataThemes: AtlasMach.ITheme[];
  dataBrands: AtlasMach.IBrand[];
  dataBrandAccounts: AtlasMach.IAccount[];

  enableMultiSources?: boolean;
  disableBrands?: boolean;
  disableAudiences?: boolean;
  disableSentiments?: boolean;
  disableThemes?: boolean;
  enableEngagementsLikelihood?: boolean;
  enableBrandAccounts?: boolean;
  enableStarredAccounts?: boolean;
}

function FiltersBar({
  topic,
  filters,
  updateFilters,
  onButtonClicked,
  onCancel,
  dataSources,
  dataAudiences,
  dataThemes,
  dataBrands,
  dataBrandAccounts,
  enableMultiSources,
  disableBrands,
  disableAudiences,
  disableSentiments,
  disableThemes,
  enableEngagementsLikelihood,
  enableBrandAccounts,
  enableStarredAccounts,
}: FiltersBarProps) {
  const handleSelectSource = (option) => {
    updateFilters({
      ...filters,
      source: option.payload,
    });
  };
  const handleSelectSources = (option) => {
    updateFilters({
      ...filters,
      sources: option.map((opt) => opt.payload),
    });
  };
  const handleSelectAudience = (option) => {
    updateFilters({
      ...filters,
      audiences: option.map((opt) => opt.payload),
    });
  };
  const handleSelectSentiment = (option) => {
    updateFilters({
      ...filters,
      sentiments: option.map((opt) => opt.payload),
    });
  };
  const handleSelectTheme = (option) => {
    updateFilters({
      ...filters,
      themes: option.map((opt) => opt.payload),
    });
  };
  const handleSelectBrand = (option) => {
    updateFilters({
      ...filters,
      brands: option.map((opt) => opt.payload),
    });
  };
  const handleSelectBrandAccount = (option) => {
    updateFilters({
      ...filters,
      brandAccounts: option.map((opt) => opt.payload),
    });
  };
  const handleSelectEngagementLikelihood = (option) => {
    updateFilters({
      ...filters,
      engagementsLikelihood: option.map((opt) => opt.payload),
    });
  };
  const handleToggleOnlyStarredAccounts = () => {
    updateFilters({
      ...filters,
      onlyStarredAccounts: !filters.onlyStarredAccounts,
    });
  };

  const engagementToLabel = (v: number) => {
    if (v == 5) return "Very Unlikely";
    if (v == 4) return "Unlikely";
    if (v == 3) return "Neutral";
    if (v == 2) return "Likely";
    if (v == 1) return "Very Likely";
    return "";
  };

  const sourcesOptions = dataSources.map((src) => ({
    value: src.id,
    label: src.name,
    payload: src,
  }));
  const audiencesOptions = dataAudiences.map((a) => ({
    value: a.id,
    label: a.name,
    payload: a,
  }));
  const sentimentsOptions = [
    { value: "positive", label: "Positive", payload: "positive" },
    { value: "neutral", label: "Neutral", payload: "neutral" },
    { value: "negative", label: "Negative", payload: "negative" },
  ];
  const themesOptions = dataThemes.map((t) => ({
    value: t.id,
    label: t.name,
    payload: t,
  }));
  const brandsOptions = dataBrands.map((b) => ({
    value: b.id,
    label: b.name,
    payload: b,
  }));
  const brandAccountsOptions = dataBrandAccounts.map((a) => ({
    value: a.id,
    label: a.accountName,
    payload: a,
  }));
  const engagementsLikelihoodOptions = [
    { value: "1", label: engagementToLabel(1), payload: 1 },
    { value: "2", label: engagementToLabel(2), payload: 2 },
    { value: "3", label: engagementToLabel(3), payload: 3 },
    { value: "4", label: engagementToLabel(4), payload: 4 },
    { value: "5", label: engagementToLabel(5), payload: 5 },
  ];

  const sourceValue = {
    value: filters.source.id,
    label: filters.source.name,
    payload: filters.source,
  };
  const sourcesValues = filters.sources
    ? filters.sources.map((src) => ({
        value: src.id,
        label: src.name,
        payload: src,
      }))
    : [];
  const audiencesValues = filters.audiences.map((a) => ({
    value: a.id,
    label: a.name,
    payload: a,
  }));
  const sentimentsValues = filters.sentiments.map((s) => ({
    value: s,
    label: s,
    payload: s,
  }));

  const themesValues = filters.themes.map((t) => ({
    value: t.id,
    label: t.name,
    payload: t,
  }));
  const brandsValues = filters.brands.map((b) => ({
    value: b.id,
    label: b.name,
    payload: b,
  }));
  const brandAccountsValue = filters.brandAccounts.map((a) => ({
    value: a.id,
    label: a.accountName,
    payload: a,
  }));
  const engagementsLikelihoodValues = filters.engagementsLikelihood.map(
    (el) => ({
      value: el.toString(),
      label: engagementToLabel(el),
      payload: el,
    })
  );

  if (dataSources.length == 0) return <div />;

  return (
    <div className="filters-bar">
      <div className="filters-bar-wrapper">
        {!enableMultiSources && (
          <FilterSelect
            title="Sources"
            options={sourcesOptions}
            value={sourceValue}
            onChange={handleSelectSource}
            isMulti={false}
          />
        )}
        {enableMultiSources && (
          <FilterSelect
            title="Sources"
            options={sourcesOptions}
            value={sourcesValues}
            onChange={handleSelectSources}
            isMulti={true}
          />
        )}
        {enableBrandAccounts && (
          <FilterSelect
            title="Brand Accounts"
            options={brandAccountsOptions}
            value={brandAccountsValue}
            onChange={handleSelectBrandAccount}
            isMulti={true}
          />
        )}
        {!disableBrands && (
          <FilterSelect
            title="Brands/Companies"
            options={brandsOptions}
            value={brandsValues}
            onChange={handleSelectBrand}
            isMulti={true}
          />
        )}
        {!disableAudiences && (
          <FilterSelect
            title="Audiences"
            options={audiencesOptions}
            value={audiencesValues}
            onChange={handleSelectAudience}
            isMulti={true}
          />
        )}
        {!disableSentiments && (
          <FilterSelect
            title="Sentiments"
            options={sentimentsOptions}
            value={sentimentsValues}
            onChange={handleSelectSentiment}
            isMulti={true}
          />
        )}
        {!disableThemes && (
          <FilterSelect
            title="Themes"
            options={themesOptions}
            value={themesValues}
            onChange={handleSelectTheme}
            isMulti={true}
          />
        )}
        {enableEngagementsLikelihood && (
          <FilterSelect
            title="Engagement Likelihood"
            options={engagementsLikelihoodOptions}
            value={engagementsLikelihoodValues}
            onChange={handleSelectEngagementLikelihood}
            isMulti={true}
          />
        )}
        {enableStarredAccounts && (
          <>
            <label className="hs-checkmark" htmlFor="filters-starred-accounts">
              <input
                id="filters-starred-accounts"
                type="checkbox"
                checked={filters.onlyStarredAccounts}
                onClick={handleToggleOnlyStarredAccounts}
              />
              <span className="checkmark"></span>
              Starred Accounts
            </label>
          </>
        )}
        <div className="filters-buttons">
          {onButtonClicked && (
            <button className="hs-button primary" onClick={onButtonClicked}>
              Save
            </button>
          )}

          {onCancel && (
            <button className="hs-button secondary" onClick={onCancel}>
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({
  children,
  ...props
}: ValueContainerProps<{ label: string; value: string }>) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} innerProps={{}} isFocused={true}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => {
        var x: any;
        x = child;
        return child && x.key !== "placeholder" ? child : null;
      })}
    </ValueContainer>
  );
};

interface FilterSelectProps {
  title: string;
  options: { label: string; value: string }[];
  value:
    | { label: string; value: string }
    | { label: string; value: string }[]
    | null;
  onChange: (string) => void;
  // For our use case, we could derive isMulti from whether or not defaultValue is set or is null
  isMulti: boolean;
}

function FilterSelect({
  title,
  options,
  onChange,
  value,
  isMulti,
}: FilterSelectProps) {
  return (
    <div className="filters-bar-select">
      <Select
        unstyled
        isMulti={isMulti}
        onChange={onChange}
        placeholder={title}
        options={options}
        value={value}
        isSearchable={false}
        styles={{
          container: (baseStyle, state) => ({
            ...baseStyle,
            color: "white",
            minWidth: "120px",
          }),
          control: (baseStyle, state) => ({
            ...baseStyle,
            border: "1px solid #1C3A4A",
            borderRadius: "15px",
            padding: "0 15px",
            cursor: "pointer",
          }),
          indicatorsContainer: (baseStyle, state) => ({
            ...baseStyle,
            color: "#8D9CA6",
            marginLeft: "10px",
          }),
          menuList: (baseStyle, state) => ({
            ...baseStyle,
            maxHeight: "700px",
          }),
          menu: (baseStyle, state) => ({
            ...baseStyle,
            background: "#112936",
            border: "1px solid #1C3A4A",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.45)",
            borderRadius: "14px",
            width: "max-content",
            minWidth: "100%",
            padding: "15px",
          }),
          placeholder: (baseStyle, state) => ({
            ...baseStyle,
            gridArea: "1/1/1/2",
          }),
          singleValue: (baseStyle, state) => ({
            ...baseStyle,
            gridArea: "1/2/2/3",
            background: "#1A394A",
            padding: "5px",
            margin: "0 10px",
            borderRadius: "5px",
            maxWidth: "80px",
          }),
          multiValue: (baseStyle, state) => ({
            ...baseStyle,
            background: "#1A394A",
            padding: "5px",
            margin: "0 10px",
            borderRadius: "5px",
            maxWidth: "80px",
          }),
          option: (baseStyle, state) => ({
            ...baseStyle,
            margin: "10px 0",
            fontWeight: "400",
            "&:hover": {
              fontWeight: "bold",
            },
            cursor: "pointer",
          }),
        }}
        components={{ ValueContainer: CustomValueContainer }}
      />
    </div>
  );
}

export default FiltersBar;
