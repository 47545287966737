import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import PageTemplate from "pages/PageTemplate";

import {
  setIsLoading,
  setAPIToken,
  updateTopic,
  updateDateRange,
  updateFilters,
  setClient,
  setTopics,
  setSources,
  setAudiences,
  setThemes,
  setBrands,
  setBrandAccounts,
  setAudienceAnalysis,
  pushChart,
} from "../redux/actions";
import {
  API_USER_INFO,
  API_USER_CLIENT,
  API_TOPIC,
  API_IDENTITY,
  API_SOURCES,
  API_THEMES,
  API_BRANDS,
  API_BRAND_ACCOUNTS,
} from "../constants/routes";
import { UserManager } from "../utils/authConst";

// A list  of clients that are legacy and should not have access to Health Social.
// This is obvsiouly unsafe to do this in the front-end, but this is supposed to be
// temporary as these legacy clients would either upgrade to Health Social or just stop
// using the platform. The mid-term goal is to give all clients access to the same
// underlying functionalities but just change the branding slighlty depending on
// the client. Ideally there's no need for backend distinction of the client and
// everything can be done in the front end with some superficial changes, so for now
// it seems ok to just apply a very lightweigh layer of security on top of health social.
const LEGACY_CLIENTS = ["fb59e4da-1fa0-4820-96dd-1f2b65aa1970"];
// This is the legacy clients in local dev, for testing.
//const LEGACY_CLIENTS = ['0a50664d-5c50-438d-9be7-ba2db1f5a945']

function WithLoggedIn(
  WrappedComponent: React.ComponentType<any>
): React.ComponentType<any> {
  const LoggedInComponent = (props) => {
    const {
      dataClient,
      dataTopics,
      topic,
      authToken,
      updateTopic,
      setIsLoading,
      setClient,
      setTopics,
      setSources,
      setAudiences,
      setThemes,
      setBrands,
      setBrandAccounts,
      filters,
      updateDateRange,
      updateFilters,
      dataSources,
      setAPIToken,
    } = props;

    const navigate = useNavigate();

    useEffect(() => {
      setIsLoading(true);
      const identityAPI = async (user) => {
        try {
          const response = await fetch(API_IDENTITY, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          });
          setIsLoading(false);
          if (response.status === 401) {
            // Not very clear how we end up here, looks a lot like some weird inconsistency when the token becomes invalid (change of signature key after rollout maybe?).
            // In doubt, we just go with the most powerful option which is to send to the login page.
            console.log(
              "user credentials are invalid, redirecting to login page"
            );
            window.location.href = "/hs/login";
            return;
          }
          if (response.status !== 200) {
            console.error("Unexpected error in identity request, result:");
            console.log(response);
            return;
          }
          setAPIToken(user.access_token);
        } catch (ex) {
          console.log("signout redirect");
          console.log(ex);
          // TODO: let's show a proper error page, and no need to sign out? Or maybe sign out but stay on error page?
          //UserManager.signoutRedirect()
        }
      };

      //UserManager.events.addUserSignedOut(function () {
      //  console.log("User signed out of IdentityServer");
      //});
      UserManager.events.addAccessTokenExpired(function () {
        console.log("Access token has expired!");
        alert(
          "Your session has expired, you will be redirected to the login page."
        );
        UserManager.signoutRedirect();
      });
      //UserManager.querySessionStatus().then(res => console.log(res))
      //UserManager.signinSilent().then(res => {
      //  console.log(res)
      UserManager.getUser().then((user) => {
        console.log("user: ");
        console.log(user);
        if (user && !user.expired) {
          identityAPI(user);
        } else {
          console.log("user is not logged in, redirecting to sign in");
          UserManager.signinRedirect();
        }
      });
      //})
    }, []);

    // Initialize all fixed data once a topic is selected.
    const initializeTopicData = () => {
      if (!topic) return;

      setIsLoading(true);

      // The default date range is always the last year from the available topic data.
      // It can be smaller if there's not enough data.
      const dt_from = new Date(topic.startDate);
      const dt_to = new Date(topic.endtDate);
      // By default, we cap the data that we show to a max range, which is defined to
      // be 3 years. We probably should set 1 year instead but for current demo 3 years is
      // more impactful.
      // TODO: Once we are live with clients, we should change this to one year to improve performance and
      // also because it's more relevant to see the trailing one year than three.
      const defaultYearsToDisplay = 3;
      if (
        dt_to.getTime() - dt_from.getTime() >
        1000 * 3600 * 24 * 365 * defaultYearsToDisplay
      ) {
        // If the topic has more than the default max range, we limit the from/to.
        updateDateRange({
          from: {
            year: dt_to.getFullYear() - defaultYearsToDisplay,
            month: dt_to.getMonth(),
          },
          to: {
            year: dt_to.getFullYear(),
            month: dt_to.getMonth(),
          },
        });
      } else {
        // Otherwise we take all the data available.
        updateDateRange({
          from: {
            year: dt_from.getFullYear(),
            month: dt_from.getMonth(),
          },
          to: {
            year: dt_to.getFullYear(),
            month: dt_to.getMonth(),
          },
        });
      }

      const loadThemes = async () => {
        const response = await fetch(API_THEMES.replace("$1", topic.id), {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();

        setThemes(data);
      };

      const loadBrands = async () => {
        const response = await fetch(API_BRANDS.replace("$1", topic.id), {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setBrands(data);
      };

      const loadBrandAccounts = async () => {
        const response = await fetch(
          API_BRAND_ACCOUNTS.replace("$1", topic.id),
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const data = await response.json();
        setBrandAccounts(data);
      };

      const loadSources = async () => {
        const response = await fetch(API_SOURCES.replace("$1", topic.id), {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        // We want to put Twitter first because it's the most interesting source.
        data.sort((x, y) => {
          if (x.id === y.id) return 0;
          if (x.name === "Twitter") return -1;
          if (y.name === "Twitter") return 1;
          return x.name < y.name ? -1 : 1;
        });

        // TODO: this is not exactly the end of loading, technically we should synchronize with the other loading function,
        // but as a first approximation this is probably good enough to give a good UX.
        setIsLoading(false);

        setSources(data);
        setAudiences(topic.audiences);

        updateFilters({
          source: data[0],
          sources: [],
          audiences: [],
          sentiments: [],
          themes: [],
          brands: [],
          brandAccounts: [],
          engagementsLikelihood: [],
        });
      };
      loadThemes();
      loadBrands();
      loadBrandAccounts();
      loadSources();
    };
    useEffect(() => {
      initializeTopicData();
    }, [topic]);

    // We must initialize some global state that needs to be available in any of the children components.
    // These are the client, the topic and the default filters.
    useEffect(() => {
      // init topics data, only if the topics hasn't been set.

      const loadTopics = async () => {
        const response = await fetch(API_TOPIC, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        if (data.length > 0) {
          const initData = data.map((dt) => ({
            ...dt,
            sources: [],
            chartData: [],
          }));
          setTopics(initData);
          let initialTopic = initData[0];
          if (localStorage.getItem("topic")) {
            const savedTopic = initData.find(
              (d) => d.id == localStorage.getItem("topic")
            );
            if (savedTopic) initialTopic = savedTopic;
          }
          updateTopic(initialTopic);
        } else {
          // TODO: redirect to error page
          alert("No topics found");
        }
      };

      const loadClient = () => {
        fetch(API_USER_CLIENT, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
          .then((res) => res.json())
          .then((res) => {
            var client: AtlasMach.IClient = res;
            if (LEGACY_CLIENTS.includes(client.id.toLowerCase())) {
              console.log(
                "The client is legacy and has no access to Health Social"
              );
              UserManager.signoutRedirect();
            } else {
              setClient(client);
            }
          });
      };

      const loadUser = () => {
        fetch(API_USER_INFO, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
          .then((res) => res.json())
          .then((res) => {
            if (window["smartlook"])
              window["smartlook"]("identify", res.id, {
                email: res.email,
                name: res.name,
              });
          });
      };

      if (authToken && dataTopics.length === 0) loadTopics();
      if (authToken && !dataClient) loadClient();
      if (authToken) loadUser();
    }, [authToken]);

    if (!authToken || !topic || !dataClient || !filters || !dataSources)
      return (
        <PageTemplate title="">
          <div></div>
        </PageTemplate>
      );

    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = (state: AtlasMach.StoreState) => ({
    dataClient: state.data.client,
    dataTopics: state.data.topics,
    dataSources: state.data.sources,
    dataThemes: state.data.themes,
    dataAudiences: state.data.audiences,
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    chartStack: state.data.chartStack,
    authToken: state.data.auth_token,
    starAccounts: state.data.starAccounts,
    audienceAnalysisData: state.data.audienceAnalysis,
  });

  const mapDispatchToProps = {
    setIsLoading,
    updateTopic,
    updateDateRange,
    updateFilters,
    setClient,
    setTopics,
    setSources,
    setAudiences,
    setThemes,
    setBrands,
    setBrandAccounts,
    setAudienceAnalysis,
    pushChart,
    setAPIToken,
  };

  return connect(mapStateToProps, mapDispatchToProps)(LoggedInComponent);
}

export default WithLoggedIn;
