/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import TabbedPageContent from "components/TabbedPageContent";

import "./styles.scss";

function ContentStudioTabbedPageContent(props) {
  const { tab } = useParams();

  const [triggerExport, setTriggerExport] = useState(false);

  const tabs = [
    {
      id: "message-generator",
      label: "Message Generator",
      withCalendar: false,
    },
    {
      id: "content-enhancer",
      label: "Content Enhancer",
      withCalendar: false,
      disabled: true,
    },
    {
      id: "agency-brief",
      label: "Agency Brief",
      withCalendar: false,
      disabled: true,
    },
  ];
  const activeTab = tabs.findIndex(
    (t) => (props.tab && t.id === props.tab) || t.id === tab
  );

  return (
    <TabbedPageContent tabs={tabs} activeTab={activeTab}>
      {props.children}
    </TabbedPageContent>
  );
}

export default ContentStudioTabbedPageContent;
