import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { setIsLoading } from "redux/actions";

import { convertToDt, convertToDt2 } from "utils/convertData";
import moment from "moment";

import PageTemplate from "pages/PageTemplate";
import DOLTabbedPageContent from "./DOLTabbedPageContent";
import FiltersBar from "components/FiltersBar";
import ModuleNotAvailable from "components/ModuleNotAvailable";
import { AtlasLineChart, AtlasHeatMapChart } from "components/AtlasCharts";

import {
  API_HEATMAP,
  API_THEMES_HEATMAP,
  API_HEATMAP_ACCOUNTS,
} from "constants/routes";

const RandomColors = [
  "#78D2F1",
  "#498AEC",
  "#8169E4",
  "#C463F1",
  "#D99F48",
  "#DFEA5F",
  "#5FEA9F",
  "#6AD95E",
  "#DC7758",
];

interface ICentricityReportProps {
  setIsLoading: (loading: boolean) => void;
  topic: AtlasMach.ITopic;
  filters: AtlasMach.UIFilters;
  dateRange: AtlasMach.IDateRange;
  authToken: string;
}

function CentricityReportScreen({
  setIsLoading,
  topic,
  filters,
  dateRange,
  authToken,
}: ICentricityReportProps) {
  const [audiencesCentricity, setAudiencesCentricity] = useState<
    AtlasMach.IHeatMapData[]
  >([]);
  const [loadingAudiencesCentricity, setLoadingAudiencesCentricity] =
    useState(false);
  const [themesInsights, setThemesInsights] = useState<
    AtlasMach.IHeatMapData[]
  >([]);
  const [loadingThemesInsights, setLoadingThemesInsights] = useState(false);
  const [accountsCentricity, setAccountsCentricity] = useState<
    AtlasMach.IHeatAccountData[]
  >([]);
  const [loadingAccountsCentricity, setLoadingAccountsCentricity] =
    useState(false);

  // Currently instagram has anonymous users so we cannot support this module.
  const isModuleAvailable = filters.source.name != "Instagram";

  useEffect(() => {
    setIsLoading(
      loadingAudiencesCentricity ||
        loadingThemesInsights ||
        loadingAccountsCentricity
    );
  }, [
    loadingAudiencesCentricity,
    loadingThemesInsights,
    loadingAccountsCentricity,
  ]);

  const loadAudiencesCentricity = () => {
    setLoadingAudiencesCentricity(true);
    const dt = convertToDt(dateRange.from, dateRange.to);
    fetch(
      API_HEATMAP.replace("$1", topic.id).replace("$2", filters.source.id),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
        }),
      }
    )
      .then((res) => res.json())
      .then((json: AtlasMach.IHeatMapData[]) => {
        setLoadingAudiencesCentricity(false);
        // TODO: this actually doesn't work because the backend doesn't set all the proper labels, and some datapoint might be missing labels.
        // This must be fixed in the backend as it gets very tricky to resolve in the front end if the data is not exhaustive.
        const sortedData = json.map((dataset) => {
          return {
            ...dataset,
            data: dataset.data.sort(
              (a, b) =>
                new Date(a.label).getTime() - new Date(b.label).getTime()
            ),
          };
        });

        // This is a bit of a hack, but there are audiences we don't want to show in the heatmap chart, and currently
        // it's only the "Other" audience, so we filter it out here and re-normalize all the data (also works if there's
        // no such audience in the data, result is just identical).

        //const dataWithoutOthers = sortedData.filter((d) => d.label != "Other");
        //if (dataWithoutOthers.length == 0) {
        //  setAudiencesCentricity(dataWithoutOthers);
        //  return;
        //}

        //for (var i = 0; i < dataWithoutOthers[0].data.length; ++i) {
        //  var total = 0;
        //  dataWithoutOthers.forEach((dataset) => {
        //    if (i < dataset.data.length) total += dataset.data[i].value;
        //  });
        //  dataWithoutOthers.forEach((dataset) => {
        //    if (i < dataset.data.length)
        //      dataset.data[i].value = (dataset.data[i].value / total) * 100;
        //  });
        //}
        //console.log(dataWithoutOthers);
        //setAudiencesCentricity(dataWithoutOthers);
        setAudiencesCentricity(sortedData);
      }); //;
  };

  const loadThemesInsights = () => {
    setLoadingThemesInsights(true);
    const dt = convertToDt(dateRange.from, dateRange.to);
    fetch(
      API_THEMES_HEATMAP.replace("$1", topic.id).replace(
        "$2",
        filters.source.id
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const sortedData = json.map((dataset) => {
          return {
            ...dataset,
            data: dataset.data.sort(
              (a, b) =>
                new Date(a.label).getTime() - new Date(b.label).getTime()
            ),
          };
        });
        setLoadingThemesInsights(false);
        setThemesInsights(sortedData);
      });
  };

  const loadAccountsCentricity = () => {
    setLoadingAccountsCentricity(true);
    const dt = convertToDt(dateRange.from, dateRange.to);
    fetch(
      API_HEATMAP_ACCOUNTS.replace("$1", topic.id).replace(
        "$2",
        filters.source.id
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const sortedData = json.map((dataset) => {
          return {
            ...dataset,
            data: dataset.data.sort(
              (a, b) =>
                new Date(a.label).getTime() - new Date(b.label).getTime()
            ),
          };
        });
        setLoadingAccountsCentricity(false);
        setAccountsCentricity(sortedData);
      });
  };

  useEffect(() => {
    loadAudiencesCentricity();
    loadThemesInsights();
    loadAccountsCentricity();
  }, [filters, dateRange, topic]);

  const audiencesCentricityLabels = useMemo(() => {
    if (audiencesCentricity.length === 0) return [];
    let allLabels: string[] = [];
    audiencesCentricity.forEach((hm) => {
      allLabels.push(...hm.data.map((dt) => dt.label));
    });
    const uniqueLabels = [...new Set(allLabels)].sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    return uniqueLabels;
  }, [audiencesCentricity]);

  const audiencesCentricityData = useMemo(() => {
    const newHeatMap = audiencesCentricity.map((hm) => {
      const normalizedData = audiencesCentricityLabels.map((lbDate) => {
        let existValue = hm.data.find((hmd) => hmd.label === lbDate);
        return {
          label: lbDate,
          value: existValue?.value || 0,
        };
      });

      return { ...hm, data: normalizedData };
    });
    return newHeatMap.sort(
      (a: AtlasMach.IHeatMapData, b: AtlasMach.IHeatMapData) =>
        b.data[0].value - a.data[0].value
    );
  }, [audiencesCentricityLabels]);

  const themesInsightsLabels = useMemo(() => {
    if (themesInsights.length === 0) return [];
    let allLabels: string[] = [];
    themesInsights.forEach((hm) => {
      allLabels.push(...hm.data.map((dt) => dt.label));
    });
    const uniqueLabels = [...new Set(allLabels)].sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    return uniqueLabels;
  }, [themesInsights]);

  const themesInsightsData = useMemo(() => {
    const newHeatThemes = themesInsights.map((hm) => {
      const normalizedData = themesInsightsLabels.map((lbDate) => {
        let existValue = hm.data.find((hmd) => hmd.label === lbDate);
        return {
          label: lbDate,
          value: existValue?.value || 0,
        };
      });

      return { ...hm, data: normalizedData };
    });
    return newHeatThemes.sort(
      (a: AtlasMach.IHeatMapData, b: AtlasMach.IHeatMapData) =>
        b.data[0].value - a.data[0].value
    );
  }, [themesInsightsLabels]);

  const accountsCentricityLabels = useMemo(() => {
    if (accountsCentricity.length === 0) return [];
    let allLabels: string[] = [];
    accountsCentricity.forEach((hm) => {
      allLabels.push(...hm.data.map((dt) => dt.label));
    });
    const uniqueLabels = [...new Set(allLabels)].sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    return uniqueLabels;
  }, [accountsCentricity]);

  const accountsCentricityData = useMemo(() => {
    const newHeatAccounts = accountsCentricity.map((hm, idx) => {
      const normalizedData = accountsCentricityLabels.map((lbDate) => {
        let existValue = hm.data.find((hmd) => hmd.label === lbDate);
        return {
          label: lbDate,
          value: existValue?.value || 0,
        };
      });
      return {
        data: normalizedData,
        color: RandomColors[idx % RandomColors.length],
        label: hm.label,
      };
    });
    return newHeatAccounts;
  }, [accountsCentricityLabels]);

  return (
    <PageTemplate title="Digital Opinion Leaders">
      <DOLTabbedPageContent tab="centricity-report">
        <div className="centricity-report-screen-container">
          <FiltersBar
            disableAudiences={true}
            disableBrands={true}
            disableThemes={true}
            disableSentiments={true}
          />
          {!isModuleAvailable && <ModuleNotAvailable />}
          {isModuleAvailable && (
            <>
              <div className="charts-container">
                <div className="chart-widget chart-widget-full">
                  <div className="chart-widget-header">
                    <div className="chart-widget-header-title">
                      Audience Centricity
                    </div>
                  </div>
                  <div>
                    <AtlasHeatMapChart
                      labels={audiencesCentricityLabels.map((hml) =>
                        moment(hml).format("MMM-YY")
                      )}
                      datasets={audiencesCentricityData.map((lb, idx) => ({
                        name: lb.label,
                        color: lb.color,
                        data: lb.data.map((d) => d.value),
                      }))}
                      showLegend={true}
                    />
                  </div>
                </div>

                <div className="chart-widget chart-widget-full">
                  <div className="chart-widget-header">
                    <div className="chart-widget-header-title">
                      Theme Insights
                    </div>
                  </div>
                  <div>
                    <AtlasHeatMapChart
                      labels={themesInsightsLabels.map((hml) =>
                        moment(hml).format("MMM-YY")
                      )}
                      datasets={themesInsightsData.map((lb, idx) => ({
                        name: lb.label,
                        color: lb.color,
                        data: lb.data.map((d) => d.value),
                      }))}
                      showLegend={true}
                    />
                  </div>
                </div>

                <div className="chart-widget chart-widget-full">
                  <div className="chart-widget-header">
                    <div className="chart-widget-header-title">
                      Starred Account Centricity
                    </div>
                  </div>
                  <div>
                    <AtlasLineChart
                      labels={accountsCentricityLabels.map((hml) =>
                        moment(hml).format("MMM-YY")
                      )}
                      linesData={accountsCentricityData.map((lb, idx) => ({
                        name: lb.label,
                        color: lb.color,
                        data: lb.data.map((d) => d.value),
                      }))}
                      showLegend={true}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </DOLTabbedPageContent>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {
  setIsLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CentricityReportScreen);
