/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import TabbedPageContent from "components/TabbedPageContent";

import "./styles.scss";
import "../tab-pages-common-styles.scss";

function PollVerseTabbedPageContent(props) {
  const { tab } = useParams();

  const [triggerExport, setTriggerExport] = useState(false);

  const tabs = [
    // TODO: check why path not updating
    {
      id: "legislators",
      label: "Legislators",
      withCalendar: false,
    },
  ];
  const activeTab = tabs.findIndex(
    (t) => (props.tab && t.id === props.tab) || t.id === tab
  );

  return (
    <TabbedPageContent tabs={tabs} activeTab={activeTab}>
      {props.children}
    </TabbedPageContent>
  );
}

export default PollVerseTabbedPageContent;
