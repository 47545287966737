/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { setIsLoading } from "redux/actions";

import PageTemplate from "pages/PageTemplate";
import ListeningTabbedPageContent from "./ListeningTabbedPageContent";
import FiltersBar from "components/FiltersBar";

import { ReactComponent as GarbageBinIcon } from "assets/icons/garbage_bin.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";

import { AtlasBarChart } from "components/AtlasCharts";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down.svg";
import { API_LISTENING_MESSAGES_DISTRIBUTION } from "constants/routes";

import { convertToDt, convertToDt2, compressJson } from "utils/convertData";

const BarsColors = [
  "#78D2F1",
  "#498AEC",
  "#8169E4",
  "#C463F1",
  "#D99F48",
  "#DFEA5F",
  "#5FEA9F",
  "#6AD95E",
  "#DC7758",
];
const LinesColors = [
  "red",
  "green",
  "orange",
  "white",
  "yellow",
  "blue",
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
  randomColor(),
];

function randomColor() {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return `rgb(${r},${g},${b})`;
}

interface IProps {
  setIsLoading: (loading: boolean) => void;
  topic: AtlasMach.ITopic;
  dateRange: AtlasMach.IDateRange;
  filters: AtlasMach.UIFilters;
  authToken: string;
}

function ThemesFitGapScreen({
  setIsLoading,
  topic,
  dateRange,
  filters,
  authToken,
}: IProps) {
  const [audiencesThemesBars, setAudiencesThemesBars] = useState<{
    [key: string]: AtlasMach.IPieData[];
  }>({});
  const [accountsThemesBars, setAccountsThemesBars] = useState<{
    [key: string]: AtlasMach.IPieData[];
  }>({});

  const [isKeyLoading, setIsKeyLoading] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    //if(Object.keys(isKeyLoading).length == 0)
    //  setIsLoading(false)
    setIsLoading(Object.keys(isKeyLoading).some((key) => isKeyLoading[key]));
  }, [isKeyLoading]);

  const loadThemesChart = (audience: AtlasMach.IAudience) => {
    setIsKeyLoading((old) => {
      var n = { ...old };
      n[audience.name] = true;
      return n;
    });
    const dt = convertToDt2(dateRange.from, dateRange.to);
    let baseUrl = API_LISTENING_MESSAGES_DISTRIBUTION.replace(
      "$1",
      topic.id
    ).replace("$2", "theme");
    let apiUrl = `${baseUrl}?startDate=${dt.dt_from}&endDate=${dt.dt_to}`;
    apiUrl += `&sourceFilters=${filters.source.id}`;
    apiUrl += `&audienceFilters=${audience.id}`;
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setIsKeyLoading((old) => {
          var n = { ...old };
          n[audience.name] = false;
          return n;
        });
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        // We must order the datapoint in each chart as there's no guaranteed order when receiving the distribution of themes.
        let themesChart: AtlasMach.IPieData[] = jsonData;
        themesChart.sort((x, y) => {
          if (x.label < y.label) return -1;
          else if (x.label > y.label) return 1;
          return 0;
        });
        var total = 0;
        themesChart.forEach((d) => {
          total += d.value;
        });
        setAudiencesThemesBars((old) => {
          var newAudiencesThemesBars: { [key: string]: AtlasMach.IPieData[] } =
            { ...old };
          newAudiencesThemesBars[audience.name] = themesChart.map((d) => ({
            ...d,
            value: total == 0 ? 0 : (d.value / total) * 100,
          }));
          return newAudiencesThemesBars;
        });
      });
  };
  const loadAccountThemesChart = (account: any /*AtlasMach.IAccount*/) => {
    setIsKeyLoading((old) => {
      var n = { ...old };
      n[account.accountName] = true;
      return n;
    });
    const dt = convertToDt2(dateRange.from, dateRange.to);
    let baseUrl = API_LISTENING_MESSAGES_DISTRIBUTION.replace(
      "$1",
      topic.id
    ).replace("$2", "theme");
    let apiUrl = `${baseUrl}?startDate=${dt.dt_from}&endDate=${dt.dt_to}`;
    apiUrl += `&sourceFilters=${filters.source.id}`;
    apiUrl += `&accountFilters=${account.id}`;
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setIsKeyLoading((old) => {
          var n = { ...old };
          n[account.accountName] = false;
          return n;
        });
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        // We must order the datapoint in each chart as there's no guaranteed order when receiving the distribution of themes.
        let themesChart: AtlasMach.IPieData[] = jsonData;
        themesChart.sort((x, y) => {
          if (x.label < y.label) return -1;
          else if (x.label > y.label) return 1;
          return 0;
        });
        var total = 0;
        themesChart.forEach((d) => {
          total += d.value;
        });
        setAccountsThemesBars((old) => {
          var newAccountsThemesBars: { [key: string]: AtlasMach.IPieData[] } = {
            ...old,
          };
          newAccountsThemesBars[account.accountName] = themesChart.map((d) => ({
            ...d,
            value: total == 0 ? 0 : (d.value / total) * 100,
          }));
          return newAccountsThemesBars;
        });
      });
  };

  useEffect(() => {
    setAudiencesThemesBars({});
    setAccountsThemesBars({});
    filters.audiences.map((audience) => loadThemesChart(audience));
    filters.brandAccounts.map((account) => loadAccountThemesChart(account));
  }, [filters, dateRange]);

  const determineThemesBarsLabels = () => {
    if (
      Object.keys(audiencesThemesBars).length === 0 &&
      Object.keys(accountsThemesBars).length === 0
    )
      return [];
    if (Object.keys(audiencesThemesBars).length > 0) {
      const data = audiencesThemesBars[Object.keys(audiencesThemesBars)[0]];
      if (!data) return [];
      return data.map((b) => b.label);
    } else {
      const data = accountsThemesBars[Object.keys(accountsThemesBars)[0]];
      if (!data) return [];
      return data.map((b) => b.label);
    }
  };
  const themesBarsLabels = determineThemesBarsLabels();

  return (
    <PageTemplate title="Listening">
      <ListeningTabbedPageContent tab="themes-fit-gap">
        <div className="listening-benchmarking-container">
          <FiltersBar
            enableBrandAccounts={true}
            disableBrands={true}
            disableSentiments={true}
            disableThemes={true}
          />
          <div className="charts-container">
            <div className="chart-widget chart-widget-full">
              <div className="chart-widget-header">
                <div className="chart-widget-header-title">THEMES</div>
              </div>
              <div>
                {(Object.keys(audiencesThemesBars).length > 0 ||
                  Object.keys(accountsThemesBars).length > 0) && (
                  <AtlasBarChart
                    labels={themesBarsLabels}
                    datasets={Object.keys(audiencesThemesBars).map(
                      (audience, idx) => ({
                        name: audience,
                        data: audiencesThemesBars[audience].map((x) =>
                          Math.floor(x.value)
                        ),
                        color: BarsColors[idx],
                      })
                    )}
                    lines={Object.keys(accountsThemesBars).map(
                      (account, idx) => ({
                        name: account,
                        data: accountsThemesBars[account].map((x) =>
                          Math.floor(x.value)
                        ),
                        color: LinesColors[idx],
                      })
                    )}
                    showLegend={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ListeningTabbedPageContent>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error(
      "topic must be set to initialize ThemesFitGapScreen component"
    );
  if (!state.ui.filters)
    throw new Error(
      "Filters must be set to initialize ThemesFitGapScreen component"
    );

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemesFitGapScreen);
