import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PageTemplate from "pages/PageTemplate";
import PollVerseTabbedPageContent from "./PollVerseTabbedPageContent";
import { ReactComponent as MapSVG } from "assets/images/us-annotated.svg";
import { ReactComponent as TennesseeMapSVG } from "assets/images/tennessee-map.svg";
import Select, { components, ValueContainerProps } from "react-select";
import "../../../components/FiltersBar/styles.scss";

import { config } from "../../../config";
import AccountPopup from "pages/DOLModule/AccountPopup";

import { politicians, interviewsDb } from "../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface IExamplePost {
  permalink: string;
  text: string;
}

interface IAudienceResponse {
  response: string;
  examplePosts: IExamplePost[];
}

/** TODO: Take from FiltersBar */

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({
  children,
  ...props
}: ValueContainerProps<{ label: string; value: string }>) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} innerProps={{}} isFocused={true}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => {
        var x: any;
        x = child;
        return child && x.key !== "placeholder" ? child : null;
      })}
    </ValueContainer>
  );
};

interface FilterSelectProps {
  title: string;
  options: { label: string; value: string }[];
  value:
    | { label: string; value: string }
    | { label: string; value: string }[]
    | null;
  onChange: (string) => void;
  // For our use case, we could derive isMulti from whether or not defaultValue is set or is null
  isMulti: boolean;
}

function FilterSelect({
  title,
  options,
  onChange,
  value,
  isMulti,
}: FilterSelectProps) {
  return (
    <div className="filters-bar-select">
      <Select
        unstyled
        isMulti={isMulti}
        onChange={onChange}
        placeholder={title}
        options={options}
        value={value}
        isSearchable={false}
        styles={{
          container: (baseStyle, state) => ({
            ...baseStyle,
            color: "white",
            minWidth: "120px",
          }),
          control: (baseStyle, state) => ({
            ...baseStyle,
            border: "1px solid #1C3A4A",
            borderRadius: "15px",
            padding: "0 15px",
            cursor: "pointer",
          }),
          indicatorsContainer: (baseStyle, state) => ({
            ...baseStyle,
            color: "#8D9CA6",
            marginLeft: "10px",
          }),
          menuList: (baseStyle, state) => ({
            ...baseStyle,
            maxHeight: "700px",
          }),
          menu: (baseStyle, state) => ({
            ...baseStyle,
            background: "#112936",
            border: "1px solid #1C3A4A",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.45)",
            borderRadius: "14px",
            width: "max-content",
            minWidth: "100%",
            padding: "15px",
          }),
          placeholder: (baseStyle, state) => ({
            ...baseStyle,
            gridArea: "1/1/1/2",
          }),
          singleValue: (baseStyle, state) => ({
            ...baseStyle,
            gridArea: "1/2/2/3",
            background: "#1A394A",
            padding: "5px",
            margin: "0 10px",
            borderRadius: "5px",
            maxWidth: "280px",
          }),
          multiValue: (baseStyle, state) => ({
            ...baseStyle,
            background: "#1A394A",
            padding: "5px",
            margin: "0 10px",
            borderRadius: "5px",
            maxWidth: "280px",
          }),
          option: (baseStyle, state) => ({
            ...baseStyle,
            margin: "10px 0",
            fontWeight: "400",
            "&:hover": {
              fontWeight: "bold",
            },
            cursor: "pointer",
          }),
        }}
        components={{ ValueContainer: CustomValueContainer }}
      />
    </div>
  );
}

const allStates = [
  { value: "all", label: "USA" },
  { value: "connecticut", label: "Connecticut" },
  { value: "tennessee", label: "Tennessee" },
  { value: "texas", label: "Texas" },
  { value: "utah", label: "Utah" },
  { value: "vermont", label: "Vermont" },
  { value: "virginia", label: "Virginia" },
  { value: "washington", label: "Washington" },
  { value: "show more", label: "Show more" },
];

const positionOptions = {
  0: { label: "Con", color: "#CB1D1D" },
  1: { label: "Neutral", color: "#C6C627" },
  2: { label: "Pro", color: "#68C95E" },
  99: { label: "Unknown", color: "#FFFFFF" },
};

const tennesseeDistricts = {
  "gun control": [0, 0, 99, 99, 99, 99, 99, 99, 2],
  healthcare: [0, 0, 99, 99, 99, 99, 99, 99, 2],
  immigration: [0, 2, 99, 99, 99, 99, 99, 99, 2],
  "inflation and prices": [0, 0, 99, 99, 99, 99, 99, 99, 2],
  abortion: [0, 0, 99, 99, 99, 99, 99, 99, 2],
  "civil liberties": [99, 99, 99, 99, 99, 99, 99, 99, 99],
  "civil rights": [0, 2, 99, 99, 99, 99, 99, 99, 2],
  crime: [0, 2, 99, 99, 99, 99, 99, 99, 2],
  climate: [0, 0, 99, 99, 99, 99, 99, 99, 2],
  "foreign policy": [2, 0, 99, 99, 99, 99, 99, 99, 2],
  jobs: [0, 0, 99, 99, 99, 99, 99, 99, 2],
  "national security": [99, 99, 99, 99, 99, 99, 99, 99, 99],
  taxes: [0, 0, 99, 99, 99, 99, 99, 99, 2],
};

const topics = [
  {
    value: "gun control",
    label: "Gun Control",
    interviewsDbKey: "Guns",
  },
  {
    value: "healthcare",
    label: "Healthcare",
    interviewsDbKey: "Healthcare",
  },
  {
    value: "immigration",
    label: "Immigration",
    interviewsDbKey: "Immigration",
  },
  {
    value: "inflation and prices",
    label: "Inflation and Prices",
    interviewsDbKey: "Inflation and Prices",
  },
  {
    value: "abortion",
    label: "Abortion",
    interviewsDbKey: "Abortion",
  },
  {
    value: "civil liberties",
    label: "Civil Liberties",
    interviewsDbKey: "Civil Liberties",
  },
  {
    value: "civil rights",
    label: "Civil Rights",
    interviewsDbKey: "Civil Rights",
  },
  {
    value: "crime",
    label: "Crime",
    interviewsDbKey: "Crime",
  },
  {
    value: "climate",
    label: "Environmental and Climate Policy",
    interviewsDbKey: "Environmental and Climate Policy",
  },
  {
    value: "foreign policy",
    label: "Foreign Policy (Including Ukraine Conflict)",
    interviewsDbKey: "Foreign Policy (Including Ukraine Conflict",
  },
  {
    value: "jobs",
    label: "Jobs & the Economy",
    interviewsDbKey: "Jobs & the Economy",
  },
  {
    value: "national security",
    label: "National Security",
    interviewsDbKey: "National Security",
  },
  {
    value: "taxes",
    label: "Taxes",
    interviewsDbKey: "Taxes",
  },
];

function FiltersBarPollverse({
  changeUsState,
  usState,
  onTopicChange,
  topic,
}: {
  changeUsState: any;
  usState: string;
  onTopicChange: any;
  topic: any;
}) {
  return (
    <div className="filters-bar">
      <div className="filters-bar-wrapper">
        <FilterSelect
          title="State"
          options={allStates}
          value={allStates.filter((v) => v.value === usState)} //{ value: "all", label: "USA" }
          onChange={(e) => {
            if (e.length > 0) changeUsState(e.slice(-1)[0].value);
            else changeUsState("all");
          }}
          isMulti={true}
        />
        <FilterSelect
          title="Topics"
          options={topics}
          value={topic}
          onChange={(e) => {
            onTopicChange(e);
          }}
          isMulti={false}
        />
      </div>
    </div>
  );
}

function TennesseeMap({ topic }: { topic: any }) {
  useEffect(() => {
    if (!tennesseeDistricts[topic.value]) alert("Please select other topic");

    tennesseeDistricts[topic.value].forEach((value, index) => {
      const elemDistrict = document.getElementById(`TN-${index + 1}`);

      if (!elemDistrict) {
        console.log("District not found");
        return;
      }

      elemDistrict.setAttribute("fill", positionOptions[value].color);
    });
  }, [topic]);

  return <TennesseeMapSVG />;
}

function USStateMap({
  usState,
  changeUsState,
  topic,
}: {
  usState: string;
  changeUsState: any;
  topic: any;
}) {
  const [politicianPopupIndex, setPoliticianPopupIndex] = useState<number>(-1); // politician index

  useEffect(() => {
    setPoliticianPopupIndex(-1);

    // Listen to mouse events on Tennessee state
    const elemTN = document.getElementById("TN");

    if (elemTN) {
      elemTN.onclick = function () {
        changeUsState("tennessee");
      };

      // Hover style
      // Add a mouseenter event listener to change the hover styles
      elemTN.addEventListener("mouseenter", function () {
        elemTN.style.cursor = "pointer";
      });

      // Add a mouseleave event listener to revert to the original styles
      elemTN.addEventListener("mouseleave", function () {
        elemTN.style.cursor = "initial";
      });
    }

    // Listen to mouse events on district 9 (Cohen) of Tennessee
    const elemTNDistrictCohen = document.getElementById("TN-9");

    if (elemTNDistrictCohen) {
      elemTNDistrictCohen.ondblclick = function () {
        setPoliticianPopupIndex(2); // Cohen index
      };

      // Hover style
      // Add a mouseenter event listener to change the hover styles
      elemTNDistrictCohen.addEventListener("mouseenter", function () {
        elemTNDistrictCohen.style.cursor = "pointer";
      });

      // Add a mouseleave event listener to revert to the original styles
      elemTNDistrictCohen.addEventListener("mouseleave", function () {
        elemTNDistrictCohen.style.cursor = "initial";
      });
    }

    // Listen to mouse events on district 1 (Harshbarger) of Tennessee
    const elemTNDistrictBurchett = document.getElementById("TN-2");

    if (elemTNDistrictBurchett) {
      elemTNDistrictBurchett.ondblclick = function () {
        setPoliticianPopupIndex(4); // Cohen index
      };

      // Hover style
      // Add a mouseenter event listener to change the hover styles
      elemTNDistrictBurchett.addEventListener("mouseenter", function () {
        elemTNDistrictBurchett.style.cursor = "pointer";
      });

      // Add a mouseleave event listener to revert to the original styles
      elemTNDistrictBurchett.addEventListener("mouseleave", function () {
        elemTNDistrictBurchett.style.cursor = "initial";
      });
    }
  }, [usState, topic]);

  return (
    <div className="pollverse-map-container">
      <div className="pollverse-map">
        {usState === "all" ? (
          <MapSVG />
        ) : usState === "tennessee" ? (
          <TennesseeMap topic={topic} />
        ) : (
          <></>
        )}
      </div>
      <div className="pollverse-map-labels">
        <div>
          <div className="pollverse-label-bubble con"></div>
          <div>Con</div>
        </div>
        <div>
          <div className="pollverse-label-bubble neutral"></div>
          <div>Neutral</div>
        </div>
        <div>
          <div className="pollverse-label-bubble pro"></div>
          <div>Pro</div>
        </div>
      </div>
      {politicianPopupIndex >= 0 && (
        <div className="pollverse-response-card-wrapper">
          <div className="pollverse-response-card">
            {/** TODO: rename */}
            <div
              className="popup-close"
              onClick={() => setPoliticianPopupIndex(-1)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <div>
              <img
                src={politicians[politicianPopupIndex].picture}
                width={250}
              />
            </div>
            <div>
              <div className="pollverse-response-card-name">
                <div>{politicians[politicianPopupIndex].formalName}</div>
                <div className="pollverse-party-bubble democrat"></div>
              </div>
              <div className="pollverse-response-card-response">
                {
                  interviewsDb[politicians[politicianPopupIndex].name][
                    topic.interviewsDbKey
                  ].summary
                }
              </div>
              <div className="pollverse-example-posts">
                <Link to="/pollverse/voices/legislators">
                  <div className="hs-button primary">Go to Chat</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function HeatMapScreen({ topic, filters, brands, authToken }) {
  const [usState, setUsState] = useState<string>("all");
  const [selectedTopic, setSelectedTopic] = useState<any>(topics[0]);

  return (
    <PageTemplate title={config.POLLVERSE.BRANDING.TITLE}>
      <PollVerseTabbedPageContent tab={"heat-map"}>
        <div>
          <FiltersBarPollverse
            usState={usState}
            changeUsState={setUsState}
            onTopicChange={setSelectedTopic}
            topic={selectedTopic}
          />
          <USStateMap
            usState={usState}
            changeUsState={setUsState}
            topic={selectedTopic}
          />
        </div>
      </PollVerseTabbedPageContent>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    brands: state.data.brands,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeatMapScreen);
