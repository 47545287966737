/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setIsLoading, updateFilters } from "redux/actions";

import { Chart as ChartJS } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import PageTemplate from "pages/PageTemplate";
import ListeningTabbedPageContent from "./ListeningTabbedPageContent";
import FiltersBar from "components/FiltersBar";
import { AtlasLineChart, AtlasValueCountChart } from "components/AtlasCharts";
import {
  HS_LISTENING_CONTENT_FEED,
  API_LISTENING_MESSAGES_COUNT,
  API_LISTENING_MESSAGES_DISTRIBUTION,
  API_CHART_EXPORT,
} from "constants/routes";
import { convertToDt, convertToDt2 } from "utils/convertData";

interface ILineData {
  date: string;
  value: number;
}

interface IVoiceExport {
  subTopic: string;
  audience: string;
  key: string;
  message: string;
  messageDate: string;
  messageLink: string;
  messageSentiment: string;
  theme: string;
}

interface IListeningSummaryScreen {
  setIsLoading: (loading: boolean) => void;
  dataThemes: AtlasMach.ITheme[];
  dataAudiences: AtlasMach.IAudience[];
  dateRange: AtlasMach.IDateRange;
  topic: AtlasMach.ITopic;
  filters: AtlasMach.UIFilters;
  updateFilters: (filters: AtlasMach.UIFilters) => void;
  authToken: string;
}

function SummaryScreen({
  topic,
  dataThemes,
  dataAudiences,
  dateRange,
  filters,
  updateFilters,
  setIsLoading,
  authToken,
}: IListeningSummaryScreen) {
  const [lineData, setLineData] = useState<ILineData[]>([]);
  const [loadingLineData, setLoadingLineData] = useState<boolean>(false);

  const [sourceData, setSourceData] = useState<AtlasMach.IPieData[]>([]);
  const [loadingSourceData, setLoadingSourceData] = useState<boolean>(false);

  const [sentimentData, setSentimentData] = useState<AtlasMach.IPieData[]>([]);
  const [loadingSentimentData, setLoadingSentimentData] =
    useState<boolean>(false);

  const [themeData, setThemeData] = useState<AtlasMach.IPieData[]>([]);
  const [loadingThemeData, setLoadingThemeData] = useState<boolean>(false);

  const [audienceData, setAudienceData] = useState<AtlasMach.IPieData[]>([]);
  const [loadingAudienceData, setLoadingAudienceData] =
    useState<boolean>(false);

  const [hashtagData, setHashtagData] = useState<AtlasMach.IBarData>();
  const [loadingHashtagData, setLoadingHashtagData] = useState<boolean>(false);

  const [sentimentMessages, setSentimentMessages] = useState<
    AtlasMach.IPieMessages[]
  >([]);
  const [themeMessages, setThemeMessages] = useState<AtlasMach.IPieMessages[]>(
    []
  );
  const [audienceMessages, setAudienceMessages] = useState<
    AtlasMach.IPieMessages[]
  >([]);
  const [sourceMessages, setSourceMessages] = useState<
    AtlasMach.IPieMessages[]
  >([]);

  const [selectedTheme, setSelectedTheme] = useState<AtlasMach.IPieData>();
  const [selectedAudience, setSelectedAudience] =
    useState<AtlasMach.IPieData>();
  const [selectedSentiment, setSelectedSentiment] =
    useState<AtlasMach.IPieData>();
  const [selectedSource, setSelectedSource] = useState<AtlasMach.IPieData>();

  const [selectedThemeAccount, setSelectedThemeAccount] =
    useState<AtlasMach.INode>();
  const [themeAccountMsg, setThemeAccountMsg] =
    useState<AtlasMach.IAccountMessages>();

  const [selectedAudienceAccount, setSelectedAudienceAccount] =
    useState<AtlasMach.INode>();
  const [audienceAccountMsg, setAudienceAccountMsg] =
    useState<AtlasMach.IAccountMessages>();

  const [selectedSentimentAccount, setSelectedSentimentAccount] =
    useState<AtlasMach.INode>();
  const [sentimentAccountMsg, setSentimentAccountMsg] =
    useState<AtlasMach.IAccountMessages>();

  const [selectedSourceAccount, setSelectedSourceAccount] =
    useState<AtlasMach.INode>();
  const [sourceAccountMsg, setSourceAccountMsg] =
    useState<AtlasMach.IAccountMessages>();

  const [hiddenSentiments, setHiddenSentiments] = useState<string[]>([]);
  const [hiddenThemes, setHiddenThemes] = useState<string[]>([]);
  const [hiddenAudiences, setHiddenAudiences] = useState<string[]>([]);
  //const [hiddenSources, setHiddenSources] = useState<string[]>([]);

  const sourceChartRef = useRef<any>();
  const sentimentChartRef = useRef<any>();
  const themeChartRef = useRef<any>();
  const audienceChartRef = useRef<any>();

  const navigate = useNavigate();

  const buildUrlWithFilters = (baseUrl) => {
    const dt = convertToDt2(dateRange.from, dateRange.to);
    let url = `${baseUrl}?startDate=${dt.dt_from}&endDate=${dt.dt_to}`;
    filters.sources.forEach((source) => {
      url += `&sourceFilters=${source.id}`;
    });
    filters.audiences.forEach((audience) => {
      url += `&audienceFilters=${audience.id}`;
    });
    filters.sentiments.forEach((sentiment) => {
      url += `&sentimentFilters=${sentiment}`;
    });
    filters.themes.forEach((theme) => {
      url += `&themeFilters=${theme.id}`;
    });
    filters.brands.forEach((brand) => {
      url += `&brandFilters=${brand.id}`;
    });
    return url;
  };

  useEffect(() => {
    setIsLoading(
      loadingLineData ||
        loadingSourceData ||
        loadingThemeData ||
        loadingSentimentData ||
        loadingAudienceData ||
        loadingHashtagData
    );
  }, [
    loadingLineData,
    loadingSourceData,
    loadingThemeData,
    loadingSentimentData,
    loadingAudienceData,
    loadingHashtagData,
  ]);

  const loadLineChart = () => {
    if (loadingLineData) return;
    setLoadingLineData(true);
    const apiUrl = buildUrlWithFilters(
      API_LISTENING_MESSAGES_COUNT.replace("$1", topic.id)
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setLoadingLineData(false);
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        setLineData(jsonData);
      });
  };

  const loadSourceChart = () => {
    if (loadingSourceData) return;
    setLoadingSourceData(true);
    const apiUrl = buildUrlWithFilters(
      API_LISTENING_MESSAGES_DISTRIBUTION.replace("$1", topic.id).replace(
        "$2",
        "source"
      )
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setLoadingSourceData(false);
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((json) => {
        setSourceData(json);
      });
  };

  const loadSentimentChart = () => {
    if (loadingSentimentData) return;
    setLoadingSentimentData(true);
    const apiUrl = buildUrlWithFilters(
      API_LISTENING_MESSAGES_DISTRIBUTION.replace("$1", topic.id).replace(
        "$2",
        "sentiment"
      )
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setLoadingSentimentData(false);
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((json) => {
        setSentimentData(
          json
            .filter((x) => x.label != "NULL")
            .map((x) => {
              if (x.label === "Positive") return { ...x, color: "green" };
              if (x.label === "Neutral") return { ...x, color: "yellow" };
              if (x.label === "Negative") return { ...x, color: "red" };
              return x;
            })
        );
      });
  };

  const loadThemeChart = () => {
    if (loadingThemeData) return;
    setLoadingThemeData(true);
    const apiUrl = buildUrlWithFilters(
      API_LISTENING_MESSAGES_DISTRIBUTION.replace("$1", topic.id).replace(
        "$2",
        "theme"
      )
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setLoadingThemeData(false);
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        setThemeData(jsonData);
      });
  };

  const loadAudienceChart = () => {
    if (loadingAudienceData) return;
    setLoadingAudienceData(true);
    const apiUrl = buildUrlWithFilters(
      API_LISTENING_MESSAGES_DISTRIBUTION.replace("$1", topic.id).replace(
        "$2",
        "audience"
      )
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setLoadingAudienceData(false);
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        setAudienceData(jsonData);
      });
  };

  const loadHashtagChart = (limit: number = 0) => {
    if (loadingHashtagData) return;

    setLoadingHashtagData(true);

    const apiUrl =
      buildUrlWithFilters(
        API_LISTENING_MESSAGES_DISTRIBUTION.replace("$1", topic.id).replace(
          "$2",
          "hashtag"
        )
      ) + `&limit=${limit}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setLoadingHashtagData(false);
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        var barData = {
          labels: jsonData.map((ele) => `#${ele.label}`),
          datasets: [
            {
              label: "Popular Hashtags",
              data: [] as number[],
              backgroundColor: [] as string[],
              borderColor: [] as string[],
              borderWidth: 1,
              axis: "y",
              barPercentage: 1,
              barThickness: 20,
              borderRadius: 8,
            },
          ],
          id: "hashtag-bar-chart",
        };

        jsonData.forEach((element) => {
          barData.datasets[0].data.push(element.value);
          barData.datasets[0].backgroundColor.push("#78D2F1"); // element.color
          barData.datasets[0].borderColor.push("#78D2F1"); // element.color
        });

        setHashtagData(barData);
      });
  };

  //const loadSentimentMessages = async (option, sentimentId) => {
  //  const dt = convertToDt(option.dateFrom, option.dateTo)
  //  const response = await fetch(API_SENTIMENTMESSAGE_SOURCE.replace('$1', topic.id).replace('$2', option.source).replace('$3', sentimentId), {
  //    method: 'POST',
  //    headers: {
  //      'Accept': 'application/json',
  //      'Content-Type': 'application/json',
  //      Authorization: `Bearer ${authToken}`
  //    },
  //    body: JSON.stringify({
  //      startDate: dt.dt_from,
  //      endDate: dt.dt_to,
  //      selectedAudiences: option.audiences,
  //      selectedSubtopics: option.subtopics
  //    })
  //  })
  //  const data = await response.json()
  //  return data
  //}

  //const loadThemeMessages = async (option, themeId) => {
  //  const dt = convertToDt(option.dateFrom, option.dateTo)
  //  const response = await fetch(API_THEMEMESSAGE_SOURCE.replace('$1', topic.id).replace('$2', option.source).replace("$3", themeId), {
  //    method: 'POST',
  //    headers: {
  //      'Accept': 'application/json',
  //      'Content-Type': 'application/json',
  //      Authorization: `Bearer ${authToken}`
  //    },
  //    body: JSON.stringify({
  //      startDate: dt.dt_from,
  //      endDate: dt.dt_to,
  //      selectedAudiences: option.audiences,
  //      selectedSubtopics: option.subtopics
  //    })
  //  })
  //  const data = await response.json()
  //  return data
  //}

  //const loadAudienceMessages = async (option, audienceId) => {
  //  const dt = convertToDt(option.dateFrom, option.dateTo)
  //  const response = await fetch(API_AUDIENCEMESSAGE_SOURCE.replace('$1', topic.id).replace('$2', option.source).replace("$3", audienceId), {
  //    method: 'POST',
  //    headers: {
  //      'Accept': 'application/json',
  //      'Content-Type': 'application/json',
  //      Authorization: `Bearer ${authToken}`
  //    },
  //    body: JSON.stringify({
  //      startDate: dt.dt_from,
  //      endDate: dt.dt_to,
  //      selectedAudiences: option.audiences,
  //      selectedSubtopics: option.subtopics
  //    })
  //  })
  //  const data = await response.json()
  //  return data
  //}

  //const loadSourceMessages = async (filters, sourceId) => {
  //  const dt = convertToDt(filters.dateFrom, filters.dateTo)
  //  const response = await fetch(API_SOURCECHART_MESSAGE.replace('$1', topic.id).replace("$2", sourceId), {
  //    method: 'POST',
  //    headers: {
  //      'Accept': 'application/json',
  //      'Content-Type': 'application/json',
  //      Authorization: `Bearer ${authToken}`
  //    },
  //    body: JSON.stringify({
  //      startDate: dt.dt_from,
  //      endDate: dt.dt_to,
  //      selectedAudiences: [],
  //      selectedSubtopics: []
  //    })
  //  })
  //  const data = await response.json()
  //  return data
  //}

  //const loadAccountMessages = async (topic, from, to, accountId, sourceId) => {
  //  const response = await fetch(API_MESSAGE.replace('$1', topic), {
  //    method: 'POST',
  //    headers: {
  //      'Accept': 'application/json',
  //      'Content-Type': 'application/json',
  //      Authorization: `Bearer ${authToken}`
  //    },
  //    body: JSON.stringify({
  //      dateRange: {
  //        startDate: from,
  //        endDate: to
  //      },
  //      accountId: accountId,
  //      sourceId: sourceId
  //    })
  //  })
  //  const data = await response.json()
  //  return data
  //}

  const lineLabels = useMemo(() => {
    // We don't display every single date label.
    const maxDisplayed = 10;
    const showIndex = Math.ceil(lineData.length / maxDisplayed);
    var res: string[] = [];
    for (var i = 0; i < lineData.length; i++) {
      if (i % showIndex == 0)
        res.push(new Date(lineData[i].date).toLocaleDateString());
      else res.push("");
    }
    return res;
  }, [lineData]);

  const totalMentions = useMemo(() => {
    return lineData.reduce((prev, v) => prev + v.value, 0);
  }, [lineData]);

  const suggestedMax = useMemo(() => {
    if (lineData.length === 0) return 100;
    const maxValue = lineData
      .map((ld) => ld.value)
      .reduce(function (p, v) {
        return p > v ? p : v;
      });
    return Math.round(maxValue / 10 + 1) * 10;
  }, [lineData]);

  const loadChartsData = () => {
    loadLineChart();
    loadSourceChart();
    loadSentimentChart();
    loadThemeChart();
    loadAudienceChart();
    loadHashtagChart(8);
  };

  useEffect(() => {
    if (filters) loadChartsData();
  }, [filters, dateRange]);

  const defaultLegendClickHandler =
    ChartJS.overrides.doughnut.plugins.legend.onClick;
  const renderDoughnut = (type, ref, data, handlePieClick) => {
    var total = data.reduce((sum, el) => sum + el.value, 0);
    return (
      <div style={{ height: "400px", minWidth: "500px", maxWidth: "800px" }}>
        <Doughnut
          ref={ref}
          onClick={handlePieClick}
          data={{
            labels: data.map((ds) => ds.label),
            datasets: [
              {
                data: data.map((ds) => ((ds.value / total) * 100).toFixed(2)),
                backgroundColor: data.map((ds) => ds.color),
                borderWidth: 0,
                hoverBorderColor: "white",
                hoverBorderWidth: 1,
              },
            ],
          }}
          options={{
            cutout: "70%",
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                bodyFont: {
                  family: "Tomorrow",
                  size: 14,
                },
                callbacks: {
                  label: function (context) {
                    return ` ${context.dataset.data[context.dataIndex]} %`;
                  },
                },
              },
              legend: {
                onClick: function (e, legendItem, legend) {
                  if (type === "source") {
                    //setHiddenSources((prev) =>
                    //  prev.indexOf(legendItem.text) >= 0
                    //    ? prev.filter((hd) => hd !== legendItem.text)
                    //    : [...prev, legendItem.text]
                    //);
                  } else if (type === "sentiment") {
                    setHiddenSentiments((prev) =>
                      prev.indexOf(legendItem.text) >= 0
                        ? prev.filter((hd) => hd !== legendItem.text)
                        : [...prev, legendItem.text]
                    );
                  } else if (type === "theme") {
                    setHiddenThemes((prev) =>
                      prev.indexOf(legendItem.text) >= 0
                        ? prev.filter((hd) => hd !== legendItem.text)
                        : [...prev, legendItem.text]
                    );
                  } else if (type === "audience") {
                    setHiddenAudiences((prev) =>
                      prev.indexOf(legendItem.text) >= 0
                        ? prev.filter((hd) => hd !== legendItem.text)
                        : [...prev, legendItem.text]
                    );
                  }
                  defaultLegendClickHandler.call(this, e, legendItem, legend);
                },
                position: "right",
                labels: {
                  font: {
                    family: "Tomorrow",
                    size: 12,
                  },
                  color: "white",
                  usePointStyle: true,
                  pointStyle: "circle",
                  padding: 20,
                },
              },
            },
          }}
        />
      </div>
    );
  };

  const handleSourceClick = async (ev) => {};

  const handleSentimentClick = async (ev) => {
    const points = sentimentChartRef.current.getElementsAtEventForMode(
      ev,
      "nearest",
      { intersect: true },
      true
    );
    if (points.length > 0) {
      var chartSentiment = sentimentData[points[0].index];
      updateFilters({
        ...filters,
        sentiments: [chartSentiment.label.toLowerCase()],
      });
      navigate(HS_LISTENING_CONTENT_FEED);
    }
  };
  const handleThemeClick = async (ev) => {
    const points = themeChartRef.current.getElementsAtEventForMode(
      ev,
      "nearest",
      { intersect: true },
      true
    );
    if (points.length > 0) {
      var chartTheme = themeData[points[0].index];
      // Find the theme object based on the label.
      var theme = dataThemes.find((t) => t.name === chartTheme.label);
      if (!theme) {
        console.error(
          "Unexpected undefined theme for label: " + chartTheme.label
        );
        return;
      }
      // We actually want to keep all existing filters here and just apply the selected theme on top (so we override
      // any theme filters at this point), then send to the content page for showing examples.
      updateFilters({ ...filters, themes: [theme] });
      navigate(HS_LISTENING_CONTENT_FEED);
    }
  };

  const handleAudienceClick = async (ev) => {
    const points = audienceChartRef.current.getElementsAtEventForMode(
      ev,
      "nearest",
      { intersect: true },
      true
    );
    if (points.length > 0) {
      var chartAudience = audienceData[points[0].index];
      var audience = dataAudiences.find((a) => a.name === chartAudience.label);
      if (!audience) {
        console.error(
          "Unexpected undefined audience for label: " + chartAudience.label
        );
        return;
      }
      updateFilters({ ...filters, audiences: [audience] });
      navigate(HS_LISTENING_CONTENT_FEED);
    }
  };

  const tableKeys = [
    { title: "Sender", name: "Sender", width: 275, leftPos: 20 },
    { title: "Audience", name: "Audience", width: 214, leftPos: 303 },
    { title: "Message", name: "Message", width: 423, leftPos: 525 },
    { title: "Sentiment", name: "Sentiment", width: 197, leftPos: 956 },
    { title: "Theme", name: "Theme", width: 296, leftPos: 1161 },
    { title: "Engagement", name: "EngagementScore", width: 214, leftPos: 1280 },
    { title: "Date", name: "Date", width: 214, leftPos: 1502 },
  ];
  const getAudienceColor = (audienceID: string) => {
    const ad = audienceData.find((item) => item.id === audienceID);
    if (!ad) return "white";

    return ad.color;
  };

  const getSentimentText = (sentimentIdx: number) => {
    const sentiment = sentimentData.find(
      (sd) => sd.id === sentimentIdx.toString()
    );
    if (!sentiment) return "";

    return sentiment.label;
  };

  const getSentimentColor = (sentimentIdx: number) => {
    const sentiment = sentimentData.find(
      (sd) => sd.id === sentimentIdx.toString()
    );
    if (!sentiment) return "";

    return sentiment.color || "white";
  };

  const getThemeColor = (themeID: string) => {
    const theme = themeData.find((td) => td.id === themeID);
    if (!theme) return "white";

    return theme.color;
  };

  const handleExport = () => {
    //setIsLoading(true)
    //const dt = convertToDt(dateRange.from, dateRange.to)
    //const response = await fetch(API_CHART_EXPORT.replace('$1', topic.id).replace('$2', filters.source.id), {
    //  method: 'POST',
    //  headers: {
    //    'Accept': 'application/json',
    //    'Content-Type': 'application/json',
    //    Authorization: `Bearer ${authToken}`
    //  },
    //  body: JSON.stringify({
    //    startDate: dt.dt_from,
    //    endDate: dt.dt_to,
    //    selectedAudiences: filters.audiences
    //  })
    //})
    //const exportData: IVoiceExport[] = await response.json()
    //if (!currentChartData) return
    //const nodes = sideOption.audiences.length === 0 ? currentChartData.nodes
    //  : currentChartData.nodes.filter(node => sideOption.audiences.findIndex(ad => ad === node.audienceId) > -1)
    //const csv = [
    //  [
    //    'Source',
    //    'Sender Name',
    //    'Sender Username',
    //    'Sender Influence Score',
    //    'Sender Audience',
    //    'Sender Link to Profile',
    //    'Message',
    //    'Link to Message',
    //    'Message Date',
    //    'Overall Message Sentiment',
    //    'Theme'
    //  ]
    //]
    //exportData.forEach(ed => {
    //  const node = nodes.find(node => node.key === ed.key)
    //  if (node)
    //    csv.push([
    //      filters.source.name,
    //      node?.label || '',
    //      ed.key,
    //      node?.sizePercent.toString() || '' ,
    //      ed.audience,
    //      node?.profileLink || '',
    //      ed.message.replace(/\n|\r/g, "").replace(/"/g, '""'),
    //      ed.messageLink,
    //      ed.messageDate,
    //      ed.messageSentiment,
    //      ed.theme
    //    ])
    //})
    //setIsLoading(false)
    //setCsvData(csv)
    //const fileName = `Atlas[${topic.name}]_TopicListening`
    //setDownloadName(fileName)
    //setTimeout(() => {
    //  csvLink.current.link.click()
    //}, 500)
    //
  };

  return (
    <PageTemplate title="Listening">
      <ListeningTabbedPageContent tab="summary">
        <div className="listening-summary-container">
          <FiltersBar enableMultiSources={true} />
          <div className="charts-container">
            <div className="chart-widget chart-widget-full listening-line-chart-widget">
              <div className="chart-widget-header listening-line-chart-header">
                <div className="chart-widget-header-title">MENTION TRENDS</div>
                <div className="listening-line-chart-header-note">
                  MENTIONS: <em>{totalMentions.toLocaleString("en-US")}</em>
                </div>
              </div>
              <div>
                <AtlasLineChart
                  labels={lineLabels}
                  linesData={[
                    {
                      name: "",
                      color: "#78D2F1",
                      data: lineData.map((ld) => ld.value),
                    },
                  ]}
                  showLegend={false}
                />
              </div>
            </div>
            <div className="chart-widget chart-widget-half">
              <div className="chart-widget-header">
                <div className="chart-widget-header-title">Source</div>
              </div>
              <div>
                {renderDoughnut(
                  "source",
                  sourceChartRef,
                  sourceData,
                  handleSourceClick
                )}
              </div>
            </div>
            <div className="chart-widget chart-widget-half">
              <div className="chart-widget-header">
                <div className="chart-widget-header-title">Sentiment</div>
              </div>
              <div>
                {renderDoughnut(
                  "sentiment",
                  sentimentChartRef,
                  sentimentData,
                  handleSentimentClick
                )}
              </div>
            </div>
            <div className="chart-widget chart-widget-half">
              <div className="chart-widget-header">
                <div className="chart-widget-header-title">
                  Theme & Discussion
                </div>
              </div>
              <div>
                {renderDoughnut(
                  "theme",
                  themeChartRef,
                  themeData,
                  handleThemeClick
                )}
              </div>
            </div>
            <div className="chart-widget chart-widget-half">
              <div className="chart-widget-header">
                <div className="chart-widget-header-title">Audience</div>
              </div>
              <div>
                {renderDoughnut(
                  "audience",
                  audienceChartRef,
                  audienceData,
                  handleAudienceClick
                )}
              </div>
            </div>
            <div className="chart-widget chart-widget-half">
              <div className="chart-widget-header">
                <div className="chart-widget-header-title">
                  Popular Hashtags
                </div>
              </div>
              <div>
                {hashtagData && <AtlasValueCountChart data={hashtagData} />}
                {/* {renderDoughnut('hashtag', audienceChartRef, hashtagData, handleAudienceClick)} */}
              </div>
            </div>
          </div>
        </div>
      </ListeningTabbedPageContent>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize SummaryScreen component");
  if (!state.ui.filters)
    throw new Error(
      "Filters must be set to initialize SummaryScreen component"
    );

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    dataThemes: state.data.themes,
    dataAudiences: state.data.audiences,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {
  setIsLoading,
  updateFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryScreen);
