import React from "react";

import XIcon from "assets/images/icons/anal-x.svg";

import "./AtlasFullScreenPopup.scss";

interface Props {
  onClose: () => void;
  children: React.ReactNode;
}

function AtlasFullScreenPopup({ onClose, children }: Props) {
  return (
    <div className="hs-fullscreen-popup">
      <div className="hs-fullscreen-popup-wrapper hs-scrollable-container">
        <img
          className="hs-fullscreen-popup-close"
          src={XIcon}
          alt="menu"
          onClick={onClose}
        />
        {children}
      </div>
    </div>
  );
}

export default AtlasFullScreenPopup;
