import React, { useState, useEffect, useRef } from "react";
import { Tweet } from "react-twitter-widgets";
import { connect } from "react-redux";
import { setIsLoading } from "redux/actions";

import PageTemplate from "pages/PageTemplate";
import ListeningTabbedPageContent from "./ListeningTabbedPageContent";
import FiltersBar from "components/FiltersBar";

import { convertToDt, convertToDt2 } from "utils/convertData";

import { API_CHART_EXPORT, API_LISTENING_RAW_MESSAGES } from "constants/routes";

import AtlasSelect from "../../components/AtlasSelect";

interface IVoiceExport {
  subTopic: string;
  audience: string;
  key: string;
  fromName: string;
  fromProfileImage: string;
  fromInfluencerScore: number;
  message: string;
  messageDate: string;
  messageLink: string;
  messageSentiment: string;
  theme: string;
}

interface IRawMessage {
  author: string;
  message: string;
  permalink: string;
  date: Date;
}

function uniq(a) {
  var seen = {};
  return a.filter(function (item) {
    return seen.hasOwnProperty(item.permalink)
      ? false
      : (seen[item.permalink] = true);
  });
}

interface Props {
  setIsLoading: (loading: boolean) => void;
  topic: AtlasMach.ITopic;
  filters: AtlasMach.UIFilters;
  dateRange: AtlasMach.IDateRange;
  authToken: string;
}

function ContentFeedScreen({
  setIsLoading,
  topic,
  filters,
  dateRange,
  authToken,
}: Props) {
  const [rawMessages, setRawMessages] = useState<IRawMessage[]>([]);
  const [orderBy, setOrderBy] = useState<
    { orderBy: string; orderByDesc: boolean } | undefined
  >(undefined);
  const [hashtagFilter, setHashtagFilter] = useState<string>("");

  const [loadingMessages, setLoadingMessages] = useState(false);
  // The hashtag for which we were loading
  const [loadingHashtag, setLoadingHashtag] = useState<string>("");

  const [endOfPage, setEndOfPage] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 8;

  const [twitterLoading, setTwitterLoading] = useState(false);

  const buildUrlWithFilters = (baseUrl) => {
    const dt = convertToDt2(dateRange.from, dateRange.to);
    let url = `${baseUrl}?startDate=${dt.dt_from}&endDate=${dt.dt_to}`;
    // For the content feed we always show with a single source, due to the content formatting.
    url += `&sourceFilters=${filters.source.id}`;
    filters.audiences.forEach((audience) => {
      url += `&audienceFilters=${audience.id}`;
    });
    filters.sentiments.forEach((sentiment) => {
      url += `&sentimentFilters=${sentiment}`;
    });
    filters.themes.forEach((theme) => {
      url += `&themeFilters=${theme.id}`;
    });
    filters.brands.forEach((brand) => {
      url += `&brandFilters=${brand.id}`;
    });
    if (hashtagFilter) {
      url += `&hashtagFilters=${hashtagFilter}`;
    }
    if (orderBy)
      url += `&orderBy=${orderBy.orderBy}&orderByDesc=${orderBy.orderByDesc}`;
    url += `&offset=${offset}&limit=${limit}`;
    return url;
  };

  useEffect(() => {
    setIsLoading(loadingMessages || twitterLoading);
  }, [loadingMessages, twitterLoading]);

  const loadMessages = () => {
    if (loadingMessages) return;

    setLoadingMessages(true);
    setLoadingHashtag(hashtagFilter);
    const apiUrl = buildUrlWithFilters(
      API_LISTENING_RAW_MESSAGES.replace("$1", topic.id)
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        setLoadingMessages(false);
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        setOffset((offset) => offset + limit);

        if (jsonData.length !== 0) {
          setRawMessages((old) => uniq([...old, ...jsonData]));
          if (filters.source.name === "Twitter") {
            // This is a hack to show the loading feedback to the user while twitter loads, we don't
            // know exactly how long it takes but probably 1-2 seconds is reasonable.
            setTwitterLoading(true);
            window.setTimeout(() => setTwitterLoading(false), 2000);
          }
        }
      });
  };

  useEffect(() => {
    if (rawMessages.length === 0) loadMessages();
  }, [rawMessages]);

  useEffect(() => {
    if (endOfPage) loadMessages();
  }, [endOfPage]);

  useEffect(() => {
    // We want to explicitly re-initialize the messages to nothing and force a re-render.
    // This avoids some weird bug with trailing messages after changing the filters (maybe it's our fault though).
    setOffset(0);
    setRawMessages([]);
  }, [authToken, topic, filters, dateRange, orderBy]);

  const handleScroll = () => {
    // The current value of y at the bottom of the visible screen.
    const currentY = window.innerHeight + window.pageYOffset;
    // The total height on the page.
    const totalHeight = document.body.offsetHeight;

    if (endOfPage) {
      if (currentY < totalHeight) {
        console.log("no longer end of page");
        // This means we are no longer at end of page, most likely because the additional loading added new data.
        setEndOfPage(false);
      }
      return;
    }

    // We add some margin where we start loading both to start loading before the very end and also to handle a bug
    // where it seems sometimes currentY does not reach totalHeight.
    if (currentY + 50 >= totalHeight) {
      setEndOfPage(true);
    }
  };

  useEffect(() => {
    console.log("effect");
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    //}, [rawMessages, offset, endOfPage, topic, dateRange, filters, orderBy])
  });

  const permalinkToTweetId = (permalink: string) => {
    const parts = permalink.split("/");
    const tweetId = parts[parts.length - 1];
    return tweetId;
  };

  const options = [
    {
      value: "engagement-desc",
      label: "Highest engagement",
      payload: { orderBy: "engagement", orderByDesc: true },
    },
    {
      value: "engagement-asc",
      label: "Lowest engagement",
      payload: { orderBy: "engagement", orderByDesc: false },
    },
    {
      value: "date-asc",
      label: "Earliest date",
      payload: { orderBy: "date", orderByDesc: false },
    },
    {
      value: "date-desc",
      label: "Latest date",
      payload: { orderBy: "date", orderByDesc: true },
    },
  ];
  const onChangeSort = (option) => {
    setOrderBy(option.payload);
  };

  const handleExport = async (setCsvData, setDownloadName, csvLink) => {
    setIsLoading(true);
    const dt = convertToDt(dateRange.from, dateRange.to);
    // TODO: support multi-source as well.
    const response = await fetch(
      API_CHART_EXPORT.replace("$1", topic.id).replace("$2", filters.source.id),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          // Let's make the behavior to be an export of everything, ignoring the filters.
          // In terms of UX, the export is at the top of the page so it might be ok to assume that it will export the entire
          // content feed, not just the filtered one (although we still respect the date ranage here). The API only supports
          // filtering by audiences, but to make it consistent there's a lot more filters to support, so it's easier to just
          // always export all. In practice it's easy to filter on the Excel sheet after the export as well.
          //selectedAudiences: filters.audiences.map((a) => a.id),
        }),
      }
    );
    const exportData: IVoiceExport[] = await response.json();

    const csv = [
      [
        "Source",
        "Sender Name",
        "Sender Username",
        "Sender Influence Score",
        "Sender Audience",
        "Sender Link to Profile",
        "Message",
        "Link to Message",
        "Message Date",
        "Overall Message Sentiment",
        "Theme",
      ],
    ];
    exportData.forEach((ed) => {
      csv.push([
        filters.source.name || "",
        ed.fromName || "",
        ed.key,
        ed.fromInfluencerScore.toString() || "",
        ed.audience,
        ed.fromProfileImage || "",
        ed.message.replace(/\n|\r/g, "").replace(/"/g, '""'),
        ed.messageLink,
        ed.messageDate,
        ed.messageSentiment,
        ed.theme,
      ]);
    });
    setIsLoading(false);
    setCsvData(csv);
    setDownloadName(`Atlas[${topic.name}]_ContentFeed`);
    setTimeout(() => {
      csvLink.current.link.click();
    }, 500);
  };

  const [nextHashtagFilter, setNextHashtagFilter] = useState<string>("");
  var [setHashtagCallback, setSetHashtagCallback] = useState<
    number | undefined
  >(undefined);
  const onHashtagFilterChange = (hashtag: string) => {
    setNextHashtagFilter(hashtag);
    if (setHashtagCallback) window.clearTimeout(setHashtagCallback);
    setSetHashtagCallback(
      window.setTimeout(() => {
        var hashtagPreprocessed = hashtag.toLowerCase();

        if (hashtagPreprocessed.slice(0, 1) === "#") {
          hashtagPreprocessed = hashtagPreprocessed.slice(1);
        }

        setHashtagFilter(hashtagPreprocessed);
        if (hashtagPreprocessed.length == 1)
          // We ignore if it's the first character
          return;
        // Otherwise we reset and let the effects reload.
        setRawMessages([]);
        setHashtagCallback = undefined;
      }, 800)
    );
  };

  return (
    <PageTemplate title="Listening" onExport={handleExport}>
      <ListeningTabbedPageContent tab="content-feed">
        <div className="listening-content-container">
          <FiltersBar enableMultiSources={false} />
          <AtlasSelect
            onChange={onChangeSort}
            placeholder={"Sort by"}
            options={options}
            value={undefined}
          />
          <div className="listening-content-hashtag-filter">
            <label htmlFor="listening-content-hashtag-filter-input">
              Filter by hashtag:{" "}
            </label>
            <input
              className="hs-input"
              type="text"
              id="listening-content-hashtag-filter-input"
              value={nextHashtagFilter}
              onChange={(ev) => onHashtagFilterChange(ev.target.value)}
            />
          </div>
          {filters.source.name === "Twitter" &&
            rawMessages.map((data) => (
              <div key={data.permalink}>
                <Tweet
                  tweetId={permalinkToTweetId(data.permalink)}
                  options={{ width: 700, theme: "dark" }}
                />
              </div>
            ))}
          {filters.source.name != "Twitter" &&
            rawMessages.map((data) => (
              <a
                href={data.permalink}
                target="_blank"
                key={data.permalink}
                className="listening-content-message"
              >
                <div className="listening-content-message-date">
                  {data.date.toLocaleString()}
                </div>
                <div className="listening-content-message-author">
                  {data.author}
                </div>
                <div className="listening-content-message-text">
                  {data.message}
                </div>
              </a>
            ))}
        </div>
      </ListeningTabbedPageContent>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error(
      "topic must be set to initialize ContentScreenComponent component"
    );
  if (!state.ui.filters)
    throw new Error(
      "Filters must be set to initialize ContentScreenComponent component"
    );

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentFeedScreen);
