import {
  SET_CLIENT,
  SET_TOPICS,
  SET_SUBTOPICS,
  SET_SOURCES,
  SET_AUDIENCES,
  SET_THEMES,
  SET_BRANDS,
  SET_BRAND_ACCOUNTS,
  SET_API_TOKEN,
  PUSH_CHARTDATA,
  SET_STARACCOUNTS,
  SET_HEATMAPS,
  SET_HEATTHEMES,
  SET_HEATACCOUNTS,
  SET_AUDIENCE_ANALYSIS,
  UPDATE_EXPORT_DATA,
} from "../actionTypes";
const initialState: AtlasMach.DataState = {
  client: undefined,
  topics: [],
  subtopics: [],
  sources: [],
  audiences: [],
  themes: [],
  brands: [],
  brandAccounts: [],
  notifications: [],
  chartStack: {},
  starAccounts: [],
  heatMaps: [],
  heatThemes: [],
  heatAccounts: [],
  audienceAnalysis: {
    name: "",
    profileImage: "",
    Sections: [],
  },
  auth_token: "",
  exportData: {},
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case SET_CLIENT: {
      const { client } = action.payload;
      return {
        ...state,
        client: client,
      };
    }
    case SET_TOPICS: {
      const { topics } = action.payload;
      return {
        ...state,
        topics: topics,
        subtopics: [],
        sources: [],
        audiences: [],
      };
    }
    case SET_SUBTOPICS: {
      const { subtopics } = action.payload;
      return {
        ...state,
        subtopics: subtopics,
      };
    }
    case SET_SOURCES: {
      const { sources } = action.payload;
      return {
        ...state,
        sources: sources,
      };
    }
    case SET_AUDIENCES: {
      const { audiences } = action.payload;
      return {
        ...state,
        audiences: audiences,
      };
    }
    case SET_THEMES: {
      const { themes } = action.payload;
      return {
        ...state,
        themes: themes,
      };
    }
    case SET_BRANDS: {
      const { brands } = action.payload;
      return {
        ...state,
        brands: brands,
      };
    }
    case SET_BRAND_ACCOUNTS: {
      const { brandAccounts } = action.payload;
      return {
        ...state,
        brandAccounts: brandAccounts,
      };
    }
    // case SET_NOTIFICATIONS: {
    //   const { notifications } = action.payload
    //   return {
    //     ...state,
    //     notifications: notifications
    //   }
    // }
    case SET_STARACCOUNTS: {
      const { accounts } = action.payload;
      return {
        ...state,
        starAccounts: accounts,
      };
    }
    case SET_HEATMAPS: {
      const { heatMaps } = action.payload;
      return {
        ...state,
        heatMaps: heatMaps,
      };
    }
    case SET_HEATTHEMES: {
      const { heatThemes } = action.payload;
      return {
        ...state,
        heatThemes: heatThemes,
      };
    }
    case SET_HEATACCOUNTS: {
      const { heatAccounts } = action.payload;
      return {
        ...state,
        heatAccounts: heatAccounts,
      };
    }
    case SET_AUDIENCE_ANALYSIS: {
      const { aa } = action.payload;
      return {
        ...state,
        audienceAnalysis: aa,
      };
    }
    case PUSH_CHARTDATA: {
      const { key, chart } = action.payload;
      let stack = { ...state.chartStack };
      stack[key] = chart;
      return {
        ...state,
        chartStack: stack,
      };
    }
    case SET_API_TOKEN: {
      const { token } = action.payload;
      return {
        ...state,
        auth_token: token,
      };
    }
    case UPDATE_EXPORT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        exportData: data,
      };
    }
    default:
      return state;
  }
}
