import React, { useState, useEffect } from "react";
import Select from "react-select";

import "./AtlasPagination.scss";

interface IAtlasPaginationProps {
  defaultItemsPerPage: number;
  defaultCurrentPage: number;
  rangePages: number[];
  callback: any;
}

const AtlasPagination = ({
  defaultItemsPerPage,
  defaultCurrentPage,
  rangePages,
  callback,
}: IAtlasPaginationProps) => {
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage || 10);
  const [currentPage, setCurrentPage] = useState(
    defaultCurrentPage || (rangePages?.length ? 1 : 0)
  );

  useEffect(() => {
    callback(itemsPerPage, currentPage);
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    setCurrentPage(defaultCurrentPage);
  }, [defaultCurrentPage]);

  return (
    <div className="atlas-pagination-footer">
      <div className="atlas-pagination-footer-max-entries">
        <Select
          unstyled
          onChange={(opt) => {
            if (opt) setItemsPerPage(opt.value);
          }}
          options={[
            { value: 10, label: "10" },
            { value: 25, label: "25" },
            { value: 50, label: "50" },
            { value: 100, label: "100" },
          ]}
          defaultValue={{
            value: itemsPerPage,
            label: itemsPerPage.toString(),
          }}
          styles={{
            container: (baseStyle, state) => ({
              ...baseStyle,
              display: "inline-block",
              color: "white",
              margingRight: "10px",
              width: "50px",
            }),
          }}
        />
        <div className="atlas-pagination-footer-max-entries-text">
          Entries per page
        </div>
      </div>
      <div className="atlas-pagination-footer-pages">
        {rangePages.map((page) => (
          <div
            key={page}
            className={
              "atlas-pagination-footer-page" +
              (page === currentPage ? " active" : "")
            }
            onClick={() => {
              setCurrentPage(page);
            }}
          >
            {page}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AtlasPagination;
