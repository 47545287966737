import { config } from "../config";

// Routes for App
export const HS_HOME = "/hs/home";
export const HS_LISTENING_SUMMARY = "/hs/listening/summary";
export const HS_LISTENING_CONTENT_FEED = "/hs/listening/content-feed";
export const HS_LISTENING_THEMES_FIT_GAP = "/hs/listening/themes-fit-gap";
export const HS_LISTENING_BENCHMARKING = "/hs/listening/benchmarking";
export const HS_DOL_NETWORK_MAP = "/hs/dol/network-map";
export const HS_DOL_PATHWAYS = "/hs/dol/pathways";
export const HS_DOL_CONVERSATIONAL_MATRIX = "/hs/dol/conversational-matrix";
export const HS_DOL_CENTRICITY_REPORT = "/hs/dol/centricity-report";
export const HS_DOL_STARRED_ACCOUNTS = "/hs/dol/starred-accounts";
export const HS_VOICES_PATIENTS = "/hs/voices/patients";
export const HS_VOICES_HCPS = "/hs/voices/hcps";
export const HS_VOICES_STARRED_ACCOUNTS = "/hs/voices/starred-accounts";
export const HS_VOICES_DEBATE = "/hs/voices/debate";
export const HS_VOICES_SAVED = "/hs/voices/saved";
export const HS_CONTENT_STUDIO_MESSAGE_GENERATOR =
  "/hs/content-studio/message-generator";

// Routes for pollverse
export const POLLVERSE_HOME = "/pollverse/home";
export const POLLVERSE_VOICES = "/pollverse/voices/legislators";
export const POLLVERSE_VOICES_COMPARE = "/pollverse/voices/compare";
export const POLLVERSE_COMPARE = "/pollverse/compare/debate-showdown";
export const POLLVERSE_NETWORK_MAP = "/pollverse/network-map";
export const POLLVERSE_HEAT_MAP = "/pollverse/heat-map";

// Routes for api
export const API_BASE = config.WEB_API_BASE_URI;

// Global (cross-topic) APIs (for Users, Clients, or Acccounts)
export const API_USER_INFO = `${API_BASE}/User/me`;
export const API_USER_CLIENT = `${API_BASE}/User/Client`;
export const API_ACCOUNTS_AUDIENCE_ANALYSIS_REQUEST = `${API_BASE}/Account/$1/AudienceAnalysisRequest`;

export const API_IDENTITY = `${API_BASE}/identity`;
export const API_GLOSSARY = `${API_BASE}/glossary`;
export const API_SUPPORT = `${API_BASE}/support`;
export const API_TOPIC = `${API_BASE}/topics`;
export const API_SOURCES = `${API_BASE}/topics/$1/sources`;
export const API_THEMES = `${API_BASE}/topics/$1/themes`;
export const API_BRANDS = `${API_BASE}/topics/$1/brands`;
export const API_BRAND_ACCOUNTS = `${API_BASE}/Listening/$1/BrandAccounts`;
export const API_AUDIENCES = `${API_BASE}/topics/$1/audiences`;

// Old style APIs, to be ported to the new style (organized by modules, as below)
export const API_CHARTDATA = `${API_BASE}/topics/$1/chartdata`;
export const API_MESSAGE = `${API_BASE}/topics/$1/messages`;
export const API_STAR = `${API_BASE}/topics/$1/sources/$2/accounts/$3/star`;
export const API_UNSTAR = `${API_BASE}/topics/$1/sources/$2/accounts/$3/unstar`;
export const API_STAR_PROFILE = `${API_BASE}/topics/$1/sources/$2/accounts`;
export const API_HEATMAP = `${API_BASE}/heatmap?topicId=$1&sourceId=$2`;
export const API_THEMES_HEATMAP = `${API_BASE}/heatmap/themes?topicId=$1&sourceId=$2`;
export const API_HEATMAP_ACCOUNTS = `${API_BASE}/heatmap/accounts?topicId=$1&sourceId=$2`;
export const API_CHART_EXPORT = `${API_BASE}/topics/$1/sources/$2/chartdata/export`;
export const API_ENHANCED_PROFILE = `${API_BASE}/account/$1/enhanced?topicId=$2`;
export const API_MATRIX_THEME_MESSAGE = `${API_BASE}/topiclistening/topics/$1/sources/$2/account/$3/theme/$4/messages`;

export const API_LISTENING_RAW_MESSAGES = `${API_BASE}/Listening/$1/RawMessages`;
export const API_LISTENING_MESSAGES_COUNT = `${API_BASE}/Listening/$1/MessagesCount`;
export const API_LISTENING_MESSAGES_DISTRIBUTION = `${API_BASE}/Listening/$1/MessagesDistribution/$2`;
export const API_LISTENING_BENCHMARKING_SERIES = `${API_BASE}/Listening/$1/BenchmarkingSeries`;
export const API_LISTENING_BENCHMARKING_SERIES_NAME = `${API_BASE}/Listening/$1/BenchmarkingSeries/$2`;
export const API_DOL_MATRIX_AUTHORS = `${API_BASE}/DOL/$1/matrix/authors`;
export const API_DOL_MATRIX_MENTIONS = `${API_BASE}/DOL/$1/matrix/mentions`;
export const API_DOL_PATHWAYS = `${API_BASE}/DOL/$1/pathways`;
export const API_DOL_PATHWAYS_HSREC = `${API_BASE}/DOL/$1/pathways/hsrec`;
export const API_DOL_ACCOUNT_MENTIONS = `${API_BASE}/DOL/$1/accounts/$2/mentions`;
export const API_DOL_ACCOUNT_REPORT = `${API_BASE}/DOL/$1/accounts/$2/report`;
export const API_DOL_ACCOUNT_HCP = `${API_BASE}/DOL/$1/accounts/$2/hcp-data`;
export const API_DOL_HCPS_AGGREGATE = `${API_BASE}/DOL/$1/hcps-aggregate`;
export const API_VOICES_PATIENTS = `${API_BASE}/Voices/$1/Patients`;
export const API_VOICES_SAVED_ANSWERS = `${API_BASE}/Voices/$1/SavedAnswers`;
export const API_VOICES_SAVED_ANSWER = `${API_BASE}/Voices/$1/SavedAnswers/$2`;
