import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setAudienceAnalysis } from "redux/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import StarFilled from "assets/images/icons/star-filled.svg";
import StarEmpty from "assets/images/icons/star-empty.svg";

import { API_DOL_ACCOUNT_HCP } from "constants/routes";

import AccountCard from "./AccountCard";

interface AccountPopupProps {
  topic: AtlasMach.ITopic;
  source: AtlasMach.ISource;
  dateRange: AtlasMach.IDateRange;
  node: { accountId: string; color: string; sizePercent: number | undefined };
  isStarred: boolean;
  onToggleStar: () => void;
  setIsLoading: (x: boolean) => void;
  position: { x: number; y: number };
  onClose: () => void;
  authToken: string;
  setAudienceAnalysis: (aa: AtlasMach.IAASection) => void;
  highlightMessage?: (msg: string) => boolean;
}

function AccountPopup({
  topic,
  source,
  dateRange,
  node,
  isStarred,
  onToggleStar,
  setIsLoading,
  position,
  authToken,
  onClose,
  setAudienceAnalysis,
  highlightMessage,
}: AccountPopupProps) {
  const [loading, setLoading] = useState(false);
  const [npi, setNpi] = useState<number | undefined>(undefined);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    var apiUrl = API_DOL_ACCOUNT_HCP.replace("$1", topic.id).replace(
      "$2",
      node.accountId
    );

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
        }
        return res.json();
      })
      .then((jsonData) => {
        console.log(jsonData);
        setNpi(jsonData.npi);
      });
  }, [topic, node, authToken]);

  return (
    <GraphPopup position={position} onClose={onClose} loading={loading}>
      <AccountCard
        topic={topic}
        source={source}
        accountId={node.accountId}
        npi={npi ? npi : undefined}
        isStarred={isStarred}
        onToggleStar={onToggleStar}
        color={node.color}
        sizePercent={node.sizePercent}
        dateRange={dateRange}
        setIsLoading={setLoading}
        authToken={authToken}
        setAudienceAnalysis={setAudienceAnalysis}
        highlightMessage={highlightMessage}
      />
    </GraphPopup>
  );
}

interface GraphPopupProps {
  position: { x: number; y: number };
  onClose: () => void;
  loading: boolean;
  children: React.ReactNode;
}

function GraphPopup({ position, onClose, loading, children }: GraphPopupProps) {
  var top = position.y;

  const parentsRelative = document.getElementsByClassName(
    "hs-content-container"
  );
  if (parentsRelative.length > 0) {
    top = Math.max(
      0, // We prevent it to be negative
      Math.min(
        (parentsRelative[0].getBoundingClientRect()?.height || 0) - // The total height of the popup's parent
          (parentsRelative[0].getBoundingClientRect()?.top || 0) - // The relative position of the popup's parent
          531, // The max size of a popup based on emperical observations
        top
      )
    );
  }

  return (
    <div
      className="hs-card-popup"
      style={{
        top: `${top}px`,
        left: `${position.x}px`,
      }}
    >
      {children}
      {!loading && (
        <div
          className="popup-close"
          onClick={(e) => {
            e.stopPropagation();
            if (onClose) onClose();
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  return {};
};

const mapDispatchToProps = {
  setAudienceAnalysis,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPopup);
