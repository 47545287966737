/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { setIsLoading } from "redux/actions";

import PageTemplate from "pages/PageTemplate";
import ListeningTabbedPageContent from "./ListeningTabbedPageContent";
// We are importing the underlying component instead of the connected component, because we don't want to use the global
// state for this. Note that I tried to override the mapping function of the connect() redux library, to override some
// props, but it just led to unexpecter errors so I rather bypass Redux entirely.
import FiltersBar from "components/FiltersBar/FiltersBar";

import { ReactComponent as GarbageBinIcon } from "assets/icons/garbage_bin.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";

import { AtlasLineChart, AtlasBarChart } from "components/AtlasCharts";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow-down.svg";
import {
  API_LISTENING_MESSAGES_COUNT,
  API_LISTENING_MESSAGES_DISTRIBUTION,
  API_LISTENING_BENCHMARKING_SERIES,
  API_LISTENING_BENCHMARKING_SERIES_NAME,
} from "constants/routes";

import { convertToDt, convertToDt2, compressJson } from "utils/convertData";

interface ILineData {
  date: string;
  value: number;
}

// A named set of filters, which we will display as independent data point on the
// benchmarking page.
interface ISeries {
  name: string;
  filters: AtlasMach.UIFilters;
  isActive: boolean;
}

// List of colors to use for each additional series. These are hard-coded here in order to look nice with the design.
// TODO: add a couple more, and set a max number of series.
const SeriesColors = [
  "#78D2F1",
  "#498AEC",
  "#8169E4",
  "#C463F1",
  "#D99F48",
  "#DFEA5F",
  "#5FEA9F",
  "#6AD95E",
  "#DC7758",
];

interface IBenchmarkingScreen {
  setIsLoading: (loading: boolean) => void;
  topic: AtlasMach.ITopic;
  dateRange: AtlasMach.IDateRange;
  filters: AtlasMach.UIFilters;
  authToken: string;
}

function BenchmarkingScreen({
  setIsLoading,
  topic,
  dateRange,
  filters,
  authToken,
}: IBenchmarkingScreen) {
  // TODO: We should move them to the props parameters and use the connector instead.
  const dataSources = useSelector<AtlasMach.StoreState, AtlasMach.ISource[]>(
    (state) => state.data.sources
  );
  const dataAudiences = useSelector<
    AtlasMach.StoreState,
    AtlasMach.IAudience[]
  >((state) => state.data.audiences);
  const dataThemes = useSelector<AtlasMach.StoreState, AtlasMach.ITheme[]>(
    (state) => state.data.themes
  );
  const dataBrands = useSelector<AtlasMach.StoreState, AtlasMach.IBrand[]>(
    (state) => state.data.brands
  );

  const [availableSeries, setAvailableSeries] = useState<ISeries[]>([]);

  // The chart data (mentions, themes, sentiments) associated with each active series.
  const [mentionLines, setMentionLines] = useState<{
    [key: string]: ILineData[];
  }>({});
  const [themesBars, setThemesBars] = useState<{
    [key: string]: AtlasMach.IPieData[];
  }>({});
  const [sentimentsBars, setSentimentsBars] = useState<{
    [key: string]: AtlasMach.IPieData[];
  }>({});

  const [isLoadingMentionChart, setIsLoadingMentionChart] =
    useState<boolean>(false);
  const [isLoadingThemesChart, setIsLoadingThemesChart] =
    useState<boolean>(false);
  const [isLoadingSentimentChart, setIsLoadingSentimentChart] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoading(
      isLoadingMentionChart || isLoadingSentimentChart || isLoadingThemesChart
    );
  }, [isLoadingMentionChart, isLoadingSentimentChart, isLoadingThemesChart]);

  const loadBenchmarkingSeries = () => {
    const apiUrl = API_LISTENING_BENCHMARKING_SERIES.replace("$1", topic.id);
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        setAvailableSeries(
          jsonData.map((seriesData) => {
            // TODO: Note that we have a backward compatibility bug here when we add a new filters field and
            // we decode a series that was encoded prior to adding these. This is because all fields are
            // explicitly encoded as arrays (and empty arrays if they have no filters applied) and thus
            // when adding a new one it is expected to be an empty array (which is the type we expect in FiltersBar)
            // but it won't be in the decoded series unfortunately. This can be fixed by deleting all series, but
            // in the future this might not be an acceptable solution if we have a lot of data.
            let { name, encodedSeries } = seriesData;
            let decodedSeries = JSON.parse(encodedSeries);
            return { name: name, filters: decodedSeries, isActive: false };
          })
        );
      });
  };

  useEffect(() => {
    loadBenchmarkingSeries();
  }, []);

  const buildUrlWithFilters = (
    baseUrl: string,
    filters: AtlasMach.UIFilters
  ) => {
    const dt = convertToDt2(dateRange.from, dateRange.to);
    let url = `${baseUrl}?startDate=${dt.dt_from}&endDate=${dt.dt_to}`;
    url += `&sourceFilters=${filters.source.id}`;
    filters.audiences.forEach((audience) => {
      url += `&audienceFilters=${audience.id}`;
    });
    filters.sentiments.forEach((sentiment) => {
      url += `&sentimentFilters=${sentiment}`;
    });
    filters.themes.forEach((theme) => {
      url += `&themeFilters=${theme.id}`;
    });
    filters.brands.forEach((brand) => {
      url += `&brandFilters=${brand.id}`;
    });
    return url;
  };

  const loadMentionChart = (s: ISeries, setMentionLine) => {
    setIsLoadingMentionChart(true);

    const apiUrl = buildUrlWithFilters(
      API_LISTENING_MESSAGES_COUNT.replace("$1", topic.id),
      s.filters
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          setIsLoadingMentionChart(false);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        setMentionLine(jsonData);
        setIsLoadingMentionChart(false);
      });
  };

  const loadThemesChart = (s: ISeries, setThemesChart) => {
    setIsLoadingThemesChart(true);

    const apiUrl = buildUrlWithFilters(
      API_LISTENING_MESSAGES_DISTRIBUTION.replace("$1", topic.id).replace(
        "$2",
        "theme"
      ),
      s.filters
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          setIsLoadingThemesChart(false);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        // We must order the datapoint in each chart as there's no guaranteed order when receiving the distribution of themes.
        let themesChart: AtlasMach.IPieData[] = jsonData;
        themesChart.sort((x, y) => {
          if (x.label < y.label) return -1;
          else if (x.label > y.label) return 1;
          return 0;
        });
        var total = 0;
        themesChart.forEach((d) => {
          total += d.value;
        });
        setThemesChart(
          themesChart.map((d) => ({
            ...d,
            value: ((d.value / total) * 100).toFixed(0),
          }))
        );
        setIsLoadingThemesChart(false);
      });
  };

  const loadSentimentChart = (s: ISeries, setSentimentChart) => {
    setIsLoadingSentimentChart(true);

    const apiUrl = buildUrlWithFilters(
      API_LISTENING_MESSAGES_DISTRIBUTION.replace("$1", topic.id).replace(
        "$2",
        "sentiment"
      ),
      s.filters
    );
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          setIsLoadingSentimentChart(false);
          // TODO: what to return and how to redirect?
        }
        return res.json();
      })
      .then((jsonData) => {
        let sentimentsChart: AtlasMach.IPieData[] = jsonData;
        // TODO: order neg-neutral-pos hardcoded, the below does the same but by chance because of the natural alpha ordering
        sentimentsChart.sort((x, y) => {
          if (x.label < y.label) return -1;
          else if (x.label > y.label) return 1;
          return 0;
        });
        var total = 0;
        sentimentsChart.forEach((d) => {
          total += d.value;
        });
        setSentimentChart(
          sentimentsChart.map((d) => ({
            ...d,
            value: ((d.value / total) * 100).toFixed(0),
          }))
        );
        setIsLoadingSentimentChart(false);
      });
  };

  useEffect(() => {
    availableSeries
      .filter((s) => s.isActive)
      .forEach((series) => {
        // For all series, if we don't have data for it, we fetch it. If we have the data for it
        // we just keep it around, we will only display a chart based on what series is active, so there's
        // no harm in keeping extra data points for charts.
        // Note that this is not where we handle updated filters for an existing series, this is just where
        // we handle adding new active series (and thus not changing filters), as you can see from the
        // dependency of the useEffect.

        if (!mentionLines[series.name]) {
          // This is a new series, we need the data.
          loadMentionChart(series, (x) =>
            setMentionLines((o) => {
              var res = { ...o };
              res[series.name] = x;
              return res;
            })
          );
        }
        if (!themesBars[series.name]) {
          // This is a new series, we need the data.
          loadThemesChart(series, (x) =>
            setThemesBars((o) => {
              var res = { ...o };
              res[series.name] = x;
              return res;
            })
          );
        }
        if (!sentimentsBars[series.name]) {
          // This is a new series, we need the data.
          loadSentimentChart(series, (x) =>
            setSentimentsBars((o) => {
              var res = { ...o };
              res[series.name] = x;
              return res;
            })
          );
        }
      });
  }, [availableSeries]);

  useEffect(() => {
    // TODO: refactor to share the code with the useEffect on new series added.
    // This refreshes all the series data, because some of the filters have changed.
    // This is different from the above which just adds a new series and thus only fetches the data for the new series.
    availableSeries
      .filter((s) => s.isActive)
      .forEach((series) => {
        loadMentionChart(series, (x) =>
          setMentionLines((o) => {
            var res = { ...o };
            res[series.name] = x;
            return res;
          })
        );
        loadThemesChart(series, (x) =>
          setThemesBars((o) => {
            var res = { ...o };
            res[series.name] = x;
            return res;
          })
        );
        loadSentimentChart(series, (x) =>
          setSentimentsBars((o) => {
            var res = { ...o };
            res[series.name] = x;
            return res;
          })
        );
      });
  }, [dateRange]);

  const onDeleteSeries = (s: ISeries) => {
    const apiUrl = API_LISTENING_BENCHMARKING_SERIES_NAME.replace(
      "$1",
      topic.id
    ).replace("$2", encodeURIComponent(s.name));
    fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status !== 204) {
        console.error("Unexpected response code for query: " + apiUrl);
        console.log(res);
        // TODO: what to return and how to redirect?
      }
    });

    var newAvailableSeries = [...availableSeries];
    newAvailableSeries.splice(availableSeries.indexOf(s), 1);
    setAvailableSeries(newAvailableSeries);
  };

  const onCheckSeries = (series: ISeries) => {
    series.isActive = !series.isActive;
    setAvailableSeries([...availableSeries]);
  };

  const onUpdateSeries = (name: string, s: ISeries) => {
    const encodedSeries = JSON.stringify(s.filters);
    const apiUrl = API_LISTENING_BENCHMARKING_SERIES_NAME.replace(
      "$1",
      topic.id
    ).replace("$2", encodeURIComponent(name));
    fetch(apiUrl, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        name: s.name,
        encodedSeries: encodedSeries,
      }),
    }).then((res) => {
      if (res.status !== 204) {
        console.error("Unexpected response code for query: " + apiUrl);
        console.log(res);
        // TODO: what to return and how to redirect?
      }
    });

    setAvailableSeries(
      availableSeries.map((os) => (os.name === name ? s : os))
    );
    loadMentionChart(s, (x) =>
      setMentionLines((o) => {
        var res = { ...o };
        res[s.name] = x;
        return res;
      })
    );
    loadThemesChart(s, (x) =>
      setThemesBars((o) => {
        var res = { ...o };
        res[s.name] = x;
        return res;
      })
    );
    loadSentimentChart(s, (x) =>
      setSentimentsBars((o) => {
        var res = { ...o };
        res[s.name] = x;
        return res;
      })
    );
  };

  const onCreateSeries = (s: ISeries) => {
    const encodedSeries = JSON.stringify(s.filters);

    const apiUrl = API_LISTENING_BENCHMARKING_SERIES.replace("$1", topic.id);
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        name: s.name,
        encodedSeries: encodedSeries,
      }),
    }).then((res) => {
      if (res.status !== 204) {
        console.error("Unexpected response code for query: " + apiUrl);
        console.log(res);
        // TODO: what to return and how to redirect?
      }
    });

    var newSeries = [...availableSeries];
    newSeries.push(s);
    setAvailableSeries(newSeries);
  };

  const mentionLinesLabels = useMemo(() => {
    // We don't display every single date label.
    const maxDisplayed = 10;
    return Object.keys(mentionLines).map((seriesName) => {
      const mentionLine = mentionLines[seriesName];
      const showIndex = Math.ceil(mentionLine.length / maxDisplayed);
      var res: string[] = [];
      for (var i = 0; i < mentionLine.length; i++) {
        if (i % showIndex == 0)
          res.push(new Date(mentionLine[i].date).toLocaleDateString());
        else res.push("");
      }
      return res;
    });
  }, [mentionLines]);

  const determineThemesBarsLabels = () => {
    // We must be careful and check that all data is loaded because this is used as soon as a series changes isActive ,
    // however the data itself is still loading.
    if (availableSeries.filter((s) => s.isActive).length === 0) return [];
    const name = availableSeries.filter((s) => s.isActive)[0].name;
    const data = themesBars[name];
    if (!data) return [];
    return data.map((b) => b.label);
  };
  const themesBarsLabels = determineThemesBarsLabels();

  const determineSentimentsBarsLabels = () => {
    // We must be careful and check that all data is loaded because this is used as soon as a series changes isActive,
    // however the data itself is still loading.
    if (availableSeries.filter((s) => s.isActive).length === 0) return [];
    const name = availableSeries.filter((s) => s.isActive)[0].name;
    const data = sentimentsBars[name];
    if (!data) return [];
    return data.map((b) => b.label);
  };
  const sentimentsBarsLabels = determineSentimentsBarsLabels();

  return (
    <PageTemplate title="Listening">
      <ListeningTabbedPageContent tab="benchmarking">
        <div className="listening-benchmarking-container">
          <SelectSeries
            availableSeries={availableSeries}
            onCreateSeries={onCreateSeries}
            onUpdateSeries={onUpdateSeries}
            onCheckSeries={onCheckSeries}
            onDeleteSeries={onDeleteSeries}
            topic={topic}
            dataSources={dataSources}
            dataAudiences={dataAudiences}
            dataThemes={dataThemes}
            dataBrands={dataBrands}
          />
          {availableSeries.filter((s) => s.isActive).length > 0 && (
            <div className="charts-container">
              <div className="chart-widget chart-widget-full listening-line-chart-widget">
                <div className="chart-widget-header listening-line-chart-header">
                  <div className="chart-widget-header-title">
                    MENTION TRENDS
                  </div>
                </div>
                <div>
                  <AtlasLineChart
                    labels={mentionLinesLabels[0]}
                    linesData={availableSeries
                      .filter((s) => s.isActive)
                      .map((series, i) => {
                        let ml = mentionLines[series.name];
                        if (!ml) ml = [];
                        return {
                          name: series.name,
                          data: ml.map((data) => data.value),
                          color: SeriesColors[i],
                        };
                      })}
                    showLegend={true}
                  />
                </div>
              </div>
              <div className="chart-widget chart-widget-full">
                <div className="chart-widget-header">
                  <div className="chart-widget-header-title">THEMES</div>
                </div>
                <div>
                  <AtlasBarChart
                    labels={themesBarsLabels}
                    datasets={availableSeries
                      .filter((s) => s.isActive)
                      .map((series, i) => {
                        let tb = themesBars[series.name];
                        if (!tb) tb = [];
                        return {
                          name: series.name,
                          data: tb.map((data) => data.value),
                          color: SeriesColors[i],
                        };
                      })}
                    showLegend={true}
                  />
                </div>
              </div>
              <div className="chart-widget chart-widget-full">
                <div className="chart-widget-header">
                  <div className="chart-widget-header-title">SENTIMENT</div>
                </div>
                <div>
                  <AtlasBarChart
                    labels={sentimentsBarsLabels}
                    datasets={availableSeries
                      .filter((s) => s.isActive)
                      .map((series, i) => {
                        let sb = sentimentsBars[series.name];
                        if (!sb) sb = [];
                        return {
                          name: series.name,
                          data: sb.map((data) => data.value),
                          color: SeriesColors[i],
                        };
                      })}
                    showLegend={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </ListeningTabbedPageContent>
    </PageTemplate>
  );
}

function SelectSeries({
  topic,
  dataSources,
  dataAudiences,
  dataThemes,
  dataBrands,
  availableSeries,
  onCreateSeries,
  onCheckSeries,
  onDeleteSeries,
  onUpdateSeries,
}) {
  const [folded, setFolded] = useState<boolean>(true);
  const [editingSeries, setEditingSeries] = useState<ISeries | undefined>(
    undefined
  );
  // The name of the original series that we are editing, undefined if it's a new series.
  const [editingExisting, setEditingExisting] = useState<string | undefined>(
    undefined
  );

  const handleEditingNew = () => {
    setEditingExisting(undefined);
    setEditingSeries({
      name: "",
      filters: {
        source: dataSources[0], // TODO: remove
        sources: [],
        audiences: [],
        sentiments: [],
        themes: [],
        brands: [],
        brandAccounts: [],
        engagementsLikelihood: [],
        onlyStarredAccounts: false,
      },
      isActive: false,
    });
  };

  const handleEditSeries = (series: ISeries) => {
    setEditingExisting(series.name);
    setEditingSeries(series);
  };

  return (
    <div className="listening-benchmarking-select-series-container">
      <div
        className={
          "listening-benchmarking-select-series-header " +
          (folded ? "folded" : "unfolded")
        }
      >
        <div
          className="listening-benchmarking-select-series-text"
          onClick={() => setFolded(!folded)}
        >
          Select Series
        </div>
        {availableSeries
          .filter((s) => s.isActive)
          .map((series) => (
            <div
              key={series.name}
              className="listening-benchmarking-select-series-item"
            >
              {series.name}
            </div>
          ))}
        <ArrowIcon
          fill="#8D9CA6"
          width="18"
          height="18"
          onClick={() => setFolded(!folded)}
        />
      </div>
      {!folded && (
        <div className="listening-benchmarking-select-series-body">
          {!editingSeries && (
            <div>
              <div
                className="listening-benchmarking-select-series-create"
                onClick={handleEditingNew}
              >
                Create +
              </div>
              {availableSeries.map((fb, idx) => (
                <div
                  key={fb.name}
                  className="listening-benchmarking-select-series-item"
                >
                  <label className="hs-checkmark">
                    <input
                      type="checkbox"
                      checked={fb.isActive}
                      onChange={() => onCheckSeries(fb)}
                    />
                    <span className="checkmark"></span>
                    {fb.name}
                  </label>
                  <PencilIcon onClick={() => handleEditSeries(fb)} />
                  <GarbageBinIcon onClick={() => onDeleteSeries(fb)} />
                </div>
              ))}
            </div>
          )}
          {editingSeries && (
            <div className="listening-benchmarking-select-series-definition">
              <input
                className="input-name"
                type="text"
                placeholder="Name"
                value={editingSeries.name}
                onChange={(e) =>
                  setEditingSeries({ ...editingSeries, name: e.target.value })
                }
              />
              <FiltersBar
                enableMultiSources={false}
                onButtonClicked={() => {
                  if (!editingSeries.name) {
                    alert("You need to name your series");
                    return;
                  }
                  if (
                    availableSeries.some(
                      (s) =>
                        s.name !== editingExisting &&
                        s.name === editingSeries.name
                    )
                  ) {
                    alert("You must use a unique name for your series");
                    return;
                  }
                  if (editingExisting) {
                    onUpdateSeries(editingExisting, editingSeries);
                  } else {
                    onCreateSeries(editingSeries);
                  }
                  setEditingSeries(undefined);
                }}
                onCancel={() => setEditingSeries(undefined)}
                topic={topic}
                filters={editingSeries.filters}
                updateFilters={(fltrs) =>
                  setEditingSeries({ ...editingSeries, filters: fltrs })
                }
                dataSources={dataSources}
                dataAudiences={dataAudiences}
                dataBrands={dataBrands}
                dataBrandAccounts={[]}
                dataThemes={dataThemes}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error(
      "topic must be set to initialize ThemesFitGapScreen component"
    );
  if (!state.ui.filters)
    throw new Error(
      "Filters must be set to initialize ThemesFitGapScreen component"
    );

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkingScreen);
