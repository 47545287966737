import React, { useState } from "react";
import { connect } from "react-redux";

import AudienceVoicesScreen from "./AudienceVoicesScreen";

import { inspireMe, inspireMeThemes } from "./inspiremestarred";

import { API_DOL_ACCOUNT_REPORT } from "constants/routes";

import AtlasSelect from "../../components/AtlasSelect";

const REPORT_EXISTS = [
  "ae2d6866-9467-4eca-9278-b598ebc4e252",
  "58dc0f0e-ed3e-4d12-863c-97ce8d92319b",
];

function IndividualAccountScreen({ topic, bots, authToken }) {
  const options = bots.map((bot) => ({ value: bot.botId, label: bot.name }));
  /*{
    // PollVerse
    "f7ecc682-d9a0-4349-817f-7bb3380f51ba": [
      {
        value: "stevecohen",
        label: "@RepCohen (Twitter)",
      },
      {
        value: "stevecohen-press",
        label: "@RepCohen (Press Release)",
      },
      {
        value: "MarshaBlackburn",
        label: "@MarshaBlackburn (Twitter)",
      },
      {
        value: "DianaHarshbarger",
        label: "@RepHarshbarger (Twitter)",
      },
      {
        value: "TimBurchett",
        label: "@RepTimBurchett (Twitter)",
      },
      {
        value: "e54f101c-5b49-4ff0-bbbe-cd689fcc4980",
        label: "@m_ebrard",
      },
    ],
    // Migraine
    "a393e845-d667-4e98-977e-9ee91096ad5e": [
      {
        value: "ae2d6866-9467-4eca-9278-b598ebc4e252",
        label: "@beth_morton (Patient)",
      },
      {
        value: "58dc0f0e-ed3e-4d12-863c-97ce8d92319b",
        label: "@CSWhiteMD (HCP)",
      },
    ],
    // Migraine (Demo)
    "cb68ff6c-2e8f-46eb-88dd-47ac195176b1": [
      {
        value: "ae2d6866-9467-4eca-9278-b598ebc4e252",
        label: "@beth_morton (Patient)",
      },
      {
        value: "58dc0f0e-ed3e-4d12-863c-97ce8d92319b",
        label: "@CSWhiteMD (HCP)",
      },
    ],
    // Trentino
    "4090a2aa-fbf3-417a-8beb-f305676016dd": [
      {
        value: "maurizio",
        label: "Maurizio Rossini",
      },
      {
        value: "Sarah",
        label: "Sarah Villeneuve",
      },
      {
        value: "railway",
        label: "@Simply_Railway",
      },
      {
        value: "oscar",
        label: "@DevOpsOscar",
      },
      {
        value: "snow",
        label: "@SnowBrains",
      },
    ],
  }*/

  // TODO: disable if no options
  const [selectedAccount, setSelectedAccount] = useState<{
    value: string;
    label: string;
  }>(options[0]);

  const handleChange = (option) => {
    setSelectedAccount(option);
  };
  const handleGenerateReport = () => {
    var url = API_DOL_ACCOUNT_REPORT.replace("$1", topic.id).replace(
      "$2",
      selectedAccount.value
    );

    // We need to use a fetch and some logic to trigger the download with the blob below.
    // The obvious simpler approach would be to use a <a href=...> tag and let the browser
    // download automatically, however this is not working because we cannot pass the Authorization
    // header, which is used to validate that the user has access to the report.
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        //var file = window.URL.createObjectURL(blob);
        //window.location.assign(file);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `report-${selectedAccount.value}.pdf`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      });
  };

  const selectAccountWidget = (
    <>
      <AtlasSelect
        placeholder={"Choose an account"}
        options={options}
        onChange={handleChange}
        value={selectedAccount}
      />
      {REPORT_EXISTS.includes(selectedAccount.value) && (
        <button className="hs-button primary" onClick={handleGenerateReport}>
          Request Report
        </button>
      )}
    </>
  );
  const isModuleAvailable = options[0].value != "";

  return (
    <>
      <AudienceVoicesScreen
        selectWidget={isModuleAvailable ? selectAccountWidget : undefined}
        isGlobal={true}
        isSavedEnabled={false}
        audience={selectedAccount.value}
        tab="starred-accounts"
        welcomeText={
          "Hi, I am an AI engine representing " +
          selectedAccount.label.split(" ")[0] +
          " posts over the period of"
        }
        examplePostsLead="Examples of actual posts by the author"
        disclaimer="This is NOT the real person. This is an Artificial Intelligence engine that summarizes posts from the author."
        inspireMe={inspireMe}
        inspireMeLead={"Choose a subject"}
        /* inspireMeThemes={inspireMeThemes} */
        inspireMeThemes={[]}
      />
    </>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize SummaryScreen component");

  const individualBots = state.ui.topic.topicBots
    .map((tb) => tb.bot)
    .filter((b) => !b.topicAudienceId);

  return {
    topic: state.ui.topic,
    bots: individualBots,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualAccountScreen);
