import React, { useState, useEffect } from "react";
import Select from "react-select";

import { ReactComponent as ArrowDownIcon } from "assets/icons/sort_arrow_down.svg";
import AtlasPagination from "./AtlasPagination";

import "./AtlasTable.scss";

interface IAtlasTableProps {
  // Columns describe the name of the column and how to extract the value from a data row.
  columns: { name: string; selector: (object) => any; sortable?: boolean }[];
  data: { id: string }[];

  currentPage: number;
  onUpdateCurrentPage: (n: number) => void;
  itemsPerPage: number;
  onUpdateItemsPerPage: (n: number) => void;
  orderBy: { column: string; desc: boolean } | undefined;
  onUpdateOrderBy: (orderBy: { column: string; desc: boolean }) => void;

  withPaginationFooter?: boolean;
  withoutTopRoundedBorder?: boolean;
  totalPages?: number;
}
function AtlasTable({
  columns,
  data,
  currentPage,
  onUpdateCurrentPage,
  itemsPerPage,
  onUpdateItemsPerPage,
  orderBy,
  onUpdateOrderBy,
  withPaginationFooter,
  withoutTopRoundedBorder,
  totalPages,
}: IAtlasTableProps) {
  //if(!currentPage)
  //  currentPage = 1

  //useEffect(() => {
  //  onUpdate(currentPage, itemsPerPage, orderBy)
  //}, [currentPage, itemsPerPage, orderBy])

  const startPage = Math.max(currentPage - 2, 1);
  let endPage = startPage + 4;
  if (totalPages !== undefined && totalPages < endPage) endPage = totalPages;
  var rangePages: number[] = [];
  for (var i = startPage; i <= endPage; i++) rangePages.push(i);

  const sortBy = (column: { name: string; selector: (object) => string }) => {
    var desc = true;
    if (orderBy && orderBy.column === column.name) desc = !orderBy.desc;
    onUpdateOrderBy({ column: column.name, desc: desc });
  };

  return (
    <div>
      <table
        className={
          "atlas-table" +
          (withoutTopRoundedBorder ? "" : " atlas-table-bordered-top")
        }
      >
        <thead>
          <tr>
            {columns.map((c) => (
              <th
                key={c.name}
                className={c.sortable ? "sortable" : ""}
                onClick={() => {
                  if (c.sortable) sortBy(c);
                }}
              >
                {c.name}{" "}
                {c.sortable && (
                  <div className="sort-arrows">
                    <ArrowDownIcon
                      className={
                        orderBy && orderBy.desc && orderBy.column === c.name
                          ? "ordered-by"
                          : ""
                      }
                    />
                    <ArrowDownIcon
                      className={
                        orderBy && !orderBy.desc && orderBy.column === c.name
                          ? "ordered-by"
                          : ""
                      }
                      style={{ rotate: "180deg" }}
                    />
                  </div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d) => (
            <tr key={d.id}>
              {columns.map((c) => (
                <td key={c.name}>{c.selector(d)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {withPaginationFooter && (
        <AtlasPagination
          defaultItemsPerPage={itemsPerPage}
          defaultCurrentPage={currentPage}
          rangePages={rangePages}
          callback={(newItemsPerPage, newCurrentPage) => {
            if (newItemsPerPage !== itemsPerPage)
              onUpdateItemsPerPage(newItemsPerPage);
            if (newCurrentPage !== currentPage)
              onUpdateCurrentPage(newCurrentPage);
          }}
        />
      )}
    </div>
  );
}

export default AtlasTable;
