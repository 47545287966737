// Propose a question for a theme, given a global list of known treatments/drugs and companies (pharma0.
const inspireMe = (
  disease: string,
  theme: string,
  treatments: string[],
  companies: string[]
) => {
  const treatment = treatments[Math.floor(Math.random() * treatments.length)];
  const company = companies[Math.floor(Math.random() * companies.length)];

  let questions = {
    "Treatment Options": [
      `What treatment do you take and how do you feel about it?`,
      `What are the different treatment options?`,
      `What are the pros and cons of your treatment?`,
      `What are your primary concerns and goals for your treatment?`,
      `Do you have any preferences in terms of the delivery method of treatment?`,
      `What do you need in order to make an informed decision about your treatment?`,
      `What treatment(s) have you tried and how effective were they?`,
      `What has been the most effective treatment option so far?`,
    ],
    "Diagnosis & Patient Care": [
      `What symptoms did you notice before being diagnosed?`,
      `Describe your attidude towards doctors`,
      `How can pharmaceutical companies work to improve patient access to information, resources, and treatment options for your treatment?`,
      `How were you diagnosed?`,
      `How can you work with you healthcare provider to get an accurate diagnosis for your condition?`,
      `Do you ever get frustrated with your doctor?`,
      `What steps can you take to manage your condition on a day-to-day basis, and how can medication help support you in this?`,
    ],
    "Science & Research": [
      `How do you feel about research around your disease?`,
      `Do you think there is enough research around your disease?`,
      `How do you feel about the pace of innovation around diagnosis and treatment for your disease?`,
      `How do you stay up-to date on the latest research related to your disease?`,
      `What kind of research have you seen related to your disease?`,
    ],
    "Patient Experiences": [
      `How has your disease affected your daily life and activities?`,
      `How has your treatment helped you manage your symptoms and improve your quality of life?`,
      `How has your condition impacted your life, and what kind of challenges have you faced as a result?`,
    ],
    "Advice & Community": [
      `How can you connect with other patients who are going through similar experiences?`,
      `What advice do health care professionals have for managing your disease?`,
      `What resources do pharma companies offer to help support communities dealing with your disease?`,
      `Where do you find the support and resources you need to manage your condition and treatment effectively?`,
      `Is there a strong community of patients who are dealing with your condition?`,
      `How helpful is the advice you are receiving from other patients?`,
      `Do you use social platforms or forums to get advice from other patients dealing with similar conditions to you?`,
    ],
    "Symptoms & Ailments": [
      `What are the common symptoms of your condition?`,
      `How can you best communicate your symptoms to your healthcare provider?`,
      `What were the first symptoms you noticed before being diagnosed with your condition?`,
      `How has medication help manage your symptoms?`,
      `What changes have you noticed in the frequency or severity of your symptoms as a result of using medication?`,
    ],
    "Side Effects": [
      `Are there any specific side effects that you are concered about or have experienced with treatments?`,
      `Are you aware of the potential side efffects of your treatment?`,
      `What are the most common side effects you have experienced using your treatment?`,
      `What kind of support is offered to help you manage any side effects while using your treatment?`,
      `Do the potential benefits of your treatment outweigh the potential risks and side effects, what factors do you consider when making this decision?`,
      `What kind of communication can you expect from pharma companies regarding any updates or changes related to potential side effects of your treatment?`,
    ],
    "Price & Insurance": [
      `How important is the cost of treatment to you?`,
      `What kind of financial assistance programs are available to help you cover the costs of your treatment?`,
      `Does your insurance cover the full cost of your treatment?`,
      `Has pricing ever been an issue for you when accessing your treatment?`,
      `What kind of resources do pharma companies offer to help with the costs associated with your treatment?`,
      `Does your treatment manufacturer/pharma company offer suitable resources in determining the best treatment options for your condition that is also within your budget?`,
    ],
  };

  if (theme in questions) {
    let possibleQuestions = questions[theme];
    return possibleQuestions[
      Math.floor(Math.random() * possibleQuestions.length)
    ];
  }
  return null;
};

const inspireMeThemes = [
  "Treatment Options",
  "Diagnosis & Patient Care",
  "Science & Research",
  "Patient Experiences",
  "Advice & Community",
  "Symptoms & Ailments",
  "Side Effects",
  "Price & Insurance",
];

export { inspireMe, inspireMeThemes };
