import React, { useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { useDetectClickOutside } from "react-detect-click-outside";
import { CSVLink } from "react-csv";

import { UserManager } from "utils/authConst";
import { config } from "config.js";

import BasePage from "pages/BasePage/BasePage";

import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
import { ReactComponent as ExportIcon } from "assets/icons/export.svg";

import GlossaryIcon from "assets/images/icons/glossary.svg";
import GlossaryHoverIcon from "assets/images/icons/glossary-hover.svg";
import PasswordIcon from "assets/images/icons/password.svg";
import PasswordHoverIcon from "assets/images/icons/password-hover.svg";
import HelpIcon from "assets/images/icons/help.svg";
import HelpHoverIcon from "assets/images/icons/help-hover.svg";
import LogoutIcon from "assets/images/icons/logout.svg";
import LogoutHoverIcon from "assets/images/icons/logout-hover.svg";

import "./styles.scss";
import { setIsLoading } from "redux/actions";

interface Props {
  title: string;
  loading: boolean;
  onExport?: (
    setCsvData: (data: string[][]) => void,
    setDownloadName: (data: string) => void,
    csvLink: any
  ) => void;
  children: React.ReactNode;
}

const Logo = config.BRANDING.LOGO;

// A Template page for any screen that are logged in and with configured topic.
// TODO: rename this to something less generic than PageTemplate.
function Page({ title, loading, onExport, children }: Props) {
  const [showSettings, setShowSettings] = useState(false);

  const [downloadName, setDownloadName] = useState("Atlas");
  const [csvData, setCsvData] = useState<string[][]>([]);
  const csvLink = useRef<any>();

  return (
    <BasePage>
      <ToastContainer />
      <CSVLink
        className="hidden"
        data={csvData}
        ref={csvLink}
        filename={`${downloadName}.csv`}
      >
        Download me
      </CSVLink>
      <div className="hs-header-container">
        <Link
          className="hs-header-logo-title"
          to={config.APP == "healthsocial" ? "/hs/home" : "/pollverse/home"}
        >
          <Logo className="hs-logo" width="40" height="40" />
          <h1>{title}</h1>
        </Link>

        <div className="hs-header-menubar">
          {/*<BellIcon width="25" height="25" />*/}
          {onExport && (
            <ExportIcon
              onClick={() => onExport(setCsvData, setDownloadName, csvLink)}
              width="25"
              height="25"
            />
          )}
          <SettingsIcon
            width="25"
            height="25"
            onClick={() => setShowSettings(true)}
          />
          {showSettings && (
            <SettingsMenu
              onClose={() => setShowSettings(false)}
              setIsLoading={setIsLoading}
            />
          )}
        </div>
      </div>

      <div className="hs-content-container">{children}</div>
      {loading && (
        <div className="load-wrapper">
          <div className="load-center">
            <Oval
              color="#78D2F1"
              height={40}
              width={40}
              secondaryColor="white"
              strokeWidth={5}
            />
            <span>Loading...</span>
          </div>
        </div>
      )}
    </BasePage>
  );
}

function MenuItem({ icon, hoverIcon, title, handleClick }) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = (e) => {
    setIsHovering(true);
  };

  const handleMouseOut = (e) => {
    setIsHovering(false);
  };

  return (
    <div
      className="hs-menu-item"
      onMouseOver={handleMouseMove}
      onMouseOut={handleMouseOut}
      onClick={(e) => {
        if (handleClick) handleClick();
      }}
    >
      {!isHovering && <img src={icon} alt="topic" />}
      {isHovering && <img src={hoverIcon} alt="topic" />}
      <span>{title}</span>
    </div>
  );
}

function SettingsMenu({ onClose, setIsLoading }) {
  const ref = useDetectClickOutside({ onTriggered: onClose });

  return (
    <div className="hs-menu-settings hs-menu-dropdown" ref={ref}>
      {/*<MenuItem
        icon={GlossaryIcon}
        hoverIcon={GlossaryHoverIcon}
        title='Glossary'
        handleClick={() => openMenu('glossary')} />*/}
      {/*<MenuItem
        icon={PasswordIcon}
        hoverIcon={PasswordHoverIcon}
        title="Change Password"
        handleClick={() => {
          onClose();
          window.location.href = CHANGE_PASSWORD_ROUTE;
        }}
      />*/}
      {/*<MenuItem
        icon={HelpIcon}
        hoverIcon={HelpHoverIcon}
        title='Help &amp; Support'
        handleClick={handleSupport} />*/}
      <MenuItem
        icon={LogoutIcon}
        hoverIcon={LogoutHoverIcon}
        title="Logout"
        handleClick={() => {
          setIsLoading();
          UserManager.signoutRedirect();
        }}
      />
    </div>
  );
}

export default Page;
