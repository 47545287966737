import { ReactComponent as HSLogo } from "assets/logos/healthsocial.svg";
import { ReactComponent as AtlasLogo } from "assets/logos/atlas.svg";

// We do most of the config based on actual domain where this is run. This is probably not
// ideal and it might be better to deploy different binary config for each deployment environment,
// but it also simplifies our deployment where we need a single hosted website and multiple
// domains pointing to it through a load balancer.
// Note that we try to limit this config per domain to front-end services (this React app as well
// as the identity server, which also should be a single deployment with different UI depending on
// what domain it is serving from). We should also keep in mind that this only works while we share
// the same DB for these, once we start using independent DBs we are better off deploying independent
// binaries as well because everything will end up duplicated.

type AppMode = "healthsocial" | "pollverse";

const defaultAppMode = "healthsocial";
// We host all the apps based on Atlas technology on the same binary, they are just split by the initial
// prefix (/hs, /pollverse, etc). As there's no way to navigate from one to another within the SPA, these
// top level constant are truly constant on page load.
const appMode = window.location.pathname.startsWith("/hs")
  ? "healthsocial"
  : window.location.pathname.startsWith("/pollverse")
  ? "pollverse"
  : defaultAppMode;

// The Frontend has a different branding based on the domain it is accessed through. The
// underlying functionaly and capabilities are exactly the same, but this is to help brand
// its features towards different types of clients.
var healthSocialBranding = {
  TITLE: "HealthSocial",
  LOGO: HSLogo,
  // the favicon is in the /public folder.
  FAVICON: "/favicon-healthsocial.png",
  PATHWAYS_REC: "HS Rec",
};
var atlasBranding = {
  TITLE: "Mach9 Atlas",
  LOGO: AtlasLogo,
  FAVICON: "/favicon-atlas.png",
  PATHWAYS_REC: "Mach9 Rec",
};

var pollverseBranding = {
  TITLE: "Pollverse",
  LOGO: AtlasLogo,
  FAVICON: "/favicon-atlas.png",
  // PATHWAYS_REC: "Mach9 Rec",
};

var branding = healthSocialBranding;
var authority = "https://login.healthsocial.com/";

if (window.location.host == "app.healthsocial.com") {
  branding = healthSocialBranding;
  authority = "https://login.healthsocial.com/";
} else if (window.location.host == "appdev.healthsocial.com") {
  branding = healthSocialBranding;
  authority = "https://logindev.mach9atlas.com/";
} else if (window.location.host == "mach9atlas.com") {
  branding = atlasBranding;
  authority = "https://login.mach9atlas.com/";
} else if (window.location.host == "beta.mach9atlas.com") {
  branding = atlasBranding;
  authority = "https://login.mach9atlas.com/";
} else if (window.location.host == "dev.mach9atlas.com") {
  branding = atlasBranding;
  authority = "https://logindev.mach9atlas.com/";
} else if (window.location.host == "localhost:3000") {
  //branding = healthSocialBranding;
  branding = atlasBranding;
  authority = "https://localhost:5001/";
}

export const config = {
  BASE_URL: window.location.protocol + "//" + window.location.host,

  CLIENT_ID: window._env_.CLIENT_ID,
  AUTHORITY: authority,
  // TODO: AUTHORITY is not used anymore from the config file.
  //AUTHORITY: window._env_.AUTHORITY,
  RESPONSE_TYPE: window._env_.RESPONSE_TYPE,
  SCOPE: window._env_.SCOPE,
  WEB_API_BASE_URI: window._env_.WEB_API_BASE_URI,
  CHANGE_PASSWORD_ROUTE: window._env_.CHANGE_PASSWORD_ROUTE,

  BRANDING: branding,
  POLLVERSE: {
    BRANDING: pollverseBranding,
  },

  APP: appMode,

  ENABLE_CONTENT_STUDIO: window._env_.ENABLE_CONTENT_STUDIO,
};
