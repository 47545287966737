/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";

import debateData from "./data";

import { HS_VOICES_SAVED } from "constants/routes";

import TabbedPageContent from "components/TabbedPageContent";

import { ReactComponent as StarIcon } from "assets/icons/star.svg";

import "./styles.scss";

function VoicesTabbedPageContent(props) {
  const { tab } = useParams();

  const [triggerExport, setTriggerExport] = useState(false);

  const audienceBots = props.topic.topicBots
    .map((tb) => tb.bot)
    .filter((b) => b.topicAudienceId)
    .map((b) => ({
      id: b.id,
      audience: props.topic.audiences.find((a) => a.id == b.topicAudienceId)
        .name,
    }));
  const individualBots = props.topic.topicBots
    .map((tb) => tb.bot)
    .filter((b) => !b.topicAudienceId);

  const tabs = audienceBots.map((ab) => ({
    id: ab.audience.toLowerCase(),
    label: ab.audience,
    withCalendar: false,
    widget: <SavedButton />,
  }));
  if (individualBots.length > 0) {
    tabs.push({
      id: "starred-accounts",
      label: "Starred Accounts (Beta)",
      withCalendar: false,
    });
  }

  if (props.topic.id in debateData) {
    tabs.push({
      id: "debate",
      label: "Debate Showdown",
      withCalendar: false,
    });
  }

  const activeTab = tabs.findIndex(
    (t) => (props.tab && t.id === props.tab) || t.id === tab
  );

  return (
    <TabbedPageContent tabs={tabs} activeTab={activeTab}>
      {props.children}
    </TabbedPageContent>
  );
}

function SavedButton(props) {
  return (
    <Link to={HS_VOICES_SAVED} className="voices-saved-button">
      <StarIcon fill={"#8D9CA6"} /> Saved
    </Link>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");

  return {
    topic: state.ui.topic,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoicesTabbedPageContent);
