import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { CSVLink } from "react-csv";

import ExportIcon from "assets/images/icons/anal-export.svg";
import XIcon from "assets/images/icons/anal-x.svg";
import GeneralIcon from "assets/images/icons/anal-general-info.svg";
import MediaIcon from "assets/images/icons/anal-media.svg";
import BrandIcon from "assets/images/icons/anal-brands.svg";
import InfluIcon from "assets/images/icons/anal-infulencer.svg";
import CitiesIcon from "assets/images/icons/anal-cities.svg";
import EthnIcon from "assets/images/icons/anal-ethnicity.svg";
import InterIcon from "assets/images/icons/anal-interest.svg";
import EduIcon from "assets/images/icons/anal-education.svg";
import AgeIcon from "assets/images/icons/anal-age.svg";
import IncomeIcon from "assets/images/icons/anal-income.svg";

import "./styles.scss";

interface IRoundProgress {
  percent: number;
  text: string;
}
function RoundProgress({ percent, text }: IRoundProgress) {
  return (
    <div className="round-progress">
      <div className="progress" style={{ width: `${percent}%` }}></div>
      <span>{text}</span>
    </div>
  );
}

interface IRectProgress {
  percent: number;
  text: string;
  max: number;
}
function RectProgress({ percent, text, max }: IRectProgress) {
  return (
    <div className="rect-progress">
      <div
        className="progress"
        style={{ width: `${(percent / (max * 1.2)) * 100}%` }}
      ></div>
      <div className="text-wrapper">
        <span className="percent">{percent.toFixed(1)}%</span>
        <span className="text">{text}</span>
      </div>
    </div>
  );
}

interface IInfobox {
  icon: any;
  title: string;
  data: AtlasMach.IAASection;
  dataKey: string;
  decimal?: boolean;
  dataCount?: number;
}
function RoundProgressInfo({
  icon,
  title,
  data,
  dataKey,
  decimal,
  dataCount,
}: IInfobox) {
  const section = useMemo(() => {
    return data.Sections.find((sect) => sect.Section === dataKey);
  }, [data, dataKey]);
  return (
    <div className="info-box">
      <div className="info-header">
        <img src={icon} alt="icon" />
        <span>{title}</span>
      </div>
      <div className="info-body general">
        {section &&
          dataCount &&
          section.Items.filter((v, i) => i < dataCount).map((v, i) => (
            <RoundProgress
              key={`aa-item-${v.Name}`}
              percent={Math.max(v.Pct, 10)}
              text={`${decimal ? v.Pct.toFixed(2) : Math.round(v.Pct)}% are ${
                v.Name
              }`}
            />
          ))}
      </div>
    </div>
  );
}

function doesThisKeyExist(key, generalInfo) {
  const itemInfo = generalInfo.Items.find((gi) => gi.Name === key);
  return itemInfo !== null && typeof itemInfo !== "undefined";
}

function GeneralInfo({ icon, title, data, dataKey, decimal, dataCount }) {
  const section = useMemo(() => {
    if (dataKey === "General Info") {
      const generalInfo = data.Sections.find(
        (sect) => sect.Section === dataKey
      );
      const itemArray = [
        "Male",
        "Married",
        "Single",
        "Female",
        "Parents",
      ].filter((x) => doesThisKeyExist(x, generalInfo));
      if (generalInfo) {
        return {
          Section: "General Info",
          Items: itemArray.map((key) => {
            const itemInfo = generalInfo.Items.find((gi) => gi.Name === key);
            if (itemInfo === null || typeof itemInfo === "undefined") {
            } else {
            }
            return {
              Name: key,
              Pct: itemInfo?.Pct,
              Size: itemInfo?.Size,
              Num: itemInfo?.Num,
            };
          }),
        };
      } else {
        return {
          Section: "",
          Items: [],
        };
      }
    }
    return data.Sections.find((sect) => sect.Section === dataKey);
  }, [data, dataKey]);
  return (
    <div className="info-box">
      <div className="info-header">
        <img src={icon} alt="icon" />
        <span>{title}</span>
      </div>
      <div className="info-body general">
        {section &&
          dataCount &&
          section.Items.filter((v, i) => i < dataCount).map((v, i) => (
            <RoundProgress
              key={`aa-item-${v.Name}`}
              percent={Math.max(v.Pct, 10)}
              text={`${decimal ? v.Pct.toFixed(2) : Math.round(v.Pct)}% are ${
                v.Name
              }`}
            />
          ))}
      </div>
    </div>
  );
}

function EthnicityInfo({ icon, title, data }: IInfobox) {
  const section = useMemo(() => {
    return data.Sections.find((sect) => sect.Section === "Ethnicity Graph");
  }, [data]);
  const colors = ["#E4B5B5", "#7795FE", "#B7A6E9", "#8AC890"];
  return (
    <div className="info-box double">
      <div className="info-header">
        <img src={icon} alt="icon" />
        <span>{title}</span>
      </div>
      <div className="info-body">
        <div className="doughnut-wrapper">
          <Doughnut
            data={{
              labels: section?.Items.map(
                (ds) => `${ds.Name} (${ds.Pct.toFixed(2)}%)`
              ),
              datasets: [
                {
                  data: section?.Items.map((ds) => ds.Pct.toFixed(2)),
                  backgroundColor: section?.Items.map((ds, idx) => colors[idx]),
                  borderWidth: 0,
                  hoverBorderColor: "white",
                  hoverBorderWidth: 1,
                },
              ],
            }}
            options={{
              cutout: "40%",
              maintainAspectRatio: false,
              plugins: {
                tooltip: {
                  bodyFont: {
                    family: "Tomorrow",
                    size: 14,
                  },
                  callbacks: {
                    label: function (context) {
                      return ` ${context.dataset.data[context.dataIndex]} %`;
                    },
                  },
                },
                legend: {
                  position: "right",
                  labels: {
                    font: {
                      family: "Tomorrow",
                      size: 12,
                    },
                    color: "white",
                    usePointStyle: true,
                    pointStyle: "circle",
                    padding: 15,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

function RectProgressInfo({ icon, title, data, dataKey }: IInfobox) {
  const section = useMemo(() => {
    var s = data.Sections.find((sect) => sect.Section === dataKey);
    if (dataKey == "Age" && s) {
      s.Items.sort((a, b) => {
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
      });
    }
    if (dataKey == "Personal Income" && s) {
      s.Items.sort((a, b) => {
        if (a.Name == b.Name) return 0;
        if (a.Name.startsWith("Under")) return -1;
        if (b.Name.startsWith("Under")) return 1;
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
      });
    }
    return s;
  }, [data, dataKey]);
  const maxPercent = useMemo(() => {
    return Math.max(...(section?.Items.map((it) => it.Pct) || []));
  }, [section]);
  return (
    <div className="info-box">
      <div className="info-header">
        <img src={icon} alt="icon" />
        <span>{title}</span>
      </div>
      <div
        className="info-body common hs-scrollable-container"
        style={{ padding: "10px 20px" }}
      >
        {section?.Items.map((dt, idx) => (
          <RectProgress
            key={`aa-item-${dt.Name}`}
            percent={dt.Pct}
            text={dt.Name}
            max={maxPercent}
          />
        ))}
      </div>
    </div>
  );
}

function CommonInfo({ icon, title, data, dataKey }: IInfobox) {
  const section = useMemo(() => {
    return data.Sections.find((sect) => sect.Section === dataKey);
  }, [data, dataKey]);
  return (
    <div className="info-box">
      <div className="info-header">
        <img src={icon} alt="icon" />
        <span>{title}</span>
      </div>
      <div className="info-body common hs-scrollable-container">
        {section?.Items.map((dt, idx) => (
          <div className="info-row" key={`aa-item-${dt.Name}`}>
            <div
              className="circle"
              style={{
                background: `rgba(120, 210, 241, ${Math.round(dt.Pct)})`,
              }}
            ></div>
            <div className="text">
              <div className="title">{dt.Name}</div>
              <div className="value">{dt.Pct.toFixed(1)}%</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface IProp {
  authToken: string;
  onClose?: () => void;
  aaData: AtlasMach.IAASection;
}
function AudienceAnalysis({ authToken, onClose, aaData }: IProp) {
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [downloadName, setDownloadName] = useState("Atlas");

  const handleExport = useCallback(() => {
    let csv = [["Category", "Value", "Percentage"]];
    const generalInfo = aaData.Sections.find(
      (sect) => sect.Section === "General Info"
    );
    if (generalInfo) {
      ["Male", "Female", "Married", "Single", "Parents"].forEach((key) => {
        const itemInfo = generalInfo.Items.find((gi) => gi.Name === key);
        if (itemInfo !== null && typeof itemInfo !== "undefined") {
          csv.push([
            "General Info",
            String(itemInfo?.Name),
            String(itemInfo?.Pct.toFixed(2)) + "%",
          ]);
        }
      });
    }
    aaData.Sections.find(
      (sect) => sect.Section === "Ethnicity Graph"
    )?.Items.forEach((item) => {
      csv.push(["Ethnicity", item.Name, item.Pct.toFixed(2) + "%"]);
    });
    [
      "Cities",
      "Personal Income",
      "Age",
      "Level of Education",
      "Interest Areas",
      "Media",
      "Brands",
      "Influencers",
      "Education",
    ].forEach((key) => {
      aaData.Sections.find((sect) => sect.Section === key)?.Items.forEach(
        (item) => {
          csv.push([key, item.Name, item.Pct.toFixed(2) + "%"]);
        }
      );
    });

    setCsvData(csv);
    const fileName = `Atlas[${aaData.name}]_Audience_Analysis`;
    setDownloadName(fileName);
    setTimeout(() => {
      csvLink.current.link.click();
    }, 500);
  }, [aaData]);

  const csvLink = useRef<any>();

  const handleClose = () => {
    if (onClose) onClose();
  };

  if (!aaData.name) return <div></div>;

  return (
    <div className="aa-wrapper">
      <CSVLink
        className="hidden"
        data={csvData}
        ref={csvLink}
        filename={`${downloadName}.csv`}
      >
        Download me
      </CSVLink>
      <div className="border-wrapper">
        <div className="aa-container">
          <div className="aa-header">
            <span>Audience Analysis</span>
            <div className="header-icons">
              <img
                className="header-export"
                src={ExportIcon}
                alt="menu"
                onClick={handleExport}
              />
              <img src={XIcon} alt="menu" onClick={handleClose} />
            </div>
          </div>
          <div className="aa-body">
            <div className="profile">
              <div className="photo">
                {aaData.profileImage && (
                  <img src={aaData.profileImage} alt="" />
                )}
              </div>
              <div className="text">
                <span className="name">{aaData.name}</span>
                <span className="state"></span>
              </div>
            </div>
            <div className="infobox-wrapper">
              <GeneralInfo
                icon={GeneralIcon}
                data={aaData}
                title="General Info"
                dataKey="General Info"
                dataCount={3}
                decimal={0}
              />
              <EthnicityInfo
                icon={EthnIcon}
                data={aaData}
                title="Ethnicity"
                dataKey="Ethnicity"
              />
            </div>
            <div className="infobox-wrapper">
              <RectProgressInfo
                icon={AgeIcon}
                data={aaData}
                title="Age"
                dataKey="Age"
              />
              <RectProgressInfo
                icon={IncomeIcon}
                data={aaData}
                title="Personal Income"
                dataKey="Personal Income"
              />
              <RectProgressInfo
                icon={CitiesIcon}
                data={aaData}
                title="Cities"
                dataKey="Cities"
              />
              <RoundProgressInfo
                icon={EduIcon}
                data={aaData}
                title="Level of Education"
                dataKey="Level of Education"
                decimal
                dataCount={4}
              />
            </div>
            <div className="infobox-wrapper">
              <CommonInfo
                icon={InterIcon}
                data={aaData}
                title="Interest Areas"
                dataKey="Interest Areas"
              />
              <CommonInfo
                icon={MediaIcon}
                data={aaData}
                title="Media"
                dataKey="Media"
              />
              <CommonInfo
                icon={BrandIcon}
                data={aaData}
                title="Brands"
                dataKey="Brands"
              />
              <CommonInfo
                icon={InfluIcon}
                data={aaData}
                title="Influencers"
                dataKey="Influencers"
              />
              <CommonInfo
                icon={EduIcon}
                data={aaData}
                title="Universities"
                dataKey="Education"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AudienceAnalysis;
