import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { inspireMe, inspireMeThemes } from "./inspireme";

import AudienceVoicesScreen from "./AudienceVoicesScreen";

function PatientsScreen({ topic, botId, audience }) {
  return (
    <AudienceVoicesScreen
      selectWidget={undefined}
      isSavedEnabled={true}
      isGlobal={false}
      inspireMeLead={"Choose a theme"}
      audience={audience}
      tab={audience.toLowerCase()}
      welcomeText={`I represent the voice of ${topic.name} ${audience} based on online conversations over the period of`}
      examplePostsLead={"Examples of actual posts by " + audience + " audience"}
      disclaimer={
        "This is an Artificial Intelligence engine that summarizes real " +
        audience +
        " posts. Answers to individualized questions may vary."
      }
      inspireMe={inspireMe}
      /*inspireMeThemes={inspireMeThemes}*/
      inspireMeThemes={[]}
    />
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    brands: state.data.brands,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsScreen);
