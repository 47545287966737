import React from "react";

import "./ModuleNotAvailable.scss";

function ModuleNotAvailable() {
  return (
    <div className="hs-module-not-available">
      Sorry, this module is not available
    </div>
  );
}

export default ModuleNotAvailable;
