import React, { useState, useEffect } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Select from "react-select";
import { connect } from "react-redux";

import { config } from "../../../config.js";

import { API_VOICES_PATIENTS } from "constants/routes";

import { convertToDt } from "utils/convertData";

import AtlasFullScreenPopup from "components/AtlasFullScreenPopup";
import ModuleNotAvailable from "components/ModuleNotAvailable";
import PageTemplate from "pages/PageTemplate";
import PollVerseTabbedPageContent from "./PollVerseTabbedPageContent";
import Typing from "../../../components/Typing";
import AtlasSelect from "../../../components/AtlasSelect";
import FiltersBar from "components/Pollverse/FiltersBar";

import {
  politicians,
  interviewsDb,
  nicotineTopics,
  pharmaTopics,
} from "../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy as faCopyRegular } from "@fortawesome/free-regular-svg-icons";

const POLITICIANS = [
  {
    value: "Cucinelli",
    label: "Brunello Cucinelli",
    name: "Brunello Cucinelli",
  },
  {
    value: "Antoine",
    label: "Antoine Arnault",
    name: "Antoine Arnault",
  },
  {
    value: "ae2d6866-9467-4eca-9278-b598ebc4e252",
    label: "@beth_morton (Patient)",
    name: "@beth_morton (Patient)",
  },
  {
    value: "58dc0f0e-ed3e-4d12-863c-97ce8d92319b",
    label: "@CSWhiteMD (HCP)",
    name: "@CSWhiteMD (HCP)",
  },
  {
    value: "railway",
    label: "@Simply_Railway",
    name: "@Simply_Railway",
  },
  {
    value: "oscar",
    label: "@DevOpsOscar",
    name: "@DevOpsOscar",
  },
];

var generalTopics = JSON.parse(
  JSON.stringify(interviewsDb["Marsha Blackburn"])
);

Object.keys(interviewsDb["Marsha Blackburn"]).forEach((k) => {
  generalTopics[k] = interviewsDb["Marsha Blackburn"][k]["questions"].map(
    (q) => q.question
  );
});

const POLITICAL_TOPICS = [
  {
    value: "general topics",
    label: "Current Political Issues",
    topics: generalTopics,
  },
  {
    value: "nicotine topics",
    label: "Nicotine Industry",
    topics: nicotineTopics,
  },
  {
    value: "pharma topics",
    label: "Pharma Industry",
    topics: pharmaTopics,
  },
];

interface IExamplePost {
  permalink: string;
  text: string;
}
interface IAudienceResponse {
  response: string;
  examplePosts: IExamplePost[];
}

function ResponseCard({ account, response }) {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <div className="pollverse-response-card">
      <div>
        <div className="pollverse-response-card-name">
          <div>{account.name}</div>
        </div>
        <div className="pollverse-response-card-response">
          {response.response}
        </div>
        {response.examplePosts.length > 0 && (
          <div className="pollverse-example-posts">
            <button
              className="hs-button primary"
              onClick={() => setShowPopup(true)}
            >
              See Example Posts
            </button>
          </div>
        )}
      </div>
      {showPopup && (
        <AtlasFullScreenPopup onClose={() => setShowPopup(false)}>
          <div className="pollverse-inspire-me-popup">
            <h2>Example Posts for {account.name}</h2>
            <div>
              <div className="pollverse-example-posts">
                {response.examplePosts.map((p) => (
                  <a
                    key={p.permalink}
                    href={p.permalink}
                    target="_blank"
                    className="pollverse-voices-example-post"
                  >
                    {p.text}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </AtlasFullScreenPopup>
      )}
    </div>
  );
}

function VoicesScreen({ topic, filters, brands, authToken }) {
  var disclaimer = "";
  const options = [
    ...POLITICIANS,
    {
      value: "all",
      label: "All Accounts",
      name: "All Accounts",
    },
  ];

  // const [selectedAccount, setSelectedAccount] = useState<{
  //   value: string;
  //   label: string;
  // }>(options[0]);
  const [selectedAccounts, setSelectedAccounts] = useState<
    {
      value: string;
      label: string;
    }[]
  >([options[0]]);

  const [question, setQuestion] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [responses, setResponses] = useState<IAudienceResponse[] | []>([]);

  const [savedAnswerId, setSavedAnswerId] = useState<string>("");

  const [showInspireMePopup, setShowInspireMePopup] = useState<boolean>(false);

  const dt_from = new Date(topic.startDate);
  const initialFromDate = {
    year: dt_from.getFullYear(),
    month: dt_from.getMonth(),
  };
  const [fromDate, setFromDate] = useState<{
    value: string;
    label: string;
    payload: AtlasMach.IDate;
  }>(dateToOption(initialFromDate));
  const dt_to = new Date(topic.endtDate);
  const initialToDate = { year: dt_to.getFullYear(), month: dt_to.getMonth() };
  const [toDate, setToDate] = useState<{
    value: string;
    label: string;
    payload: AtlasMach.IDate;
  }>(dateToOption(initialToDate));

  const isDateRangeValid =
    fromDate.payload.year < toDate.payload.year ||
    (fromDate.payload.year == toDate.payload.year &&
      fromDate.payload.month <= toDate.payload.month);

  let welcomeText =
    "I am an AI Persona Generator representing the online posts of " +
    (selectedAccounts.length === 1
      ? selectedAccounts[0].label
      : "All Accounts") +
    " over the period of ";

  const disease = topic.name;
  const treatments = brands.filter((b) => !b.isCompany).map((b) => b.name);
  const companies = brands.filter((b) => b.isCompany).map((b) => b.name);

  function getResponses(account) {
    const dt = convertToDt(fromDate.payload, toDate.payload);
    const apiUrl = API_VOICES_PATIENTS.replace("$1", topic.id); // + `?startDate=${dt.dt_from}&endDate=${dt.dt_to}`

    return fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        topicId: undefined,
        audience: account.value,
        question: question,
        dateFrom: dt.dt_from,
        dateTo: dt.dt_to,
      }),
    }).then((res) => {
      if (res.status !== 200) {
        console.error("Unexpected response code for query: " + apiUrl);
        console.log(res);
        // TODO: what to return and how to redirect?
        // setSubmitting(false);
        return {
          response:
            "Sorry, I'm a bit overwhelmed right now, can you ask again later?",
          examplePosts: [],
        };
      }
      return res.json();
    });
  }

  async function submitQuestion() {
    if (submitting) return;

    setResponses([]);
    var allResponses: any[] = [];
    setSubmitting(true);

    // const dt = convertToDt(fromDate.payload, toDate.payload);
    // const apiUrl = API_VOICES_PATIENTS.replace("$1", topic.id); // + `?startDate=${dt.dt_from}&endDate=${dt.dt_to}`

    for (const account of selectedAccounts) {
      const resp = await getResponses(account);
      allResponses = [...allResponses, resp];
    }
    setResponses(allResponses);
    setSubmitting(false);

    // Promise.all(selectedAccounts.map(getResponses)).then((resps: any[]) => {
    //   setSubmitting(false);
    //   setResponses(resps);
    // });
  }

  // const saveAnswer = () => {
  //   if (!response) return;

  //   const dt = convertToDt(fromDate.payload, toDate.payload);
  //   const apiUrl = API_VOICES_SAVED_ANSWERS.replace("$1", topic.id);
  //   fetch(apiUrl, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //     body: JSON.stringify({
  //       question: {
  //         topicId: topic.id,
  //         audience: "stevecohen",
  //         question: question,
  //         dateFrom: dt.dt_from,
  //         dateTo: dt.dt_to,
  //       },
  //       answer: {
  //         answer: response.response,
  //         examplePosts: response.examplePosts,
  //       },
  //     }),
  //   })
  //     .then((res) => {
  //       if (res.status !== 201) {
  //         console.error("Unexpected response code for query: " + apiUrl);
  //         console.log(res);
  //         alert("Failed to save the answer due to an unexpected error");
  //       }
  //       return res.json();
  //     })
  //     .then((res) => {
  //       setSavedAnswerId(res.id);
  //     });
  // };
  // const deleteAnswer = () => {
  //   const apiUrl = API_VOICES_SAVED_ANSWERS.replace("$1", topic.id).replace(
  //     "$2",
  //     savedAnswerId
  //   );
  //   fetch(apiUrl, {
  //     method: "DELETE",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //   }).then((res) => {
  //     if (res.status !== 204) {
  //       console.error("Unexpected response code for query: " + apiUrl);
  //       console.log(res);
  //     }
  //   });
  //   setSavedAnswerId("");
  // };

  const handleInputChange = (e) => {
    setQuestion(e.target.value.replace("\n", ""));
  };
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && !isSubmitDisabled) submitQuestion();
  };
  const handleChange = (option) => {
    setResponses([]);
    if (option.value === "all") {
      setSelectedAccounts(options.filter((o) => o.value != "all"));
    } else {
      setSelectedAccounts([option]);
    }
  };

  const isVoiceSupported: boolean = true;
  const isSubmitDisabled = submitting || !isDateRangeValid;

  const dateOptions = generateDateRange(initialFromDate, initialToDate).map(
    (d) => dateToOption(d)
  );
  return (
    <PageTemplate title={config.POLLVERSE.BRANDING.TITLE}>
      <PollVerseTabbedPageContent tab={"voices"}>
        <>
          {/*
          <FiltersBar
            disableSources={true}
            disableAudiences={true}
            disableBrands={true}
            disableThemes={true}
            disableSentiments={true}
            enableStarredAccounts={false}
            defaultStates={[{ value: "tennessee", label: "Tennessee" }]}
            filters={{
              audiences: [],
              brandAccounts: [],
              brands: [],
              engagementsLikelihood: [],
              sentiments: [],
              source: {
                id: "8392c05d-9a21-4547-98a6-cc9d1c1e94de",
                name: "Twitter",
                color: "#FF0000",
              },
              sources: [],
              themes: [
                {
                  id: "3d7e684e-5e75-4992-9c62-e67d95d17bc2",
                  name: "Gun Control",
                  color: "rgba(228,121,121,1)",
                },
              ],
            }}
          /> */}
          {showInspireMePopup && (
            <InspireMePopup
              onClose={() => setShowInspireMePopup(false)}
              setQuestion={setQuestion}
            />
          )}
          <div className="pollverse-voices-screen-container">
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <AtlasSelect
                placeholder={"Choose an account"}
                options={options}
                onChange={handleChange}
                value={
                  selectedAccounts.length > 1
                    ? options[options.length - 1] // All accounts
                    : selectedAccounts[0] // First an only selected
                }
              />
            </div>
            <div className="pollverse-voices-welcome-text">
              {!isVoiceSupported && <ModuleNotAvailable />}
              {isVoiceSupported && (
                <>
                  {welcomeText}
                  <SelectDate
                    options={dateOptions}
                    value={fromDate}
                    onChange={(d) => setFromDate(d)}
                  />
                  and{" "}
                  <SelectDate
                    options={dateOptions}
                    value={toDate}
                    onChange={(d) => setToDate(d)}
                  />
                </>
              )}
            </div>
            {/*<div className="pollverse-voices-inspire-me">
              <button
                className="hs-button primary"
                onClick={() => setShowInspireMePopup(true)}
              >
                Subjects of Discussion
              </button>
            </div>*/}

            {isVoiceSupported && (
              <div className="pollverse-voices-body">
                <div className="pollverse-voices-question-answer">
                  <div className="pollverse-voices-question">
                    <TextareaAutosize
                      disabled={submitting}
                      className="pollverse-voices-question-input"
                      placeholder="Ask your question"
                      value={question}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                    />
                    <button
                      className={
                        "pollverse-voices-question-submit" +
                        (isSubmitDisabled ? " disabled" : "")
                      }
                      onClick={() => {
                        if (!isSubmitDisabled) submitQuestion();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  {submitting && <Typing label={"typing"} />}
                  {responses.length === 1 && (
                    <div>
                      <div className="pollverse-voices-answer">
                        <div className="pollverse-voices-answer-text">
                          {responses[0].response}
                        </div>
                      </div>
                      {responses[0].examplePosts.length > 0 && (
                        <div className="pollverse-example-posts">
                          <p>{"See examples"}</p>
                          {responses[0].examplePosts.map((p) => (
                            <a
                              key={p.permalink}
                              href={p.permalink}
                              target="_blank"
                              className="pollverse-voices-example-post"
                            >
                              {p.text}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {responses.length > 1 && (
                    <div className="pollverse-response-card-wrapper">
                      {responses.map((response, index) => (
                        <ResponseCard
                          account={selectedAccounts[index]}
                          response={response}
                        />
                      ))}
                    </div>
                  )}
                  <div className="pollverse-voices-disclaimer">
                    {disclaimer}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      </PollVerseTabbedPageContent>
    </PageTemplate>
  );
}

function InspireMePopup({ onClose, setQuestion }) {
  const options = Object.values(POLITICAL_TOPICS);

  const [selectedPoliticalIssue, setSelectedPoliticalIssue] = useState<{
    value: string;
    label: string;
    topics: Array<String>;
  }>(options[0]);

  const handleChange = (option) => {
    setSelectedPoliticalIssue(option);
  };

  return (
    <AtlasFullScreenPopup onClose={onClose}>
      <div className="pollverse-inspire-me-popup">
        {/*<h2>Subjects of Discussion</h2>*/}
        <div>
          <div className="select-wrapper">
            <AtlasSelect
              width={320}
              placeholder={
                selectedPoliticalIssue
              } /** TODO: allow for default value in AtlasSelect */
              options={options}
              onChange={handleChange}
              value={selectedPoliticalIssue}
            />
          </div>
          {Object.keys(selectedPoliticalIssue["topics"]).map((topic) => (
            <TopicDropdown
              key={topic}
              setQuestion={(question) => {
                setQuestion(question);
                onClose();
              }}
              topic={topic}
              questions={selectedPoliticalIssue["topics"][topic]}
            />
          ))}
        </div>
      </div>
    </AtlasFullScreenPopup>
  );
}

function TopicDropdown({ topic, questions, setQuestion }) {
  const [showQuestions, setShowQuestions] = useState<boolean>(false);

  return (
    <>
      <div
        onClick={() => setShowQuestions(!showQuestions)}
        className="pollverse-inspire-me-popup-topic"
      >
        <span>{topic}</span> <i>&#8964;</i>
      </div>
      {showQuestions && (
        <ol className="pollverse-inspire-me-popup-questions">
          {questions.map((question) => (
            <li onClick={() => setQuestion(question)}>
              <div>
                <div>{question}</div>
                <div>
                  <FontAwesomeIcon icon={faCopyRegular} />
                </div>
              </div>
            </li>
          ))}
        </ol>
      )}
    </>
  );
}

const SelectDate = ({ options, value, onChange }) => {
  return (
    <Select
      unstyled
      onChange={onChange}
      options={options}
      value={value}
      isSearchable={false}
      styles={{
        container: (baseStyle, state) => ({
          ...baseStyle,
          display: "inline-block",
        }),
        control: (baseStyle, state) => ({
          ...baseStyle,
          margin: "0",
          padding: "0 5px",
          border: "1px solid #1C3A4A",
          borderRadius: "15px",
          cursor: "pointer",
        }),
        indicatorsContainer: (baseStyle, state) => ({
          ...baseStyle,
          color: "#8D9CA6",
          marginLeft: "5px",
        }),
        menu: (baseStyle, state) => ({
          ...baseStyle,
          background: "#112936",
          border: "1px solid #1C3A4A",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.45)",
          borderRadius: "14px",
          width: "max-content",
          minWidth: "100%",
          padding: "15px",
        }),
        option: (baseStyle, state) => ({
          ...baseStyle,
          margin: "10px 0",
          fontWeight: state.isFocused ? "bold" : "400",
          cursor: "pointer",
        }),
        singleValue: (baseStyle, state) => ({
          ...baseStyle,
          color: "#78D2F1",
          textDecoration: "underline",
          cursor: "pointer",
        }),
      }}
    />
  );
};

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function dateToOption(date: AtlasMach.IDate): {
  value: string;
  label: string;
  payload: AtlasMach.IDate;
} {
  return {
    value: `${MONTHS[date.month]} ${date.year}`,
    label: `${MONTHS[date.month]} ${date.year}`,
    payload: date,
  };
}

// Generate a list of all month starting at fromDate and ending at toDate (inclusive).
function generateDateRange(
  fromDate: AtlasMach.IDate,
  toDate: AtlasMach.IDate
): AtlasMach.IDate[] {
  let res: AtlasMach.IDate[] = [];
  for (var year = fromDate.year; year <= toDate.year; year++) {
    for (var month = 0; month <= 11; month++) {
      if (year == fromDate.year && month < fromDate.month) continue;
      if (year == toDate.year && month > toDate.month) continue;
      res.push({ year, month });
    }
  }
  return res;
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize this component");
  if (!state.ui.filters)
    throw new Error("Filters must be set to initialize this component");

  return {
    topic: state.ui.topic,
    filters: state.ui.filters,
    dateRange: state.ui.dateRange,
    brands: state.data.brands,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VoicesScreen);
