import React from "react";
import PageTemplate from "pages/PageTemplate";
import "./styles.scss";
import { Link } from "react-router-dom";

function Page404() {
  return (
    <PageTemplate title="Page not found">
      <div id="page-404">
        <div className="status">404</div>
        <div className="description">Page not found</div>
        <div>
          <Link to="/">
            <button className="hs-button primary">Go to home page</button>
          </Link>
        </div>
      </div>
    </PageTemplate>
  );
}

export default Page404;
