import React from "react";

import { Chart, Line, Bar, Doughnut } from "react-chartjs-2";

import "./AtlasCharts.scss";

interface IAtlasLineChartProps {
  labels: string[];
  linesData: { name: string; color: string; data: number[] }[];
  showLegend: boolean;
}

const AtlasLineChart = ({
  labels,
  linesData,
  showLegend,
}: IAtlasLineChartProps) => {
  const options = {
    responsive: true,
    // This combines with the fixed with of enclosing container and ensure the chart only takes the set vertical space.
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: "#1C3A4A",
          lineWidth: 2,
          drawBorder: false,
          borderDash: [10, 5],
        },
        ticks: {
          color: "#8D9CA6",
          padding: 10,
          font: {
            family: "Tomorrow",
            size: 16,
          },
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#8D9CA6",
          padding: 20,
          font: {
            family: "Tomorrow",
            size: 16,
          },
        },
        //suggestedMin: 0,
        //suggestedMax: suggestedMax,
      },
    },
    plugins: {
      legend: {
        display: showLegend,
        position: "top" as const,
        align: "end" as const,
        labels: {
          font: {
            family: "Tomorrow",
            size: 12,
          },
          color: "white",
          pointStyle: "circle" as const,
          usePointStyle: true,
          padding: 20,
        },
      },
    },
  };

  return (
    <div style={{ height: "500px" }}>
      <Line
        datasetIdKey="id"
        data={{
          labels: labels,
          datasets: linesData.map((ld) => ({
            label: ld.name,
            data: ld.data,
            borderColor: ld.color,
            pointBackgroundColor: ld.color,
            borderWidth: 5,
          })),
        }}
        options={options}
      />
    </div>
  );
};

interface IAtlasHeatMapChartProps {
  labels: string[];
  datasets: { name: string; color: string; data: number[] }[];
  showLegend: boolean;
}
const AtlasHeatMapChart = ({
  labels,
  datasets,
  showLegend,
}: IAtlasHeatMapChartProps) => {
  const options = {
    responsive: true,
    // This combines with the fixed with of enclosing container and ensure the chart only takes the set vertical space.
    maintainAspectRatio: false,
    interaction: {
      mode: "nearest" as const,
      axis: "x" as const,
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          color: "#1C3A4A",
          lineWidth: 2,
          drawBorder: false,
          borderDash: [10, 5],
        },
        ticks: {
          color: "#8D9CA6",
          padding: 10,
          font: {
            family: "Tomorrow",
            size: 16,
          },
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: "#8D9CA6",
          padding: 20,
          font: {
            family: "Tomorrow",
            size: 16,
          },
        },
        min: 0,
        max: 100,
      },
    },
    plugins: {
      tooltip: {
        mode: "index" as const,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label;
            const dataValue = context.dataset.data[context.dataIndex];
            let value = "";
            if (typeof dataValue === "number") {
              value = dataValue.toFixed(2);
            }
            return `${label}: ${value} %`;
          },
        },
      },
      legend: {
        display: showLegend,
        position: "bottom" as const,
        align: "center" as const,
        labels: {
          font: {
            family: "Tomorrow",
            size: 12,
          },
          color: "white",
          pointStyle: "circle" as const,
          usePointStyle: true,
          padding: 20,
        },
      },
    },
  };

  return (
    <div style={{ height: "700px" }}>
      <Line
        datasetIdKey="id"
        data={{
          labels: labels,
          datasets: datasets.map((ld) => ({
            label: ld.name,
            data: ld.data,
            borderColor: ld.color,
            backgroundColor: ld.color,
            pointBackgroundColor: ld.color,
            fill: true,
            pointRadius: 0,
          })),
        }}
        options={options}
      />
    </div>
  );
};

interface IAtlasBarChartProps {
  labels: string[];
  datasets: { name: string; color: string; data: number[] }[];
  lines?: { name: string; color: string; data: number[] }[];
  showLegend: boolean;
}
const AtlasBarChart = ({
  labels,
  datasets,
  lines,
  showLegend,
}: IAtlasBarChartProps) => {
  const options = {
    responsive: true,
    // This combines with the fixed with of enclosing container and ensure the chart only takes the set vertical space.
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: "#1C3A4A",
          lineWidth: 2,
          drawBorder: false,
          borderDash: [10, 5],
        },
        ticks: {
          color: "#8D9CA6",
          padding: 10,
          font: {
            family: "Tomorrow",
            size: 16,
          },
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#8D9CA6",
          padding: 20,
          font: {
            family: "Tomorrow",
            size: 16,
          },
          callback: function (value, index, ticks) {
            return value + "%";
          },
        },
        //suggestedMin: 0,
        //suggestedMax: suggestedMax,
      },
    },
    plugins: {
      legend: {
        display: showLegend,
        position: "top" as const,
        align: "end" as const,
        labels: {
          font: {
            family: "Tomorrow",
            size: 12,
          },
          color: "white",
          pointStyle: "circle" as const,
          usePointStyle: true,
          padding: 20,
        },
      },
    },
  };
  if (labels.length > 7) {
    options.scales.x.ticks.maxRotation = 45;
    options.scales.x.ticks.minRotation = 45;
  }

  return (
    <div style={{ height: "700px" }}>
      <Chart
        type="bar"
        datasetIdKey="id"
        data={{
          labels: labels,
          datasets: datasets
            .map<any>((d) => ({
              label: d.name,
              data: d.data,
              borderColor: d.color,
              backgroundColor: d.color,
              pointBackgroundColor: d.color,
              borderWidth: 0,
              borderRadius: 5,
              type: "bar",
              order: 1,
            }))
            .concat(
              lines
                ? lines.map((d) => ({
                    label: d.name,
                    data: d.data,
                    borderColor: d.color,
                    backgroundColor: d.color,
                    pointBackgroundColor: d.color,
                    borderWidth: 2,
                    borderRadius: 5,
                    pointRadius: 3,
                    type: "line",
                    order: 0,
                  }))
                : []
            ),
        }}
        options={options}
      />
    </div>
  );
};

interface IAtlasValueCountChartProps {
  data: AtlasMach.IBarData;
}

const AtlasValueCountChart = ({ data }: IAtlasValueCountChartProps) => {
  const options = {
    responsive: true,

    // This combines with the fixed with of enclosing container and ensure the chart only takes the set vertical space.
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: "#1C3A4A",
          lineWidth: 2,
          drawBorder: false,
          borderDash: [10, 5],
        },
        ticks: {
          color: "#8D9CA6",
          padding: 10,
          font: {
            family: "Tomorrow",
            size: 16,
          },
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false,
          precision: 0,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#8D9CA6",
          padding: 20,
          font: {
            family: "Tomorrow",
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div style={{ height: "400px", minWidth: "500px", maxWidth: "800px" }}>
      <Chart type="bar" data={data} options={{ indexAxis: "y", ...options }} />
    </div>
  );
};

interface IAtlasHorizontalBarChartProps {
  data: AtlasMach.IBarDataMatrix;
  handleSelectAccountTheme;
  isMention: boolean;
}

const AtlasHorinzontalBarChart = ({
  data,
  handleSelectAccountTheme,
  isMention,
}: IAtlasHorizontalBarChartProps) => {
  // handleSelectAccount // Won't do for now

  const options = {
    responsive: true,
    // This combines with the fixed with of enclosing container and ensure the chart only takes the set vertical space.
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: "#1C3A4A",
          lineWidth: 2,
          drawBorder: false,
          borderDash: [10, 5],
        },
        max: 100,
        ticks: {
          color: "#8D9CA6",
          padding: 10,
          font: {
            family: "Tomorrow",
            size: 16,
          },
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false,
          precision: 0,
          beginAtZero: true,
          callback: function (value, index, ticks) {
            return value + "%";
          },
        },
        stacked: true,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#8D9CA6",
          padding: 20,
          font: {
            family: "Tomorrow",
            size: 16,
          },
          onClick: function (evt, item) {
            console.log("legend onClick", evt, item);
          },
        },
        stacked: true,
      },
    },
    onClick: function (e, activeElements) {
      if (activeElements.length) {
        handleSelectAccountTheme(
          data.accountIds[activeElements[0].index],
          {
            color:
              data.datasets[activeElements[0].datasetIndex].backgroundColor,
            label: data.datasets[activeElements[0].datasetIndex].label,
            value:
              data.datasets[activeElements[0].datasetIndex].data[
                activeElements[0].index
              ],
            id: data.datasets[activeElements[0].datasetIndex].labelId,
          },
          isMention,
          { x: e.native.clientX, y: e.native.clientY }
        );
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          pointStyle: "circle",
          usePointStyle: true, // The point size is calculated based on the font-size. We cannot reduce it without reducing the font size.
          color: "#8D9CA6",
          padding: 30,
          font: {
            family: "Tomorrow",
            size: 12,
          },
        },
        position: "bottom" as const,
      },
    },
  };
  return (
    <div
      style={{
        height: 58 * data.datasets[0].data.length + 100,
        minWidth: "500px",
        width: "100%",
      }}
    >
      {/** TODO: we assume at least one dataset */}
      <Chart type="bar" data={data} options={{ indexAxis: "y", ...options }} />
    </div>
  );
};

export {
  AtlasLineChart,
  AtlasHeatMapChart,
  AtlasBarChart,
  AtlasValueCountChart,
  AtlasHorinzontalBarChart,
};
