// Propose a question for a theme, given a global list of known treatments/drugs and companies (pharma0.
const inspireMe = (
  disease: string,
  theme: string,
  treatments: string[],
  companies: string[]
) => {
  const treatment = treatments[Math.floor(Math.random() * treatments.length)];
  const company = companies[Math.floor(Math.random() * companies.length)];

  let questions = {
    "Motivation Drivers": [
      "What draws you to the topic of health? e.g., why have you chosen to be on social in the space?",
      "Do you consider yourself an “influencer”? What does that entail for you? What expectations do others have of you?",
      "Do you like introducing new brands and products/treatments to your followers?",
      "Did you start your account with the intention of being a patient advocate or influencer? If so, why?",
      "Would you say that you are a patient advocate? If yes, can you describe what being a patient advocate means to you?",
      "Do you like helping people by providing them with information on many kinds of products?",
      "How would you describe the health needs of your followers?",
      "Why is social media a good platform to respond to the health needs of your followers? What are the limitations of social media?",
      "Are there certain things or certain topics of posts that are your favorite to post? Why?",
      "Are there certain things or certain topics of posts that you prefer not to post? Why?",
      "What role does empathy and compassion play in your work as a patient influencer? Can you give an example?",
      "Have you been thanked for what you have posted? Or has someone has been angry with what you have posted? Why? And how did you handled it? Have you ever severed ties or had to block followers and why?",
      "Do people ask you for information on drug brands, products or treatments? How do you handle those requests?",
      "Do any of your posts target specific types of people? (women, moms, younger people, ethnic background, etc.?). Do you check their analytics? What are the most important information that you get from their analytics? How do your partners “evaluate” their relationships? Are they supposed to achieve a certain number of engagements? Followers?",
      "How would you characterize your relationship with your followers? Do you consider your followers friends?",
    ],
    Affinity: [
      "Have you ever declined to partner with a health brand or organization? Why? Or Why not?",
      "How do you feel about having to make these decisions (which brands to work with)? Which of these decisions are ethical in nature? Can you explain?",
      "Do you rely on anyone when making these decisions (which brands to work with)? Who? How and why? How do your followers factor into these decisions? What's the nature of your relationship with followers?",
      "What choices do your followers make because of your content? What do your followers do with the information you post? Are these good choices? Can you explain? What are the most common reactions or comments to your post? ",
      "What are your thoughts on representing pharmaceutical drugs and the power dynamics of this relationship? How do your followers factor into this power dynamic?",
    ],
    Affiliation: [
      "Do you usually create the content you share or is content provided to you? How do you decide what content to share?",
      "What are some of the most common decisions you make in your job as an influencer? Does this include choosing what topic to post on? Does this include what images to post or what captions to write?",
      "What brands do you work with? How did those relationships come about? What health partners do you work with?",
      "Do you think you were sought (for brand partnerships) out based on your demographics? Age? Gender? Race/ethnicity? Part of the country you're from?",
      "In working with partners do you ever consider the cost of the product or the activity associated with the partner? Why? Or Why not?",
    ],
  };

  if (theme in questions) {
    let possibleQuestions = questions[theme];
    return possibleQuestions[
      Math.floor(Math.random() * possibleQuestions.length)
    ];
  }
  return null;
};

const inspireMeThemes = ["Motivation Drivers", "Affinity", "Affiliation"];

export { inspireMe, inspireMeThemes };
