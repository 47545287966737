import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

import {
  POLLVERSE_VOICES,
  POLLVERSE_VOICES_COMPARE,
  POLLVERSE_COMPARE,
  POLLVERSE_NETWORK_MAP,
  POLLVERSE_HEAT_MAP,
} from "constants/routes";

import { config } from "../../../config.js";

import PageTemplate from "pages/PageTemplate";

// Top-level modules
import { ReactComponent as KolDolIcon } from "assets/icons/kol_dol.svg";
import { ReactComponent as VoicesIcon } from "assets/icons/voices.svg";

// Sub-modules
import { ReactComponent as NetworkMapIcon } from "assets/icons/network_map.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/people.svg";
import { ReactComponent as HeatIcon } from "assets/icons/heat.svg";
import { ReactComponent as MapIcon } from "assets/icons/map.svg";
import { ReactComponent as DebateSetIcon } from "assets/icons/debate-set.svg";
import { ReactComponent as DebateIcon } from "assets/icons/debate.svg";

// import "./styles.scss";
const ICON_COLOR = "#78D2F1";
const ICON_COLOR_DISABLED = "#8D9CA6";

interface IMenuPageProps {
  topics: AtlasMach.ITopic[];
  topic: AtlasMach.ITopic;
  setSources: (sources: AtlasMach.ISource[]) => void;
  setAudiences: (sources: AtlasMach.IAudience[]) => void;
  setThemes: (sources: AtlasMach.ITheme[]) => void;
  updateTopic: (topic: AtlasMach.ITopic) => void;
  updateFilters: (filters: AtlasMach.UIFilters | undefined) => void;
}

function MenuPage({
  topics,
  topic,
  updateTopic,
  updateFilters,
  setSources,
  setAudiences,
  setThemes,
}: IMenuPageProps) {
  const topicsOptions = topics.map((t) => ({
    value: t.id,
    label: t.name,
    payload: t,
  }));

  return (
    <PageTemplate title={config.POLLVERSE.BRANDING.TITLE}>
      <div className="hs-menu-container">
        <div className="hs-menu-header"> </div>
        <div className="hs-menu-modules">
          <div className="hs-menu-module">
            <VoicesIcon width="25" height="25" />
            <h2>
              <Link to={POLLVERSE_VOICES}>Voices</Link>
            </h2>
            <div className="hs-menu-module-hr">
              <hr />
              <span className="circle" />
            </div>
            <div className="hs-menu-submodules">
              <div className="hs-menu-submodule">
                <PeopleIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={POLLVERSE_VOICES}>Legislators</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Interview legislators assisted by AI
                </div>
              </div>
              {/*
              <div className="hs-menu-submodule disabled">
                <PeopleIcon fill={ICON_COLOR_DISABLED} />
                <h4>HCPs - coming soon</h4>
                <div className="hs-menu-submodule-description">Interview HCPs assisted by AI</div>
              </div>
              */}
              {/* <div className="hs-menu-submodule">
                <PeopleIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_VOICES_HCPS}>HCPs</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Interview HCPs assisted by AI
                </div>
              </div>
              <div className="hs-menu-submodule">
                <PeopleIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_VOICES_STARRED_ACCOUNTS}>
                    Starred Accounts (Beta)
                  </Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Interview selected individual accounts
                </div>
              </div> */}
            </div>
          </div>
          <div className="hs-menu-module">
            <DebateIcon width="25" height="25" />
            <h2>
              <Link to={POLLVERSE_COMPARE}>Debate Showdown</Link>
            </h2>
            <div className="hs-menu-module-hr">
              <hr />
              <span className="circle" />
            </div>
            <div className="hs-menu-submodules">
              <div className="hs-menu-submodule">
                <DebateSetIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={POLLVERSE_COMPARE}>Legislators</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Staged Debates assisted by AI
                </div>
              </div>
              {/* <div className="hs-menu-submodule">
                <ContentIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_LISTENING_CONTENT_FEED}>Content Feed</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Browse most relevant social posts
                </div>
              </div>
              <div className="hs-menu-submodule">
                <ContentFitGapIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_LISTENING_THEMES_FIT_GAP}>Themes Fit Gap</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Compare themes across audiences
                </div>
              </div>
              <div className="hs-menu-submodule">
                <BenchmarkingIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_LISTENING_BENCHMARKING}>Benchmarking</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Create and compare data sets
                </div>
              </div> */}
            </div>
          </div>
          <div className="hs-menu-module">
            <HeatIcon width="25" height="25" fill="#FFF" />
            <h2>
              <Link to={POLLVERSE_HEAT_MAP}>Heatmap</Link>
            </h2>
            <div className="hs-menu-module-hr">
              <hr />
              <span className="circle" />
            </div>
            <div className="hs-menu-submodules">
              <div className="hs-menu-submodule">
                <MapIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={POLLVERSE_HEAT_MAP}>US Map</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Snapshot of Legislators' positions
                </div>
              </div>
              {/* <div className="hs-menu-submodule disabled">
                <ContentEnhancerIcon fill={ICON_COLOR_DISABLED} />
                <h4>Content Enhancer</h4>
                <div className="hs-menu-submodule-description">Coming soon</div>
              </div>
              <div className="hs-menu-submodule disabled">
                <AgencyBriefIcon fill={ICON_COLOR_DISABLED} />
                <h4>Agency Brief</h4>
                <div className="hs-menu-submodule-description">Coming soon</div>
              </div> */}
            </div>
          </div>
          <div className="hs-menu-module">
            <KolDolIcon width="25" height="25" />
            <h2>
              <Link to={POLLVERSE_NETWORK_MAP}>Influence Maps</Link>
            </h2>
            <div className="hs-menu-module-hr">
              <hr />
              <span className="circle" />
            </div>
            <div className="hs-menu-submodules">
              <div className="hs-menu-submodule">
                <NetworkMapIcon fill={ICON_COLOR} width="25" height="25" />
                <h4>
                  <Link to={POLLVERSE_NETWORK_MAP}>Network Map</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Discover the micro-universes of influence
                </div>
              </div>
              {/* <div className="hs-menu-submodule">
                <PathwaysIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_DOL_PATHWAYS}>Pathways</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Prioritize DOLs engagement
                </div>
              </div>
              <div className="hs-menu-submodule">
                <GridIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_DOL_CONVERSATIONAL_MATRIX}>
                    Conversational Matrix
                  </Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Personalize content themes
                </div>
              </div>
              <div className="hs-menu-submodule">
                <CentricityIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_DOL_CENTRICITY_REPORT}>Centricity Report</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Track and monitor influence
                </div>
              </div>
              <div className="hs-menu-submodule">
                <StarIcon fill={ICON_COLOR} />
                <h4>
                  <Link to={HS_DOL_STARRED_ACCOUNTS}>Starred Accounts</Link>
                </h4>
                <div className="hs-menu-submodule-description">
                  Dive deep into selected accounts
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default MenuPage;
