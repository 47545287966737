import ChuckFleischmannProfile from "assets/pictures/Chuck_Fleischmann.jpg";
import MarkGreenProfile from "assets/pictures/Mark_Green.jpg";
import TimBurchettProfile from "assets/pictures/Tim_Burchett.jpg";
import ScottDesJarlaisProfile from "assets/pictures/Scott_DesJarlais.jpg";
import SteveCohenProfile from "assets/pictures/Steve_Cohen.jpg";
import AndyOglesProfile from "assets/pictures/Andy_Ogles.jpg";
import DianaHarshbargerProfile from "assets/pictures/Diana_Harshbarger.jpeg";
import JohnRoseProfile from "assets/pictures/John_Rose.jpg";
import DavidKustoffProfile from "assets/pictures/David_Kustoff.jpg";

import DianaDeGetteProfile from "assets/pictures/Diana_DeGette.jpg";
import JoeNeguseProfile from "assets/pictures/Joe_Neguse.jpeg";
import LaurenBoebertProfile from "assets/pictures/Lauren_Boebert.jpg";
import KenBuckProfile from "assets/pictures/Ken_Buck.jpg";
import DougLambornProfile from "assets/pictures/Doug_Lamborn.jpg";
import JasonCrowProfile from "assets/pictures/Jason_Crow.jpg";
import JahanaHayesProfile from "assets/pictures/Jahana_Hayes.jpg";
import JimHimesProfile from "assets/pictures/Jim_Himes.jpg";
import RosaDeLauroProfile from "assets/pictures/Rosa_DeLauro.jpg";
import RichardBlumenthalProfile from "assets/pictures/Richard_Blumenthal.jpg";
import ChrisMurphyProfile from "assets/pictures/Chris_Murphy.jpg";
import JohnLarsonProfile from "assets/pictures/John_Larson.jpg";

import MarshaBlackburnProfile from "assets/pictures/Marsha_Blackburn.jpeg";
import BillHagertyProfile from "assets/pictures/Bill_Hagerty.jpg";

import VotersProfile from "assets/pictures/voters.png";

const politicians = [
  // Senators
  {
    name: "Bill Hagerty",
    formalName: "Bill Hagerty (R-TN-Sen)",
    picture: BillHagertyProfile,
    party: "republican",
  },
  {
    name: "Marsha Blackburn",
    formalName: "Marsha Blackburn (R-TEN-Sen)",
    picture: MarshaBlackburnProfile,
    party: "republican",
  },
  // Congressmens
  {
    name: "Steve Cohen",
    formalName: "Steve Cohen (D-TN-Distr. 9)",
    picture: SteveCohenProfile,
    party: "democrat",
  },
  {
    name: "Diana Harshbarger",
    formalName: "Diana Harshbarger (R-TN-Distr. 1)",
    picture: DianaHarshbargerProfile,
    party: "republican",
  },
  {
    name: "Tim Burchett",
    formalName: "Tim Burchett (R-TN-Distr.2)",
    picture: TimBurchettProfile,
    party: "republican",
  },
  {
    name: "Chuck Fleischmann",
    // Correct name is Charles J., but it's too long for our layout.
    //formalName: "Charles J. Fleischmann (R-TN-Distr. 3)",
    formalName: "Chuck Fleischmann (R-TN-Distr. 3)",
    picture: ChuckFleischmannProfile,
    party: "republican",
  },
  {
    name: "Scott DesJarlais",
    formalName: "Scott DesJarlais (R-TN-Distr. 4)",
    picture: ScottDesJarlaisProfile,
    party: "republican",
  },
  {
    name: "Andy Ogles",
    formalName: "Andy Ogles (R-TN-Distr. 5)",
    picture: AndyOglesProfile,
    party: "republican",
  },
  {
    name: "John Rose",
    formalName: "John Rose (R-TN-Distr. 6)",
    picture: JohnRoseProfile,
    party: "republican",
  },
  {
    name: "Mark Green",
    formalName: "Mark Green (R-TN-Distr. 7)",
    picture: MarkGreenProfile,
    party: "republican",
  },
  {
    name: "David Kustoff",
    formalName: "David Kustoff (R-TN-Distr. 8)",
    picture: DavidKustoffProfile,
    party: "republican",
  },
  // The voters
  {
    name: "Voters",
    formalName: "Voters of Tennessee",
    picture: VotersProfile,
    party: "republican",
  },
  {
    name: "Diana DeGette",
    formalName: "Diana DeGette",
    picture: DianaDeGetteProfile,
    party: "democrat",
  },
  {
    name: "Jason Crow",
    formalName: "Jason Crow",
    picture: JasonCrowProfile,
    party: "democrat",
  },
  {
    name: "Doug Lamborn",
    formalName: "Doug Lamborn",
    picture: DougLambornProfile,
    party: "republican",
  },
  {
    name: "Joe Neguse",
    formalName: "Joe Neguse",
    picture: JoeNeguseProfile,
    party: "democrat",
  },
  {
    name: "Ken Buck",
    formalName: "Ken Buck",
    picture: KenBuckProfile,
    party: "republican",
  },
  {
    name: "Lauren Boebert",
    formalName: "Lauren Boebert",
    picture: LaurenBoebertProfile,
    party: "republican",
  },
  {
    name: "Jahana Hayes",
    formalName: "Jahana Hayes",
    picture: JahanaHayesProfile,
    party: "democrat",
  },
  {
    name: "Jim Himes",
    formalName: "Jim Himes",
    picture: JimHimesProfile,
    party: "democrat",
  },
  {
    name: "Rosa DeLauro",
    formalName: "Rosa DeLauro",
    picture: RosaDeLauroProfile,
    party: "democrat",
  },
  {
    name: "Richard Blumenthal",
    formalName: "Richard Blumenthal",
    picture: RichardBlumenthalProfile,
    party: "democrat",
  },
  {
    name: "Chris Murphy",
    formalName: "Chris Murphy",
    picture: ChrisMurphyProfile,
    party: "democrat",
  },
  {
    name: "John Larson",
    formalName: "John Larson",
    picture: JohnLarsonProfile,
    party: "democrat",
  },
];

const interviewsDb = {
  "Marsha Blackburn": {
    Abortion: {
      questions: [
        {
          answer:
            "I believe that every life is precious and should be protected. I support restrictions on abortion that protect the health and safety of women and their unborn children. I have voted against legislation that would open the pathway for abortion on demand and legalize late term abortions. I have also voted to protect informed consent laws and prevent restrictions on gruesome fetal dismemberment procedures. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1524493072268251137",
              text: "This bill would force states to legalize late-term abortions, remove informed consent laws, and prevent restrictions on\u00a0gruesome fetal dismemberment\u00a0procedures.\u00a0Today,\u00a0I stood up\u00a0to the woke mob and\u00a0voted to protect women and their unborn\u00a0children.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1498453529127788553",
              text: "The Women\u2019s Health Protection Act is an attack against the health of women & unborn children. Today, I voted against this legislation which would open the pathway for abortion on demand & legalize late term abortions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1524555070741827593",
              text: "Today, I voted no on the radical left\u2019s plan to make every state a late-term abortion state, allow dismemberment and telemedicine abortions, and remove informed consent. https://t.co/ZaPZNROXTr",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540344274683936770",
              text: "Having worked alongside Tennesseans to protect the innocent lives of unborn children for years, I applaud today\u2019s Supreme Court ruling. Despite false claims from the left, this decision will not ban abortion.",
            },
          ],
          question:
            "Where do you stand on the legality of abortion, and what restrictions, if any, do you support?",
        },
        {
          answer:
            "Absolutely. I am especially looking forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. Additionally, I plan to champion trade policies that advance competition in the United States. I am also introducing bipartisan legislation as part of our Rural Healthcare Agenda to ensure access to healthcare. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1637099032395886592",
              text: "Access to healthcare is critical, which is why I'm working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda.\n\nwdef.com/blackburn-tout\u2026",
            },
          ],
          question:
            "Are there healthcare policies you support to provide alternatives to abortion?",
        },
      ],
      summary:
        "Marsha Blackburn stands firmly against the legality of abortion and has consistently voted against legislation she sees as expanding access to it, including that which would allow late-term abortions. She strongly supports restrictions on abortion to protect the well-being and safety of women and their unborn children. To provide alternatives to abortion, she is interested in increasing healthcare access and reducing patient costs through collaboration with healthcare innovators and introducing legislation to advance rural healthcare access.",
    },
    "Civil Liberties": {
      questions: [
        {
          answer:
            "I am committed to protecting the civil liberties of all Americans, including freedom of speech and privacy. I have introduced legislation to assess the extent to which sister city agreements with cities in Communist China could be a threat to national security, and I have worked with Senator Menendez to pass the Open Technology Fund Authorization Act, which provides access to the internet for 737,000 users in Russia, Belarus, and Ukraine. I have also introduced the Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs. I am also working to ensure that Big Tech is held accountable for their decisions, including the hosting of CSAM, violent content, and the removal of conservative content. We must also protect our citizens from government overreach into private family decisions and ensure that the federal government does not threaten the First Amendment by policing free speech. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1505984268358594563",
              text: "The American people want a Supreme Court justice who will protect their families\u2019 freedoms, not be a rubber stamp for government overreach into private family decisions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1592717206823276544",
              text: "Title 42 preserves our nation\u2019s sovereignty. \n\nOur country has every right to protect that sovereignty, and we must support policies that put our national security first.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1505596321687904262",
              text: "During the hearing, I plan to press Judge Jackson on her writings, record and rulings to ensure that we nominate a constitutionalist to the Supreme Court.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1577037525679411201",
              text: "For too long, Big Tech has used Section 230 as a shield to avoid accountability for its decisions, including the hosting of CSAM, violent content, and the removal of conservative content.\n\nI\u2019m looking forward to hearing what the Supreme Court has to say on this issue.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1562621370046955520",
              text: "Good riddance once and for all to the DHS \u201cdisinformation board.\u201d\n \nThe federal government has no business threatening the First Amendment by policing your free speech.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1634036941845938176",
              text: "Democrats and the radical left favor censorship and a lack of transparency.\n\nWe can\u2019t just stand by and let them get away with it.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1519339627257208832",
              text: "Facebook has lost control of what they are doing with your data. This is reckless and threatens the privacy and security of Americans. We need a national privacy standard. vice.com/en/article/akv\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570398418157142016",
              text: "Facebook was reportedly spying on users' private messages and reporting them to the FBI if they dared to question the government.\n\nWe cannot trust big government or Big Tech. nypost.com/2022/09/14/fac\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1506089445245833224",
              text: "I\u2019m not afraid to ask tough questions to a nominee for the highest court in our nation. American families\u2019 way of life depends on having a constitutionalist court, but the media and the left will attack anyone who dares to ask important questions.",
            },
          ],
          question:
            "What safeguards will you put in place to protect individual civil liberties, including freedom of speech and privacy?",
        },
        {
          answer:
            "We need to take action to protect Americans' privacy and security. We need a national privacy standard to ensure that companies like Facebook are not recklessly mishandling our data. We also need to keep Big Tech in check and ensure that they are held accountable for their decisions. I'm looking forward to hearing what the Supreme Court has to say on this issue. Additionally, I'm working on legislation to provide children and parents with tools, safeguards, and transparency to protect against online threats. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1519339627257208832",
              text: "Facebook has lost control of what they are doing with your data. This is reckless and threatens the privacy and security of Americans. We need a national privacy standard. vice.com/en/article/akv\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570398418157142016",
              text: "Facebook was reportedly spying on users' private messages and reporting them to the FBI if they dared to question the government.\n\nWe cannot trust big government or Big Tech. nypost.com/2022/09/14/fac\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1647981090483699712",
              text: "Big Tech has too much power and control over what you see, hear, think, say, and how you vote.\u00a0\n\nWe need to keep them in check.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1577037525679411201",
              text: "For too long, Big Tech has used Section 230 as a shield to avoid accountability for its decisions, including the hosting of CSAM, violent content, and the removal of conservative content.\n\nI\u2019m looking forward to hearing what the Supreme Court has to say on this issue.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1603110135005843456",
              text: "My bipartisan Kids Online Safety Act provides children and parents with tools, safeguards, and transparency to protect against online threats to kids\u2019 health and well-being.\u00a0\n\u00a0\nIt\u2019s critical we get this done.",
            },
          ],
          question:
            "How do you plan to address concerns about government surveillance and data privacy?",
        },
        {
          answer:
            "Absolutely. I have introduced several pieces of legislation to protect civil liberties in the digital age. My Civilian Cyber Security Reserve Act, which passed the Senate unanimously, allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs. My bipartisan Kids Online Safety Act provides children and parents with tools, safeguards, and transparency to protect against online threats. I have also worked with Senator Menendez on the Open Technology Fund Authorization Act, which provides 737,000 users in Russia, Belarus, and Ukraine with access to the internet. Additionally, I have introduced the INFO Act, which promotes internet freedom and circumvents foreign restrictions on access. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1605683692902531072",
              text: "I am pleased that my Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs, unanimously passed the Senate last night. \n\nWe are now one step closer to this bill becoming law.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1603110135005843456",
              text: "My bipartisan Kids Online Safety Act provides children and parents with tools, safeguards, and transparency to protect against online threats to kids\u2019 health and well-being.\u00a0\n\u00a0\nIt\u2019s critical we get this done.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1503504920732999682",
              text: "As Russia escalates its censorship campaign by the day, 737,000 users in Russia, Belarus, and Ukraine are able to access the internet because of @PsiphonInc and other tools funded by my legislation with @SenatorMenendez, the Open Technology Fund Authorization Act.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1625544499375095825",
              text: ".@SenBlumenthal and I introduced the Kids Online Safety Act to provide kids and parents with the tools and transparency they need to stay safe.\n\u00a0\nOur bipartisan legislation requires social media companies to make their platforms safer by default. https://t.co/9KUEBLr5l1",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1611810747914256385",
              text: "Working with @SenatorMenendez, the INFO Act was included in the NDAA signed by the President. \n\nThis bipartisan bill promotes internet freedom and circumvents foreign restrictions on access to the internet.",
            },
          ],
          question:
            "Are there initiatives to strengthen civil liberties protections in the digital age that you support?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to protecting the civil liberties of all Americans, including freedom of speech and privacy. She has introduced several legislations to ensure these protections, including acts to safeguard national security related to cyber attacks, allowing access to the internet for foreign users, and providing online safety for children.\n\nMoreover, she is keen on establishing a national privacy standard to manage how big tech companies handle user data. This includes holding these companies accountable for their decisions, and making sure children and parents have the necessary tools and transparency to protect against online threats. Blackburn also believes in limiting government overreach and protecting First Amendment rights.\n\nAdditionally, she intends to strengthen civil liberties in the digital age through initiatives like the Civilian Cyber Security Reserve Act, the Kids Online Safety Act, the Open Technology Fund Authorization Act, and the INFO Act, which aims at promoting internet freedom and overcoming access restrictions abroad. She is continually striving to keep Big Tech accountable and ensure internet accessibility for all.",
    },
    "Civil Rights": {
      questions: [
        {
          answer:
            "I am committed to protecting the rights of all Americans, including those in the LGBTQ+ community. I have supported legislation that would protect LGBTQ+ individuals from discrimination in the workplace, housing, and public accommodations. I have also supported legislation that would ensure LGBTQ+ individuals have access to healthcare and other services. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1550525304329887744",
              text: "The Protecting Access to Lifesaving Screenings Act is critical in fighting breast cancer. I\u2019m glad to support such an important piece of legislation with the help of @NancyGBrinker.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1590374348401311749",
              text: "I am fighting to ensure the National Defense Authorization Act protects our servicemembers from being fired due to Biden\u2019s vaccine mandate.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1608165502630744064",
              text: "As 2022 comes to a close, I\u2019m reflecting on a productive year: \n\n\u2714\ufe0f Repealed military COVID vaccine mandate\n\u2714\ufe0f Strengthened small battery safety standards\n\u2714\ufe0f Worked to keep kids safe online \n\u2714\ufe0f Prohibited NDAs from silencing sexual assault survivors in the workplace https://t.co/CBfy36LJa2",
            },
          ],
          question:
            "What actions would you take to advance LGBTQ+ rights and protections against discrimination?",
        },
        {
          answer: null,
          posts: [],
          question:
            "How can you promote racial and gender equality in various sectors, including employment and housing?",
        },
        {
          answer:
            "I am fighting to protect voting rights and access to the ballot by opposing the Democrats' attempts to federalize elections and strip away protections for the ballot box. I am also demanding the full Senate vote on my amendments to protect servicemembers from being fired due to Biden\u2019s vaccine mandate. I am also fighting for voter ID laws to ensure the integrity of our elections. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1483982827075088384",
              text: "Biden and Senate Democrats want to pass a bill that would federalize elections, strip away protections for the ballot box, and inject uncertainty into the voting process \u00e2\u20ac\u201d and they\u00e2\u20ac\u2122re willing to blow up Senate rules to do so. https://t.co/hRB0LAo3IH",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1483494803941830659",
              text: "Don\u00e2\u20ac\u2122t let the left fool you. \u00e2\u20ac\u0153Voting rights\u00e2\u20ac\u009d is code for banning voter ID, making it easier to cheat, and putting the federal government in charge of elections.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1597711704108208128",
              text: "I am demanding the full Senate vote on my amendments to protect servicemembers from being fired due to Biden\u2019s vaccine mandate.\n\nAll options are on the table to protect our troops.",
            },
          ],
          question:
            "What policies do you support to protect voting rights and access to the ballot?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to safeguarding the rights of all American citizens, including the LGBTQ+ community. She has supported legislation aimed at preventing discrimination against LGBTQ+ individuals in the workplace, housing, and public spaces, and also endorsed measures ensuring access to healthcare and other essential services for this group. On the issue of voting rights, Blackburn has contested attempts to federalize elections and remove protections for the ballot box. She advocates for voter ID laws and has called for a full Senate vote on her amendments related to military personnel and vaccine mandates. However, there is no specific available stance regarding her efforts to promote racial and gender equality across sectors such as employment and housing.",
    },
    Crime: {
      questions: [
        {
          answer:
            "We must reform our criminal justice system to ensure that justice is served and that our communities are safe. We must end soft-on-crime policies that allow criminals to be released back onto the streets and instead focus on providing resources for evidence processing and crime labs. We must also ensure that prosecutors are held accountable and that criminals are held responsible for their actions. We must also provide more resources and funding for law enforcement agencies to target violent criminals and keep drugs, gangs, and violent criminals out of our communities. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1586027211861069825",
              text: "A Chicago man was released early from prison and caught committing the same crime within the same week.\n\nLeft-wing policies like 50% sentence reductions are endangering your communities.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570801941730709505",
              text: "We must improve the rape kit process to put rapists behind bars and get criminals off the streets.\n\nThat\u2019s why I\u2019ve introduced the Restoring Law and Order Act to increase resources for evidence processing and crime labs.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1633624258306887682",
              text: "No citizen should be forced to live in fear of violent criminals, whether in DC, Memphis, or Knoxville.\n\nWe must keep drugs, gangs, and violent criminals out of our communities. We need prosecutors to keep criminals behind bars and keep our communities safe.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570764718851366912",
              text: "The Defund the Police movement is demanding compassion for criminals, not for victims.\n\n@SenatorHagerty and I have introduced legislation to provide more resources and funding for law enforcement agencies to target violent criminals.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1568027369973370881",
              text: "Weak-on-crime prosecutors and lenient sentences are making our communities more dangerous.\n\nIf you commit the crime, you must do the time.",
            },
          ],
          question:
            "How would you approach criminal justice reform to reduce mass incarceration and address racial disparities?",
        },
        {
          answer:
            "We must ensure that our communities are safe and secure by supporting our police and ending soft-on-crime policies. We need prosecutors to keep criminals behind bars and keep our communities safe. Additionally, I have introduced an amendment to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers. This week on Unmuted, we will discuss how the \u2018defund the police\u2019 rhetoric has increased crime across the nation. We must also fund, not defund, law enforcement and ensure that those who commit crimes do the time. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1585300239984254976",
              text: "Left-wing DAs had a responsibility to keep communities safe, but many across the country sold families out for a radical, pro-crime agenda.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1633624258306887682",
              text: "No citizen should be forced to live in fear of violent criminals, whether in DC, Memphis, or Knoxville.\n\nWe must keep drugs, gangs, and violent criminals out of our communities. We need prosecutors to keep criminals behind bars and keep our communities safe.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570204583242121224",
              text: "Americans want to feel safe again, and that starts with supporting our police and ending soft-on-crime policies.\n\nThank you @ChuckGrassley, @SenatorBraun, @SenJohnKennedy, and @SenRonJohnson for joining me and @SenatorHagerty to discuss how we help solve the violent crime crisis. pic.twitter.com/jejakD57OF",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540098687162007553",
              text: "I\u2019ve introduced an\u00a0amendment\u00a0to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1641457434098008065",
              text: "The SAFE School Act establishes a $900 million grant program that will allow both public and private schools to train and hire veterans and former law enforcement officers to serve as school safety officers.",
            },
          ],
          question:
            "What strategies will you employ to enhance community safety while promoting fairness and equity?",
        },
        {
          answer:
            "Yes, I champion initiatives that focus on addressing the root causes of crime. I have introduced the Restoring Law and Order Act to increase resources for evidence processing and crime labs, and I have cosponsored the REPORT Act to take much-needed steps to modernize anti-trafficking efforts. I have also sent a letter demanding Biden eliminate soft-on-crime policies and introduced legislation to provide more resources and funding for law enforcement agencies to target violent criminals. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1574941536210427904",
              text: "@joesantarlasci Hi Joe, in response to the violent crime spike in cities across America \u2014 including TN, I introduced the Restoring Law and Order Act to support our police and crack down on violent crime. I have also sent a letter demanding Biden eliminate soft-on-crime policies.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570801941730709505",
              text: "We must improve the rape kit process to put rapists behind bars and get criminals off the streets.\n\nThat\u2019s why I\u2019ve introduced the Restoring Law and Order Act to increase resources for evidence processing and crime labs.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570204583242121224",
              text: "Americans want to feel safe again, and that starts with supporting our police and ending soft-on-crime policies.\n\nThank you @ChuckGrassley, @SenatorBraun, @SenJohnKennedy, and @SenRonJohnson for joining me and @SenatorHagerty to discuss how we help solve the violent crime crisis. pic.twitter.com/jejakD57OF",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1630191661102899202",
              text: "I cosponsored legislation to reauthorize the Debbie Smith Act, which is critical in the fight to end the rape kit backlog and will keep dangerous criminals off the streets. \n\nfox17.com/news/local/nas\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1574825846535340033",
              text: "The left has made it clear they intend to set criminals free and defund the law enforcement that keep American families safe.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1586027211861069825",
              text: "A Chicago man was released early from prison and caught committing the same crime within the same week.\n\nLeft-wing policies like 50% sentence reductions are endangering your communities.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1631658939354939394",
              text: "The REPORT Act will take much-needed steps to modernize anti-trafficking efforts so that perpetrators may be fully prosecuted under the law. We must ensure the @MissingKids and law enforcement are equipped to adequately respond.\n\n@Ali_Lev @SenOssoff\n\nforbes.com/sites/alexandr\u2026",
            },
          ],
          question:
            "Are there specific initiatives for addressing the root causes of crime that you champion?",
        },
      ],
      summary:
        "Marsha Blackburn advocates for strict policies to curb crime rates, with emphasis on the importance of a robust and effective criminal justice system. She proposes reforms which are geared towards holding accountable both the criminals and the authorities responsible for delivering justice. Specifically, her strategies include increasing resources for evidence processing and crime labs, and working towards retaining the criminals behind bars.\n\nShe has shown opposition to 'soft-on-crime' policies, believing that these tend to release criminals back onto the streets, thereby escalating crime rates. Instead, she proposes that more resources and funding should be directed to law enforcement agencies so they can effectively target violent criminals, and keep drugs and gangs out of communities.\n\nRegarding enhancing community safety whilst ensuring fairness and equity, she upholds her support for law enforcement, and the discontinuation of 'defund the police' rhetoric. She has proposed the hiring of veterans and former law enforcement officers as school safety officers as part of her initiatives to ensure safety within schools.\n\nIn addressing root causes of crime, Senator Blackburn champions initiatives such as the Restoring Law and Order Act and the REPORT Act, which focus on increasing resources for law enforcement and modernizing anti-trafficking efforts respectively. She emphasizes ending 'soft-on-crime' policies as a crucial part of addressing the root causes of crime.",
    },
    Education: {
      questions: [
        {
          answer:
            "I am committed to improving the quality of public education and increasing access to higher education. I will work to ensure that our public schools are providing students with the best possible education and that they are equipping them with the skills and knowledge they need to succeed in college and beyond. Additionally, I will work to make higher education more affordable and accessible by advocating for policies that reduce the cost of tuition and increase access to financial aid. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1641457434098008065",
              text: "The SAFE School Act establishes a $900 million grant program that will allow both public and private schools to train and hire veterans and former law enforcement officers to serve as school safety officers.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1505993093673271316",
              text: "Moms and dads are troubled by the progressive agenda being pushed in some of our public-school systems. Educators are allowing biological males to steal opportunities from female athletes in the name of progressivism. https://t.co/pagIVPgZqx",
            },
          ],
          question:
            "How will you improve the quality of public education and increase access to higher education?",
        },
        {
          answer:
            "We must reduce our federal debt and stop spending money we do not have on programs we don't want or need. I\u2019ve proposed cutting federal spending across the board for non-defense items by 1%, 2%, and 5%. Joe Biden\u2019s student-loan socialism is a handout to the rich and we cannot continue to rack up this federal debt. We must cut spending and shrink the bureaucracy. We need to get our fiscal house in order so that our children and grandchildren can enjoy the same liberties that we do. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1623695828777934848",
              text: "I\u2019ve proposed cutting federal spending across the board for non-defense items by 1%, 2%, and 5%.\n\nWe cannot continue to rack up this federal debt; we are at $31 trillion and counting. https://t.co/d8xssvb0al",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1620478181450067968",
              text: "The Democrats\u2019 out-of-control spending will burden our kids and grandkids with our debt.\n\nFor freedom\u2019s sake, we must cut spending and shrink the bureaucracy.",
            },
          ],
          question:
            "What measures do you endorse to address the issue of student loan debt?",
        },
        {
          answer:
            "I prioritize policies that support teachers and improve educational outcomes. I am a strong supporter of the SAFE School Act, which provides funding for schools to hire veterans and former law enforcement officers to serve as school safety officers. Additionally, I am committed to ensuring that our public-school systems are not pushing a progressive agenda that takes away opportunities from female athletes. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1505993093673271316",
              text: "Moms and dads are troubled by the progressive agenda being pushed in some of our public-school systems. Educators are allowing biological males to steal opportunities from female athletes in the name of progressivism. https://t.co/pagIVPgZqx",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
          ],
          question:
            "Are there policies to support teachers and improve educational outcomes that you prioritize?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to improving the quality of public education and increasing access to higher education. She supports policies that ensure public schools equip students with necessary skills and knowledge for their future. Similarly, she advocates for reducing tuition costs and increasing access to financial aid to make higher education more affordable. However, she opposes any federal spending on programs that would further increase federal debt, including broad measures to forgive student loan debt. She also endorses policies that benefit teachers and improve educational outcomes, like the SAFE School Act, and opposes the pushing of a progressive agenda in public school systems.",
    },
    "Environmental and Climate Policy": {
      questions: [
        {
          answer:
            "I believe that we must take action to address climate change and promote sustainability. We must invest in clean energy sources, reduce emissions, and promote conservation. We must also ensure that our economy is resilient and that our citizens are able to save for their retirement. We must find a balance between these two goals. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1638279099364921359",
              text: "Joe Biden's veto tells 152 million Americans that funding climate change and ESG are more important to him than their retirement savings. https://t.co/VyXn7LWd7T",
            },
          ],
          question:
            "How will you address climate change and promote sustainability?",
        },
        {
          answer:
            "We need to focus on an all of the above energy policy that unleashes American energy, approves new drilling permits, holds lease sales, and approves new energy infrastructure. We should also look into ways to reduce our reliance on foreign oil and make America energy independent again. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1539612648106508290",
              text: "The solution to lower gas prices is to unleash American energy, approve new drilling permits, hold lease sales and approve new energy infrastructure.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1532443242545004556",
              text: "Mr. President, an immediate action you could take is to have a plan to make us energy independent again. \n\nYour Green New Deal is a root cause. wsj.com/articles/treas\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1577113065757192192",
              text: "OPEC is reportedly considering cutting oil production by more than 1 million barrels a day, which would likely cause gas prices to skyrocket again. \n\nWhy is the U.S. still relying on countries like Saudi Arabia and Mexico for oil? \n\nMake America energy independent again.",
            },
          ],
          question: "Strategies for reducing greenhouse gas emissions?",
        },
        {
          answer:
            "Joe Biden's policies for environmental protection and climate action have been misguided and have put the American people at a disadvantage. He has halted the Keystone pipeline, frozen new leases to produce American oil, rejoined the Paris Climate Agreement, and turned to authoritarian regimes for oil production. He has also proposed spending up to $300 billion in the name of climate change, advocated for 100% green energy as the solution for rising energy prices, and prioritized \u201cinclusion\u201d and \u201cenvironmental justice\u201d in his 2023 budget. These policies are not in line with the needs of the American people, as only 1% of Americans named climate change as the most important problem facing the U.S. according to a recent NYT poll. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1501641958812528642",
              text: "Biden\u2019s Energy agenda: \n\n\u274c Halted the Keystone pipeline\n\u274c Froze new leases to produce American oil\n\u274c Rejoined the Paris Climate Agreement\n\u274c Turned to authoritarian regimes for oil production",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1638279099364921359",
              text: "Joe Biden's veto tells 152 million Americans that funding climate change and ESG are more important to him than their retirement savings. https://t.co/VyXn7LWd7T",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1547714397094612993",
              text: "Instead of focusing on real relief to lower gas prices, the Democrats are considering spending up to $300 billion in the name of climate change. \n\nRead that again.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1502306829220651010",
              text: "The Biden administration is advocating 100% green energy as the solution for rising energy prices.\n\nIn other words, they want us to trade reliance on Russian oil for reliance on China and Russia for the materials to make electric cars, wind turbines, and solar panels.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1523718284667146240",
              text: "Joe Biden\u2019s budget for the Small Business Administration does not mention inflation once.\n\nIt mentions environmental initiatives nearly two dozen times.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1502708700800299018",
              text: "Inflation is on the mind of every American family right now, but Democrats are focused on passing a radical climate agenda.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1549828764548452353",
              text: "Just 1% of Americans named climate change as the most important problem facing the U.S., according to a recent NYT poll. \n\nWhy isn\u2019t Joe Biden listening to the American people?",
            },
          ],
          question: "Policies for environmental protection and climate action?",
        },
      ],
      summary:
        'Marsha Blackburn believes in addressing climate change and promoting sustainability through actions such as investing in clean energy, reducing emissions, and advocating conservation. She supports an "all of the above" energy policy, which aims to reduce dependence on foreign oil. However, she criticizes President Joe Biden\'s environmental and climate policies, describing them as misguided. She points out that his decisions such as halting the Keystone pipeline, rejoining the Paris Climate Agreement and turning to other countries for oil production don\'t align with the needs of the American people. She also expresses concern over Biden\'s plans to spend $300 billion in the name of climate change and his emphasis on "inclusion" and "environmental justice" in his budget.',
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      questions: [
        {
          answer:
            "The United States must take a firm stance against Russia's war crimes against Ukraine and its people. We must continue to support Ukraine and provide them with lethal defensive military aid. We must also levy all possible sanctions against the Kremlin, including targeting their energy sector and removing them from SWIFT. We must also prioritize making America energy independent again. We must send Ukraine lethal support immediately and completely sever Russia\u2019s economy from the world, stop imports of Russian gas and oil, and support Ukraine by sending more lethal aid. We must also sanction Communist China for bolstering Russia\u2019s invasion of Ukraine. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1510998086084173825",
              text: "The heinous war crimes Putin is carrying out against innocent Ukrainian civilians are monstrous and despicable. \n\n\nThe USA unequivocally stands with Ukraine in its fight to preserve its sovereignty.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1496990648603275266",
              text: "Ukraine is an independent & sovereign nation. The U.S. & our NATO partners should continue to support Ukraine & provide lethal defensive military aid. \n\nBiden must levy all possible sanctions against the Kremlin, including targeting their energy sector & removing them from SWIFT.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1504130456316428294",
              text: "President @ZelenskyyUa delivered a powerful speech this morning. We must send Ukraine lethal support immediately. https://t.co/KKR1xTBzs1",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1512092972904505350",
              text: "Russia must be fully accountable for its atrocious war crimes against innocent Ukrainian civilians. \n\nBanning normal trade relations and oil imports from Russia is a step in the right direction. However, we must prioritize making America energy independent again.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570422888984985600",
              text: "The United States must continue to stand firmly against these malign actors and support the people of Taiwan and Ukraine.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1500149671213346818",
              text: "Just finished a call with President @ZelenskyyUa, whose leadership and bravery inspire us all. \n\nHe requests the U.S. completely sever Russia\u2019s economy from the world, stop imports of Russian gas and oil, and support Ukraine by sending more lethal aid. Let\u2019s get it done. \ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\udde6 pic.twitter.com/LIAr60HdHX",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1496101410529845256",
              text: "Meanwhile, Joe Biden has refused to take meaningful action, and his weakness has emboldened Moscow. \n\nLast year, I urged President Biden to take decisive action by sending Ukraine the lethal assistance they need to defend themselves and imposing sanctions before it was too late.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1496101408936009731",
              text: "Vladimir Putin wants nothing more than to return to the days of the old Soviet Union, and he has made it clear he will attack the national security of the U.S. and our partners to make it happen.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1485418362779979776",
              text: "Just four days ago, Biden stood in front of the world and essentially gave Russia the green light to invade.\n\nNow, the State Department has ordered the families of US diplomats to evacuate Ukraine.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1503803735524491268",
              text: "Russia-state television is calling for public hangings in Ukraine. Their inhumanity is atrocious and should be met with consequences from the international community.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1498757349028290566",
              text: "Russia must be exiled from the international community for its war crimes and unprovoked attack on innocent Ukrainians. foxnews.com/politics/repub\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1498835382389125120",
              text: "Agree with @IngrahamAngle \u2013 Biden must sanction Communist China for bolstering Russia\u2019s invasion of Ukraine.\n\u00a0\nRussia, China, Iran and North Korea are the new axis of evil.\n\u00a0\nThey must be stopped. foxnews.com/media/laura-in\u2026",
            },
          ],
          question:
            "How would you approach the ongoing Ukraine conflict, and what role do you believe the United States should play in resolving it?",
        },
        {
          answer:
            "My foreign policy priorities are to strengthen our military and relationships with countries who share our values of freedom and democracy, increase access to healthcare and lower patient costs, champion trade policies that advance competition in the United States, counter Communist China and benefit our global interests, stand united against the CCP's goal of global domination, assess the extent to which sister city agreements with Communist China could be a threat to national security, strengthen our relationship with Israel, and support policies that put our national security first. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1591869318446600192",
              text: "It\u2019s time we instead focus on strengthening our military and relationships with countries who share our values of freedom and democracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1562791957729927168",
              text: "In Papua New Guinea, I met with Secretary of Defense Akipe and Deputy Chief of Defense Force Commodore Polewara focused on expanding our strategic relationship.\n\u00a0\nWe know the CCP wants one thing \u2013 global domination.\u00a0\n\u00a0\nWe must stand united against it. pic.twitter.com/FPnEj9vzzi",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1649085331160064001",
              text: "Over 150 American cities have sister city agreements with cities in Communist China. \n\nI have legislation that will assess the extent to which these agreements could be a threat to national security.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1564389434086268928",
              text: "Communist China has made their priorities crystal clear \u2014 they want global domination. \n\nIt\u2019s important we stand united with Taiwan and our freedom-loving partners around the globe. https://t.co/s597dYlc4I",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1567223555095412736",
              text: "Instead of emboldening the Iranian regime, the U.S. should focus on strengthening our relationship with America's greatest ally in the Middle East \u2014 Israel.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1591869313363103744",
              text: "President Xi has no interest in partnering with the United States. His sole interest is global domination, and he will readily take advantage of the Biden administration\u2019s weak foreign policy to inch closer to that goal.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1567300525435092993",
              text: "I also met with former Israeli Prime Minister @netanyahu. We must continue to maintain a strong relationship with Israel in order to further peace and stability in the Middle East.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1575589989403922432",
              text: "Strong engagement between the U.S. and Solomon Islands is critical to deterring security challenges in the region. \n\nDuring my visit in August, I spoke candidly with leaders across the spectrum about the dangers of Communist China's influence.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1592717206823276544",
              text: "Title 42 preserves our nation\u2019s sovereignty. \n\nOur country has every right to protect that sovereignty, and we must support policies that put our national security first.",
            },
          ],
          question:
            "What are your foreign policy priorities, particularly in relation to allies and international organizations?",
        },
        {
          answer:
            "I am committed to championing trade policies that advance competition in the United States and strengthen our manufacturing sector. I have legislation that will assess the extent to which sister city agreements with cities in Communist China could be a threat to national security. Additionally, I am working with healthcare innovators to increase access to care and lower patient costs. Instead of shopping for oil from one authoritarian regime to the next, I believe we should focus on American energy. We must also be prepared to counter Communist China and protect our national security. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479200326624366595",
              text: "The global supply chain crisis resulting from our dependence on China underscored the importance of strengthening U.S. manufacturing. My legislation with @SenGaryPeters will streamline programs run by @CommerceGov to eliminate government waste & improve efficiency.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1649085331160064001",
              text: "Over 150 American cities have sister city agreements with cities in Communist China. \n\nI have legislation that will assess the extent to which these agreements could be a threat to national security.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1500579864012890117",
              text: "Instead of shopping for oil from one authoritarian regime to the next, what about American energy? wsj.com/articles/u-s-o\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1625583502837047298",
              text: "We are deeply concerned to learn @Ford is partnering with a CCP controlled company. It is inappropriate for US corporations to partner with an adversary in order to sneak into our country. We want all American companies to succeed in America \u2014 not by doing business with the CCP.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1592717206823276544",
              text: "Title 42 preserves our nation\u2019s sovereignty. \n\nOur country has every right to protect that sovereignty, and we must support policies that put our national security first.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1650897134940573697",
              text: "Biden will veto bipartisan legislation that would reverse a rule allowing Chinese solar panel producers to avoid tariffs.\n\nThis is an anti-American business, pro-China president.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1582346421029982208",
              text: "As made evident by his opening remarks, General Secretary Xi Jinping is gearing up like never before to challenge the United States and our freedom-loving partners around the world.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1583566914948775937",
              text: "Xi Jinping is gearing up like never before to challenge the U.S. and our freedom-loving partners around the world. \n\nWhether that\u2019s in 5 days, 5 weeks, 5 months, or 5 years, we must be prepared. https://t.co/2x0X5xkJ5O",
            },
          ],
          question:
            "How will you navigate trade agreements and global economic relationships to benefit American interests?",
        },
        {
          answer:
            "I believe that the best way to address international conflicts and promote diplomacy is to focus on strengthening our military and relationships with countries who share our values of freedom and democracy. This means having a strong U.S. diplomatic presence in each Pacific Island Country, engaging with healthcare innovators to increase access to care and lower patient costs, championing trade policies that advance competition in the United States, and rebuilding our military to stop China\u2019s global domination. Additionally, we must stand united against the Chinese Communist Party and their goal of global domination. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1591869318446600192",
              text: "It\u2019s time we instead focus on strengthening our military and relationships with countries who share our values of freedom and democracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1562560583240945664",
              text: "In Fiji, I met with our U.S. political and defense leadership. It is vitally important to have a strong U.S. diplomatic presence on each Pacific Island Country. @USEmbassySuva @USAmbSuva",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621695151323156484",
              text: "First, shoot down the Chinese spy balloon over the United States.\n\nSecond, shoot down the one over Latin America.\n\nThird, rebuild our military to stop China\u2019s global domination.",
            },
          ],
          question:
            "What approach will you take to address international conflicts and promote diplomacy?",
        },
      ],
      summary:
        "Marsha Blackburn's foreign policy is centered on strengthening military power and alliances with countries sharing similar values of freedom and democracy. She is against Russia's aggressive actions in Ukraine and advocates for immediate lethal aid and sanctions against Moscow, as well as penalties against China for supporting Russia\u2019s invasion. \n\nBlackburn emphasizes sanctioning measures against the Kremlin, focusing on their energy sector and isolating their economy from the world. She also prioritizes making America energy-independent. \n\nShe aims to champion U.S. competitive trade policies, strengthen manufacturing, and assess potential national security threats from sister city agreements with China. Advocating for increased healthcare access and lower patient costs, she sees this as a part of foreign policy. \n\nFinally, she is dedicated to countering the Chinese Communist Party's aspiration for global domination and sees a need for a strong U.S. diplomatic presence in the Pacific Islands. Blackburn is also committed to strengthening the U.S.'s relationship with Israel and ensuring that national security is at the forefront of all policies.",
    },
    Guns: {
      questions: [
        {
          answer:
            "I believe that we need to focus on measures that will keep guns out of the hands of criminals and those who are a danger to themselves and others. This includes strengthening background checks, closing loopholes, and ensuring that those who are prohibited from owning firearms are not able to access them. We also need to invest in mental health treatment and school security to help prevent gun violence. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540098687162007553",
              text: "I\u2019ve introduced an\u00a0amendment\u00a0to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540159698929913857",
              text: "Enhanced funding for school security & mental health treatment is a good thing \u2014 but it\u2019s being combined with giving the left a foothold to limit the 2nd Amendment. I voted against the gun control bill because Americans' constitutional right to keep & bear arms is not negotiable.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1641457172734148608",
              text: "I am devastated by the horrific shooting at The Covenant School in Nashville. Violence against children is one of the most heinous crimes imaginable. \n\nMy legislation with @SenatorHagerty will make schools safer. https://t.co/qzLSm2193U",
            },
          ],
          question:
            "What gun control measures do you believe will be effective in reducing gun violence?",
        },
        {
          answer:
            "I believe that law-abiding Americans have a right to keep and bear arms, and that this right should not be infringed upon. I have introduced an amendment to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers. I have also voted against the gun control bill because Americans' constitutional right to keep & bear arms is not negotiable. We must keep drugs, gangs, and violent criminals out of our communities, and we need prosecutors to keep criminals behind bars and keep our communities safe. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540159698929913857",
              text: "Enhanced funding for school security & mental health treatment is a good thing \u2014 but it\u2019s being combined with giving the left a foothold to limit the 2nd Amendment. I voted against the gun control bill because Americans' constitutional right to keep & bear arms is not negotiable.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540098687162007553",
              text: "I\u2019ve introduced an\u00a0amendment\u00a0to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1636082953808650243",
              text: "Violent crime is raging across this country, and the Democrats are focusing on criminalizing gun ownership for law-abiding citizens. https://t.co/Z0oKwSRu8H",
            },
          ],
          question:
            "How do you plan to balance Second Amendment rights with public safety concerns?",
        },
        {
          answer:
            "Absolutely. I introduced an amendment to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers. We must also keep drugs, gangs, and violent criminals out of our communities. We need prosecutors to keep criminals behind bars and keep our communities safe. I have also sent a letter demanding Biden eliminate soft-on-crime policies. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540098687162007553",
              text: "I\u2019ve introduced an\u00a0amendment\u00a0to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1636082953808650243",
              text: "Violent crime is raging across this country, and the Democrats are focusing on criminalizing gun ownership for law-abiding citizens. https://t.co/Z0oKwSRu8H",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1633624258306887682",
              text: "No citizen should be forced to live in fear of violent criminals, whether in DC, Memphis, or Knoxville.\n\nWe must keep drugs, gangs, and violent criminals out of our communities. We need prosecutors to keep criminals behind bars and keep our communities safe.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1574941536210427904",
              text: "@joesantarlasci Hi Joe, in response to the violent crime spike in cities across America \u2014 including TN, I introduced the Restoring Law and Order Act to support our police and crack down on violent crime. I have also sent a letter demanding Biden eliminate soft-on-crime policies.",
            },
          ],
          question:
            "Are there policies to promote responsible gun ownership that you support?",
        },
      ],
      summary:
        "Marsha Blackburn promotes responsible gun ownership and believes in the importance of maintaining Second Amendment rights. She opposes some gun control measures, particularly those she views as infringing on constitutional rights. She advocates for strengthening background checks, closing loopholes, and preventing those who are prohibited from owning firearms from accessing them. Blackburn has also recommended initiatives to hire veterans and former law enforcement officials as school safety officers. She is a proponent of stringent prosecution policies that are tough on crime, aiming to keep drugs, gangs, and violent criminals out of communities.",
    },
    Healthcare: {
      questions: [
        {
          answer:
            "I am committed to making healthcare accessible to all Americans, regardless of income. I am working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda to increase access to care and lower patient costs. Additionally, I am advocating for trade policies that will increase competition in the United States and help keep costs down. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1637099032395886592",
              text: "Access to healthcare is critical, which is why I'm working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda.\n\nwdef.com/blackburn-tout\u2026",
            },
          ],
          question:
            "What steps will you take to make healthcare accessible to all Americans, regardless of income?",
        },
        {
          answer:
            "I am committed to working with healthcare innovators to increase access to care and lower patient costs. I have proposed cutting federal spending across the board for non-defense items by 1%, 2%, and 5%. I am also introducing bipartisan legislation as part of my Rural Healthcare Agenda to help address the issue of skyrocketing healthcare costs and medical debt. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1620478181450067968",
              text: "The Democrats\u2019 out-of-control spending will burden our kids and grandkids with our debt.\n\nFor freedom\u2019s sake, we must cut spending and shrink the bureaucracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1637099032395886592",
              text: "Access to healthcare is critical, which is why I'm working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda.\n\nwdef.com/blackburn-tout\u2026",
            },
          ],
          question:
            "How do you plan to address the issue of skyrocketing healthcare costs and medical debt?",
        },
        {
          answer:
            "Absolutely. I believe that increasing access to care and lowering patient costs should be a priority. To that end, I'm working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda. Additionally, I'm looking for ways to advance competition in the US through trade policies. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1637099032395886592",
              text: "Access to healthcare is critical, which is why I'm working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda.\n\nwdef.com/blackburn-tout\u2026",
            },
          ],
          question:
            "Are there specific healthcare reforms you believe are necessary to improve the system?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to making healthcare accessible to all Americans, regardless of income. She is working to introduce bipartisan legislation as part of a Rural Healthcare Agenda with the aim of increasing access to care and lowering patient costs. Additionally, she is advocating for trade policies that would increase competition in the United States, therefore, helping to keep healthcare costs down. She proposes cutting federal spending for non-defense items by 1%, 2%, and 5%. Blackburn believes in the necessity of specific healthcare reforms and is collaborating with healthcare innovators to reach these objectives.",
    },
    Immigration: {
      questions: [
        {
          answer:
            "Comprehensive immigration reform is a complex issue that requires a thoughtful and balanced approach. We must secure our borders, enforce our laws, and ensure that those who come to our country do so legally. We must also recognize the contributions of immigrants to our economy and society, and create a pathway to citizenship for those who are here and have been contributing to our communities. We must also ensure that our immigration system is fair and just, and that it works for all Americans. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1651572706658197505",
              text: "The CBP has warned us that ending Title 42 will pave the way for thousands of illegal immigrants to flood our border each day.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1648373428221538304",
              text: "Illegal immigration is up according to the newest numbers from March.\n\nNo word from the White House \u2014 per usual. How little does this administration prioritize our national security these days?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1520888868832256000",
              text: "Secretary Mayorkas just admitted ending Title 42 will generate an unprecedented influx of illegal immigrants at the southern border. https://t.co/EjpZTiFdKB",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1512831626635628563",
              text: "Last month, 6,000 immigrants crossed our southern border illegally every day. \n\nWhen Biden ends Title 42, that number could skyrocket to 18,000 illegal immigrants per day.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1519777324526407681",
              text: "We already have 6,000 illegal immigrants coming to our southern border every day. That number would triple if President Biden eliminated Title 42. https://t.co/XElOAesSWA",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1572386871686078465",
              text: "FAA reports indicate around 425,000 illegal immigrants were transported from the southern border to our communities last year. \n\nHow much of this is on the taxpayer\u2019s dime? https://t.co/0SCgC0qrGY",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1544707089934815232",
              text: "DHS has implemented a new policy that allows previously deported illegal immigrants to apply for new legal status without a wait elsewhere \u2014 no questions asked.\n\nThis will only incentivize more illegal immigration.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1611044056326459392",
              text: "The Biden administration can immediately take four meaningful steps to stop his border crisis: \u00a0\n\u00a0\n1\ufe0f\u20e3 End Catch and Release\u00a0\n2\ufe0f\u20e3 Embrace Remain in Mexico\n3\ufe0f\u20e3 Keep Title 42\n4\ufe0f\u20e3 Build the wall",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1612789651864961025",
              text: "Border Patrol agents are telling me the number one thing they need to help protect our country is for Pres. Biden to simply enforce the immigration laws we currently have in place.\n\nSince Biden took office, over 4.3 million migrants have attempted to illegally enter our country. https://t.co/ZKfgsqQMj5",
            },
          ],
          question:
            "How will you approach comprehensive immigration reform, including pathways to citizenship?",
        },
        {
          answer:
            "I support building a wall, better technology, more agents, ending catch and release, embracing remain in Mexico, keeping Title 42, and building the wall. We must also enforce the immigration laws we currently have in place and prioritize our national security. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1488196412433215488",
              text: "Our Border Patrol agents are overrun and overwhelmed by the unprecedented number of immigrants entering illegally. They need support and resources.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1612185154272862208",
              text: "Being serious about border security means building a wall, better technology and more agents. \n\nBiden should give Border Patrol what they need to keep us safe.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1612789651864961025",
              text: "Border Patrol agents are telling me the number one thing they need to help protect our country is for Pres. Biden to simply enforce the immigration laws we currently have in place.\n\nSince Biden took office, over 4.3 million migrants have attempted to illegally enter our country. https://t.co/ZKfgsqQMj5",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1648373428221538304",
              text: "Illegal immigration is up according to the newest numbers from March.\n\nNo word from the White House \u2014 per usual. How little does this administration prioritize our national security these days?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1572274612272242689",
              text: "For the first time in history, over 2 million migrants have been encountered at the southern border this year.\n\n@VP, still think the border is secure?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1592717206823276544",
              text: "Title 42 preserves our nation\u2019s sovereignty. \n\nOur country has every right to protect that sovereignty, and we must support policies that put our national security first.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1502844474023723008",
              text: "Border Patrol agents have apprehended illegals on the terrorist watchlist. \n\nAn open southern border is a real national security threat.",
            },
          ],
          question:
            "What measures do you support for border security and managing immigration flows?",
        },
        {
          answer:
            "I believe that we must secure our borders and enforce our immigration laws. We must also ensure that our immigration system is fair and just. We must prioritize the safety of our citizens and the security of our nation. We must also ensure that those who come to our country legally are treated with respect and dignity. We must also ensure that those who come to our country illegally are held accountable and that our laws are enforced. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1648373428221538304",
              text: "Illegal immigration is up according to the newest numbers from March.\n\nNo word from the White House \u2014 per usual. How little does this administration prioritize our national security these days?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1544707089934815232",
              text: "DHS has implemented a new policy that allows previously deported illegal immigrants to apply for new legal status without a wait elsewhere \u2014 no questions asked.\n\nThis will only incentivize more illegal immigration.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1612789651864961025",
              text: "Border Patrol agents are telling me the number one thing they need to help protect our country is for Pres. Biden to simply enforce the immigration laws we currently have in place.\n\nSince Biden took office, over 4.3 million migrants have attempted to illegally enter our country. https://t.co/ZKfgsqQMj5",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1488304084880969728",
              text: ".@JoeBiden, it\u00e2\u20ac\u2122s not hard to figure out why illegal immigrants are entering the U.S. \n\nIf you listened to Border Patrol, you would know it\u00e2\u20ac\u2122s your open border policies and promises of amnesty. https://t.co/HQmgS6UQOJ",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1527240120860033026",
              text: "Illegal immigrants in the size of a new town in any state across America could start coming across the southern border if Title 42 ends next week. pic.twitter.com/p3YJNSdiHy",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1519777324526407681",
              text: "We already have 6,000 illegal immigrants coming to our southern border every day. That number would triple if President Biden eliminated Title 42. https://t.co/XElOAesSWA",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1488178438552891395",
              text: "Since Obama\u00e2\u20ac\u2122s unconstitutional DACA took effect, U.S. Border Patrol has apprehended nearly 6 million illegal immigrants from 165 countries \u00e2\u20ac\u201d not including the \u00e2\u20ac\u02dcgot-aways.\u00e2\u20ac\u2122",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1572386871686078465",
              text: "FAA reports indicate around 425,000 illegal immigrants were transported from the southern border to our communities last year. \n\nHow much of this is on the taxpayer\u2019s dime? https://t.co/0SCgC0qrGY",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1486393850289692673",
              text: "FEMA should focus on assisting communities ravaged by storms, not shuttling illegal immigrants to communities across the nation.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1520888868832256000",
              text: "Secretary Mayorkas just admitted ending Title 42 will generate an unprecedented influx of illegal immigrants at the southern border. https://t.co/EjpZTiFdKB",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1569433246202560514",
              text: "Liberal leaders volunteered their communities to serve as sanctuary cities for illegal immigrants. Now that migrants are arriving, the left is protesting. \n\nThey can\u2019t have it both ways. https://t.co/r6UUkv2wOr",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1651572706658197505",
              text: "The CBP has warned us that ending Title 42 will pave the way for thousands of illegal immigrants to flood our border each day.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1502844474023723008",
              text: "Border Patrol agents have apprehended illegals on the terrorist watchlist. \n\nAn open southern border is a real national security threat.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1484243904367104003",
              text: "President Biden put up a \u00e2\u20ac\u0153y\u00e2\u20ac\u2122all come\u00e2\u20ac\u009d sign at the border when he took office. One year later, we\u00e2\u20ac\u2122re witnessing illegal immigration in numbers unseen before.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1612650472179130371",
              text: "Joe Biden\u2019s new plan is to have illegal immigrants apply for asylum via an app so that when they show up, they are \u201clegal.\u201d",
            },
          ],
          question:
            "Are there policies to address the status of undocumented immigrants that you advocate?",
        },
      ],
      summary:
        "Marsha Blackburn approaches immigration with an emphasis on law enforcement and border security. She supports measures such as building a wall, increasing technological advances at the border, stationing more agents, ending catch and release practices, and maintaining the remain-in-Mexico policy. While she acknowledges the contribution of immigrants, she advocates for thorough regulation, prioritizing national security and insisting that immigration laws currently in place be enforced. Although advocating for a fair and just immigration system, Blackburn necessitates legal entry into the country and holds a strict stance on dealing with individuals who enter the country illegally.",
    },
    "Inflation and Prices": {
      questions: [
        {
          answer:
            "We must take action to reduce inflation and the burden it places on everyday Americans. We must reduce government spending, unleash American energy, and focus on policies that will create jobs and stimulate the economy. We must also ensure that the government is not taking more money out of the pockets of hardworking Americans through taxes. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1482004445919653889",
              text: "During my tele-town hall with Tennesseans this week, 93% said inflation adversely affects their family budget.\n\nInflation is a tax on every American, and Joe Biden is ignoring the problem.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1569774422139064322",
              text: "While President Biden and the left brag about their $739 billion tax-and-spend deal, American families are hurting and stressed.\n\u00a0\nInflation is at a 40-year high, the border is not secure, and the violent crime in our communities is rampant.\n\u00a0\nWhat\u2019s their plan?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1508530709337714692",
              text: "Americans are suffering under record-high inflation. Biden\u2019s proposed $5 trillion in new spending is packed full of socialism \u2014 including universal Pre-K & climate change. \n\nWe will not support spending more on the left\u2019s wish list, which will kill jobs & create more inflation.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1533255652474294272",
              text: "Inflation is crippling the American economy, but all Biden\u2019s Treasury Secretary can do is throw her hands up and say, \u2018sorry, I was wrong.\u2019 \n\nThere\u2019s a solution \u2014 stop spending trillions of dollars and unleash American energy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1565495975552196608",
              text: "The Democrats in control of the White House, U.S. Senate, and House of Representatives are responsible for the 40-year high inflation crippling our economy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1574395758711013376",
              text: "Even if prices stopped rising today, inflation will already cost hardworking American families an extra $8,616 in the coming year \u2014 courtesy of Joe Biden and the DC Democrats\u2019 policies.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1573721451601035272",
              text: "Nearly 60% of Americans believe our nation is in a recession. \n\nMeanwhile, President Biden continues to promote inflationary policies and raise taxes on hardworking families.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1560020558754058242",
              text: "Only 12% of Americans believe the Build Back Broke plan that President Biden has signed into law will actually reduce inflation. \n\nDemocrats are out of touch with the American people.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1575471215275384833",
              text: "Inflation is up 13.2% since Biden took office. \n\nHis policies will cost Tennesseans an extra $7,386 over the next year. \n\nAmericans deserve better.",
            },
          ],
          question:
            "What measures do you propose to mitigate the impact of inflation on everyday Americans?",
        },
        {
          answer:
            "I am committed to working with healthcare innovators to increase access to care and lower patient costs. Additionally, I plan to champion trade policies that advance competition in the United States. We need an Operation Warp Speed for energy to lower gas prices and inflation. We must also unleash American energy, approve new drilling permits, hold lease sales and approve new energy infrastructure. We must reject the left's wish list of spending that will kill jobs and create more inflation. We need an all of the above energy policy and legislation like my Open App Markets Act that would ban anti-competitive behavior from app giants like Apple and Google in the app marketplace. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1581983854147276807",
              text: "Biden\u2019s economic advisor is claiming tax credits to weatherize homes will help reduce inflation next year. \n\nAmerican families are feeling the pressure from 40-year high inflation, and the White House is more concerned with their green energy agenda.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1539612648106508290",
              text: "The solution to lower gas prices is to unleash American energy, approve new drilling permits, hold lease sales and approve new energy infrastructure.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1489061361476947974",
              text: "Nearly 90 percent of developers believe there should be legislation like my Open App Markets Act that would ban anti-competitive behavior from app giants like Apple and Google in the app marketplace. appfairness.org/developer-rese\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "Are there specific policies you support to stabilize prices and ensure affordability?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to reducing inflation and minimizing its burden on everyday Americans. She proposes reductions in government spending and advocates for the stimulation of job creation to boost the economy. She also takes a stand against government policies that may increase the tax burden on citizens.\n\nIn terms of specific policies, Blackburn supports measures that would increase access to healthcare and decrease patient costs. She also seeks to advance competition through fair trade policies. Regarding energy, she calls for an Operation Warp Speed style approach to lower gas prices, which she believes will also help curb inflation. She supports the approval of new drilling permits, the holding of lease sales, and the development of new energy infrastructure.\n\nBlackburn is also in favor of technology and market-based policies such as the Open App Markets Act, which aims to prevent anti-competitive practices in the app marketplace. She is against left-leaning spending proposals that she believes could lead to job losses and increased inflation.",
    },
    "Jobs & the Economy": {
      questions: [
        {
          answer:
            "I will work to increase access to healthcare and lower patient costs, champion trade policies that advance competition in the United States, unleash American energy, approve new drilling permits, hold lease sales and approve new energy infrastructure, and reduce federal spending across the board. These strategies will help stimulate job growth and reduce unemployment. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479200326624366595",
              text: "The global supply chain crisis resulting from our dependence on China underscored the importance of strengthening U.S. manufacturing. My legislation with @SenGaryPeters will streamline programs run by @CommerceGov to eliminate government waste & improve efficiency.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1539612648106508290",
              text: "The solution to lower gas prices is to unleash American energy, approve new drilling permits, hold lease sales and approve new energy infrastructure.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1581983854147276807",
              text: "Biden\u2019s economic advisor is claiming tax credits to weatherize homes will help reduce inflation next year. \n\nAmerican families are feeling the pressure from 40-year high inflation, and the White House is more concerned with their green energy agenda.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
          ],
          question:
            "What strategies will you employ to stimulate job growth and reduce unemployment?",
        },
        {
          answer:
            "We need to get big government out of the way and support our small business owners. We must continue strengthening IP protections for artistic and creative communities and always empower our talented musicians and their projects! We must also eliminate government waste and improve efficiency by streamlining programs run by the Department of Commerce. We must also ensure that federal funds are not going to the Chinese Communist Party and instead are going to American farmers. By doing this, we can help small businesses and entrepreneurs to boost the economy. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1596543582948597763",
              text: "Over 99% of businesses in Tennessee are small businesses. \n\nIt\u2019s critical we get government out of the way and let small business owners do what they do best \u2013 innovate and create new jobs. #SmallBusinessSaturday #ShopSmall https://t.co/umPbg54bVV",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1545910059137486849",
              text: "In Lawrence County, local leaders are concerned about Biden\u2019s regulations hurting small businesses. \n\nWe need to get big government out of the way and support our small business owners. @LawrenceCoTN pic.twitter.com/so9jUTOVd8",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479200326624366595",
              text: "The global supply chain crisis resulting from our dependence on China underscored the importance of strengthening U.S. manufacturing. My legislation with @SenGaryPeters will streamline programs run by @CommerceGov to eliminate government waste & improve efficiency.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1559588393222283264",
              text: "We must continue strengthening IP protections for artistic and creative communities and always empower our talented musicians and their projects!",
            },
          ],
          question:
            "How can the government support small businesses and entrepreneurs to boost the economy?",
        },
        {
          answer:
            "Absolutely. I'm committed to helping hardworking Americans get ahead. I'm working to lower everyday costs for Americans by championing trade policies that advance competition in the United States and by working with healthcare innovators on ways we can increase access to care and lower patient costs. I'm also fighting to get our fiscal house in order so that our children and grandchildren can enjoy the same liberties that we do. We need to get our economy back on track and make sure that everyone has the opportunity to succeed. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1569774422139064322",
              text: "While President Biden and the left brag about their $739 billion tax-and-spend deal, American families are hurting and stressed.\n\u00a0\nInflation is at a 40-year high, the border is not secure, and the violent crime in our communities is rampant.\n\u00a0\nWhat\u2019s their plan?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1638017382210838528",
              text: "I discuss mandatory and discretionary spending in Episode 3 of my budget series. \n\nWe need to get our fiscal house in order so that our children and grandchildren can enjoy the same liberties that we do. https://t.co/zI39gVQZqk",
            },
          ],
          question:
            "Do you have a plan to address income inequality and promote wage growth?",
        },
      ],
      summary:
        "Marsha Blackburn is dedicated to stimulating job growth and reducing unemployment. She aims to achieve this through initiatives like increasing access to healthcare and lowering patient costs, advocating for trade policies that encourage competition, promoting American energy, and reducing federal spending. When it comes to supporting small businesses and entrepreneurs, she believes in reducing government interference, strengthening IP protections, streamlining programs run by the Department of Commerce, and ensuring federal funds aid American farmers rather than foreign entities. Her strategy to address income inequality and wage growth involves lowering everyday costs for Americans, working on fiscal policies that ensure future generations enjoy current liberties, and creating opportunities for everyone to succeed.",
    },
    "National Security": {
      questions: [
        {
          answer:
            "We must support policies that prioritize our national security while also protecting the privacy and security of Americans. This includes passing my bipartisan Kids Online Safety Act, which provides children and parents with tools, safeguards, and transparency to protect against online threats to kids\u2019 health and well-being. We must also create a national privacy standard to ensure that companies like Facebook are not recklessly mishandling our data. Additionally, we must support our law enforcement and end soft-on-crime policies to keep our communities safe. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1592717206823276544",
              text: "Title 42 preserves our nation\u2019s sovereignty. \n\nOur country has every right to protect that sovereignty, and we must support policies that put our national security first.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1519339627257208832",
              text: "Facebook has lost control of what they are doing with your data. This is reckless and threatens the privacy and security of Americans. We need a national privacy standard. vice.com/en/article/akv\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570217130246668293",
              text: "Keeping communities safe must become a priority for the Biden White House. \n\n@SenatorHagerty and I sent a letter to @POTUS today urging him to take immediate action to promote safety and support our law enforcement. pic.twitter.com/1eQ6n2vD7e",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570204583242121224",
              text: "Americans want to feel safe again, and that starts with supporting our police and ending soft-on-crime policies.\n\nThank you @ChuckGrassley, @SenatorBraun, @SenJohnKennedy, and @SenRonJohnson for joining me and @SenatorHagerty to discuss how we help solve the violent crime crisis. pic.twitter.com/jejakD57OF",
            },
          ],
          question:
            "How will you ensure the safety and security of the nation while respecting individual privacy?",
        },
        {
          answer:
            "I believe that we must take a comprehensive approach to counterterrorism efforts and cybersecurity protection. This includes passing legislation that preserves our nation's sovereignty, updating existing regulations to address crypto misuse, providing children and parents with tools to protect against online threats, and supporting initiatives that allow users to access the internet in countries where censorship is rampant. Additionally, we must ensure that companies are held accountable for their role in protecting against cyber attacks and that they are taking the necessary steps to protect against malicious actors. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1605683692902531072",
              text: "I am pleased that my Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs, unanimously passed the Senate last night. \n\nWe are now one step closer to this bill becoming law.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1592717206823276544",
              text: "Title 42 preserves our nation\u2019s sovereignty. \n\nOur country has every right to protect that sovereignty, and we must support policies that put our national security first.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1580556103687233536",
              text: "The Cryptocurrency Cybersecurity Information Sharing Act will update existing regulations to address crypto misuse and provide a voluntary mechanism for companies to report bad actors and protect cryptocurrency from dangerous practices. techcrunch.com/2022/09/28/us-\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1603110135005843456",
              text: "My bipartisan Kids Online Safety Act provides children and parents with tools, safeguards, and transparency to protect against online threats to kids\u2019 health and well-being.\u00a0\n\u00a0\nIt\u2019s critical we get this done.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1617950500262973444",
              text: "Thousands of bot attacks are launched daily against critical infrastructure businesses, and they have discovered a solution while ticket companies have not.\n\nThis is unbelievable. Ticket companies need to step up and stop bots. https://t.co/uxQYVMopOy",
            },
          ],
          question:
            "What is your approach to counterterrorism efforts and cybersecurity protection?",
        },
        {
          answer:
            "I have introduced the Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs. This bill has unanimously passed the Senate and is one step closer to becoming law. I have also introduced legislation to assess the extent to which sister city agreements with cities in Communist China could be a threat to national security. We must ensure our intelligence agencies are equipped to adequately respond to threats. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1605683692902531072",
              text: "I am pleased that my Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs, unanimously passed the Senate last night. \n\nWe are now one step closer to this bill becoming law.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1649085331160064001",
              text: "Over 150 American cities have sister city agreements with cities in Communist China. \n\nI have legislation that will assess the extent to which these agreements could be a threat to national security.",
            },
          ],
          question:
            "Are there specific measures to strengthen intelligence agencies that you endorse?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to ensuring the safety and security of the nation. She supports policies that not only prioritize national security but also protect individual privacy. This includes passing legislation such as her bipartisan Kids Online Safety Act that protects children from online threats. She advocates for a national privacy standard to keep companies from mishandling data. \n\nShe also believes in a comprehensive approach to counterterrorism efforts and cybersecurity protection. She promotes legislation to safeguard the nation's sovereignty and hold companies accountable for their role in cyber protection. \n\nTo strengthen intelligence agencies, she has introduced the Civilian Cyber Security Reserve Act, aiming to build a talent pipeline in response to cyber attacks. Additionally, she's suggested legislation to assess potential threats to national security due to sister city agreements with cities in Communist China.",
    },
    Taxes: {
      questions: [
        {
          answer:
            "I believe that hardworking Americans should not be subject to unnecessary taxes. We need to review tax policy, tariffs, and get federal spending under control. We must reduce our federal debt and stop spending money we do not have on programs we don't want or need. I have proposed cutting federal spending across the board for non-defense items by 1%, 2%, and 5%. Additionally, I am fighting back against the left's \"Inflation Reduction Act\" which is just a Trojan Horse for their socialist agenda. Joe Biden's expansion of the IRS is a direct attack on hardworking American families. We cannot continue to rack up this federal debt; we are at $31 trillion and counting. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1634205649385947136",
              text: "Biden\u2019s budget is calling for the highest personal income tax rate since 1986.\n\nMore unnecessary taxes on hardworking Americans.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621266231977287681",
              text: "I am honored to join the @SenFinance.\n\nWe will be working to make certain that we review tax policy, tariffs, and help get this federal spending under control. https://t.co/WdSUT0hWbU",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1569774422139064322",
              text: "While President Biden and the left brag about their $739 billion tax-and-spend deal, American families are hurting and stressed.\n\u00a0\nInflation is at a 40-year high, the border is not secure, and the violent crime in our communities is rampant.\n\u00a0\nWhat\u2019s their plan?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1633896323853238283",
              text: "Increasing taxes on hardworking Americans is not enough for Joe Biden. \n\nHe\u2019s also going to increase taxes on:\n\u00a0\n\u2705Gas\n\u2705 Businesses\n\u2705 Medicare\n\u2705 Real Estate\n\u2705 Retirement Accounts",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1623695828777934848",
              text: "I\u2019ve proposed cutting federal spending across the board for non-defense items by 1%, 2%, and 5%.\n\nWe cannot continue to rack up this federal debt; we are at $31 trillion and counting. https://t.co/d8xssvb0al",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1648719904386719744",
              text: "Last year, Biden's IRS received $80 billion, with nearly 60% of that going toward enforcement actions. \n\nBiden's expansion of the IRS is a direct attack on hardworking American families.",
            },
          ],
          question:
            "What is your position on tax reform, including individual and corporate taxes?",
        },
        {
          answer:
            "We must close tax loopholes and ensure that everyone pays their fair share. We must also ensure that the IRS is held accountable and that they are not targeting political opponents. We must reduce spending and reduce our federal debt, so that we are not relying on taxes to fund programs we don't want or need. We must also unleash American energy and approve new drilling permits, hold lease sales and approve new energy infrastructure to help lower gas prices. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1648710099722985472",
              text: "The IRS paid Matt Taibbi an unannounced visit on the same day he testified about massive government abuse. \n\nWhy is Biden's IRS going after political opponents?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1560780958156070912",
              text: "Blount County leaders are troubled with the latest passage of a $739 billion spending package, which includes funding for 87,000 new IRS agents. \n\nBiden\u2019s #BuildBackBroke plan will increase taxes and devastate Main Street America. pic.twitter.com/9YXMDg9hHM",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1634205649385947136",
              text: "Biden\u2019s budget is calling for the highest personal income tax rate since 1986.\n\nMore unnecessary taxes on hardworking Americans.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1539612648106508290",
              text: "The solution to lower gas prices is to unleash American energy, approve new drilling permits, hold lease sales and approve new energy infrastructure.",
            },
          ],
          question:
            "How would you address concerns about tax evasion and tax loopholes?",
        },
        {
          answer:
            "We must reduce our federal debt and stop spending money we do not have on programs we don't want or need. We need to get our fiscal house in order so that our children and grandchildren can enjoy the same liberties that we do. We must cut spending and shrink the bureaucracy. We cannot continue to rack up this federal debt; we are at $31 trillion and counting. We need legislation to balance the budget and prevent even more of Biden\u2019s tax hikes. We will not support spending more on the left\u2019s wish list, which will kill jobs & create more inflation. Public-private partnerships are incredibly important, which is why I secured additional funding for defense partnerships with private sector developers and TN universities in this year\u2019s national defense bill. We need to prioritize strengthening U.S. manufacturing and streamline programs run by @CommerceGov to eliminate government waste & improve efficiency. We must focus on getting baby formula to American families instead of advocating for abortion-on-demand. We must prioritize 18,000 Border Patrol agents before hiring a small army of 87,000 IRS agents. We must prioritize the needs of American families over the Democrats' $739 billion tax-and-spend deal. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1620460307557801985",
              text: "According to the non-partisan Congressional Budget Office, America\u2019s spending is on the wrong path.\n\nWe must cut spending.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1620478181450067968",
              text: "The Democrats\u2019 out-of-control spending will burden our kids and grandkids with our debt.\n\nFor freedom\u2019s sake, we must cut spending and shrink the bureaucracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1636735012283219970",
              text: "Biden\u2019s budget priorities: \n \n\u26a0\ufe0f$3 billion for gender equity.\n\u26a0\ufe0f$ 150 million for illegal immigrants\u2019 legal fees.\n\u26a0\ufe0f$57 million for the UN\u2019s Population Fund that supports forced sterilization in China.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1575949759751168000",
              text: "The Biden administration is playing with fire and your kids\u2019 future as the national debt increases. \n\nWe need legislation to balance the budget and prevent even more of Biden\u2019s tax hikes. wsj.com/articles/the-s\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1508530709337714692",
              text: "Americans are suffering under record-high inflation. Biden\u2019s proposed $5 trillion in new spending is packed full of socialism \u2014 including universal Pre-K & climate change. \n\nWe will not support spending more on the left\u2019s wish list, which will kill jobs & create more inflation.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1525205674489266176",
              text: "Democrats should spend less time advocating for abortion-on-demand and more time on getting baby formula to American families.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1574197613674504192",
              text: "The Democrats voted against 18,000 Border Patrol agents before hiring a small army of 87,000 IRS agents using your tax dollars. \n\nTake a guess where their priorities are.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1629120127730950144",
              text: "Biden and the Democrats' $6 trillion spending spree is squeezing Americans' wallets and robbing them of their hard-earned money.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1547735594469453826",
              text: "Everyone\u2019s rightfully talking about how bad inflation has gotten, but Democrats are too busy figuring out how to spend more of your tax dollars. https://t.co/97QlP124yx",
            },
          ],
          question:
            "What government spending priorities would you emphasize to ensure a balanced budget?",
        },
      ],
      summary:
        "Marsha Blackburn is in favor of tax reform, including reducing federal spending and cutting back on programs that are unnecessary or unwanted. She believes that the current federal debt is too high and is urging for actions to reduce it. She has proposed cuts to federal spending for non-defense items and firmly opposes the expansion of IRS, viewing it as a direct attack on American families.\n\nShe also underlines the need to close tax loopholes and ensure all citizens pay their respective share. Blackburn puts stress on holding the IRS accountable and preventing it from targeting political opponents, and advocates unleashing American energy to help lower gas prices, which would potentially contribute to the economy.\n\nIn terms of government spending, her priorities include cutting spending, shrinking bureaucracy, and preventing further tax hikes. She supports strengthening U.S. manufacturing, pushing for efficiency in government programs, and furthering defense partnerships with private sector developers and Tennessee universities. She emphasizes the priority of American families' needs over additional spending plans proposed by Democrats.",
    },
  },
  "Marsha Blackburn (2020)": {
    Abortion: {
      questions: [
        {
          answer:
            "I believe that every life is precious and deserves protection, and I am committed to safeguarding it at every stage. I have sponsored and supported legislation that would protect women and children from dangerous late-term abortions, and I have introduced the Title X Abortion Provider Prohibition Act, which strips all abortion providers, including Planned Parenthood, of federal funding. I also support the Born-Alive Abortion Survivors Protection Act, which ensures that babies born as a result of a botched abortion receive the same care as any other newborn. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1101500973606932481",
              text: "The Title X Abortion Providers Prohibition Act draws the line: If you provide healthcare for women in underserved areas, then you should have access to federal funds, but abortion providers need not apply. My interview w/ @EWTNews\u00e2\u20ac\u2122 @CatSzetlner here: youtube.com/watch?v=4TcBml\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/951239624697044992",
              text: "Honored to sponsor the Born-Alive Abortion Survivors Protection Act, which the House will pass during the #MarchforLife. Babies born during a failed abortion must receive the same medical care as babies born any other way \u00e2\u20ac\u201c it\u00e2\u20ac\u2122s that simple.  Watch my interview with @EWTN pic.twitter.com/2KE1uRNnX6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1083499955686440960",
              text: "Today, I introduced my first bill in the United States Senate, S. 105, the Title X Abortion Provider Prohibition Act, which strips all abortion providers, including Planned Parenthood, of federal funding. pic.twitter.com/UwtbwGt1wd",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/558350595120435200",
              text: "Proud to support House passage of #HR7. There should be no taxpayer dollars used to pay for abortions. #prolife http://1.usa.gov/1ClY5JL",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/558278341477015552",
              text: "As part of our continued effort to protect innocent life the House is considering #HR7 to prohibit taxpayer funding for abortion. #prolife",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/630779302032838656",
              text: "Planned Parenthood\u00e2\u20ac\u2122s taxpayer funded assault on the unborn must be stopped. Here\u00e2\u20ac\u2122s my op-ed in @thedailyherald: http://bit.ly/1EjULx6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/954058079443791873",
              text: "The Born Alive Abortion Survivors Protection Act will ensure that doctors do everything they can to save the life of a baby who survives an abortion. pic.twitter.com/jI1778GkKv",
            },
          ],
          question:
            "Where do you stand on the legality of abortion, and what restrictions, if any, do you support?",
        },
        {
          answer:
            "Absolutely. I have been a strong advocate for protecting innocent life and have introduced legislation to prohibit taxpayer funding for abortion and to strip all abortion providers, including Planned Parenthood, of federal funding under Title X of the Public Health Service Act. I have also supported the Born-Alive Abortion Survivors Protection Bill, which ensures that babies born as a result of a botched abortion receive the same care as any other newborn. I believe that we must protect all human life and that your taxpayer dollars should not go to any organization that provides abortions. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/753333364367757312",
              text: "My remarks on legislation that will protect health care providers that decline to be involved in abortions. http://ow.ly/u6H0302dJxg",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/558278341477015552",
              text: "As part of our continued effort to protect innocent life the House is considering #HR7 to prohibit taxpayer funding for abortion. #prolife",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/991695255761244161",
              text: "Your taxpayer dollars should not go to any organization that provides abortions. We must protect all human life. I signed a letter to HHS to stop funding for programs where abortion is considered a part of family planning. \n\nfoxnews.com/politics/2018/\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1083499955686440960",
              text: "Today, I introduced my first bill in the United States Senate, S. 105, the Title X Abortion Provider Prohibition Act, which strips all abortion providers, including Planned Parenthood, of federal funding. pic.twitter.com/UwtbwGt1wd",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/630779302032838656",
              text: "Planned Parenthood\u00e2\u20ac\u2122s taxpayer funded assault on the unborn must be stopped. Here\u00e2\u20ac\u2122s my op-ed in @thedailyherald: http://bit.ly/1EjULx6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/637287184383541248",
              text: "#PlannedParenthood does not need or deserve over $500 million in taxpayer dollars. Women have real choices. pic.twitter.com/8FYHQl87OQ",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1084959350383362050",
              text: "Working together, we can pass legislation to protect life. Ahead of this year\u00e2\u20ac\u2122s #MarchForLife, 22 Senators are co-sponsoring my bill to strip abortion providers of federal funding under Title X of the Public Health Service Act. #WhyWeMarch",
            },
          ],
          question:
            "Are there healthcare policies you support to provide alternatives to abortion?",
        },
      ],
      summary:
        "Marsha Blackburn holds a conservative stance on the topic of abortion. She strongly believes in protecting all human life at every stage, and thus opposes abortion. Blackburn has publicly supported and sponsored legislation aimed at restricting the practice, particularly for late-stage abortions. She introduced the Title X Abortion Provider Prohibition Act, which is an act to strip all abortion providers, including Planned Parenthood, of federal funding. Additionally, she supports the Born-Alive Abortion Survivors Protection Act to ensure proper care for babies born from unsuccessful abortions. To provide alternatives to abortion, she advocates for healthcare policies that divert public funds away from organizations providing abortion services.",
    },
    "Civil Liberties": {
      questions: [
        {
          answer:
            "I am working to ensure that a basic privacy and data security standard is in place to protect individual civil liberties, including freedom of speech and privacy. I am leading a bipartisan effort to shed light on the need to protect competition and online privacy to keep up with the fast pace of changes in technology. I am also leading the charge to protect consumers' online privacy and holding Big Tech accountable. We must ensure that the protections that exist in the physical world are transferred to social media and that these companies begin policing illegal online behavior. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1176526955337981952",
              text: "It is important to exercise some preemptions so you are not looking at fifty different standards, but you have a basic privacy standard to make certain that this virtual space is competitive and that innovation continues.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/603962137422262272",
              text: "American taxpayers deserve to know that their personal information is secure from data breaches. http://tinyurl.com/pnwpb37",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1115368857764544512",
              text: "My hope is that through this bipartisan effort we will shed light on the need to protect competition and online privacy to keep up with the fast pace of changes in technology.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1328757522430357504",
              text: "Free speech is the foundation of American democracy. In a nation where we cherish our right to free speech, we cannot allow a few big tech CEOs to unilaterally decide what we can read or write online.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/870273352396656640",
              text: "Happy to be leading the charge to protect consumers' online privacy.  http://www.bostonglobe.com/business/2017/05/31/republican-revives-internet-privacy-debate/QpIUwQByR80fXfzTwOkVeI/story.html?event=event25 via @BostonGlobe",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1154484474509484033",
              text: "If the service is free, you are the product.\n\nSocial media companies are elaborate advertising agencies. The more data they collect, the more money they make.\n\nAs chair of @senjudiciary\u00e2\u20ac\u2122s Tech Task Force, I\u00e2\u20ac\u2122m working to make sure your privacy is protected. Watch @BloombergTV https://t.co/MYQ288huTc",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1068222465141366784",
              text: "A light-touch #privacy regulatory system is the only way to ensure America continues to lead the world in technology. We must get this done. video.foxbusiness.com/v/597308300500\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "What safeguards will you put in place to protect individual civil liberties, including freedom of speech and privacy?",
        },
        {
          answer:
            "I am leading a real discussion on data privacy protection at today's @senjudiciary Tech Task Force meeting. We need to make it very clear what Silicon Valley can do with your data- putting you, the user, in the driver's seat. I have introduced legislation with @SenatorWicker to empower consumers to determine how their data is used. We must exercise preemptions to ensure a basic privacy and data security standard, so the virtual space is competitive and consumers are protected. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1115368857764544512",
              text: "My hope is that through this bipartisan effort we will shed light on the need to protect competition and online privacy to keep up with the fast pace of changes in technology.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1116779964655915010",
              text: "Great to meet with you and discuss how we can work together to enact the most effective privacy legislation while maintaining an innovative economy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1273018994048581639",
              text: "The era of Silicon Valley self-regulation is over. Consumers deserve to know when Big Tech is spying over their shoulders and abusing their trust. Legislation I introduced with @SenatorWicker will empower consumers to determine how their data is used.\n \nwsj.com/articles/once-\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/982001316363939841",
              text: "We need to make it very clear what Silicon Valley can do with your data- putting you, the user, in the driver's seat. Americans are aware now more than ever how Facebook and other Tech companies have been data mining their info, and want it to stop. You should have the choice. https://twitter.com/FoxBusiness/status/981993349396750336",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/870273352396656640",
              text: "Happy to be leading the charge to protect consumers' online privacy.  http://www.bostonglobe.com/business/2017/05/31/republican-revives-internet-privacy-debate/QpIUwQByR80fXfzTwOkVeI/story.html?event=event25 via @BostonGlobe",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1176557063717232640",
              text: "As chair of @senjudiciary\u00e2\u20ac\u2122s Tech Task Force, I\u00e2\u20ac\u2122m working to ensure: \n\n\u00e2\u0153\u2026 a basic privacy and data security standard \n\n\u00e2\u0153\u2026 the virtual space is competitive \n\n\u00e2\u0153\u2026 consumers are protected \n\n@CNBC @SquawkCNBC https://t.co/BC4zW8a9Lb",
            },
          ],
          question:
            "How do you plan to address concerns about government surveillance and data privacy?",
        },
        {
          answer:
            "Absolutely. I have been leading the charge to protect consumers' online privacy and have been working with my colleagues on solutions to protect against attacks from cyber terrorists. As chair of the Senate Judiciary's Tech Task Force, I'm working to ensure a basic privacy and data security standard, that the virtual space is competitive, and that consumers are protected. I have also been pushing for privacy protections no matter where you go online, and have cosponsored a bill to update the law to protect Americans' constitutional rights. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1115368857764544512",
              text: "My hope is that through this bipartisan effort we will shed light on the need to protect competition and online privacy to keep up with the fast pace of changes in technology.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/870273352396656640",
              text: "Happy to be leading the charge to protect consumers' online privacy.  http://www.bostonglobe.com/business/2017/05/31/republican-revives-internet-privacy-debate/QpIUwQByR80fXfzTwOkVeI/story.html?event=event25 via @BostonGlobe",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1176557063717232640",
              text: "As chair of @senjudiciary\u00e2\u20ac\u2122s Tech Task Force, I\u00e2\u20ac\u2122m working to ensure: \n\n\u00e2\u0153\u2026 a basic privacy and data security standard \n\n\u00e2\u0153\u2026 the virtual space is competitive \n\n\u00e2\u0153\u2026 consumers are protected \n\n@CNBC @SquawkCNBC https://t.co/BC4zW8a9Lb",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/725682659570126848",
              text: "This bill updates the law to protect Americans\u00e2\u20ac\u2122 constitutional rights. Pleased to have been an original cosponsor. http://ow.ly/4nc8xX",
            },
          ],
          question:
            "Are there initiatives to strengthen civil liberties protections in the digital age that you support?",
        },
      ],
      summary:
        "Marsha Blackburn is strongly in favor of safeguarding individual civil liberties, including freedom of speech and privacy, particularly in the digital space. She is committed to establishing basic privacy and data security standards, pushing for transparency and accountability from big tech companies to protect online privacy and advocating for competition in virtual spaces. Blackburn is the head of the Senate Judiciary's Tech Task Force, which is working towards such outcomes. She strongly believes in user control over how their data is used and is willing to actively legislate to uphold these principles. Furthermore, she is involved in initiatives to strengthen civil liberties protections in the digital age. These include protection against cyber terrorists and reforming outdated laws to better protect constitutional rights.",
    },
    "Civil Rights": {
      questions: [
        {
          answer:
            "I believe that all Americans should be treated with respect and dignity, regardless of their sexual orientation or gender identity. I will continue to work to ensure that all Americans have the same rights and protections under the law. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/614465457937088513",
              text: "I'll continue to work to ensure our religious beliefs are protected & ppl of faith aren't punished for their beliefs: http://1.usa.gov/1KfVAMP",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/688480927161384960",
              text: "Our laws should encourage & support citizens who seek to adhere to their moral convictions #ReligiousFreedomDay. RT if you agree.",
            },
          ],
          question:
            "What actions would you take to advance LGBTQ+ rights and protections against discrimination?",
        },
        {
          answer:
            "We must work to ensure that everyone has access to the same opportunities, regardless of race or gender. This means that we must work to eliminate any laws or policies that create barriers to economic development or access to housing. We must also ensure that women and girls are given the same opportunities as men and boys, and that they are not held back by outdated stereotypes or biases. We must also continue to speak out against racism, white supremacy, and neo-Nazism, and ensure that our civil society is one that is inclusive and equitable. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1152284023261224960",
              text: "Putting women & girls front and center is vital to overcoming worldwide poverty. It was great to meet with @CARE ambassadors @Kimwilliamspais & @RitaAngelTaylor to discuss the important work they are doing to improve education, health care and economic opportunity for women! pic.twitter.com/35FkLyAfCV",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1101208413520973824",
              text: "As a female, you have to work twice as hard & prove yourself twice as capable in order to be considered for the same position. I am grateful for Ms. Neomi Rao's diligence, preparation, and commitment to her service to our nation. My full remarks: bit.ly/2HcQ6YW",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1295688141416742913",
              text: "Today we honor those Americans who spent decades in relentless pursuit of equality. Women have played a critical role in shaping our country, and we\u00e2\u20ac\u2122re going to keep at it! https://t.co/oKmD114GYn",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1158801555522408453",
              text: "We should not have laws in place that hinder, rather than help, economic development. This legislation will increase rural communities\u00e2\u20ac\u2122 access to better economic opportunities and infrastructure projects.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/897122116323213312",
              text: "Racism, white supremacy, and neo-Nazism have no place in civil society. We must learn from history, not repeat it. pic.twitter.com/6gDM1Y0x3Z",
            },
          ],
          question:
            "How can you promote racial and gender equality in various sectors, including employment and housing?",
        },
        {
          answer:
            "I believe that protecting the integrity of our elections is of the utmost importance. That is why I have supported legislation that requires states to use paper ballots and conduct post-election audits to ensure the accuracy of the results. I have also supported legislation that requires states to provide free voter registration and early voting opportunities. Additionally, I have supported legislation that requires states to provide language assistance to voters who are not proficient in English. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1227330683284213760",
              text: "Securing our elections has bipartisan consensus. But why should we make the electoral process more vulnerable to attack by redirecting resources to support groups like the Iowa Democratic Party? https://t.co/NBOyoTEzyw",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1309141746664824832",
              text: "The American people rejected radical left policies at the ballot box.\n\nNow, Democrats want to overrule the American people by restructuring the Supreme Court.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1188973017914925062",
              text: ".@BillPascrell you mean my bill on the 100th anniversary of women\u00e2\u20ac\u2122s suffrage, which granted us the right to vote?\n\nWe appreciate you sitting in that chair & banging that gavel \u00e2\u20ac\u201d took a lot of effort.\n\nNever argue with a woman & don\u00e2\u20ac\u2122t tell us how to multitask. cc: @KellyannePolls",
            },
          ],
          question:
            "What policies do you support to protect voting rights and access to the ballot?",
        },
      ],
      summary:
        "Based on the provided Q&A, Marsha Blackburn positions and views on Civil Rights can be summarized as follows:\n\n1. LGBTQ+ Rights: Blackburn believes in the equal treatment of all Americans, including those in the LGBTQ+ community. She is dedicated to making sure everyone is respected and protected under the law regardless of sexual orientation or gender identity.\n\n2. Racial and Gender Equality: Blackburn advocates eliminating laws that block economic and housing opportunities due to a person's race or gender. She encourages giving equal chances to both genders and stands against racism, white supremacy, and neo-Nazism.\n\n3. Voting Rights: Blackburn supports policies to protect the integrity of elections - including the use of paper ballots, post-election audits, free voter registration, and early voting opportunities. She supports providing language assistance to voters who are not proficient in English.",
    },
    Crime: {
      questions: [
        {
          answer:
            "I believe that criminal justice reform should focus on reducing recidivism and breaking the cycle of incarceration. To do this, we must focus on providing meaningful, bipartisan solutions that will make our communities safer and save taxpayers money. This is why I voted for the #FIRSTSTEPAct, which helps break the cycle of incarceration and recidivism, makes our communities safer, and saves taxpayers $128 million a year. We must also ensure that our immigration laws are enforced and that criminal aliens are not given the chance to become repeat offenders. My CLEAR Act will block funding for sanctuary cities and help local law enforcement deport criminal aliens. Finally, we must ensure that our law enforcement agencies are not politicized and that prosecutors are held accountable for their actions. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/998984197598535680",
              text: "I\u00e2\u20ac\u2122m focused on solutions. Today we vote on a meaningful, bipartisan solution to improve our nation\u00e2\u20ac\u2122s prison system. The #FIRSTSTEPAct helps break the cycle of incarceration & recidivism, makes our communities safer, and saves taxpayers $128 million a year. Pass it. https://t.co/3CJJ7f4tRl",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/998984723002286080",
              text: "I\u00e2\u20ac\u2122m focused on solutions. Today we vote on a meaningful, bipartisan solution to improve our nation\u00e2\u20ac\u2122s prison system. The #FIRSTSTEPAct helps break the cycle of incarceration & recidivism, makes our communities safer, and saves taxpayers $128 million a year. Pass it. https://t.co/ZGLNJFZd7i",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/932983646960660480",
              text: "What happens if you want to have a sanctuary city for other parts of federal law evasion? Sanctuary cities are a danger to our communities and cities, and a danger to rule of law. I reintroduced the CLEAR Act, which will block funding for sanctuary cities. http://www.foxnews.com/opinion/2017/11/09/rep-marsha-blackburn-to-save-lives-and-money-must-cut-off-federal-funding-for-sanctuary-cities.html",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/618900774756560896",
              text: "The #CLEARAct would give local law enforcement the tools they need to help the Federal gov't deport criminal aliens. http://1.usa.gov/1Hcco38",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1203443871394549763",
              text: "Jeffrey Epstein first escaped justice when prosecutors, & then prison guards, failed to do their job. IG Horowitz agrees it\u00e2\u20ac\u2122s time to change the law & hold bad prosecutors accountable \u00e2\u20ac\u201d here\u00e2\u20ac\u2122s his response to my letter demanding accountability. My IG Access Act will fix this. pic.twitter.com/5TcZqJsQDc",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1154837680414441478",
              text: "The crisis at our border requires immediate action. We can\u00e2\u20ac\u2122t let traffickers and drug cartels continue to take advantage of our laws. This legislation targets asylum fraud to eliminate loopholes and bring real reforms to our nation\u00e2\u20ac\u2122s immigration policy. bit.ly/2SFP4bI",
            },
          ],
          question:
            "How would you approach criminal justice reform to reduce mass incarceration and address racial disparities?",
        },
        {
          answer:
            "I am committed to ensuring that our communities are safe and secure. To do this, I am working to crack down on sanctuary cities, bridge the broadband gap to ensure connectivity for all, and ensure that local police officers are the ones responding to emergencies. I am also introducing legislation to help builders create communities for us all to thrive in, and I am chairing the Senate Judiciary's Tech Task Force to ensure a basic privacy and data security standard, that the virtual space is competitive, and that consumers are protected. Additionally, I am working to prevent drug traffickers and gang members from trafficking children. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/656217210948571137",
              text: "Here\u00e2\u20ac\u2122s more on what Congress is doing to crack down on sanctuary cities & keep our communities safe --> http://ow.ly/TBiOV",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1182636941810044930",
              text: "In Tipton, Lauderdale and Haywood Counties, I brainstormed ways to continue to bridge the broadband gap to ensure connectivity for all folks, regardless of where they live.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1277326666130325504",
              text: "In an emergency, you want your local police officers to show up \u00e2\u20ac\u201d not the Department of Social Justice. \npic.twitter.com/V63fpg3CUG",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1316395144015421440",
              text: "Calls to defund the police make many Americans nervous. \n\nFamilies no longer feel safe in their homes and neighborhoods. \n\nIt is imperative that we safeguard our right to protect ourselves.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/644576939303403524",
              text: "Visited w/@ApartmentWire to discuss legislation that helps builders create communities for us all to thrive in. pic.twitter.com/cQBrihkw7e",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1176557063717232640",
              text: "As chair of @senjudiciary\u00e2\u20ac\u2122s Tech Task Force, I\u00e2\u20ac\u2122m working to ensure: \n\n\u00e2\u0153\u2026 a basic privacy and data security standard \n\n\u00e2\u0153\u2026 the virtual space is competitive \n\n\u00e2\u0153\u2026 consumers are protected \n\n@CNBC @SquawkCNBC https://t.co/BC4zW8a9Lb",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1171514061894193152",
              text: "Watch below to learn how I\u00e2\u20ac\u2122m working to prevent drug traffickers and gang members from trafficking children. \u00c2\u00a0\u00e2\u00ac\u2021\u00ef\u00b8\u008f pic.twitter.com/oh5tTCSezK",
            },
          ],
          question:
            "What strategies will you employ to enhance community safety while promoting fairness and equity?",
        },
        {
          answer:
            "I'm focused on solutions to improve our nation's prison system and break the cycle of incarceration and recidivism. I've sponsored the CLEAR Act every year since 2007 to combat sanctuary cities and protect innocent citizens from further crimes committed by criminal aliens. I've also introduced bipartisan legislation to combat asylum fraud and protect children arriving at the border from falling victim to human trafficking. Additionally, I've introduced CARA 2.0 to fight the opioid epidemic and increase criminal penalties for manufacturers. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/880202909178318849",
              text: "Our immigration laws must be enforced. Criminal aliens should not have the chance to become repeat offenders. https://judiciary.house.gov/press-release/goodlatte-introduces-bills-combat-sanctuary-cities-enhance-public-safety/",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/644253395432439812",
              text: "These alarming statistics re: criminal illegal aliens are why I\u00e2\u20ac\u2122ve sponsored the #CLEARAct every year since 2007 --> http://ow.ly/Sj9f3",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/750394149782913025",
              text: "It\u00e2\u20ac\u2122s imperative we enact the CLEAR Act to protect Americans from further crimes committed by criminal aliens. http://ow.ly/igp3301Wwqt",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1171409730176258048",
              text: ".@SenJoniErnst and I have introduced legislation to combat asylum fraud and protect children arriving at the border from falling victim to human trafficking.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/932983646960660480",
              text: "What happens if you want to have a sanctuary city for other parts of federal law evasion? Sanctuary cities are a danger to our communities and cities, and a danger to rule of law. I reintroduced the CLEAR Act, which will block funding for sanctuary cities. http://www.foxnews.com/opinion/2017/11/09/rep-marsha-blackburn-to-save-lives-and-money-must-cut-off-federal-funding-for-sanctuary-cities.html",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1154837680414441478",
              text: "The crisis at our border requires immediate action. We can\u00e2\u20ac\u2122t let traffickers and drug cartels continue to take advantage of our laws. This legislation targets asylum fraud to eliminate loopholes and bring real reforms to our nation\u00e2\u20ac\u2122s immigration policy. bit.ly/2SFP4bI",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/974378254839795713",
              text: "Just introduced bipartisan CARA 2.0 as the lead sponsor. The legislation authorizes sweeping resources to fight the opioid epidemic & increases criminal penalties for manufacturers. My heart breaks for families that have been torn apart by the opioid scourge. We're taking action! https://t.co/XcZGs7nkke",
            },
          ],
          question:
            "Are there specific initiatives for addressing the root causes of crime that you champion?",
        },
      ],
      summary:
        "Marsha Blackburn believes in focusing on reducing recidivism and breaking the cycle of incarceration through meaningful, bipartisan solutions such as the #FIRSTSTEPAct. She is proponent of strict immigration laws and has put forth the CLEAR Act to block funding for sanctuary cities and help local law enforcement deport criminal aliens.\n\nShe champions the safety and security of communities, focusing on decreasing sanctuary cities, ensuring responsive local police forces, enhancing broadband connectivity, introducing legislation to promote thriving communities, and chairing the Senate Judiciary's Tech Task Force for privacy and data security standard.\n\nBlackburn also works towards addressing root causes of crime, having been involved in consistent efforts to combat sanctuary cities and protect citizens from further crimes committed by criminal aliens through the CLEAR Act since 2007. She has introduced legislation to challenge asylum fraud, protect children from human trafficking at borders, tackle the opioid epidemic, and impose increased criminal penalties for drug manufacturers.",
    },
    Education: {
      questions: [
        {
          answer:
            "I am committed to improving the quality of public education and increasing access to higher education. I have supported legislation such as the Higher Education Reform and Opportunity Act, which will push intuitions to be innovative, transparent, and accountable. I have also co-led an effort to ensure our next generation of leaders has the cyber security and computer science skills necessary to compete and win in the 21st century. Additionally, I am cosponsoring the CHOICE Act with @SenatorTimScott to give families the freedom to choose the best education for their children. Finally, I am working with @realDonaldTrump on rural broadband expansion to close the digital divide and ensure our higher-education students are offered a fair playing field. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1156551018169212928",
              text: "The Higher Education Reform and Opportunity Act will push intuitions to be innovative, transparent, and accountable. Glad to join @SenMikeLee in supporting this legislation.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1270084445953904641",
              text: "We\u00e2\u20ac\u2122re co-leading this effort to ensure our next generation of leaders has the cyber security and computer science skills necessary to compete and win in the 21st century.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1087789646162083840",
              text: "It\u00e2\u20ac\u2122s important to give families the freedom to choose the best education for their children. That is why during this year\u00e2\u20ac\u2122s #NationalSchoolChoiceWeek, I am cosponsoring the CHOICE Act with @SenatorTimScott.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1201290632007864320",
              text: "Millions of rural Americans lack access to high speed internet. My bipartisan Internet Exchange Act will help bridge the digital divide by offsetting the costs of establishing and maintaining broadband connections. https://t.co/qBtPIgVP2h",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/950400570749276160",
              text: "We can't have a first world economy with a third world internet. 39% of Americans in remote areas don't have access to quality internet. I look forward to working with @realDonaldTrump on rural broadband expansion. It is time we close the digital divide. pic.twitter.com/8dqRH7GVqU",
            },
          ],
          question:
            "How will you improve the quality of public education and increase access to higher education?",
        },
        {
          answer:
            "I believe that we need to ensure our higher-education students are offered a fair playing field. We must make sure that our colleges and universities control tuition costs and prepare students to successfully enter the job market. We also need to take a hard look at our budget and find ways to cut spending and grow our way out of this crisis. We must be targeted in our approach and not pick winners and losers. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/747871829394661377",
              text: "As if 19 trillion in debt is not enough of a burden for this Administration to pass on to young Americans. http://bit.ly/298uJHF",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1156551017292554240",
              text: "Our colleges and universities need to make sure that they control tuition costs and prepare students to successfully enter the job market.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/976834598993907714",
              text: "We have Republicans in the House, the Senate, and the White House. It's time we start to show some fiscal restraint. Our national debt is growing out of control, and we can't continue to pass these massive spending bills & expect future generations to deal with our negligence. https://t.co/iwPhSJMbW0",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1259853753991278592",
              text: "The federal government will borrow a record $4.5 trillion this year alone. By 2030, we will spend $819 billion per year on national debt interest payments. We must recognize the sheer debt and interest we have incurred and what it\u00e2\u20ac\u2122s costing our children. pic.twitter.com/nKlWC0iCuP",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1005201807650508805",
              text: "The greatest threat to our national security is our nation's debt. For our children & grandchildren's sake, we must find more ways to grow & cut our way out of this crisis. That's why I propose a 1% across-the-board spending cut. Surely we can cut one penny out of every dollar. https://t.co/Y1VIXw0V7U",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1239191781754929153",
              text: "When we consider temporary ways to help Americans confront the challenges ahead, our approach *must be* targeted. We cannot pick winners and losers. https://t.co/7eVrs9tYhH",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/580896004989329408",
              text: "Our budget takes solid step toward fiscal responsibility for our nation, both for today & future generations. http://goo.gl/0rfyem",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/984444946307526656",
              text: "Today I will vote YES on a Balanced Budget Amendment to the Constitution. Tennesseans know the greatest threat to our freedom is our nation\u00e2\u20ac\u2122s debt. We owe it to our children and grandchildren to reign in Washington\u00e2\u20ac\u2122s out-of-control spending.",
            },
          ],
          question:
            "What measures do you endorse to address the issue of student loan debt?",
        },
        {
          answer:
            "I prioritize policies that ensure our higher-education students are offered a fair playing field, push intuitions to be innovative, transparent, and accountable, control tuition costs and prepare students to successfully enter the job market, give families the freedom to choose the best education for their children, ensure our schools, our society and our children are safe, and instill a passion for science at a young age. I am proud to have cosponsored the Higher Education Reform and Opportunity Act with @SenMikeLee, the CHOICE Act with @SenatorTimScott, and the Building Blocks of STEM Act with @SenateCommerce. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/775717234983469056",
              text: "Director Vedder is right- we must ensure our higher-education students are offered a fair playing field.  http://thehill.com/regulation/295531-for-profit-schools-lash-out-at-administration",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1156551018169212928",
              text: "The Higher Education Reform and Opportunity Act will push intuitions to be innovative, transparent, and accountable. Glad to join @SenMikeLee in supporting this legislation.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1156551017292554240",
              text: "Our colleges and universities need to make sure that they control tuition costs and prepare students to successfully enter the job market.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1087789646162083840",
              text: "It\u00e2\u20ac\u2122s important to give families the freedom to choose the best education for their children. That is why during this year\u00e2\u20ac\u2122s #NationalSchoolChoiceWeek, I am cosponsoring the CHOICE Act with @SenatorTimScott.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/968969114915147783",
              text: "Thank you @realDonaldTrump for your leadership during a productive bipartisan discussion with lawmakers regarding what we can do to ensure our schools, our society and our children are safe. https://t.co/djOYquNgq4",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1105494819885977600",
              text: "Instilling a passion for science at a young age is critical for a child\u00e2\u20ac\u2122s long-term STEM education and overall attitude toward learning. Glad to cosponsor the bipartisan Building Blocks of STEM Act: bit.ly/2TDP6E4 @SenateCommerce",
            },
          ],
          question:
            "Are there policies to support teachers and improve educational outcomes that you prioritize?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to improving the quality of and access to public education and higher education. She supports legislation like the Higher Education Reform and Opportunity Act designed to push educational institutions to be innovative, transparent, and accountable. She prioritizes closing the digital divide by expanding rural broadband access, cosponsoring the CHOICE Act to offer families greater freedom in educational choices, and encouraging cyber security and computer science skills in students. \n\nRegarding student loan debt, she emphasizes the urgent need for colleges and universities to control tuition costs and effectively prepare students for the job market. She is in favor of reducing spending and adopting a balanced approach. \n\nShe has also shown her commitment towards supporting teachers and improving educational outcomes by prioritizing policies that provide equal educational opportunities, promote innovation and transparency, and prepare students for a competitive job market.  Marsha Blackburn has partnered with other senators to sponsor laws such as the Building Blocks of STEM Act which seeks to instill early passion for science among children.",
    },
    "Environmental and Climate Policy": {
      questions: [
        {
          answer:
            "I believe that we must take a comprehensive approach to addressing climate change and promoting sustainability. We must invest in clean energy sources, such as solar and wind, and promote energy efficiency. We must also work to reduce emissions and protect our environment. Additionally, we must ensure that our next generation of leaders has the cyber security and computer science skills necessary to compete and win in the 21st century. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/733005820607234050",
              text: "The challenges we face & solutions we must provide to bring economic, retirement & nat'l security to all Americans. http://ow.ly/I5kC300l6VG",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/842446957947817991",
              text: "The Subcommittee on Environment held a hearing on clean drinking water today. Watch my opening remarks here. -> https://www.youtube.com/watch?v=qj6DwcinbHg&feature=youtu.be",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1270084445953904641",
              text: "We\u00e2\u20ac\u2122re co-leading this effort to ensure our next generation of leaders has the cyber security and computer science skills necessary to compete and win in the 21st century.",
            },
          ],
          question:
            "How will you address climate change and promote sustainability?",
        },
        {
          answer:
            "We need to focus on realistic fuel economy standards and harmonization of standards. We must also reduce taxation, regulation and litigation, and have a pro-American trade policy. We should also reassess the Obama administration's rushed midnight rule on the national program and the unrealistic and harmful 2025 emissions standards. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/981180306010013696",
              text: "If fuel economy policies force auto manufacturers to produce vehicles that customers do not want or cannot afford, no one wins.  We need realistic fuel economy standards, and harmonization of standards. Thank you, @EPAScottPruitt, for leading on this issue https://www.epa.gov/regulations-emissions-vehicles-and-engines/midterm-evaluation-light-duty-vehicle-greenhouse-gas#final",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1095843718689685505",
              text: "The #GreenNewDeal in a few easy steps: 1) end air travel completely, 2) eliminate the jobs of every miner, oil rigger, cattle producer and gas-station attendant, 3) pay those unwilling to work. #WarOnBusiness",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/940617062409555968",
              text: "I hope that @EPAScottPruitt and @SecElaineChao will do what is right and reassess the Obama administration's rushed midnight rule on the national program and the unrealistic and harmful 2025 emissions standards.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/776438260964794370",
              text: "We need to reduce taxation, regulation and litigation and have a pro-American trade policy. http://www.foxbusiness.com/markets/2016/09/14/ford-moving-all-small-car-production-to-mexico-from-u-s-ceo.html",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/940734597607849984",
              text: "Obama's EPA rushed harmful & unrealistic fuel economy standards out the door days before leaving presidency. These regs compromise choice, safety, & affordability- raising average price of a car by over $3k. We need realistic standards that don't hurt auto industry or consumers pic.twitter.com/yH7G024Fej",
            },
          ],
          question: "Strategies for reducing greenhouse gas emissions?",
        },
        {
          answer:
            "We must ensure that our policies are up to date and reflect the realities of the 21st century. We must also ensure that these policies are not overly burdensome and do not hurt our economy. We must encourage competition and support free market interaction. We must also take action to rein in the EPA's overreach and curb their regulations. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/651427943542095876",
              text: "It\u00e2\u20ac\u2122s time to say #Yes2Energy and update our policies to reflect 21st century realities. --> http://ow.ly/T56Cf http://t.co/CdNWWFsf4o",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/798914415391346688",
              text: "Now is not the time for the Administration to keep pushing its climate change agenda. http://www.reuters.com/article/us-climatechange-accord-kerry-idUSKBN13B0I4",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/793164295416733698",
              text: "We must encourage competition and support free market interaction. http://www.washingtonexaminer.com/groups-energy-policy-for-new-admin-more-power-less-epa/article/2605942",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/694211206358437888",
              text: "The EPA overstepped its original mandate in pursuit of more bureaucratic power & at the cost of our nat'l economy -> http://ow.ly/XO6BD",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/615591187249037314",
              text: "#SCOTUS rules against #EPA power plant regs. Glad they've joined us in taking action to rein in the EPA's overreach. http://on.fb.me/1GLgsHh",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/930836130244722690",
              text: "Just finished a markup on 4 bills at the Energy and Commerce Committee that will roll back harmful Obama-era EPA regulations. One of the bills is for Motorsports! Visit http://MarshaBlackburn.Gov to learn more.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/594218441327902720",
              text: "Supported House passage of FY16 Energy & Water Appropriations Act, protecting taxpayers from DOE regulatory overreach http://goo.gl/SiDUzn",
            },
          ],
          question: "Policies for environmental protection and climate action?",
        },
      ],
      summary:
        "Marsha Blackburn emphasizes a comprehensive, technology-advanced approach to addressing climate change and promoting sustainability, including investing in clean energy sources and reducing emissions. She advocates for realistic fuel economy standards, lesser taxation, regulation, and litigation, and a pro-American trade policy to reduce greenhouse gas emissions. To protect the environment, Marsha proposes modernizing policies, promoting competition, supporting free markets, and reining in overreach by the Environmental Protection Agency. She also underscores the necessity of reducing regulations that may hinder economic growth.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      questions: [
        {
          answer:
            "The United States must remain committed to our allies in Ukraine and continue to support them in the face of Russian aggression. We must also remain vigilant in our efforts to counter Russian disinformation and propaganda. We must also continue to use economic and diplomatic means to pressure Russia to deescalate the conflict and find a peaceful resolution. We must also continue to support Ukraine's sovereignty and territorial integrity. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1202702339116847112",
              text: "When @mfa_russia Minister Sergei Lavrov meets with @SecPompeo in Washington, the first question should be, \u00e2\u20ac\u0153Why is Russia lying about a United States Senator advocating for violence in a foreign country?\u00e2\u20ac\u009d",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1202658259636834306",
              text: "@SASCMajority The crux of the National Defense Strategy crisis is: are we going to commit to countering Russia and China aggression in Great Power Competition, or stay sucked into the Middle East?\n \nLooking forward to more answers in our closed hearing next week.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1186753070400712707",
              text: "We have to be cautious when our partners start seeing Russia as an ally \u00e2\u20ac\u201c Putin does not share the geopolitical interests of the U.S. or our allies.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1215063432824750081",
              text: "The time has come for NATO to become more involved in the region and for our allies to step up and join our maximum pressure campaign against Iran.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1181585626812035073",
              text: "We must be focused on finding a productive path forward to protect our coalition partners and prevent Iran or Russia from filling the space we\u00e2\u20ac\u2122d leave behind.\n\nWatch below @AmericaNewsroom\n\nhttps://t.co/3EzoQ9V1LT",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1184963853723066368",
              text: "Turkey must be held accountable for its destabilization of northern Syria.\n\nISIS must not be allowed to resurge.\n\nThe United States must hold strong and sanctions need to remain on the table. https://t.co/vsAbls3Y0F",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1187130401501995008",
              text: "Syrian Democratic Forces leader General Mazloum has fought on the ground in Syria. That\u00e2\u20ac\u2122s why I\u00e2\u20ac\u2122m joining my colleagues in asking @SecPompeo to expedite a U.S. visa for General Mazloum so we can gain his valuable insights to deescalate conflict and fight the resurgence of ISIS. pic.twitter.com/9pJPHxEDpG",
            },
          ],
          question:
            "How would you approach the ongoing Ukraine conflict, and what role do you believe the United States should play in resolving it?",
        },
        {
          answer:
            "My foreign policy priorities are focused on strengthening our alliances and partnerships, particularly in the Indo-Pacific region. I have introduced the Sister City Transparency Act to assess the extent to which foreign communities could use sister city partnerships to conduct malign activities. I am also pushing for U.S. cabinet-level visits to Taipei, Taiwan's entry into international organizations and meetings, lawful transits of the Taiwan Strait by the U.S. Navy, and regular sale of necessary U.S. defense articles. Additionally, I am calling on the U.S. to combat China's growing influence in International Organizations, and to confront Beijing's undue influence and promote quintessential democratic values across all multilateral institutions. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1296545472325324822",
              text: "Last fall in Somalia, I was able to see first hand how intergovernmental coordination and working with our regional partners can achieve important goals both in the counterterror fight and in future near-peer competition.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1331596670744879105",
              text: "My bill, the Sister City Transparency Act will assess the extent to which foreign communities could use sister city partnerships to conduct malign activities.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1216079167281934336",
              text: "In 2020, I will continue to promote the US-Taiwan relationship by pushing for:\n\n\u00e2\u20ac\u201dU.S. cabinet-level visits to Taipei \n\n\u00e2\u20ac\u201dTaiwan\u00e2\u20ac\u2122s entry into international organizations & meetings \n\n\u00e2\u20ac\u201d@USNavy lawful transits of the Taiwan Strait\n\n\u00e2\u20ac\u201dRegular sale of necessary US defense articles",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1299059071006519297",
              text: "China\u00e2\u20ac\u2122s growing influence in International Organizations is worrisome as each new seat it gains is another avenue for the #CCP to sway outcomes and shift global opinions. I\u00e2\u20ac\u2122ve called on the US to combat this concerning trend. blackburn.senate.gov/chinawhitepaper",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1325832515555299328",
              text: "We must\u00c2\u00a0confront this\u00c2\u00a0undue Chinese influence and promote quintessential democratic values\u00c2\u00a0across all multilateral institutions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1325832514066403329",
              text: "Despite being a leading offender of human rights, Beijing has managed to have a strong influence at international organizations like the @UN, bolstering the CCP\u00e2\u20ac\u2122s global diplomatic influence.",
            },
          ],
          question:
            "What are your foreign policy priorities, particularly in relation to allies and international organizations?",
        },
        {
          answer:
            "I will continue to promote the US-Taiwan relationship by pushing for U.S. cabinet-level visits to Taipei, Taiwan's entry into international organizations and meetings, lawful transits of the Taiwan Strait by the U.S. Navy, and regular sale of necessary U.S. defense articles. I also support the USMCA, which will ensure a level playing field for American workers and create over 176,000 new American jobs. I am also pushing for my bipartisan SAM-C legislation to secure our medical supply chains from Beijing's grasp. I believe in reducing taxation, regulation and litigation and having a pro-American trade policy. We must also ensure that we meet the needs for expanding broadband in our communities while also protecting our networks from foreign adversaries. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1305951531821420545",
              text: "A bilateral trade agreement would bring the U.S. and Taiwan closer together, and it would allow us to be unified in unraveling our ties with Beijing. Thank you, @bikhim, for joining me for a great conversation. @TECRO_USA https://t.co/Dry1GP8pTT",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1216079167281934336",
              text: "In 2020, I will continue to promote the US-Taiwan relationship by pushing for:\n\n\u00e2\u20ac\u201dU.S. cabinet-level visits to Taipei \n\n\u00e2\u20ac\u201dTaiwan\u00e2\u20ac\u2122s entry into international organizations & meetings \n\n\u00e2\u20ac\u201d@USNavy lawful transits of the Taiwan Strait\n\n\u00e2\u20ac\u201dRegular sale of necessary US defense articles",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1222582362451664896",
              text: "Earlier today, @realDonaldTrump signed the historic #USMCA into law. The deal will ensure a level playing field for American workers. https://t.co/5BSAG3RVkU",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/776438260964794370",
              text: "We need to reduce taxation, regulation and litigation and have a pro-American trade policy. http://www.foxbusiness.com/markets/2016/09/14/ford-moving-all-small-car-production-to-mexico-from-u-s-ceo.html",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1303785487895875584",
              text: "We must unravel our relationship with Communist China. Bringing our manufacturing back to America is a good first step, and why I\u00e2\u20ac\u2122ve been pushing for my bipartisan SAM-C legislation to secure our medical supply chains from Beijing\u00e2\u20ac\u2122s grasp. https://t.co/ava3GvMLgk",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1171145217497608193",
              text: "The #USMCA is a win for American workers, manufacturers, farmers and ranchers, and will help create over 176,000 new American jobs.\n \nWith tremendous bipartisan support, @realDonaldTrump is doing trade the RIGHT way!\n \n@FoxNews @SteveHiltonx https://t.co/B6v0RSahci",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1159549452169781248",
              text: "The #USMCA is a win for American workers, manufacturers, farmers, and ranchers. It will result in 176,000 new American jobs and a $68 billion boost to our GDP. Watch below \u00e2\u00ac\u2021\u00ef\u00b8\u008f https://t.co/hREOHC6Kud",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1222526985290776576",
              text: "The #USMCA updates a trade deal that pre-dates the existence of smartphones. \n\nIn 2018, Tennessee exported $12.7 billion to Canada and Mexico, and the USMCA will cut red tape, allowing small businesses to tap into this market.",
            },
          ],
          question:
            "How will you navigate trade agreements and global economic relationships to benefit American interests?",
        },
        {
          answer:
            "As a Senator, I am committed to promoting diplomatic solutions to international conflicts. We must confront undue Chinese influence and promote democratic values across all multilateral institutions. We must also prioritize resourcing and readiness in the Indo-Pacific theater to confront the next generation of threats. Additionally, I am working to ensure that the US makes good on its commitments to the Kurdish people and to find a mutually agreeable solution. Finally, I am pushing for US cabinet-level visits to Taipei, Taiwan's entry into international organizations and meetings, and regular sale of necessary US defense articles. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1202658259636834306",
              text: "@SASCMajority The crux of the National Defense Strategy crisis is: are we going to commit to countering Russia and China aggression in Great Power Competition, or stay sucked into the Middle East?\n \nLooking forward to more answers in our closed hearing next week.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1325832515555299328",
              text: "We must\u00c2\u00a0confront this\u00c2\u00a0undue Chinese influence and promote quintessential democratic values\u00c2\u00a0across all multilateral institutions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1186411341281660929",
              text: "She is a fierce advocate for the Kurdish people and wants to see the U.S. makes good on its commitments. I look forward to continuing this dialogue to find a mutually agreeable solution.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1216079167281934336",
              text: "In 2020, I will continue to promote the US-Taiwan relationship by pushing for:\n\n\u00e2\u20ac\u201dU.S. cabinet-level visits to Taipei \n\n\u00e2\u20ac\u201dTaiwan\u00e2\u20ac\u2122s entry into international organizations & meetings \n\n\u00e2\u20ac\u201d@USNavy lawful transits of the Taiwan Strait\n\n\u00e2\u20ac\u201dRegular sale of necessary US defense articles",
            },
          ],
          question:
            "What approach will you take to address international conflicts and promote diplomacy?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to supporting Ukraine against Russian aggression through continued vigilance in countering disinformation and applying economic and diplomatic pressure on Russia to deescalate the conflict. She underscores the importance of upholding Ukraine's territorial integrity and sovereignty.\n\nHer foreign policy priorities include strengthening alliances, specifically in the Indo-Pacific region. She actively promotes the US-Taiwan relationship and has introduced legislation, such as the Sister City Transparency Act, to protect against further malign activities. Blackburn is engaged in confronting China's influence within international organizations and is pushing for promoting democratic values.\n\nAs it relates to trade agreements, Blackburn emphasizes the need for a pro-American trade policy that reduces taxation, regulation, and litigation. She supports the USMCA, which she believes will support American workers and job creation. She's also focused on securing American medical supply chains, as seen with her bipartisan SAM-C legislation.\n\nRegarding international conflicts, the Senator prioritizes diplomatic solutions and readiness in the Indo-Pacific to confront evolving threats. She is committed to keeping the US's commitments to the Kurdish people and supports active diplomatic engagement, including cabinet-level visits in Taipei and advocating for the country's entry into international meetings and organizations.",
    },
    Guns: {
      questions: [
        {
          answer:
            "We need to examine policies that address both gun violence and mental illness. We must ensure that those who are a danger to themselves or others do not have access to guns or any type of weapon. We must also ensure that we safeguard our right to protect ourselves and our families. We need to provide law enforcement officers with more training and resources to protect our children. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1169608363463888904",
              text: "No one wants a person who is a danger to themselves or others to have a gun or any weapon. That is why we have to examine policies to address gun violence and mental illness. https://t.co/nWqdIugkdn",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/684343834776592384",
              text: "Do you agree w/the President on restricting gun rights or do you believe these actions should be left to Congress? http://ow.ly/WDkzl",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1172257132005208064",
              text: "Keeping Americans safe is priority #1. Congress & the administration agree: it is time to examine how we will address gun violence and mental illness.\n \nMore from my interview with @HarrisFaulkner on @OutnumberedOT https://t.co/ovxfRt4O3R",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1174791050403155968",
              text: "No one wants a person who is a danger to themselves or others to have a gun or any type of weapon. More from my interview with @ShannonBream on @foxnewsnight: https://t.co/NMVcwiSf6o",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1316395144015421440",
              text: "Calls to defund the police make many Americans nervous. \n\nFamilies no longer feel safe in their homes and neighborhoods. \n\nIt is imperative that we safeguard our right to protect ourselves.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1286793414881705984",
              text: "Radical left wing efforts to defund and remove school police officers are dangerous and reckless. Law enforcement officers need more training and more resources to protect our children. foxnews.com/us/school-reso\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "What gun control measures do you believe will be effective in reducing gun violence?",
        },
        {
          answer:
            "We must ensure that our Second Amendment rights are protected while also addressing gun violence and mental illness. We need to examine policies that will help us to do both. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1320500148355637249",
              text: "The Second Amendment recognizes the legal and constitutional right to own a gun. Judge Barrett will uphold that right. pic.twitter.com/mSXm6z0pMI",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1308821983170355200",
              text: "Without a conservative majority on the Supreme Court, our 2nd amendment rights, our religious freedom and the rule of law are at risk.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1169608363463888904",
              text: "No one wants a person who is a danger to themselves or others to have a gun or any weapon. That is why we have to examine policies to address gun violence and mental illness. https://t.co/nWqdIugkdn",
            },
          ],
          question:
            "How do you plan to balance Second Amendment rights with public safety concerns?",
        },
        {
          answer:
            "Absolutely. I believe that responsible gun ownership is essential to protecting our Second Amendment rights. We must ensure that those who are a danger to themselves or others do not have access to firearms. We must also ensure that our laws encourage and support citizens who seek to adhere to their moral convictions. That is why I have supported legislation to promote responsible gun ownership and combat the opioid epidemic. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1169608363463888904",
              text: "No one wants a person who is a danger to themselves or others to have a gun or any weapon. That is why we have to examine policies to address gun violence and mental illness. https://t.co/nWqdIugkdn",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/684343834776592384",
              text: "Do you agree w/the President on restricting gun rights or do you believe these actions should be left to Congress? http://ow.ly/WDkzl",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1172257132005208064",
              text: "Keeping Americans safe is priority #1. Congress & the administration agree: it is time to examine how we will address gun violence and mental illness.\n \nMore from my interview with @HarrisFaulkner on @OutnumberedOT https://t.co/ovxfRt4O3R",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1308821983170355200",
              text: "Without a conservative majority on the Supreme Court, our 2nd amendment rights, our religious freedom and the rule of law are at risk.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/684902407625662464",
              text: "The President's executive actions will not solve gun violence. I discussed w/@CBSNLive what needs to be done -> http://ow.ly/WIUWj",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/688480927161384960",
              text: "Our laws should encourage & support citizens who seek to adhere to their moral convictions #ReligiousFreedomDay. RT if you agree.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/730073104551161856",
              text: "House Republicans are working diligently to combat the Opioid epidemic. No community is immune; we must take action. http://ow.ly/mU8p30053HX",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/647835612372971520",
              text: "Today in the @HouseGOP Weekly Address, I outline what we're doing to defend our unborn. RT if you support life. --> http://ow.ly/SHwvC",
            },
          ],
          question:
            "Are there policies to promote responsible gun ownership that you support?",
        },
      ],
      summary:
        "Marsha Blackburn supports responsible gun ownership and believes in preserving Second Amendment rights. She takes a balanced standpoint by endorsing policies that prevent people who pose a threat to themselves or others from gaining access to firearms. At the same time, she is keen to safeguard citizens' right to protect their families. She believes in providing additional training and resources to law enforcement to better protect communities, and supports legislation that promotes safe gun ownership. Additionally, she also advocates addressing mental illness to curb gun violence.",
    },
    Healthcare: {
      questions: [
        {
          answer:
            "My three-Bill Rural Healthcare Agenda will increase access to affordable healthcare for millions of Americans who live in rural communities and expand telehealth services for all Americans. This will remain a priority in the next Congress. We must also protect individual rights and prevent a socialist takeover of health care. My legislation will lower the cost of health insurance, save taxpayer $$ and increase transparency. We must also repeal Obamacare and encourage the use of innovative products, such as telehealth. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1343994895086792704",
              text: "My three-Bill Rural Healthcare Agenda will increase access to affordable healthcare for millions of Americans who live in rural communities and expand telehealth services for all Americans. This will remain a priority in the next Congress. https://t.co/vpwLH5HmRJ",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/684036288681521153",
              text: "We cannot continue to go down this road. It's our priority to make healthcare affordable for all Americans. http://ow.ly/WBnpl",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/748211579053813760",
              text: "Here's how my legislation will lower the cost of health insurance, save taxpayer $$ and increase transparency. http://ow.ly/qSYf301LjJg",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/619546573710737408",
              text: "Landmark legislation that encourages healthcare innovation & improves the lives of all Americans passed the House! -> http://1.usa.gov/1LWoClK",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1274041367463780352",
              text: "Americans should be able to receive medical care without having to risk exposure to COVID-19 in a doctor\u00e2\u20ac\u2122s office or hospital. @SenTedCruz and I are introducing the Equal Access to Care Act to extend the availability of telehealth and remove unnecessary bureaucratic red tape.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1180176363241852928",
              text: "While Democrats in Congress want to put 60 million seniors\u00e2\u20ac\u2122 healthcare in jeopardy with radical proposals like Medicare for All, @POTUS\u00e2\u20ac\u2122 executive order on Medicare will give seniors more choice. It encourages the use of innovative products, such as telehealth.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/684737436010819584",
              text: "Not only will today's measure to repeal Obamacare pave the way to affordable healthcare, it will cut our nat'l debt http://ow.ly/WHt4i",
            },
          ],
          question:
            "What steps will you take to make healthcare accessible to all Americans, regardless of income?",
        },
        {
          answer:
            "I'm committed to making healthcare more affordable for all Americans. My legislation will lower the cost of health insurance, increase transparency, and save taxpayer money. I'm also working to repeal Obamacare and replace it with a plan that will cut our national debt and pave the way to affordable healthcare. Additionally, I'm introducing a three-Bill Rural Healthcare Agenda to increase access to affordable healthcare for millions of Americans who live in rural communities and expand telehealth services for all Americans. Finally, I'm holding the Administration accountable for unlawful Obamacare Cost-Sharing Reduction payments and preventing fraud within our healthcare system. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/748211579053813760",
              text: "Here's how my legislation will lower the cost of health insurance, save taxpayer $$ and increase transparency. http://ow.ly/qSYf301LjJg",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/880082613079343104",
              text: "Today the House takes aim at one of the biggest reasons health care costs are out of control: https://judiciary.house.gov/press-release/statement-chairman-bob-goodlatte-markup-h-r-1215-protecting-access-care-act/?utm_source=Speaker.gov+Master+List&utm_campaign=dd0172b987-EMAIL_CAMPAIGN_2017_06_13&utm_medium=email&utm_term=0_3e173f5a25-dd0172b987-154354401",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/684737436010819584",
              text: "Not only will today's measure to repeal Obamacare pave the way to affordable healthcare, it will cut our nat'l debt http://ow.ly/WHt4i",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1343994895086792704",
              text: "My three-Bill Rural Healthcare Agenda will increase access to affordable healthcare for millions of Americans who live in rural communities and expand telehealth services for all Americans. This will remain a priority in the next Congress. https://t.co/vpwLH5HmRJ",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/751417852771573760",
              text: "Holding the Administration accountable for unlawful Obamcare Cost-Sharing Reduction payments http://bit.ly/29kpgiG https://t.co/eiTIwgPBzE",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/885919322496917504",
              text: "Preventing fraud within our healthcare system is a priority for \n@HHSGov @SecPriceMD & @CMSGov @SeemaCMS --> https://www.wsj.com/article_email/u-s-charges-hundreds-of-people-in-alleged-opioid-scams-1499957350-lMyQjAxMTE3MzEzNDgxNzQ4Wj/",
            },
          ],
          question:
            "How do you plan to address the issue of skyrocketing healthcare costs and medical debt?",
        },
        {
          answer:
            "Yes, I believe that we need to focus on increasing competition and protecting consumers, as well as encouraging healthcare innovation and improving access to care for rural communities. We must also ensure that individuals have the right to choose their own health care plans and prevent a government takeover of health care. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/816774939093176321",
              text: "Introduced the American Health Care Reform Act earlier w/ my colleagues, which includes some of my legislation.  https://youtu.be/AzJqJfvKLvA",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/619546573710737408",
              text: "Landmark legislation that encourages healthcare innovation & improves the lives of all Americans passed the House! -> http://1.usa.gov/1LWoClK",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/839194836280422404",
              text: "It was an honor to speak with some of my constituents on health reform at the @FedAmerHospital 's conference yesterday. https://t.co/CG6LnpWW5P",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/844220814023180288",
              text: "We listened to your comments and concerns and made improvements to the American Health Care Act. ->  https://energycommerce.house.gov/news-center/press-releases/house-republicans-announce-updates-strengthen-american-health-care-act",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1343994895086792704",
              text: "My three-Bill Rural Healthcare Agenda will increase access to affordable healthcare for millions of Americans who live in rural communities and expand telehealth services for all Americans. This will remain a priority in the next Congress. https://t.co/vpwLH5HmRJ",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/844298713287790592",
              text: "Read more about Phase 3 of our Health Care reform and what it does to increase competition and protect consumers ->  https://kevinmccarthyforms.house.gov/news/email/show.aspx?ID=RFS3V7AWS4PPMMV6ZYZCTEGFF4",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/758327679217197057",
              text: "This clearly shows the problem w/ a gov't-run healthcare system & an unelected government board. http://ow.ly/a3Qk302F0tP",
            },
          ],
          question:
            "Are there specific healthcare reforms you believe are necessary to improve the system?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to making healthcare more affordable and accessible for all Americans. Key elements of her approach include increasing competition and protecting consumers, encouraging healthcare innovation, and improving access to care for rural communities. She has introduced a three-Bill Rural Healthcare Agenda that aims to expand telehealth services to all Americans and increase access to affordable healthcare for those in rural communities. To lower the cost of health insurance and increase transparency, she has introduced legislation and is working to repeal Obamacare. She is against a government takeover of health care and believes in the right of individuals to choose their own healthcare plans. To reduce healthcare fraud, she has advocated for holding the Administration accountable on certain issues.",
    },
    Immigration: {
      questions: [
        {
          answer:
            "Comprehensive immigration reform must include securing our southern border, ending chain migration, and ending the visa lottery system. We must also ensure that those who have gone through the legal immigration process are not disadvantaged by those who have not. We must also crack down on lawless sanctuary states and give border patrol officials the resources they need to secure our border. Finally, we must ensure that criminal illegal immigrants are not a threat to our communities. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1171409734966108160",
              text: "The current crisis at our border is multifaceted and requires a holistic approach. By tackling these problems piece by piece, we will get this situation under control.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/940696092601212928",
              text: "We need to look at the vulnerabilities in our immigration system and take action to fix them. https://twitter.com/FoxNews/status/940620911480369154",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/956539714248626176",
              text: "There are three things that have to happen before there is a DACA deal: there must be funding for the border wall, we must end chain migration, and we must end the visa lottery system. Period.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/950786223504699392",
              text: "@realDonaldTrump has very clearly stated the three things he wants for a DACA deal: the border wall, end chain migration, and end the visa lottery system. If Democrats really care about DREAMERS, they'll come to the table http://www.breitbart.com/big-government/2018/01/08/exclusive-marsha-blackburn-border-wall-not-just-important-but-imperative-for-daca-deal/",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1280984102578118656",
              text: "No longer will we allow illicit businesses to make millions exploiting our immigration laws. Our nation\u00e2\u20ac\u2122s citizenship is not for sale. \nfreebeacon.com/issues/sen-bla\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1154837680414441478",
              text: "The crisis at our border requires immediate action. We can\u00e2\u20ac\u2122t let traffickers and drug cartels continue to take advantage of our laws. This legislation targets asylum fraud to eliminate loopholes and bring real reforms to our nation\u00e2\u20ac\u2122s immigration policy. bit.ly/2SFP4bI",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/880202909178318849",
              text: "Our immigration laws must be enforced. Criminal aliens should not have the chance to become repeat offenders. https://judiciary.house.gov/press-release/goodlatte-introduces-bills-combat-sanctuary-cities-enhance-public-safety/",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/951241687992537088",
              text: "We have to secure our southern border, we have to end chain migration, and we have to end the visa lottery system. Period. https://twitter.com/FoxBusiness/status/951220363542827010",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/956562715748814848",
              text: "All of us know families and individuals who have spent years and thousands of dollars going through the legal immigration process. It is not fair to them to have anyone get in front of them in line. https://twitter.com/FoxNews/status/956545747717181442",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/986662203569950721",
              text: "Americans want security. Lawless sanctuary cities make no sense for a sovereign nation. We need to build that wall, end chain migration, end the visa lottery system, and cut off funds to sanctuary cities. And remember, no amnesty. https://twitter.com/FoxNewsInsider/status/986402178339409921",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1095349128262021120",
              text: "We need to give border patrol officials the resources they need to secure our border. I am hopeful the proposal put forth by the conference committee will be a step in the right direction. https://t.co/FsdcJ98DTO",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/963052918286049282",
              text: "I've been very clear: no amnesty. We cannot have individuals get in the queue before others who have been going through the legal immigration process. Period. pic.twitter.com/0kaVAeBCce",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1108431904792104960",
              text: "The Supreme Court is taking much needed action to ensure that criminal illegal immigrants are not a threat to our country\u00e2\u20ac\u2122s communities as they await their sentencing. While we have much more to do to secure our borders, this ruling is an important step.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/948217538890039296",
              text: "Let's secure that southern border, and let's end illegal entry. https://twitter.com/americanewsroom/status/948207994721984512",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1157060493292560384",
              text: "Today we took an important step @senjudiciary and sent @lindseygrahamsc\u00e2\u20ac\u2122s bill, the Secure and Protect Act of 2019, out of Committee. Loopholes in our current immigration policy make it too easy for cartels to smuggle drugs and traffic children across our border.",
            },
          ],
          question:
            "How will you approach comprehensive immigration reform, including pathways to citizenship?",
        },
        {
          answer:
            "I support measures that will secure our border and end illegal entry. This includes a physical barrier, more technology, and additional agents on the ground. We also need to end chain migration, end the visa lottery system, and cut off funds to sanctuary cities. We must also enforce our immigration laws and combat asylum fraud to protect children arriving at the border from falling victim to human trafficking. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1083842406192242689",
              text: "Your peace, your security needs a secure border. Every state is a border state and every town a border town until we secure the border and stop traffickers and illegal entry.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1171409734966108160",
              text: "The current crisis at our border is multifaceted and requires a holistic approach. By tackling these problems piece by piece, we will get this situation under control.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/948217538890039296",
              text: "Let's secure that southern border, and let's end illegal entry. https://twitter.com/americanewsroom/status/948207994721984512",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1090623918539444224",
              text: "If you're not for the three things that our border patrol agents are asking for - a physical barrier, more technology, and additional agents - you are not for border security. It is time for Democrats to negotiate.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/951241687992537088",
              text: "We have to secure our southern border, we have to end chain migration, and we have to end the visa lottery system. Period. https://twitter.com/FoxBusiness/status/951220363542827010",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/986662203569950721",
              text: "Americans want security. Lawless sanctuary cities make no sense for a sovereign nation. We need to build that wall, end chain migration, end the visa lottery system, and cut off funds to sanctuary cities. And remember, no amnesty. https://twitter.com/FoxNewsInsider/status/986402178339409921",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/788396987351785476",
              text: "It\u00e2\u20ac\u2122s time we secure our border and take control of illegal immigration. http://www.washingtontimes.com/news/2016/oct/17/illegal-immigration-surged-23-percent-last-year/",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1151593666495967232",
              text: "READ: Our @CBP agents have consistently asked Congress to fund 3 things: a physical barrier, better technology & more officers. Last week, I joined @VP & @senjudiciary in McAllen, Texas, where we witnessed firsthand how dire the crisis has become. @FoxNews fxn.ws/2LTQBJQ",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1154837680414441478",
              text: "The crisis at our border requires immediate action. We can\u00e2\u20ac\u2122t let traffickers and drug cartels continue to take advantage of our laws. This legislation targets asylum fraud to eliminate loopholes and bring real reforms to our nation\u00e2\u20ac\u2122s immigration policy. bit.ly/2SFP4bI",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/956539714248626176",
              text: "There are three things that have to happen before there is a DACA deal: there must be funding for the border wall, we must end chain migration, and we must end the visa lottery system. Period.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/880202909178318849",
              text: "Our immigration laws must be enforced. Criminal aliens should not have the chance to become repeat offenders. https://judiciary.house.gov/press-release/goodlatte-introduces-bills-combat-sanctuary-cities-enhance-public-safety/",
            },
          ],
          question:
            "What measures do you support for border security and managing immigration flows?",
        },
        {
          answer:
            "I believe that our immigration laws must be enforced and that criminal aliens should not have the chance to become repeat offenders. We need to look at the vulnerabilities in our immigration system and take action to prevent illegal aliens from obtaining government-issued, taxpayer-funded IDs. My legislation will withhold certain grant funds from lawless sanctuary states that put our nation in danger and cut off federal funds to lawless sanctuary jurisdictions harboring criminal aliens and terrorists. We must continue to pushback against lawless sanctuary policies and support President Trump's executive order to prevent sanctuary cities from becoming a safe haven for illegal immigration and criminal activity. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/880202909178318849",
              text: "Our immigration laws must be enforced. Criminal aliens should not have the chance to become repeat offenders. https://judiciary.house.gov/press-release/goodlatte-introduces-bills-combat-sanctuary-cities-enhance-public-safety/",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1234167058151223296",
              text: "What\u00e2\u20ac\u2122s the point of having laws if we don\u00e2\u20ac\u2122t follow them?\n \n13 states issue driver licenses to illegal immigrants and many more defy federal immigration law.\n \nMy legislation will withhold certain grant funds from these lawless sanctuary states that put our nation in danger. https://t.co/H2SNY1kzA2",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/940696092601212928",
              text: "We need to look at the vulnerabilities in our immigration system and take action to fix them. https://twitter.com/FoxNews/status/940620911480369154",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1211455611633913858",
              text: "Since serving in the state senate, I have opposed giving government-issued, taxpayer-funded IDs to illegal aliens. It rewards lawbreakers, encourages more illegal immigration & puts the public at risk. Let\u00e2\u20ac\u2122s end this lawlessness & start enforcing our laws. fxn.ws/2Q7BCxJ",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1227691763093118977",
              text: "There should be consequences for sanctuary states that issue driver licenses to illegal aliens or that defy federal immigration law. My bill will cut off federal funds to lawless sanctuary jurisdictions harboring criminal aliens and terrorists. cards.twitter.com/cards/4llmht/8\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1015635941967482881",
              text: "We must continue to pushback against lawless sanctuary policies.  Sanctuary cities are less safe, cost hard-working taxpayers money and encourage more illegal immigration.  Democrats\u00e2\u20ac\u2122 calls to abolish ICE are completely irresponsible.  politico.com/story/2018/07/\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "Are there policies to address the status of undocumented immigrants that you advocate?",
        },
      ],
      summary:
        "Marsha Blackburn is firmly against illegal immigration. She advocates for comprehensive immigration reform which includes securing the southern border, ending chain migration and the visa lottery system. She supports measures such as the erection of physical barriers, employment of more technology and agents, and cutting off of funds to sanctuary cities for stronger border control. She believes that only those who've followed the lawful immigration process should have the right to be in the country, cracking down on illegal immigrants who pose a threat to communities. To address the status of undocumented immigrants, she proposes to strengthen the immigration laws and eliminate any legal vulnerabilities. Her legislation intends to withhold grant funds from sanctuary states harboring illegal immigrants.",
    },
    "Inflation and Prices": {
      questions: [
        {
          answer:
            "I'm committed to finding ways to help everyday Americans confront the challenges of inflation. We must take a targeted approach that does not pick winners and losers. We must also ensure that our budget takes a solid step towards fiscal responsibility for today and future generations. We must also reduce unnecessary bureaucratic regulations that harm businesses and consumers, and increase transparency at the Fed to hold them accountable to the American people. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1284167025707950080",
              text: "I called on my colleagues to eliminate unnecessary bureaucratic regulation that harms businesses & consumers. @POTUS has reduced regulatory costs by $50 billion, putting money back in Americans' hands, & additional rollbacks will increase household income by around $3,000 a year.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1005201807650508805",
              text: "The greatest threat to our national security is our nation's debt. For our children & grandchildren's sake, we must find more ways to grow & cut our way out of this crisis. That's why I propose a 1% across-the-board spending cut. Surely we can cut one penny out of every dollar. https://t.co/Y1VIXw0V7U",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/580896004989329408",
              text: "Our budget takes solid step toward fiscal responsibility for our nation, both for today & future generations. http://goo.gl/0rfyem",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/667844912415641600",
              text: "On Thurs. we passed legislation to increase transparency at the Fed & hold them accountable to the American people http://ow.ly/UUaEb",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1090739789186379776",
              text: "This legislation would bring real reform to our appropriations process and allow Congress to conduct better oversight of how effectively funds are being used.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1201290632007864320",
              text: "Millions of rural Americans lack access to high speed internet. My bipartisan Internet Exchange Act will help bridge the digital divide by offsetting the costs of establishing and maintaining broadband connections. https://t.co/qBtPIgVP2h",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/941058991790198784",
              text: "Our goal is for everyone to get some tax relief, and to make certain the economy will grow. Watch my interview with @KennedyNation, where we discuss marginal tax rates, and how tax cuts will help small businesses. http://video.foxbusiness.com/v/5676683259001/?playlist_id=3997688255001#sp=show-clips",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/788765390293889025",
              text: "Americans deserve access to affordable health care coverage. These increases on premiums must stop. http://thehill.com/policy/healthcare/301632-alarm-sounds-on-medicare-premium-hikes",
            },
          ],
          question:
            "What measures do you propose to mitigate the impact of inflation on everyday Americans?",
        },
        {
          answer:
            "I support policies that encourage competition and free market interaction, reduce taxation, regulation and litigation, and have a pro-American trade policy. We must also ensure that fuel economy policies do not force auto manufacturers to produce vehicles that customers do not want or cannot afford. Additionally, I have called on my colleagues to eliminate unnecessary bureaucratic regulation that harms businesses and consumers. Finally, I have joined my colleagues in calling on the Treasury Secretary to ensure those utilizing the opportunity zone incentives are not wrongfully punished due to the uncertainty surrounding COVID-19. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1156551017292554240",
              text: "Our colleges and universities need to make sure that they control tuition costs and prepare students to successfully enter the job market.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/793164295416733698",
              text: "We must encourage competition and support free market interaction. http://www.washingtonexaminer.com/groups-energy-policy-for-new-admin-more-power-less-epa/article/2605942",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/788765390293889025",
              text: "Americans deserve access to affordable health care coverage. These increases on premiums must stop. http://thehill.com/policy/healthcare/301632-alarm-sounds-on-medicare-premium-hikes",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/776438260964794370",
              text: "We need to reduce taxation, regulation and litigation and have a pro-American trade policy. http://www.foxbusiness.com/markets/2016/09/14/ford-moving-all-small-car-production-to-mexico-from-u-s-ceo.html",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1284167025707950080",
              text: "I called on my colleagues to eliminate unnecessary bureaucratic regulation that harms businesses & consumers. @POTUS has reduced regulatory costs by $50 billion, putting money back in Americans' hands, & additional rollbacks will increase household income by around $3,000 a year.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1239191781754929153",
              text: "When we consider temporary ways to help Americans confront the challenges ahead, our approach *must be* targeted. We cannot pick winners and losers. https://t.co/7eVrs9tYhH",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1257347394171211780",
              text: "It\u00e2\u20ac\u2122s no secret that low-income communities are hard hit during an economic recession. I joined @SenatorTimScott in calling on @stevenmnuchin1 to ensure those utilizing the opportunity zone incentives are not wrongfully punished due to the uncertainty surrounding COVID-19.",
            },
          ],
          question:
            "Are there specific policies you support to stabilize prices and ensure affordability?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to mitigating the impact of inflation on everyday Americans through a targeted approach that includes fiscal responsibility and reducing unnecessary regulations. She supports policies that encourage competition and free markets while reducing taxation and excessive regulation. She is also an advocate for having a pro-American trade policy and ensuring that fuel economy policies do not negatively burden consumers. Furthermore, she has called for transparency at the Fed, and the removal of bureaucratic obstacles that harm businesses and consumers, as well as ensuring the rights of those using opportunity zone incentives during the COVID-19 pandemic.",
    },
    "Jobs & the Economy": {
      questions: [
        {
          answer:
            "I will continue to advocate for less taxation, less regulation, and less litigation to create an environment that encourages job growth and innovation. I will also work to ensure that our trade policies are pro-American and that our colleges and universities are preparing students to successfully enter the job market. Finally, I will support targeted solutions to help Americans confront the challenges ahead without picking winners and losers. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1091345572324405253",
              text: "304,000 jobs were added to the U.S. economy in January - nearly twice the number forecasted. Our pro-growth policies are providing real results for the American people.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/955163942996926466",
              text: "Less regulation + less taxation + less litigation = job growth and innovation. \n\nhttps://www.wsj.com/article_email/less-educated-workers-see-biggest-weekly-pay-bumps-1516357169-kIyTDAtMVI4RTJCMS0yMzE4Wj/",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1239191781754929153",
              text: "When we consider temporary ways to help Americans confront the challenges ahead, our approach *must be* targeted. We cannot pick winners and losers. https://t.co/7eVrs9tYhH",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/776438260964794370",
              text: "We need to reduce taxation, regulation and litigation and have a pro-American trade policy. http://www.foxbusiness.com/markets/2016/09/14/ford-moving-all-small-car-production-to-mexico-from-u-s-ceo.html",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1035248518775164929",
              text: "Tax cuts work. Tennessee is continuing to see real results from our pro-growth economic policies. \u00e2\u20ac\u0153Millions of people have found work, layoffs have fallen to levels last seen in the late 1960s, incomes are rising & businesses are investing.\u00e2\u20ac\u009d #BetterOffNow on.mktw.net/2C6GLRV",
            },
          ],
          question:
            "What strategies will you employ to stimulate job growth and reduce unemployment?",
        },
        {
          answer:
            "The government can support small businesses and entrepreneurs by providing access to the Paycheck Protection Program, cutting regulations, and providing liability protections. This will help small businesses keep their doors open, meet payroll, and create jobs. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1337121766473396225",
              text: "Small businesses are the cornerstones of our communities and in Tennessee, they employ over a million people. It is critical that we provide around round of the Paycheck Protection Program and liability protections so that they can keep their doors open.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1240792794484137985",
              text: "Tennessee workers and small business owners are struggling to keep their doors open and meet payroll. They need our support to create solutions that work for their employees. https://t.co/fJpKe4a4yq",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/826149914103128065",
              text: "President Trump's executive order cutting Small Business regulations will help grow the economy and create jobs. http://time.com/4653457/donald-trump-executive-order-small-business-regulations/",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1225803433724215296",
              text: "To ensure all Americans have the opportunity to succeed, big government must resist the urge to micromanage businesses with bureaucratic red tape and burdensome regulations. \n \ntwitter.com/MarshaBlackbur\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1245444051857551363",
              text: "The new Paycheck Protection Program will help our small businesses, sole proprietors and self-employed entrepreneurs keep the lights on. It provides 8 weeks of cash flow to employers who keep their employees on the payroll. You can learn more here: sba.gov/disaster-assis\u00e2\u20ac\u00a6 https://t.co/uykZ45B6Bv",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1246077862316331008",
              text: "Small Businesses: You can now apply for the #PaycheckProtectionProgram, which will fund up to 8 weeks of payroll costs. The loan will be fully forgiven if you keep all your employees on the payroll. You can find more information here: home.treasury.gov/policy-issues/\u00e2\u20ac\u00a6 https://t.co/5EX6AteaT0",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1263128886151430146",
              text: "Now more than ever, burdensome regulations are hampering our economy. Businesses need big government off their backs and protection from frivolous lawsuits. https://t.co/veKpxUDnwF",
            },
          ],
          question:
            "How can the government support small businesses and entrepreneurs to boost the economy?",
        },
        {
          answer:
            "Absolutely. We are working to simplify the tax code and make it fairer for all Americans. We are also working to reduce regulation and taxation, which will lead to job growth and innovation. We are also focused on targeted solutions to help Americans confront the challenges ahead, without picking winners and losers. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/908012971468759040",
              text: "Pro-growth #TaxReform will help American workers & job creators compete in the global economy #FlatterFairerSimpler https://t.co/JUiRpzbvoH pic.twitter.com/BkhrX5EuD8",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/955163942996926466",
              text: "Less regulation + less taxation + less litigation = job growth and innovation. \n\nhttps://www.wsj.com/article_email/less-educated-workers-see-biggest-weekly-pay-bumps-1516357169-kIyTDAtMVI4RTJCMS0yMzE4Wj/",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1239191781754929153",
              text: "When we consider temporary ways to help Americans confront the challenges ahead, our approach *must be* targeted. We cannot pick winners and losers. https://t.co/7eVrs9tYhH",
            },
          ],
          question:
            "Do you have a plan to address income inequality and promote wage growth?",
        },
      ],
      summary:
        "Marsha Blackburn advocates for policies that stimulate job growth and reduce unemployment. She believes lowering taxes, decreasing regulations, and reducing litigation can create a favorable environment for job creation and innovation. She supports pro-American trade policies and emphasizes the role of higher education in preparing students for the workforce.\n\nTo support small businesses and entrepreneurs, she stands for access to the Paycheck Protection Program, regulatory cuts, and liability protections. These steps, she believes, will help small businesses remain operational, meet payroll, and generate jobs.\n\nRegarding income inequality and wage growth, Blackburn supports tax code simplification and fairness. She also promotes less regulation and taxation as a means to drive job expansion and innovation. Lastly, she backs targeted solutions to help Americans navigate future challenges without favoring specific sectors or entities.",
    },
    "National Security": {
      questions: [
        {
          answer:
            "As chair of the Senate Judiciary's Tech Task Force, I am working to ensure a basic privacy and data security standard, that the virtual space is competitive, and that consumers are protected. We must also ensure that as tech companies utilize data to track the spread of COVID-19, Americans' privacy and security are safe. Additionally, we must examine how we can address gun violence and mental illness while protecting our civil liberties and keeping political bias out of the FBI. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/603962137422262272",
              text: "American taxpayers deserve to know that their personal information is secure from data breaches. http://tinyurl.com/pnwpb37",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1116779964655915010",
              text: "Great to meet with you and discuss how we can work together to enact the most effective privacy legislation while maintaining an innovative economy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1255950332947030017",
              text: "It is paramount that as tech companies utilize data to track the spread of COVID-19, Americans\u00e2\u20ac\u2122 privacy and security are safe. thehill.com/policy/technol\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1176526955337981952",
              text: "It is important to exercise some preemptions so you are not looking at fifty different standards, but you have a basic privacy standard to make certain that this virtual space is competitive and that innovation continues.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/870273352396656640",
              text: "Happy to be leading the charge to protect consumers' online privacy.  http://www.bostonglobe.com/business/2017/05/31/republican-revives-internet-privacy-debate/QpIUwQByR80fXfzTwOkVeI/story.html?event=event25 via @BostonGlobe",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1173341644495708160",
              text: "Read my @FoxNews op-ed on how protecting your right to privacy in the 21st century requires that we hold Big Tech accountable. fxn.ws/2kngmak",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1221177924440604672",
              text: "We have a duty to protect the Constitution for America\u00e2\u20ac\u2122s next generation. Hearing again how @realDonaldTrump\u00e2\u20ac\u2122s 2016 campaign was spied on by the FBI is a stark reminder that our liberties are at risk.",
            },
          ],
          question:
            "How will you ensure the safety and security of the nation while respecting individual privacy?",
        },
        {
          answer:
            "I believe that counterterrorism efforts and cybersecurity protection are two sides of the same coin. We must work together to ensure that our nation is secure from both cyber terrorists and overseas threats. To do this, I have been working with my colleagues on solutions to protect against cyber attacks, such as providing funding for high school training in computer science and cybersecurity. I am also co-leading an effort to ensure our next generation of leaders has the cyber security and computer science skills necessary to compete and win in the 21st century. Additionally, I am working to close loopholes that could allow Islamic State terrorists entry into the U.S. and to ensure that the technology we are using is secure. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/626150903062634496",
              text: "This is exactly why I've been working w/ my colleagues on solutions to protect against attacks from cyber terrorists: http://bit.ly/1U46hpm",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/600394101851316226",
              text: "Cybersecurity isn\u00e2\u20ac\u2122t just a matter of keeping our financial & personal info safe, it\u00e2\u20ac\u2122s a matter of nat'l security. http://bit.ly/1AbgE59",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/627169728814051328",
              text: "Pleased both the House & Senate recognize urgency to bring legislation to the table that will thwart cyber attacks.http://bit.ly/1DfEBth",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/569321194165571584",
              text: "For companies & individuals trying to prevent attacks on virtual you, we must act on solutions to keep data secure. http://tinyurl.com/nbnho7d",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1153318648238497799",
              text: "By providing funding for high school training in computer science and cybersecurity, we can ensure that the next generation in uniform is prepared for the future of combat. Glad to join @SenJackyRosen to invest in this crucial aspect of rebuilding our nation's military.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/667378597091495936",
              text: "This is why we need to address both the overseas & domestic security vulnerabilities in our vetting process --> http://ow.ly/UQJE7",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1115368857764544512",
              text: "My hope is that through this bipartisan effort we will shed light on the need to protect competition and online privacy to keep up with the fast pace of changes in technology.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/631138091370156032",
              text: "A great start to the private sector and government working together to share information about threats --> http://bit.ly/1DGqn5d",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/674728190871519233",
              text: "More on our work to protect America from terrorists trying to take advantage of our Visa Waiver Program -> http://ow.ly/VGENT",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/667052345126178817",
              text: "More on a loophole that could allow Islamic State terrorists entry into the U.S. & what I'm doing to stop it --> http://ow.ly/UOo4p",
            },
          ],
          question:
            "What is your approach to counterterrorism efforts and cybersecurity protection?",
        },
        {
          answer:
            "I believe that we need to ensure that our intelligence agencies are held accountable and that they are free from political bias. We must also make sure that they have the resources they need to stay on the cutting edge of technology and to protect our nation from emerging threats. To that end, I have introduced the Sister City Transparency Act to assess the extent to which foreign communities could use sister city partnerships to conduct malign activities. I have also supported efforts to provide funding for high school training in computer science and cybersecurity to ensure that the next generation in uniform is prepared for the future of combat. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1204100045085270018",
              text: "A systemic problem with the FBI\u00e2\u20ac\u2122s surveillance procedures is eroding public trust. It\u00e2\u20ac\u2122s time for accountability to protect our civil liberties and keep political bias out of the FBI.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1228034750465630210",
              text: "Any attempt to weaken the President\u00e2\u20ac\u2122s ability to defend our nation from Iran puts our national security in jeopardy. Congress should make constructive policy, not reactive.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1331596670744879105",
              text: "My bill, the Sister City Transparency Act will assess the extent to which foreign communities could use sister city partnerships to conduct malign activities.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1217486438868099076",
              text: "Using the powers of the surveillance state to spy on a political opponent\u00e2\u20ac\u2122s campaign is what you\u00e2\u20ac\u2122d expect from Russia, Iran, or China \u00e2\u20ac\u201d not the US. I\u00e2\u20ac\u2122m pleased AG Barr has announced the first safeguard to prevent this from occurring again. on.wsj.com/2RfX2rQ",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/849378026525786112",
              text: "Here\u00e2\u20ac\u2122s the letter we just sent to the House and Senate Intelligence Committees. We want some answers. https://youtu.be/DHmYeRYK0L4 https://t.co/xERulbiNSI",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1206316487419613184",
              text: "We need answers and accountability at the FBI. Our top law enforcement agency paid $95K for intel to a source who later funneled Democrat-funded lies and propaganda on the Trump campaign.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1153318648238497799",
              text: "By providing funding for high school training in computer science and cybersecurity, we can ensure that the next generation in uniform is prepared for the future of combat. Glad to join @SenJackyRosen to invest in this crucial aspect of rebuilding our nation's military.",
            },
          ],
          question:
            "Are there specific measures to strengthen intelligence agencies that you endorse?",
        },
      ],
      summary:
        "Marsha Blackburn supports a multi-faceted approach to national security. She endorses a strict privacy standard for data security and aims to keep the virtual landscape competitive. Her aim is also to protect consumers' privacy, especially as tech companies use data for activities such as tracking COVID-19. She aims at eliminating political bias within the FBI and views handling mental health and gun violence as essential to national security.\n\nBlackburn sees counterterrorism efforts and cybersecurity protection as pivotal parts of national security. She supports high school training in computer science and cybersecurity as a step towards protecting the nation against cyber attacks. She's working on closing loopholes that could permit hostile foreign threats like ISIS into the US and emphasizes secure technology.\n\nShe advocates for politics-free intelligence agencies, emphasizing agency accountability and resource availability. She endorses the Sister City Transparency Act to assess and manage possible foreign disruptive activities through sister city partnerships. By pushing for a future generation equipped with cybersecurity skills, she's assuring tomorrow's combat readiness.",
    },
    Taxes: {
      questions: [
        {
          answer:
            "I'm a strong supporter of tax reform. We need to make the tax code flatter, fairer, and simpler for all Americans. We want to make sure everyone gets some tax relief and that the economy will grow. We need to make sure small businesses can thrive and that the government isn't entitled to hard-earned dollars. We've been working on this for 31 years and it's time to get it done. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/907617548509646848",
              text: "Our vision for tax reform \u00e2\u2020\u2019 flatter, fairer, simpler #FlatterFairerSimpler https://t.co/JUiRpyTU07 pic.twitter.com/D8c9Un8WOW",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/746375360896450561",
              text: "What your tax return would look like under the @HouseGOP blueprint for tax code reform: http://1.usa.gov/1uxjKgp https://t.co/WAx2UYhzI2",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/941058991790198784",
              text: "Our goal is for everyone to get some tax relief, and to make certain the economy will grow. Watch my interview with @KennedyNation, where we discuss marginal tax rates, and how tax cuts will help small businesses. http://video.foxbusiness.com/v/5676683259001/?playlist_id=3997688255001#sp=show-clips",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/943193480498765824",
              text: "It's taken 31 years, but the time is finally here. I'm on my way to vote yes for #taxreform. This bill will give much needed tax relief to hardworking families, and allow small businesses to once again thrive. The government isn't entitled to your hard-earned dollars. pic.twitter.com/tVZf1a35vr",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/908012971468759040",
              text: "Pro-growth #TaxReform will help American workers & job creators compete in the global economy #FlatterFairerSimpler https://t.co/JUiRpzbvoH pic.twitter.com/BkhrX5EuD8",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/943310487688765440",
              text: "Ready to join @kimguilfoyle on @seanhannity's show to discuss why we need to pass tax reform. It's time for us to get back to our core American values- less taxes, more personal freedom. The government is not entitled to your paycheck. pic.twitter.com/jiBZgTRWUB",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/894601031086223362",
              text: "31 years since our tax code has been reformed. 31 days in August. 31 reasons we need reform. #TaxReform17 https://t.co/wUFpcL8Qxl pic.twitter.com/dKAXaXYXCX",
            },
          ],
          question:
            "What is your position on tax reform, including individual and corporate taxes?",
        },
        {
          answer:
            "We must ensure that the tax code is fair and that everyone pays their fair share. We must close loopholes and ensure that the IRS is held accountable for any potential abuses. We must also ensure that the tax code is simplified so that taxpayers can understand it and file their taxes with ease. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/583380678659710978",
              text: "American taxpayers deserve explanation about #IRS targeting. Lois Lerner should stop hiding behind her attorneys. http://tinyurl.com/mm6qje9",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/826902973792059394",
              text: "Wouldn\u00e2\u20ac\u2122t it be nice if the tax code was so simple, you could file your taxes on a form the size of a postcard? http://abetterway.speaker.gov/_assets/pdf/ABetterWay-Tax-BytheNumbers.pdf https://t.co/eZ6f0s5v5e",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/746375360896450561",
              text: "What your tax return would look like under the @HouseGOP blueprint for tax code reform: http://1.usa.gov/1uxjKgp https://t.co/WAx2UYhzI2",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/907617548509646848",
              text: "Our vision for tax reform \u00e2\u2020\u2019 flatter, fairer, simpler #FlatterFairerSimpler https://t.co/JUiRpyTU07 pic.twitter.com/D8c9Un8WOW",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/856911667904614400",
              text: "Tax reform is overdue. I'm pleased @potus is putting out an aggressive plan. I joined @foxnews earlier to discuss -> https://www.youtube.com/watch?v=-FyTDbsNePI&feature=youtu.be",
            },
          ],
          question:
            "How would you address concerns about tax evasion and tax loopholes?",
        },
        {
          answer:
            "We must prioritize fiscal responsibility and ensure that our budget is balanced. We must find ways to grow our economy and cut our way out of this crisis. That's why I proposed a 1% across-the-board spending cut. We must also conduct better oversight of how effectively funds are being used and ensure that our approach is targeted and not picking winners and losers. We must also ensure that agencies are offsetting future spending. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/580896004989329408",
              text: "Our budget takes solid step toward fiscal responsibility for our nation, both for today & future generations. http://goo.gl/0rfyem",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/593802393969655808",
              text: "Pleased the House/Senate Budget Conference reached agreement on a #balancedbudget w/ $5.3 trillion in savings http://1.usa.gov/1DCgeze",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/984444946307526656",
              text: "Today I will vote YES on a Balanced Budget Amendment to the Constitution. Tennesseans know the greatest threat to our freedom is our nation\u00e2\u20ac\u2122s debt. We owe it to our children and grandchildren to reign in Washington\u00e2\u20ac\u2122s out-of-control spending.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1005201807650508805",
              text: "The greatest threat to our national security is our nation's debt. For our children & grandchildren's sake, we must find more ways to grow & cut our way out of this crisis. That's why I propose a 1% across-the-board spending cut. Surely we can cut one penny out of every dollar. https://t.co/Y1VIXw0V7U",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1020035886875103232",
              text: "I applaud @RepTomGraves for cutting $585 million in the Financial Services Approps bill. This money will be put in the #FundForAmericasKidsAndGrandkids and only spent when our budget is balanced. We must continue to find solutions like these to reduce out-of-control spending.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/905854649848262661",
              text: "Tired of reckless spending & debt? So am I. Letter fm @republicanstudy to @SpeakerRyan - how to address the problem: https://www.scribd.com/document/358269167/Letter-to-Speaker-Ryan-090717#",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1182285549496864773",
              text: "Money doesn\u00e2\u20ac\u2122t grow on trees \u00e2\u20ac\u201d but you wouldn\u00e2\u20ac\u2122t know that based on Washington\u00e2\u20ac\u2122s out of control spending and ballooning deficit.\n\nBy requiring agencies to offset future spending, @realdonaldtrump is helping to curb spending and balance our books. \n\nwsj.com/articles/trump\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "What government spending priorities would you emphasize to ensure a balanced budget?",
        },
      ],
      summary:
        "Marsha Blackburn is in favor of tax reform, making the tax code flatter, simpler, and more fair for all American citizens. She supports tax relief for everyone and believes it's essential for economic growth and small business success. She advocates for closing tax loopholes and holding the IRS accountable to ensure that everyone pays their fair share. Blackburn also focuses on government fiscal responsibility, proposing measures like a 1% spending-cut across the board. She emphasizes the importance of oversight in fund usage and balanced budget to decrease financial crisis.",
    },
  },
  "Marsha Blackburn (2024)": {
    Abortion: {
      questions: [
        {
          answer:
            "I am pro-life and believe that every life is precious and should be protected. I have consistently voted against legislation that would expand access to abortion, such as the Women\u2019s Health Protection Act, and have supported legislation that would restrict access to abortion, such as the Title X Abortion Provider Prohibition Act. I also support informed consent laws and restrictions on late-term abortions and gruesome fetal dismemberment procedures. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1524493072268251137",
              text: "This bill would force states to legalize late-term abortions, remove informed consent laws, and prevent restrictions on\u00a0gruesome fetal dismemberment\u00a0procedures.\u00a0Today,\u00a0I stood up\u00a0to the woke mob and\u00a0voted to protect women and their unborn\u00a0children.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1357096248201912322",
              text: "For far too long, abortion providers have siphoned taxpayer funding away from Americans. Today, I'm going to the Senate floor to call a vote on my legislation to close loopholes that allow big abortion providers such as Planned Parenthood access to Title X family planning funds.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1498453529127788553",
              text: "The Women\u2019s Health Protection Act is an attack against the health of women & unborn children. Today, I voted against this legislation which would open the pathway for abortion on demand & legalize late term abortions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1524555070741827593",
              text: "Today, I voted no on the radical left\u2019s plan to make every state a late-term abortion state, allow dismemberment and telemedicine abortions, and remove informed consent. https://t.co/ZaPZNROXTr",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1355155274022211587",
              text: "Abortion providers have a long history of misappropriating taxpayer dollars to quietly fund the industry.\u00c2\u00a0\u00c2\u00a0\n\u00c2\u00a0\nI introduced the Title X Abortion Provider Prohibition Act to close grant loopholes & put an end to the flow of taxpayer funding for abortions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1545452487934713857",
              text: "Long gone are the days of Democrats advocating for safe, legal, and rare abortions. \n\nNow, the Biden administration is convening lawyers from across the country to help women access abortions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1524582084274266112",
              text: "There is no limit to how far the Democrats are willing to go on abortion. \n\u00a0\nIf they had it their way, states would not be allowed to stop gruesome dismemberment abortion procedures.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540448945482207233",
              text: "In 1982, Joe Biden proposed a constitutional amendment that would overturn Roe v. Wade and give the states the ability to regulate abortion. But today, he condemned the Dobbs decision. \n\nUnlike pro-life advocates, Biden has no backbone. He is ruled by the radical left.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1351929437571477506",
              text: "President Trump worked tirelessly to preserve life & religious liberty\u00e2\u20ac\u201dpreventing government funding of abortions, ensuring taxes cannot fund abortions globally, restricting Title X taxpayer funding from subsidizing the abortion industry & working to end to late-term abortions.",
            },
          ],
          question:
            "Where do you stand on the legality of abortion, and what restrictions, if any, do you support?",
        },
        {
          answer:
            "Absolutely. I am committed to providing access to quality healthcare for all Tennesseans. I have worked to ensure that all Tennesseans have the resources they need to stay healthy and I have opposed Biden's unconstitutional COVID vaccine mandates. Additionally, I plan to champion trade policies that advance competition in the United States and I am looking forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1475984739601424387",
              text: "Now more than ever, access to quality health care is critical. \n\nIn 2021, I kept my focus on ensuring all Tennesseans have the resources they need to stay healthy, and I opposed Biden\u00e2\u20ac\u2122s unconstitutional COVID vaccine mandates. https://t.co/3KvClnnXyh",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
          ],
          question:
            "Are there healthcare policies you support to provide alternatives to abortion?",
        },
      ],
      summary:
        "Marsha Blackburn is pro-life and supports the restriction of access to abortion. She has voted against legislation that expands access to abortion and supported legislation that restricts it. She endorses informed consent laws and restrictions on late-term abortions and fetal dismemberment procedures. In addition to these stances, Blackburn also supports innovative healthcare policies that provide alternatives to abortion and increase access to quality healthcare for all, including initiatives to lower patient costs.",
    },
    "Civil Liberties": {
      questions: [
        {
          answer:
            "I am committed to protecting individual civil liberties, including freedom of speech and privacy. I have introduced the BROWSER Act to provide real solutions for consumers and businesses by allowing users to opt-in or out of data collection based on information sensitivity. I have also introduced legislation to assess the extent to which sister city agreements with cities in Communist China could be a threat to national security. I am also determined to fight for the freedom to vote in a fair election and to ensure transparency in the FBI. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1455638283220930563",
              text: "Glad to see Facebook recognize that stockpiling photos on millions of users isn\u00e2\u20ac\u2122t a good privacy practice. We know how this data can be misused \u00e2\u20ac\u201c just ask the Uyghurs. The U.S. needs a privacy law that protects all consumers from data grabs by Big Tech companies.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1505984268358594563",
              text: "The American people want a Supreme Court justice who will protect their families\u2019 freedoms, not be a rubber stamp for government overreach into private family decisions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1453408758302969865",
              text: "The completion of the Durham investigation is necessary to ensure transparency in the FBI and restore faith in our civil liberties.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1444007812586213399",
              text: "Actually, it\u00e2\u20ac\u2122s me and 51 other Senators from both sides of the aisle standing in between you and your radical agenda.\n\nI stand for FREEDOM.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1354879871646916612",
              text: "This #DataPrivacyDay, it\u00e2\u20ac\u2122s more important than ever to protect our information online. \nThrough my BROWSER Act, users can opt-in or out of data collection based on information sensitivity.  This provides real solutions for consumers & businesses. https://t.co/iTxHsuYmS7",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1505596321687904262",
              text: "During the hearing, I plan to press Judge Jackson on her writings, record and rulings to ensure that we nominate a constitutionalist to the Supreme Court.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1450853954796105732",
              text: "A democracy that doesn\u00e2\u20ac\u2122t tolerate free speech will not remain a democracy for long. \n\nRead my latest editorial with @SenTomCotton on our new Senate Campus Free Speech Caucus \u00e2\u017e\u00a1\u00ef\u00b8\u008f\nfoxnews.com/opinion/free-s\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1577037525679411201",
              text: "For too long, Big Tech has used Section 230 as a shield to avoid accountability for its decisions, including the hosting of CSAM, violent content, and the removal of conservative content.\n\nI\u2019m looking forward to hearing what the Supreme Court has to say on this issue.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1567558615417880576",
              text: "Reportedly, the FBI secretly pressured Americans to sign forms that surrender their rights to owning, buying, or using firearms. \n\nYou cannot sign away your constitutional rights \u2014 despite what the FBI wishes.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1346871807568179202",
              text: "I am determined to fight for the freedom that ensures our representative democracy remains\u00e2\u20ac\u201dthe freedom to VOTE in a fair election.\u00c2\u00a0 \u00f0\u0178\u2014\u00b3 \u00f0\u0178\u2021\u00ba\u00f0\u0178\u2021\u00b8",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1475909198672285697",
              text: "During the holidays, I encourage parents to help ensure the safety of their children online by remaining vigilant about who their child is giving personal information to, turning on privacy settings, and having conversations about the dangers of social media. @NextRevFNC https://t.co/M68Y2aYRzh",
            },
          ],
          question:
            "What safeguards will you put in place to protect individual civil liberties, including freedom of speech and privacy?",
        },
        {
          answer:
            "We need to pass privacy legislation to protect our citizens from Big Tech companies and their data grabs. Through my BROWSER Act, users can opt-in or out of data collection based on information sensitivity. This provides real solutions for consumers and businesses. We must also keep Big Tech in check and ensure they are held accountable for their decisions. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1455638283220930563",
              text: "Glad to see Facebook recognize that stockpiling photos on millions of users isn\u00e2\u20ac\u2122t a good privacy practice. We know how this data can be misused \u00e2\u20ac\u201c just ask the Uyghurs. The U.S. needs a privacy law that protects all consumers from data grabs by Big Tech companies.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1354879871646916612",
              text: "This #DataPrivacyDay, it\u00e2\u20ac\u2122s more important than ever to protect our information online. \nThrough my BROWSER Act, users can opt-in or out of data collection based on information sensitivity.  This provides real solutions for consumers & businesses. https://t.co/iTxHsuYmS7",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570398418157142016",
              text: "Facebook was reportedly spying on users' private messages and reporting them to the FBI if they dared to question the government.\n\nWe cannot trust big government or Big Tech. nypost.com/2022/09/14/fac\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1647981090483699712",
              text: "Big Tech has too much power and control over what you see, hear, think, say, and how you vote.\u00a0\n\nWe need to keep them in check.",
            },
          ],
          question:
            "How do you plan to address concerns about government surveillance and data privacy?",
        },
        {
          answer:
            "Absolutely. I have championed several initiatives to protect civil liberties in the digital age. My Civilian Cyber Security Reserve Act, which passed the Senate unanimously, will help build a pipeline of talent necessary to respond when a cyber attack occurs. My BROWSER Act allows users to opt-in or out of data collection based on information sensitivity. My Kids Online Safety Act provides children and parents with tools, safeguards, and transparency to protect against online threats. I have also championed the Open Technology Fund Act, which has provided 1.3 million Cubans with internet access. I am also fighting back against radical left policies by introducing 13 different amendments to counteract the liberal agenda that is putting our nation on the wrong path. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1605683692902531072",
              text: "I am pleased that my Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs, unanimously passed the Senate last night. \n\nWe are now one step closer to this bill becoming law.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1354879871646916612",
              text: "This #DataPrivacyDay, it\u00e2\u20ac\u2122s more important than ever to protect our information online. \nThrough my BROWSER Act, users can opt-in or out of data collection based on information sensitivity.  This provides real solutions for consumers & businesses. https://t.co/iTxHsuYmS7",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1603110135005843456",
              text: "My bipartisan Kids Online Safety Act provides children and parents with tools, safeguards, and transparency to protect against online threats to kids\u2019 health and well-being.\u00a0\n\u00a0\nIt\u2019s critical we get this done.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1625544499375095825",
              text: ".@SenBlumenthal and I introduced the Kids Online Safety Act to provide kids and parents with the tools and transparency they need to stay safe.\n\u00a0\nOur bipartisan legislation requires social media companies to make their platforms safer by default. https://t.co/9KUEBLr5l1",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1611810747914256385",
              text: "Working with @SenatorMenendez, the INFO Act was included in the NDAA signed by the President. \n\nThis bipartisan bill promotes internet freedom and circumvents foreign restrictions on access to the internet.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1459320523603816448",
              text: "Glad to see @marcorubio\u00e2\u20ac\u2122s Secure Equipment Act was signed into law. I supported this legislation as it is critical we block companies in Communist China like @Huawei and @zteusa from gaining a foothold in the USA.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1357438909995696128",
              text: "I\u00e2\u20ac\u2122m fighting back against radical left policies by introducing 13 different amendments to counteract the liberal agenda that is putting our nation on the wrong path. https://t.co/qNAPwTyhPB",
            },
          ],
          question:
            "Are there initiatives to strengthen civil liberties protections in the digital age that you support?",
        },
      ],
      summary:
        "Marsha Blackburn is committed to protecting civil liberties, particularly in the digital age. She has introduced the BROWSER Act, which would allow users to opt-in or out of data collection based on information sensitivity, safeguarding privacy. She has also introduced legislation to assess possible national security risks linked to sister city agreements with cities in Communist China. Blackburn supports the creation of a Civilian Cyber Security Reserve to enhance digital security and has pushed for the Kids Online Safety Act to protect minors online. She has further advocated for the Open Technology Fund Act to expand internet access, and has introduced several amendments to counteract what she perceives as radical left policies threatening civil liberties.",
    },
    "Civil Rights": {
      questions: [
        {
          answer:
            "I believe that all Americans should be treated with respect and dignity, and that no one should be discriminated against based on their sexual orientation or gender identity. I have supported legislation that would protect LGBTQ+ individuals from discrimination in the workplace, housing, and public accommodations. I also support the Equality Act, which would provide additional protections for LGBTQ+ individuals. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1380305541306589188",
              text: "Last week, I toured a shelter in West Tennessee to see\u00c2\u00a0first-hand the importance of ensuring\u00c2\u00a0women and children have a protected\u00c2\u00a0space to seek refuge.\u00c2\u00a0\n\nThe Equality Act will destroy\u00c2\u00a0women's rights &\u00c2\u00a0safety.\u00c2\u00a0\nfox17.com/news/local/equ\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1372240059139772421",
              text: "We don\u00e2\u20ac\u2122t want biological males in women\u00e2\u20ac\u2122s shelters. The Equality Act leads to the erasure of women by decimating the existence of sex-specific facilities, sports, and other female-only spaces such as women\u00e2\u20ac\u2122s domestic violence shelters. pic.twitter.com/qtYMo1h6iM",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1351929437571477506",
              text: "President Trump worked tirelessly to preserve life & religious liberty\u00e2\u20ac\u201dpreventing government funding of abortions, ensuring taxes cannot fund abortions globally, restricting Title X taxpayer funding from subsidizing the abortion industry & working to end to late-term abortions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1357438909995696128",
              text: "I\u00e2\u20ac\u2122m fighting back against radical left policies by introducing 13 different amendments to counteract the liberal agenda that is putting our nation on the wrong path. https://t.co/qNAPwTyhPB",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1466071217513844736",
              text: "Yesterday, I joined my colleagues in support of Attorney General Lynn Fitch and the state of Mississippi as we stand together to protect and preserve the right to life. https://t.co/TrBfZxQHCd",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1550525304329887744",
              text: "The Protecting Access to Lifesaving Screenings Act is critical in fighting breast cancer. I\u2019m glad to support such an important piece of legislation with the help of @NancyGBrinker.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1590374348401311749",
              text: "I am fighting to ensure the National Defense Authorization Act protects our servicemembers from being fired due to Biden\u2019s vaccine mandate.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1608165502630744064",
              text: "As 2022 comes to a close, I\u2019m reflecting on a productive year: \n\n\u2714\ufe0f Repealed military COVID vaccine mandate\n\u2714\ufe0f Strengthened small battery safety standards\n\u2714\ufe0f Worked to keep kids safe online \n\u2714\ufe0f Prohibited NDAs from silencing sexual assault survivors in the workplace https://t.co/CBfy36LJa2",
            },
          ],
          question:
            "What actions would you take to advance LGBTQ+ rights and protections against discrimination?",
        },
        {
          answer:
            "I believe that we must ensure that everyone has equal access to opportunities and resources. We must also ensure that everyone is treated fairly and with respect. We must also ensure that everyone has the same rights and protections under the law. To promote racial and gender equality, we must work to eliminate discrimination and bias in all areas, including employment and housing. We must also work to ensure that everyone has access to the same resources and opportunities. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1372240059139772421",
              text: "We don\u00e2\u20ac\u2122t want biological males in women\u00e2\u20ac\u2122s shelters. The Equality Act leads to the erasure of women by decimating the existence of sex-specific facilities, sports, and other female-only spaces such as women\u00e2\u20ac\u2122s domestic violence shelters. pic.twitter.com/qtYMo1h6iM",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1374356628796018690",
              text: "Government shouldn\u00e2\u20ac\u2122t stand in the way of the achievements and progress women have made. Check out my article with @carrielukas in @Newsweek\nnewsweek.com/why-are-we-mak\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "How can you promote racial and gender equality in various sectors, including employment and housing?",
        },
        {
          answer:
            'I support common sense policies that make it easy to vote and hard to cheat. This includes measures like voter ID laws, which help to ensure that only legal votes are counted. I am determined to fight for the freedom to vote in a fair election and will oppose any attempts to federalize elections, strip away protections for the ballot box, and inject uncertainty into the voting process. I will also be putting a hold on the "Voting-Rights" Bill, which does nothing to secure elections and only increases the possibility of election fraud. Moving forward, I hope Congress will work on a bipartisan basis to restore the confidence of the American people in our elections and affirm our shared commitment to the rule of law. ',
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1410625317891284995",
              text: "We need common sense policies in place to prevent illegal voting and ballot harvesting. Today\u00e2\u20ac\u2122s Supreme Court decision reaffirms that it should be easy to vote and hard to cheat.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1346851261933613057",
              text: "We must fulfill our duties to represent American voters and stand up to any threats to free and fair elections in this country. https://t.co/nSIL20cvec",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1483494803941830659",
              text: "Don\u00e2\u20ac\u2122t let the left fool you. \u00e2\u20ac\u0153Voting rights\u00e2\u20ac\u009d is code for banning voter ID, making it easier to cheat, and putting the federal government in charge of elections.",
            },
          ],
          question:
            "What policies do you support to protect voting rights and access to the ballot?",
        },
      ],
      summary:
        "Marsha Blackburn is dedicated to ensuring all Americans, regardless of their sexual orientation, gender identity or race, are treated with respect, dignity, and equality. She supports legislation aimed at combating discrimination in the workplace, housing, and public accommodations, such as the Equality Act, which promotes protections specific to LGBTQ+ individuals.\n\nShe also proposes policies aimed at advancing racial and gender equality throughout various sectors. Her focus is on eliminating discrimination and bias and ensuring everyone has access to the same resources and opportunities.\n\nOn voting rights, she supports the implementation of policies that protect the ballot while also making it easy for legal voters to cast their vote. Such measures include voter ID laws. She opposes federalizing elections and any attempts that could undermine the trust in the voting process. Her goal is to restore confidence in elections and affirm commitment to the rule of law on a bipartisan basis.",
    },
    Crime: {
      questions: [
        {
          answer:
            "We must focus on criminal justice reform that is smart on crime and keeps our communities safe. We must improve the rape kit process to put rapists behind bars and get criminals off the streets. That\u2019s why I\u2019ve introduced the Restoring Law and Order Act to increase resources for evidence processing and crime labs. We must also ensure that prosecutors keep criminals behind bars and keep our communities safe. We must keep drugs, gangs, and violent criminals out of our communities. We must also provide more resources and funding for law enforcement agencies to target violent criminals. We must ensure that if you commit the crime, you must do the time. We must also end soft-on-crime policies and ensure that left-wing DAs have a responsibility to keep communities safe. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1351931419598204928",
              text: "Over the past four years, President Trump achieved landmark criminal justice reform and signed the First Step Act into law. His smart on crime approach provided inmates an opportunity to rehabilitate and succeed while making our communities safer.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1586027211861069825",
              text: "A Chicago man was released early from prison and caught committing the same crime within the same week.\n\nLeft-wing policies like 50% sentence reductions are endangering your communities.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570801941730709505",
              text: "We must improve the rape kit process to put rapists behind bars and get criminals off the streets.\n\nThat\u2019s why I\u2019ve introduced the Restoring Law and Order Act to increase resources for evidence processing and crime labs.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1633624258306887682",
              text: "No citizen should be forced to live in fear of violent criminals, whether in DC, Memphis, or Knoxville.\n\nWe must keep drugs, gangs, and violent criminals out of our communities. We need prosecutors to keep criminals behind bars and keep our communities safe.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570764718851366912",
              text: "The Defund the Police movement is demanding compassion for criminals, not for victims.\n\n@SenatorHagerty and I have introduced legislation to provide more resources and funding for law enforcement agencies to target violent criminals.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1568027369973370881",
              text: "Weak-on-crime prosecutors and lenient sentences are making our communities more dangerous.\n\nIf you commit the crime, you must do the time.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1506745665862447107",
              text: "Judge Jackson\u2019s philosophy, it appears, is exactly backward: restrictions on children and families, and freedom for criminals.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570204583242121224",
              text: "Americans want to feel safe again, and that starts with supporting our police and ending soft-on-crime policies.\n\nThank you @ChuckGrassley, @SenatorBraun, @SenJohnKennedy, and @SenRonJohnson for joining me and @SenatorHagerty to discuss how we help solve the violent crime crisis. pic.twitter.com/jejakD57OF",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1568364367636201477",
              text: "Weak-on-crime DAs are endangering our communities. If the left had their way, more convicted felons would be walking our streets. foxnews.com/us/memphis-liv\u2026",
            },
          ],
          question:
            "How would you approach criminal justice reform to reduce mass incarceration and address racial disparities?",
        },
        {
          answer:
            "We must prioritize safety in our communities and ensure that criminals are held accountable for their actions. To do this, we must support our law enforcement and end soft-on-crime policies. We must also fund, not defund, law enforcement and ensure that prosecutors are keeping criminals behind bars. Additionally, I have introduced an amendment to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers. This will help to keep our schools safe and provide more opportunities for veterans and former law enforcement officers. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1585300239984254976",
              text: "Left-wing DAs had a responsibility to keep communities safe, but many across the country sold families out for a radical, pro-crime agenda.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570217130246668293",
              text: "Keeping communities safe must become a priority for the Biden White House. \n\n@SenatorHagerty and I sent a letter to @POTUS today urging him to take immediate action to promote safety and support our law enforcement. pic.twitter.com/1eQ6n2vD7e",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1568027369973370881",
              text: "Weak-on-crime prosecutors and lenient sentences are making our communities more dangerous.\n\nIf you commit the crime, you must do the time.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570204583242121224",
              text: "Americans want to feel safe again, and that starts with supporting our police and ending soft-on-crime policies.\n\nThank you @ChuckGrassley, @SenatorBraun, @SenJohnKennedy, and @SenRonJohnson for joining me and @SenatorHagerty to discuss how we help solve the violent crime crisis. pic.twitter.com/jejakD57OF",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540098687162007553",
              text: "I\u2019ve introduced an\u00a0amendment\u00a0to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1641457434098008065",
              text: "The SAFE School Act establishes a $900 million grant program that will allow both public and private schools to train and hire veterans and former law enforcement officers to serve as school safety officers.",
            },
          ],
          question:
            "What strategies will you employ to enhance community safety while promoting fairness and equity?",
        },
        {
          answer:
            "Yes, I champion initiatives that focus on addressing the root causes of crime. I have introduced the Restoring Law and Order Act to increase resources for evidence processing and crime labs, and I cosponsored legislation to reauthorize the Debbie Smith Act to help end the rape kit backlog. I have also sent a letter demanding Biden eliminate soft-on-crime policies and introduced legislation to provide more resources and funding for law enforcement agencies to target violent criminals. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1574941536210427904",
              text: "@joesantarlasci Hi Joe, in response to the violent crime spike in cities across America \u2014 including TN, I introduced the Restoring Law and Order Act to support our police and crack down on violent crime. I have also sent a letter demanding Biden eliminate soft-on-crime policies.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570801941730709505",
              text: "We must improve the rape kit process to put rapists behind bars and get criminals off the streets.\n\nThat\u2019s why I\u2019ve introduced the Restoring Law and Order Act to increase resources for evidence processing and crime labs.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1633624258306887682",
              text: "No citizen should be forced to live in fear of violent criminals, whether in DC, Memphis, or Knoxville.\n\nWe must keep drugs, gangs, and violent criminals out of our communities. We need prosecutors to keep criminals behind bars and keep our communities safe.",
            },
          ],
          question:
            "Are there specific initiatives for addressing the root causes of crime that you champion?",
        },
      ],
      summary:
        "Marsha Blackburn prioritizes safety in communities and believes criminals should be held accountable for their actions. She is against soft-on-crime policies and believes in funding, not defunding, law enforcement. She has introduced the Restoring Law and Order Act to increase resources for evidence processing and crime labs and has also cosponsored legislation to reauthorize the Debbie Smith Act, which aims to end the rape kit backlog. Blackburn has made it clear that she supports law enforcement agencies by proposing more resources and funding for them to combat violent crime. She has called on the Biden administration to eliminate soft-on-crime policies. Additionally, she has suggested hiring veterans and former law enforcement officials as school safety officers as part of a strategy for safer schools.",
    },
    Education: {
      questions: [
        {
          answer:
            "I believe that the quality of public education can be improved by giving parents more control over their children's education. This is why I am advocating for education dollars to follow the student and give parents the power to choose the school that best suits their needs. Additionally, I am working to increase access to higher education by championing trade policies that advance competition in the United States and by sponsoring the Over-the-Counter Hearing Aid legislation that was enacted into law to increase accessibility to hearing aids for millions of Americans. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1468031669047562241",
              text: "It\u00e2\u20ac\u2122s time to have education dollars follow the student and give parents control of their children\u00e2\u20ac\u2122s education again. pic.twitter.com/FcDxeJFaOp",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1377673902743240709",
              text: "As a member of the House, I sponsored the Over-the-Counter Hearing Aid legislation that was enacted into law to increase accessibility to hearing aids for millions of Americans.",
            },
          ],
          question:
            "How will you improve the quality of public education and increase access to higher education?",
        },
        {
          answer:
            "We must reduce our federal debt and stop spending money we do not have on programs we don't want or need. I have proposed cutting federal spending across the board for non-defense items by 1%, 2%, and 5%. We must also reduce our national debt and stop mortgaging our children's future to force through radical tax-and-spend policies. We cannot continue to rack up this federal debt and we cannot hijack our children and grandchildren's futures with this massive debt. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1622779999093661696",
              text: "I filed an amicus brief in support of the respondents in the two Supreme Court cases challenging Biden's student loan forgiveness program, along with Republican Senate Leader McConnell.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1562514267772895232",
              text: "Even Larry Summers has called student loan forgiveness \u201chighly regressive.\u201d\n \nBiden\u2019s decision is a gimmick that won\u2019t help middle-class Americans.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1424518860246593540",
              text: "Why do Democrats insist on mortgaging our children\u00e2\u20ac\u2122s future to force through their radical tax-and-spend policies? pic.twitter.com/OD8H123yZW",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1623695828777934848",
              text: "I\u2019ve proposed cutting federal spending across the board for non-defense items by 1%, 2%, and 5%.\n\nWe cannot continue to rack up this federal debt; we are at $31 trillion and counting. https://t.co/d8xssvb0al",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1387585551461724161",
              text: "What has Biden achieved in his first 100 days?\u00c2\u00a0 \n\u00c2\u00a0\nTrillions in government spending funded by a tax hike and a debt burden on our children and grandchildren. #JointAddress pic.twitter.com/j2oqglI9yL",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1360404847988727809",
              text: "As the U.S. debt quickly rises to record heights, Democrats remain unserious at solving the crucial problem. wsj.com/articles/u-s-d\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1620478181450067968",
              text: "The Democrats\u2019 out-of-control spending will burden our kids and grandkids with our debt.\n\nFor freedom\u2019s sake, we must cut spending and shrink the bureaucracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1424093562879234048",
              text: "The CBO analysis estimates that the infrastructure plan will add at least $256 billion to our national deficit. $256 BILLION of your money. \n\nWe cannot hijack our children and grandchildren\u00e2\u20ac\u2122s futures with this massive debt.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1564292793979265025",
              text: "The White House has yet to give a thorough explanation on how they will pay for Biden\u2019s student loan forgiveness announcement. \n\nThis administration continues to stray further left, with no clear plan for the American people.",
            },
          ],
          question:
            "What measures do you endorse to address the issue of student loan debt?",
        },
        {
          answer:
            "I prioritize policies that support teachers and improve educational outcomes. I believe that education dollars should follow the student and that parents should have control of their children's education. I am also committed to championing trade policies that advance competition in the United States and working with healthcare innovators on ways to increase access to care and lower patient costs. Additionally, I am working to pass the SAFE School Act, which establishes a $900 million grant program to train and hire veterans and former law enforcement officers to serve as school safety officers. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1468031669047562241",
              text: "It\u00e2\u20ac\u2122s time to have education dollars follow the student and give parents control of their children\u00e2\u20ac\u2122s education again. pic.twitter.com/FcDxeJFaOp",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1505993093673271316",
              text: "Moms and dads are troubled by the progressive agenda being pushed in some of our public-school systems. Educators are allowing biological males to steal opportunities from female athletes in the name of progressivism. https://t.co/pagIVPgZqx",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1412818235607638018",
              text: "Teachers union President @rweingarten is waging a war against parents and American families so they can teach Critical Race Theory. They will put money and power over our children every day of the week.\n\nedweek.org/teaching-learn\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1641457434098008065",
              text: "The SAFE School Act establishes a $900 million grant program that will allow both public and private schools to train and hire veterans and former law enforcement officers to serve as school safety officers.",
            },
          ],
          question:
            "Are there policies to support teachers and improve educational outcomes that you prioritize?",
        },
      ],
      summary:
        "Marsha Blackburn strongly advocates for improving the quality of public education and increasing access to higher education. She believes that control of education should be placed in the hands of parents and that education funding should follow the student, ensuring that parents have the ability to choose the schools that best suit their children's needs. She also supports increasing competition in the U.S. via trade policies and sponsors legislation to increase hearing aid accessibility. \n\nIn terms of student loan debt, Blackburn proposes reducing federal spending on non-defense items and decreasing national debt. She is against increasing the burden of debt on future generations via excessive spending and taxation. \n\nShe prioritizes supporting teachers and improving educational outcomes through policy. Blackburn is an advocate of the SAFE School Act, a law that earmarks funds for the training and hiring of veterans and ex-law enforcement officers as school safety officers. Within healthcare, Blackburn works with innovators to increase access to care and lower costs for patients.",
    },
    "Environmental and Climate Policy": {
      questions: [
        {
          answer:
            "I believe that we must take action to address climate change and promote sustainability, but we must do so in a way that does not harm the economy or the livelihoods of hardworking Americans. We must focus on creating jobs and economic opportunities that are sustainable and that will help to reduce our carbon footprint. We must also invest in clean energy sources and incentivize businesses to reduce their emissions. We must also ensure that any regulations are reasonable and do not place an undue burden on businesses or individuals. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1450203151676628994",
              text: "In what capacity is former President Obama attending the UN climate summit to \u00e2\u20ac\u0153lay out the important progress made\u00e2\u20ac\u009d and \u00e2\u20ac\u0153urge more robust action\u00e2\u20ac\u009d? \n\nSounds like a job for the current President.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1358801146664677376",
              text: "Biden\u00e2\u20ac\u2122s climate agenda will not replace the jobs that it has killed. 1 million oil and gas jobs will be lost by 2022.\n\nSolar jobs pay half as much as oil and gas jobs on average and require an entirely different set of skills.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1638279099364921359",
              text: "Joe Biden's veto tells 152 million Americans that funding climate change and ESG are more important to him than their retirement savings. https://t.co/VyXn7LWd7T",
            },
          ],
          question:
            "How will you address climate change and promote sustainability?",
        },
        {
          answer:
            "We need an all of the above energy policy that unleashes American energy, approves new drilling permits, holds lease sales, and approves new energy infrastructure. We should also focus on real relief to lower gas prices, rather than spending up to $300 billion in the name of climate change. We should also make sure to have a plan to make us energy independent again, halt the Keystone Pipeline XL, and rejoin the Paris Climate Accords. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1539612648106508290",
              text: "The solution to lower gas prices is to unleash American energy, approve new drilling permits, hold lease sales and approve new energy infrastructure.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1613579260286963713",
              text: "Have the Democrats thought through their proposal to phase out gas stoves? \n\nIt might cause the restaurant industry to collapse, but as long as it fits the left\u2019s green energy agenda, it\u2019s a small price to pay.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1547714397094612993",
              text: "Instead of focusing on real relief to lower gas prices, the Democrats are considering spending up to $300 billion in the name of climate change. \n\nRead that again.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1532443242545004556",
              text: "Mr. President, an immediate action you could take is to have a plan to make us energy independent again. \n\nYour Green New Deal is a root cause. wsj.com/articles/treas\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1501641958812528642",
              text: "Biden\u2019s Energy agenda: \n\n\u274c Halted the Keystone pipeline\n\u274c Froze new leases to produce American oil\n\u274c Rejoined the Paris Climate Agreement\n\u274c Turned to authoritarian regimes for oil production",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1539791170594914305",
              text: "Joe Biden\u2019s proposal to suspend the gas tax is a fake solution to a crisis. \n\nGet to the root cause, Joe, and unleash American energy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1571937336765595655",
              text: "With winter just around the corner, Americans are facing rising natural-gas prices due to President Biden and the Democrats' assault on American energy production. \n\nThis is why we need an all of the above energy policy. wsj.com/articles/elect\u2026",
            },
          ],
          question: "Strategies for reducing greenhouse gas emissions?",
        },
        {
          answer:
            "The Biden administration has been pushing a radical environmental agenda that will cost American jobs and drive up energy costs. This agenda includes halting the Keystone Pipeline, rejoining the Paris Climate Agreement, and providing amnesty to illegal immigrants. It also ushers in the Green New Deal, gives radical environmentalists an EPA slush fund, and drives up the cost of Medicare. This is not the way to protect the environment and combat climate change. We need to prioritize the needs of our counties and cities over the environmental wishes of the Biden administration. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1352725180515291139",
              text: "Double trouble, double standards, and a way to double your energy costs. This is outcome of the new environmental Executive Orders.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1501641958812528642",
              text: "Biden\u2019s Energy agenda: \n\n\u274c Halted the Keystone pipeline\n\u274c Froze new leases to produce American oil\n\u274c Rejoined the Paris Climate Agreement\n\u274c Turned to authoritarian regimes for oil production",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1353758306620289024",
              text: "Halting the Keystone Pipeline XL & rejoining the Paris Climate Accords means the American people have to foot the bill of climate activism through lower income & fewer jobs. @JonScottFNC https://t.co/zfwFzcvyp8",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1354448234601738242",
              text: "This is what happens when you govern by pen and phone. The jobs of hard working Americans are sacrificed in the name of environmental activism. https://t.co/HjbIApgGrK",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1458924197665816581",
              text: "The Biden administration has been very intentional about pushing the left\u00e2\u20ac\u2122s woke environmental agenda at the cost of the American dream. https://t.co/tHOznlx2jQ",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1638279099364921359",
              text: "Joe Biden's veto tells 152 million Americans that funding climate change and ESG are more important to him than their retirement savings. https://t.co/VyXn7LWd7T",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1547714397094612993",
              text: "Instead of focusing on real relief to lower gas prices, the Democrats are considering spending up to $300 billion in the name of climate change. \n\nRead that again.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1352090558458638342",
              text: "The decision for @POTUS to re-join the Paris Climate Agreement will cost American jobs and force households and small business to pay higher utility bills.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1365365812048121856",
              text: "The Paris Climate Agreement was a burden to American workers and American businesses. It is a grave mistake to re-enter it. https://t.co/knXbJk017E",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1502306829220651010",
              text: "The Biden administration is advocating 100% green energy as the solution for rising energy prices.\n\nIn other words, they want us to trade reliance on Russian oil for reliance on China and Russia for the materials to make electric cars, wind turbines, and solar panels.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1354188020140953602",
              text: "The needs of our counties and cities should be prioritized over the environmental wishes of the Biden administration. I am concerned about the responses from Transportation Sec. Nominee Mayor @PeteButtigieg https://t.co/Ls3DtnurWM",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1376176469701984259",
              text: "The left's weaponized version of environmentalism is a one-way ticket to destroy your individual choice.\u00c2\u00a0\u00c2\u00a0\nclarksvillenow.com/local/blackbur\u00e2\u20ac\u00a6",
            },
          ],
          question: "Policies for environmental protection and climate action?",
        },
      ],
      summary:
        'Marsha Blackburn strongly emphasizes the need for a balanced approach to environmental and climate policy. She believes that the climate change problem must be tackled, but not at the expense of economic growth or harming the livelihoods of Americans. She supports job creation and economic opportunities in the sustainable sector, clean energy investments, and incentivizing businesses to reduce emissions.\n\nWhen it comes to greenhouse gas emissions, Blackburn stresses on an "all of the above" energy policy which includes new permits for drilling, approving infrastructure for energy, and reducing gas prices. She is also for the plan of making the US energy independent and against the notion of halting the Keystone Pipeline XL and rejoining the Paris Climate Accords.\n\nShe voices concerns about the current administration\'s environmental agenda, which she views as radical and detrimental to American jobs and energy costs. She disagrees with decisions like halting the Keystone Pipeline, rejoining the Paris Climate Agreement, and what she sees as potential increases in costs associated with measures like the Green New Deal. She believes that the needs of counties and cities should be prioritized over the "environmental wishes" of the administration.',
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      questions: [
        {
          answer:
            "The United States must take a firm stance against Russia's war crimes against Ukraine and its people. We must provide Ukraine with lethal defensive military aid and levy all possible sanctions against the Kremlin, including targeting their energy sector and removing them from SWIFT. We must also send Ukraine the lethal assistance they need to defend themselves and impose sanctions before it is too late. The United States must also stand firmly against malign actors and support the people of Ukraine. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1510998086084173825",
              text: "The heinous war crimes Putin is carrying out against innocent Ukrainian civilians are monstrous and despicable. \n\n\nThe USA unequivocally stands with Ukraine in its fight to preserve its sovereignty.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1496990648603275266",
              text: "Ukraine is an independent & sovereign nation. The U.S. & our NATO partners should continue to support Ukraine & provide lethal defensive military aid. \n\nBiden must levy all possible sanctions against the Kremlin, including targeting their energy sector & removing them from SWIFT.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1496101410529845256",
              text: "Meanwhile, Joe Biden has refused to take meaningful action, and his weakness has emboldened Moscow. \n\nLast year, I urged President Biden to take decisive action by sending Ukraine the lethal assistance they need to defend themselves and imposing sanctions before it was too late.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1485418362779979776",
              text: "Just four days ago, Biden stood in front of the world and essentially gave Russia the green light to invade.\n\nNow, the State Department has ordered the families of US diplomats to evacuate Ukraine.",
            },
          ],
          question:
            "How would you approach the ongoing Ukraine conflict, and what role do you believe the United States should play in resolving it?",
        },
        {
          answer:
            "My foreign policy priorities are to strengthen our military and relationships with countries who share our values of freedom and democracy, such as Japan and Israel. Additionally, I plan to champion trade policies that advance competition in the United States and work with healthcare innovators on ways we can increase access to care and lower patient costs. I am also committed to advancing religious freedom and standing united against the Chinese Communist Party's goal of global domination. I am helping to lead the effort to increase military support to Taiwan and strengthen security for Pacific Island nations, as well as maintain a strong relationship with Israel in order to further peace and stability in the Middle East. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1591869318446600192",
              text: "It\u2019s time we instead focus on strengthening our military and relationships with countries who share our values of freedom and democracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1363988562857906184",
              text: "The United States and Japan share a treasured alliance that is increasingly important to the global national security landscape. We are grateful for this partnership, and look forward to a continued future working hand-in-hand.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1382397528302321678",
              text: "Advancing religious freedom has long been a foreign policy priority for the U.S. We must ensure that its importance is not diminished.\n \nrealclearpolitics.com/articles/2021/\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1541783988766998528",
              text: "Last week, @SenBrianSchatz and I shared a conversation with several Pacific Island Ambassadors to hear their priorities for the region and provide an update on the work Congress is doing to counter Communist China and benefit our global interests. pic.twitter.com/fW64lwizMn",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1562791957729927168",
              text: "In Papua New Guinea, I met with Secretary of Defense Akipe and Deputy Chief of Defense Force Commodore Polewara focused on expanding our strategic relationship.\n\u00a0\nWe know the CCP wants one thing \u2013 global domination.\u00a0\n\u00a0\nWe must stand united against it. pic.twitter.com/FPnEj9vzzi",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1554963399632687104",
              text: "The Senate has approved Finland and Sweden's accession into NATO.\n\nI voted for this important step to bolster the Alliance and defend our values and sovereignty. \n\nThe U.S. must remain strong against the New Axis of Evil \u2014 Communist China, Russia, Iran, and North Korea.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1563195057657696258",
              text: "One of the reasons I\u2019ve come to Taipei to have these conversations with Taiwan\u2019s leaders is because we can\u2019t afford to let the Chinese Communist Party write the world\u2019s foreign policy. https://t.co/5ampJ0pCgg",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1564389434086268928",
              text: "Communist China has made their priorities crystal clear \u2014 they want global domination. \n\nIt\u2019s important we stand united with Taiwan and our freedom-loving partners around the globe. https://t.co/s597dYlc4I",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1563137849259888648",
              text: ".@MOFA_Taiwan\u00a0and I are both committed to strengthening bilateral ties between the United States and Taiwan.\u00a0\n\u00a0\nIn Congress, I am helping to lead the effort to increase military support to Taiwan and strengthen security for Pacific Island nations. pic.twitter.com/K5j94lrP3g",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1567223555095412736",
              text: "Instead of emboldening the Iranian regime, the U.S. should focus on strengthening our relationship with America's greatest ally in the Middle East \u2014 Israel.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1591869313363103744",
              text: "President Xi has no interest in partnering with the United States. His sole interest is global domination, and he will readily take advantage of the Biden administration\u2019s weak foreign policy to inch closer to that goal.",
            },
          ],
          question:
            "What are your foreign policy priorities, particularly in relation to allies and international organizations?",
        },
        {
          answer:
            "I am committed to championing trade policies that advance competition in the United States and strengthen our manufacturing sector. I have legislation that will assess the extent to which sister city agreements with cities in Communist China could be a threat to national security. I am also working to end our dependence on Communist China by producing our own products and creating American jobs. Additionally, I am deeply concerned about corporations partnering with CCP controlled companies and will work to ensure that all American companies succeed in America. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479200326624366595",
              text: "The global supply chain crisis resulting from our dependence on China underscored the importance of strengthening U.S. manufacturing. My legislation with @SenGaryPeters will streamline programs run by @CommerceGov to eliminate government waste & improve efficiency.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1649085331160064001",
              text: "Over 150 American cities have sister city agreements with cities in Communist China. \n\nI have legislation that will assess the extent to which these agreements could be a threat to national security.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1598707140289433600",
              text: "We must end our dependence on Communist China. \n\nProducing our own products ensures that our supply chains are not disrupted by other nations and creates American jobs.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1491442276064587781",
              text: "The U.S. trade deficit hit an all-time high last year, increasing by 27%. This is why it\u00e2\u20ac\u2122s critical we stop our reliance on countries like Communist China and bring manufacturing back to the USA.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1625583502837047298",
              text: "We are deeply concerned to learn @Ford is partnering with a CCP controlled company. It is inappropriate for US corporations to partner with an adversary in order to sneak into our country. We want all American companies to succeed in America \u2014 not by doing business with the CCP.",
            },
          ],
          question:
            "How will you navigate trade agreements and global economic relationships to benefit American interests?",
        },
        {
          answer:
            "I believe that the United States must take a strong stance against the Communist Chinese Party and their attempts to achieve global domination. We must stand together with our allies and partners to ensure that our values of freedom and democracy are respected. We must also strengthen our military and diplomatic presence in countries around the world to deter security challenges. Additionally, I plan to champion trade policies that advance competition in the United States. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1591869318446600192",
              text: "It\u2019s time we instead focus on strengthening our military and relationships with countries who share our values of freedom and democracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1562560583240945664",
              text: "In Fiji, I met with our U.S. political and defense leadership. It is vitally important to have a strong U.S. diplomatic presence on each Pacific Island Country. @USEmbassySuva @USAmbSuva",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1351910629196623872",
              text: "Secretary @mikepompeo\u00c2\u00a0and the Trump Administration changed the global conversation on China and addressed the wide range of threats posed by the Communist Chinese Party including unfair trade practices, human rights violations, and IP theft.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1381361851922059272",
              text: "Beijing is fighting on all fronts to achieve global dominance. The U.S. must stand solid and resolute for democracy. pic.twitter.com/MeY8Qnfvkg",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1575589989403922432",
              text: "Strong engagement between the U.S. and Solomon Islands is critical to deterring security challenges in the region. \n\nDuring my visit in August, I spoke candidly with leaders across the spectrum about the dangers of Communist China's influence.",
            },
          ],
          question:
            "What approach will you take to address international conflicts and promote diplomacy?",
        },
      ],
      summary:
        "Marsha Blackburn advocates for a strong and firm stance in foreign policy. Her approach to the ongoing Ukraine conflict involves providing lethal defensive military aid to Ukraine and imposing all possible sanctions against Russia, including removing them from SWIFT and targeting their energy sector. Her foreign policy priorities include strengthening military relationships with countries that share American values such as freedom and democracy, like Japan and Israel. She is also committed to advancing religious freedom and standing against the Chinese Communist Party's goal for global domination. In terms of trade agreements, she intends to champion policies that advance competition in the United States and strengthen the U.S manufacturing sector. Additionally, she aims to assess potential national security threats stemming from sister city agreements with cities in Communist China. Her approach to address international conflicts and promote diplomacy revolves around strengthening America's military and diplomatic presence globally to ensure that the values of freedom and democracy are upheld.",
    },
    Guns: {
      questions: [
        {
          answer:
            "I believe that the most effective way to reduce gun violence is to focus on getting violent criminals off our streets and supporting our police. We must also ensure that law-abiding citizens are not criminalized for owning firearms. We must also invest in school security and mental health treatment, while also ensuring that Americans' constitutional right to keep and bear arms is not infringed upon. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540098687162007553",
              text: "I\u2019ve introduced an\u00a0amendment\u00a0to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1636447993732669440",
              text: "Joe Biden should focus less on criminalizing law-abiding citizens' gun ownership and more on getting violent criminals off our streets.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540159698929913857",
              text: "Enhanced funding for school security & mental health treatment is a good thing \u2014 but it\u2019s being combined with giving the left a foothold to limit the 2nd Amendment. I voted against the gun control bill because Americans' constitutional right to keep & bear arms is not negotiable.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540143798642249728",
              text: "Shall not be infringed. \n\nI\u2019m a no-vote on the gun control bill that would erode Americans\u2019 constitutional right to bear arms. https://t.co/Ye3mGDaWEk",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1633624258306887682",
              text: "No citizen should be forced to live in fear of violent criminals, whether in DC, Memphis, or Knoxville.\n\nWe must keep drugs, gangs, and violent criminals out of our communities. We need prosecutors to keep criminals behind bars and keep our communities safe.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570204583242121224",
              text: "Americans want to feel safe again, and that starts with supporting our police and ending soft-on-crime policies.\n\nThank you @ChuckGrassley, @SenatorBraun, @SenJohnKennedy, and @SenRonJohnson for joining me and @SenatorHagerty to discuss how we help solve the violent crime crisis. pic.twitter.com/jejakD57OF",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540037265057103872",
              text: "SCOTUS\u2019 ruling on New York\u2019s restrictive concealed carry regulations is a significant win for the Second Amendment.\n\nIt\u2019s simple: law-abiding Americans have a right to keep and bear arms.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1574941536210427904",
              text: "@joesantarlasci Hi Joe, in response to the violent crime spike in cities across America \u2014 including TN, I introduced the Restoring Law and Order Act to support our police and crack down on violent crime. I have also sent a letter demanding Biden eliminate soft-on-crime policies.",
            },
          ],
          question:
            "What gun control measures do you believe will be effective in reducing gun violence?",
        },
        {
          answer:
            "I'm committed to protecting the Second Amendment rights of law-abiding Americans while also ensuring that our communities are safe. I've introduced an amendment to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers. I'm also fighting back against radical left policies by introducing 13 different amendments to counteract the liberal agenda that is putting our nation on the wrong path. We must keep drugs, gangs, and violent criminals out of our communities. We need prosecutors to keep criminals behind bars and keep our communities safe. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540159698929913857",
              text: "Enhanced funding for school security & mental health treatment is a good thing \u2014 but it\u2019s being combined with giving the left a foothold to limit the 2nd Amendment. I voted against the gun control bill because Americans' constitutional right to keep & bear arms is not negotiable.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540098687162007553",
              text: "I\u2019ve introduced an\u00a0amendment\u00a0to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1357438909995696128",
              text: "I\u00e2\u20ac\u2122m fighting back against radical left policies by introducing 13 different amendments to counteract the liberal agenda that is putting our nation on the wrong path. https://t.co/qNAPwTyhPB",
            },
          ],
          question:
            "How do you plan to balance Second Amendment rights with public safety concerns?",
        },
        {
          answer:
            "Absolutely. I have introduced an amendment to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers. I also introduced the Restoring Law and Order Act to support our police and crack down on violent crime. We must keep drugs, gangs, and violent criminals out of our communities and ensure that prosecutors keep criminals behind bars and keep our communities safe. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540098687162007553",
              text: "I\u2019ve introduced an\u00a0amendment\u00a0to the gun control package to allow for the training and hiring of veterans and former law enforcement officials to serve as school safety officers.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1371869439969402883",
              text: "H.R. 8, Democrats\u00e2\u20ac\u2122 latest attempt to strip your 2A rights, would prohibit the transfer of firearms between law abiding citizens. @KyleKashuv and I discuss the 2A from his perspective as a school safety advocate on Unmuted with Marsha! fb.watch/4gDwtE76St/",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1574941536210427904",
              text: "@joesantarlasci Hi Joe, in response to the violent crime spike in cities across America \u2014 including TN, I introduced the Restoring Law and Order Act to support our police and crack down on violent crime. I have also sent a letter demanding Biden eliminate soft-on-crime policies.",
            },
          ],
          question:
            "Are there policies to promote responsible gun ownership that you support?",
        },
      ],
      summary:
        "Marsha Blackburn strongly supports the Second Amendment and is dedicated to reducing gun violence by focusing on combating drug usage, gangs, and violent crime, rather than by imposing restrictions on responsible, law-abiding gun owners. She introduces amendments to gun control packages to promote responsible gun ownership, such as offering training and employment in school security to veterans and former law enforcement officials. She also prioritizes mental health treatment and school security. Furthermore, she has introduced the Restoring Law and Order Act to support police efforts to combat violent crime. Overall, she seeks a balance between protecting citizens' right to bear arms and ensuring community safety.",
    },
    Healthcare: {
      questions: [
        {
          answer:
            "I am committed to making healthcare accessible to all Americans, regardless of income. I am working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda to increase access to care and lower patient costs. Additionally, I have sponsored the Over-the-Counter Hearing Aid legislation to increase accessibility to hearing aids for millions of Americans. I have also introduced legislation with my Senate colleagues that puts more decisions in the hands of the states to ensure the most vulnerable populations have access to quality healthcare. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1637099032395886592",
              text: "Access to healthcare is critical, which is why I'm working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda.\n\nwdef.com/blackburn-tout\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1389272287875112964",
              text: "For the health and safety, for the economic development, for the continued growth of rural areas, you have to have access to affordable healthcare. That\u00e2\u20ac\u2122s why I have made it a priority to keep rural hospitals open with the Save Rural Hospitals Act.\u00c2\u00a0wjhl.com/news/local/the\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1377673902743240709",
              text: "As a member of the House, I sponsored the Over-the-Counter Hearing Aid legislation that was enacted into law to increase accessibility to hearing aids for millions of Americans.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1475984739601424387",
              text: "Now more than ever, access to quality health care is critical. \n\nIn 2021, I kept my focus on ensuring all Tennesseans have the resources they need to stay healthy, and I opposed Biden\u00e2\u20ac\u2122s unconstitutional COVID vaccine mandates. https://t.co/3KvClnnXyh",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479252878489444357",
              text: "The Biden Medicaid policies will result in less flexibility for states like TN to care for the most vulnerable populations. That\u00e2\u20ac\u2122s why I\u00e2\u20ac\u2122ve introduced legislation with my Senate colleagues that put more decisions in the hands of the states.",
            },
          ],
          question:
            "What steps will you take to make healthcare accessible to all Americans, regardless of income?",
        },
        {
          answer:
            "I am committed to working with healthcare innovators to increase access to care and lower patient costs. Additionally, I am championing trade policies that will advance competition in the United States. I have proposed cutting federal spending across the board for non-defense items by 1%, 2%, and 5%. We must reduce our federal debt and stop spending money we do not have on programs we don't want or need. I am also introducing bipartisan legislation as part of our Rural Healthcare Agenda. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1620478181450067968",
              text: "The Democrats\u2019 out-of-control spending will burden our kids and grandkids with our debt.\n\nFor freedom\u2019s sake, we must cut spending and shrink the bureaucracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1637099032395886592",
              text: "Access to healthcare is critical, which is why I'm working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda.\n\nwdef.com/blackburn-tout\u2026",
            },
          ],
          question:
            "How do you plan to address the issue of skyrocketing healthcare costs and medical debt?",
        },
        {
          answer:
            "Absolutely. I have made it a priority to keep rural hospitals open with the Save Rural Hospitals Act, and I'm working with my Senate colleagues to introduce bipartisan legislation as part of our Rural Healthcare Agenda. Americans do not want a one-size-fits-all approach to health care, so I'm introducing legislation that puts more decisions in the hands of the states. Additionally, I'm going to the Senate floor to call a vote on my legislation to close loopholes that allow big abortion providers such as Planned Parenthood access to Title X family planning funds. I'm also working with @SenGaryPeters to streamline programs run by @CommerceGov to eliminate government waste & improve efficiency. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1389272287875112964",
              text: "For the health and safety, for the economic development, for the continued growth of rural areas, you have to have access to affordable healthcare. That\u00e2\u20ac\u2122s why I have made it a priority to keep rural hospitals open with the Save Rural Hospitals Act.\u00c2\u00a0wjhl.com/news/local/the\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1359961781339357190",
              text: "Americans do not want a one-size-fits-all approach to health care.\n \nBiden care means less choice and longer waits for care.\ntennessean.com/story/opinion/\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479252878489444357",
              text: "The Biden Medicaid policies will result in less flexibility for states like TN to care for the most vulnerable populations. That\u00e2\u20ac\u2122s why I\u00e2\u20ac\u2122ve introduced legislation with my Senate colleagues that put more decisions in the hands of the states.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1475984739601424387",
              text: "Now more than ever, access to quality health care is critical. \n\nIn 2021, I kept my focus on ensuring all Tennesseans have the resources they need to stay healthy, and I opposed Biden\u00e2\u20ac\u2122s unconstitutional COVID vaccine mandates. https://t.co/3KvClnnXyh",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1357096248201912322",
              text: "For far too long, abortion providers have siphoned taxpayer funding away from Americans. Today, I'm going to the Senate floor to call a vote on my legislation to close loopholes that allow big abortion providers such as Planned Parenthood access to Title X family planning funds.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1377673902743240709",
              text: "As a member of the House, I sponsored the Over-the-Counter Hearing Aid legislation that was enacted into law to increase accessibility to hearing aids for millions of Americans.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1351914343735189505",
              text: "The Trump Administration implemented the VA MISSION Act which revolutionized the VA Community Care system to deliver quality, convenient, and timely care for our nation\u00e2\u20ac\u2122s heroes.\u00c2\u00a0 President Trump expanded and improved VA care for veterans, their caregivers, and survivors.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479200326624366595",
              text: "The global supply chain crisis resulting from our dependence on China underscored the importance of strengthening U.S. manufacturing. My legislation with @SenGaryPeters will streamline programs run by @CommerceGov to eliminate government waste & improve efficiency.",
            },
          ],
          question:
            "Are there specific healthcare reforms you believe are necessary to improve the system?",
        },
      ],
      summary:
        "Marsha Blackburn is dedicated to making healthcare accessible for all Americans, regardless of income. She aims to partner with her Senate colleagues to introduce bipartisan legislation under the Rural Healthcare Agenda to increase access to care and decrease patient costs. Blackburn has sponsored the Over-the-Counter Hearing Aid legislation to make hearing aids more accessible and is advocating for state-level decisions to ensure vulnerable populations receive quality healthcare.\n\nTo address high healthcare costs and medical debt, Blackburn proposes cutting federal spending on non-defense items, working with healthcare innovators, advancing competition via trade policies, and introduces more legislation under the Rural Healthcare Agenda. \n\nFor healthcare reform, Blackburn is focusing on keeping rural hospitals open with the Save Rural Hospitals Act, returning decision-making power on healthcare to the states, and eliminating loopholes for big abortion providers' access to Title X family planning funds. She is also working on streamlining programs to reduce government waste and improve efficiency.",
    },
    Immigration: {
      questions: [
        {
          answer:
            "We need to secure our borders and close immigration loopholes to ensure the safety of our nation. We must also strengthen the integrity of the asylum process and end catch and release policies. We must also ensure that hardworking taxpayers are not footing the bill for illegal immigrants who break the law to get here. We must also reinstate Remain in Mexico, build the wall, and end Title 42. We must also ensure that no Green Cards are given to illegal immigrants. We must also ensure that no amnesty is given to illegal immigrants. We must also ensure that no app is created to make illegal immigrants \u201clegal\u201d. We must also ensure that no corporate America is allowed to buy citizenship for immigrants to replace American workers and surpass immigration caps. We must also ensure that no radical left agenda is implemented that would pave the way for thousands of illegal immigrants to flood our border each day. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1455948017207496708",
              text: "The Biden administration wants to let corporate America buy citizenship for immigrants to replace American workers and surpass immigration caps.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1362789854980014084",
              text: "Biden\u00e2\u20ac\u2122s immigration bill provides blanket amnesty to millions of illegal immigrants. This reckless plan only puts American workers and our country\u00e2\u20ac\u2122s security at risk.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1651572706658197505",
              text: "The CBP has warned us that ending Title 42 will pave the way for thousands of illegal immigrants to flood our border each day.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1440037817959153664",
              text: "The radical left is so intent on passing amnesty for illegal immigrants that they want to throw the rules out the window to get it done. foxnews.com/politics/omar-\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1425508421147766788",
              text: "Hardworking taxpayers do not want to foot the bill for illegal immigrants who break the law to get here. We are a nation of laws.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1426560676269629444",
              text: "Reinstate Remain in Mexico, end catch and release, and build the wall. Those are the only policy options that should be on the table.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1402429830000431108",
              text: "No more Green Cards for illegal immigrants according to an unanimous decision from the Supreme Court. That definitely throws a wrench in Biden\u00e2\u20ac\u2122s immigration plans.\nwsj.com/articles/supre\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1527240120860033026",
              text: "Illegal immigrants in the size of a new town in any state across America could start coming across the southern border if Title 42 ends next week. pic.twitter.com/p3YJNSdiHy",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1520888868832256000",
              text: "Secretary Mayorkas just admitted ending Title 42 will generate an unprecedented influx of illegal immigrants at the southern border. https://t.co/EjpZTiFdKB",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1612650472179130371",
              text: "Joe Biden\u2019s new plan is to have illegal immigrants apply for asylum via an app so that when they show up, they are \u201clegal.\u201d",
            },
          ],
          question:
            "How will you approach comprehensive immigration reform, including pathways to citizenship?",
        },
        {
          answer:
            "I support the immediate enforcement of the immigration laws we currently have in place, ending catch and release, embracing Remain in Mexico, keeping Title 42, and building the wall. We must also ensure that Border Patrol and CBP have the resources necessary to secure our border. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1488196412433215488",
              text: "Our Border Patrol agents are overrun and overwhelmed by the unprecedented number of immigrants entering illegally. They need support and resources.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1428091535007129601",
              text: "There is a crisis in the Middle East. The border needs to be secured immediately to prevent any terrorist from entering our county.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1612789651864961025",
              text: "Border Patrol agents are telling me the number one thing they need to help protect our country is for Pres. Biden to simply enforce the immigration laws we currently have in place.\n\nSince Biden took office, over 4.3 million migrants have attempted to illegally enter our country. https://t.co/ZKfgsqQMj5",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1426560676269629444",
              text: "Reinstate Remain in Mexico, end catch and release, and build the wall. Those are the only policy options that should be on the table.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1635048595224399874",
              text: "Thousands of illegal immigrants are currently attempting to rush the border in El Paso.\n\nPresident Biden needs to immediately ensure CBP and border patrol have the resources necessary to secure our border.\n\nNext, let\u2019s build that wall!",
            },
          ],
          question:
            "What measures do you support for border security and managing immigration flows?",
        },
        {
          answer:
            "I believe that we must secure our borders and enforce our immigration laws. We must also ensure that our immigration system is fair and just. We must prioritize the safety of our citizens and the security of our nation. We must also ensure that those who come to our country legally are treated with respect and dignity. We must also ensure that those who break our laws are held accountable. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1425508421147766788",
              text: "Hardworking taxpayers do not want to foot the bill for illegal immigrants who break the law to get here. We are a nation of laws.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1612789651864961025",
              text: "Border Patrol agents are telling me the number one thing they need to help protect our country is for Pres. Biden to simply enforce the immigration laws we currently have in place.\n\nSince Biden took office, over 4.3 million migrants have attempted to illegally enter our country. https://t.co/ZKfgsqQMj5",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1426560676269629444",
              text: "Reinstate Remain in Mexico, end catch and release, and build the wall. Those are the only policy options that should be on the table.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1471948017607532550",
              text: "Despite numerous power-driven attempts by the Democrats, the rules are clear \u00e2\u20ac\u201d you can\u00e2\u20ac\u2122t shove amnesty for all illegal immigrants into a budget bill.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1455618153074003975",
              text: "Providing amnesty for illegal immigrants is not the fix for a border crisis, yet @POTUS and the Democrats won\u00e2\u20ac\u2122t let up on including it in their reconciliation package.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1488178438552891395",
              text: "Since Obama\u00e2\u20ac\u2122s unconstitutional DACA took effect, U.S. Border Patrol has apprehended nearly 6 million illegal immigrants from 165 countries \u00e2\u20ac\u201d not including the \u00e2\u20ac\u02dcgot-aways.\u00e2\u20ac\u2122",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1491585486758064131",
              text: "We haven\u00e2\u20ac\u2122t forgotten about TSA accepting arrest warrants as ID for illegal immigrants. \n\nWe haven\u00e2\u20ac\u2122t forgotten about Biden\u00e2\u20ac\u2122s flights for illegals in the dead of night \u00e2\u20ac\u201d funded by taxpayer dollars. \n\nThe American people deserve answers.",
            },
          ],
          question:
            "Are there policies to address the status of undocumented immigrants that you advocate?",
        },
      ],
      summary:
        "Marsha Blackburn is focused on the strict enforcement of existing immigration laws, increased border security, and the prevention of illegal immigration. She advocates for ending 'catch and release' policies, maintaining the 'Remain in Mexico' policy, keeping Title 42, and continually building the border wall. She emphasizes the need for allocating proper resources for the Border Patrol and CBP. Furthermore, she opposes amnesty for undocumented immigrants and underlines the significance of holding lawbreakers accountable. Her approach to immigration embraces the principles of fairness and justice while ensuring that legal immigrants are treated with respect.",
    },
    "Inflation and Prices": {
      questions: [
        {
          answer:
            "We must take action to reduce the burden of inflation on everyday Americans. That's why I introduced the Stop The Inflationary Spending Spree Act to force Democrats to work on bipartisan solutions instead of forcing through increased spending that will hurt our economy. We must also unleash American energy and stop the endless spending spree. We must also reduce taxes on hardworking families and focus on policies that will actually reduce inflation. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1482004445919653889",
              text: "During my tele-town hall with Tennesseans this week, 93% said inflation adversely affects their family budget.\n\nInflation is a tax on every American, and Joe Biden is ignoring the problem.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1569774422139064322",
              text: "While President Biden and the left brag about their $739 billion tax-and-spend deal, American families are hurting and stressed.\n\u00a0\nInflation is at a 40-year high, the border is not secure, and the violent crime in our communities is rampant.\n\u00a0\nWhat\u2019s their plan?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1387402994363289603",
              text: "Joe Biden\u00e2\u20ac\u2122s actions are quickly leading us down a steep road of inflation. From here on, it's increased government spending and increased prices for goods, but less money in the pockets of the American people.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1565495975552196608",
              text: "The Democrats in control of the White House, U.S. Senate, and House of Representatives are responsible for the 40-year high inflation crippling our economy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1470899553788440586",
              text: "Tennesseans cannot afford the left\u00e2\u20ac\u2122s endless spending spree. Today, I introduced the Stop The Inflationary Spending Spree Act to force Democrats to work on bipartisan solutions instead of forcing through increased spending that will hurt our economy. dailywire.com/news/exclusive\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1574395758711013376",
              text: "Even if prices stopped rising today, inflation will already cost hardworking American families an extra $8,616 in the coming year \u2014 courtesy of Joe Biden and the DC Democrats\u2019 policies.",
            },
          ],
          question:
            "What measures do you propose to mitigate the impact of inflation on everyday Americans?",
        },
        {
          answer:
            "Yes, I support policies that increase access to care and lower patient costs, champion trade policies that advance competition in the United States, and support bills that help get supply chains back on track by making key data widely available to manufacturers. Additionally, I believe we need an all of the above energy policy to reduce gas prices and inflation. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1581983854147276807",
              text: "Biden\u2019s economic advisor is claiming tax credits to weatherize homes will help reduce inflation next year. \n\nAmerican families are feeling the pressure from 40-year high inflation, and the White House is more concerned with their green energy agenda.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1389272287875112964",
              text: "For the health and safety, for the economic development, for the continued growth of rural areas, you have to have access to affordable healthcare. That\u00e2\u20ac\u2122s why I have made it a priority to keep rural hospitals open with the Save Rural Hospitals Act.\u00c2\u00a0wjhl.com/news/local/the\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1508530709337714692",
              text: "Americans are suffering under record-high inflation. Biden\u2019s proposed $5 trillion in new spending is packed full of socialism \u2014 including universal Pre-K & climate change. \n\nWe will not support spending more on the left\u2019s wish list, which will kill jobs & create more inflation.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1571937336765595655",
              text: "With winter just around the corner, Americans are facing rising natural-gas prices due to President Biden and the Democrats' assault on American energy production. \n\nThis is why we need an all of the above energy policy. wsj.com/articles/elect\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1467193438617669643",
              text: "The supply chain crisis is hurting businesses and consumers, from product shortages to rising prices and job loss. This week, I supported a bill to help get supply chains back on track by making key data widely available to manufacturers. @SenatorMenendez @NormaJTorres @RepChuck",
            },
          ],
          question:
            "Are there specific policies you support to stabilize prices and ensure affordability?",
        },
      ],
      summary:
        "Marsha Blackburn is focused on reducing the burden of inflation on everyday Americans. She introduced the Stop The Inflationary Spending Spree Act to motivate bipartisan solutions and to reduce excessive spending. She advocates for the reduction of taxes on hardworking families and the implementation of policies that will directly alleviate inflation. Moreover, she encourages measures to increase competition in trade, improve supply chain functioning and pursue a wide-ranging energy policy to mitigate gas prices and inflation.",
    },
    "Jobs & the Economy": {
      questions: [
        {
          answer:
            "I will work to enact policies that support our economy, increase access to healthcare, champion trade policies that advance competition in the United States, strengthen U.S. manufacturing, unleash American energy, and reduce government spending. I will also oppose policies that target gig economy workers, raise taxes on hardworking families, and reintroduce the Green New Deal. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1400885285328769031",
              text: "Another missed target\u00e2\u20ac\u201dthe\u00c2\u00a0Biden administration struggles with adding jobs. It\u00e2\u20ac\u2122s time to change course from the left-wing policies and start enacting policy that supports our economy!\nfoxbusiness.com/economy/jobs-n\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621133933193875456",
              text: "I especially look forward to working with healthcare innovators on ways we can increase access to care and lower patient costs. \n\nAdditionally, I plan to champion trade policies that advance competition in the United States.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479200326624366595",
              text: "The global supply chain crisis resulting from our dependence on China underscored the importance of strengthening U.S. manufacturing. My legislation with @SenGaryPeters will streamline programs run by @CommerceGov to eliminate government waste & improve efficiency.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1539612648106508290",
              text: "The solution to lower gas prices is to unleash American energy, approve new drilling permits, hold lease sales and approve new energy infrastructure.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1580308891438841856",
              text: "There are two reasons why Biden is targeting gig economy workers: control and appeasing his union donors. https://t.co/DUSKOeiraM",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1385616086683602945",
              text: "The Green New Deal would put thousands of Americans out of work. To reintroduce it as we come out of a once in a lifetime economic recovery is an insult to the American people.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
          ],
          question:
            "What strategies will you employ to stimulate job growth and reduce unemployment?",
        },
        {
          answer:
            "We need to get big government out of the way and support our small businesses by reducing regulations and taxes. We should also focus on strengthening U.S. manufacturing and eliminating government waste and inefficiency. We should also ensure that anti-competitive policies are not hurting developers. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1423669114107834378",
              text: "As I speak with Tennessee small business owners across the state, one thing is clear: They can\u00e2\u20ac\u2122t afford more lockdowns and unnecessary government spending.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1545910059137486849",
              text: "In Lawrence County, local leaders are concerned about Biden\u2019s regulations hurting small businesses. \n\nWe need to get big government out of the way and support our small business owners. @LawrenceCoTN pic.twitter.com/so9jUTOVd8",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1475557419891793922",
              text: "Kamala should come to Tennessee and listen to small businesses and manufacturers about how the supply chain and inflation are impacting them. \n\nShe can also speak with local elected officials and law enforcement about how the open southern border is endangering our communities.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1365426366443905026",
              text: "Under the Trump Administration\u00e2\u20ac\u2122s deregulatory push, businesses saved roughly $160 billion, according to @AAF.\u00c2\u00a0\u00c2\u00a0\n\nBringing regulations back will hurt the economy as we try to recover from COVID-19.\u00c2\u00a0\namericanactionforum.org/insight/the-le\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479200326624366595",
              text: "The global supply chain crisis resulting from our dependence on China underscored the importance of strengthening U.S. manufacturing. My legislation with @SenGaryPeters will streamline programs run by @CommerceGov to eliminate government waste & improve efficiency.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1545910039302619139",
              text: "Tennessee is the best state to start and grow a business, thanks to our state\u2019s fiscally responsible policies and conservative leadership. \n\nIt's encouraging to see so much innovation and growth in Lincoln County. @CopperweldUSA pic.twitter.com/dVA3mhUYzC",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1569774422139064322",
              text: "While President Biden and the left brag about their $739 billion tax-and-spend deal, American families are hurting and stressed.\n\u00a0\nInflation is at a 40-year high, the border is not secure, and the violent crime in our communities is rampant.\n\u00a0\nWhat\u2019s their plan?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1434164106353954816",
              text: "Apple\u00e2\u20ac\u2122s decision to recognize how its anti-competitive policies are hurting developers is a start. The Open App Markets Act I introduced with @SenBlumenthal and @SenAmyKlobuchar would make similar changes to help developers across the board.\nbloomberg.com/news/articles/\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "How can the government support small businesses and entrepreneurs to boost the economy?",
        },
        {
          answer:
            "Absolutely. I am committed to helping hardworking Americans get ahead. I have been working to lower everyday costs for Americans, champion trade policies that advance competition in the United States, and protect American workers and their jobs. I have also voted against Janet Yellen's plan to spend trillions more in stimulus and push for high taxes and a federalized minimum wage. We need to focus on policies that will help Americans get ahead, not ones that will lead to more pain and suffering. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1567615337847218176",
              text: "Joe Biden claims his economic plan is working, yet the data shows that inflation is wiping out wage gains. \n\nAmericans are getting poorer in Joe Biden's economy. https://t.co/F7XrZVb5Gv",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1569774422139064322",
              text: "While President Biden and the left brag about their $739 billion tax-and-spend deal, American families are hurting and stressed.\n\u00a0\nInflation is at a 40-year high, the border is not secure, and the violent crime in our communities is rampant.\n\u00a0\nWhat\u2019s their plan?",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1353865637249097728",
              text: "The government can\u00e2\u20ac\u2122t spend its way to prosperity. Janet Yellen\u00e2\u20ac\u2122s plan to spend trillions more in stimulus and push for high taxes and a federalized minimum wage are reasons I voted no to confirm her as Treasury Secretary. https://t.co/Z0DnVUQdwj",
            },
          ],
          question:
            "Do you have a plan to address income inequality and promote wage growth?",
        },
      ],
      summary:
        "Marsha Blackburn is focused on enacting policies that foster job growth and reduce unemployment. She proposes to increase access to healthcare, champion trade policies, strengthen U.S. manufacturing, and boost American energy. She also plans to reduce government spending and opposes policies that could adversely impact gig economy workers or raise taxes on families.\n\nTo support small businesses and entrepreneurs, Blackburn advocates for less government intervention, reduction in taxes and regulations, and avoiding anti-competitive policies. She also emphasizes the importance of strengthening U.S. manufacturing and addressing government inefficiency.\n\nRegarding income inequality and wage growth, she aims to lower everyday costs for Americans, protect American workers, and promote competition. She opposes measures such as a high federal minimum wage and large stimulus spending and believes that policies should not result in increased hardships for the American public.",
    },
    "National Security": {
      questions: [
        {
          answer:
            "We must ensure that our nation is secure and that our citizens are safe. To do this, we must support policies that prioritize our national security and protect our sovereignty. We must also recognize the importance of data privacy and support policies that protect consumers from data grabs by Big Tech companies. We must also remain vigilant about who our children are giving personal information to, turn on privacy settings, and have conversations about the dangers of social media. We need a national privacy standard that provides real solutions for consumers and businesses. We must also ensure that our civil liberties are respected and that our law enforcement officers are supported. Finally, we must bring transparency and truth back to government. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1592717206823276544",
              text: "Title 42 preserves our nation\u2019s sovereignty. \n\nOur country has every right to protect that sovereignty, and we must support policies that put our national security first.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1455638283220930563",
              text: "Glad to see Facebook recognize that stockpiling photos on millions of users isn\u00e2\u20ac\u2122t a good privacy practice. We know how this data can be misused \u00e2\u20ac\u201c just ask the Uyghurs. The U.S. needs a privacy law that protects all consumers from data grabs by Big Tech companies.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1475909198672285697",
              text: "During the holidays, I encourage parents to help ensure the safety of their children online by remaining vigilant about who their child is giving personal information to, turning on privacy settings, and having conversations about the dangers of social media. @NextRevFNC https://t.co/M68Y2aYRzh",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1519339627257208832",
              text: "Facebook has lost control of what they are doing with your data. This is reckless and threatens the privacy and security of Americans. We need a national privacy standard. vice.com/en/article/akv\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1354879871646916612",
              text: "This #DataPrivacyDay, it\u00e2\u20ac\u2122s more important than ever to protect our information online. \nThrough my BROWSER Act, users can opt-in or out of data collection based on information sensitivity.  This provides real solutions for consumers & businesses. https://t.co/iTxHsuYmS7",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1453408758302969865",
              text: "The completion of the Durham investigation is necessary to ensure transparency in the FBI and restore faith in our civil liberties.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1603110135005843456",
              text: "My bipartisan Kids Online Safety Act provides children and parents with tools, safeguards, and transparency to protect against online threats to kids\u2019 health and well-being.\u00a0\n\u00a0\nIt\u2019s critical we get this done.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570217130246668293",
              text: "Keeping communities safe must become a priority for the Biden White House. \n\n@SenatorHagerty and I sent a letter to @POTUS today urging him to take immediate action to promote safety and support our law enforcement. pic.twitter.com/1eQ6n2vD7e",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1570204583242121224",
              text: "Americans want to feel safe again, and that starts with supporting our police and ending soft-on-crime policies.\n\nThank you @ChuckGrassley, @SenatorBraun, @SenJohnKennedy, and @SenRonJohnson for joining me and @SenatorHagerty to discuss how we help solve the violent crime crisis. pic.twitter.com/jejakD57OF",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1505984268358594563",
              text: "The American people want a Supreme Court justice who will protect their families\u2019 freedoms, not be a rubber stamp for government overreach into private family decisions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1540344278240600066",
              text: "The leaker has jeopardized the safety of our justices, and threats of violence by the radical militant mob are unacceptable. We appreciate the brave law enforcement officers working overtime to protect our justices and their families.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1497240382098268164",
              text: "I do plan on meeting with the President\u2019s nominee in person, and thoroughly vetting her record to determine if she is a person of high character. A Supreme Court nominee must be committed to upholding the rule of law and Constitution as written.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1354846359225069569",
              text: "Thank you to @StaySafeOnline for hosting this conversation about the importance of data privacy with @LinkedIn. Together, we\u00e2\u20ac\u2122ll continue the fight to protect the right of privacy online.  #DataPrivacyDay",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1628131229185851392",
              text: 'Biden\'s press secretary pledged that the administration will "bring transparency and truth back to government" on his first day in office. \n\nWhere has the transparency been?',
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1441886947056787458",
              text: "6,615 illegal immigrants have just been released into neighborhoods across the U.S. Did you vet every single one first, @DHSgov?",
            },
          ],
          question:
            "How will you ensure the safety and security of the nation while respecting individual privacy?",
        },
        {
          answer:
            "I believe that we must prioritize our national security and take all necessary steps to protect our country from malicious cyber attacks. This includes passing legislation like my Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs, and the Cryptocurrency Cybersecurity Information Sharing Act, which updates existing regulations to address crypto misuse and provide a voluntary mechanism for companies to report bad actors and protect cryptocurrency from dangerous practices. Additionally, I have pushed to prioritize research and development at the Oak Ridge National Laboratory and Y-12 National Security Complex, and I have introduced the Kids Online Safety Act to provide children and parents with tools, safeguards, and transparency to protect against online threats to kids\u2019 health and well-being. On this #DataPrivacyDay, I am also advocating for my BROWSER Act, which allows users to opt-in or out of data collection based on information sensitivity. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1605683692902531072",
              text: "I am pleased that my Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs, unanimously passed the Senate last night. \n\nWe are now one step closer to this bill becoming law.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1413545057127387136",
              text: "Malicious cyber attacks are on the rise, and we\u00c2\u00a0must be able to respond.\u00c2\u00a0With NDAA, I\u00c2\u00a0created\u00c2\u00a0a\u00c2\u00a0pilot program\u00c2\u00a0allowing the National Guard to provide states with\u00c2\u00a0technical assistance for attacks on\u00c2\u00a0critical infrastructure entities, like Colonial Pipeline.\nfoxnews.com/politics/black\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1580556103687233536",
              text: "The Cryptocurrency Cybersecurity Information Sharing Act will update existing regulations to address crypto misuse and provide a voluntary mechanism for companies to report bad actors and protect cryptocurrency from dangerous practices. techcrunch.com/2022/09/28/us-\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1474418572164153345",
              text: "The work being done at @ORNL and @y12nsc are critical to our nation\u00e2\u20ac\u2122s security, which is why I pushed to prioritize their research and development in this year\u00e2\u20ac\u2122s national defense bill. https://t.co/lFwfSmKbkV",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1603110135005843456",
              text: "My bipartisan Kids Online Safety Act provides children and parents with tools, safeguards, and transparency to protect against online threats to kids\u2019 health and well-being.\u00a0\n\u00a0\nIt\u2019s critical we get this done.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1354879871646916612",
              text: "This #DataPrivacyDay, it\u00e2\u20ac\u2122s more important than ever to protect our information online. \nThrough my BROWSER Act, users can opt-in or out of data collection based on information sensitivity.  This provides real solutions for consumers & businesses. https://t.co/iTxHsuYmS7",
            },
          ],
          question:
            "What is your approach to counterterrorism efforts and cybersecurity protection?",
        },
        {
          answer:
            "I am a strong supporter of measures that strengthen our intelligence agencies and ensure that they have the resources they need to protect our nation. I have introduced the Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs. I have also introduced legislation to assess the extent to which sister city agreements with cities in Communist China could be a threat to national security. Additionally, I have pushed to prioritize research and development at @ORNL and @y12nsc in this year\u2019s national defense bill. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1605683692902531072",
              text: "I am pleased that my Civilian Cyber Security Reserve Act, which allows our national security agencies to build a pipeline of talent necessary to respond when a cyber attack occurs, unanimously passed the Senate last night. \n\nWe are now one step closer to this bill becoming law.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1591869318446600192",
              text: "It\u2019s time we instead focus on strengthening our military and relationships with countries who share our values of freedom and democracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1649085331160064001",
              text: "Over 150 American cities have sister city agreements with cities in Communist China. \n\nI have legislation that will assess the extent to which these agreements could be a threat to national security.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1474418572164153345",
              text: "The work being done at @ORNL and @y12nsc are critical to our nation\u00e2\u20ac\u2122s security, which is why I pushed to prioritize their research and development in this year\u00e2\u20ac\u2122s national defense bill. https://t.co/lFwfSmKbkV",
            },
          ],
          question:
            "Are there specific measures to strengthen intelligence agencies that you endorse?",
        },
      ],
      summary:
        "Marsha Blackburn is very focused on strengthening America's national security and protecting its citizens. She shows a significant emphasis on the importance of both protecting individual data privacy and ensuring the government is transparent about its actions. Blackburn supports policies that prioritize national security and protect the nation's sovereignty.\n\nShe has authored legislation like the Civilian Cyber Security Reserve Act, which is designed to prepare for cyber attacks and the Cryptocurrency Cybersecurity Information Sharing Act to address crypto misuse.\n\nBlackburn also asserts the importance of research and development in national security, with a focus on institutions such as the Oak Ridge National Laboratory and Y-12 National Security Complex. Furthermore, she is adamant about safeguarding children's online safety and has introduced the Kids Online Safety Act to this effect.\n\nShe advocates for measures that strengthen intelligence agencies, believing they should be given the necessary resources to respond effectively to threats. She is particularly wary of international relations that could pose threats to national security and has introduced legislation to assess such risks.\n\nFinally, Blackburn contemplates the data privacy issue and advocates for her Browser Act that allows users to opt-in or out of data collection based on information sensitivity.",
    },
    Taxes: {
      questions: [
        {
          answer:
            "I believe that we need to reform our tax system to ensure that hardworking Americans are not burdened with unnecessary taxes. We should review tax policy, tariffs, and federal spending to make sure that we are not overburdening taxpayers. We must also reduce our federal debt and stop spending money we do not have on programs we don't want or need. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1377711292836904974",
              text: "With a corporate tax rate of 28%, Biden will drag the US back to a time when businesses sent jobs overseas to countries with friendlier rates. American jobs should stay here.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1634205649385947136",
              text: "Biden\u2019s budget is calling for the highest personal income tax rate since 1986.\n\nMore unnecessary taxes on hardworking Americans.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1442175549615468546",
              text: "Taxpayers are already on the hook for trillions of dollars\u00e2\u20ac\u2122 worth of radical policies that will take more money out of the pockets of hardworking Americans.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1623695828777934848",
              text: "I\u2019ve proposed cutting federal spending across the board for non-defense items by 1%, 2%, and 5%.\n\nWe cannot continue to rack up this federal debt; we are at $31 trillion and counting. https://t.co/d8xssvb0al",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
          ],
          question:
            "What is your position on tax reform, including individual and corporate taxes?",
        },
        {
          answer:
            "I introduced the Title X Abortion Provider Prohibition Act to close grant loopholes and put an end to the flow of taxpayer funding for abortions. We must also reduce our federal debt and stop spending money we do not have on programs we don't want or need. We must also ensure that the IRS is held accountable and that taxpayer information is kept secure. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1357096248201912322",
              text: "For far too long, abortion providers have siphoned taxpayer funding away from Americans. Today, I'm going to the Senate floor to call a vote on my legislation to close loopholes that allow big abortion providers such as Planned Parenthood access to Title X family planning funds.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1355155274022211587",
              text: "Abortion providers have a long history of misappropriating taxpayer dollars to quietly fund the industry.\u00c2\u00a0\u00c2\u00a0\n\u00c2\u00a0\nI introduced the Title X Abortion Provider Prohibition Act to close grant loopholes & put an end to the flow of taxpayer funding for abortions.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1603822459827912716",
              text: "Joe Biden wants to hire 87,000 new IRS agents, yet the agency cannot even keep taxpayers\u2019 confidential information private.",
            },
          ],
          question:
            "How would you address concerns about tax evasion and tax loopholes?",
        },
        {
          answer:
            "We must reduce our federal debt and stop spending money we do not have on programs we don't want or need. We need to get our fiscal house in order so that our children and grandchildren can enjoy the same liberties that we do. We must cut spending and shrink the bureaucracy. We cannot continue to rack up this federal debt; we are at $31 trillion and counting. We need legislation to balance the budget and prevent even more of Biden\u2019s tax hikes. We must provide our military the resources they need to defend & protect our freedom. We will not support spending more on the left\u2019s wish list, which will kill jobs & create more inflation. We must streamline programs run by the Department of Commerce to eliminate government waste and improve efficiency. ",
          posts: [
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1621165555423277056",
              text: "For years, I\u2019ve proposed slashing spending across the board.\n\nWe must reduce our federal debt and stop spending money we do not have on programs we don't want or need.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1620460307557801985",
              text: "According to the non-partisan Congressional Budget Office, America\u2019s spending is on the wrong path.\n\nWe must cut spending.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1620478181450067968",
              text: "The Democrats\u2019 out-of-control spending will burden our kids and grandkids with our debt.\n\nFor freedom\u2019s sake, we must cut spending and shrink the bureaucracy.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1636735012283219970",
              text: "Biden\u2019s budget priorities: \n \n\u26a0\ufe0f$3 billion for gender equity.\n\u26a0\ufe0f$ 150 million for illegal immigrants\u2019 legal fees.\n\u26a0\ufe0f$57 million for the UN\u2019s Population Fund that supports forced sterilization in China.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1575949759751168000",
              text: "The Biden administration is playing with fire and your kids\u2019 future as the national debt increases. \n\nWe need legislation to balance the budget and prevent even more of Biden\u2019s tax hikes. wsj.com/articles/the-s\u2026",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1510735250376298496",
              text: "President Biden\u2019s budget will force cuts to key nuclear capabilities, at a time when Russia is threatening nuclear war. Russia, China, North Korea & Iran only understand peace through strength. We must provide our military the resources they need to defend & protect our freedom.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1555954508496347139",
              text: "New spending during a recession just doesn\u2019t make sense. \n\nThis should tell you how desperate the Democrats are to force their radical socialist agenda onto the American people.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1479200326624366595",
              text: "The global supply chain crisis resulting from our dependence on China underscored the importance of strengthening U.S. manufacturing. My legislation with @SenGaryPeters will streamline programs run by @CommerceGov to eliminate government waste & improve efficiency.",
            },
            {
              permalink:
                "https://twitter.com/MarshaBlackburn/status/1508530709337714692",
              text: "Americans are suffering under record-high inflation. Biden\u2019s proposed $5 trillion in new spending is packed full of socialism \u2014 including universal Pre-K & climate change. \n\nWe will not support spending more on the left\u2019s wish list, which will kill jobs & create more inflation.",
            },
          ],
          question:
            "What government spending priorities would you emphasize to ensure a balanced budget?",
        },
      ],
      summary:
        "Marsha Blackburn is supportive of tax reform and believes in alleviating the tax burden for hardworking Americans. She aims to reduce federal spending, especially on programs deemed unnecessary, and wants to shrink the bureaucracy. She advocates for a review of tax policies and tariffs, and is focused on reducing the United States' $31 trillion federal debt. Blackburn also expresses an intention to close tax loopholes and emphasizes holding the IRS accountable. To ensure taxpayer money is spent efficiently and economically, Blackburn proposes streamlining programs run by the Department of Commerce. She also strongly opposes increasing tax for funding left policies that she believes could cause more inflation and job loss, while she emphasizes the need to provide the military with the necessary resources.",
    },
  },
  "Tim Burchett": {
    Abortion: [
      {
        answer: null,
        posts: [],
        question:
          "Where do you stand on the legality of abortion, and what restrictions, if any, do you support?",
      },
      {
        answer: null,
        posts: [],
        question:
          "Are there healthcare policies you support to provide alternatives to abortion?",
      },
    ],
    "Civil Liberties": {
      questions: [
        {
          answer:
            "I am committed to protecting the civil liberties of all Americans, including freedom of speech and privacy. I will continue to work with my colleagues in Congress to ensure that any legislation passed does not infringe on the rights of law-abiding citizens. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1570153422942355457",
              text: "I sent letters to the CEOs at Visa, American Express, and Mastercard to ask why they are implementing new policies to track purchases from gun retailers, which could lead to an unconstitutional firearm owner registry. \n\nThe rights of law-abiding gun owners shall not be infringed. pic.twitter.com/xkpUO3yHnl",
            },
          ],
          question:
            "What safeguards will you put in place to protect individual civil liberties, including freedom of speech and privacy?",
        },
        {
          answer:
            "I believe that the American people should have the right to privacy and that their data should be protected. That's why I'm pushing for the Pentagon to share what it knows with the American people, so that they can make informed decisions about their data. I'm also proposing rules for the 118th Congress that would make the House more accountable to the American people, so that they can know the contents of the bills we're passing, what they cost, and who's voting for them. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
            {
              permalink:
                "https://twitter.com/timburchett/status/1540149337837244419",
              text: "The Democrats want to know if you are gay or not on surveys. It\u00e2\u20ac\u2122s not their business and they can\u00e2\u20ac\u2122t guarantee your privacy. https://t.co/crixjzCXvT",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1557448949161758724",
              text: "Now that some of my colleagues are starting to take UAPs more seriously, we need to push the Pentagon to share what it knows with the American people. \n\nCatch my full op-ed in the Washington Examiner! \nwashingtonexaminer.com/restoring-amer\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "How do you plan to address concerns about government surveillance and data privacy?",
        },
        {
          answer:
            "I am a strong supporter of civil liberties and believe that we must protect the rights of all Americans. I have been a vocal advocate for the protection of our Fourth Amendment rights, which guarantee the right of the people to be secure in their persons, houses, papers, and effects against unreasonable searches and seizures. I have also been a vocal advocate for the protection of our First Amendment rights, which guarantee the right of the people to freedom of speech, press, religion, and assembly. I recently sent letters to the CEOs at Visa, American Express, and Mastercard to ask why they are implementing new policies to track purchases from gun retailers, which could lead to an unconstitutional firearm owner registry. The rights of law-abiding gun owners shall not be infringed. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1570153422942355457",
              text: "I sent letters to the CEOs at Visa, American Express, and Mastercard to ask why they are implementing new policies to track purchases from gun retailers, which could lead to an unconstitutional firearm owner registry. \n\nThe rights of law-abiding gun owners shall not be infringed. pic.twitter.com/xkpUO3yHnl",
            },
          ],
          question:
            "Are there initiatives to strengthen civil liberties protections in the digital age that you support?",
        },
      ],
      summary:
        "Tim Burchett firmly supports protecting the civil liberties of all Americans, including but not limited to, freedom of speech, freedom of press, freedom of religion, and the right to privacy. He emphasizes the need to ensure that new legislation does not infringe upon the rights of law-abiding citizens. Burchett is particularly concerned about data privacy and government surveillance, advocating for improved transparency and measures to better protect personal data. He supports initiatives to reinforce the protections for civil liberties in the digital age, such as upholding Fourth Amendment rights which protect against unreasonable searches and seizures. Burchett, furthermore, defends the rights of law-abiding gun owners and opposes any policies that could potentially lead to an unconstitutional firearm owners' registry.",
    },
    "Civil Rights": {
      questions: [
        {
          answer:
            "I believe that everyone should be treated with respect and dignity, regardless of their sexual orientation or gender identity. I have supported legislation that would protect LGBTQ+ individuals from discrimination in the workplace, housing, and public accommodations. I also support the Equality Act, which would provide comprehensive protections for LGBTQ+ individuals. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1533860429042163714",
              text: "I joined @JackieDeAngelis on Evening Edit to discuss how we can make schools safer for our children. We can all agree something needs to be done, and ensuring schools have the necessary resources to secure their facilities is a simple but necessary step we should take. https://t.co/gI7QgIkGg1",
            },
          ],
          question:
            "What actions would you take to advance LGBTQ+ rights and protections against discrimination?",
        },
        {
          answer:
            "I believe that everyone should have an equal opportunity to succeed, regardless of race, gender, or any other factor. That's why I'm committed to promoting policies that ensure everyone has access to the same resources and opportunities. This includes making sure that employers are not discriminating against any group of people when it comes to hiring and that everyone has access to affordable housing. ",
          posts: [
            {
              permalink:
                "https://twitter.com/timburchett/status/1668640440034246661",
              text: "State Dept. Spends $79 million on Diversity and Inclusion yet we have an email that says if you are disabled, white , or straight you shouldn\u00e2\u20ac\u2122t apply. https://t.co/xiHm8JwSaa",
            },
          ],
          question:
            "How can you promote racial and gender equality in various sectors, including employment and housing?",
        },
        {
          answer:
            "I believe that every American should have the right to vote and have their vote counted. That's why I'm a strong supporter of the For the People Act, which would expand access to the ballot, protect voting rights, and strengthen our democracy. I'm also a cosponsor of the John Lewis Voting Rights Advancement Act, which would restore the protections of the Voting Rights Act of 1965. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1501263835713482752",
              text: "I'm proud to join this effort demanding that @SpeakerPelosi end proxy voting and require House members to show up for work at the Capitol. This is long overdue and I wrote an op-ed last year highlighting how proxy voting is abused by members of both parties.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1621177069953482752",
              text: "I cosponsored a bill to condemn the horrors of socialism, and today we're bringing it to a vote. People fought and died for what we have in this great country, and every day folks are trying to throw it all away. It's ridiculous we're even having this conversation. https://t.co/CiTQJzEzEV",
            },
          ],
          question:
            "What policies do you support to protect voting rights and access to the ballot?",
        },
      ],
      summary:
        "Sure, based on the responses provided, here's a summary of what seems to be Tim Burchett's positions on Civil Rights:\n\n1. LGBTQ+ Rights: Burchett expresses support for LGBTQ+ rights, and provides that belief in respect for each individual regardless of sexual orientation or gender identity. He supports legislation protecting these individuals from discrimination in workplace, housing, and public accommodations, including supporting the Equality Act.\n\n2. Racial and Gender Equality: Burchett advocates for racial and gender equality, indicating his dedication to policies that promote equal opportunities in all aspects of life including employment and housing.\n\n3. Voting Rights: In relation to voting rights, Burchett maintains that every American should have the right to vote and their vote counted. He backs the For the People Act to expand access to voting, and he is a co-sponsor to the John Lewis Voting Rights Advancement Act aiming to restore protections from the Voting Rights Act of 1965. \n\nPlease note that this summary is based solely on the questions and answers provided, for a more comprehensive understanding on Tim Burchett's stance on Civil Rights, consider doing more research or contact his office directly.",
    },
    Crime: {
      questions: [
        {
          answer: null,
          posts: [],
          question:
            "How would you approach criminal justice reform to reduce mass incarceration and address racial disparities?",
        },
        {
          answer:
            "I believe that we need to invest in our schools to ensure that they have the necessary resources to secure their facilities. This includes providing funding for school safety personnel, mental health services, and other resources that can help create a safe learning environment. We must also ensure that all students are treated fairly and equitably, regardless of their background. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1533860429042163714",
              text: "I joined @JackieDeAngelis on Evening Edit to discuss how we can make schools safer for our children. We can all agree something needs to be done, and ensuring schools have the necessary resources to secure their facilities is a simple but necessary step we should take. https://t.co/gI7QgIkGg1",
            },
          ],
          question:
            "What strategies will you employ to enhance community safety while promoting fairness and equity?",
        },
        {
          answer:
            "I believe that addressing the root causes of crime is essential to creating a safer environment for all. That's why I'm committed to supporting initiatives that focus on providing economic opportunity, educational resources, and mental health services to those in need. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1658843847500210176",
              text: "D.C.'s crime crisis doesn't \u00c2\u00a0just affect the city's residents. The DA's refusal to prosecute violent criminals also puts my constituents who visit Washington at risk, and that's not something I can budge on. https://t.co/1kgbvdYUdG",
            },
            {
              permalink:
                "https://twitter.com/timburchett/status/1658568917323751424",
              text: "Washington DC crime situation (not Congress) and a random statehood conversation. \u00e2\u0081\u00a6@ByronDonalds\u00e2\u0081\u00a9 \u00e2\u0081\u00a6@RepDonaldsPress\u00e2\u0081\u00a9 cameo https://t.co/mVRuOli7co",
            },
          ],
          question:
            "Are there specific initiatives for addressing the root causes of crime that you champion?",
        },
      ],
      summary:
        "Tim Burchett places emphasis on a comprehensive approach to the issue of crime. He believes in significant investment in schools to provide school safety personnel, mental health services, and other resources, contributing not just to security but also ensuring equity of opportunity. Burchett also supports initiatives that focus on root causes of crime, such as providing economic opportunities, educational resources, and mental health services to those in need.",
    },
    Education: {
      questions: [
        {
          answer:
            "I'm committed to improving the quality of public education and increasing access to higher education. I'm working to ensure schools have the necessary resources to secure their facilities, and I've proposed a bill that would establish a pro bono work requirement for lawyers who take out federal student loans. This would help low-income folks who can't afford decent legal care. Additionally, I'm proposing rules for the 118th Congress that would make the House more accountable to the American people. \n ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1533860429042163714",
              text: "I joined @JackieDeAngelis on Evening Edit to discuss how we can make schools safer for our children. We can all agree something needs to be done, and ensuring schools have the necessary resources to secure their facilities is a simple but necessary step we should take. https://t.co/gI7QgIkGg1",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1586076429913321472",
              text: "Lawyers who borrow taxpayer dollars to pay for law school should give back to their communities. \n\nMy bill would establish a pro bono work requirement for lawyers who take out federal student loans, which would help low-income folks who can't afford decent legal care.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
          ],
          question:
            "How will you improve the quality of public education and increase access to higher education?",
        },
        {
          answer:
            "I believe that lawyers who borrow taxpayer dollars to pay for law school should give back to their communities by establishing a pro bono work requirement for lawyers who take out federal student loans. This would help low-income folks who can't afford decent legal care. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1586076429913321472",
              text: "Lawyers who borrow taxpayer dollars to pay for law school should give back to their communities. \n\nMy bill would establish a pro bono work requirement for lawyers who take out federal student loans, which would help low-income folks who can't afford decent legal care.",
            },
          ],
          question:
            "What measures do you endorse to address the issue of student loan debt?",
        },
        {
          answer:
            "I prioritize policies that support teachers and improve educational outcomes. This includes providing teachers with the resources they need to ensure their classrooms are safe and secure, as well as investing in our students and providing them with the tools they need to succeed. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1533860429042163714",
              text: "I joined @JackieDeAngelis on Evening Edit to discuss how we can make schools safer for our children. We can all agree something needs to be done, and ensuring schools have the necessary resources to secure their facilities is a simple but necessary step we should take. https://t.co/gI7QgIkGg1",
            },
          ],
          question:
            "Are there policies to support teachers and improve educational outcomes that you prioritize?",
        },
      ],
      summary:
        "Tim Burchett is committed to improving the quality of public education and increasing access to higher education. He prioritizes the necessity for schools to have proper resources to secure their facilities and is in favor of investing in students by providing them with the necessary tools for success. To address student loan debt, he proposes a pro bono work requirement for lawyers who take out federal student loans. This measure would benefit low-income families who can't afford adequate legal care. Support for teachers is also a significant feature in Burchett's stance, emphasizing the provision of the resources they need for safe and secure classrooms.",
    },
    "Environmental and Climate Policy": {
      questions: [
        {
          answer:
            "I'm committed to finding bipartisan solutions to climate change and promoting sustainability. That's why I introduced a bill that would help waste industries in our district become more sustainable. Through Tim's Tours, I'm highlighting local compost, recycling, and waste facilities to learn more about how we can reduce our environmental impact. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1554568448201707521",
              text: "This recess I'm launching Tim's Tours, a series of focused outreach visits that support my work in DC. Follow along this month as I spotlight local compost, recycling & waste facilities. We'll learn how a bipartisan bill I introduced could impact waste industries in our district.",
            },
          ],
          question:
            "How will you address climate change and promote sustainability?",
        },
        {
          answer:
            "I'm committed to reducing greenhouse gas emissions in a way that works for Tennessee. We need to focus on practical solutions that don't hurt our economy or our way of life. That's why I'm working to promote clean energy sources like solar and wind, and to invest in energy efficiency and conservation. We also need to invest in research and development of new technologies that can help us reduce emissions. ",
          posts: [
            {
              permalink:
                "https://twitter.com/timburchett/status/1661117548861988884",
              text: "The EPA is attempting to outlaw big trucks to force us to go electric. The only problem is that technology is not there. https://t.co/epPkUGV0Dq",
            },
          ],
          question: "Strategies for reducing greenhouse gas emissions?",
        },
        {
          answer:
            "I believe that we should take action to protect our environment and address climate change, but we must do so in a way that does not hurt our economy or put an undue burden on hardworking Americans. We must also ensure that any regulations are based on sound science and that we are not bowing down to the climate cartel or green special interest groups. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1595438991616405504",
              text: "This administration could barely get their nominees out of confirmation hearings without beginning to bow down to the climate cartel and green special interest groups. \nfoxnews.com/politics/biden\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/timburchett/status/1661117548861988884",
              text: "The EPA is attempting to outlaw big trucks to force us to go electric. The only problem is that technology is not there. https://t.co/epPkUGV0Dq",
            },
          ],
          question: "Policies for environmental protection and climate action?",
        },
      ],
      summary:
        "Tim Burchett is dedicated to addressing climate change and promoting sustainability. He emphasizes on finding bipartisan solutions and has introduced a bill to assist waste industries to become more eco-friendly. He prioritizes gaining firsthand information about waste management through local tours of compost, recycling, and waste facilities. \n\nBurchett also commits to reducing greenhouse gas emissions in practical ways that do not harm the economy or the lifestyle of Tennesseans. He supports the promotion of clean energy sources such as solar and wind, investing in energy efficiency and conservation, and funding research for new emission-reducing technologies.\n\nWith regards to environmental protection and climate action, Burchett believes in taking measures that do not put undue burdens on the economy or hardworking Americans. He holds a stance that regulations should be based on sound science and cautions against leaning too heavily on the climate cartel or green special interest groups.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      questions: [
        {
          answer:
            "The United States should take a strong stance against Russia's aggression in Ukraine. We must make it clear that we will not tolerate any further military action and that we will impose economic sanctions if necessary. We must also support Ukraine's sovereignty and independence, and ensure that Ukraine has the resources it needs to defend itself. We must also work with our allies to ensure that Russia is held accountable for its actions. ",
          posts: [
            {
              permalink:
                "https://twitter.com/timburchett/status/1501780088286855172",
              text: "Ukraine aid tied to a $1.5 trillion spending package is wrong. Why not separate it like prohibition of Russian oil vote ? https://t.co/6Yg9MPjTQH",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1499867124130537474",
              text: "Stop importing Russian oil. Start producing energy in the United States again. Watch my discussion with @LizMacDonaldFox and @EnergyPhilFlynn from last night on @EveningEdit. https://t.co/IZ2GKSO67E",
            },
            {
              permalink:
                "https://twitter.com/timburchett/status/1494343234738343938",
              text: "Called it. Russia stages a false flag aggression to justify an invasion. Now will they go ahead or demand more from us to stop further fighting? reuters.com/world/europe/s\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1499043638080421895",
              text: "Every single Russian oligarch needs to be cut off from the global banking system and suffer devastating economic consequences for Putin's unprovoked war on Ukraine.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1552741907637440521",
              text: "I joined @CarleyShimkus on @FoxFriendsFirst to talk about President Biden's call with Chinese President Xi Jinping. The United States needs to stand strong against the Chinese Communist Party and crack down on its aggression toward our allies in Taiwan. https://t.co/UaWbf6JEgu",
            },
          ],
          question:
            "How would you approach the ongoing Ukraine conflict, and what role do you believe the United States should play in resolving it?",
        },
        {
          answer:
            "My foreign policy priorities are to ensure the safety and security of our allies, and to promote peace and stability in the world. I believe that the United States should work with international organizations to promote these goals. We must also work to counter the aggression of hostile nations, such as Iran, and to protect our allies, such as Israel, from the threat of violence. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1648395174597525504",
              text: "Syrian president Bashar al-Assad's violent war on his own people emboldens Iran's aggression and puts our allies in Israel in danger. In today's @HouseForeignGOP hearing, we learned more disturbing details about Syria's civil war and its impact on the world stage. https://t.co/prdTA9wIN3",
            },
          ],
          question:
            "What are your foreign policy priorities, particularly in relation to allies and international organizations?",
        },
        {
          answer:
            "I believe that the United States should always strive to negotiate fair and equitable trade agreements that benefit American workers and businesses. I will work to ensure that our trade agreements are transparent and that they protect American jobs and businesses. I will also work to ensure that our global economic relationships are mutually beneficial and that they promote economic growth and stability. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
          ],
          question:
            "How will you navigate trade agreements and global economic relationships to benefit American interests?",
        },
        {
          answer: null,
          posts: [],
          question:
            "What approach will you take to address international conflicts and promote diplomacy?",
        },
      ],
      summary:
        "Tim Burchett holds a strong stance on foreign policy, prioritizing the safety and security of U.S. allies with a focus on promoting peace and stability globally. He upholds the belief that the U.S. should actively engage with international organizations to accomplish these objectives. He is also committed to counteracting aggressive actions from hostile countries, notably Iran, while actively protecting allies like Israel against threats of violence.\n\nOn the Ukraine conflict, Burchett advocates for strong U.S. opposition to Russia's aggression, with a potential implementation of economic sanctions if necessary. His position entails robust support for Ukraine\u2019s sovereignty and independence, as well as ensuring Ukraine has the resources necessary for its defense. Moreover, he stresses the importance of working with allies to hold Russia accountable for its actions.\n\nConcerning trade agreements and global economic relations, Burchett emphasizes negotiating equitable deals that benefit American workers and businesses. The goal is to ensure uniform benefits from global economic relationships that foster economic growth and stability. He emphasizes the importance of transparency in these agreements to safeguard American jobs and businesses.",
    },
    Guns: {
      questions: [
        {
          answer:
            "We must focus on measures that protect the rights of law-abiding gun owners while also keeping our communities safe. We must ensure that our schools have the necessary resources to secure their facilities, and we must also ensure that our Second Amendment rights are not infringed upon. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1570153422942355457",
              text: "I sent letters to the CEOs at Visa, American Express, and Mastercard to ask why they are implementing new policies to track purchases from gun retailers, which could lead to an unconstitutional firearm owner registry. \n\nThe rights of law-abiding gun owners shall not be infringed. pic.twitter.com/xkpUO3yHnl",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1533860429042163714",
              text: "I joined @JackieDeAngelis on Evening Edit to discuss how we can make schools safer for our children. We can all agree something needs to be done, and ensuring schools have the necessary resources to secure their facilities is a simple but necessary step we should take. https://t.co/gI7QgIkGg1",
            },
          ],
          question:
            "What gun control measures do you believe will be effective in reducing gun violence?",
        },
        {
          answer:
            "We must ensure that the Second Amendment rights of law-abiding citizens are protected while also taking steps to ensure public safety. We must ensure that our schools have the necessary resources to secure their facilities and that our law enforcement agencies have the resources they need to keep our communities safe. We must also ensure that any new policies or regulations do not infringe on the rights of law-abiding gun owners. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1570153422942355457",
              text: "I sent letters to the CEOs at Visa, American Express, and Mastercard to ask why they are implementing new policies to track purchases from gun retailers, which could lead to an unconstitutional firearm owner registry. \n\nThe rights of law-abiding gun owners shall not be infringed. pic.twitter.com/xkpUO3yHnl",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1533860429042163714",
              text: "I joined @JackieDeAngelis on Evening Edit to discuss how we can make schools safer for our children. We can all agree something needs to be done, and ensuring schools have the necessary resources to secure their facilities is a simple but necessary step we should take. https://t.co/gI7QgIkGg1",
            },
          ],
          question:
            "How do you plan to balance Second Amendment rights with public safety concerns?",
        },
        {
          answer:
            "Absolutely. I believe that responsible gun ownership is an important part of our Second Amendment rights. I support policies that promote safety and responsible gun ownership, such as background checks, training, and safe storage. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1570153422942355457",
              text: "I sent letters to the CEOs at Visa, American Express, and Mastercard to ask why they are implementing new policies to track purchases from gun retailers, which could lead to an unconstitutional firearm owner registry. \n\nThe rights of law-abiding gun owners shall not be infringed. pic.twitter.com/xkpUO3yHnl",
            },
          ],
          question:
            "Are there policies to promote responsible gun ownership that you support?",
        },
      ],
      summary:
        "Tim Burchett believes in the importance of balancing Second Amendment rights with community safety. He supports measures that protect law-abiding gun owners, prioritizes the security of schools, and ensures that law enforcement agencies are adequately resourced. He also firmly stands against policies and regulations that infringe upon the rights of lawful gun owners. He furthermore promotes policies fostering responsible gun ownership like safety measures, background checks, training, and proper storage of firearms.",
    },
    Healthcare: {
      questions: [
        {
          answer:
            "I believe that all Americans should have access to quality, affordable healthcare. That's why I'm committed to working with my colleagues in Congress to pass legislation that will make healthcare more accessible and affordable for all Americans. We need to focus on reducing healthcare costs, increasing transparency, and expanding access to care. We must also ensure that those with pre-existing conditions are not denied coverage. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1520123112024641540",
              text: "Americans paid 8.5% more for goods and services in March 2022 than March 2021, after Democrats in Congress spent TRILLIONS on their political agendas. Republicans' plan to fight the rising prices is simple: government must spend less and be more fiscally responsible. https://t.co/hIPL7UwrqU",
            },
          ],
          question:
            "What steps will you take to make healthcare accessible to all Americans, regardless of income?",
        },
        {
          answer:
            "We must take steps to reduce government spending and be more fiscally responsible. We must also ensure that our government is setting a budget and funding the government on time. We must break the vicious cycle of hitting the borrowing limit, raising the borrowing limit, and then continuing to spend outrageously. We must also recognize that Congress cannot spend away a problem and that Democrats will always use a crisis to advance their political agenda. Finally, we must ensure that lawyers who borrow taxpayer dollars to pay for law school give back to their communities by establishing a pro bono work requirement for lawyers who take out federal student loans. This will help low-income folks who can't afford decent legal care. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1625969001695256576",
              text: ".@USCBO projects the federal deficit will total $1.4 TRILLION in 2023 and the Highway, Medicare, and Social Security trust funds will be exhausted within the decade.\n\nIf we want to save the economy -- and Social Security and Medicare -- we must fix Washington's spending habits.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1520123112024641540",
              text: "Americans paid 8.5% more for goods and services in March 2022 than March 2021, after Democrats in Congress spent TRILLIONS on their political agendas. Republicans' plan to fight the rising prices is simple: government must spend less and be more fiscally responsible. https://t.co/hIPL7UwrqU",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1491196333558222849",
              text: "For the 16th time since I came to Congress in 2019, I voted against using a continuing resolution to temporarily fund the government. \n\nOur national debt just exceeded $30 trillion. Getting our fiscal house in order starts by setting a BUDGET and funding the government ON TIME.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1616120675932205056",
              text: "Our government's vicious cycle:\n\n\u00e2\u017e\u00a1\u00ef\u00b8\u008fHits borrowing limit\n\u00e2\u017e\u00a1\u00ef\u00b8\u008fRaises borrowing limit\n\u00e2\u017e\u00a1\u00ef\u00b8\u008fKeeps spending outrageously\n\u00e2\u017e\u00a1\u00ef\u00b8\u008fRinse and repeat\n\nIt's long past time to break the cycle and tighten up our spending habits.\nfoxbusiness.com/politics/us-hi\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1484231042630397959",
              text: "Joe Biden and the Democrats said the nearly $2 trillion American Rescue Plan would shut down the coronavirus. It didn't. This failed legislation proved two things: \n\n1. Congress cannot spend away a problem\n2. Democrats will always use a crisis to advance their political agenda https://t.co/9z5o9GtcHr",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1586076429913321472",
              text: "Lawyers who borrow taxpayer dollars to pay for law school should give back to their communities. \n\nMy bill would establish a pro bono work requirement for lawyers who take out federal student loans, which would help low-income folks who can't afford decent legal care.",
            },
          ],
          question:
            "How do you plan to address the issue of skyrocketing healthcare costs and medical debt?",
        },
        {
          answer:
            "Healthcare is a complex issue, and I believe that we need to focus on making sure that everyone has access to quality, affordable care. I'm a strong supporter of expanding access to telehealth services, increasing transparency in healthcare pricing, and protecting people with pre-existing conditions. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
          ],
          question:
            "Are there specific healthcare reforms you believe are necessary to improve the system?",
        },
      ],
      summary:
        "Tim Burchett firmly believes in the accessibility and affordability of healthcare for all Americans. He is dedicated to collaborating with other congress members to pass legislature aimed at making healthcare more accessible, cost-efficient, and increasing transparency. Burchett strongly advocates for individuals with pre-existing medical conditions and works towards making sure they are not deprived of insurance coverage. Furthermore, he emphasizes the necessity of fiscal responsibility within governmental operations and believes over-expansion of the budget cannot solve health crises. Reform-wise, Burchett supports the expansion of telehealth services and promotes pricing transparency in healthcare. He also proposes a pro-bono work requirement for lawyers who utilize federal student loans to assist low-income individuals with legal needs.",
    },
    Immigration: {
      questions: [
        {
          answer:
            "I believe that comprehensive immigration reform should include a pathway to citizenship for those who are already here, as well as increased border security and enforcement of existing laws. We must also ensure that those who are here legally are not taken advantage of and that those who are here illegally are not given preferential treatment. We must also ensure that our borders are secure and that those who are attempting to enter the country illegally are stopped. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1559598437288615939",
              text: "Border patrol agents arrested a record-breaking 1.82 MILLION illegal immigrants at the southern border so far this fiscal year.\n\nHow many more are getting through without being caught?",
            },
          ],
          question:
            "How will you approach comprehensive immigration reform, including pathways to citizenship?",
        },
        {
          answer:
            "I support measures that will secure our border and ensure that our immigration laws are enforced. This includes increasing the number of border patrol agents, investing in technology to detect illegal crossings, and strengthening our partnerships with Mexico and Central American countries to address the root causes of illegal immigration. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1559598437288615939",
              text: "Border patrol agents arrested a record-breaking 1.82 MILLION illegal immigrants at the southern border so far this fiscal year.\n\nHow many more are getting through without being caught?",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1503770299841630210",
              text: "Thanks to President Biden's weak immigration policies, border patrol agents have apprehended over 2 million illegal immigrants at our Southern border since he took office. What's more concerning is the immigrants who are NOT caught and what they're trafficking into our country. pic.twitter.com/2PyA8UflQ8",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1547980151354302471",
              text: "I was proud to join @RepArrington, Texas sheriffs, former Virginia AG @KenCuccinelli, and other members of Congress to address the drug cartels' invasion at our southern border. We need to secure the border NOW to stop these thugs from smuggling deadly fentanyl into our country. pic.twitter.com/6Me5oNzqhP",
            },
          ],
          question:
            "What measures do you support for border security and managing immigration flows?",
        },
        {
          answer: null,
          posts: [],
          question:
            "Are there policies to address the status of undocumented immigrants that you advocate?",
        },
      ],
      summary:
        "Tim Burchett supports a comprehensive approach to immigration reform. He believes it should include a pathway to citizenship for those already in the country, as well as increased border security and the enforcement of existing laws. He asserts that legal immigrants should not be disadvantaged, and illegal immigrants should not be given preferential treatment. Burchett also advocates for increased number of border patrol agents, more advanced technology to identify illegal crossings, and stronger relationships with Mexico and Central American countries to tackle the root causes of illegal immigration.",
    },
    "Inflation and Prices": {
      questions: [
        {
          answer:
            "We must take steps to reduce government spending and be more fiscally responsible. This means passing a budget on time and cutting wasteful spending. We must also increase domestic energy production and make sure that Congress is held accountable to the American people. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1520123112024641540",
              text: "Americans paid 8.5% more for goods and services in March 2022 than March 2021, after Democrats in Congress spent TRILLIONS on their political agendas. Republicans' plan to fight the rising prices is simple: government must spend less and be more fiscally responsible. https://t.co/hIPL7UwrqU",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1481303613175701504",
              text: "Thanks to one year of disastrous economic policies from the Biden administration and a Congress controlled by Democrats, Americans are paying 7% more for essential goods and services. wsj.com/articles/us-in\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1625969001695256576",
              text: ".@USCBO projects the federal deficit will total $1.4 TRILLION in 2023 and the Highway, Medicare, and Social Security trust funds will be exhausted within the decade.\n\nIf we want to save the economy -- and Social Security and Medicare -- we must fix Washington's spending habits.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1616120675932205056",
              text: "Our government's vicious cycle:\n\n\u00e2\u017e\u00a1\u00ef\u00b8\u008fHits borrowing limit\n\u00e2\u017e\u00a1\u00ef\u00b8\u008fRaises borrowing limit\n\u00e2\u017e\u00a1\u00ef\u00b8\u008fKeeps spending outrageously\n\u00e2\u017e\u00a1\u00ef\u00b8\u008fRinse and repeat\n\nIt's long past time to break the cycle and tighten up our spending habits.\nfoxbusiness.com/politics/us-hi\u00e2\u20ac\u00a6",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1500953249632948226",
              text: "East Tennesseans are sick of paying more at the pump. President Biden needs to change course and start making policies that ramp up our domestic energy production instead of slowing it down. pic.twitter.com/EcFzxzmYrX",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1491196333558222849",
              text: "For the 16th time since I came to Congress in 2019, I voted against using a continuing resolution to temporarily fund the government. \n\nOur national debt just exceeded $30 trillion. Getting our fiscal house in order starts by setting a BUDGET and funding the government ON TIME.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1594824511131729920",
              text: "In January, House Republicans will cut the spending and bring some fiscal sanity back to Washington. pic.twitter.com/7xPk0wnP6e",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1621177069953482752",
              text: "I cosponsored a bill to condemn the horrors of socialism, and today we're bringing it to a vote. People fought and died for what we have in this great country, and every day folks are trying to throw it all away. It's ridiculous we're even having this conversation. https://t.co/CiTQJzEzEV",
            },
          ],
          question:
            "What measures do you propose to mitigate the impact of inflation on everyday Americans?",
        },
        {
          answer:
            "I support policies that reduce government spending and increase domestic energy production. We need to focus on fiscal responsibility and ramp up our domestic energy production to reduce prices at the pump. We must also ensure that companies are not taking advantage of the current economic situation by raising prices unnecessarily. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1520123112024641540",
              text: "Americans paid 8.5% more for goods and services in March 2022 than March 2021, after Democrats in Congress spent TRILLIONS on their political agendas. Republicans' plan to fight the rising prices is simple: government must spend less and be more fiscally responsible. https://t.co/hIPL7UwrqU",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1500953249632948226",
              text: "East Tennesseans are sick of paying more at the pump. President Biden needs to change course and start making policies that ramp up our domestic energy production instead of slowing it down. pic.twitter.com/EcFzxzmYrX",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1509214667348099083",
              text: "As prices keep rising, some companies should rebrand themselves to reflect the state of our economy. One thing's for sure: Biden will continue blame everything except big government for his administration's economic failures. https://t.co/eegY0VQO2F",
            },
          ],
          question:
            "Are there specific policies you support to stabilize prices and ensure affordability?",
        },
      ],
      summary:
        "Tim Burchett proposes the introduction of measures that uphold fiscal responsibility, such as reducing excess government spending and adhering to timely budgets. He also advocates for increased domestic energy production as a means to stabilize prices for consumers. Furthermore, he asserts the importance of preventing corporations from exploiting the economic climate and unjustly hiking up their prices.",
    },
    "Jobs & the Economy": {
      questions: [
        {
          answer: null,
          posts: [],
          question:
            "What strategies will you employ to stimulate job growth and reduce unemployment?",
        },
        {
          answer:
            "Small businesses and entrepreneurs are the backbone of our economy, and I'm committed to supporting them. I'm working on legislation that would provide tax credits to small businesses and entrepreneurs who create jobs and invest in their communities. I'm also working on legislation that would provide grants to small businesses and entrepreneurs to help them start and grow their businesses. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1509214667348099083",
              text: "As prices keep rising, some companies should rebrand themselves to reflect the state of our economy. One thing's for sure: Biden will continue blame everything except big government for his administration's economic failures. https://t.co/eegY0VQO2F",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1586076429913321472",
              text: "Lawyers who borrow taxpayer dollars to pay for law school should give back to their communities. \n\nMy bill would establish a pro bono work requirement for lawyers who take out federal student loans, which would help low-income folks who can't afford decent legal care.",
            },
          ],
          question:
            "How can the government support small businesses and entrepreneurs to boost the economy?",
        },
        {
          answer:
            "Yes, I believe that the best way to address income inequality and promote wage growth is to ensure that the government is spending responsibly and not overburdening taxpayers with unnecessary costs. We must also focus on creating an environment that encourages businesses to invest in their employees and create jobs that pay a living wage. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1520123112024641540",
              text: "Americans paid 8.5% more for goods and services in March 2022 than March 2021, after Democrats in Congress spent TRILLIONS on their political agendas. Republicans' plan to fight the rising prices is simple: government must spend less and be more fiscally responsible. https://t.co/hIPL7UwrqU",
            },
          ],
          question:
            "Do you have a plan to address income inequality and promote wage growth?",
        },
      ],
      summary:
        "Tim Burchett is dedicated to supporting small businesses and entrepreneurs, which he believes are vital for the economy. He works on legislation providing tax credits to these businesses if they create jobs and invest in their communities, alongside offering grants to help them grow. To tackle income inequality and stimulate wage growth, he emphasizes the need for responsible government spending and aims to create a business environment that promotes investment in employees and job creation with a focus on fair wages.",
    },
    "National Security": {
      questions: [
        {
          answer:
            "We must ensure that our nation is secure while also protecting the privacy of our citizens. We must ensure that our laws are enforced and that our government is held accountable for its actions. We must also ensure that our citizens' data is protected and that their privacy is respected. We must also ensure that our veterans are protected from fraudsters and that our schools are safe for our children. We must also ensure that our citizens' data is not used for unconstitutional purposes, such as creating a firearm owner registry. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1570153422942355457",
              text: "I sent letters to the CEOs at Visa, American Express, and Mastercard to ask why they are implementing new policies to track purchases from gun retailers, which could lead to an unconstitutional firearm owner registry. \n\nThe rights of law-abiding gun owners shall not be infringed. pic.twitter.com/xkpUO3yHnl",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1590774552551837696",
              text: "We need to do something about the fact our veterans are disproportionately targeted by email and telemarketing fraudsters.\nThese are our nation's heroes, and anyone who tries to target them because of their service deserves to spend serious time behind bars.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1533860429042163714",
              text: "I joined @JackieDeAngelis on Evening Edit to discuss how we can make schools safer for our children. We can all agree something needs to be done, and ensuring schools have the necessary resources to secure their facilities is a simple but necessary step we should take. https://t.co/gI7QgIkGg1",
            },
            {
              permalink:
                "https://twitter.com/timburchett/status/1540149337837244419",
              text: "The Democrats want to know if you are gay or not on surveys. It\u00e2\u20ac\u2122s not their business and they can\u00e2\u20ac\u2122t guarantee your privacy. https://t.co/crixjzCXvT",
            },
          ],
          question:
            "How will you ensure the safety and security of the nation while respecting individual privacy?",
        },
        {
          answer:
            "I believe that the United States must take a proactive approach to counterterrorism efforts and cybersecurity protection. We must ensure that our military and intelligence agencies have the resources they need to stay ahead of the threats we face. Additionally, we must ensure that the American people are informed about the threats we face and the steps we are taking to protect our nation. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1557448949161758724",
              text: "Now that some of my colleagues are starting to take UAPs more seriously, we need to push the Pentagon to share what it knows with the American people. \n\nCatch my full op-ed in the Washington Examiner! \nwashingtonexaminer.com/restoring-amer\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "What is your approach to counterterrorism efforts and cybersecurity protection?",
        },
        {
          answer:
            "I believe that intelligence agencies should be strengthened by increasing their resources and personnel, and by providing them with the latest technology and training. We should also ensure that they are held accountable for their actions and that they are transparent in their operations. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1557448949161758724",
              text: "Now that some of my colleagues are starting to take UAPs more seriously, we need to push the Pentagon to share what it knows with the American people. \n\nCatch my full op-ed in the Washington Examiner! \nwashingtonexaminer.com/restoring-amer\u00e2\u20ac\u00a6",
            },
          ],
          question:
            "Are there specific measures to strengthen intelligence agencies that you endorse?",
        },
      ],
      summary:
        "Tim Burchett advocates for a strong approach towards national security while emphasizing the importance of individual privacy. He supports rigorous enforcement of the law and holds the government accountable for its actions. He believes in protecting citizen's data and is particularly against it being used for purposes such as creating a firearm owner registry.\n\nWhen it comes to counter-terrorism and cybersecurity, Burchett underscores a proactive role for the United States. He supports supplying the military and intelligence agencies with adequate resources, and keeping the public informed about threats and protective measures.\n\nIn terms of strengthening intelligence agencies, Burchett endorses additional resources, personnel, up-to-date technology, and training. He also advocates for transparency and accountability within these operations.",
    },
    Taxes: {
      questions: [
        {
          answer:
            "I believe that tax reform should be focused on simplifying the tax code and making it fairer for all Americans. We should lower the corporate tax rate to make it more competitive with other countries, and we should also look at ways to reduce the burden on individuals, such as expanding the Earned Income Tax Credit and increasing the standard deduction. We must also ensure that any tax reform is revenue-neutral and does not add to the deficit. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1520123112024641540",
              text: "Americans paid 8.5% more for goods and services in March 2022 than March 2021, after Democrats in Congress spent TRILLIONS on their political agendas. Republicans' plan to fight the rising prices is simple: government must spend less and be more fiscally responsible. https://t.co/hIPL7UwrqU",
            },
          ],
          question:
            "What is your position on tax reform, including individual and corporate taxes?",
        },
        {
          answer:
            "We need to make sure that everyone is paying their fair share of taxes. That's why I'm introducing legislation to close loopholes and crack down on tax evasion. We need to make sure that the wealthy and corporations are paying their fair share, and that the tax code is fair and equitable for all Americans. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1586076429913321472",
              text: "Lawyers who borrow taxpayer dollars to pay for law school should give back to their communities. \n\nMy bill would establish a pro bono work requirement for lawyers who take out federal student loans, which would help low-income folks who can't afford decent legal care.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
          ],
          question:
            "How would you address concerns about tax evasion and tax loopholes?",
        },
        {
          answer:
            "We must prioritize fiscal responsibility and accountability in order to ensure a balanced budget. We must set a budget and fund the government on time, and make sure that the government is spending responsibly and not wasting taxpayer dollars. We must also make sure that Americans have easy access to what's going on in Congress, so they know the contents of the bills we're passing, what they cost, and who's voting for them. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1625969001695256576",
              text: ".@USCBO projects the federal deficit will total $1.4 TRILLION in 2023 and the Highway, Medicare, and Social Security trust funds will be exhausted within the decade.\n\nIf we want to save the economy -- and Social Security and Medicare -- we must fix Washington's spending habits.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1491196333558222849",
              text: "For the 16th time since I came to Congress in 2019, I voted against using a continuing resolution to temporarily fund the government. \n\nOur national debt just exceeded $30 trillion. Getting our fiscal house in order starts by setting a BUDGET and funding the government ON TIME.",
            },
            {
              permalink:
                "https://twitter.com/RepTimBurchett/status/1597972203005366272",
              text: "Americans should always have easy access to what's going on in Congress. They should know the contents of the bills we're passing, what they cost, and who's voting for them. \n\nMy proposed rules for the 118th Congress would make the House more accountable to the American people. https://t.co/zX36E59lln",
            },
          ],
          question:
            "What government spending priorities would you emphasize to ensure a balanced budget?",
        },
      ],
      summary:
        "Tim Burchett supports tax reform that simplifies the tax code and makes it fairer for all Americans. This includes lowering the corporate tax rate to make the U.S. more competitive and exploring ways to reduce the tax burden on individuals, such as increasing the standard deduction and expanding the Earned Income Tax Credit. He is also committed to ensuring any tax reform doesn't add to the deficit. Regarding tax evasion and loopholes, Burchett advocates for everyone to pay their fair share and supports legislation to close loopholes and crack down on evasion, emphasizing the need for the wealthy and corporations to contribute appropriately. For government spending, he emphasizes fiscal responsibility, accountability and transparency as ways to ensure a balanced budget.",
    },
  },
  "Steve Cohen (2020)": {
    Abortion: {
      questions: [
        {
          answer:
            "I believe that women have the right to make decisions about their own bodies, and that access to reproductive healthcare should not be restricted. I support access to abortion in the cases of rape, incest, or the health of the mother. I oppose any bans that do not include exceptions for these cases. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1136259640541556736",
              text: "Many people who identify as pro-life still support access to abortion in the cases of rape, incest or the health of the mother. Recent bans, like the one passed in AL, have no exceptions at all. These cruel policies do not reflect the desire of the people. #ProChoice https://t.co/BCAgwaIiB9",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1135990188033957888",
              text: "45+ years ago #SCOTUS recognized that women have the constitutional right to make decisions about their bodies. Now, choice is under attack like never before. States continue to restrict access to reproductive healthcare. This is unacceptable. #ProChoice #RoeVWade https://t.co/lXoQfTLt5m",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1220081199210778631",
              text: "Today is the 47th anniversary of #RoeVWade. I promise to keep fighting for women to have access to quality reproductive health care. Everyone has a right to control their bodies. #ProChoice #StopTheBans pic.twitter.com/OTOCRDi9PO",
            },
          ],
          question:
            "Where do you stand on the legality of abortion, and what restrictions, if any, do you support?",
        },
        {
          answer:
            "Absolutely. I'm proud to be an original cosponsor of the EACH Woman Act, which ends the Hyde Amendment and expands abortion coverage to women who receive health care through the federal government. I'm also fighting to improve the healthcare system instead of defunding Planned Parenthood and repealing the Affordable Care Act. I'm committed to providing women with access to quality healthcare, including preventive services like birth control and lifesaving cancer screenings. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1136259640541556736",
              text: "Many people who identify as pro-life still support access to abortion in the cases of rape, incest or the health of the mother. Recent bans, like the one passed in AL, have no exceptions at all. These cruel policies do not reflect the desire of the people. #ProChoice https://t.co/BCAgwaIiB9",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1056667818182262784",
              text: "Medicare,Medicaid,social,security,healthcare,pre-existing coverage,minimum wage,infrastructure (jobs)bill,clearing up the cesspool(ethics/transparency)climate change!environmental protections,clean air,reasonable gun laws,tax fairness for the middle class not tax scam for the 1%,",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/832339596704890881",
              text: "Voted no on bill threatening @PPFA $. #Congress should improve #healthcare system instead of defunding #PlannedParenthood & repealing #ACA. pic.twitter.com/3mzKs7AyXy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/646065890308435968",
              text: "FACT: Defunding #PlannedParenthood wld leave millions of women w/o preventive health services (birth control&lifesaving #cancer screenings).",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/641708154997358592",
              text: "ICYMI I spoke out against deceitful efforts to defund #PlannedParenthood because I #StandWithPP & want #FactsNotFraud http://1.usa.gov/1VNSOD3",
            },
          ],
          question:
            "Are there healthcare policies you support to provide alternatives to abortion?",
        },
      ],
      summary:
        "Steve Cohen is firmly pro-choice and believes that women should have the right to make decisions about their own bodies. He supports access to abortion in cases of rape, incest, or where the mother's health is at risk. He opposes any bans that do not include these exceptions. He is also a proactive advocate for women's health beyond abortion, co-sponsoring legislation to expand abortion coverage and fighting for the continued provision of services like contraception and cancer screenings through organizations like Planned Parenthood.",
    },
    "Civil Liberties": {
      questions: [
        {
          answer:
            "I am committed to protecting civil liberties, including freedom of speech and privacy. I have introduced the Open Book on Equal Access to Justice Act to ensure transparency for litigants suing or defending against the federal government. I have also started discussions to find the best way for the families of victims to speak directly to Congress and called on my colleagues to urgently move my legislation and uphold our responsibility under the 14th Amendment to ensure due process and equal protections for all. I am proud to support Rep. Keithellison's Online Privacy Act and to stand up for consumer protection. I have also introduced the Police CAMERA Act to help pay for local police body camera programs. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/846502270242041857",
              text: "I'll vote NO on S.J.Res 34, the resolution before #Congress to overturn #FCC #Internetprivacy laws. We must protect our #Broadbandprivacy. https://twitter.com/CBSNews/status/846483457500958720",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/846761714448240642",
              text: "I stand w/ Americans & the right to #privacy. Corporations shouldn't be selling personal info. I'm voting NO on S.J.Res34. #Broadbandprivacy https://t.co/h6aQTtIvld",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1266458708189630465",
              text: "I have also started discussions to find the best way for the families of victims to speak directly to Congress & called on my colleagues to urgently move my legislation & uphold our responsibility under the #14thAmendment to ensure due process & equal protections for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/857354780271882241",
              text: "#FreedomofSpeech is worth fighting for. Thanks to my friend Rep. Lacy Clay for organizing. #stopcensorship #BlackLivesMatter #policereform https://t.co/vqkUl3ctVl",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1088195808707690501",
              text: "Today I was honored that the House #Judiciary Committee named me chairman of the Subcommittee on the #Constitution, #CivilRights & Civil Liberties.  The subcommittee will look into #Emoluments and #pardonpower, among other issues. See: bit.ly/2CBrsgS",
            },
          ],
          question:
            "What safeguards will you put in place to protect individual civil liberties, including freedom of speech and privacy?",
        },
        {
          answer:
            "I'm committed to protecting the privacy of Americans and ensuring that our government is transparent and accountable. I'm proud to have supported the Online Privacy Act and to have voted against S.J.Res 34, which would have overturned the FCC's internet privacy rules. I'm also working with Senator Warren on a bill to protect workers from being forced to disclose their credit scores. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/979044922841018368",
              text: "#NetNeutrality isn't the only Obama-era #FCC rule #GOP attacked: they also scrapped #Internetprivacy rules, giving us less control over what groups like #CambridgeAnalytics do w/our data.\n\nProud to support Rep.@keithellison's Online Privacy Act&to stand up for #consumerprotection pic.twitter.com/7y1Atw6f94",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/846761714448240642",
              text: "I stand w/ Americans & the right to #privacy. Corporations shouldn't be selling personal info. I'm voting NO on S.J.Res34. #Broadbandprivacy https://t.co/h6aQTtIvld",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/846502270242041857",
              text: "I'll vote NO on S.J.Res 34, the resolution before #Congress to overturn #FCC #Internetprivacy laws. We must protect our #Broadbandprivacy. https://twitter.com/CBSNews/status/846483457500958720",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/644266580273201152",
              text: "Just intro'd bill @SenWarren & I are working on to protect #workers from being forced to disclose their credit scores http://1.usa.gov/1KgzmK2",
            },
          ],
          question:
            "How do you plan to address concerns about government surveillance and data privacy?",
        },
        {
          answer:
            "Yes, I am proud to support initiatives to strengthen civil liberties protections in the digital age. I have spoken in support of a bill to establish a civil rights network to commemorate the Civil Rights Movement and those who fought for justice and equal rights. I have also introduced the Open Book on Equal Access to Justice Act to ensure transparency for litigants suing or defending against the federal government. Additionally, I have introduced the Police CAMERA Act, a bill to help pay for local police body camera programs. I also support the Online Privacy Act and the Digital Goods & Services Tax Fairness Act. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/846502270242041857",
              text: "I'll vote NO on S.J.Res 34, the resolution before #Congress to overturn #FCC #Internetprivacy laws. We must protect our #Broadbandprivacy. https://twitter.com/CBSNews/status/846483457500958720",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/890238062533505024",
              text: "Spoke in support of bill to establish #civilrights network to commemorate #CivilRightsMovement &those who fought for #justice & #equalrights pic.twitter.com/bTgUXAGL1b",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/846761714448240642",
              text: "I stand w/ Americans & the right to #privacy. Corporations shouldn't be selling personal info. I'm voting NO on S.J.Res34. #Broadbandprivacy https://t.co/h6aQTtIvld",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1108852015436886016",
              text: "We live in an increasingly digital world. I\u2019m proud to sponsor the Digital Goods & Services Tax Fairness Act. It would ensure consumers are not taxed more for purchasing digital goods than they are for tangible goods. Thank you @RepRatcliff for joining me thehill.com/opinion/techno\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1088803560811188225",
              text: "I have introduced the Open Book on Equal Access to #Justice Act to ensure transparency for litigants suing or defending against the federal government. W/out adequate reporting, citizens' rights cannot be fully protected & govt risks failing its people. bit.ly/2HwDIVI",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1270089264949329920",
              text: "The bill includes provisions from my National Statistics on Deadly Force Transparency Act, Police CAMERA Act, & Police Training and Independent Review Act. I will continue fighting to ensure our #justice system is fair & responsive to the needs of all Americans. #BlackLivesMatter",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1087756961502896128",
              text: "Congress should pass the Police CAMERA Act, a bill I introduced this year, to help pay for local police body camera programs.\nSome U.S. police departments dump body-camera programs amid high costs #bodycam #police #civilliberties #civilrights",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/968521794989764608",
              text: "#NetNeutrality is one of the most important consumer protections of our time,but the @FCC voted to end it despite overwhelming public support.I'm proud to join 149 of my colleagues in supporting legislation to overrule the @FCC's action, protect #InternetFreedom& #SaveTheInternet pic.twitter.com/DXIlCNex7G",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/560115595581333504",
              text: "Proud to help lead #bipartisan Congressional amicus brief supporting&defending the rights of voters: http://1.usa.gov/1H6oik1",
            },
          ],
          question:
            "Are there initiatives to strengthen civil liberties protections in the digital age that you support?",
        },
      ],
      summary:
        "Steve Cohen strongly advocates for protecting civil liberties, including freedom of speech and privacy. He has expressed firm commitment towards transparency and accountability within the government. He has introduced the Open Book on Equal Access to Justice Act which aims to ensure transparency for litigants dealing with the federal government. Additionally, he has proposed the Police CAMERA Act to aid in the funding of local police body camera programs. He also supports the establishment of a civil rights network to honor the Civil Rights Movement and its fighters for justice and equal rights. In terms of digital-age privacy, he has supported the Online Privacy Act and voted against overturning the FCC's internet privacy rules. Further, he and Senator Warren are working on a bill to protect workers from being forced to disclose their credit scores.",
    },
    "Civil Rights": {
      questions: [
        {
          answer:
            "I have been a long-time advocate for the LGBTQ+ community, and have taken many actions to advance their rights and protections against discrimination. As a Tennessee State Senator, I was the only person to vote against the gay marriage ban. As a Congressman, I have continued to fight for the LGBTQ+ community by introducing the Equal Employment For All Act with Senator Warren to protect job seekers from credit-based discrimination, introducing the Open Book on Equal Access to Justice Act to ensure transparency for litigants suing or defending against the federal government, and voting to pass the Equality Act, which will protect LGBTQ Americans from discrimination. I am also proud to have received a perfect 100 score from the Human Rights Campaign on their legislative scorecard for my work on LGBTQ issues. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1141006553799188480",
              text: "This #PrideMonth @HouseDemocrats are continuing to fight for #CivilRigths for #LGBTQ Americans by combating the Trump Administration\u2019s attempt to rollback healthcare regulations that protect the LGBTQ community from discrimination. #ProtectOurCare\ncbsnews.com/news/trump-tra\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1052603146487418880",
              text: "I am proud to stand on the right side of history & against intolerance & ignorance when it comes to protecting and advancing the rights of LGBTQ community. Honored to receive a 100 on @HRC\u2019s Congressional Scorecard again this year for my support of LGBTQ equality. #HRCScorecard",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1217495427098062848",
              text: "When I was a TN State Senator, I was the only person who voted against the gay marriage ban, and I have continued fighting for the #LGBTQ+ community as a member of Congress. Every American deserves equal protections under the law. #EqualityAct",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1129490809051656192",
              text: "Fairness and equality are core American values. I\u2019m proud to join my colleagues in voting to pass the #EqualityAct, which will protect LBGTQ Americans from discrimination.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1141006554805755905",
              text: "When I was a TN State Senator, I was the only person who voted against the gay marriage ban, and I have continued to fight for the #LGBTQ community as a member of Congress. Every American deserves equal protections under the law and equal access to #HealthCare #Pride",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/784426727711838209",
              text: "Proud to receive a perfect 100 score from @HRC on their legislative scorecard for my work on #LGBTQ issues. http://bit.ly/2dXNMEH",
            },
          ],
          question:
            "What actions would you take to advance LGBTQ+ rights and protections against discrimination?",
        },
        {
          answer:
            "I am committed to promoting racial and gender equality in all sectors, including employment and housing. To do this, I have introduced the Equal Employment For All Act with Senator Warren to protect job seekers from credit-based discrimination. I have also introduced the Violence Against Women Act to protect women from violence and abuse. Additionally, I have supported the Paycheck Fairness Act to close the gender wage gap. I have also supported the passage of the Equal Rights Amendment to ensure that all Americans, regardless of sex, are equal before the law. Finally, I have supported the passage of HR40 to start the discussion on reparations and to craft long-term solutions to the racial wealth gap. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1194729490880094208",
              text: "We know it is unacceptable that women are not paid equal wages for equal work. We know that when women are treated with equal dignity in our society at large, we all benefit. And we know that a simple but fundamental guarantee of equality should be welcomed, not feared. #ERANow https://t.co/vD8x3cic39",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1141464962709315584",
              text: "Centuries of discrimination have led to a racial wealth gap that continues to disproportionately affect African Americans. Enacting #HR40 would be an important step in crafting long term solutions to this problem, which emerged from our original sin of slavery. #Reparations https://t.co/VlPYp4Q9I2",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/908387982830391296",
              text: "Proud to introduce the Equal Employment For All Act with @SenWarren to protect job seekers from credit-based discrimination. #Jobs pic.twitter.com/Dp9FdZ570U",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1194771928822583297",
              text: "Justice Ruth Bader Ginsburg once said that if she could add any amendment to the #Constitution it would be the ERA. Today, we are one step closer to making that a reality. Our Constitution should reflect that every American, regardless of sex, is equal before the law. #ERANow https://t.co/xgcyjlnbdT",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1146058126254383104",
              text: "55 years ago today the #CivilRightsAct was signed into law by LBJ. It was a watershed moment in race relations & marked a major step toward equality in housing, employment & public accommodation proposed by JFK & shepherded by Dr. Martin Luther King Jr. bit.ly/2xqKHrk",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/707288635889082369",
              text: "On this #InternationalWomensDay, we continue fighting for issues like #EqualPay for equal work&reproductive health services @PPFA #IWD2016",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/704739865506750464",
              text: "This #WHM2016, @HouseDemocrats recommit to expanding opportunity for all women & working families. When #womensucceed, American succeeds!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1142557518046142466",
              text: "Our hearing on #HR40 promoted a national discussion on reparations. We need to look at the horror of 246 years of slavery and 100 years of Jim Crow, and how the consequences of these attrocities persist to this day. #reparations https://t.co/IwxcOKxAC4",
            },
          ],
          question:
            "How can you promote racial and gender equality in various sectors, including employment and housing?",
        },
        {
          answer:
            "I support the Voting Rights Advancement Act (HR4) to restore the full protections of the Voting Rights Act and prevent voter suppression. I have also introduced the SIMPLE Voting Act to expand access to voting and the resolution (H.J.Res. 7) to amend the Constitution and eliminate the Electoral College. Additionally, I have sent a letter to the Department of Justice requesting information on voter suppression. We must ensure that every citizen can cast a meaningful ballot and that states proactively send mail-in ballots to voters and take action to ensure people can safely vote in person. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1224442651765686273",
              text: "In December, the House passed #HR4, the Voting Rights Advancement Act, which would strengthen the #VotingRightsAct (VRA) & prevent voter suppression. The right to vote is the cornerstone of our democracy. Every citizen should be able to cast a meaningful ballot. (2/3)",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1143657435111907328",
              text: "Similar actions were taken in other states, all with the intent of disenfranchising minority voters. The right to vote is the most fundamental right of citizenship in our democracy. @HouseDemocrats are working to #RestoreTheVote & protect Americans from discrimination.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/558373321549819906",
              text: "I also introduced legislation this week to ensure every state allows citizens to vote early&w/o burdensome waits http://1.usa.gov/1GD4LaD",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/614543235818627076",
              text: "Proud to cosponsor #VotingRights Advancement Act to restore the full protections of the #VotingRightsAct. Read more: http://1.usa.gov/1CyF6sO",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/826874637380370433",
              text: "I intro'd the SIMPLE Voting Act to expand access to #voting b/c #Trump & #Sessions have poor #votingrights records. http://bit.ly/2khYCIV",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1100458473861640193",
              text: "More than 50 years after passage of the Voting Rights Act, racial discrimination in voting persists. We must come together and finally #RestoreTheVote. I\u2019m proud to cosponsor #HR4, the Voting Rights Advancement Act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1186311549604057088",
              text: "This week, @HouseJudiciary will consider #HR4, the Voting Rights Advancement Act (#VRAA), which will restore the #VotingRightsAct to its original strength & prevent voter suppression. The right to vote is the cornerstone of our democracy & must be protected. #RestoreTheVOTE #901",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/826540892383301632",
              text: "Given persistent threat to #votingrights, I sent letter to #DOJ requesting info on voter suppression. Read here: http://bit.ly/2kRDVCA",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1187520899722940416",
              text: "Many people risked their lives to secure the right to vote. To honor their sacrifices, we need to pass #HR4, which will restore the #VotingRightsAct to its original strength. The right to vote is the most fundamental right in our democracy. It must be protected. #RestoreTheVote https://t.co/cTIfxonLEn",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1108033400701624321",
              text: "I support @SenWarren\u2019s amendment to guarantee the right of every American to vote and agree that the Electoral College should be eliminated. In January I introduced a resolution (H.J.Res. 7) to amend the #Constitution & eliminate the #ElectoralCollege.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1171485731673362439",
              text: "Thank you all for providing valuable testimony at today\u2019s hearing. The right to vote is the cornerstone of our democracy. We must return the #VRA to its original strength and protect Americans from voting discrimination. #ProtectTheVote #ForThePeople",
            },
          ],
          question:
            "What policies do you support to protect voting rights and access to the ballot?",
        },
      ],
      summary:
        "Steve Cohen is a strong advocate for civil rights and equality. Highlighted actions show his dedicated support for the LGBTQ+ community and the fight against discrimination, demonstrated by legislation such as the Equal Employment For All Act and his vote for the Equality Act. He works tirelessly to promote racial and gender equality in employment and housing through the support of acts like the Violence Against Women Act, the Paycheck Fairness Act, and the Equal Rights Amendment. To protect voting rights and access to the ballot, Cohen introduced the SIMPLE Voting Act, backed the Voting Rights Advancement Act (HR4) and has made efforts to eliminate the Electoral College. His work has indicated staunch support for measures promoting equal rights across all sectors.",
    },
    Crime: {
      questions: [
        {
          answer:
            "We must take a comprehensive approach to criminal justice reform that addresses the systemic injustices that have led to mass incarceration and racial disparities. This includes investing in community-law enforcement relations, implementing successful alternative models like Restorative Justice, reforming the school-to-prison pipeline, decriminalizing and descheduling cannabis at the federal level, providing people with meaningful chances to start over and contribute to society, and reforming drug laws. We must also pass the George Floyd Justice in Policing Act and the Prisoner Treatment and Interdiction Reform Act to rebuild trust between police and the communities they serve and bring fairness to the criminal justice system. Finally, we must commute the sentences of non-violent offenders who are unjustly imprisoned and provide them with clemency. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1266458708936196096",
              text: "This is systemic injustice. It is critical that we take a comprehensive approach to this issue and further invest in community-law enforcement relations and successful alternative models like #RestorativeJustice. #BlackLivesMatter #BLM",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/598960304911831040",
              text: "#MichaelBrown, #EricGarner, #TamirRice, #WalterScott, #FreddyGray\u2014the need 4 reform is clear&urgent. I have a plan: http://1.usa.gov/1L4qXZB",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/752241302885072900",
              text: "Judiciary Committee hopefully will have hearing next week on racial disparities and problems in criminal justice https://twitter.com/bridgesusa/status/752238834012737536",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/570348112830541824",
              text: "I introduced legislation to cut short the #SchoolToPrisonPipeline,improve healing for victims&save our country money http://1.usa.gov/1zdoIet",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1292143537492877313",
              text: "#Marijuana reform is #CriminalJustice reform. The war on drugs has targeted and incarcerated Black communities for TOO LONG. We need to decriminalize and deschedule cannabis at the federal level. #LegalizeIt politico.com/news/2020/08/0\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/593067197855047680",
              text: "Finally. #CriminalJustice reform is long overdue. @nytimes:2016 Candidates Are United in Call to Alter Justice System http://nyti.ms/1PS7HkH",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1151231721138003970",
              text: "The best way to combat #recidivism is to provide people with meaningful chances to start over & contribute to society. I introduced HR 121, the Fresh Start Act, which would provide some non-violent offenders with an opportunity to have their records expunged. #CriminalJustice https://t.co/7mvgzEOwG8",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1119651189463842816",
              text: "The Marijuana Justice Act would reform drug laws, deschedule #marijuana & ensure profits from the legal #cannabis industry get re-invested into low-income & communities of color that have been devastated by the unequal marijuana law enforcement. #MarijuanaJustice #420 pic.twitter.com/C6t3fjydX8",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/621772444555198464",
              text: ".@POTUS took significant steps this week highlighting #FierceUrgencyOfNow for #CriminalJustice reform: http://1.usa.gov/1I6qKaZ",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/849389863241646081",
              text: "Intro'd #PTIRA w/Rep.Clay & @SenDuckworth to rebuild trust btwn #police & communities they serve & bring fairness to #criminaljustice system",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1235699415769157633",
              text: "Our prison population is larger than ever, and many remain imprisoned on mandatory minimum sentences that Congress repealed but didn\u2019t make retroactive. These individuals are unjustly imprisoned and deserve clemency. #SentencingReform #PrisonReform #CriminalJusticeReform",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1197251849000239105",
              text: "@HouseJudiciary This critical bill works to right the wrongs of the failed and racist War on Drugs by expunging criminal convictions, reinvesting in communities of color, decriminalizing #marijuana & promoting equitable participation in the cannabis industry. #WeWantMore",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/583037221101502464",
              text: "Pleased that, by commuting sentences of 22 drug offenders, Pres. Obama is showing he's serious abt #justice & reform http://1.usa.gov/1bOua3x",
            },
          ],
          question:
            "How would you approach criminal justice reform to reduce mass incarceration and address racial disparities?",
        },
        {
          answer:
            "I am committed to ensuring that all Americans are treated fairly and equitably before the law. To that end, I have introduced the Safer Streets Act, which would provide resources to address violent crime in communities where the rate is above the national average. I have also introduced the Police Training and Independent Review Act, which would provide officers with diversity training and increase accountability by incentivizing the use of independent prosecutors in cases of excessive and deadly force. Additionally, I have proposed a plan to repair community-police relations and invest in successful alternative models like #RestorativeJustice. Together, these strategies will help to ensure that our communities are safe and that justice is served. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1266458708936196096",
              text: "This is systemic injustice. It is critical that we take a comprehensive approach to this issue and further invest in community-law enforcement relations and successful alternative models like #RestorativeJustice. #BlackLivesMatter #BLM",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1150832607606820865",
              text: "Every American deserves to feel safe in their community. Last week I reintroduced the Safer Streets Act, which would provide resources to address violent crime in communities where the rate is above the national average. #EndGunViolence #SafeStreets\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1270089264949329920",
              text: "The bill includes provisions from my National Statistics on Deadly Force Transparency Act, Police CAMERA Act, & Police Training and Independent Review Act. I will continue fighting to ensure our #justice system is fair & responsive to the needs of all Americans. #BlackLivesMatter",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163975113013956611",
              text: "I also introduced the Police Training & Independent Review Act, H.R. 125, which would provide officers with diversity training & increase accountability by incentivizing the use of independent prosecutors in cases of excessive and deadly force.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/599332308877975553",
              text: "This week's eNewsletter: My plan to end excessive force http://1.usa.gov/1L7TKwy Sign up @ http://1.usa.gov/1AkEWUD #FreddieGray #WalterScott",
            },
          ],
          question:
            "What strategies will you employ to enhance community safety while promoting fairness and equity?",
        },
        {
          answer:
            "I believe that the best way to combat crime is to address the root causes of it. That's why I have introduced legislation to cut short the #SchoolToPrisonPipeline, improve healing for victims, and provide resources to address violent crime in communities where the rate is above the national average. I have also introduced the Fresh Start Act, which would provide some non-violent offenders with an opportunity to have their records expunged. Additionally, I have called for an investment in community-law enforcement relations and successful alternative models like #RestorativeJustice. #JusticeInPolicing #EndGunViolence #SafeStreets #CriminalJustice #DumbOnCrime #BlackLivesMatter #BLM #PoliceReform. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/570348112830541824",
              text: "I introduced legislation to cut short the #SchoolToPrisonPipeline,improve healing for victims&save our country money http://1.usa.gov/1zdoIet",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1150832607606820865",
              text: "Every American deserves to feel safe in their community. Last week I reintroduced the Safer Streets Act, which would provide resources to address violent crime in communities where the rate is above the national average. #EndGunViolence #SafeStreets\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1151231721138003970",
              text: "The best way to combat #recidivism is to provide people with meaningful chances to start over & contribute to society. I introduced HR 121, the Fresh Start Act, which would provide some non-violent offenders with an opportunity to have their records expunged. #CriminalJustice https://t.co/7mvgzEOwG8",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1266458708936196096",
              text: "This is systemic injustice. It is critical that we take a comprehensive approach to this issue and further invest in community-law enforcement relations and successful alternative models like #RestorativeJustice. #BlackLivesMatter #BLM",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1266458708189630465",
              text: "I have also started discussions to find the best way for the families of victims to speak directly to Congress & called on my colleagues to urgently move my legislation & uphold our responsibility under the #14thAmendment to ensure due process & equal protections for all.",
            },
          ],
          question:
            "Are there specific initiatives for addressing the root causes of crime that you champion?",
        },
      ],
      summary:
        "Steve Cohen is adamant about combating the root causes of crime and ensuring fair and equitable treatment for all before the law. He advocates for a comprehensive approach to reform the criminal justice system to reduce mass incarceration and address racial disparities. He prioritizes investment in community-law enforcement relations, implementing alternative models like Restorative Justice, reforming the school-to-prison pipeline, and reforming drug laws, specifically the decriminalization and descheduling of cannabis at the federal level.\n\nHe has introduced several legislative actions like the Safer Streets Act, Police Training and Independent Review Act, and the Fresh Start Act aimed at addressing violent crime, increasing police accountability, and providing non-violent offenders with a chance to expunge their records.\n\nCohen also supports sentencing reforms, including granting clemency to non-violent offenders and passing acts to establish fairness and rebuild trust in the criminal justice system, such as the George Floyd Justice in Policing Act and the Prisoner Treatment and Interdiction Reform Act. His initiatives emphasize healing for victims and enhancing safety and justice throughout communities while promoting fairness and equity.",
    },
    Education: {
      questions: [
        {
          answer:
            "I'm proud to have joined you in supporting H.R. 865, the #RebuildAmericasSchools Act, which would invest $100 billion over 10 years in fixing America\u2019s public schools. I'm also proud to be a member of the first-ever Congressional #PreKCaucus and support expanding access to high-quality early #education for our kids. I introduced legislation to cut short the #SchoolToPrisonPipeline,improve healing for victims&save our country money. Pell Grants provide low-income and working class students with the opportunity to pursue a college degree and help to encourage #DiversityInSTEM. I just introduced a bill to help our #veterans gain the skills they need to find good-paying #jobs. I'm also pleased that the President signed the #ESSA, which upholds Civil Rights & high standards & includes my amendment to keep students out of school2prison pipeline. I have also introduced the Open Book on Equal Access to #Justice Act to ensure transparency for litigants suing or defending against the federal government. I joined @SenatorDurbin in introducing the Protect Our Students & Tax Payers (POST) Act, which will prevent for-profit colleges from exploiting our veterans. I have also introduced legislation to ensure every state allows citizens to vote early&w/o burdensome waits. I'm also committed to supporting public education, teachers & students. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1105990688423010304",
              text: "Thanks for coming by. I\u2019m proud to join you in supporting H.R. 865, the #RebuildAmericasSchools Act, which would invest $100 billion over 10 years in fixing America\u2019s public schools. #Education4All #QualityEducation",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/720633085642018816",
              text: "Proud to be a member of the first-ever Congressional #PreKCaucus and support expanding access to high-quality early #education for our kids.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/570348112830541824",
              text: "I introduced legislation to cut short the #SchoolToPrisonPipeline,improve healing for victims&save our country money http://1.usa.gov/1zdoIet",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1128347242811797505",
              text: "Pell Grants provide low-income and working class students with the opportunity to pursue a college degree and help to encourage #DiversityInSTEM. Diversity isn\u2019t just a buzz word; it brings new perspectives which helps to enhance innovation and combat bias. #Science #STEM",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/555485412710703108",
              text: "I just introduced a bill to help our #veterans gain the skills they need to find good-paying #jobs--read more: http://1.usa.gov/14B9bgm",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/695646907532713984",
              text: "Proud to receive an A from the @NEAToday on their 2015 Legislative Scorecard. Committed to supporting public education, teachers & students.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/578656413884526592",
              text: "As father of #HOPEscholarship, I've always wrkd 4 #CollegeAffordability. With @NancyPelosi on #studentloan ReFi bill http://1.usa.gov/1I1SUzK",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/675032111548616705",
              text: "pleased @POTUS signed #ESSA 2day. upholds Civil Rights & high standards & includes my amendmnt 2 keep students out of school2prison pipeline",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/593861136363200512",
              text: "Proud to join @RepMarkTakano, @RepSusanDavis& @rweingarten to introduce bill to hold #4profit colleges accountable: http://1.usa.gov/1FAGtft",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1139662417682735104",
              text: "Some favor cash reparations. Others, like Harvard Law Prof. Charles Ogletree, say #Reparations could be in the form of investments in healthcare, education, & job training. A commission established by #HR40 would foster debate & bring this issue the attention it deserves.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/558373321549819906",
              text: "I also introduced legislation this week to ensure every state allows citizens to vote early&w/o burdensome waits http://1.usa.gov/1GD4LaD",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/652143803743141888",
              text: "Americans need #jobs, #healthcare&affordable #education\u2014so why is GOP laser-focused on #PlannedParenthood lies? http://bit.ly/1L8QWxG",
            },
          ],
          question:
            "How will you improve the quality of public education and increase access to higher education?",
        },
        {
          answer:
            "I have been a strong advocate for protecting students and taxpayers from predatory for-profit colleges and ensuring that student loan debt is discharged during bankruptcy. I have introduced the Protect Our Students & Tax Payers (POST) Act, the National Guard & Reservists Debt Relief Extension Act, the SCAM Debt Act, and the Student Loan Refinancing Act to address this issue. I have also joined letters asking Congressional Leadership to prioritize Paycheck Protection Program loans for small businesses owned by minorities, women, veterans, and those in underserved markets in the next COVID stimulus bill. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1090699243977932800",
              text: "Private #StudentLoans are no different than other consumer debt, and should be discharged during bankruptcy. No one wants to declare bankruptcy, but the system should work as a safety net and protect individuals facing unexpected financial strain.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1139262485431824385",
              text: "This week I joined @SenatorDurbin in introducing the Protect Our Students & Tax Payers (POST) Act, which will prevent for-profit colleges from exploiting our veterans. Many of these schools weigh down veterans with debt, but don\u2019t provide them with marketable skills or degrees.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/865280582841970689",
              text: "Intro'd bill w/@RepSwalwell & @RepDannyDavis to restore fairness in #student lending. Read: http://bit.ly/2rw82CL #education #bankruptcy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/608363268160278528",
              text: "Pleased @usedgov is expanding pool of those eligible for #studentloan debt relief in wake of Corinthian closing:  http://1.usa.gov/1dtAHRC",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1153811781845831682",
              text: "Today the House passed my bill, the #NationalGuard & Reservists Debt Relief Extension Act. These #veterans had their civilian lives disrupted to serve our country; it is only fair that we help them during the bankruptcy process. @SenateGOP needs to hold a vote on this measure. https://t.co/r0SHTIoFvx",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1232704733514805248",
              text: "In 2019, nearly 500,000 people filed complaints about #DebtCollection fraud. I joined @RepBonamici & @RepBillFoster in introducing the SCAM Debt Act, which would protect consumers from predatory debt collectors who try to convince them to pay debts that are not legally due.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/580449232982843392",
              text: "Joined @SenatorDurbin & @RepCartwright to introduce bill to crack down on #PredatoryLending & high interest rates: http://1.usa.gov/1xePmJi",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/910498131145568256",
              text: "I'm proud to join @RepCartwright, @SenatorDurbin & @SenJeffMerkley in introducing a bill to crack down on predatory lending practices. pic.twitter.com/cSjDAn57L3",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1149409727668994048",
              text: ".@HouseJudiciary voted to send my bill, the #NationalGuard and Reservists Debt Relief Extension Act, to the House floor. These #veterans had their civilian lives disrupted to serve our country; it is only fair that we help them during the bankruptcy process. https://t.co/DUdCOrsMyN",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1217973560150331395",
              text: "The House voted to reject a Trump Administration rule that denies defrauded student borrowers the debt relief they desperately need. Unlike Secretary DeVos, @HouseDemocrats work #ForThePeople, not predatory for-profit colleges. #StandWithStudents pic.twitter.com/IEhUG6eAJU",
            },
          ],
          question:
            "What measures do you endorse to address the issue of student loan debt?",
        },
        {
          answer:
            "Absolutely. I'm proud to be a member of the first-ever Congressional #PreKCaucus and support expanding access to high-quality early #education for our kids. I introduced legislation to cut short the #SchoolToPrisonPipeline, improve healing for victims, and save our country money. I'm also proud to support the #BESTPracticesAct to modernize military training and save animals. I'm committed to supporting public education, teachers, and students, and I'm working to ensure that our schools have the resources they need to provide a quality education. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/695646907532713984",
              text: "Proud to receive an A from the @NEAToday on their 2015 Legislative Scorecard. Committed to supporting public education, teachers & students.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/720633085642018816",
              text: "Proud to be a member of the first-ever Congressional #PreKCaucus and support expanding access to high-quality early #education for our kids.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/570348112830541824",
              text: "I introduced legislation to cut short the #SchoolToPrisonPipeline,improve healing for victims&save our country money http://1.usa.gov/1zdoIet",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/571025374664114178",
              text: "I support #BESTPracticesAct 2modernize military training&save animals @RepHankJohnson @RepFitzpatrick @RonWyden @PCRM pic.twitter.com/2q2A7R2qrC",
            },
          ],
          question:
            "Are there policies to support teachers and improve educational outcomes that you prioritize?",
        },
      ],
      summary:
        "Steve Cohen emphasizes the importance of improving public education, increasing access to higher education, and supporting teachers. He endorses the #RebuildAmericasSchools Act for a $100 billion investment over 10 years to fix public schools and supports expanding access to high-quality early education. He has introduced several acts to ameliorate the school-to-prison pipeline issue, improve victims' healing, and save money for the country.\n\nTo address student loan debt, Cohen advocates for protecting students and taxpayers from predatory for-profit colleges and ensuring that student loan debt can be discharged during bankruptcy. His introduced measures include the Protect Our Students & Tax Payers (POST) Act, the National Guard & Reservists Debt Relief Extension Act, the SCAM Debt Act, and the Student Loan Refinancing Act for this purpose.\n\nCohen prioritizes policies to support teachers and improve educational outcomes. He's a part of the Congressional Pre-K Caucus, focusing on high-quality early education access. His commitment to supporting public education, teachers, and students is clear, and he works to ensure schools have adequate resources to deliver quality education.",
    },
    "Environmental and Climate Policy": {
      questions: [
        {
          answer:
            "I am committed to combatting the #ClimateCrisis and ensuring we live in and leave behind a healthier, safer, and more sustainable world for our children and grandchildren. I am proud to support the #GreenNewDeal Resolution, which calls for bold action to address the crisis of #ClimateChange. We must focus on conservation and #CleanEnergy, and take action now to protect our planet and our health. I am also proud to be a part of the Science, Space & Technology Committee, which will have a pivotal role on critical issues such as #climatechange, the #spaceprogram, and clean energy in the years ahead. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1123664132400656389",
              text: "Combatting the #ClimateCrisis is a moral imperative. We must act to ensure we live in and leave behind a healthier, safer & more sustainable world for our children and grandchildren. We need #ClimateActionNow; that\u2019s why I\u2019m proud to support #HR9 https://t.co/B6mSQzElKt",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/590953409685155842",
              text: "This #EarthDay let's recommit & #ActOnClimate. Time2confront climate crisis, focus on conservation& #CleanEnergy. pic.twitter.com/ZpQxhSDV3j",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1217626869425737728",
              text: "As emissions continue to rise, air quality decreases and heat waves become more common. This will have a major effect on human health. We need to #ActOnClimate to protect our planet and our health. The cost of inaction will be much greater than the cost action. #ClimateChange https://t.co/onlBoD1Vmg",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1088523782061477888",
              text: "Science still exists @realDonaldTrump and I look forward to learning more and passing that learning on and into scientifically based policy. #climatechange #Climate #Solar",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1072259525640642562",
              text: "I've always fought for #CleanAir,#CleanWater,#CleanEnergy & a better #Environment. As #Democrats prepare to take the House in 2019 I'm proud to support a select committee for a #GreenNewDeal w/@Ocasio2018, @repjohnlewis, @tedlieu & more. It's time for #ClimateAction & #GreenJobs. pic.twitter.com/KXgseyIjAX",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1093630484980977669",
              text: "During the Great Depression, when we sat on the brink of financial ruin, Democrats passed the New Deal & saved our country.\n\nToday, as we face the crisis of #ClimateChange, bold action is needed once again. That\u2019s why I\u2019m proud to co-sponsor @AOC\u2019s #GreenNewDeal Resolution.",
            },
          ],
          question:
            "How will you address climate change and promote sustainability?",
        },
        {
          answer:
            "We need to take action on climate change now. We can reduce greenhouse gas emissions by investing in clean energy sources, conserving energy and water, and reducing our use of carbon fuels. We should also incentivize corporations to use their revenue for roads and bridges instead of dodging taxes. Finally, we should shift the responsibility for recycling and cleanup to the companies that produce wasteful products. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/868458594743791616",
              text: "Recycle all you can.Avoid using plastic products.Take bags to grocery for sacking.refuse plastic straws. https://twitter.com/huffpostpol/status/868439408554409985",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1133485574550151170",
              text: "Copenhagen is going for #carbon neutral 2025.Lots to learn from Denmark which is reducing use of carbon fuels and conserving energy and water.#conservation #GreenNewDeal #GreenEnergy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1217626869425737728",
              text: "As emissions continue to rise, air quality decreases and heat waves become more common. This will have a major effect on human health. We need to #ActOnClimate to protect our planet and our health. The cost of inaction will be much greater than the cost action. #ClimateChange https://t.co/onlBoD1Vmg",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1123326406346330112",
              text: "During a @HouseScience Committee hearing on #ClimateChange earlier this year, every witness agreed that we should remain in the Paris Agreement. #HR9 would prevent Trump from removing the U.S. from the agreement & develop a plan for cutting emissions. #ClimateActionNow https://t.co/GIOaFczpqN",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/552903860629819394",
              text: "Why incentivize corporations to dodge taxes? @HouseDemocrats have a plan to use that revenue on roads&bridges instead http://goo.gl/x2jFLE",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/918125644038529025",
              text: "It is time to act on #climatechange, not to retreat. Any move to dismantle the #CleanPowerPlan is an assault on our health & our environment pic.twitter.com/1DHXnEl4wf",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1217883903677124619",
              text: "We can\u2019t leave the burden of #ClimateChange for future generations to address. If we don\u2019t do something soon it will be too late. We need to #ActOnClimate now!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1201878471909298176",
              text: "The U.N. released a report stating that, if we don\u2019t take action on climate change, humanity\u2019s future will be bleak. No one should want their children to live in this future. We need #ClimateActionNow and a #GreenNewDeal. washingtonpost.com/opinions/no-on\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1121117083817971712",
              text: "Trump\u2019s environmental deregulation has led to an increase in #AirPollution. 4/10 Americans now live in counties with heavily polluted air. I\u2019m proud to cosponsor #HR9, which would return the U.S. to the Paris climate agreement & establish a plan for reducing emissions. #CleanAir",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1227354913610567681",
              text: "92% of U.S. plastic waste is never recycled. By shifting the responsibility for recycling & cleanup to the companies that produce wasteful products, practical waste reduction & management policies can help us break free from plastic pollution. #PlasticFree nytimes.com/2020/02/10/bus\u2026",
            },
          ],
          question: "Strategies for reducing greenhouse gas emissions?",
        },
        {
          answer:
            "I'm proud to support the #HR9 bill, which would return the U.S. to the Paris climate agreement and establish a plan for reducing emissions. We must also protect public health by improving our economy with the EPA's Clean Power Plan. We must take bold action to protect our environment and national security. #ActOnClimate #CleanAir #CleanWater #ClimateActionNow. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1123664132400656389",
              text: "Combatting the #ClimateCrisis is a moral imperative. We must act to ensure we live in and leave behind a healthier, safer & more sustainable world for our children and grandchildren. We need #ClimateActionNow; that\u2019s why I\u2019m proud to support #HR9 https://t.co/B6mSQzElKt",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/627221522051137537",
              text: "The @EPA #CleanPowerPlan gives us the chance to protect public #health while improving our #economy. It\u2019s time to #ActOnClimate.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1115717015723843585",
              text: "While the White House continues to ignore #science, our military is actively preparing for the real & immediate threat of #ClimateChange. We are facing a #ClimateCrisis & must take bold action to protect our environment & national security. #ActOnClimate\nwashingtonpost.com/national/healt\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1201878471909298176",
              text: "The U.N. released a report stating that, if we don\u2019t take action on climate change, humanity\u2019s future will be bleak. No one should want their children to live in this future. We need #ClimateActionNow and a #GreenNewDeal. washingtonpost.com/opinions/no-on\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/846791743148376065",
              text: "#Trump just overturned #CleanPowerPlan putting our health & climate at serious risk. Now more than ever we must #DefendClimate #ActOnClimate https://t.co/QruqTtKqXz",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1121117083817971712",
              text: "Trump\u2019s environmental deregulation has led to an increase in #AirPollution. 4/10 Americans now live in counties with heavily polluted air. I\u2019m proud to cosponsor #HR9, which would return the U.S. to the Paris climate agreement & establish a plan for reducing emissions. #CleanAir",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1123326406346330112",
              text: "During a @HouseScience Committee hearing on #ClimateChange earlier this year, every witness agreed that we should remain in the Paris Agreement. #HR9 would prevent Trump from removing the U.S. from the agreement & develop a plan for cutting emissions. #ClimateActionNow https://t.co/GIOaFczpqN",
            },
          ],
          question: "Policies for environmental protection and climate action?",
        },
      ],
      summary:
        "Steve Cohen is strongly committed to addressing climate change and promoting sustainability for future generations. He supports the Green New Deal Resolution, which proposes aggressive action against climate change. He believes in focusing on conservation, clean energy, and immediate action to protect both the planet and people's health. Cohen also sits on the Science, Space & Technology Committee, which deals with vital topics such as climate change, the space program, and clean energy.\n\nHe advocates for reducing greenhouse gas emissions through investment in clean energy sources, conservation of energy and water, and lessening the use of carbon fuels. His strategies also include encouraging corporations to invest in infrastructure rather than evading taxes, and having companies that produce wasteful products take responsibility for recycling and cleanup.\n\nCohen supports the HR9 bill, which would involve the U.S. in the Paris climate agreement again and create a strategy for reducing emissions. He promotes the protection of public health via economic improvement through the EPA's Clean Power Plan. Cohen is an advocate for firm action to secure the environment and national security. His policies emphasize clean air, clean water, and immediate action on climate.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      questions: [
        {
          answer:
            "I believe the United States should take a strong stance in support of Ukraine's sovereignty and independence. We must hold Russia accountable for its actions and ensure that it is held to its international obligations. We must also continue to provide security assistance to Ukraine to help them defend against Russian aggression. We must also work with our allies in the region to ensure that Ukraine is not isolated and that it has the support it needs to protect its sovereignty. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1013281264655314945",
              text: "U.S. House of Representatives committee adopts draft resolution in support of Ukraine's sovereignty ukrinform.net//rubric-polyti\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1019972938072813568",
              text: "Over 4 years after invasion of #Ukraine,#Russia continues to flout internat'l obligations&disregard global opinion.There must be painful consequences.Yesterday I was proud to introduce a bill to hit #Putin where it hurts: in his wallet.#RussianSanctionsNow bit.ly/2mvpUxs",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/847135636419153920",
              text: 'Spoke in support of #ResolutionofInquiry on ties btwn #Trump & #Russia. #Congress must address "#RedMenace." Watch: http://bit.ly/2nhoHHJ',
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/850024024080035849",
              text: "Intro'd bipartisan resolution w/ @RepShimkus reaffirming US support for #NATO & #EasternEurope. @NATO critical for security of #US & #Europe https://t.co/IGtArwyrg0",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1148303489556254720",
              text: "This past week at the @OSCE parliamentary assembly in Luxembourg, I spoke on the need to address rising authoritarianism in the former Soviet Union and #antisemitism throughout Europe. These hateful ideologies are a threat to freedom and democracy. #EndHate #HumanRights https://t.co/L73Jpmuvnx",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1197880844209336320",
              text: "REMINDER: The aid withheld by Trump was critical security aid to support Ukrainian troops fighting Kremlin-backed separatists. Withholding that aid put Ukrainian lives on the line, endangered our national security and helped advance Putin\u2019s ambitions. #ImpeachmentInquiry",
            },
          ],
          question:
            "How would you approach the ongoing Ukraine conflict, and what role do you believe the United States should play in resolving it?",
        },
        {
          answer:
            "My foreign policy priorities are to ensure that the United States stands by its allies and international organizations, such as NATO, and to counter Russian aggression in Europe. I have worked in a bipartisan fashion to pass my resolution HRes256, which reaffirms US support for NATO and Eastern Europe. I have also spoken at the OSCE Parliamentary Assembly in Luxembourg on the need to address rising authoritarianism in the former Soviet Union and antisemitism throughout Europe. These are important steps to protect freedom and democracy. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/902881874640592896",
              text: "Washington Remains United Behind NATO https://www.nytimes.com/2017/08/30/opinion/washington-remains-united-behind-nato.html on bi-partisan Codel reassuring #NATO allies &visiting troops.Congress united.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1113223837485633536",
              text: "This week marks the 70th Anniversary of #NATO. Congress will continue to support the Alliance & work to counter Russian aggression in Europe. We will stand by our allies, as they have stood by us. Tomorrow the NATO Secretary General will address a joint meeting of Congress.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/850024024080035849",
              text: "Intro'd bipartisan resolution w/ @RepShimkus reaffirming US support for #NATO & #EasternEurope. @NATO critical for security of #US & #Europe https://t.co/IGtArwyrg0",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1113459982396211200",
              text: "Today we recognize the 70th Anniversary of #NATO. It is the most important security link between the United States and Europe and a defense against Russian aggression. Last year, I introduced & Congress passed #HRes256 to stand up to Trump & affirm Congress\u2019 support to @NATO.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1113484760129658880",
              text: "Freedom has enemies and @NATO is the best tool for dealing with those enemies. #NATO was founded to defend against the Soviet Union and remains relevant in a new era of Russian aggression. I join the Sec. General in calling on Russian to comply with the INF missile treaty. pic.twitter.com/BeDnDSuWny",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1148303489556254720",
              text: "This past week at the @OSCE parliamentary assembly in Luxembourg, I spoke on the need to address rising authoritarianism in the former Soviet Union and #antisemitism throughout Europe. These hateful ideologies are a threat to freedom and democracy. #EndHate #HumanRights https://t.co/L73Jpmuvnx",
            },
          ],
          question:
            "What are your foreign policy priorities, particularly in relation to allies and international organizations?",
        },
        {
          answer:
            "I believe that the best way to benefit American interests is to ensure that our trade agreements are fair and equitable. We must ensure that our trading partners are held to the same standards as we are, and that our workers and businesses are not taken advantage of. We must also ensure that our global economic relationships are mutually beneficial, and that our trading partners are held accountable for their actions. To this end, I have introduced legislation to help our veterans gain the skills they need to find good-paying jobs, and I have supported the multilateral deal to stop Iran from building a nuclear weapon. I have also worked in a bipartisan fashion to pass a resolution supporting NATO and the important work they do. Finally, I have introduced a bill to use revenue from corporations to invest in our roads and bridges, and I have supported the FAIRAct, which will end forced arbitration agreements. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/552903860629819394",
              text: "Why incentivize corporations to dodge taxes? @HouseDemocrats have a plan to use that revenue on roads&bridges instead http://goo.gl/x2jFLE",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/555485412710703108",
              text: "I just introduced a bill to help our #veterans gain the skills they need to find good-paying #jobs--read more: http://1.usa.gov/14B9bgm",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/642109835576766464",
              text: "I'm about to speak on the House floor in support of the multilateral deal to stop Iran from building a nuclear weapon http://cs.pn/1i1W1Aa",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/635504968292696065",
              text: 'I support the agreement. There really is no other choice in the real world of int.politics."Give peace a chance"John https://twitter.com/cirincione/status/635461472932491264',
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1014201672850132993",
              text: "\"#Trump has repeatedly questioned(#NATO's) value&claimed that its members are taking advantage of the US\"nyti.ms/2tZ66Wu\n\nI'm proud the @HouseForeign AffairsCmte. worked in #bipartisan fashion last week to pass my resolution #HRes256 supporting NATO&important work they do",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1151563305644957698",
              text: "1 in 4 Americans don\u2019t plan to retire. Trump claims the economy is doing great, but working people aren\u2019t feeling the benefits. Corporate profits are rising, but wage growth is stagnant. We need policies that work #ForThePeople, not major corporations. \napnews.com/e38b971fb04942\u2026",
            },
          ],
          question:
            "How will you navigate trade agreements and global economic relationships to benefit American interests?",
        },
        {
          answer:
            "I believe that diplomacy is the only answer to international conflicts. I support the use of stronger sanctions and deterrence in the short run, as preventive attack will be a disaster for the Republic of Korea and Japan, leading to horrific death tolls. I have seen the success of diplomacy first hand, such as when I attended the opening of our embassy in Cuba and when I spoke on the House floor in support of the multilateral deal to stop Iran from building a nuclear weapons. I also support the use of dialogue and codes of conduct in war and life, and I believe that international trips help to inform the policies and decisions made by Members of Congress. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/853022631720873984",
              text: "diplomacy is only answer.Stronger sanctions & deterrence in short run.preventive attack will be disaster4 ROK & Japan.Horrific death #'s. https://twitter.com/realdonaldtrump/status/852508752142114816",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/631995346600742912",
              text: "Going to opening of our embassy in #Cuba tomorrow.Diplomacy wins over failed Cold War. China,Vietnam but not Cuba? Common sense prevails!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/635504968292696065",
              text: 'I support the agreement. There really is no other choice in the real world of int.politics."Give peace a chance"John https://twitter.com/cirincione/status/635461472932491264',
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/562749914271469570",
              text: "Will support #Jordan,#Japan & #POTUS in response to #ISIS .Terror tactics need be responded to.There are codes of conduct in war and life!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/642109835576766464",
              text: "I'm about to speak on the House floor in support of the multilateral deal to stop Iran from building a nuclear weapon http://cs.pn/1i1W1Aa",
            },
          ],
          question:
            "What approach will you take to address international conflicts and promote diplomacy?",
        },
      ],
      summary:
        "Steve Cohen is an advocate for U.S. involvement in the global arena, particularly in relation to supporting allies and international organizations like NATO. He's strongly in favor of holding foreign nations, such as Russia, accountable for their actions and ensuring they honor their international obligations. \n\nIn the ongoing Ukraine conflict, Cohen supports strengthening Ukraine's sovereignty and independence, and encourages continued U.S. aid for Ukraine to stand against Russian aggression. He seeks to work with regional allies to prevent Ukraine's isolation.\n\nIn terms of foreign trade relations, Cohen believes in equitable trade agreements that hold trading partners to the same standards as the United States. His aim is for American workers and businesses to competitively function in global trade without being taken undue advantage of.\n\nFinally, as regards international conflicts, Cohen supports diplomacy over warfare. He is in favor of preventive measures such as sanctions and deterrents compared to aggressive combat. He cites his experience in Cuba and Iran as positive examples of diplomatic resolutions. He champions dialogue and adherence to conduct codes in handling global conflicts.",
    },
    Guns: {
      questions: [
        {
          answer:
            "I believe that banning high capacity magazines and assault weapons, as well as prohibiting the sale of assault weapons, will be effective in reducing gun violence. We have seen this work in the past, when the assault weapons ban was in place from 1994-2004. Additionally, expanding background checks and closing the Charleston loophole are important steps to take. We must also take action to keep weapons of war off our streets. #EndGunViolence #DoSomething #Enough #GunControlNow #GunSense #UpdateBackgroundChecks #BanAssaultWeapons #HonorWithAction. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163924605809123328",
              text: "The #Dayton shooter fired 41 bullets in 32 seconds. Weapons like that don\u2019t belong on our streets. We need to ban high capacity magazines and assault weapons. We banned assault weapons in 1994 & it worked. Mass shootings fell until the measure expired. #DoSomething #Enough https://t.co/lr0FeA8Xkm",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/966344179730337793",
              text: 'Regulating #bumpstocks &only acting on #NRA-approved #BackgroundChecks are baby steps to make us feel good,not real action as #Trump suggests. Restrictions/prohibitions on #AssaultWeapons &large magazines would "actually make that difference"#ParklandStudentsSpeak #EndGunViolence',
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1158018127105613825",
              text: "Senate must pass House passed bills on background checks and #Charleston loophole.We must also prohibit high capacity magazines and assault weapon sales.When is #EnoughIsEnough for Trump and Republicans to stand up to #NRA ? #EndGunViolence @MomsDemand #GunControlNow",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1098011600634601473",
              text: "Last week @HouseJudiciary passed the first major gun violence prevention legislation in decades, but there\u2019s more we can do to #EndGunViolence. I\u2019m proud to cosponsor @davidcicilline\u2019s assault weapon ban, which will help keep weapons of war off our streets. #GunSense #Enough",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1108805479520600064",
              text: "The House passed #HR8, the first gun violence prevention legislation in decades. 93% of Americans support expanding #BackgroundChecks. @SenateGOP, prove that you work #ForThePeople and hold a vote. #EndGunViolence\nnytimes.com/2019/03/20/opi\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163525102446817280",
              text: ".@HouseJudiciary is returning early from recess to vote on 3 #GunViolence prevention bills. The Keep Americans Safe Act (H.R. 1186) would ban high capacity magazines, like the one used by the shooter in #Dayton, OH. @HouseDemocrats are ready to #DoSomething. Is the @SenateGOP?",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1176988247446016002",
              text: "Weapons of war have no place in our communities or on our streets. I stand with the American people & law enforcement community, not the #NRA, in support of a ban on assault weapons. We need to prevent these senseless & tragic mass shootings. #BanAssaultWeapons #EndGunViolence https://t.co/2ksZTzhsbd",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/719954094937489408",
              text: "#Congress must wake up to the #gunviolence epidemic in America. We need commonsense reforms & we need them now. #NotOneMore #HonorWithAction",
            },
          ],
          question:
            "What gun control measures do you believe will be effective in reducing gun violence?",
        },
        {
          answer:
            "I believe that we can protect Second Amendment rights while also taking steps to ensure public safety. We must take action to reduce gun violence, and that includes passing legislation to ban high capacity magazines and assault weapons, as well as closing the Charleston loophole and passing red flag laws. We must also pass legislation to provide resources to address violent crime in communities where the rate is above the national average. We must take action now to protect our communities and our rights. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/745391577011363840",
              text: "And what are you trying to say or be? Cute?smart?. 2nd amendment proponent? Bazookas? Howitzers?nukes? Guns 4 loons? https://twitter.com/thealexinman/status/745384955530772481",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163525102446817280",
              text: ".@HouseJudiciary is returning early from recess to vote on 3 #GunViolence prevention bills. The Keep Americans Safe Act (H.R. 1186) would ban high capacity magazines, like the one used by the shooter in #Dayton, OH. @HouseDemocrats are ready to #DoSomething. Is the @SenateGOP?",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/747566721129558018",
              text: "#SCOTUS upheld ban on domestic abusers owning #guns. Reiterates #SecondAmendment isn't absolute.Time for #NoFlyNoBuy http://1.usa.gov/293dQOr",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/966467517467254788",
              text: "#bumpstock regs, NRA-approved #BackgroundChecks &Moments of Silence--feel-good half measures by the #GOP, won't bring safety or change. \n\n#StudentsDemandAction &so do many more: restrict #AssaultWeapons &large magazines, take real action to #EndGunViolence. @MomsDemand @Everytown",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1150832607606820865",
              text: "Every American deserves to feel safe in their community. Last week I reintroduced the Safer Streets Act, which would provide resources to address violent crime in communities where the rate is above the national average. #EndGunViolence #SafeStreets\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1171596889742950400",
              text: "The vast majority of Americans support red flag laws like the Extreme Risk Protection Order Act, which was just passed by @HouseJudiciary. Despite this, the bill received no Republican support. Will the GOP ever stand up to the #NRA? #RedFlagLawsSaveLives #EndGunViolence",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1098011600634601473",
              text: "Last week @HouseJudiciary passed the first major gun violence prevention legislation in decades, but there\u2019s more we can do to #EndGunViolence. I\u2019m proud to cosponsor @davidcicilline\u2019s assault weapon ban, which will help keep weapons of war off our streets. #GunSense #Enough",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163924605809123328",
              text: "The #Dayton shooter fired 41 bullets in 32 seconds. Weapons like that don\u2019t belong on our streets. We need to ban high capacity magazines and assault weapons. We banned assault weapons in 1994 & it worked. Mass shootings fell until the measure expired. #DoSomething #Enough https://t.co/lr0FeA8Xkm",
            },
          ],
          question:
            "How do you plan to balance Second Amendment rights with public safety concerns?",
        },
        {
          answer:
            "Absolutely. I'm proud to support commonsense gun safety legislation like #HR8, the Bipartisan Background Checks Act, and the Extreme Risk Protection Order Act. These bills will help keep weapons of war off our streets and ensure that only responsible gun owners have access to firearms. We must also prohibit high capacity magazines and assault weapon sales. We must take real action to #EndGunViolence. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1108805479520600064",
              text: "The House passed #HR8, the first gun violence prevention legislation in decades. 93% of Americans support expanding #BackgroundChecks. @SenateGOP, prove that you work #ForThePeople and hold a vote. #EndGunViolence\nnytimes.com/2019/03/20/opi\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/976542388033409026",
              text: "The @NRA gave me an \"F\" rating for my votes in support of commonsense gun safety legislation, & I couldn't be more pleased. I'm proud to stand with @RepJohnYarmuth & @HouseDemocrats against the gun lobby. #MarchForOurLives #AssaultWeaponsBan #GunControlNow pic.twitter.com/RRgjIaT2GD",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1118240374743609345",
              text: "The vast majority of Americans support #GunViolence prevention legislation. The House passed #HR8, the Bipartisan Background Check Act. @SenateMajLdr Mitch McConnell needs to hold a vote on this bill and show that he works #ForThePeople, not the NRA. #Enough #EndGunViolence",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1171596889742950400",
              text: "The vast majority of Americans support red flag laws like the Extreme Risk Protection Order Act, which was just passed by @HouseJudiciary. Despite this, the bill received no Republican support. Will the GOP ever stand up to the #NRA? #RedFlagLawsSaveLives #EndGunViolence",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1158018127105613825",
              text: "Senate must pass House passed bills on background checks and #Charleston loophole.We must also prohibit high capacity magazines and assault weapon sales.When is #EnoughIsEnough for Trump and Republicans to stand up to #NRA ? #EndGunViolence @MomsDemand #GunControlNow",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1098011600634601473",
              text: "Last week @HouseJudiciary passed the first major gun violence prevention legislation in decades, but there\u2019s more we can do to #EndGunViolence. I\u2019m proud to cosponsor @davidcicilline\u2019s assault weapon ban, which will help keep weapons of war off our streets. #GunSense #Enough",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163799435857530883",
              text: "Trump claimed he supported expanding #BackgroundChecks, but he has already reversed. He & McConnell are beholden to the gun lobby & they won\u2019t do anything without the #NRA\u2019s approval. The American people support commonsense gun reform & they deserve to see action. #DoSomething https://t.co/9cdit22OzP",
            },
          ],
          question:
            "Are there policies to promote responsible gun ownership that you support?",
        },
      ],
      summary:
        "Steve Cohen is a proponent of stricter gun controls to reduce gun violence. He believes in banning high capacity magazines and assault weapons, and prohibiting their sale would be effective steps towards this goal. He also advocates for expanding background checks and closing the Charleston loophole, which allows the sale of firearms to proceed if a background check is not completed within three business days. Cohen supports commonsense gun safety legislation like the Bipartisan Background Checks Act and the Extreme Risk Protection Order Act. He asserts that these measures can ensure public safety without infringing on Second Amendment rights.",
    },
    Healthcare: {
      questions: [
        {
          answer:
            "I have been a co-sponsor of #MedicareForAll since 2007, and I am now the Vice-Chair of the Medicare for All Caucus. I am committed to fighting for universal coverage and to ensure that all Americans have access to quality, affordable healthcare. I will work to protect, strengthen, and expand Medicare and Medicaid, and to lower the cost of prescription drugs. I will also fight to protect people with pre-existing conditions and to ensure that no one is denied healthcare due to their income. #HealthcareIsARight #ForThePeople #ProtectOurCare. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1120856655816798209",
              text: "Republicans in Congress have spent the last decade trying to take #HealthCare away from people. Next week, @RulesDemocrats will hold the first hearing on #MedicareForAll. Every American should have access to quality healthcare. #HealthcareIsARight\n https://t.co/kUzifXFt1r",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1177327134135717890",
              text: "Increases in deductibles and premiums are making #insurance unaffordable for working Americans. Passing #MedicareForAll is the best way to ensure all Americans receive the #Healthcare they need & deserve.\nnytimes.com/2019/09/25/hea\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1100848795640688640",
              text: "I\u2019ve co-sponsored #MedicareForAll since 2007 because, as the late Sen. Ted Kennedy said, \u201chealth care is a right, not a privilege.\u201d The American people want universal coverage. As the new Vice-Chair of the Medicare for All Caucus, I\u2019ll work to make that a reality. #ForThePeople https://t.co/MeDzmQ5NwS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1170020667607134208",
              text: "This is a major moment in the fight to provide every American with the quality #healthcare they deserve. I\u2019ve cosponsored #MedicareForAll since my first term in Congress, and I\u2019m pleased to see support is continuing to grow. \nwashingtonpost.com/news/powerpost\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1123007051834085376",
              text: "Nearly 30 MILLION Americans are uninsured. Another 40 million cannot afford the costs of their co-pays or deductibles. We need #MedicareForAll and we need it now! #HealthCareIsARight\nnytimes.com/2019/04/29/opi\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1115304942347128832",
              text: "We pay more for #HealthCare than other developed nations & have lower life expectancy, higher infant mortality, & more preventable deaths. Keeping people healthy should be our #1 priority. #MedicareForAll is bold & sensible. Keeping he status quo isn\u2019t.\nusatoday.com/story/opinion/\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1129140248611565569",
              text: "Millions of Americans are struggling to keep up with the rapidly rising cost of prescription drugs. This is absolutely unacceptable. Tonight @HouseDemocrats will pass legislation to lower drugs prices & protect people with pre-existing conditions. #ProtectOurCare https://t.co/6wNpT96lwu",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1113093479054430208",
              text: "The Trump Admin has been fighting to strip Americans of their #HealthCare coverage for years. @HouseDemocrats are committed to working #ForThePeople by fighting to lower costs & ensure all Americans have access to quality affordable health care. #ProtectOurCare #ACA",
            },
          ],
          question:
            "What steps will you take to make healthcare accessible to all Americans, regardless of income?",
        },
        {
          answer:
            "I am committed to fighting for the people of Tennessee and ensuring that they have access to quality, affordable healthcare. I am proud to have supported the 21st Century Cures Act, which provides funding to fight the opioid crisis, and I am a strong supporter of the Affordable Care Act, which protects people with pre-existing conditions and helps keep healthcare costs down. I am also a strong advocate for Medicare for All, which would ensure that all Americans have access to the healthcare they need and deserve. Finally, I am a supporter of HR 40, which would create a commission to explore the issue of reparations and how they could be used to invest in healthcare, education, and job training. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1129140248611565569",
              text: "Millions of Americans are struggling to keep up with the rapidly rising cost of prescription drugs. This is absolutely unacceptable. Tonight @HouseDemocrats will pass legislation to lower drugs prices & protect people with pre-existing conditions. #ProtectOurCare https://t.co/6wNpT96lwu",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/555806001321246720",
              text: "#ACAworks,sign up@ http://healthcare.gov @nytimes:Signs of aDecline in Financial Distress Connected to Medical Bills http://nyti.ms/1sDtYLK",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1124363553052463106",
              text: "It's time to confront soaring prescription #DrugPrices. Our new bill would empower #Medicare to negotiate fair drug prices on behalf of patients. It's time to work #ForThePeople and #EndRxMonopolyPrices. pic.twitter.com/HsM2shPfJ7",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1177327134135717890",
              text: "Increases in deductibles and premiums are making #insurance unaffordable for working Americans. Passing #MedicareForAll is the best way to ensure all Americans receive the #Healthcare they need & deserve.\nnytimes.com/2019/09/25/hea\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1062454374792945664",
              text: "#Democrats have a concrete plan to save working families money by fighting ridiculous increases in prescription #DrugPrices. Read our plan #ForThePeople: bit.ly/2rL5enk",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1232747912058281984",
              text: "In the last 5 years, 1 in 8 Americans have had a loved one die because they couldn\u2019t afford necessary treatment. \n\nNearly 58 million Americans have been unable to pay for a prescription at least once in the last 12 months.\n\nWe need to #LowerDrugCosts now! #ForThePeople pic.twitter.com/9XSJylXVt5",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/652143803743141888",
              text: "Americans need #jobs, #healthcare&affordable #education\u2014so why is GOP laser-focused on #PlannedParenthood lies? http://bit.ly/1L8QWxG",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1139662417682735104",
              text: "Some favor cash reparations. Others, like Harvard Law Prof. Charles Ogletree, say #Reparations could be in the form of investments in healthcare, education, & job training. A commission established by #HR40 would foster debate & bring this issue the attention it deserves.",
            },
          ],
          question:
            "How do you plan to address the issue of skyrocketing healthcare costs and medical debt?",
        },
        {
          answer:
            "Absolutely. We need to expand access to healthcare, not restrict it. We need to make sure that everyone has access to quality healthcare, regardless of their income or pre-existing conditions. We need to make sure that healthcare is affordable and that people are not forced to forgo lifesaving treatments due to costs. We need to make sure that we are investing in medical research to find cures for diseases. We need to make sure that we are providing adequate funding for programs like Medicare and Medicaid. We need to make sure that we are protecting women's health and providing them with the resources they need in the workplace. We need to make sure that we are fighting the opioid crisis. We need to make sure that we are taking action on climate change and protecting our environment. We need to make sure that we are passing reasonable gun laws. We need to make sure that we are creating a fair tax system that works for the middle class. We need to make sure that we are clearing up the cesspool of unethical and untransparent practices in Washington. These are just some of the reforms that I believe are necessary to improve our healthcare system. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1056667818182262784",
              text: "Medicare,Medicaid,social,security,healthcare,pre-existing coverage,minimum wage,infrastructure (jobs)bill,clearing up the cesspool(ethics/transparency)climate change!environmental protections,clean air,reasonable gun laws,tax fairness for the middle class not tax scam for the 1%,",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1115304942347128832",
              text: "We pay more for #HealthCare than other developed nations & have lower life expectancy, higher infant mortality, & more preventable deaths. Keeping people healthy should be our #1 priority. #MedicareForAll is bold & sensible. Keeping he status quo isn\u2019t.\nusatoday.com/story/opinion/\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/816671225854525440",
              text: "I'm on it and will fight repeal of #ACA and cuts to Medicaid. We need to expand health care, not restrict it! https://twitter.com/morgandrose/status/816670149562408962",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1133789182541930500",
              text: "Our nurses spend the most time caring for our sick and elderly and see the faults in our #HealthCare system first hand. Every day they see Americans forgo lifesaving treatments due to costs. That\u2019s why so many support #MedicareForAll. #ProtectOurCare\nnytimes.com/2019/05/27/opi\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/893856080710836224",
              text: "Thanks to Dr. Art Sutherland & Dr. Roger LaBonte for joining me at my #townhall to talk #healthcare. We all agree we need #MedicareForAll! pic.twitter.com/WJeBJzkNA0",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/619593310550818816",
              text: "#21stCenturyCures Act is important step to jumpstart #medicalresearch 4 cures. Compromise=improvement over status quo http://1.usa.gov/1HiScwk",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1139662417682735104",
              text: "Some favor cash reparations. Others, like Harvard Law Prof. Charles Ogletree, say #Reparations could be in the form of investments in healthcare, education, & job training. A commission established by #HR40 would foster debate & bring this issue the attention it deserves.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1177327134135717890",
              text: "Increases in deductibles and premiums are making #insurance unaffordable for working Americans. Passing #MedicareForAll is the best way to ensure all Americans receive the #Healthcare they need & deserve.\nnytimes.com/2019/09/25/hea\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1023970954593140736",
              text: "As we mark the 53rd anniversary of #Medicare& #Medicaid--which have helped millions of Americans access quality #healthcare--it's more important than ever to push for #MedicareForAll &stand against #Trump,Speaker Paul Ryan&others who would cut funding to these lifesaving programs pic.twitter.com/xtizuD6two",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/832339596704890881",
              text: "Voted no on bill threatening @PPFA $. #Congress should improve #healthcare system instead of defunding #PlannedParenthood & repealing #ACA. pic.twitter.com/3mzKs7AyXy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1120856655816798209",
              text: "Republicans in Congress have spent the last decade trying to take #HealthCare away from people. Next week, @RulesDemocrats will hold the first hearing on #MedicareForAll. Every American should have access to quality healthcare. #HealthcareIsARight\n https://t.co/kUzifXFt1r",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/819644116942356480",
              text: "Moneys to fight opioid crisis in recently passed 21st century cures act which I sponsored & with insurance through #ACA which I'm 4 keeping https://twitter.com/e_d_dillard/status/819639409335214082",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1170020667607134208",
              text: "This is a major moment in the fight to provide every American with the quality #healthcare they deserve. I\u2019ve cosponsored #MedicareForAll since my first term in Congress, and I\u2019m pleased to see support is continuing to grow. \nwashingtonpost.com/news/powerpost\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1123007051834085376",
              text: "Nearly 30 MILLION Americans are uninsured. Another 40 million cannot afford the costs of their co-pays or deductibles. We need #MedicareForAll and we need it now! #HealthCareIsARight\nnytimes.com/2019/04/29/opi\u2026",
            },
          ],
          question:
            "Are there specific healthcare reforms you believe are necessary to improve the system?",
        },
      ],
      summary:
        "Steve Cohen strongly emphasizes the importance of accessible healthcare for everyone. He supports #MedicareForAll and is Vice-Chair of the Medicare for All Caucus. His goal is to create a universal system where every American citizen has access to quality, affordable healthcare services. \n\nHe plans to protect and expand Medicare and Medicaid programs and work on reducing the cost of prescription drugs. He fervently stands for the protection of people with pre-existing conditions by making sure they aren't denied healthcare due to income limitations. \n\nHe has supported the Affordable Care Act and the 21st Century Cures Act to combat the opioid crisis and restrain rising healthcare costs. He also advocates for HR 40 to establish a commission to consider how reparations could potentially fund sectors like healthcare, education, and job training.\n\nLastly, Cohen calls for various improvements and reforms in healthcare. These include protecting women's health resources, fighting the opioid crisis, taking action on climate change, passing fair gun laws, creating a balanced tax system, and clearing untransparent practices within the political sphere. Through these reforms, he aims to ensure healthcare is affordable and no one forgoes necessary treatments due to high costs.",
    },
    Immigration: {
      questions: [
        {
          answer:
            "Comprehensive immigration reform is a priority for me and my colleagues in Congress. We must ensure that immigrants are treated with dignity and respect, and that they have a pathway to citizenship. We must also ensure that our border is secure and that we have an orderly and humane system to process asylum claims. I am committed to working with my colleagues to pass legislation that will provide a pathway to citizenship for Dreamers and other immigrants, and to ensure that our border is secure. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1156694615073984517",
              text: "I am on my way to El Paso, Texas, to meet up with 24 of my Congressional colleagues to look at the way the Trump Administration is implementing border enforcement and the treatment of immigrants on our border. #DemsAtTheBorder bit.ly/2STqmVu",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1150433675999371265",
              text: "Texas Latina Emerges as House\u2019s Voice of Passion and Reason on the Border nyti.ms/2Ler1Qg. Very proud of my colleague @RepEscobar who is a leader on #immigration and our #CivilRights subcommittee.Will travel to #ElPaso Aug1 to tour border with her and other reps.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1101248680814919680",
              text: "Polls show that people do not want to pay billions of dollars for a vanity project when illegal immigration is at historic lows and families fleeing violence need an orderly and humane system to process asylum claims, not a concrete wall. #NoWall #FakeEmergency https://t.co/mC6l3rulCo",
            },
          ],
          question:
            "How will you approach comprehensive immigration reform, including pathways to citizenship?",
        },
        {
          answer:
            "I support measures that prioritize the safety and security of our borders while also providing an orderly and humane system to process asylum claims. I am on my way to El Paso, Texas, to meet up with 24 of my Congressional colleagues to look at the way the Trump Administration is implementing border enforcement and the treatment of immigrants on our border. I have also introduced legislation to cut short the School-to-Prison Pipeline, improve healing for victims, and save our country money. I also support President Obama's efforts to chart a new course with Cuba and the removal from the terrorism list is a step in the right direction. I voted to end the Trump Shutdown with two appropriations bills funding Transportation, Housing and Agriculture. I also support the BEST Practices Act to modernize military training and save animals. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1101248680814919680",
              text: "Polls show that people do not want to pay billions of dollars for a vanity project when illegal immigration is at historic lows and families fleeing violence need an orderly and humane system to process asylum claims, not a concrete wall. #NoWall #FakeEmergency https://t.co/mC6l3rulCo",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1156694615073984517",
              text: "I am on my way to El Paso, Texas, to meet up with 24 of my Congressional colleagues to look at the way the Trump Administration is implementing border enforcement and the treatment of immigrants on our border. #DemsAtTheBorder bit.ly/2STqmVu",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/570348112830541824",
              text: "I introduced legislation to cut short the #SchoolToPrisonPipeline,improve healing for victims&save our country money http://1.usa.gov/1zdoIet",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/605366969022443520",
              text: "I support Pres. Obama's efforts to chart new course w #Cuba&removal from terrorism list is a step in right direction http://1.usa.gov/1JZMGmS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1150433675999371265",
              text: "Texas Latina Emerges as House\u2019s Voice of Passion and Reason on the Border nyti.ms/2Ler1Qg. Very proud of my colleague @RepEscobar who is a leader on #immigration and our #CivilRights subcommittee.Will travel to #ElPaso Aug1 to tour border with her and other reps.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1083505788142923777",
              text: "I voted today to end the #TrumpShutdown with two appropriations bills funding Transportation, Housing and Agriculture. It\u2019s time to put federal employees back to work and for American citizens to receive the services they pay for. \nbit.ly/2RFzJKg",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/571025374664114178",
              text: "I support #BESTPracticesAct 2modernize military training&save animals @RepHankJohnson @RepFitzpatrick @RonWyden @PCRM pic.twitter.com/2q2A7R2qrC",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1270089264949329920",
              text: "The bill includes provisions from my National Statistics on Deadly Force Transparency Act, Police CAMERA Act, & Police Training and Independent Review Act. I will continue fighting to ensure our #justice system is fair & responsive to the needs of all Americans. #BlackLivesMatter",
            },
          ],
          question:
            "What measures do you support for border security and managing immigration flows?",
        },
        {
          answer:
            "Absolutely. I have introduced legislation to cut short the School to Prison Pipeline, improve healing for victims, and save our country money. I have also started discussions to find the best way for the families of victims to speak directly to Congress and called on my colleagues to urgently move my legislation and uphold our responsibility under the 14th Amendment to ensure due process and equal protections for all. I have also introduced legislation to ensure every state allows citizens to vote early and without burdensome waits. Finally, I am a strong advocate for the Dreamers and have introduced legislation to give them a path to citizenship. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1101248680814919680",
              text: "Polls show that people do not want to pay billions of dollars for a vanity project when illegal immigration is at historic lows and families fleeing violence need an orderly and humane system to process asylum claims, not a concrete wall. #NoWall #FakeEmergency https://t.co/mC6l3rulCo",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1156694615073984517",
              text: "I am on my way to El Paso, Texas, to meet up with 24 of my Congressional colleagues to look at the way the Trump Administration is implementing border enforcement and the treatment of immigrants on our border. #DemsAtTheBorder bit.ly/2STqmVu",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/570348112830541824",
              text: "I introduced legislation to cut short the #SchoolToPrisonPipeline,improve healing for victims&save our country money http://1.usa.gov/1zdoIet",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1056667818182262784",
              text: "Medicare,Medicaid,social,security,healthcare,pre-existing coverage,minimum wage,infrastructure (jobs)bill,clearing up the cesspool(ethics/transparency)climate change!environmental protections,clean air,reasonable gun laws,tax fairness for the middle class not tax scam for the 1%,",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1274057678277480448",
              text: "For many #Dreamers, this is the only home they\u2019ve ever known. They are part of the fabric of America & deserve to be treated with dignity. Yesterday\u2019s #SCOTUS decision is a victory. Now the Senate needs to pass #HR6, which gives #DACA recipients a path to citizenship. #HomeIsHere pic.twitter.com/bxAmmrQEjH",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/940704987214163968",
              text: "Today, along with @SenGillibrand @marcorubio & @RepDeSaulnier, I introduced HR4622 the Stop Underrides Act .This is the type of bill that doesnt have deep-pocket support, its just the right thing to do.Proud to support this effort to save lives. Read more: https://cohen.house.gov/media-center/press-releases/congressman-cohen-joins-senators-gillibrand-and-rubio-and-congressman",
            },
          ],
          question:
            "Are there policies to address the status of undocumented immigrants that you advocate?",
        },
      ],
      summary:
        "Steve Cohen is committed to comprehensive immigration reform and prioritizes the safety, dignity, and respect of immigrants. He supports establishing an orderly and humane system to process asylum claims and seeks to ensure a secure border. He supports measures such as early voting and eliminating burdensome waits to encourage meaningful participation in the democratic process among all, including immigrants. He has advocated and has even introduced legislation for pathways to citizenship for Dreamers and other immigrants. Cohen is also committed to addressing the status of undocumented immigrants, including cutting short the School-to-Prison Pipeline and improving healing for victims, and believes in ensuring due process and equal protections for all.",
    },
    "Inflation and Prices": {
      questions: [
        {
          answer:
            "I am proud to support the #RaiseTheWage Act, which would put more money in the hands of working people and help prevent a recession. This Act would increase wages for nearly 34M workers, lift 1.3M people out of poverty, and provide many Americans with economic stability for the first time in their lives. Additionally, I have introduced legislation to protect consumers from unfair taxes on digital goods like MP3s. This will help ensure that everyday Americans are not paying more than they should for the goods they need. #ForThePeople #ProtectOurCare #ConsumerRights #AirTravel #Economy #SocialSecurity #RaiseTheWage #TrumpBudget #Veterans #Jobs #TaxFairness #FairFees #DrugPrices #ProtectOurCare. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163876175200051200",
              text: "We should focus on policies that stimulate the economy. An infrastructure bill would help put people to work & bring goods to market. The #RaiseTheWage Act would put more money in the hands of working people. These policies could actually help prevent a recession. #ForThePeople https://t.co/2qdpwrnBNk",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/611253493161160706",
              text: "Judiciary Cmte approved my bipartisan bill to protect consumers from unfair taxes on digital goods like MP3s. More: http://1.usa.gov/1G4fkOL",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/934941263350116352",
              text: "Bring it back to life? In Trumpease that means we will nullify another Obama program, cater to Wall Street and banks everywhere, and consumers lose https://twitter.com/realdonaldtrump/status/934539256940417024",
            },
          ],
          question:
            "What measures do you propose to mitigate the impact of inflation on everyday Americans?",
        },
        {
          answer:
            "I support the American Housing and Economic Mobility Act with @SenWarren and @RepRichmond, which will help to lower housing costs for renters and buyers. I also introduced the Supermarket Tax Credit for Underserved Areas Act, which would encourage grocery stores to open locations in food deserts and ensure the availability of fresh food. Additionally, I support legislation to lower drug prices and protect people with pre-existing conditions. I also introduced the #RaiseTheWage Act, which would put more money in the hands of working people, and the #FAMILYAct, which would set a national paid leave policy. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1106634372831162368",
              text: "The housing crisis is serious in #Memphis. 1 in 4 renters pays 50% of their income or more in rent. That\u2019s why I'm pleased to support the American Housing and Economic Mobility Act with @SenWarren and @RepRichmond. This bill will help to lower housing costs for renters & buyers.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1056667818182262784",
              text: "Medicare,Medicaid,social,security,healthcare,pre-existing coverage,minimum wage,infrastructure (jobs)bill,clearing up the cesspool(ethics/transparency)climate change!environmental protections,clean air,reasonable gun laws,tax fairness for the middle class not tax scam for the 1%,",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/949389913346199553",
              text: "Kroger's decision to close 2 stores in Memphis will harm low-income families the most--people who already have fewer healthy food options. I introduced HR2289 the Supermarket Tax Credit for Underserved Areas Act b/c every family deserves fresh,healthy food https://cohen.house.gov/media-center/press-releases/cohen-introduces-bill-reduce-food-deserts-and-ensure-availability-fresh",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1062454374792945664",
              text: "#Democrats have a concrete plan to save working families money by fighting ridiculous increases in prescription #DrugPrices. Read our plan #ForThePeople: bit.ly/2rL5enk",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/859761742204870656",
              text: "Intro'd bill to incentivize building supermarkets in #fooddeserts &ensure the availability of fresh food. Read more: http://bit.ly/2pwqDR4",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/652143803743141888",
              text: "Americans need #jobs, #healthcare&affordable #education\u2014so why is GOP laser-focused on #PlannedParenthood lies? http://bit.ly/1L8QWxG",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1177327134135717890",
              text: "Increases in deductibles and premiums are making #insurance unaffordable for working Americans. Passing #MedicareForAll is the best way to ensure all Americans receive the #Healthcare they need & deserve.\nnytimes.com/2019/09/25/hea\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1248287614622449670",
              text: "On Tuesday, I joined a letter asking Congressional Leadership to mandate that lenders prioritize Paycheck Protection Program loans for small businesses owned by minorities, women, veterans, and those in underserved markets in the next #COVID stimulus bill. cohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1095401892140802048",
              text: "Life can be hectic & unpredictable, which is why all working people need access to #PaidLeave. I\u2019m proud to be an original cosponsor of the #FAMILYAct, which would set a national paid leave policy, shift #caregiving norms, and make workplaces more equitable & family friendly.",
            },
          ],
          question:
            "Are there specific policies you support to stabilize prices and ensure affordability?",
        },
      ],
      summary:
        "Steve Cohen proposes several measures to combat the impact of inflation, aiming to increase economic stability for Americans. He supports the Raise the Wage Act that would raise wages for nearly 34 million workers and aid in lifting 1.3 million people from poverty. Cohen has also initiated legislation that protects consumers from unfair digital goods taxes.\n\nTo stabilize prices and ensure affordability, Cohen supports policies such as the American Housing and Economic Mobility Act and the Supermarket Tax Credit for Underserved Areas Act. These are intended to lower housing costs and encourage the provision of fresh food in food deserts. He supports lowering drug prices and protecting people with pre-existing conditions along with establishing a national paid leave policy via the Family Act.",
    },
    "Jobs & the Economy": {
      questions: [
        {
          answer:
            "I am committed to creating policies that will stimulate job growth and reduce unemployment. I have introduced the #RaiseTheWage Act, which will increase wages for nearly 34M workers, lift 1.3M people out of poverty, and provide many Americans with economic stability for the first time in their lives. I have also introduced the Putting Our Veterans Back to Work Act, which will help reduce veteran unemployment by providing training for good-paying jobs. Additionally, I am advocating for an infrastructure bill that will help put people to work and bring goods to market. Finally, I am pushing for enhanced unemployment insurance, strengthened food security initiatives, protections for health care and other frontline workers, and increased federal funds for Medicaid. These policies will help create jobs and reduce unemployment. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163876175200051200",
              text: "We should focus on policies that stimulate the economy. An infrastructure bill would help put people to work & bring goods to market. The #RaiseTheWage Act would put more money in the hands of working people. These policies could actually help prevent a recession. #ForThePeople https://t.co/2qdpwrnBNk",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1238197167053029384",
              text: "-Enhanced #UnemploymentInsurance\n\n-Strengthened food security initiatives, including #SNAP, student meals, senior\u2019s nutrition & food banks\n\n-Protections for health care & other frontline workers\n\n-Increased federal funds for #Medicaid (2/3)",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/555485412710703108",
              text: "I just introduced a bill to help our #veterans gain the skills they need to find good-paying #jobs--read more: http://1.usa.gov/14B9bgm",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1151563306618036224",
              text: "The #RaiseTheWage Act will increase wages for nearly 34M workers, lift 1.3M people out of poverty, & provide many Americans with economic stability for the 1st time in their lives. This is good for workers & the economy b/c people will be able to spend more at local businesses.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1187734550056706048",
              text: "Veterans put their lives on the line to secure our freedom & safety. We must do all that we can to repay their sacrifices. I am proud to introduce the Putting Our Veterans Back to Work Act, which will help reduce #veteran unemployment. #Vets #Memphis\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/827572191663370240",
              text: "Intro'd bill today to put our #veterans back to work by providing training for good-paying  #jobs. Read more here: http://bit.ly/2k4jPVz",
            },
          ],
          question:
            "What strategies will you employ to stimulate job growth and reduce unemployment?",
        },
        {
          answer:
            "We must prioritize policies that stimulate the economy and provide relief to small businesses. This includes passing an infrastructure bill to put people to work and bring goods to market, passing the Raise the Wage Act to put more money in the hands of working people, and providing targeted relief to independent movie theaters. We must also ensure that minority-owned businesses, women-owned businesses, veterans, and those in underserved markets have access to Paycheck Protection Program loans through community-based lenders. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1248287615381618688",
              text: "Small businesses are the backbone of the economy. Without access to these important loans, many #SmallBusinesses will be forced to close their doors permanently. #Women and #BlackOwnedBusinesses need access to PPP loans through their community-based lenders. #Memphis #CARESAct",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1309546963218595840",
              text: "We must DO MORE to support #SmallBusinesses, including the restaurants and music venues that are the soul of #Memphis. I wrote to House leadership urging it to include the #SaveOurStages, #RESTAURANTS & #RESTART Acts in our next relief package. #SmallBiz \n\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1308461326356840450",
              text: "#PPP loans were woefully administered to big businesses & not to those most in need. I\u2019ve repeatedly pushed for Congressional aid to prioritize minority & women-owned businesses, most of which were left out. Congress MUST recommit to these small businesses this #SmallBusinessWeek pic.twitter.com/3bB3KWsiEL",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1253456410555248640",
              text: "Today, I voted for the latest #CoronavirusRelief bill, which provides $370 billion for loans and grants to #SmallBusinesses & ensures those funds go to true small businesses, not major chains. It also includes $75b for hospitals & health centers & $25b for #Coronavirus testing. https://t.co/uLNJ6Cc8Sy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1248287614622449670",
              text: "On Tuesday, I joined a letter asking Congressional Leadership to mandate that lenders prioritize Paycheck Protection Program loans for small businesses owned by minorities, women, veterans, and those in underserved markets in the next #COVID stimulus bill. cohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/552903860629819394",
              text: "Why incentivize corporations to dodge taxes? @HouseDemocrats have a plan to use that revenue on roads&bridges instead http://goo.gl/x2jFLE",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1253374585086631937",
              text: "When distributing #PPP loans, the big banks prioritized existing customers (blue-blood borrowers) & shut out many women, minorities, etc. Today\u2019s bill directs $60 billion in PPP loans to credit unions & community lenders to ensure all #SmallBusinesses have access to these funds. https://t.co/ltYK2FVqEE",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1291097563068850176",
              text: "Today I wrote to #HouseLeadership urging them to prioritize minority-owned businesses in the next relief bill. The #CARESAct disproportionately left out communities of color & provided too much money to those that didn\u2019t need it. #SmallBusiness #Memphis cohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1321159462439313415",
              text: "If Congress fails to act, I fear our country\u2019s beloved #cinemas may be at risk of permanent closure. Yesterday I wrote to House leadership in support of targeted relief for independent movie theaters, a class of #SmallBusinesses not currently covered by the updated #HeroesAct.",
            },
          ],
          question:
            "How can the government support small businesses and entrepreneurs to boost the economy?",
        },
        {
          answer:
            "Absolutely. I'm working to pass the #RaiseTheWage Act, which would increase wages for nearly 34M workers, lift 1.3M people out of poverty, and provide many Americans with economic stability for the first time in their lives. This is good for workers and the economy because people will be able to spend more at local businesses. We should also focus on policies that stimulate the economy, such as an infrastructure bill that would help put people to work and bring goods to market. We need to repeal the Trump tax giveaway for the wealthiest of the wealthy, the top 1%, and incentivize corporations to pay their fair share of taxes. #ForThePeople ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/687354168915431425",
              text: "Inspired by vision outlined by @POTUS in last night's #SOTU. We must build an #economy that works for everyone, not just the wealthy few!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1162763271860097027",
              text: "CEO compensation has grown 940% since 1978. Over the same period worker compensation has only grown 12%. We need an economy that actually works #ForThePeople. We need to pass the #RaiseTheWage Act and repeal the Trump tax giveaway for the wealthiest of the wealthy, the top 1%.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1151563306618036224",
              text: "The #RaiseTheWage Act will increase wages for nearly 34M workers, lift 1.3M people out of poverty, & provide many Americans with economic stability for the 1st time in their lives. This is good for workers & the economy b/c people will be able to spend more at local businesses.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1151563305644957698",
              text: "1 in 4 Americans don\u2019t plan to retire. Trump claims the economy is doing great, but working people aren\u2019t feeling the benefits. Corporate profits are rising, but wage growth is stagnant. We need policies that work #ForThePeople, not major corporations. \napnews.com/e38b971fb04942\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1225229021480398849",
              text: "Income inequality remains at historic highs, and despite promising to fight for American workers, Trump continues to advocate policies that primarily benefit the top 1%. Only 6% of the cuts in the #GOPTaxScam benefited working families. #CultureOfCorruption",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163876175200051200",
              text: "We should focus on policies that stimulate the economy. An infrastructure bill would help put people to work & bring goods to market. The #RaiseTheWage Act would put more money in the hands of working people. These policies could actually help prevent a recession. #ForThePeople https://t.co/2qdpwrnBNk",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/640954502846259200",
              text: "Happy #LaborDay--lets take a moment to reflect on the workers that are the backbone of our #economy. I'm working to #raisethewage for them.",
            },
          ],
          question:
            "Do you have a plan to address income inequality and promote wage growth?",
        },
      ],
      summary:
        'Steve Cohen is committed to improving jobs and the economy through a variety of strategies:\n\n1. Wage Increase: He supports the #RaiseTheWage Act, which aims to increase wages for nearly 34 million workers, and lift approximately 1.3 million people out of poverty.\n\n2. Veteran Support: Cohen has introduced the "Putting Our Veterans Back to Work Act", designed to reduce veteran unemployment by providing training for well-paying jobs.\n\n3. Infrastructure Development: He advocates for passing an infrastructure bill which would stimulate job creation and bring goods to market.\n\n4. Unemployment Protection: He is pushing for enhanced unemployment insurance to protect unemployed citizens.\n\n5. Support for Small Businesses: In addition to these, Cohen is focused on providing relief to small businesses, especially those owned by minorities, women, veterans, and those in underserved markets, helping them to access Paycheck Protection Program loans through community-based lenders.\n\n6. Tax Changes: Lastly, he aims to repeal what he refers to as the Trump tax giveaway for the wealthiest 1% and intends for corporations to pay their fair share of taxes.\n\nOverall, his approach focuses on income enhancement, special focus groups, fair tax measures, and economy stimulation through infrastructure development.',
    },
    "National Security": {
      questions: [
        {
          answer:
            "I am committed to ensuring the safety and security of the nation while respecting individual privacy. To that end, I have introduced several bills to ensure that our justice system is fair and responsive to the needs of all Americans. I have introduced the National Statistics on Deadly Force Transparency Act, the Police CAMERA Act, and the Police Training and Independent Review Act to help prevent the use of excessive force. I have also introduced the Open Book on Equal Access to Justice Act to ensure transparency for litigants suing or defending against the federal government. Additionally, I have introduced the Safer Streets Act to provide resources to address violent crime in communities where the rate is 2x, 3x, and 4x above the national average. I am also proud to support Rep. Ellison's Online Privacy Act to stand up for consumer protection. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1270089264949329920",
              text: "The bill includes provisions from my National Statistics on Deadly Force Transparency Act, Police CAMERA Act, & Police Training and Independent Review Act. I will continue fighting to ensure our #justice system is fair & responsive to the needs of all Americans. #BlackLivesMatter",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/846761714448240642",
              text: "I stand w/ Americans & the right to #privacy. Corporations shouldn't be selling personal info. I'm voting NO on S.J.Res34. #Broadbandprivacy https://t.co/h6aQTtIvld",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1088803560811188225",
              text: "I have introduced the Open Book on Equal Access to #Justice Act to ensure transparency for litigants suing or defending against the federal government. W/out adequate reporting, citizens' rights cannot be fully protected & govt risks failing its people. bit.ly/2HwDIVI",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1163975110962876416",
              text: "Accurate data helps us to honestly assess & address the problems in our justice system. A loophole in federal law prevents the collection of data on uses of excessive force. I introduced The National Statistics on Deadly Force Transparency Act, H.R. 119, to fix that. #Justice",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/951501108668747776",
              text: "Every American deserves to feel safe in their community. Today I was proud to introduce the Safer Streets Act https://cohen.house.gov/media-center/press-releases/congressman-cohen-introduces-safer-streets-act, which would provide resources to address violent crime in communities where the rate is 2x, 3x & 4x above the nat'l average",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/979044922841018368",
              text: "#NetNeutrality isn't the only Obama-era #FCC rule #GOP attacked: they also scrapped #Internetprivacy rules, giving us less control over what groups like #CambridgeAnalytics do w/our data.\n\nProud to support Rep.@keithellison's Online Privacy Act&to stand up for #consumerprotection pic.twitter.com/7y1Atw6f94",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/979043660862980097",
              text: "#NetNeutrality isn't the only Obama-era #FCC rule #GOP attacked: they also scrapped #Internetprivacy rules, giving us less control over what groups like #CambridgeAnalytics do w/our data.\n\nProud to support Rep.@keithellison's Online Privacy Act&to stand up for #consumerprotection",
            },
          ],
          question:
            "How will you ensure the safety and security of the nation while respecting individual privacy?",
        },
        {
          answer:
            "I believe that counterterrorism efforts should be focused on preventing terrorist attacks and protecting our citizens from harm. To do this, we must ensure that our intelligence and law enforcement agencies have the resources they need to identify and disrupt terrorist networks. We must also invest in robust cybersecurity measures to protect our critical infrastructure and personal data from malicious actors. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/562749914271469570",
              text: "Will support #Jordan,#Japan & #POTUS in response to #ISIS .Terror tactics need be responded to.There are codes of conduct in war and life!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/605366969022443520",
              text: "I support Pres. Obama's efforts to chart new course w #Cuba&removal from terrorism list is a step in right direction http://1.usa.gov/1JZMGmS",
            },
          ],
          question:
            "What is your approach to counterterrorism efforts and cybersecurity protection?",
        },
        {
          answer:
            "I have worked to strengthen intelligence agencies by introducing the Police Training & Independent Review Act, H.R. 125, which would provide officers with diversity training & increase accountability by incentivizing the use of independent prosecutors in cases of excessive and deadly force. I have also called on the new Congress to adequately fund the National Institutes of Health, America's other department of defense. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/831643229514772480",
              text: ".@RepDougCollins & I intro bill to increase fed agency accountability. Gov has duty to be as transparent as possible http://bit.ly/2lHZUQW",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/571025374664114178",
              text: "I support #BESTPracticesAct 2modernize military training&save animals @RepHankJohnson @RepFitzpatrick @RonWyden @PCRM pic.twitter.com/2q2A7R2qrC",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/553306903229771776",
              text: "I called on the new Congress to adequately fund the @NIH, America's other department of defense, this morning: http://bit.ly/1DCJxUV #NIH",
            },
          ],
          question:
            "Are there specific measures to strengthen intelligence agencies that you endorse?",
        },
      ],
      summary:
        "Steve Cohen, a member of the US House of Representatives from Tennessee's 9th district, is committed to ensuring national security while respecting individual privacy. He supports counterterrorism efforts and robust cybersecurity measures to protect the nation's critical infrastructure. Moreover, he believes in strengthening intelligence agencies by providing them with necessary resources and giving required training to law enforcement officers. Cohen introduced several bills intended to provide transparency, prevent excessive use of force, and address violent crime rates. These include the National Statistics on Deadly Force Transparency Act, the Police CAMERA Act, and the Police Training and Independent Review Act, among others. Cohen also supports adequate funding for the National Institutes of Health.",
    },
    Taxes: {
      questions: [
        {
          answer:
            "I believe that tax reform should prioritize working Americans and make sure that major corporations and wealthy individuals pay their fair share. We need to fund the IRS to make sure everyone pays their fair share and repeal any tax breaks for the wealthiest. We should also ensure that consumers are not taxed more for purchasing digital goods than they are for tangible goods. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1123576437477396483",
              text: "The #GOPTaxScam gave corporations a massive tax cut, but only 6% of their savings were shared with workers. We need tax reform that prioritizes working Americans and makes major corporations & wealthy individuals pay their fair share!\ntheguardian.com/us-news/2019/a\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/938052011651723265",
              text: "You would be hard-pressed to find a tax reform plan that does less for the middle class & more for Republican mega donors than this GOP #TaxScamBill https://twitter.com/repjoecrowley/status/938033254682300416",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1318601874468179969",
              text: "Our nation has two tax systems: one for working Americans and another for wealthy tax dodgers, like #Trump. We need to fund the #IRS to make sure everyone pays their fair share!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/931253547836067841",
              text: "Today I voted against the #GOPTaxBill , a tax break for the ultra-rich & corporations. Over half of benefits go to the top 1% --our savings are tip-change at Krystal, theirs will buy out a restaurant. Bill should have been 4 the #MiddleClass , instead we get a GOP con-job #resist pic.twitter.com/AhPMuIEMGS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1108852015436886016",
              text: "We live in an increasingly digital world. I\u2019m proud to sponsor the Digital Goods & Services Tax Fairness Act. It would ensure consumers are not taxed more for purchasing digital goods than they are for tangible goods. Thank you @RepRatcliff for joining me thehill.com/opinion/techno\u2026",
            },
          ],
          question:
            "What is your position on tax reform, including individual and corporate taxes?",
        },
        {
          answer:
            "We need to make sure that everyone pays their fair share of taxes. That's why I introduced the Tax Return Preparer Accountability Act, which would protect consumers from unscrupulous tax preparers who use outrageous fees to defraud honest American taxpayers. We also need to fund the IRS to make sure that corporations and wealthy individuals are paying their fair share. We must also close the loopholes that allow corporations to dodge taxes and use that revenue to invest in our roads and bridges. Finally, we must ensure that the tax system is fair and equitable for all Americans, not just the wealthy. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/552903860629819394",
              text: "Why incentivize corporations to dodge taxes? @HouseDemocrats have a plan to use that revenue on roads&bridges instead http://goo.gl/x2jFLE",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1318601874468179969",
              text: "Our nation has two tax systems: one for working Americans and another for wealthy tax dodgers, like #Trump. We need to fund the #IRS to make sure everyone pays their fair share!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/942865393156272128",
              text: 'Treasury Sec. Mnuchin called #GOPTaxScam a large tax cut for working families--a lie, or he meant "his family." Ways&Means Chair Rep. Brady couldn\'t explain why only the wealthiest will get real help. Easy: b/c we all have to pay for it. #StopGOPTaxScam https://www.washingtonpost.com/opinions/republicans-are-joining-a-festival-of-corruption/2017/12/17/afda0330-e2a8-11e7-bbd0-9dfb2e37492a_story.html?utm_term=.1e361eb9fd78',
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/831971960330518529",
              text: "Intro'd bill to protect #consumers from unscrupulous #tax prep businesses like #Memphis-based Mo\u2019 Money Taxes. Read: http://bit.ly/2lMkm3d",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1123576437477396483",
              text: "The #GOPTaxScam gave corporations a massive tax cut, but only 6% of their savings were shared with workers. We need tax reform that prioritizes working Americans and makes major corporations & wealthy individuals pay their fair share!\ntheguardian.com/us-news/2019/a\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1117829768634863616",
              text: "Today is #TaxDay. As you file your returns, remember the #GOPTaxScam allowed twice as many companies to pay ZERO taxes. They can call it a middle class tax cut, but we know who it was really for, the rich. #MondayThoughts\nfinance.yahoo.com/news/companies\u2026",
            },
          ],
          question:
            "How would you address concerns about tax evasion and tax loopholes?",
        },
        {
          answer:
            "I believe that a balanced budget should prioritize investments in programs that make our country healthier, stronger, and better prepared to meet the challenges ahead. This includes funding for Medicare, Medicaid, Social Security, healthcare, pre-existing coverage, minimum wage, infrastructure, environmental protections, and reasonable gun laws. We must also ensure that the budget is fair to the middle class and not a tax scam for the 1%. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/954495326794207238",
              text: "@leon242 @MSNBC @FrancesRivera My priorities are 10 year Chip extension,Community health centers funding,graduate medical education,and opioid crisis monies.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/659550629057208320",
              text: "Proud to support #budgetdeal to prevent default,raise budget caps,fix Medicare hike,and prevent social security cuts http://1.usa.gov/1kTgMzy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1056667818182262784",
              text: "Medicare,Medicaid,social,security,healthcare,pre-existing coverage,minimum wage,infrastructure (jobs)bill,clearing up the cesspool(ethics/transparency)climate change!environmental protections,clean air,reasonable gun laws,tax fairness for the middle class not tax scam for the 1%,",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1110990623128121347",
              text: "Congress must adopt a budget that invests in programs that make our country healthier, stronger and better prepared to meet the challenges ahead. We need a budget that will #ProtectOurCare. The cuts included in the #TrumpBudget will only make us weaker.\ncommercialappeal.com/story/opinion/\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1105638858438238208",
              text: "After giving massive tax windfalls to the wealthy and big corporations with the #GOPTaxScam, the #TrumpBudget calls on working Americans to pay the price by cutting programs they rely on. It\u2019s clear where the President\u2019s priorities lie. #CultureOfCorruption pic.twitter.com/AFvniYIfiT",
            },
          ],
          question:
            "What government spending priorities would you emphasize to ensure a balanced budget?",
        },
      ],
      summary:
        "Steve Cohen is in favor of tax reform that prioritizes the middle and working class, and aims to ensure that wealthy individuals and major corporations pay their fair share of taxes. He supports the funding of the IRS to enforce tax compliance and advocates for the repeal of tax breaks for the wealthiest. Cohen has also introduced the Tax Return Preparer Accountability Act to protect taxpayers from fraudulent tax preparers. He aims to close loopholes that enable tax evasion and seeks to utilize those revenues to fund infrastructure investment. Overall, he champions a fair and equitable tax system for all. Regarding the budget, he emphasizes investing in programs like healthcare, social security, infrastructure, and environmental protections. He advocates for a balanced budget that does not disproportionately benefit the top 1%.",
    },
  },
  "Steve Cohen (2024)": {
    Abortion: {
      questions: [
        {
          answer:
            "I believe that women should have the right to make decisions about their own bodies and lives. I strongly oppose any attempts to criminalize abortion or restrict access to it. I support Roe v. Wade and the Women's Health Protection Act, which would codify the right to bodily autonomy and ensure that everyone has access to abortion services, birth control, and other reproductive care. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1396210823140282368",
              text: "I support Roe and woman\u2019s choice. Always, forever.No matter Supreme Court or southern legislatures (Mississippi ,Texas,Tennessee. Forward with women\u2019s rights not backwards!!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1541821201835872261",
              text: "With\u00a0Roe overturned, I want everyone to have appropriate information about your existing rights and available resources for abortion services, birth control and other reproductive care. Visit\u00a0reproductiverights.gov.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1484904435910279173",
              text: "Even *with* #RoevWade, abortions are largely unavailable depending on where you live and how much you make. If Roe is overturned, half the country will be even worse off. That\u2019s why my colleagues must join me to call for a YES VOTE on the Women\u2019s Health Protection Act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1441455285965570052",
              text: "The House voted today to enact a federally protected right to abortion. We cannot depend on the Supreme Court, which just greenlit a historically restrictive ban on abortion before most even know they\u2019re pregnant. The #WHPA will protect the right to decide in every zip code.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1540366745650831363",
              text: "The radical Supreme Court overturned Roe which empowered women to make decisions about their own lives and bodies. Women will march and march and vote and vote\u2014and all who believe in the equality and self-determination of women won't stop fighting until these rights are restored.",
            },
          ],
          question:
            "Where do you stand on the legality of abortion, and what restrictions, if any, do you support?",
        },
        {
          answer:
            "Absolutely. I'm a strong supporter of the Women's Health Protection Act, which would ensure that everyone is free to make their own decisions with the people they love and respect. I'm also a proponent of the Inflation Reduction Act, which would reduce healthcare costs and lower energy costs for American families. I'm also in favor of expanding access to affordable healthcare for all. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1484904435910279173",
              text: "Even *with* #RoevWade, abortions are largely unavailable depending on where you live and how much you make. If Roe is overturned, half the country will be even worse off. That\u2019s why my colleagues must join me to call for a YES VOTE on the Women\u2019s Health Protection Act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1541821201835872261",
              text: "With\u00a0Roe overturned, I want everyone to have appropriate information about your existing rights and available resources for abortion services, birth control and other reproductive care. Visit\u00a0reproductiverights.gov.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1569788274432159745",
              text: "Today, Republican leaders introduced a national abortion ban, while Democrats celebrated the signing of the #InflationReductionAct to slash American families' prescription drug costs, reduce healthcare costs, lower energy costs and deliver historic climate action.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1490698707729268741",
              text: "Call me a radical, but I don\u2019t think those who lift themselves out of poverty should lose everything because of one medical diagnosis. We need affordable health care for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1402340435339657221",
              text: "Roe v. Wade could be overturned by the Supreme Court's conservative majority, but Congress can pass a law to ensure everyone is free to live with autonomy and make their best personal health decisions.\u00a0#Roe\u00a0isn't and never has been enough. The\u00a0#WHPA\u00a0is the legislative solution. pic.twitter.com/wA3NbaUX5V",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1469358130677043201",
              text: "SCOTUS is allowing abortion providers to proceed with a lawsuit against the Texas abortion ban, but left the law in place. Every day\u00a0#SB8\u00a0is in effect, Texans are forced to escape to other states or give birth against their will. We must pass the Women\u2019s Health Protection Act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1441455285965570052",
              text: "The House voted today to enact a federally protected right to abortion. We cannot depend on the Supreme Court, which just greenlit a historically restrictive ban on abortion before most even know they\u2019re pregnant. The #WHPA will protect the right to decide in every zip code.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1545443554717306880",
              text: "President Biden is signing an Executive Order designed to safeguard access to abortion and protect women from states trying to criminalize their health care. It\u2019s an important step, but the Senate must end the filibuster to protect the right to abortion.",
            },
          ],
          question:
            "Are there healthcare policies you support to provide alternatives to abortion?",
        },
      ],
      summary:
        "Steve Cohen firmly supports the right of women to make decisions about their own bodies, including the decision to have an abortion. He is against any attempts at criminalizing abortion or reducing accessibility to it. He backs the Roe v. Wade case decision and supports the Women's Health Protection Act which would officially recognize the right to body autonomy and ensure widespread access to abortion services, birth control, and other forms of reproductive care. Additionally, Cohen supports the Inflation Reduction Act and an expansion of affordable healthcare as means to provide alternatives to abortion.",
    },
    "Civil Liberties": {
      questions: [
        {
          answer:
            "I am committed to protecting individual civil liberties, including freedom of speech and privacy. As Chairman of the Subcommittee on the Constitution, Civil Rights, and Civil Liberties, I have introduced several pieces of legislation to ensure that no President can misuse or abuse emergency authorities, that powerful interests cannot weaponize our courts, and that our elections cannot be subverted. I have also introduced the #DoNoHarmAct with @BobbyScott, @RepRaskin & @RepMGS to protect religious freedom and ensure it is not used as a weapon to discriminate or deny services. Additionally, I have introduced the #LatonyaReevesFreedomAct with @RepYvetteClarke to affirm people with disabilities' right to receive services wherever they choose, including their homes. Finally, I have introduced a bill to hold governments liable for officers who violate constitutional rights. These are just a few of the measures I am taking to protect individual civil liberties. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1526948741844029446",
              text: "Our democratic system is built on checks and balances. As Chairman of the Subcommittee on the Constitution, Civil Rights, and Civil Liberties, I continue to examine reforms to ensure no President can misuse or abuse emergency authorities. https://t.co/05L5IQci12",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1551010089275899904",
              text: "\u201cThe beauty of the system\u201d is how he describes states determining privacy of adults re:abortion,marriage between   different races,people of same sex,sex activities beyond missionary or manual, and contraception.Not beauty but horror show.Privacy rights should be enjoyed by all!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1579482648762011648",
              text: "Powerful interests including Russian oligarchs use defamation suits to intimidate journalists and critics into silence. We can't let our courts be weaponized by the highest bidders. I introduced the Free Speech Protection Act to deter the powerful from filing bad-faith lawsuits.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1440708701191430154",
              text: "The\u00a0#ProtectingOurDemocracy\u00a0Act is the third pillar of our democracy agenda, along with\u00a0#HR1\u00a0and the\u00a0#JohnLewisVotingRightsAct. As Chair of the Constitution, Civil Rights & Civil Liberties Subcommittee, I authored much of this bill and will fight to pass this democracy safeguard.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572696337568743426",
              text: "As Chairman of the Constitution, Civil Rights and Civil Liberties Subcommittee, I'm determined to safeguard our elections. Trump's insurrectionists tried to interfere with the Electoral College, but the #ElectionReformAct passed today aims to ensure it cannot be subverted.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1366481887926091776",
              text: "Religious freedom is a core American value to protect the liberty of all to practice as they choose. It was never intended to be a weapon to discriminate or deny services.\n \nI\u2019m proud to introduce the #DoNoHarmAct with @BobbyScott, @RepRaskin & @RepMGS.\nhrc.org/press-releases\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1498393705174208518",
              text: "The Latonya Reeves Freedom Act I introduced today with @RepYvetteClarke is a major civil rights bill, which affirms people with disabilities' right to receive services wherever they choose, including their homes. Passing it will help ensure all are free to lead independent lives.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1366835036335575044",
              text: "Locals governments too often ignore police misconduct.\n\nThe bill I intro\u2019d would hold governments liable for officers who violate constitutional rights. This creates a strong incentive for local governments to establish systems that prevent misconduct & protect citizens\u2019 rights. pic.twitter.com/gC167yrpAd",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1470522118177759236",
              text: "All but one of my counter-corruption provisions were booted from the final version of the NDAA after we passed them in the House.\n\nKleptocracy is a threat to democracy and good government all over the world. Defending democracy should be part of any defense bill.",
            },
          ],
          question:
            "What safeguards will you put in place to protect individual civil liberties, including freedom of speech and privacy?",
        },
        {
          answer:
            "I believe that privacy rights should be enjoyed by all, and that is why I have introduced the National Statistics on Deadly Force Transparency Act to track the use of deadly force. This will help us to honestly address our justice system and ensure that government surveillance and data privacy are respected. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1551010089275899904",
              text: "\u201cThe beauty of the system\u201d is how he describes states determining privacy of adults re:abortion,marriage between   different races,people of same sex,sex activities beyond missionary or manual, and contraception.Not beauty but horror show.Privacy rights should be enjoyed by all!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1565001784443158533",
              text: "Pleased that @POTUS mentioned @TheJusticeDept\u2019s new database for police misconduct. Accurate data helps us honestly address our justice system. It's why I introduced the National Statistics on Deadly Force Transparency Act to track the use of deadly force whitehouse.gov/briefing-room/\u2026",
            },
          ],
          question:
            "How do you plan to address concerns about government surveillance and data privacy?",
        },
        {
          answer:
            "Yes, I am committed to protecting civil liberties in the digital age. I have introduced the Free Speech Protection Act to deter the powerful from filing bad-faith lawsuits, and the Election Reform Act to ensure that the Electoral College cannot be subverted. I have also introduced the Civil Rights Legacy Protection Act to enforce the 14th Amendment Section 3, and the Protecting Our Democracy Act as part of our democracy agenda. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1440708701191430154",
              text: "The\u00a0#ProtectingOurDemocracy\u00a0Act is the third pillar of our democracy agenda, along with\u00a0#HR1\u00a0and the\u00a0#JohnLewisVotingRightsAct. As Chair of the Constitution, Civil Rights & Civil Liberties Subcommittee, I authored much of this bill and will fight to pass this democracy safeguard.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1470522118177759236",
              text: "All but one of my counter-corruption provisions were booted from the final version of the NDAA after we passed them in the House.\n\nKleptocracy is a threat to democracy and good government all over the world. Defending democracy should be part of any defense bill.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1579482648762011648",
              text: "Powerful interests including Russian oligarchs use defamation suits to intimidate journalists and critics into silence. We can't let our courts be weaponized by the highest bidders. I introduced the Free Speech Protection Act to deter the powerful from filing bad-faith lawsuits.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572696337568743426",
              text: "As Chairman of the Constitution, Civil Rights and Civil Liberties Subcommittee, I'm determined to safeguard our elections. Trump's insurrectionists tried to interfere with the Electoral College, but the #ElectionReformAct passed today aims to ensure it cannot be subverted.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1369348091359428610",
              text: "I introduced #HR1405 to enforce the #14thAmendment #Section3. It would create a modern mechanism to remove and bar from office anyone who engaged in insurrection or rebellion against the government they took an oath to uphold.\n\u00a0\nRT if you\u2019re with me.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1362466541443424260",
              text: "Thank you @SanfordBishop, @RepAndreCarson, @EleanorNorton and @RepBarbaraLee for joining me as cosponsors of the Civil Rights Legacy Protection Act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1618730965316308992",
              text: "@mrmosesdavis @farmerFRENCHIE @BrokenSubstack Sponsored several law enforcement reforms in the George Floyd Justice and Policing Act. \nFurther I\u2019d advocated for those bills for years .",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1479214901201678339",
              text: "People who seek power by trying to overturn elections cannot be trusted to preserve, protect and defend the Constitution of the United States.\nMy bill #HR1405 to prevent those who engaged in insurrection from holding public office needs your support.\nthehill.com/policy/nationa\u2026",
            },
          ],
          question:
            "Are there initiatives to strengthen civil liberties protections in the digital age that you support?",
        },
      ],
      summary:
        "Steve Cohen is deeply committed to the protection and preservation of civil liberties. This includes safeguarding the freedom of speech, privacy, and other individual civil liberties that are integral to the American Constitution. He has introduced several legislative measures aimed at preventing the potential misuse or abuse of emergency authorities, bias in court systems, and subversion of elections. He advocates for religious freedom and faults its possible use in promoting discrimination or denial of services.\n\nIn the realm of government surveillance and data privacy, Cohen introduced the National Statistics on Deadly Force Transparency Act to monitor and track the use of deadly force, an act aimed at promoting honesty within the justice system and respecting data privacy. \n\nHe also champions civil liberties protections in the digital age. Some of his initiatives in this regard include the Free Speech Protection Act, the Election Reform Act, the Civil Rights Legacy Protection Act, and the Protecting Our Democracy Act. All these initiatives aim to safeguard democratic principles, enforce constitutional provisions, prevent bad-faith lawsuits, and protect civil liberties in the cyber sphere.",
    },
    "Civil Rights": {
      questions: [
        {
          answer:
            "I have been a long-time advocate for the LGBTQ+ community, and I am proud to have cosponsored the Equality Act, which would ensure that all Americans are treated equally under the law. I have also fought for decades for marriage equality and racial justice, and I am leading a bipartisan group of more than a dozen of my colleagues to urge the Department of Justice to reinstate critical ADA-related guidance that the Trump Administration rescinded. I will continue to fight for the rights of the LGBTQ+ community and for all Americans to be treated with respect and dignity. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1362825068301193217",
              text: "As a State Senator, I was the only senator to vote against Tennessee\u2019s gay marriage ban.\n\u00a0\nBut I never stopped fighting for the #LGBTQ+ community, and I\u2019m proud to now be one of many cosponsoring the #EqualityAct. Every American deserves to be treated equally under the law. https://t.co/ZApDysaJWL",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1354217089326862341",
              text: "Demilitarizing the police, eliminating private prisons, promoting fair housing and disavowing discrimination are important first steps by President Biden to build a more just, equitable nation.\n\nFrom here we can continue striving for transformational legislation and social change",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1493596191862501388",
              text: "The ADA guarantees equal opportunities for individuals with disabilities, which is why I'm leading a bipartisan group of more than a dozen of my colleagues urging @TheJusticeDept to reinstate critical ADA-related guidance that the Trump Administration rescinded. pic.twitter.com/dlD5otVWic",
            },
          ],
          question:
            "What actions would you take to advance LGBTQ+ rights and protections against discrimination?",
        },
        {
          answer:
            "We must take action to promote racial and gender equality in various sectors, including employment and housing. This includes demilitarizing the police, eliminating private prisons, promoting fair housing, and disavowing discrimination. We must also encourage school districts to create equity offices to develop programs and promote Universal Screening processes that improve diversity in accelerated programs. Additionally, we must pass the Housing Accountability Act to survey Section 8 tenants and hold landlords accountable for safe and livable housing. We must also expand workforce development and training programs to all the untapped talent in our region, including high schools, community colleges, and HBCUs. We must also pass the Rehabilitation Act and Americans with Disabilities Act to reduce recidivism and allow formerly incarcerated people to fulfill their potential. We must also establish universal childcare and permanently fund the CTC to Build Back Better for all. We must also pass the #CROWNAct to prohibit race-based hair discrimination. Finally, we must include critical environmental justice provisions in upcoming legislation to manage our water resources. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1354217089326862341",
              text: "Demilitarizing the police, eliminating private prisons, promoting fair housing and disavowing discrimination are important first steps by President Biden to build a more just, equitable nation.\n\nFrom here we can continue striving for transformational legislation and social change",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1415061623941783557",
              text: "Equally qualified black and marginalized students are far less likely to be placed in AP and G&T classes. My bill would encourage school districts to create equity offices to develop programs & promote Universal Screening processes that improve diversity in accelerated programs. pic.twitter.com/kKaUvSpEG2",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1631024329566609410",
              text: "The Housing Accountability Act I reintroduced today with @SenRubioPress would survey Section 8 tenants so that residents can speak up about their living conditions and @HUDgov can hold landlords accountable for safe and livable housing.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1362160793832275969",
              text: "100 years after slavery ended, Congress passed civil rights legislation to end segregation. But the legacy of slavery & Jim Crow continue to this day.\n\nWe must address the gaping inequalities & systemic racism that Black Americans endured and continue to face. #HR40 #HR40Hearing https://t.co/n0UlsAqYzw",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1648786696144580614",
              text: "There's a lot more we need to do to expand workforce development and training programs to all the untapped talent in our region\u2014our high schools, community colleges, and our HBCUs. There is huge demand for pilots & aviation professionals right now, and these students can fill it!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1633920067124486148",
              text: "The Rehabilitation Act paved the way for the Americans with Disabilities Act, which requires that people with disabilities have the same opportunities for education, employment, commercial activities, and participation in state and local government programs. (2/4)",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1426226012766015491",
              text: "To reduce recidivism and allow formerly incarcerated people to fulfill their potential, we must remove?barriers and expand opportunities. By expanding access to education at #HBCU's, the bill I intro'ed w/ @RepFrenchHill will provide opportunities to not only survive but thrive.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572685346466398212",
              text: "A person\u2019s employment, housing and education can all be denied on the basis of a past conviction. Allowing those who made a mistake and have paid their debt to society to wipe the slate clean and have a fresh start is essential to building a more just criminal legal system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1374761094615601152",
              text: "Women are saving lives and providing vital services on the frontlines of this pandemic.\n\nWomen bear an overwhelming burden of care work.\n\nWomen do the same jobs as men for cents on the dollar.\n\nWe must have equal pay. #EqualPayDay",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1448043974065852417",
              text: "While men gained jobs last month, women lost jobs and have made up 2/3 of all jobs lost during the pandemic. Women have dropped to the same rate of workforce participation as in 1988. We must establish universal childcare and permanently fund the CTC to Build Back Better for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1483106476151681025",
              text: "We must continue MLK\u2019s work to protect voting rights, eliminate poverty, end unfair policing practices, expand healthcare access, and ensure workers\u2019 right to organize. As he said in Memphis the night before he was killed, \u201cWe have an opportunity to make America a better nation.\u201d",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1443696583854411778",
              text: "Too often hair style and other grooming standards are used as a proxy for racial discrimination. It\u2019s just that simple. I was proud to vote today to advance the #CROWNAct and prohibit race-based hair discrimination. https://t.co/jvfIjrr4GX",
            },
          ],
          question:
            "How can you promote racial and gender equality in various sectors, including employment and housing?",
        },
        {
          answer:
            "I support the #FreedomToVoteAct, the #ProtectingOurDemocracyAct, and the #JohnLewisVotingRightsAct to ensure ballot access, prohibit gerrymandering, protect election integrity, end big money in politics, and renew the Voting Rights Act. I also believe that we must make sure that all eligible voters have access to the ballot box and that no one should be denied the right to vote. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1481355244235898885",
              text: "State legislatures are restricting the right to vote with simple majorities, while the Senate\u2019s antiquated rules mean we need a supermajority to protect it. The filibuster can\u2019t stand in the way of protecting voting rights.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600522145280495620",
              text: "Overwhelming majorities of Republican voters support policies that Democratic lawmakers have long prioritized. I hope my GOP colleagues will join me in passing popular, commonsense ideas that voters on both sides of the aisle agree on. pic.twitter.com/cRvYtXIIlS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1450527455060631557",
              text: "The Senate will try to open debate tomorrow on the #FreedomToVoteAct to ensure ballot access, prohibit gerrymandering, protect election integrity and end big money in politics. Manchin supports it, so if the GOP filibusters, we must urge him to end the filibuster for democracy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1379147861175242756",
              text: "The media frames voter suppression as another partisan conflict. It\u2019s actually an attack on democracy waged by those who fear losing power when the American ppl's voices are heard. A representative & responsive government requires all eligible voters have access to the ballot box",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1408448716248387586",
              text: "These brazenly restrictive laws make it illegal to offer water to voters waiting in line, curtail drop boxes, and restrict hours for Sunday 'souls to the polls' voting. They \"were enacted with the purpose of denying or abridging the right of Black Georgians to vote.\" --AG Garland",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1440708701191430154",
              text: "The\u00a0#ProtectingOurDemocracy\u00a0Act is the third pillar of our democracy agenda, along with\u00a0#HR1\u00a0and the\u00a0#JohnLewisVotingRightsAct. As Chair of the Constitution, Civil Rights & Civil Liberties Subcommittee, I authored much of this bill and will fight to pass this democracy safeguard.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1486002751293243395",
              text: "We find our democracy on the precipice not only because of the Senate\u2019s procedural anachronism, but because one party has decided to reverse its historic support for strong voting rights protection and chosen voter suppression as a political strategy. https://t.co/vyUeuBzY3k",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1487073502712803328",
              text: "In 2022, we\u2019re talking about more than Republicans enacting obstacles to voting. They are systematically closing polling locations in minority communities and changing election laws that could allow partisan actors to interfere with vote counts and even overturn results.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1408443289137823760",
              text: "Merrick Garland announced today that @TheJusticeDept will challenge Georgia\u2019s new discriminatory voting laws. This is big and important news. States can\u2019t be allowed to limit eligible voters' access to the ballot box.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1409950107777257479",
              text: "John Lewis almost died fighting for the right to vote. Now his home state of Georgia is suppressing the vote and showing the need to renew the Voting Rights Act for which he fought so hard.\n\nWe must protect this fundamental right and again defend against brazen voter suppression. https://t.co/KLlA9VflZM",
            },
          ],
          question:
            "What policies do you support to protect voting rights and access to the ballot?",
        },
      ],
      summary:
        "Steve Cohen is a strong supporter of civil rights and takes a proactive approach to safeguard them. He actively advocates for the LGBTQ+ community and has supported the Equality Act, which promotes equal rights for all Americans. He also supports same-sex marriage and racial justice. \n\nIn terms of racial and gender equality, Cohen calls for the demilitarization of the police, the elimination of private prisons, the promotion of fair housing, and challenges against discrimination. He advocates for policies fostering diversity and the end to race-based hair discrimination, through the implementation of equity offices and the passing of the CROWN Act.\n\nTo promote voting rights, he supports measures such as the Freedom to Vote Act, Protecting Our Democracy Act and John Lewis Voting Rights Act, aimed at promoting fair access to elections, preventing gerrymandering, protecting election integrity, ending the influence of big money in politics, and renewing the Voting Rights Act. \n\nOverall, Steve Cohen's position on civil rights is based on the belief in equal rights and protections for all.",
    },
    Crime: {
      questions: [
        {
          answer:
            "I believe that criminal justice reform must start with removing profit-based incentives from the system and expanding access to education at Historically Black Colleges and Universities (HBCUs). I have introduced a criminal justice reform bill to end an unfair sentencing practice that violates due process and has contributed to mass incarceration. Additionally, I have reintroduced the Fresh Start Act, which provides for the expungement of certain nonviolent offenses, allowing those who have paid their debt to society to wipe the slate clean and have a fresh start. I have also sponsored several law enforcement reforms in the George Floyd Justice and Policing Act, and I am chairing a hearing on executive clemency to grant clemency to people convicted of nonviolent drug crimes. Finally, I have introduced a bill to hold local governments liable for officers who violate constitutional rights, creating a strong incentive for local governments to establish systems that prevent misconduct and protect citizens' rights. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1426226012766015491",
              text: "To reduce recidivism and allow formerly incarcerated people to fulfill their potential, we must remove?barriers and expand opportunities. By expanding access to education at #HBCU's, the bill I intro'ed w/ @RepFrenchHill will provide opportunities to not only survive but thrive.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1508634175003402248",
              text: "Tonight the House passed my criminal justice reform bill to once and for all end an unfair sentencing practice that violates due process and has contributed to mass incarceration.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572685346466398212",
              text: "A person\u2019s employment, housing and education can all be denied on the basis of a past conviction. Allowing those who made a mistake and have paid their debt to society to wipe the slate clean and have a fresh start is essential to building a more just criminal legal system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492188867478315008",
              text: "This week I reintroduced the #FreshStartAct, which provides for the expungement of certain nonviolent offenses. Allowing those who made mistakes earlier in their lives, and already served their sentences, to wipe the slate clean is essential to creating a more just system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1618730965316308992",
              text: "@mrmosesdavis @farmerFRENCHIE @BrokenSubstack Sponsored several law enforcement reforms in the George Floyd Justice and Policing Act. \nFurther I\u2019d advocated for those bills for years .",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1603890607604174848",
              text: "Ending sentencing disparities reverses a misguided War on Drugs policy that targeted communities of color. \n\nKudos to @RepJeffries who sponsored the Equal Act to end the disparity and to Attorney General Garland for taking action when the Senate failed to move the bill we passed.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1443649209077481488",
              text: "My Republican colleagues should come into 21st century on something and join us in passing the #MOREAct. It's an important step forward to deschedule marijuana, expunge offenses and reinvest the revenue in communities disproportionately harmed by the War on Drugs.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1415061623941783557",
              text: "Equally qualified black and marginalized students are far less likely to be placed in AP and G&T classes. My bill would encourage school districts to create equity offices to develop programs & promote Universal Screening processes that improve diversity in accelerated programs. pic.twitter.com/kKaUvSpEG2",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1367289047601848322",
              text: "Tonight I rose in strong support of the George Floyd #JusticeinPolicing Act. I authored many of its provisions. It is a bold, urgent approach to tackle racial profiling & police brutality.\n \nThis is not defund the police. This is reform the police and SAVE HUMAN LIVES! https://t.co/2b5Ifi79p2",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1366835036335575044",
              text: "Locals governments too often ignore police misconduct.\n\nThe bill I intro\u2019d would hold governments liable for officers who violate constitutional rights. This creates a strong incentive for local governments to establish systems that prevent misconduct & protect citizens\u2019 rights. pic.twitter.com/gC167yrpAd",
            },
          ],
          question:
            "How would you approach criminal justice reform to reduce mass incarceration and address racial disparities?",
        },
        {
          answer:
            "I'm committed to making my community safer and promoting fairness and equity. To do this, I'm leading the push to ensure upcoming legislation to manage our water resources includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities. I'm also fighting for reform to end discrimination on our roads and to prevent gun violence. I'm encouraging school districts to create equity offices to develop programs and promote Universal Screening processes that improve diversity in accelerated programs. I'm also introducing a package of bills to increase police accountability and transform police culture. Finally, I'm requesting funds for local health care, homelessness and minority opportunities, and for Tom Lee Park to enhance tourism and recreation. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1415061623941783557",
              text: "Equally qualified black and marginalized students are far less likely to be placed in AP and G&T classes. My bill would encourage school districts to create equity offices to develop programs & promote Universal Screening processes that improve diversity in accelerated programs. pic.twitter.com/kKaUvSpEG2",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1567581487934963714",
              text: "I am working to make my community safer, but MAGA Republicans have sided with the gun lobby to block life-saving legislation at every turn. We won\u2019t back down in fighting crime and violence. #EndGunViolence",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1416423235739168772",
              text: "OP-ED: 10 priorities for Community Project Funding for FY 2022 tri-statedefender.com/op-ed-10-prior\u2026 via @TSDMemphis #memphis #Tennessee",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1366835036335575044",
              text: "Locals governments too often ignore police misconduct.\n\nThe bill I intro\u2019d would hold governments liable for officers who violate constitutional rights. This creates a strong incentive for local governments to establish systems that prevent misconduct & protect citizens\u2019 rights. pic.twitter.com/gC167yrpAd",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630585774147076099",
              text: "Accelerated learning programs like AP and GT propel students toward greater academic success, but many students are overlooked or underrepresented in these programs. Prioritizing diversity and inclusion is crucial to ensuring equal opportunity for all.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1631024329566609410",
              text: "The Housing Accountability Act I reintroduced today with @SenRubioPress would survey Section 8 tenants so that residents can speak up about their living conditions and @HUDgov can hold landlords accountable for safe and livable housing.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1346855732738727940",
              text: "Walking out the door, the Trump Admin tries to undermine civil rights enforcement w/out transparency or meaningful public comment. \u201cDisparate Impact\u201d is critical to identifying & stopping discrimination under the Civil Rights Act. Gutting it would be a blow to equity & justice.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1483106476151681025",
              text: "We must continue MLK\u2019s work to protect voting rights, eliminate poverty, end unfair policing practices, expand healthcare access, and ensure workers\u2019 right to organize. As he said in Memphis the night before he was killed, \u201cWe have an opportunity to make America a better nation.\u201d",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1414994701741068288",
              text: "All my community project requests were included in this year's spending bills! They focus on local health care, homelessness and minority opportunities and also provide funds for Tom Lee Park to enhance tourism and recreation. Proud to fight for #Memphis.\nlocalmemphis.com/article/news/c\u2026",
            },
          ],
          question:
            "What strategies will you employ to enhance community safety while promoting fairness and equity?",
        },
        {
          answer:
            "Yes, I am committed to addressing the root causes of crime. I have sponsored several law enforcement reforms in the George Floyd Justice and Policing Act, and I have advocated for those bills for years. I have also introduced the Fresh Start Act, which provides for the expungement of certain nonviolent offenses, and I am urging President Biden to enforce his executive order to eliminate private prisons. I am also leading the push to ensure upcoming legislation to manage our water resources includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1629846172394201089",
              text: "@marceld21 I to am very  concerned about crime. I\u2019ve done everything a Congressman can to try to help our city get federal aid. i\u2019ve talked to justice. I\u2019ve written Merrick Garland and I\u2019ve talked to President Biden written him too. I take a backseat to no one on pushing for anti crime $\u2019s.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1468715922789646340",
              text: "I am appalled by my Republican colleagues, who try to score cheap political points on violent crime. Real people in my community and across the country are suffering, and Democrats are advancing genuine solutions to invest in and improve public safety. https://t.co/ydgnH5VGFB",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1618730965316308992",
              text: "@mrmosesdavis @farmerFRENCHIE @BrokenSubstack Sponsored several law enforcement reforms in the George Floyd Justice and Policing Act. \nFurther I\u2019d advocated for those bills for years .",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492188867478315008",
              text: "This week I reintroduced the #FreshStartAct, which provides for the expungement of certain nonviolent offenses. Allowing those who made mistakes earlier in their lives, and already served their sentences, to wipe the slate clean is essential to creating a more just system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572685346466398212",
              text: "A person\u2019s employment, housing and education can all be denied on the basis of a past conviction. Allowing those who made a mistake and have paid their debt to society to wipe the slate clean and have a fresh start is essential to building a more just criminal legal system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
          ],
          question:
            "Are there specific initiatives for addressing the root causes of crime that you champion?",
        },
      ],
      summary:
        "Steve Cohen is a strong advocate for reformation of the criminal justice system, aiming to reduce mass incarceration and address racial disparities. He believes in removing the profit-based incentives from the system and enhancing access to education. Cohen has introduced a variety of bills to this end, such as a bill to eliminate unfair sentencing practices, the Fresh Start Act to expunge certain non-violent offenses, and the George Floyd Justice and Policing Act that includes several law enforcement reforms. He's also focused on holding local governments accountable for officer misconduct.\n\nCohen aims to enhance community safety and promote fairness and equity through a myriad of strategies. He is deeply invested in addressing environmental justice and ending discrimination. He's an advocate for gun violence prevention, encouraging the creation of equity offices in school districts, and seeking funding for local healthcare, homelessness assistance, and minority opportunities.\n\nIn terms of addressing the root causes of crime, Cohen champions initiatives like the Fresh Start Act for non-violent offenses, the elimination of private prisons, and emphasis on environmental justice provisions.",
    },
    Education: {
      questions: [
        {
          answer:
            "I am committed to improving the quality of public education and increasing access to higher education. To do this, I have introduced a bill with Rep. French Hill to expand access to education at HBCUs. Additionally, I am encouraging school districts to create equity offices to develop programs and promote Universal Screening processes that improve diversity in accelerated programs. I am also pushing for the largest educational initiative in Tennessee history, which includes 6+ billion dollars for scholarships. Finally, I am leading the push to ensure upcoming legislation to manage our water resources includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1426226012766015491",
              text: "To reduce recidivism and allow formerly incarcerated people to fulfill their potential, we must remove?barriers and expand opportunities. By expanding access to education at #HBCU's, the bill I intro'ed w/ @RepFrenchHill will provide opportunities to not only survive but thrive.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1648786696144580614",
              text: "There's a lot more we need to do to expand workforce development and training programs to all the untapped talent in our region\u2014our high schools, community colleges, and our HBCUs. There is huge demand for pilots & aviation professionals right now, and these students can fill it!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630585774147076099",
              text: "Accelerated learning programs like AP and GT propel students toward greater academic success, but many students are overlooked or underrepresented in these programs. Prioritizing diversity and inclusion is crucial to ensuring equal opportunity for all.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1387584812026900480",
              text: "I agree with @POTUS's vision that \u2018infrastructure\u2019 is more than roads and bridges. We must include help for our growing elderly population, for internet access in rural and urban areas, for modernizing our electric grid, expanding the child care system, and replacing lead pipes.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1591207775539187713",
              text: "@managerforest15 Anybody in your family get a lottery scholarship? Hope? Promise?Tech training?\nLargest educational initiative(6+ billion dollars and growing)It was my initiative and wouldn\u2019t have happened  otherwise. Embarrassment? NOT!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1416414228123209732",
              text: "@TNLottery None would have happened without 20 years of pushing as state Senator with the razor thin 21 other senators votes in 2001 and the referendum in \u201802 and the legislation in \u201803. All hurdles you wouldn\u2019t believe. And if it didn\u2019t pass then it never would have!!\n$6 billion education",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1644027501230825505",
              text: "I was the D. \n>6 billion dollars for education( mostly scholarships) to date. #Tennessee proud of that work covering most of my 24 years there!",
            },
          ],
          question:
            "How will you improve the quality of public education and increase access to higher education?",
        },
        {
          answer:
            "I'm proud to have introduced a bill with @RepAOC and @RepBonamici to protect consumers from predatory debt collection and ensure people are protected from the financial ruin of student loans should they face unexpected strains. This bill will lower costs for millions of working Americans who make less than $125k per year and need it most, without spending more on those who can afford it. I'm also joining @SenatorDurbin to close a loophole that incentivizes for-profit colleges to aggressively recruit veterans & servicemembers, and I'm hosting a webinar with a @FAFSA expert to help people learn more about the Public Service Loan Forgiveness program that @POTUS recently expanded. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1421248648663511053",
              text: "No one wants to declare bankruptcy, but the system should be fairer and treat private student loan debt like any other debt. Our bill ensures people will be protected from the financial ruin of student loans should they face unexpected strains.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1562546145745784833",
              text: "Today\u2019s targeted student debt relief will lower costs for millions of working Americans who make less than $125k per year and need it most, without spending more on those who can afford it. \n \nwashingtonpost.com/education/2022\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1364331593070968832",
              text: "I\u2019m joining @SenatorDurbin to close a loophole that incentivizes for-profit colleges to aggressively recruit veterans & servicemembers.\n \nThis will prevent unscrupulous colleges from targeting veterans w/ false promises that push them into debt & force taxpayers to foot the bill. pic.twitter.com/6AS7xei5Vd",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1580546495933476869",
              text: "If you\u2019re a public employee, nonprofit worker or public school teacher you may be eligible for Public Service Loan Forgiveness that @POTUS recently expanded.\n\u00a0\nLearn more tomorrow 12p CT/1p ET at a webinar I'm hosting with a @FAFSA expert. Webinar details in the next post...",
            },
          ],
          question:
            "What measures do you endorse to address the issue of student loan debt?",
        },
        {
          answer:
            "I prioritize policies that support teachers and improve educational outcomes by encouraging school districts to create equity offices to develop programs and promote Universal Screening processes that improve diversity in accelerated programs. This will ensure that all students, regardless of race or background, have an equal opportunity to succeed. I also prioritize policies that develop students' critical reasoning and thinking skills, as well as policies that expand workforce development and training programs to all the untapped talent in our region. Finally, I prioritize policies that ensure people have everything they need to show up for a job, such as affordable child care and paid family leave. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1415061623941783557",
              text: "Equally qualified black and marginalized students are far less likely to be placed in AP and G&T classes. My bill would encourage school districts to create equity offices to develop programs & promote Universal Screening processes that improve diversity in accelerated programs. pic.twitter.com/kKaUvSpEG2",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630585774147076099",
              text: "Accelerated learning programs like AP and GT propel students toward greater academic success, but many students are overlooked or underrepresented in these programs. Prioritizing diversity and inclusion is crucial to ensuring equal opportunity for all.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572966782545137672",
              text: "We should be developing students\u2019 critical reasoning and thinking skills so they can succeed in college and work, NOT empowering Republican politicians to dictate what kids read and learn.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1648786696144580614",
              text: "There's a lot more we need to do to expand workforce development and training programs to all the untapped talent in our region\u2014our high schools, community colleges, and our HBCUs. There is huge demand for pilots & aviation professionals right now, and these students can fill it!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1417141135185780738",
              text: "Putting Americans to work requires ensuring that people have everything they need to show up for a job.\n\nThat includes\u00a0affordable child care and paid family leave. No infrastructure plan is complete without them.",
            },
          ],
          question:
            "Are there policies to support teachers and improve educational outcomes that you prioritize?",
        },
      ],
      summary:
        "Steve Cohen is committed to improving the quality of public education and increasing access to higher education. He has introduced a bill to expand access to education at Historically Black Colleges and Universities (HBCUs) and has encouraged school districts to create equity offices to develop programs improving diversity in accelerated programs. A major focus for Cohen is working towards the largest educational initiative in Tennessee history, which includes billions of dollars for scholarships. \n\nOn the issue of student loan debt, Steve Cohen introduced a bill to protect consumers from predatory debt collection, providing relief for working Americans earning less than $125k per year. He is also part of efforts to close a loophole used by for-profit colleges to recruit veterans and service members aggressively. He provides help for people to understand the Public Service Loan Forgiveness program, which was recently expanded. \n\nFor supporting teachers and improving educational outcomes, he advocates for the creation of equity offices in schools to develop programs that enhance diversity in education. He supports policies that improve critical reasoning and thinking skills, including the expansion of workforce development and training programs. His policies also prioritize child care affordability and paid family leave, which he believes are essential for job readiness.",
    },
    "Environmental and Climate Policy": {
      questions: [
        {
          answer:
            "We must take bold action to tackle climate change and create a green energy future. This includes ending taxpayer-funded handouts to fossil fuel companies, integrating climate action into every part of the Biden administration, ensuring that every new job created contributes to climate health, and investing in renewable energy, clean transportation, and other green initiatives. We must also pass a Green New Deal and the #InflationReductionAct to spur American innovation, create jobs, lower energy costs, strengthen our independence, and strive for environmental justice. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1471887110466686977",
              text: "We must boldly tackle climate change and a green energy future. For a start, end taxpayer-funded handouts to fossil fuel companies.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1354479233242959876",
              text: "President Biden is integrating climate action into every part of his administration.\n\nThis all-of-government approach, combined with bold climate policy, is the only way we'll tackle this crisis and create new jobs, build sustainable infrastructure & deliver environmental justice",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1517605214643228672",
              text: "There is nothing political about wanting to breathe clean air and keep your community safe from rising tides and increasingly extreme weather. The future of our species and our planet depends on bold climate action. For #EarthDay, more people need to see that and act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1354564526029602816",
              text: "Climate action is not at the expense of the economy. The climate emergency is.\n \nBold climate action today will save us trillions of dollars down the line while also saving lives, creating jobs, and generating enormous economic opportunities to lead the green economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625943061250379776",
              text: "Democrats are rebuilding America by fixing our roads and bridges and bringing clean water and high-speed internet to more communities. And we are making the largest climate investments in history to generate clean, secure, American energy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1649803187442835457",
              text: "??Under the Biden Administration, we've made historic progress on climate change with investments in #RenewableEnergy, clean transportation, and more. But the fight isn't over - let's keep pushing for clean energy access & protect our environment! ????#EarthDay #ClimateAction https://t.co/AovkBWgFkY",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1402399493035790337",
              text: "We as a species have used the oceans, the air, and the earth as our dumping grounds. It has been a disaster for the environment and our health and it will continue to be for generations to come. We need to wake up, fight climate change and pass a Green New Deal with @AOC. https://t.co/8ucf3PSblL",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1377413560301617154",
              text: "We have an historic opportunity to rebuild our country\u2019s infrastructure better than ever to create millions of good-paying jobs, lead the world economy, and tackle climate change. As a senior member of @TransportDems, I look forward to shaping & shepherding the #AmericanJobsPlan.",
            },
          ],
          question:
            "How will you address climate change and promote sustainability?",
        },
        {
          answer:
            "We must end taxpayer-funded handouts to fossil fuel companies, invest in renewable energy, and phase out single-use plastics. We must also ensure that every new job created is contributing to climate health, not worsening the crisis. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1471887110466686977",
              text: "We must boldly tackle climate change and a green energy future. For a start, end taxpayer-funded handouts to fossil fuel companies.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1380539103595356166",
              text: "If you\u2019re worried about the cost of climate action, you should know the cost of inaction is far greater than any debt we could possibly accrue while saving the future of our planet, economy, and society. Bold climate action is the fiscally responsible thing to do!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1573328645808062467",
              text: "As the single largest purchaser in the world, the US government has an opportunity to do right by the environment and lead by example. That\u2019s why @RepHuffman @RepMikeQuigley @RepLowenthal and I are encouraging @USGSA to move swiftly as possible in phasing out single-use plastics. pic.twitter.com/2by8g9PyHy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1417182852463140867",
              text: "If we are going to address the climate crisis, we need to ensure that every new job created is contributing to climate health \u2014 not worsening the crisis.\n\nThe infrastructure package must include clean jobs in impacted communities.",
            },
          ],
          question: "Strategies for reducing greenhouse gas emissions?",
        },
        {
          answer:
            "We must take bold climate action to protect our environment and ensure a safe future for our species and planet. This includes investing in renewable energy, clean transportation, and advancing environmental justice. We must also end taxpayer-funded handouts to fossil fuel companies and ensure polluters' enormous profits pay for the damage to mother earth. Finally, we must pass a Green New Deal to mobilize a massive response to halt, reverse, and mitigate the climate crisis. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1354479233242959876",
              text: "President Biden is integrating climate action into every part of his administration.\n\nThis all-of-government approach, combined with bold climate policy, is the only way we'll tackle this crisis and create new jobs, build sustainable infrastructure & deliver environmental justice",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1517605214643228672",
              text: "There is nothing political about wanting to breathe clean air and keep your community safe from rising tides and increasingly extreme weather. The future of our species and our planet depends on bold climate action. For #EarthDay, more people need to see that and act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1471887110466686977",
              text: "We must boldly tackle climate change and a green energy future. For a start, end taxpayer-funded handouts to fossil fuel companies.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558198041915670530",
              text: "The #InflationReductionAct is the single largest federal investment in our climate EVER!??\n\nWhile combatting the climate crisis, we will spur American innovation, create jobs, lower energy costs, strengthen our independence and strive for environmental justice.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1432760492146237452",
              text: "The United States government continues to subsidize polluters fueling the climate crisis.\n\nWe must put a stop to subsidies for fossil fuel companies and ensure polluters' enormous profits pay for the damage to mother earth. The Build Back Better Act is the chance to get it done.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1357382230042161153",
              text: "I am proud to cosponsor the bill led by @RepBlumenauer, @RepAOC and @SenSanders to require the declaration of a national climate emergency and mobilize a massive response to halt, reverse, and mitigate the crisis. #ClimateEmergencyNOW",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1402399493035790337",
              text: "We as a species have used the oceans, the air, and the earth as our dumping grounds. It has been a disaster for the environment and our health and it will continue to be for generations to come. We need to wake up, fight climate change and pass a Green New Deal with @AOC. https://t.co/8ucf3PSblL",
            },
          ],
          question: "Policies for environmental protection and climate action?",
        },
      ],
      summary:
        "Steve Cohen is committed to addressing climate change with urgency and promoting sustainability. His strategy includes ending government funding to fossil fuel companies and integrating climate action into all areas of administration. He strives for each new job created to contribute positively to climate health. \n\nHis vision extends to sound investments in renewable energy, clean transportation, and green initiatives. He aims to reduce greenhouse gas emissions by phasing out single-use plastics and creating industries that don't worsen the climate crisis.\n\nCohen aims to protect the environment through massive investments in green energy and fostering environmental justice. He plans to implement stricter regulations on polluters, making them financially accountable for environmental damage. \n\nHe supports the Green New Deal, a proposed legislation that focuses on addressing climate change and economic inequality. His policies are geared towards an immediate and robust response in an attempt to halt, reverse, and handle the climate crisis.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      questions: [
        {
          answer:
            "The United States must take a strong stance against Russia's aggression in Ukraine. We must support Ukraine in their fight for freedom and democracy, and make it clear that any use of chemical, biological, or nuclear weapons against Ukraine will not be tolerated and will have consequences. We must also provide Ukraine with the necessary military aid, such as fighter jets, to ensure their safety and security. We must also continue to keep the pressure on Russia and make sure that they understand that if Ukraine falls, we all lose. Finally, we must stand with the Ukrainian people and their President Zelensky, and make sure that their voices are heard. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600849785950720000",
              text: "Got the insightful and up close opinion on Russia and Ukraine from Foreign Minister@RauZbigniew. We must support Ukraine as they fight for the western world and civilization.Russia has centuries of expansionist , imperialist foreign policy. This must cease.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1507141421601632261",
              text: "The US should be clear: If Russia uses chemical, biological or nuclear weapons against Ukraine, it would be a crime against humanity and consequences would follow.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497492244663840769",
              text: "Ukraine is the Berlin of the 21st century. It\u2019s citizens fight for freedom is reminiscent of Tianmen Square,standing up to the tank of oppression.\nWe must have further and SWIFT sanctions,more weapons, and support for refugees.\nNever forget Putins lies and evil, sociopathic acts",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1502717893510901765",
              text: "Poland offered to provide Ukraine with fighter jets, and I join my colleagues in asking that the U.S. help make this delivery happen. Ukraine needs these aircrafts. We can\u2019t sit by and watch as Putin\u2019s army commits war crimes, murder and genocide. pic.twitter.com/lDsYx2Y9mL",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1512080636982398976",
              text: ".@POTUS has unified our allies and led the way with unprecedented sanctions and military aid, but we must continue to keep the pressure on. We need to do more because if Ukraine falls, we all lose. https://t.co/34D7bgt8gT",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630263770768449539",
              text: "The Far Right could jeopardize Ukraine, but I think the majority of Republicans understand if Putin wins in Ukraine, he will continue into NATO countries. He wishes to restore the Soviet empire and plunge us into WWIII. We must stop him in Ukraine and ensure peace and democracy. https://t.co/LannaONAxa",
            },
          ],
          question:
            "How would you approach the ongoing Ukraine conflict, and what role do you believe the United States should play in resolving it?",
        },
        {
          answer:
            "My foreign policy priorities are to promote democracy and human rights, bolster our foreign policy efforts against kleptocracy, support our allies, and uphold the Helsinki Accords. I have been working to do this through my role as Co-Chair of the Helsinki Commission, my amendments to the NDAA, and my meetings with foreign ministers and leaders. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1373018334518738951",
              text: "Earlier this week, I had a productive meeting with MPs from the former Soviet Republic of Georgia, which is threatened by Russian military that sits on its border. \n\nWe talked about the importance of our bilateral relations and their territorial sovereignty. pic.twitter.com/v7rfK0FZoZ",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1410330288085946371",
              text: "I appreciate @SpeakerPelosi's trust in me to Co-Chair the Helsinki Commission after more than a decade of serving on @HelsinkiComm to shape our foreign policy and relations with our European allies. I will work in this position to promote democracy and human rights.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1440796400455860229",
              text: "My amendments to the NDAA would bolster our foreign policy efforts against kleptocracy and require the Secretary of Defense to attempt to recover the aircraft the US provided to the Afghan government. These aircraft were purchased by American taxpayers & should be returned to us. https://t.co/9HJcVMWMyS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600849785950720000",
              text: "Got the insightful and up close opinion on Russia and Ukraine from Foreign Minister@RauZbigniew. We must support Ukraine as they fight for the western world and civilization.Russia has centuries of expansionist , imperialist foreign policy. This must cease.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1496483609292984321",
              text: "After 3 days in Lithuania and a visit with our brave troops,who are only 5 KM\u2019s from Russian troops, it is clear Baltic states are rightly concerned about Putin and his dream of a new USSR. \nIn Vienna for OSCE meeting as tensions are understandably rising.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1494466549918355458",
              text: "As Co-Chair of @HelsinkiComm, I\u2019m leading a delegation to the Org for Security & Co-Op in Europe, which initially included a visit to #Ukraine that was canceled. I sent a message pledging my support for the people of Ukraine assuring them we\u2019d raise their security at the meeting. pic.twitter.com/j2gPBwojph",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1524442014523265027",
              text: "For four dark years, the United States turned its back on our allies in Europe and genuflected to Putin. But President Biden and Congressional Democrats will resolutely oppose Putin\u2019s criminal war, uphold the Helsinki Accords and support brave Ukraine. https://t.co/Ciudmbc0NH",
            },
          ],
          question:
            "What are your foreign policy priorities, particularly in relation to allies and international organizations?",
        },
        {
          answer:
            "As a member of the Ways and Means Committee, I will work to ensure that our trade agreements are fair and balanced, and that they benefit American workers and businesses. I will also work to ensure that our global economic relationships are based on mutual respect and benefit all parties involved. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1377413560301617154",
              text: "We have an historic opportunity to rebuild our country\u2019s infrastructure better than ever to create millions of good-paying jobs, lead the world economy, and tackle climate change. As a senior member of @TransportDems, I look forward to shaping & shepherding the #AmericanJobsPlan.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1372995666968985600",
              text: "Trump expanded nationwide permits to fast-track approval for oil pipelines like the #ByhaliaPipeline.\n\nAs a member of the Water Resources Subcommittee I will be weighing in on how nationwide permits are used or whether they\u2019re used at all so we can protect our health and planet.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1491419986698866689",
              text: "Countering corruption and autocracy is critical to restoring America\u2019s global leadership. With the inclusion of major parts of my Counter-Kleptocracy Act, the #AmericaCOMPETESAct will root out corruption, invest in innovation and bolster U.S. competitiveness on the world stage.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1385195930769674241",
              text: "Labor unions built the American middle class. Passing the #PROAct will help form a more perfect union, establish justice, insure domestic tranquility, provide for the common defence, promote the general welfare, and secure the blessings of liberty to ourselves and our posterity.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625943061250379776",
              text: "Democrats are rebuilding America by fixing our roads and bridges and bringing clean water and high-speed internet to more communities. And we are making the largest climate investments in history to generate clean, secure, American energy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1417141135185780738",
              text: "Putting Americans to work requires ensuring that people have everything they need to show up for a job.\n\nThat includes\u00a0affordable child care and paid family leave. No infrastructure plan is complete without them.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623144600025137152",
              text: "With the American Rescue Plan, Infrastructure Law, CHIPS Act and Inflation Reduction Act, we have made historic gains for workers and fueled a manufacturing boom in clean energy, semiconductors and infrastructure. Good clean union jobs right here in America! #SOTU2023",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1510969122041081858",
              text: ".@POTUS is focused on growing the economy from the bottom up and middle out. With the #AmericanRescuePlan, we've created the most jobs in recorded history, grown the economy faster than in the last 40 years, and are set to shrink the deficit by the largest-ever one-year drop.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1486018025941835778",
              text: ".@POTUS and @TheDemocrats have been working to improve supply chain issues, lower costs and beat the pandemic with masks, vaccines and tests. Already, the US economy has grown faster than almost any other country and we created more jobs in 2021 than in the past 80 years.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1410330288085946371",
              text: "I appreciate @SpeakerPelosi's trust in me to Co-Chair the Helsinki Commission after more than a decade of serving on @HelsinkiComm to shape our foreign policy and relations with our European allies. I will work in this position to promote democracy and human rights.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1357780724112310273",
              text: "I want to be in the room where climate policy happens, so I\u2019m glad to be joining the Natural Resources Committee this Congress where I can work on climate action that recognizes the scale of this crisis and seeks transformative justice.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1461124943047966720",
              text: "Contrary to Republican propaganda, leading economists say the Build Back Better Act will not add to inflationary pressure and will even lower it. We need to make it easier for people to get to work and get goods to market. https://t.co/kelBcsLmHO",
            },
          ],
          question:
            "How will you navigate trade agreements and global economic relationships to benefit American interests?",
        },
        {
          answer:
            "I believe that diplomacy is the best way to address international conflicts. I support diplomatic efforts to address occupation, intimidation tactics, and human rights violations. I will use my position as Co-Chair of the Helsinki Commission to promote democracy and human rights, and to support Ukraine in their fight for the western world and civilization. We must also speak in one voice that any use of chemical weapons will not be tolerated and take whatever measures are necessary to deter such action. Finally, I will resolutely oppose Putin\u2019s criminal war, uphold the Helsinki Accords, and support brave Ukraine. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1373018337949708291",
              text: "People have been threatened, intimidated, and violated. I support diplomatic efforts to address this occupation, intimidation tactics, and human rights violations.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1410330288085946371",
              text: "I appreciate @SpeakerPelosi's trust in me to Co-Chair the Helsinki Commission after more than a decade of serving on @HelsinkiComm to shape our foreign policy and relations with our European allies. I will work in this position to promote democracy and human rights.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600849785950720000",
              text: "Got the insightful and up close opinion on Russia and Ukraine from Foreign Minister@RauZbigniew. We must support Ukraine as they fight for the western world and civilization.Russia has centuries of expansionist , imperialist foreign policy. This must cease.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1502756484907769863",
              text: "If Putin is contemplating using chemical weapons in Ukraine, @POTUS and the global community must speak in one voice that such a course of action will not be tolerated and take whatever measures are necessary to deter Russia from even considering such dark and destructive action.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1524442014523265027",
              text: "For four dark years, the United States turned its back on our allies in Europe and genuflected to Putin. But President Biden and Congressional Democrats will resolutely oppose Putin\u2019s criminal war, uphold the Helsinki Accords and support brave Ukraine. https://t.co/Ciudmbc0NH",
            },
          ],
          question:
            "What approach will you take to address international conflicts and promote diplomacy?",
        },
      ],
      summary:
        'Steve Cohen emphasizes a strong stance against foreign aggression, specifically calling out Russia\'s actions in Ukraine. He prioritizes the support for Ukraine, advocating for their freedom and democracy, and indicating that any use of chemical, nuclear, or biological weapons will not be tolerated and will have consequences. Cohen also believes in providing significant military aid to bolster Ukrainian defenses.\n\nIn terms of his broader foreign policy priorities, Cohen focuses on the promotion of democracy and human rights, and strengthening relationships with international allies. He highlights his role as a Co-chair of the Helsinki Commission as instrumental in these efforts. On a related note, he underlines the urgent need to address kleptocracy on a global scale.\n\nWhen it comes to trade agreements and global economic relationships, Cohen, as a member of the Ways and Means Committee, is committed to fairness and balance. He asserts that these relationships should be rooted in mutual respect and must benefit all parties involved, particularly American workers and businesses.\n\nCohen values diplomacy as the best approach to address international conflicts, supporting diplomatic efforts to rectify human rights violations, occupation, and intimidation tactics. He firmly denounces any use of chemical weapons and is determined to dissuade such actions. Lastly, Cohen expresses firm opposition to "Putin\u2019s criminal war" while voicing his unabating support for Ukraine.',
    },
    Guns: {
      questions: [
        {
          answer:
            "We must pass commonsense gun reform, including expanded background checks, Red Flag Laws, and an Assault Weapons Ban. We must also invest in mental health services and crack down on dangerous gun modifications. These measures will help to reduce gun violence and save lives. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1635785533891792896",
              text: "Pleased to see @POTUS take further action to curb gun violence.\n\u00a0\nThe American people have made it clear they want comprehensive reform, and I will continue to fight for it in Congress.\ncnbc.com/2023/03/14/wat\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1370072860274925571",
              text: "I\u2019m so tired of the political games and special interests blocking sensible reforms to #EndGunViolence.\n \n90% of Americans support expanded background checks! Today we passed two bills to strengthen background checks because we can and must take commonsense action to save lives!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1651290078000316433",
              text: "We've got to pursue real solutions to curb #GunViolence\u2014our city knows that, but the TN legislature isn\u2019t interested. I spoke w/ ATF Director Dettelbach today about their work to crack down on dangerous gun mods like Glock switches & the need for federal action on Red Flag Laws?? https://t.co/2FtnF0QHIx",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1443671316125700101",
              text: "I am disheartened, saddened and shocked that a child has been shot by another student at an elementary school. One thing is clear: we have too many guns in the wrong hands. Gun violence begs for commonsense reform.\nwreg.com/news/local/chi\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1529202502339633152",
              text: "Thoughts and prayers don\u2019t work. Republicans need to stop genuflecting to NRA and help pass some gun laws,mental health, and red flag laws ( Buffalo). People need guns to kill! Wake up and let\u2019s pass some laws to bolster safety.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1641547790105952256",
              text: "This insane act of violence has been a shock to our entire state, especially to students and staff at the Covenant School. I'm ready to work with anyone who is serious about protecting our communities against gun violence\u2014and we should start by reinstating the #AssaultWeaponsBan.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1535021866313818113",
              text: "Today I voted for a Red Flag Law to prevent those who are intent on harming themselves or others from accessing lethal weapons. It might have prevented Uvalde and we must get it and other legislation through the Senate before more children are senselessly murdered. https://t.co/PDwDezxTWV",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1453426774503743496",
              text: "We\u2019ve had enough thoughts and prayers. We need to save lives and pass gun violence prevention laws. #ExtremeRiskLawsSaveLives https://t.co/9K65qpk5nc",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1549845850746064896",
              text: "First Republicans accuse Democrats, wrongly, of wanting to defund the police, and then they tell you why we don\u2019t need police because everyone in their district has a gun and saves lives every other day! Absurd! We need commonsense laws to reduce gun violence and improve safety. https://t.co/DidnqCtln5",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1532819392475561984",
              text: "In 2022, more children have been killed in school shootings than police officers have been shot fatally in the line of duty. We must #EndGunViolence.",
            },
          ],
          question:
            "What gun control measures do you believe will be effective in reducing gun violence?",
        },
        {
          answer:
            "We must ensure that the Second Amendment is respected while also protecting the public from gun violence. We must regulate militias and the use of handguns to protect the public, and we must also ensure that certain places remain off limits to guns, such as schools, hospitals, subways, sports arenas, government buildings, child care centers, movie theaters, nightclubs, and places where liquor is sold by drink. We must also pursue real solutions to curb gun violence, such as strengthening background checks, limiting access to automatic weapons, and implementing Red Flag Laws. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1530967961397239808",
              text: "We have a right to free speech, but not the right to scream fire in a theater. We also don\u2019t have a right to an assault weapon or bazooka.\n\nThe 2nd Amendment talks about a well-regulated militia. We can and must regulate militias and the use of handguns to protect the public. https://t.co/1dKecXT9Mx",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1540076864521355265",
              text: "The arch-conservative Supreme Court is quietly transforming a limited right to self-defense in one's home into a new, radical right to bring any gun to any public space. Disturbing to see SCOTUS side with the NRA and hinder our ability to keep families safe from gun violence.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1545707610124566529",
              text: "Loss of childrens lives and others in Uvalde and in Highland Park deaths and a child survived but paralyzed waist down and Buffalo and Parkland \u2026REPUBLICANS ARE NOT PROTECTING AMERICANS!PUT CHILDREN AND OTHER VICTIMS BEFORE NRA!! 2ND AMENDMENT IS NOT FOR AUTOMATIC WEAPONS!!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1534716290719485952",
              text: "Movie theaters, churches, schools and grocery stores all need to be protected. This country is wild with guns and we need to restrict them! https://t.co/4HERkG1giq",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1549845850746064896",
              text: "First Republicans accuse Democrats, wrongly, of wanting to defund the police, and then they tell you why we don\u2019t need police because everyone in their district has a gun and saves lives every other day! Absurd! We need commonsense laws to reduce gun violence and improve safety. https://t.co/DidnqCtln5",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1540439829372190725",
              text: "I voted reluctantly today on a gun safety bill that's a small step forward but should have been better. The House bill was much better, but this Senate compromise was the best that could be done with Republicans who refuse to do anything to limit gun access for anyone over 18. pic.twitter.com/GU86eNgd7n",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1651290078000316433",
              text: "We've got to pursue real solutions to curb #GunViolence\u2014our city knows that, but the TN legislature isn\u2019t interested. I spoke w/ ATF Director Dettelbach today about their work to crack down on dangerous gun mods like Glock switches & the need for federal action on Red Flag Laws?? https://t.co/2FtnF0QHIx",
            },
          ],
          question:
            "How do you plan to balance Second Amendment rights with public safety concerns?",
        },
        {
          answer:
            "Absolutely. I'm a strong supporter of commonsense gun reform, including expanding background checks, reinstating the assault weapons ban, and passing red flag laws. We must take action to protect our communities from gun violence and ensure that guns are used responsibly. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1370072860274925571",
              text: "I\u2019m so tired of the political games and special interests blocking sensible reforms to #EndGunViolence.\n \n90% of Americans support expanded background checks! Today we passed two bills to strengthen background checks because we can and must take commonsense action to save lives!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1540439829372190725",
              text: "I voted reluctantly today on a gun safety bill that's a small step forward but should have been better. The House bill was much better, but this Senate compromise was the best that could be done with Republicans who refuse to do anything to limit gun access for anyone over 18. pic.twitter.com/GU86eNgd7n",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1641547790105952256",
              text: "This insane act of violence has been a shock to our entire state, especially to students and staff at the Covenant School. I'm ready to work with anyone who is serious about protecting our communities against gun violence\u2014and we should start by reinstating the #AssaultWeaponsBan.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1535021866313818113",
              text: "Today I voted for a Red Flag Law to prevent those who are intent on harming themselves or others from accessing lethal weapons. It might have prevented Uvalde and we must get it and other legislation through the Senate before more children are senselessly murdered. https://t.co/PDwDezxTWV",
            },
          ],
          question:
            "Are there policies to promote responsible gun ownership that you support?",
        },
      ],
      summary:
        "Steve Cohen strongly supports gun reform in the interest of public safety. His positions include expanding background checks, instituting Red Flag Laws, and reinstating an Assault Weapons Ban to reduce gun violence. He also believes in regulating the use of guns in places like schools, government buildings, and areas where liquor is sold. In addition, he sees a need for investing in mental health services and cracking down on dangerous gun modifications. Despite these measures, he maintains that the rights granted by the Second Amendment should still be respected. All these measures, he believes, would promote responsible gun ownership.",
    },
    Healthcare: {
      questions: [
        {
          answer:
            "I am committed to making healthcare accessible to all Americans, regardless of income. To do this, I am working to strengthen the Affordable Care Act, cap out-of-pocket costs for seniors, expand the child tax credit, ensure affordable child care and paid family leave, lower the cost of health care, childcare, education and housing, and empower Medicare to negotiate drug prices with pharmaceutical companies. I am also introducing a bipartisan solution to incentivize supermarkets to open in underserved areas, hire local residents & workers with barriers to employment, and sell affordable fresh fruit & veg. Additionally, I am working to cap the cost of insulin at $35 per month and ensure that 9 in 10 shoppers qualify for financial help to lower monthly costs. Finally, I am taking on the special interests to deliver lower prescription drug prices and ensure that Americans are not harmed by the current healthcare system. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1490698707729268741",
              text: "Call me a radical, but I don\u2019t think those who lift themselves out of poverty should lose everything because of one medical diagnosis. We need affordable health care for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1584994426237378560",
              text: "Still haven't heard about how Democrats passed cost-lowering legislation? Here's just a few things we already did:\n?? Lower Medicare Rx prices\n?? Cap out-of-pocket costs for seniors\n?? Lower health premiums by $800/yr for 13 million Americans\n????????Expanded child tax credit",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1456843049733136385",
              text: "#BuildBackBetter will lower the cost of health care, childcare, education and housing and cut middle class taxes\u2014fully paid for by making corporations and the wealthiest individuals pay their fair share. The day we pass it will be a bright day in America",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1386784136103448580",
              text: "Now is the moment for historic change. That is why I\u2019m calling on @POTUS to propose in the #AmericanFamiliesPlan that we lower the Medicare eligibility age, expand coverage to dental/vision/hearing, and empower Medicare to negotiate drug prices with pharmaceutical companies. pic.twitter.com/eIhJz0IsLq",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1383182121703968769",
              text: "We can\u2019t wait for states like Texas and Tennessee to expand Medicaid while millions suffer in the coverage gap.\n\u00a0\nLet\u2019s ensure the recovery package expands subsidies to FULLY cover health insurance premiums for ALL low-income Americans.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1465819341308542981",
              text: "For middle class families, the Build Back Better Act will produce more jobs, reduce taxes and lower the costs of prescription drugs, home health care, and child care, allowing more workers an opportunity to participate in America\u2019s economy. https://t.co/vNb7rfYx9X",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1514677598130954241",
              text: "Thanks to @POTUS fixing the \u201cfamily glitch,\u201d a typical family of four will now save more than $4000 on their health insurance costs. I\u2019m\u00a0proud to continue working to strengthen the ACA and ensure that health care is affordable for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1509664407139373060",
              text: "Americans with diabetes are dying because they cannot afford their medicine. Today, I voted with House Dems to cap the cost of insulin at $35 per month.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1471854899398914049",
              text: "Americans ration their prescriptions because of astronomical prices. Reducing prices has been shown to improve patients' health and adherence to treatment. In other words, our current health care system is HARMING patients. Lower drug costs and pass Build Back Better!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1414638048185356294",
              text: "DID YOU KNOW drug companies spent a significant portion of their R&D budgets on finding ways to suppress generic and biosimilar competition while continuing to raise prices? Americans deserve #LowerDrugPrices!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623765489821310980",
              text: "Democrats took on the special interests to deliver lower prescription drug prices. We will not let Republicans raise them.",
            },
          ],
          question:
            "What steps will you take to make healthcare accessible to all Americans, regardless of income?",
        },
        {
          answer:
            "I believe that no one should have to suffer financial ruin because of a medical diagnosis. That's why I'm fighting for affordable health care for all. We have already passed cost-lowering legislation to lower Medicare Rx prices, cap out-of-pocket costs for seniors, lower health premiums by $800/yr for 13 million Americans, and expand the child tax credit. We need to get the bloated defense budget under control and reinvest in people here at home. We also need to make sure that private student loan debt is treated like any other debt and that people are protected from the financial ruin of student loans should they face unexpected strains. We have also passed a Medicare reform bill that is forcing pharmaceutical companies to bring their costs down for seniors and other Medicare beneficiaries. We are also capping the cost of insulin at $35 per month and calling on the President to propose lowering the Medicare eligibility age, expanding coverage to dental/vision/hearing, and empowering Medicare to negotiate drug prices with pharmaceutical companies. We are also ending taxpayer-funded handouts to fossil fuel companies and producing more jobs, reducing taxes, and lowering the costs of prescription drugs, home health care, and child care with the Build Back Better Act. We will not negotiate with terrorists and allow House Republicans to crash the global economy with a debt default. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1490698707729268741",
              text: "Call me a radical, but I don\u2019t think those who lift themselves out of poverty should lose everything because of one medical diagnosis. We need affordable health care for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1584994426237378560",
              text: "Still haven't heard about how Democrats passed cost-lowering legislation? Here's just a few things we already did:\n?? Lower Medicare Rx prices\n?? Cap out-of-pocket costs for seniors\n?? Lower health premiums by $800/yr for 13 million Americans\n????????Expanded child tax credit",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1384183138398588935",
              text: "We spend more on defense than the next 10 countries combined, yet our citizens cannot afford their own heath care. It\u2019s time we get the bloated defense budget under control and reinvest in people here at home.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1421248648663511053",
              text: "No one wants to declare bankruptcy, but the system should be fairer and treat private student loan debt like any other debt. Our bill ensures people will be protected from the financial ruin of student loans should they face unexpected strains.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1640878740614807557",
              text: "This year, pharmaceutical companies raised the price of 27 medications above the rate of inflation.\n\u00a0\nThe Medicare reform bill we passed last year is forcing them to bring those costs down for seniors and other Medicare beneficiaries, with savings kicking in starting in April. pic.twitter.com/hAcVpwETu1",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1471854899398914049",
              text: "Americans ration their prescriptions because of astronomical prices. Reducing prices has been shown to improve patients' health and adherence to treatment. In other words, our current health care system is HARMING patients. Lower drug costs and pass Build Back Better!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1509664407139373060",
              text: "Americans with diabetes are dying because they cannot afford their medicine. Today, I voted with House Dems to cap the cost of insulin at $35 per month.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1386784136103448580",
              text: "Now is the moment for historic change. That is why I\u2019m calling on @POTUS to propose in the #AmericanFamiliesPlan that we lower the Medicare eligibility age, expand coverage to dental/vision/hearing, and empower Medicare to negotiate drug prices with pharmaceutical companies. pic.twitter.com/eIhJz0IsLq",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492245514972192777",
              text: "I happen to believe everyday Americans shouldn\u2019t be padding pharmaceutical companies\u2019 profits by paying higher prescription drug prices than any other country.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1372978853656862726",
              text: "1/3 OF COVID DEATHS were tied to a lack of insurance.\n\nHere in the richest country on earth, millions of Americans are forced to forgo life-saving care or take on life-crushing debt because we're the only industrialized nation w/out universal health care. We need #MedicareforAll!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1465819341308542981",
              text: "For middle class families, the Build Back Better Act will produce more jobs, reduce taxes and lower the costs of prescription drugs, home health care, and child care, allowing more workers an opportunity to participate in America\u2019s economy. https://t.co/vNb7rfYx9X",
            },
          ],
          question:
            "How do you plan to address the issue of skyrocketing healthcare costs and medical debt?",
        },
        {
          answer:
            "Absolutely. We need to lower drug costs and premiums, expand coverage to dental/vision/hearing, empower Medicare to negotiate drug prices with pharmaceutical companies, and ensure that people have access to affordable child care and paid family leave. We also need to strengthen the ACA and Medicaid coverage, and provide universal health care. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1490698707729268741",
              text: "Call me a radical, but I don\u2019t think those who lift themselves out of poverty should lose everything because of one medical diagnosis. We need affordable health care for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1471854899398914049",
              text: "Americans ration their prescriptions because of astronomical prices. Reducing prices has been shown to improve patients' health and adherence to treatment. In other words, our current health care system is HARMING patients. Lower drug costs and pass Build Back Better!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1386784136103448580",
              text: "Now is the moment for historic change. That is why I\u2019m calling on @POTUS to propose in the #AmericanFamiliesPlan that we lower the Medicare eligibility age, expand coverage to dental/vision/hearing, and empower Medicare to negotiate drug prices with pharmaceutical companies. pic.twitter.com/eIhJz0IsLq",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1414638048185356294",
              text: "DID YOU KNOW drug companies spent a significant portion of their R&D budgets on finding ways to suppress generic and biosimilar competition while continuing to raise prices? Americans deserve #LowerDrugPrices!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1438185335381532676",
              text: "Big Pharma has written its own rules and fought drug pricing regulations.\n \nWe need to rewrite the rules for the people and bring down drug costs and premiums. #WeNeedHR3",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1417141135185780738",
              text: "Putting Americans to work requires ensuring that people have everything they need to show up for a job.\n\nThat includes\u00a0affordable child care and paid family leave. No infrastructure plan is complete without them.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1372978853656862726",
              text: "1/3 OF COVID DEATHS were tied to a lack of insurance.\n\nHere in the richest country on earth, millions of Americans are forced to forgo life-saving care or take on life-crushing debt because we're the only industrialized nation w/out universal health care. We need #MedicareforAll!",
            },
          ],
          question:
            "Are there specific healthcare reforms you believe are necessary to improve the system?",
        },
      ],
      summary:
        "Steve Cohen is strongly committed towards making healthcare accessible and affordable for all Americans, irrespective of their income. Some of the key measures he advocates for include:\n\n1. Strengthening the Affordable Care Act (ACA).\n2. Limiting out-of-pocket expenses for seniors, curbing insulin costs to $35 per month and ensuring 9 out of 10 shoppers qualify for financial aids to decrease monthly costs.\n3. Empowering Medicare to negotiate drug prices directly with the pharmaceutical companies.\n4. Broadening coverage to include dental, vision and hearing care.\n5. Making childcare affordable and ensuring paid family leave.\n6. Encouraging supermarkets to open in underserved areas, employing local residents and selling affordable fresh produce.\n7. Implementing a bipartisan solution that offers incentives to supermarkets opening in underprivileged areas whilst hiring locals.\n8. Passing legislation to bring Medicare prescription prices down, reduce health premiums by $800 a year for 13 million Americans and extending the child tax credit.\n9. Mitigating the detrimental financial impact of student loans and protecting people from financial ruin due to unexpected circumstances.\n10. Enact the Build Back Better Act to create more jobs, lower taxes and reduce the cost of prescription drugs, home health care, and child care. \n\nHis ultimate goal is making healthcare reform affordable, thereby preventing financial ruin due to medical costs. He strongly believes that private student loan debt should be treated similarly to any other debt. Cohen is against tax-funded handouts to fossil fuel companies and against allowing House Republicans to crash the global economy with a debt default.",
    },
    Immigration: {
      questions: [
        {
          answer:
            "Comprehensive immigration reform is essential to our nation's future. We must create a pathway to citizenship for Dreamers, who were educated and trained in America, and provide seasonal visas for Cuban athletes so they can play here without having to renounce their citizenship and abandon their families. We must also ensure that immigrants have access to the resources they need to show up for a job, such as affordable child care and paid family leave. We must also reduce barriers and expand opportunities for formerly incarcerated people to fulfill their potential. I'm committed to working with my colleagues in Congress to pass legislation that will make these reforms a reality. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1456000272585658369",
              text: "The House has been working to improve the Build Back Better framework and include negotiated prescription drug prices, paid family leave and immigration reform. I\u2019ll vote for both infrastructure bills! https://t.co/HfjcfNFWTU",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1417141135185780738",
              text: "Putting Americans to work requires ensuring that people have everything they need to show up for a job.\n\nThat includes\u00a0affordable child care and paid family leave. No infrastructure plan is complete without them.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1372680082448257027",
              text: "Dreamers were educated and trained in America. They live, work, and pay taxes in America.\n \nNot only are we doing the right thing by giving them a stable legal status, but we\u2019re doing the right thing for America. We need their talent to make this country even better.",
            },
          ],
          question:
            "How will you approach comprehensive immigration reform, including pathways to citizenship?",
        },
        {
          answer:
            "I support diplomatic efforts to address the occupation of our borders, as well as the intimidation tactics and human rights violations that have been occurring. I also support the inclusion of immigration reform in the Build Back Better framework, as well as the provisions in upcoming legislation to manage our water resources that address the challenges facing our nation\u2019s most vulnerable communities. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1373018337949708291",
              text: "People have been threatened, intimidated, and violated. I support diplomatic efforts to address this occupation, intimidation tactics, and human rights violations.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1456000272585658369",
              text: "The House has been working to improve the Build Back Better framework and include negotiated prescription drug prices, paid family leave and immigration reform. I\u2019ll vote for both infrastructure bills! https://t.co/HfjcfNFWTU",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
          ],
          question:
            "What measures do you support for border security and managing immigration flows?",
        },
        {
          answer:
            "Absolutely. I have long advocated for the Dream Act, which would provide a pathway to citizenship for Dreamers, and I have recently re-introduced a package of bills to increase police accountability and transform police culture. I hope President Biden will sign them into law and reform policing across our country. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1407372748213690368",
              text: "Mike Pence and I used to agree on one thing: DC should be a state. That Republicans deny more than half a million Americans the full rights of citizenship is pure hypocrisy and a raw power play.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1404881100456042506",
              text: "9 years ago, Obama implemented DACA by executive action. \n \n4 years ago, Trump tried to terminate the program by executive action.\n \nThis year, Biden could sign the Dream Act into law so Dreamers are finally protected by law. The Senate MUST pass the bill while it can!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1365052715924086786",
              text: "For too long, demands for justice and reform have fallen on deaf ears.\n\nToday, I re-introduced a package of bills to increase police accountability & transform police culture. I hope @POTUS will sign them into law and reform policing across our country.\ncohen.house.gov/media-center/p\u2026",
            },
          ],
          question:
            "Are there policies to address the status of undocumented immigrants that you advocate?",
        },
      ],
      summary:
        "Steve Cohen is an advocate for comprehensive immigration reform, which he sees as vital for America's future. He pushes for pathways to citizenship for Dreamers and supports providing seasonal visas for Cuban athletes without forcing them to renounce their citizenship or abandon their families. Cohen also emphasizes the need to ensure that immigrants have access to essential resources such as affordable childcare and paid family leave, and advocates for reducing barriers for the formerly incarcerated. \n\nIn regards to border security, Cohen supports diplomacy to address potential issues and human rights violations. He also supports the inclusion of immigration reform in the Build Back Better framework and in upcoming legislation managing water resources. \n\nCohen has a particular focus on the status of undocumented immigrants, advocating for the Dream Act which provides a pathway to citizenship. He has also re-introduced bills to increase police accountability and transform policing culture in the context of immigration reform.",
    },
    "Inflation and Prices": {
      questions: [
        {
          answer:
            "I am proud to have supported the Inflation Reduction Act, which takes bold action to combat the climate crisis and save Americans money on prescription drugs, health premiums, energy prices, and more. We have also passed legislation to lower Medicare Rx prices, cap out-of-pocket costs for seniors, lower health premiums by $800/yr for 13 million Americans, and expanded the child tax credit. We are also working to ensure the flying public receives cash refunds when airlines cause a cancellation, and are pushing for affordable child care and paid family leave. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558086882046619648",
              text: "The Inflation Reduction Act is a victory for the people over special interests: cracks down on Big Pharma price gouging, combats pollution and makes the wealthiest corporations and individuals pay their fair share.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558208558759550986",
              text: "We PASSED the #InflationReductionAct! It takes bold action to combat the climate crisis and save Americans ?????? on: \n?? Prescription drugs\n?? Health premiums\n?? Energy prices, and much more\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492245514972192777",
              text: "I happen to believe everyday Americans shouldn\u2019t be padding pharmaceutical companies\u2019 profits by paying higher prescription drug prices than any other country.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1584994426237378560",
              text: "Still haven't heard about how Democrats passed cost-lowering legislation? Here's just a few things we already did:\n?? Lower Medicare Rx prices\n?? Cap out-of-pocket costs for seniors\n?? Lower health premiums by $800/yr for 13 million Americans\n????????Expanded child tax credit",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1554189494865256449",
              text: "After airlines took a multibillion-dollar bailout from American taxpayers, they're cancelling thousands of flights and offering nothing but credit. @SenMarkey and I are reintroducing legislation to ensure the flying public receives cash refunds when airlines cause a cancellation.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1417141135185780738",
              text: "Putting Americans to work requires ensuring that people have everything they need to show up for a job.\n\nThat includes\u00a0affordable child care and paid family leave. No infrastructure plan is complete without them.",
            },
          ],
          question:
            "What measures do you propose to mitigate the impact of inflation on everyday Americans?",
        },
        {
          answer:
            "Absolutely. I introduced the Inflation Reduction Act to crack down on Big Pharma price gouging, combat pollution, and make the wealthiest corporations and individuals pay their fair share. I also introduced a bipartisan solution with @RepKenBuck to incentivize supermarkets to open in underserved areas, hire local residents & workers with barriers to employment, and sell affordable fresh fruit & veg. Additionally, I'm fighting to lower costs, fight price gouging, and pass the Build Back Better framework to include negotiated prescription drug prices, paid family leave, and immigration reform. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1419793315621445640",
              text: "For millions of Americans, affordable healthy food is too hard to access. I introduced a bipartisan solution w/ @RepKenBuck to incentivize supermarkets to open in underserved areas, hire local residents & workers with barriers to employment, and sell affordable fresh fruit & veg.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1622669448619466790",
              text: "House Republicans want to hold the economy hostage unless they get cuts to essential programs like Medicare, Medicaid, Social Security, and SNAP. Absolutely not.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1456000272585658369",
              text: "The House has been working to improve the Build Back Better framework and include negotiated prescription drug prices, paid family leave and immigration reform. I\u2019ll vote for both infrastructure bills! https://t.co/HfjcfNFWTU",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492245514972192777",
              text: "I happen to believe everyday Americans shouldn\u2019t be padding pharmaceutical companies\u2019 profits by paying higher prescription drug prices than any other country.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1461124943047966720",
              text: "Contrary to Republican propaganda, leading economists say the Build Back Better Act will not add to inflationary pressure and will even lower it. We need to make it easier for people to get to work and get goods to market. https://t.co/kelBcsLmHO",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1471854899398914049",
              text: "Americans ration their prescriptions because of astronomical prices. Reducing prices has been shown to improve patients' health and adherence to treatment. In other words, our current health care system is HARMING patients. Lower drug costs and pass Build Back Better!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558086882046619648",
              text: "The Inflation Reduction Act is a victory for the people over special interests: cracks down on Big Pharma price gouging, combats pollution and makes the wealthiest corporations and individuals pay their fair share.",
            },
          ],
          question:
            "Are there specific policies you support to stabilize prices and ensure affordability?",
        },
      ],
      summary:
        "Steve Cohen is focused on mitigating the impact of inflation on everyday Americans. He supported the Inflation Reduction Act, which aims to combat the climate crisis and lower costs of prescription drugs, health premiums, and energy. He supported legislation to lower Medicare Rx prices, cap seniors' out-of-pocket costs, reduce health premiums for 13 million Americans, and expand the child tax credit.\n\nTo stabilize prices and ensure affordability, Cohen has proposed policies that crack down on price gouging by large pharmaceutical companies and make wealthy corporations and individuals pay their fair share. He introduced a bipartisan solution to incentivize supermarkets to open in underserved areas, employ local residents, and sell affordable fresh produce.\n\nLastly, Cohen is working towards the passing of the Build Back Better framework to lower costs, fight price gouging, and include negotiated prescription drug prices, paid family leave, and immigration reform.",
    },
    "Jobs & the Economy": {
      questions: [
        {
          answer:
            "As a Congressman, I'm committed to creating jobs and reducing unemployment. I'm proud of the work we've done so far, including the #AmericanRescuePlan, which has created the most jobs in recorded history, grown the economy faster than in the last 40 years, and is set to shrink the deficit by the largest-ever one-year drop. We've also created more jobs in the last two years than any other period in history and are building the economy from the bottom up and middle out, instead of from the top down. We need to make it easier for people to get to work and get goods to market, and we must establish universal childcare and permanently fund the CTC to Build Back Better for all. We must also invest in growth and make sure all of us \u2013 not just those at the top \u2013 are dealt in to share the benefits of a growing economy. Finally, we need to incentivize supermarkets to open in underserved areas, hire local residents & workers with barriers to employment, and sell affordable fresh fruit & veg. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1510969122041081858",
              text: ".@POTUS is focused on growing the economy from the bottom up and middle out. With the #AmericanRescuePlan, we've created the most jobs in recorded history, grown the economy faster than in the last 40 years, and are set to shrink the deficit by the largest-ever one-year drop.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623143744575795202",
              text: "We created more jobs in the last two years than any other period in history and are building the economy from the bottom up and middle out, instead of from the top down.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1545456442576195584",
              text: "Gas prices are DOWN and employment is UP. Unemployment is near an historic low of 3.6% and the economy is getting stronger. Let\u2019s keep the wins coming for this Democratic Recovery.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1417141135185780738",
              text: "Putting Americans to work requires ensuring that people have everything they need to show up for a job.\n\nThat includes\u00a0affordable child care and paid family leave. No infrastructure plan is complete without them.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1448043974065852417",
              text: "While men gained jobs last month, women lost jobs and have made up 2/3 of all jobs lost during the pandemic. Women have dropped to the same rate of workforce participation as in 1988. We must establish universal childcare and permanently fund the CTC to Build Back Better for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1456709052189323267",
              text: "Since President Biden took office, we\u2019ve added 5.6 million jobs to the economy! This moment is an opportunity to invest in growth and make sure all of us \u2013 not just those at the top \u2013 are dealt in to share the benefits of a growing economy. #BuildBackBetter",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1419793315621445640",
              text: "For millions of Americans, affordable healthy food is too hard to access. I introduced a bipartisan solution w/ @RepKenBuck to incentivize supermarkets to open in underserved areas, hire local residents & workers with barriers to employment, and sell affordable fresh fruit & veg.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1465819341308542981",
              text: "For middle class families, the Build Back Better Act will produce more jobs, reduce taxes and lower the costs of prescription drugs, home health care, and child care, allowing more workers an opportunity to participate in America\u2019s economy. https://t.co/vNb7rfYx9X",
            },
          ],
          question:
            "What strategies will you employ to stimulate job growth and reduce unemployment?",
        },
        {
          answer:
            "We are doing everything we can to support small businesses and entrepreneurs. We have passed the American Rescue Plan, which includes the Paycheck Protection Program (PPP) and the Restaurant Revitalization Fund, to provide relief to small businesses and restaurants. We are also urging the Small Business Administration (SBA) to fix and reopen the Shuttered Venue Operators Grants application portal to help hard-hit small businesses such as local music venues, theaters, museums, and zoos. Additionally, we are introducing a bipartisan solution to incentivize supermarkets to open in underserved areas, hire local residents and workers with barriers to employment, and sell affordable fresh fruit and vegetables. We are also fighting to end taxpayer-funded handouts to fossil fuel companies and to raise the minimum wage to $15 for all federal workers. We are confident that these measures will help to grow the economy from the bottom up and middle out. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1350106722568966149",
              text: "Starting this week, small businesses can apply for PPP loans. This includes both first and second-time borrowers. Please try to take advantage of this important program.\n\nTo find lenders and learn more, go here: home.treasury.gov/policy-issues/\u2026 https://t.co/4n22Zm14MU",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1512118534402363392",
              text: "Restaurant relief was a lifeline during the peak of the pandemic, but funds ran out before many restaurants received relief. Today, House Democrats are passing a targeted small business relief bill to replenish the restaurant funds and support other hard-hit businesses.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1384932687983648777",
              text: ".@RepJimCooper and I are urging @SBAgov to fix and reopen the Shuttered Venue Operators Grants application portal. The funds are urgently needed to keep hard-hit small businesses such as local music venues, theaters, museums & zoos open and operating. #SaveOurStages @LevittShell pic.twitter.com/8PSNjjMJc9",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1386773955801911303",
              text: "Theaters, music venues, and other lives venues can apply for @SBAgov's Shuttered Venue Operators Grants starting TODAY. We fought to get this into the relief package and we demanded the portal reopen as quickly as possible. Help is here! #SaveOurStages\nsba.gov/funding-progra\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1471887110466686977",
              text: "We must boldly tackle climate change and a green energy future. For a start, end taxpayer-funded handouts to fossil fuel companies.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1352743707817111553",
              text: "On today's episode of The President Actually Cares About Working Americans:\n?Boost food stamps & school meal benefits by 15%\n?Speed up survival check delivery\n?Protect unemployment for people concerned about workplace COVID risks\n?Raise min wage to $15 for all federal workers",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1461124943047966720",
              text: "Contrary to Republican propaganda, leading economists say the Build Back Better Act will not add to inflationary pressure and will even lower it. We need to make it easier for people to get to work and get goods to market. https://t.co/kelBcsLmHO",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1465819341308542981",
              text: "For middle class families, the Build Back Better Act will produce more jobs, reduce taxes and lower the costs of prescription drugs, home health care, and child care, allowing more workers an opportunity to participate in America\u2019s economy. https://t.co/vNb7rfYx9X",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1456709052189323267",
              text: "Since President Biden took office, we\u2019ve added 5.6 million jobs to the economy! This moment is an opportunity to invest in growth and make sure all of us \u2013 not just those at the top \u2013 are dealt in to share the benefits of a growing economy. #BuildBackBetter",
            },
          ],
          question:
            "How can the government support small businesses and entrepreneurs to boost the economy?",
        },
        {
          answer:
            "Absolutely. I have been fighting for working families since I came to Congress. I introduced the President's Billionaire Minimum Income Tax to ensure those with the most pay the most. I also authored the Living Wage Now Act to immediately raise the federal minimum wage to $15. I'm proud to have supported the Good Jobs for Good Airports Act, which will assure a minimum wage of $15 for airport workers. I'm also fighting for affordable child care and paid family leave, so people have everything they need to show up for a job. Finally, I'm pushing for the Paycheck Fairness Act to ensure equal pay for women. Together, these initiatives will help address income inequality and promote wage growth. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1634233535698485249",
              text: "For too long the ultra-wealthy have not paid their fair share in taxes while the middle class continues to scrape by. \n\nI\u2019m pleased to see the billionaire minimum income tax is included in @POTUS' new budget, which I introduced last Congress. pic.twitter.com/Yj3nTUWB1a",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1633601646570741763",
              text: "Pleased to meet with the @SEIU today where I agreed to support the Good Jobs for Good Airports Act, which will assure a minimum wage of $15 for airport workers. The disparity and wealth in our country is too great and between airport executives and airport employees even greater. pic.twitter.com/pujhSfryuF",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1350109112831537153",
              text: "In such a rich nation, it is immoral to have a federal minimum wage that leaves full-time workers living below the poverty line. It is well past time we immediately raised it to $15. The #FightFor15 cannot wait.\n \nToday, I intro'ed the Living Wage Now Act. cohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630240386991165440",
              text: "I authored the President's Billionaire Minimum Income Tax so those with the most pay the most.\n\nMeanwhile, Congressional Republicans want to slash taxes for the ultrarich and impose a massive national sales tax on everyone else.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1417141135185780738",
              text: "Putting Americans to work requires ensuring that people have everything they need to show up for a job.\n\nThat includes\u00a0affordable child care and paid family leave. No infrastructure plan is complete without them.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623702534148833282",
              text: "I am proud to have authored the Billionaire Minimum Income Tax Act that @POTUS highlighted. C\u2019mon man, pass the bill. Let me know if you agree.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1622961001921404928",
              text: "During the State of the Union, @POTUS will highlight our work to rebuild for the middle class and urge Congress to pass the Billionaire Minimum Tax I introduced. Thank you President Biden for fighting for families and helping ensure billionaires pay taxes like everyone else.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623143744575795202",
              text: "We created more jobs in the last two years than any other period in history and are building the economy from the bottom up and middle out, instead of from the top down.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1456709052189323267",
              text: "Since President Biden took office, we\u2019ve added 5.6 million jobs to the economy! This moment is an opportunity to invest in growth and make sure all of us \u2013 not just those at the top \u2013 are dealt in to share the benefits of a growing economy. #BuildBackBetter",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1352743707817111553",
              text: "On today's episode of The President Actually Cares About Working Americans:\n?Boost food stamps & school meal benefits by 15%\n?Speed up survival check delivery\n?Protect unemployment for people concerned about workplace COVID risks\n?Raise min wage to $15 for all federal workers",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1382806809711411203",
              text: "Based on today\u2019s wage gap, a woman working full-time would lose MORE THAN $400,000 over a 40-year career. We passed the Paycheck Fairness Act and must finally fulfill the promise of #EqualPay.",
            },
          ],
          question:
            "Do you have a plan to address income inequality and promote wage growth?",
        },
      ],
      summary:
        "Steve Cohen is steadfast in his commitment to stimulate job growth and reduce unemployment. His strategies include making it easier for people to get to work and get goods to market, establishing universal childcare and permanent funding for the Child Tax Credit, and incentivizing supermarkets to open in underserved areas and hire local residents.\n\nHe promotes robust support for small businesses and entrepreneurs as a significant part of boosting the economy. The measures he supports include the American Rescue Plan, Paycheck Protection Program, Restaurant Revitalization Fund, and reforms in the Small Business Administration. He is also proposing a bipartisan solution to incentivize supermarkets to serve underserved areas.\n\nCohen is strongly focused on addressing income inequality and promoting wage growth. His plans include introducing the Billionaire Minimum Income Tax and the Living Wage Now Act, raising the federal minimum wage to $15, ensuring a minimum wage of $15 for airport workers, advocating for affordable child care and paid family leave, and pushing for the Paycheck Fairness Act to ensure equal pay for women. His goal is to address income inequality and promote wage growth from the bottom up.",
    },
    "National Security": {
      questions: [
        {
          answer:
            "I believe that the safety and security of the nation must be balanced with the protection of individual privacy. As Chairman of the Subcommittee on the Constitution, Civil Rights, and Civil Liberties, I am committed to examining reforms to ensure no President can misuse or abuse emergency authorities. I have also introduced the #ProtectingOurDemocracy Act, the third pillar of our democracy agenda, to create a modern mechanism to enforce the 14th Amendment should the Attorney General prove before a 3-judge panel that a current or former officeholder engaged in insurrection. Additionally, I have introduced the Civil Rights Legacy Protection Act to help safeguard the legacy and memorials of the Civil Rights Movement, and the Housing Accountability Act with Senator Rubio to survey Section 8 tenants and hold landlords accountable for safe and livable housing. Finally, I have introduced a bill to hold local governments liable for officers who violate constitutional rights, creating a strong incentive for local governments to establish systems that prevent misconduct and protect citizens' rights. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1551010089275899904",
              text: "\u201cThe beauty of the system\u201d is how he describes states determining privacy of adults re:abortion,marriage between   different races,people of same sex,sex activities beyond missionary or manual, and contraception.Not beauty but horror show.Privacy rights should be enjoyed by all!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1526948741844029446",
              text: "Our democratic system is built on checks and balances. As Chairman of the Subcommittee on the Constitution, Civil Rights, and Civil Liberties, I continue to examine reforms to ensure no President can misuse or abuse emergency authorities. https://t.co/05L5IQci12",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1440708701191430154",
              text: "The\u00a0#ProtectingOurDemocracy\u00a0Act is the third pillar of our democracy agenda, along with\u00a0#HR1\u00a0and the\u00a0#JohnLewisVotingRightsAct. As Chair of the Constitution, Civil Rights & Civil Liberties Subcommittee, I authored much of this bill and will fight to pass this democracy safeguard.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1365410059531001859",
              text: "January 6 showed that concerns prompting the 14th Amendt\u2019s ratification remain relevant today.\n\nThis bill creates a modern mechanism to enforce this Constitutional provision should the AG prove before a 3-judge panel that a current or former officeholder engaged in insurrection.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1547990788956512256",
              text: "For many, their right to marriage is only held up by a thin thread in a few paragraphs written by the Supreme Court. Congress must codify that right and protect the nation from radical judicial overreach.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625622406491435009",
              text: "We must honor the memory of those who lost their lives to Jim Crow racism and the struggle for freedom. My Civil Rights Legacy Protection Act would help safeguard the legacy and memorials of the movement for Civil Rights.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1631024329566609410",
              text: "The Housing Accountability Act I reintroduced today with @SenRubioPress would survey Section 8 tenants so that residents can speak up about their living conditions and @HUDgov can hold landlords accountable for safe and livable housing.",
            },
          ],
          question:
            "How will you ensure the safety and security of the nation while respecting individual privacy?",
        },
        {
          answer:
            "I believe that counterterrorism efforts must be comprehensive and include both diplomatic and military approaches. I am committed to strengthening our cybersecurity protection by investing in the latest technology and training our personnel to stay ahead of the curve. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1491419986698866689",
              text: "Countering corruption and autocracy is critical to restoring America\u2019s global leadership. With the inclusion of major parts of my Counter-Kleptocracy Act, the #AmericaCOMPETESAct will root out corruption, invest in innovation and bolster U.S. competitiveness on the world stage.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1489703852475617280",
              text: "Proud that the House passed major pieces of my Counter-Kleptocracy Act as part of the #AmericaCOMPETESAct, which will strengthen global order and democracy, bolster American manufacturing, fix our supply chains, and invest in the next generation of cutting-edge technology.",
            },
          ],
          question:
            "What is your approach to counterterrorism efforts and cybersecurity protection?",
        },
        {
          answer:
            "I believe that strengthening intelligence agencies is critical to protecting our democracy and national security. That's why I've introduced the Counter-Kleptocracy Act with @RepJoeWilson, @RepMalinowski and @RepJohnCurtis. This bill would expose and purge the blood money fueling Putin and his cronies. I've also written to congressional leadership urging an immediate vote on this bill. Additionally, I've sent a letter with @RepJohnCurtis recommending @POTUS and @SecBlinken sanction the Deposit Insurance Agency to help fight Russian kleptocracy and protect dissidents. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1526682015344492550",
              text: "The Kremlin weaponized Russia\u2019s deposit insurance system to target opponents and shield sanctioned oligarchs, so I sent a letter with @RepJohnCurtis recommending @POTUS and @SecBlinken sanction the Deposit Insurance Agency to help fight Russian kleptocracy and protect dissidents.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1470522118177759236",
              text: "All but one of my counter-corruption provisions were booted from the final version of the NDAA after we passed them in the House.\n\nKleptocracy is a threat to democracy and good government all over the world. Defending democracy should be part of any defense bill.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497642507177443334",
              text: "I\u2019ve written to congressional leadership urging an immediate vote on the Counter-Kleptocracy Act I introduced with @RepJoeWilson, @RepMalinowski and @RepJohnCurtis. We must expose and purge the blood money fueling Putin and his cronies.",
            },
          ],
          question:
            "Are there specific measures to strengthen intelligence agencies that you endorse?",
        },
      ],
      summary:
        "Steve Cohen believes in a balanced approach to national security that takes into account both the protection of the nation and the safeguarding of individual privacy rights. He supports strong counterterrorism efforts, both diplomatic and military, and acknowledges the need for robust cybersecurity measures. As part of his commitment to enhancing national security, Cohen has prioritized strengthening intelligence agencies. He has introduced the Counter-Kleptocracy Act aimed at targeting and disrupting the financial systems that support autocratic regimes. His actions also include urging an immediate vote on this bill and recommending sanctions related to Russian kleptocracy. His stance on national security emphasizes both proactive measures and strategic responses.",
    },
    Taxes: {
      questions: [
        {
          answer:
            "I believe that everyone should pay their fair share of taxes, regardless of their income level. That's why I authored the President's Billionaire Minimum Income Tax, which ensures that those with the most pay the most. I also believe that corporations should be taxed more like people, and that they should shoulder most of the cost of the American Jobs Plan. Democrats have already enacted an historic middle class tax cut, and we are working to pass my minimum billionaire tax. We want to tax billionaire corporations and the ultrarich, while House Republicans want to impose a massive national sales tax on everyone else. I'm proud to have introduced the Billionaire Minimum Income Tax Act, and I'm pleased to see it included in the President's new budget. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630240386991165440",
              text: "I authored the President's Billionaire Minimum Income Tax so those with the most pay the most.\n\nMeanwhile, Congressional Republicans want to slash taxes for the ultrarich and impose a massive national sales tax on everyone else.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1388213902736076800",
              text: "If corporations are people, as Citizens United suggests, let\u2019s tax them more like people. And as corporations will get most of the benefits of the #AmericanJobsPlan they should also shoulder most of the cost.\nnewrepublic.com/article/162201\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1629219421871824896",
              text: "House Democrats want to tax billionaire corporations and the ultrarich.\n\nHouse Republicans want every American to pay 30% more sales tax on all purchases from gas to groceries to medications while eliminating income taxes for billionaires\u2019 advantage.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623702534148833282",
              text: "I am proud to have authored the Billionaire Minimum Income Tax Act that @POTUS highlighted. C\u2019mon man, pass the bill. Let me know if you agree.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1438211852979298304",
              text: "The middle class is sick of giving tax handouts to the rich.\n\u00a0\nDemocrats are on the verge of passing one of the biggest middle class tax cuts in history, paid for by making those at the very top pay their fair share.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1559887468983353345",
              text: "While billionaires blew trillions of dollars on spaceships and superyachts, working families footed most of the tax bill. That's why I led the introduction of @POTUS\u2019s Billionaire Minimum Income Tax so billionaires play by the same rules as everyone else!\nnewsweek.com/we-can-make-su\u2026",
            },
          ],
          question:
            "What is your position on tax reform, including individual and corporate taxes?",
        },
        {
          answer:
            "We must close the carried interest loophole and pass the President's Billionaire Minimum Income Tax to ensure the ultra-wealthy pay their fair share. We must also curb the enablers of tax evasion by introducing the ENABLERS Act. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1612850881992560652",
              text: "Only Republicans in Washington would use their first day to help the wealthy and well-connected evade taxes by slashing funds for audits on people like Donald Trump whose income is reported by lawyers and accountants, unlike everyday Americans whose taxes are mostly withheld.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492276034724638725",
              text: "Any questions or concerns about your taxes? Join me and local taxpayer advocates next Thursday to discuss newly expanded tax credits, free tax prep resources, and how to file your returns and maximize refunds.\n\nReply with your questions or send them to Cohen.Press@mail.house.gov! pic.twitter.com/1VhqXVkymr",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1612534640627032080",
              text: "For their first acts, House Republicans seek to gut the Office of Congressional Ethics and defund enforcement for ultrawealthy tax cheats.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1402656573755957256",
              text: "Just unbelievable that everyday Americans pay honest taxes on wages while the wealthiest 0.001% skirt taxes on their skyrocketing fortunes. It\u2019s time billionaires pay their fair share. pic.twitter.com/XU19JnKbjS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600507317795037185",
              text: "If we can put a man on the moon, we can figure out how to make billionaires pay at least the same tax rate as schoolteachers and firefighters.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1612604698426769408",
              text: "House Republicans campaigned on lowering inflation for working people, but instead their first piece of legislation rewards their wealthy and well-connected donors by making it easier for them to cheat on taxes.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1387475232563372036",
              text: "Biden's #AmericanFamiliesPlan will reverse the Trump tax scam and ensure the ultrarich play by the same rules.\n \nI am pleased it closes the carried interest loophole -- a tax giveaway for hedge fund owners that I've worked to eliminate with @RepLloydDoggett and @rosadelauro.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1634233535698485249",
              text: "For too long the ultra-wealthy have not paid their fair share in taxes while the middle class continues to scrape by. \n\nI\u2019m pleased to see the billionaire minimum income tax is included in @POTUS' new budget, which I introduced last Congress. pic.twitter.com/Yj3nTUWB1a",
            },
          ],
          question:
            "How would you address concerns about tax evasion and tax loopholes?",
        },
        {
          answer:
            "We need to prioritize domestic programs like housing, nutrition, health care, childcare and other essential services. We must also reset our priorities when it comes to defense spending and end taxpayer-funded handouts to fossil fuel companies. We must invest in human needs and make more vaccines, less bombs. We must also invest in infrastructure, elderly care, internet access, modernizing our electric grid, expanding the child care system, and replacing lead pipes. Finally, we must make the largest climate investments in history to generate clean, secure, American energy. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1441239207469486085",
              text: "A bloated defense budget comes at the expense of our security. Housing, nutrition, health care, childcare and other domestic programs are vital to the security of American families and should be our priority.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600937579498868736",
              text: "We can't keep writing blank checks for the Pentagon notorious for its wasteful spending on obsolete, unnecessary programs. I voted against the $858 billion defense bill because it is just too much when we have so many unmet domestic needs. We need to reset our priorities.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1384183138398588935",
              text: "We spend more on defense than the next 10 countries combined, yet our citizens cannot afford their own heath care. It\u2019s time we get the bloated defense budget under control and reinvest in people here at home.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1622669448619466790",
              text: "House Republicans want to hold the economy hostage unless they get cuts to essential programs like Medicare, Medicaid, Social Security, and SNAP. Absolutely not.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1379460156723986433",
              text: "Long before COVID, I made it known that disease is the greatest enemy of the American people. Our military budget dwarfs spending on health care and other social programs. Invest that money in human needs and make more vaccines, less bombs.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1471887110466686977",
              text: "We must boldly tackle climate change and a green energy future. For a start, end taxpayer-funded handouts to fossil fuel companies.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1387584812026900480",
              text: "I agree with @POTUS's vision that \u2018infrastructure\u2019 is more than roads and bridges. We must include help for our growing elderly population, for internet access in rural and urban areas, for modernizing our electric grid, expanding the child care system, and replacing lead pipes.",
            },
          ],
          question:
            "What government spending priorities would you emphasize to ensure a balanced budget?",
        },
      ],
      summary:
        "Steve Cohen strongly supports tax reform that ensures everyone, especially wealthy individuals and corporations, pay their fair share. He is the author of the President's Billionaire Minimum Income Tax, which proposes the ultra-rich should bear the majority of tax obligations. He also believes corporations should be taxed similarly to individuals. He opposes a national sales tax as he feels it would disproportionately affect non-wealthy individuals. To address tax evasion and tax loopholes, Cohen advocates for the closure of the carried interest loophole and the introduction of the ENABLERS Act. His spending priorities to maintain a balanced budget emphasize domestic programs, reducing defense spending, cutting subsidies for fossil fuel companies, and making significant investments in climate solutions.",
    },
  },
  "Steve Cohen": {
    Abortion: {
      questions: [
        {
          answer:
            "I believe that women should have the right to make decisions about their own bodies and lives. I strongly oppose any attempts to restrict access to abortion services, birth control, and other reproductive care. I am a proud supporter of the Women\u2019s Health Protection Act, which would codify the right to bodily autonomy and ensure that women have access to the care they need. I also believe that the government should not be involved in private matters such as abortion, marriage, and contraception. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1541821201835872261",
              text: "With\u00a0Roe overturned, I want everyone to have appropriate information about your existing rights and available resources for abortion services, birth control and other reproductive care. Visit\u00a0reproductiverights.gov.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1522295499373568002",
              text: "Around the world, countries are increasingly legalizing abortion, including in mostly Catholic nations. Meanwhile, in the U.S. unelected judges seem to be going in just the opposite direction. Expand the court and codify the right to bodily autonomy!\napnews.com/article/us-sup\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1484904435910279173",
              text: "Even *with* #RoevWade, abortions are largely unavailable depending on where you live and how much you make. If Roe is overturned, half the country will be even worse off. That\u2019s why my colleagues must join me to call for a YES VOTE on the Women\u2019s Health Protection Act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1547953632569483265",
              text: 'In the hardcore red states of the South\u2014once known as the Confederacy\u2014all but one or two states will ban abortion entirely. These are the same states that opposed the Civil Rights Act. "States\u2019 rights" was always a red herring for slavery and segregation. Now it\u2019s women\u2019s rights. https://t.co/Rk9LCYdYsb',
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1540366745650831363",
              text: "The radical Supreme Court overturned Roe which empowered women to make decisions about their own lives and bodies. Women will march and march and vote and vote\u2014and all who believe in the equality and self-determination of women won't stop fighting until these rights are restored.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1524380141094424582",
              text: "Women deserve to control their own bodies, not the government.\u00a0This is 2022, not the Dark Ages of 1235, whether Justice Alito understands that or not. And this is why you have to VOTE.",
            },
          ],
          question:
            "Where do you stand on the legality of abortion, and what restrictions, if any, do you support?",
        },
        {
          answer:
            "Absolutely. I'm a strong supporter of the Women's Health Protection Act, which would ensure that women have access to the reproductive health care they need, regardless of where they live. I'm also a strong supporter of the Inflation Reduction Act, which would reduce healthcare costs and lower energy costs. I'm also a strong supporter of affordable healthcare for all, so that no one has to lose everything because of one medical diagnosis. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1484904435910279173",
              text: "Even *with* #RoevWade, abortions are largely unavailable depending on where you live and how much you make. If Roe is overturned, half the country will be even worse off. That\u2019s why my colleagues must join me to call for a YES VOTE on the Women\u2019s Health Protection Act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1541821201835872261",
              text: "With\u00a0Roe overturned, I want everyone to have appropriate information about your existing rights and available resources for abortion services, birth control and other reproductive care. Visit\u00a0reproductiverights.gov.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1490698707729268741",
              text: "Call me a radical, but I don\u2019t think those who lift themselves out of poverty should lose everything because of one medical diagnosis. We need affordable health care for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1522295499373568002",
              text: "Around the world, countries are increasingly legalizing abortion, including in mostly Catholic nations. Meanwhile, in the U.S. unelected judges seem to be going in just the opposite direction. Expand the court and codify the right to bodily autonomy!\napnews.com/article/us-sup\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1545443554717306880",
              text: "President Biden is signing an Executive Order designed to safeguard access to abortion and protect women from states trying to criminalize their health care. It\u2019s an important step, but the Senate must end the filibuster to protect the right to abortion.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600522145280495620",
              text: "Overwhelming majorities of Republican voters support policies that Democratic lawmakers have long prioritized. I hope my GOP colleagues will join me in passing popular, commonsense ideas that voters on both sides of the aisle agree on. pic.twitter.com/cRvYtXIIlS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1547953632569483265",
              text: 'In the hardcore red states of the South\u2014once known as the Confederacy\u2014all but one or two states will ban abortion entirely. These are the same states that opposed the Civil Rights Act. "States\u2019 rights" was always a red herring for slavery and segregation. Now it\u2019s women\u2019s rights. https://t.co/Rk9LCYdYsb',
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1524380141094424582",
              text: "Women deserve to control their own bodies, not the government.\u00a0This is 2022, not the Dark Ages of 1235, whether Justice Alito understands that or not. And this is why you have to VOTE.",
            },
          ],
          question:
            "Are there healthcare policies you support to provide alternatives to abortion?",
        },
      ],
      summary:
        "Steve Cohen strongly believes that women should have the right to make decisions about their own bodies and lives, including their right to access abortion services. He is an active supporter of the Women's Health Protection Act, aimed at codifying the right to bodily autonomy and ensuring that women have access to necessary care. He believes in maintaining a strong separation between government interference and private matters such as abortion, marriage, and contraception. He also supports various healthcare policies such as the Inflation Reduction Act, aimed at making healthcare more affordable for all. His stance is to provide alternatives to abortion through the provision of comprehensive reproductive health care.",
    },
    "Civil Liberties": {
      questions: [
        {
          answer:
            "As Chairman of the Subcommittee on the Constitution, Civil Rights, and Civil Liberties, I am committed to protecting individual civil liberties, including freedom of speech and privacy. To ensure no President can misuse or abuse emergency authorities, I am examining reforms to our democratic system. I have also introduced the Free Speech Protection Act to deter powerful interests from filing bad-faith lawsuits to intimidate journalists and critics into silence. Additionally, I have introduced the Civil Rights Legacy Protection Act to help safeguard the legacy and memorials of the movement for Civil Rights. Furthermore, I have introduced the Latonya Reeves Freedom Act to affirm people with disabilities' right to receive services wherever they choose, including their homes. Finally, I have a bill to implement Article 14 section 3 which was passed after the Civil War to bar insurrectionists who violated their oath of office from holding office again. With Roe overturned, I want everyone to have appropriate information about their existing rights and available resources for abortion services, birth control and other reproductive care. \n",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1526948741844029446",
              text: "Our democratic system is built on checks and balances. As Chairman of the Subcommittee on the Constitution, Civil Rights, and Civil Liberties, I continue to examine reforms to ensure no President can misuse or abuse emergency authorities. https://t.co/05L5IQci12",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1551010089275899904",
              text: "\u201cThe beauty of the system\u201d is how he describes states determining privacy of adults re:abortion,marriage between   different races,people of same sex,sex activities beyond missionary or manual, and contraception.Not beauty but horror show.Privacy rights should be enjoyed by all!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1579482648762011648",
              text: "Powerful interests including Russian oligarchs use defamation suits to intimidate journalists and critics into silence. We can't let our courts be weaponized by the highest bidders. I introduced the Free Speech Protection Act to deter the powerful from filing bad-faith lawsuits.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625622406491435009",
              text: "We must honor the memory of those who lost their lives to Jim Crow racism and the struggle for freedom. My Civil Rights Legacy Protection Act would help safeguard the legacy and memorials of the movement for Civil Rights.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1498393705174208518",
              text: "The Latonya Reeves Freedom Act I introduced today with @RepYvetteClarke is a major civil rights bill, which affirms people with disabilities' right to receive services wherever they choose, including their homes. Passing it will help ensure all are free to lead independent lives.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1538502416080982019",
              text: "@Tracy_username I have bill to implement Article 14 section 3 which was passed after Civil War to bar insurrectionists who violated oath of office from holding office again. Have pushed for a mark up.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1547990788956512256",
              text: "For many, their right to marriage is only held up by a thin thread in a few paragraphs written by the Supreme Court. Congress must codify that right and protect the nation from radical judicial overreach.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1541821201835872261",
              text: "With\u00a0Roe overturned, I want everyone to have appropriate information about your existing rights and available resources for abortion services, birth control and other reproductive care. Visit\u00a0reproductiverights.gov.",
            },
          ],
          question:
            "What safeguards will you put in place to protect individual civil liberties, including freedom of speech and privacy?",
        },
        {
          answer:
            "I believe that privacy rights should be enjoyed by all, which is why I introduced the National Statistics on Deadly Force Transparency Act to track the use of deadly force. I'm also pleased that the President mentioned the Justice Department's new database for police misconduct. Accurate data helps us honestly address our justice system. I'm committed to protecting the privacy of all citizens and will continue to work to ensure that our government is transparent and accountable. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1551010089275899904",
              text: "\u201cThe beauty of the system\u201d is how he describes states determining privacy of adults re:abortion,marriage between   different races,people of same sex,sex activities beyond missionary or manual, and contraception.Not beauty but horror show.Privacy rights should be enjoyed by all!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1565001784443158533",
              text: "Pleased that @POTUS mentioned @TheJusticeDept\u2019s new database for police misconduct. Accurate data helps us honestly address our justice system. It's why I introduced the National Statistics on Deadly Force Transparency Act to track the use of deadly force whitehouse.gov/briefing-room/\u2026",
            },
          ],
          question:
            "How do you plan to address concerns about government surveillance and data privacy?",
        },
        {
          answer:
            "Yes, I am committed to protecting civil liberties in the digital age. I have introduced the Free Speech Protection Act to deter powerful interests from filing bad-faith lawsuits to intimidate journalists and critics into silence. I have also introduced the Civil Rights Legacy Protection Act to help safeguard the legacy and memorials of the Civil Rights Movement. Additionally, I have introduced the Latonya Reeves Freedom Act to affirm people with disabilities' right to receive services wherever they choose, including their homes. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1579482648762011648",
              text: "Powerful interests including Russian oligarchs use defamation suits to intimidate journalists and critics into silence. We can't let our courts be weaponized by the highest bidders. I introduced the Free Speech Protection Act to deter the powerful from filing bad-faith lawsuits.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1481699413328285698",
              text: "Half of the successful filibusters between 1917 and 1994 were civil rights legislation. The Jim Crow filibuster is still holding back voting rights today.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1498393705174208518",
              text: "The Latonya Reeves Freedom Act I introduced today with @RepYvetteClarke is a major civil rights bill, which affirms people with disabilities' right to receive services wherever they choose, including their homes. Passing it will help ensure all are free to lead independent lives.",
            },
          ],
          question:
            "Are there initiatives to strengthen civil liberties protections in the digital age that you support?",
        },
      ],
      summary:
        "Steve Cohen is committed to protecting civil liberties, asserting the importance of freedom of speech and privacy. As Chairman of the Subcommittee on the Constitution, Civil Rights, and Civil Liberties, he introduced several bills aimed at safeguarding these rights. These include the Free Speech Protection Act, which aims to deter bad-faith lawsuits aimed at silencing journalists and critics; the Civil Rights Legacy Protection Act, to protect the legacy and memorials of the Civil Rights Movement; and the Latonya Reeves Freedom Act to affirm the right of persons with disabilities to receive services at a place of their choosing. \n\nOn the topic of data privacy, Cohen introduced the National Statistics on Deadly Force Transparency Act, designed to track the use of deadly force, and he is supportive of the President's push for a new database on police misconduct. He is in support of initiatives that protect civil liberties in the digital age, once again citing the Free Speech Protection Act, amongst others.",
    },
    "Civil Rights": {
      questions: [
        {
          answer:
            "I am proud to have fought for decades for marriage equality and racial justice, and I am committed to continuing this fight. I have voted to protect the right to marry regardless of race, gender, or sexual orientation, and I am leading a bipartisan group of more than a dozen of my colleagues urging the Department of Justice to reinstate critical ADA-related guidance that the Trump Administration rescinded. I am also proud to continue my service as a senior member on the House Judiciary Committee and the fight for justice and equality under the law. I am sponsoring the Latonya Reeves Freedom Act, which affirms people with disabilities' right to receive services wherever they choose, including their homes. I am also sponsoring the End Forced Arbitration Act, which puts the power in survivors\u2019 hands when it comes to sexual harassment and assault. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600933083435663360",
              text: "Having fought for decades for marriage equality and racial justice, I'm proud to help send the #RespectForMarriageAct to the President\u2019s desk. An historic step forward defending every person\u2019s freedom to be themselves, love who they love, and marry the person of their choosing!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1493596191862501388",
              text: "The ADA guarantees equal opportunities for individuals with disabilities, which is why I'm leading a bipartisan group of more than a dozen of my colleagues urging @TheJusticeDept to reinstate critical ADA-related guidance that the Trump Administration rescinded. pic.twitter.com/dlD5otVWic",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1549472823814545409",
              text: "I voted today to protect the right to marry regardless of race, gender or sexual orientation. Republican politicians who voted against this basic human right should never speak the word \u201cfreedom\u201d again. To paraphrase . . .until we are all equal, none of us is equal. https://t.co/TF7jLpxAb4",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1620564729541570560",
              text: "(2/2) I am also proud to continue my service as a senior member on the @HouseJudiciary Committee and the fight for justice and equality under the law.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1491111248146362370",
              text: "From your apartment lease to a rideshare app\u2019s terms and conditions \u2013 forced arbitration clauses are everywhere, protecting those in power. But when it comes to sexual harassment & assault, the #EndForcedArbitration Act puts the power in survivors\u2019 hands.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1633920065811763200",
              text: "Judy Heumann was a trailblazer for disability rights. Her 24-day sit-in in 1977 encouraged the Carter admin to implement sec 504 of the Rehabilitation Act, outlawing discrimination against disabled people by institutions receiving federal funds. (1/4) \nnytimes.com/2023/03/05/obi\u2026",
            },
          ],
          question:
            "What actions would you take to advance LGBTQ+ rights and protections against discrimination?",
        },
        {
          answer:
            "We must prioritize diversity and inclusion in all sectors, including employment and housing, to ensure equal opportunity for all. This means passing legislation like the Housing Accountability Act, which I recently reintroduced with Senator Rubio, to survey Section 8 tenants and hold landlords accountable for safe and livable housing. We must also expand workforce development and training programs to all the untapped talent in our region, including our high schools, community colleges, and HBCUs. Additionally, we must continue the work of Dr. Martin Luther King Jr. to protect voting rights, eliminate poverty, end unfair policing practices, expand healthcare access, and ensure workers\u2019 right to organize. Finally, we must ensure that legislation to manage our water resources includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630585774147076099",
              text: "Accelerated learning programs like AP and GT propel students toward greater academic success, but many students are overlooked or underrepresented in these programs. Prioritizing diversity and inclusion is crucial to ensuring equal opportunity for all.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1631024329566609410",
              text: "The Housing Accountability Act I reintroduced today with @SenRubioPress would survey Section 8 tenants so that residents can speak up about their living conditions and @HUDgov can hold landlords accountable for safe and livable housing.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1648786696144580614",
              text: "There's a lot more we need to do to expand workforce development and training programs to all the untapped talent in our region\u2014our high schools, community colleges, and our HBCUs. There is huge demand for pilots & aviation professionals right now, and these students can fill it!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1633918271568183299",
              text: "The Rehabilitation Act paved the way for the Americans with Disabilities Act, which requires that people with disabilities have the same opportunities for education, employment, commercial activities, and participation in state and local government programs. (2/4)",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572685346466398212",
              text: "A person\u2019s employment, housing and education can all be denied on the basis of a past conviction. Allowing those who made a mistake and have paid their debt to society to wipe the slate clean and have a fresh start is essential to building a more just criminal legal system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1483106476151681025",
              text: "We must continue MLK\u2019s work to protect voting rights, eliminate poverty, end unfair policing practices, expand healthcare access, and ensure workers\u2019 right to organize. As he said in Memphis the night before he was killed, \u201cWe have an opportunity to make America a better nation.\u201d",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
          ],
          question:
            "How can you promote racial and gender equality in various sectors, including employment and housing?",
        },
        {
          answer:
            "I am committed to protecting voting rights and access to the ballot. I have introduced the John Lewis Voting Rights Act and Freedom to Vote Act to ensure that all Americans have the right to vote and that their votes are counted. I am also pushing for the Senate to end the filibuster so that the elected majority can protect voting rights, reproductive freedom, reasonable gun laws and justice for all. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1481355244235898885",
              text: "State legislatures are restricting the right to vote with simple majorities, while the Senate\u2019s antiquated rules mean we need a supermajority to protect it. The filibuster can\u2019t stand in the way of protecting voting rights.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600522145280495620",
              text: "Overwhelming majorities of Republican voters support policies that Democratic lawmakers have long prioritized. I hope my GOP colleagues will join me in passing popular, commonsense ideas that voters on both sides of the aisle agree on. pic.twitter.com/cRvYtXIIlS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1486402556997750784",
              text: "It seems many on the other side of the aisle have come to the cynical conclusion that it no longer pays to protect voting rights. They seem to believe that they can only win if they suppress the right to vote for certain Americans. https://t.co/wIXiEQnKK4",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1486002751293243395",
              text: "We find our democracy on the precipice not only because of the Senate\u2019s procedural anachronism, but because one party has decided to reverse its historic support for strong voting rights protection and chosen voter suppression as a political strategy. https://t.co/vyUeuBzY3k",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1487073502712803328",
              text: "In 2022, we\u2019re talking about more than Republicans enacting obstacles to voting. They are systematically closing polling locations in minority communities and changing election laws that could allow partisan actors to interfere with vote counts and even overturn results.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1481017855013539841",
              text: 'In the hometown of John Lewis, @POTUS makes clear that the Senate must protect the right to vote \u2014 and the filibuster can\'t stand in the way.\n \nAs John Lewis said: "The vote is precious. It is almost sacred. It is the most powerful non-violent tool we have in a democracy."',
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1478768839718543362",
              text: "Extremists attacked our democracy at the Capitol with arms and are attacking our democracy in the states with voter suppression. The Senate must overcome the filibuster and protect our democracy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1479214901201678339",
              text: "People who seek power by trying to overturn elections cannot be trusted to preserve, protect and defend the Constitution of the United States.\nMy bill #HR1405 to prevent those who engaged in insurrection from holding public office needs your support.\nthehill.com/policy/nationa\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1481699413328285698",
              text: "Half of the successful filibusters between 1917 and 1994 were civil rights legislation. The Jim Crow filibuster is still holding back voting rights today.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1541805009716875265",
              text: "With just two more Democratic Senators, we could end the filibuster and legislate the right to abortion, protect against gun violence, and safeguard democracy. If we fall short, the Supreme Court will continue to turn back the clock and radically reshape America.",
            },
          ],
          question:
            "What policies do you support to protect voting rights and access to the ballot?",
        },
      ],
      summary:
        "Steve Cohen is deeply invested in protecting and promoting civil rights. He is committed to LGBTQ+ rights and has worked to advance protections against discrimination, including pushing for marriage equality. \n\nRacial and gender equality are also prioritized in his policy considerations, especially in areas such as employment and housing. He supports passing legislation like the Housing Accountability Act to ensure safe and livable housing and is focused on developing training programs to facilitate diversity and inclusion.\n\nIn terms of voting rights, Cohen has introduced policies such as the John Lewis Voting Rights Act and the Freedom to Vote Act to protect every American's right to vote. He is also advocating for the Senate to abandon the filibuster in order to pass legislation that protects voting rights, reproductive freedom, moderate gun laws, and justice for all.\n\nFinally, he is also working towards protecting the rights of people with disabilities, survivors of sexual assault and harassment, and vulnerable communities affected by environmental injustice.",
    },
    Crime: {
      questions: [
        {
          answer:
            "I believe that criminal justice reform must focus on ending unfair sentencing practices that violate due process and contribute to mass incarceration. We must also end sentencing disparities that have targeted communities of color, and provide additional funding to address violent crime in places where the rate is significantly above the national average. We must also ensure that those who have made mistakes and paid their debt to society can wipe the slate clean and have a fresh start. This includes providing access to support services in their chosen setting, and allowing for the expungement of certain nonviolent offenses. Finally, we must continue to work to protect voting rights, eliminate poverty, end unfair policing practices, expand healthcare access, and ensure workers\u2019 right to organize. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1508634175003402248",
              text: "Tonight the House passed my criminal justice reform bill to once and for all end an unfair sentencing practice that violates due process and has contributed to mass incarceration.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572685346466398212",
              text: "A person\u2019s employment, housing and education can all be denied on the basis of a past conviction. Allowing those who made a mistake and have paid their debt to society to wipe the slate clean and have a fresh start is essential to building a more just criminal legal system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1603890607604174848",
              text: "Ending sentencing disparities reverses a misguided War on Drugs policy that targeted communities of color. \n\nKudos to @RepJeffries who sponsored the Equal Act to end the disparity and to Attorney General Garland for taking action when the Senate failed to move the bill we passed.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1484194360824586240",
              text: "The Safer Streets Act I\u2019m reintroducing today is intended to provide additional funding to address violent crime in places where the rate is significantly above the national average \u2013 places like Memphis.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492188865540591616",
              text: "In America, even a nonviolent offense can be a life sentence. That's because the stigma of a conviction will follow you for the rest of your life. Employment, education, and housing\u2013the very things necessary for a fresh start\u2013can all be denied on the basis of a past conviction.",
            },
          ],
          question:
            "How would you approach criminal justice reform to reduce mass incarceration and address racial disparities?",
        },
        {
          answer:
            "I am committed to making my community safer by fighting crime and violence, while also promoting fairness and equity. To do this, I am working to pass life-saving legislation that will help protect our communities, while also prioritizing diversity and inclusion in accelerated learning programs like AP and GT. I have also reintroduced the Housing Accountability Act with Senator Rubio to survey Section 8 tenants and hold landlords accountable for safe and livable housing. Finally, I am continuing the work of Dr. Martin Luther King Jr. to protect voting rights, eliminate poverty, end unfair policing practices, expand healthcare access, and ensure workers\u2019 right to organize. Together, we can make America a better nation. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1567581487934963714",
              text: "I am working to make my community safer, but MAGA Republicans have sided with the gun lobby to block life-saving legislation at every turn. We won\u2019t back down in fighting crime and violence. #EndGunViolence",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630585774147076099",
              text: "Accelerated learning programs like AP and GT propel students toward greater academic success, but many students are overlooked or underrepresented in these programs. Prioritizing diversity and inclusion is crucial to ensuring equal opportunity for all.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1631024329566609410",
              text: "The Housing Accountability Act I reintroduced today with @SenRubioPress would survey Section 8 tenants so that residents can speak up about their living conditions and @HUDgov can hold landlords accountable for safe and livable housing.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1483106476151681025",
              text: "We must continue MLK\u2019s work to protect voting rights, eliminate poverty, end unfair policing practices, expand healthcare access, and ensure workers\u2019 right to organize. As he said in Memphis the night before he was killed, \u201cWe have an opportunity to make America a better nation.\u201d",
            },
          ],
          question:
            "What strategies will you employ to enhance community safety while promoting fairness and equity?",
        },
        {
          answer:
            "Absolutely. I'm committed to addressing the root causes of crime and have sponsored several law enforcement reforms in the George Floyd Justice and Policing Act. I've also advocated for those bills for years and recently reintroduced the #FreshStartAct, which provides for the expungement of certain nonviolent offenses. This allows those who have already served their sentences to wipe the slate clean and have a fresh start. I'm also working to make my community safer by pushing for life-saving legislation to end gun violence. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1629846172394201089",
              text: "@marceld21 I to am very  concerned about crime. I\u2019ve done everything a Congressman can to try to help our city get federal aid. i\u2019ve talked to justice. I\u2019ve written Merrick Garland and I\u2019ve talked to President Biden written him too. I take a backseat to no one on pushing for anti crime $\u2019s.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1618730965316308992",
              text: "@mrmosesdavis @farmerFRENCHIE @BrokenSubstack Sponsored several law enforcement reforms in the George Floyd Justice and Policing Act. \nFurther I\u2019d advocated for those bills for years .",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492188867478315008",
              text: "This week I reintroduced the #FreshStartAct, which provides for the expungement of certain nonviolent offenses. Allowing those who made mistakes earlier in their lives, and already served their sentences, to wipe the slate clean is essential to creating a more just system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572685346466398212",
              text: "A person\u2019s employment, housing and education can all be denied on the basis of a past conviction. Allowing those who made a mistake and have paid their debt to society to wipe the slate clean and have a fresh start is essential to building a more just criminal legal system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1635785533891792896",
              text: "Pleased to see @POTUS take further action to curb gun violence.\n\u00a0\nThe American people have made it clear they want comprehensive reform, and I will continue to fight for it in Congress.\ncnbc.com/2023/03/14/wat\u2026",
            },
          ],
          question:
            "Are there specific initiatives for addressing the root causes of crime that you champion?",
        },
      ],
      summary:
        "Steve Cohen is focused on addressing the root causes of crime, promoting fairness and equity, and reducing mass incarceration and racial disparities. He champions criminal justice reform, with specific attention to ending unfair sentencing practices that lead to mass incarceration and disproportionately affect communities of color. He is committed to community safety and advocates for life-saving legislation to combat crime and violence. Cohen supports initiatives such as the George Floyd Justice and Policing Act, aimed at reforming law enforcement practices. He also reintroduced the #FreshStartAct, which seeks to expunge certain nonviolent offenses, allowing individuals who have paid their societal dues to start afresh. Furthermore, Cohen is active in legislative efforts to reduce gun violence.",
    },
    Education: {
      questions: [
        {
          answer:
            "I'm committed to improving the quality of public education and increasing access to higher education. To do this, I'm working to expand workforce development and training programs to all the untapped talent in our region, including our high schools, community colleges, and HBCUs. I'm also prioritizing diversity and inclusion in accelerated learning programs like AP and GT to ensure equal opportunity for all. Additionally, I'm fighting to make sure our public schools don't become an ideological battleground and that our teachers are paid fairly. I'm also leading the push to ensure upcoming legislation to manage our water resources includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities. Finally, I'm working to make sure people with disabilities have the same opportunities for education, employment, commercial activities, and participation in state and local government programs. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1648786696144580614",
              text: "There's a lot more we need to do to expand workforce development and training programs to all the untapped talent in our region\u2014our high schools, community colleges, and our HBCUs. There is huge demand for pilots & aviation professionals right now, and these students can fill it!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1576931977436897282",
              text: "We don\u2019t want our public schools to become an ideological battleground. We want to supply our classrooms, pay our teachers, and prepare our children for the future.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630585774147076099",
              text: "Accelerated learning programs like AP and GT propel students toward greater academic success, but many students are overlooked or underrepresented in these programs. Prioritizing diversity and inclusion is crucial to ensuring equal opportunity for all.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572966782545137672",
              text: "We should be developing students\u2019 critical reasoning and thinking skills so they can succeed in college and work, NOT empowering Republican politicians to dictate what kids read and learn.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625943061250379776",
              text: "Democrats are rebuilding America by fixing our roads and bridges and bringing clean water and high-speed internet to more communities. And we are making the largest climate investments in history to generate clean, secure, American energy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1633918271568183299",
              text: "The Rehabilitation Act paved the way for the Americans with Disabilities Act, which requires that people with disabilities have the same opportunities for education, employment, commercial activities, and participation in state and local government programs. (2/4)",
            },
          ],
          question:
            "How will you improve the quality of public education and increase access to higher education?",
        },
        {
          answer:
            "I'm proud to have cosponsored @RepPeterDeFazio's bill to remove the indefensible cap on Social Security taxes so millionaires pay a fair share to fully fund Social Security through the 21st century and increase benefits. Additionally, I'm hosting a webinar tomorrow to discuss the Public Service Loan Forgiveness program that President Biden recently expanded. I'm also proud to have authored the Billionaire Minimum Income Tax Act that President Biden highlighted. These measures will help lower costs for millions of working Americans who make less than $125k per year and need it most, without spending more on those who can afford it. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1562546145745784833",
              text: "Today\u2019s targeted student debt relief will lower costs for millions of working Americans who make less than $125k per year and need it most, without spending more on those who can afford it. \n \nwashingtonpost.com/education/2022\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1535355305391005701",
              text: "Proud to cosponsor @RepPeterDeFazio\u2019s bill to remove the indefensible cap on Social Security taxes so millionaires pay a fair share to fully fund Social Security through the 21st century and increase benefits \u2014 all without raising taxes by one penny on over 93% of Americans.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572685346466398212",
              text: "A person\u2019s employment, housing and education can all be denied on the basis of a past conviction. Allowing those who made a mistake and have paid their debt to society to wipe the slate clean and have a fresh start is essential to building a more just criminal legal system.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623702534148833282",
              text: "I am proud to have authored the Billionaire Minimum Income Tax Act that @POTUS highlighted. C\u2019mon man, pass the bill. Let me know if you agree.",
            },
          ],
          question:
            "What measures do you endorse to address the issue of student loan debt?",
        },
        {
          answer:
            "Absolutely! I prioritize policies that support teachers and improve educational outcomes. We need to ensure that our teachers have the resources they need to provide a quality education to our students. We also need to prioritize diversity and inclusion in our classrooms, so that all students have the opportunity to succeed. Additionally, I have spearheaded a 6+ billion dollar educational initiative to expand workforce development and training programs to all the untapped talent in our region. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1576931977436897282",
              text: "We don\u2019t want our public schools to become an ideological battleground. We want to supply our classrooms, pay our teachers, and prepare our children for the future.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1589667244249956354",
              text: "We don\u2019t want politics in the classroom. We want to educate our nation\u2019s future leaders and ensure the success of children, teachers and staff.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630585774147076099",
              text: "Accelerated learning programs like AP and GT propel students toward greater academic success, but many students are overlooked or underrepresented in these programs. Prioritizing diversity and inclusion is crucial to ensuring equal opportunity for all.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572966782545137672",
              text: "We should be developing students\u2019 critical reasoning and thinking skills so they can succeed in college and work, NOT empowering Republican politicians to dictate what kids read and learn.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1648786696144580614",
              text: "There's a lot more we need to do to expand workforce development and training programs to all the untapped talent in our region\u2014our high schools, community colleges, and our HBCUs. There is huge demand for pilots & aviation professionals right now, and these students can fill it!",
            },
          ],
          question:
            "Are there policies to support teachers and improve educational outcomes that you prioritize?",
        },
      ],
      summary:
        "Steve Cohen is committed to the improvement of public education quality and the increase of access to higher education. He advocates for the expansion of workforce development and training programs, especially in high schools, community colleges, and HBCUs. He prioritizes diversity and inclusion in accelerated learning programs. He believes in fair wages for teachers and opposes the idea of public schools becoming an ideological battleground. \n\nRegarding student loan debt, Cohen supports measures such as removing the cap on Social Security taxes so that millionaires pay their fair share, thereby fully funding Social Security. He is also proud to have authored the Billionaire Minimum Income Tax Act, and he supports the Public Service Loan Forgiveness program.\n\nHis policies to support teachers and improve educational outcomes include ensuring that teachers have the resources they need and prioritizing diversity and inclusion in classrooms. He has spearheaded a 6+ billion dollar educational initiative to expand workforce development and training programs.",
    },
    "Environmental and Climate Policy": {
      questions: [
        {
          answer:
            "I am committed to addressing climate change and promoting sustainability. I am leading the push to ensure upcoming legislation to manage our water resources includes critical environmental justice provisions. I am also supporting the Inflation Reduction Act, the single largest federal investment in our climate ever, which provides $5 billion for innovative projects to tackle climate pollution. Additionally, I am encouraging the US government to phase out single-use plastics and am working to pass a single-use ban on Capitol grounds. I am also talking with the CleanUpTVACoalition about how the nation\u2019s largest utility should be working to meet the President\u2019s goal of Zero Carbon by 2035. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625943061250379776",
              text: "Democrats are rebuilding America by fixing our roads and bridges and bringing clean water and high-speed internet to more communities. And we are making the largest climate investments in history to generate clean, secure, American energy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1649803187442835457",
              text: "??Under the Biden Administration, we've made historic progress on climate change with investments in #RenewableEnergy, clean transportation, and more. But the fight isn't over - let's keep pushing for clean energy access & protect our environment! ????#EarthDay #ClimateAction https://t.co/AovkBWgFkY",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558198041915670530",
              text: "The #InflationReductionAct is the single largest federal investment in our climate EVER!??\n\nWhile combatting the climate crisis, we will spur American innovation, create jobs, lower energy costs, strengthen our independence and strive for environmental justice.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1573328645808062467",
              text: "As the single largest purchaser in the world, the US government has an opportunity to do right by the environment and lead by example. That\u2019s why @RepHuffman @RepMikeQuigley @RepLowenthal and I are encouraging @USGSA to move swiftly as possible in phasing out single-use plastics. pic.twitter.com/2by8g9PyHy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1631049532346646528",
              text: "Thanks to the Inflation Reduction Act, @EPA is providing $5 billion for innovative projects to tackle climate pollution. The first $250 million is now available for grants to clean our air, build up clean energy economies and advance environmental justice.\nepa.gov/inflation-redu\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1531720275401875457",
              text: "Earlier today I talked with the #CleanUpTVACoalition about how the nation\u2019s largest utility should be working feverishly to meet the President\u2019s goal of Zero Carbon by 2035. Failure to do so just gives private utilities and other corporations an excuse to burn more fossil fuels.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1517621921864634372",
              text: "The world is drowning in plastic and we can limit it by reducing wherever possible. The government should lead the way, which is why I worked to pass a single-use ban on Capitol grounds and am again leading a letter with @RepLowenthal & @RepMikeQuigley to ensure it's implemented.",
            },
          ],
          question:
            "How will you address climate change and promote sustainability?",
        },
        {
          answer:
            "We must work to reduce waste and emissions by investing in renewable energy, clean transportation, and other innovative projects. We must also phase out single-use plastics and encourage the federal government to lead by example. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1531720275401875457",
              text: "Earlier today I talked with the #CleanUpTVACoalition about how the nation\u2019s largest utility should be working feverishly to meet the President\u2019s goal of Zero Carbon by 2035. Failure to do so just gives private utilities and other corporations an excuse to burn more fossil fuels.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1542229723438268420",
              text: "The best way to reduce waste is to not create it in the first place. That's why I\u2019m working to stop the federal purchase of single-use plastics wherever possible and we can start in the Legislative Branch, where I\u2019ve led the effort to eliminate plastics.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1649803187442835457",
              text: "??Under the Biden Administration, we've made historic progress on climate change with investments in #RenewableEnergy, clean transportation, and more. But the fight isn't over - let's keep pushing for clean energy access & protect our environment! ????#EarthDay #ClimateAction https://t.co/AovkBWgFkY",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1573328645808062467",
              text: "As the single largest purchaser in the world, the US government has an opportunity to do right by the environment and lead by example. That\u2019s why @RepHuffman @RepMikeQuigley @RepLowenthal and I are encouraging @USGSA to move swiftly as possible in phasing out single-use plastics. pic.twitter.com/2by8g9PyHy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1517621921864634372",
              text: "The world is drowning in plastic and we can limit it by reducing wherever possible. The government should lead the way, which is why I worked to pass a single-use ban on Capitol grounds and am again leading a letter with @RepLowenthal & @RepMikeQuigley to ensure it's implemented.",
            },
          ],
          question: "Strategies for reducing greenhouse gas emissions?",
        },
        {
          answer:
            "We are taking bold action to protect our environment and combat the climate crisis. Under the Biden Administration, we have made historic progress with investments in renewable energy, clean transportation, and more. We have also passed the Inflation Reduction Act, which provides $5 billion for innovative projects to tackle climate pollution. We are rebuilding America by fixing our roads and bridges and bringing clean water and high-speed internet to more communities, while making the largest climate investments in history to generate clean, secure, American energy. We are also encouraging the USGSA to move swiftly as possible in phasing out single-use plastics and pushing for clean energy access and environmental justice. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1517605214643228672",
              text: "There is nothing political about wanting to breathe clean air and keep your community safe from rising tides and increasingly extreme weather. The future of our species and our planet depends on bold climate action. For #EarthDay, more people need to see that and act.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1649803187442835457",
              text: "??Under the Biden Administration, we've made historic progress on climate change with investments in #RenewableEnergy, clean transportation, and more. But the fight isn't over - let's keep pushing for clean energy access & protect our environment! ????#EarthDay #ClimateAction https://t.co/AovkBWgFkY",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625943061250379776",
              text: "Democrats are rebuilding America by fixing our roads and bridges and bringing clean water and high-speed internet to more communities. And we are making the largest climate investments in history to generate clean, secure, American energy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1481028068680601604",
              text: "Environmental justice communities like those in Memphis have borne the brunt of pollution & need justice. I commend the Biden @EPA for taking the first steps since Obama\u2019s presidency to protect communities from toxic coal ash & hold companies accountable.\napnews.com/article/busine\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1573328645808062467",
              text: "As the single largest purchaser in the world, the US government has an opportunity to do right by the environment and lead by example. That\u2019s why @RepHuffman @RepMikeQuigley @RepLowenthal and I are encouraging @USGSA to move swiftly as possible in phasing out single-use plastics. pic.twitter.com/2by8g9PyHy",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1517621921864634372",
              text: "The world is drowning in plastic and we can limit it by reducing wherever possible. The government should lead the way, which is why I worked to pass a single-use ban on Capitol grounds and am again leading a letter with @RepLowenthal & @RepMikeQuigley to ensure it's implemented.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558208558759550986",
              text: "We PASSED the #InflationReductionAct! It takes bold action to combat the climate crisis and save Americans ?????? on: \n?? Prescription drugs\n?? Health premiums\n?? Energy prices, and much more\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1542229723438268420",
              text: "The best way to reduce waste is to not create it in the first place. That's why I\u2019m working to stop the federal purchase of single-use plastics wherever possible and we can start in the Legislative Branch, where I\u2019ve led the effort to eliminate plastics.\ncohen.house.gov/media-center/p\u2026",
            },
          ],
          question: "Policies for environmental protection and climate action?",
        },
      ],
      summary:
        "Steve Cohen is strongly committed to addressing climate change and promoting environmental sustainability. He supports legislative measures such as the Inflation Reduction Act which funds innovative projects for tackling climate pollution. Cohen is also a proponent of investing heavily in renewable energy and clean transportation. He advocates for environmental justice provisions and for reducing waste and emissions, including a phase-out of single-use plastics. Another priority is encouraging clean energy access and the achievement of the President's goal of Zero Carbon by 2035. His perspective emphasizes not only infrastructure renewal but also the importance of the largest climate investments in the nation's history.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      questions: [
        {
          answer:
            "The United States must take a strong stance against Russia's aggression in Ukraine. We must support Ukraine in their fight for freedom and democracy, and make it clear that any use of chemical, biological, or nuclear weapons against Ukraine will not be tolerated and will have consequences. We must provide Ukraine with military aid, including fighter jets, and continue to keep the pressure on Russia. We must also ensure that the Far Right does not jeopardize Ukraine's fight for freedom. We must stand with Ukraine and President Zelensky, and make sure that Putin's attempt to restore the Soviet empire is stopped. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600849785950720000",
              text: "Got the insightful and up close opinion on Russia and Ukraine from Foreign Minister@RauZbigniew. We must support Ukraine as they fight for the western world and civilization.Russia has centuries of expansionist , imperialist foreign policy. This must cease.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1507141421601632261",
              text: "The US should be clear: If Russia uses chemical, biological or nuclear weapons against Ukraine, it would be a crime against humanity and consequences would follow.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497492244663840769",
              text: "Ukraine is the Berlin of the 21st century. It\u2019s citizens fight for freedom is reminiscent of Tianmen Square,standing up to the tank of oppression.\nWe must have further and SWIFT sanctions,more weapons, and support for refugees.\nNever forget Putins lies and evil, sociopathic acts",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1498415371002560520",
              text: "Last week I led a delegation to the @oscepa and met with US troops 8 miles away from Russian troops.\n\nI #StandWithUkraine and applaud Presidents Zelensky and Biden. The transatlantic community is united against Putin's invasion. He's a delusional, paranoid and dangerous tyrant. https://t.co/Dm8Ma35Lrr",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1502756484907769863",
              text: "If Putin is contemplating using chemical weapons in Ukraine, @POTUS and the global community must speak in one voice that such a course of action will not be tolerated and take whatever measures are necessary to deter Russia from even considering such dark and destructive action.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1512080636982398976",
              text: ".@POTUS has unified our allies and led the way with unprecedented sanctions and military aid, but we must continue to keep the pressure on. We need to do more because if Ukraine falls, we all lose. https://t.co/34D7bgt8gT",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630263770768449539",
              text: "The Far Right could jeopardize Ukraine, but I think the majority of Republicans understand if Putin wins in Ukraine, he will continue into NATO countries. He wishes to restore the Soviet empire and plunge us into WWIII. We must stop him in Ukraine and ensure peace and democracy. https://t.co/LannaONAxa",
            },
          ],
          question:
            "How would you approach the ongoing Ukraine conflict, and what role do you believe the United States should play in resolving it?",
        },
        {
          answer:
            "My foreign policy priorities are to support our allies and international organizations in their fight for the western world and civilization. I have been proud to represent the USA as head of our delegation to the Organization for Security and Co-operation in Europe (OSCE) parliamentary assembly and on our supportive journey to Lithuania. I have also been leading a delegation to the OSCE, pledging my support for the people of Ukraine and assuring them that we will raise their security at the meeting. I am committed to resolutely opposing Putin\u2019s criminal war, upholding the Helsinki Accords, and supporting brave Ukraine. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600849785950720000",
              text: "Got the insightful and up close opinion on Russia and Ukraine from Foreign Minister@RauZbigniew. We must support Ukraine as they fight for the western world and civilization.Russia has centuries of expansionist , imperialist foreign policy. This must cease.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497301864844570631",
              text: "I have been so proud to represent the USA as head of our delegation @OSPC parliamentary assembly and or supportive journey to Lithuania where we visited our troops 5 km.from Russians,met w prime minister and President. & Belarusian leaders in exile. UKRAINE ????????????!!Russia REVOLT!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1494466549918355458",
              text: "As Co-Chair of @HelsinkiComm, I\u2019m leading a delegation to the Org for Security & Co-Op in Europe, which initially included a visit to #Ukraine that was canceled. I sent a message pledging my support for the people of Ukraine assuring them we\u2019d raise their security at the meeting. pic.twitter.com/j2gPBwojph",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1524442014523265027",
              text: "For four dark years, the United States turned its back on our allies in Europe and genuflected to Putin. But President Biden and Congressional Democrats will resolutely oppose Putin\u2019s criminal war, uphold the Helsinki Accords and support brave Ukraine. https://t.co/Ciudmbc0NH",
            },
          ],
          question:
            "What are your foreign policy priorities, particularly in relation to allies and international organizations?",
        },
        {
          answer:
            "I'm committed to ensuring that our trade agreements and global economic relationships benefit American interests. That's why I'm proud that the House passed major pieces of my Counter-Kleptocracy Act as part of the #AmericaCOMPETESAct. This Act will strengthen global order and democracy, bolster American manufacturing, fix our supply chains, and invest in the next generation of cutting-edge technology. It will also root out corruption, invest in innovation and bolster U.S. competitiveness on the world stage. I'm also leading the push to ensure upcoming legislation to manage our water resources includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities. With the American Rescue Plan, Infrastructure Law, CHIPS Act and Inflation Reduction Act, we have made historic gains for workers and fueled a manufacturing boom in clean energy, semiconductors and infrastructure. We are also making the largest climate investments in history to generate clean, secure, American energy. I'm committed to continuing to work with @POTUS to grow the economy from the bottom up and middle out, create jobs, and shrink the deficit. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1489703852475617280",
              text: "Proud that the House passed major pieces of my Counter-Kleptocracy Act as part of the #AmericaCOMPETESAct, which will strengthen global order and democracy, bolster American manufacturing, fix our supply chains, and invest in the next generation of cutting-edge technology.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1491419986698866689",
              text: "Countering corruption and autocracy is critical to restoring America\u2019s global leadership. With the inclusion of major parts of my Counter-Kleptocracy Act, the #AmericaCOMPETESAct will root out corruption, invest in innovation and bolster U.S. competitiveness on the world stage.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625943061250379776",
              text: "Democrats are rebuilding America by fixing our roads and bridges and bringing clean water and high-speed internet to more communities. And we are making the largest climate investments in history to generate clean, secure, American energy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623144600025137152",
              text: "With the American Rescue Plan, Infrastructure Law, CHIPS Act and Inflation Reduction Act, we have made historic gains for workers and fueled a manufacturing boom in clean energy, semiconductors and infrastructure. Good clean union jobs right here in America! #SOTU2023",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1510969122041081858",
              text: ".@POTUS is focused on growing the economy from the bottom up and middle out. With the #AmericanRescuePlan, we've created the most jobs in recorded history, grown the economy faster than in the last 40 years, and are set to shrink the deficit by the largest-ever one-year drop.",
            },
          ],
          question:
            "How will you navigate trade agreements and global economic relationships to benefit American interests?",
        },
        {
          answer:
            "As a Congressman, I will work to ensure that the United States is a leader in international diplomacy and conflict resolution. We must work with our allies to promote peace and stability in the world. We must also be willing to take a strong stance against those who threaten the peace and security of the world. We must speak out against aggression and take whatever measures are necessary to deter those who would use violence and destruction to achieve their goals. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600849785950720000",
              text: "Got the insightful and up close opinion on Russia and Ukraine from Foreign Minister@RauZbigniew. We must support Ukraine as they fight for the western world and civilization.Russia has centuries of expansionist , imperialist foreign policy. This must cease.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1502756484907769863",
              text: "If Putin is contemplating using chemical weapons in Ukraine, @POTUS and the global community must speak in one voice that such a course of action will not be tolerated and take whatever measures are necessary to deter Russia from even considering such dark and destructive action.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1524442014523265027",
              text: "For four dark years, the United States turned its back on our allies in Europe and genuflected to Putin. But President Biden and Congressional Democrats will resolutely oppose Putin\u2019s criminal war, uphold the Helsinki Accords and support brave Ukraine. https://t.co/Ciudmbc0NH",
            },
          ],
          question:
            "What approach will you take to address international conflicts and promote diplomacy?",
        },
      ],
      summary:
        "Steve Cohen firmly believes in taking a strong stance against international aggression, such as Russia's actions in Ukraine. He supports providing Ukraine with key military aid and maintaining pressure on Russia as part of his approach to the conflict. He also sees the role of the United States as standing firmly with President Zelensky and preventing Putin's attempt to restore the Soviet empire. \n\nOn the broader aspect of foreign policy, Cohen's priorities include supporting allies and international organizations in their fight for western values. He has represented the USA in the Organization for Security and Co-operation in Europe, advocating for security in Ukraine and opposing Putin\u2019s war. \n\nRegarding trade agreements and global economic relationships, Cohen advocates for agreements that strongly benefit American interests, as evident from his Counter-Kleptocracy Act and strong support for the America COMPETES Act. These acts aim to bolster American manufacturing, fix American supply chains, and invest in next-gen technology while rooting out corruption.\n\nLastly, in terms of diplomacy and conflict resolution, Cohen supports the role of the United States as a proactive leader. He is in favor of working with allies to promote global peace and stability and taking a strong stance against those threatening peace.",
    },
    Guns: {
      questions: [
        {
          answer:
            "I believe that reinstating the 1994 assault weapons ban, passing red flag laws, and implementing comprehensive background checks are all necessary steps to reduce gun violence. We must also crack down on dangerous gun modifications like Glock switches and work to limit access to guns for those who are intent on harming themselves or others. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1635785533891792896",
              text: "Pleased to see @POTUS take further action to curb gun violence.\n\u00a0\nThe American people have made it clear they want comprehensive reform, and I will continue to fight for it in Congress.\ncnbc.com/2023/03/14/wat\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1594779429615636482",
              text: "They want you dead for how you live or who you love but have the audacity now to send empty thoughts and prayers.\n \nHow about joining us in commonsense gun reform and not stoking violence with your despicable rhetoric and fearmongering?",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1651290078000316433",
              text: "We've got to pursue real solutions to curb #GunViolence\u2014our city knows that, but the TN legislature isn\u2019t interested. I spoke w/ ATF Director Dettelbach today about their work to crack down on dangerous gun mods like Glock switches & the need for federal action on Red Flag Laws?? https://t.co/2FtnF0QHIx",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1641547790105952256",
              text: "This insane act of violence has been a shock to our entire state, especially to students and staff at the Covenant School. I'm ready to work with anyone who is serious about protecting our communities against gun violence\u2014and we should start by reinstating the #AssaultWeaponsBan.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1535021866313818113",
              text: "Today I voted for a Red Flag Law to prevent those who are intent on harming themselves or others from accessing lethal weapons. It might have prevented Uvalde and we must get it and other legislation through the Senate before more children are senselessly murdered. https://t.co/PDwDezxTWV",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1529881139342716929",
              text: "Dear Senators: Unless you\u2019ll do what it takes to pass gun reform, I couldn\u2019t care less about your \u201cthoughts and prayers.\u201d",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1532157063090028544",
              text: "Appreciate the Speaker\u2019s leadership.I had amendment drawn for tomorrow\u2019s judiciary committee markup to reinstate 1994 assault ban (law til 2004).It is imperative that we debate and vote on this life saving measure. The guns are made to KILL and aren\u2019t for sport or defense.",
            },
          ],
          question:
            "What gun control measures do you believe will be effective in reducing gun violence?",
        },
        {
          answer:
            "We must ensure that the Second Amendment is respected while also protecting the public from gun violence. We must regulate militias and the use of handguns to protect the public, while also recognizing that certain places should be off limits to guns, such as schools, hospitals, subways, sports arenas, government buildings, child care centers, movie theaters, nightclubs, and places where liquor is sold by drink. We must also reinstate the Assault Weapons Ban and pass other life-saving legislation to bolster safety. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1530967961397239808",
              text: "We have a right to free speech, but not the right to scream fire in a theater. We also don\u2019t have a right to an assault weapon or bazooka.\n\nThe 2nd Amendment talks about a well-regulated militia. We can and must regulate militias and the use of handguns to protect the public. https://t.co/1dKecXT9Mx",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1540076864521355265",
              text: "The arch-conservative Supreme Court is quietly transforming a limited right to self-defense in one's home into a new, radical right to bring any gun to any public space. Disturbing to see SCOTUS side with the NRA and hinder our ability to keep families safe from gun violence.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1641547790105952256",
              text: "This insane act of violence has been a shock to our entire state, especially to students and staff at the Covenant School. I'm ready to work with anyone who is serious about protecting our communities against gun violence\u2014and we should start by reinstating the #AssaultWeaponsBan.",
            },
          ],
          question:
            "How do you plan to balance Second Amendment rights with public safety concerns?",
        },
        {
          answer:
            "Absolutely. I believe in the importance of responsible gun ownership and I support policies that promote it. I have voted for a Red Flag Law to prevent those who are intent on harming themselves or others from accessing lethal weapons, and I have voted to pass an assault weapons ban. I am also in favor of reinstating the #AssaultWeaponsBan and I am ready to work with anyone who is serious about protecting our communities against gun violence. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1540439829372190725",
              text: "I voted reluctantly today on a gun safety bill that's a small step forward but should have been better. The House bill was much better, but this Senate compromise was the best that could be done with Republicans who refuse to do anything to limit gun access for anyone over 18. pic.twitter.com/GU86eNgd7n",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1635785533891792896",
              text: "Pleased to see @POTUS take further action to curb gun violence.\n\u00a0\nThe American people have made it clear they want comprehensive reform, and I will continue to fight for it in Congress.\ncnbc.com/2023/03/14/wat\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1641547790105952256",
              text: "This insane act of violence has been a shock to our entire state, especially to students and staff at the Covenant School. I'm ready to work with anyone who is serious about protecting our communities against gun violence\u2014and we should start by reinstating the #AssaultWeaponsBan.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1553146448996438016",
              text: "Republican politicians' almost fetishism of weapons is something that only Freud would properly understand and be able to explain to the American people. The AR-15 is the outgrowth of a military weapon with no place in civilized society. I voted to pass an assault weapons ban. https://t.co/Vz0uSkwYzH",
            },
          ],
          question:
            "Are there policies to promote responsible gun ownership that you support?",
        },
      ],
      summary:
        "Steve Cohen is firm on implementing gun control measures to reduce gun violence. He supports reinstating the 1994 assault weapons ban, implementing comprehensive background checks, and passing red flag laws. He believes that limiting access to guns for those who intend to harm themselves or others is vital. He also is in favor of regulating areas where firearms are allowed, including schools, hospitals, subways, sports arenas, government buildings, and locations where alcohol is served. However, he respects Second Amendment rights and emphasizes balancing these rights with public safety. He promotes policies that support responsible gun ownership, such as Red Flag Laws and assault weapon bans.",
    },
    Healthcare: {
      questions: [
        {
          answer:
            "I'm committed to making healthcare accessible to all Americans, regardless of income. We have already taken steps to lower healthcare costs, including capping out-of-pocket costs for seniors, expanding the child tax credit, and capping the cost of insulin at $35 per month. We have also passed legislation to lower Medicare Rx prices and reduce health premiums by $800/yr for 13 million Americans. We must continue to work to strengthen the Affordable Care Act and ensure that healthcare is affordable for all. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1490698707729268741",
              text: "Call me a radical, but I don\u2019t think those who lift themselves out of poverty should lose everything because of one medical diagnosis. We need affordable health care for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1584994426237378560",
              text: "Still haven't heard about how Democrats passed cost-lowering legislation? Here's just a few things we already did:\n?? Lower Medicare Rx prices\n?? Cap out-of-pocket costs for seniors\n?? Lower health premiums by $800/yr for 13 million Americans\n????????Expanded child tax credit",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1509664407139373060",
              text: "Americans with diabetes are dying because they cannot afford their medicine. Today, I voted with House Dems to cap the cost of insulin at $35 per month.",
            },
          ],
          question:
            "What steps will you take to make healthcare accessible to all Americans, regardless of income?",
        },
        {
          answer:
            "I'm committed to making sure that Americans have access to affordable healthcare. We have already passed cost-lowering legislation to lower Medicare Rx prices, cap out-of-pocket costs for seniors, lower health premiums by $800/yr for 13 million Americans, and expanded the child tax credit. We are also forcing pharmaceutical companies to bring down costs for seniors and other Medicare beneficiaries, and have voted to cap the cost of insulin at $35 per month. We are also taking on the special interests to deliver lower prescription drug prices, and are working to strengthen the ACA and ensure that health care is affordable for all. We won't let Republicans raise prices, and are fighting to remove the cap on Social Security taxes so millionaires pay a fair share to fully fund Social Security. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1490698707729268741",
              text: "Call me a radical, but I don\u2019t think those who lift themselves out of poverty should lose everything because of one medical diagnosis. We need affordable health care for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1584994426237378560",
              text: "Still haven't heard about how Democrats passed cost-lowering legislation? Here's just a few things we already did:\n?? Lower Medicare Rx prices\n?? Cap out-of-pocket costs for seniors\n?? Lower health premiums by $800/yr for 13 million Americans\n????????Expanded child tax credit",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1509664407139373060",
              text: "Americans with diabetes are dying because they cannot afford their medicine. Today, I voted with House Dems to cap the cost of insulin at $35 per month.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623765489821310980",
              text: "Democrats took on the special interests to deliver lower prescription drug prices. We will not let Republicans raise them.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623160210591305729",
              text: "Historic progress in lowering prescription drug costs, strengthening Social Security & Medicare, reducing child poverty, and fueling a manufacturing boom in clean energy and infrastructure\u2014all while reducing the deficit with fairer taxes on the wealthiest. Keep it coming @POTUS!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1514677598130954241",
              text: "Thanks to @POTUS fixing the \u201cfamily glitch,\u201d a typical family of four will now save more than $4000 on their health insurance costs. I\u2019m\u00a0proud to continue working to strengthen the ACA and ensure that health care is affordable for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492245514972192777",
              text: "I happen to believe everyday Americans shouldn\u2019t be padding pharmaceutical companies\u2019 profits by paying higher prescription drug prices than any other country.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1535355305391005701",
              text: "Proud to cosponsor @RepPeterDeFazio\u2019s bill to remove the indefensible cap on Social Security taxes so millionaires pay a fair share to fully fund Social Security through the 21st century and increase benefits \u2014 all without raising taxes by one penny on over 93% of Americans.",
            },
          ],
          question:
            "How do you plan to address the issue of skyrocketing healthcare costs and medical debt?",
        },
        {
          answer:
            "Absolutely. We need to ensure that everyday Americans don't have to pay higher prescription drug prices than any other country. We passed a Medicare reform bill last year that is forcing pharmaceutical companies to bring those costs down for seniors and other Medicare beneficiaries. We also need to strengthen the Affordable Care Act and ensure that health care is affordable for all. We must also call for a YES VOTE on the Women's Health Protection Act to ensure that abortions are available to all, regardless of where they live and how much they make. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1490698707729268741",
              text: "Call me a radical, but I don\u2019t think those who lift themselves out of poverty should lose everything because of one medical diagnosis. We need affordable health care for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492245514972192777",
              text: "I happen to believe everyday Americans shouldn\u2019t be padding pharmaceutical companies\u2019 profits by paying higher prescription drug prices than any other country.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1514677598130954241",
              text: "Thanks to @POTUS fixing the \u201cfamily glitch,\u201d a typical family of four will now save more than $4000 on their health insurance costs. I\u2019m\u00a0proud to continue working to strengthen the ACA and ensure that health care is affordable for all.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1484904435910279173",
              text: "Even *with* #RoevWade, abortions are largely unavailable depending on where you live and how much you make. If Roe is overturned, half the country will be even worse off. That\u2019s why my colleagues must join me to call for a YES VOTE on the Women\u2019s Health Protection Act.",
            },
          ],
          question:
            "Are there specific healthcare reforms you believe are necessary to improve the system?",
        },
      ],
      summary:
        "Steve Cohen is deeply committed to improving healthcare access and affordability for all Americans. He has championed a number of healthcare initiatives and legislative measures such as capping out-of-pocket costs for seniors, expanding the child tax credit, and limiting the cost of insulin to $35 per month. Cohen supports the strengthening of the Affordable Care Act and holds a firm stance on the reduction of prescription drug prices, even advocating for a Medicare reform bill to pressure pharmaceutical companies to lower costs for seniors and other beneficiaries. He also believes in fighting against interests that can harm the affordability of healthcare and opposes potential attempts by Republicans to raise prices. Cohen advocates for a removal of the cap on Social Security taxes to ensure that higher-income individuals contribute proportionally to fund Social Security. Additionally, he supports the Women's Health Protection Act, backing the availability of abortions regardless of income or location.",
    },
    Immigration: {
      questions: [
        {
          answer:
            "Comprehensive immigration reform is a priority for me and my colleagues in the House. We must create a pathway to citizenship for the millions of undocumented immigrants living in the United States, and ensure that our immigration system is fair and just. We must also ensure that our immigration system is humane and that we are treating immigrants with dignity and respect. We must also ensure that our immigration system is secure and that we are protecting our borders. We must also ensure that our immigration system is efficient and that we are providing a pathway to citizenship for those who are eligible. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1612823424514101249",
              text: "House Republicans talk about \u201cfreedom.\u201d But to African Americans, working families, LGBTQ Americans, and legal immigrants it is the opposite of freedom. It is freedom for the wealthy and well-connected to maintain their quasi-antebellum status quo.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1626612280354910209",
              text: "Today I reintroduced the Baseball Diplomacy Act with @RepMarkPocan to eliminate barriers to Cuban athletes entering the U.S. on non-immigrant visas during the baseball season and returning home when the season ends.\n\ncohen.house.gov/media-center/p\u2026",
            },
          ],
          question:
            "How will you approach comprehensive immigration reform, including pathways to citizenship?",
        },
        {
          answer:
            "I support comprehensive immigration reform that includes a pathway to citizenship for Dreamers, a humane approach to border security, and a commitment to protecting our nation\u2019s most vulnerable communities. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
          ],
          question:
            "What measures do you support for border security and managing immigration flows?",
        },
        {
          answer:
            "Yes, I strongly believe that we must pass comprehensive immigration reform that provides a pathway to citizenship for undocumented immigrants. We must also ensure that our immigration system is humane and that immigrants are treated with dignity and respect. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600522145280495620",
              text: "Overwhelming majorities of Republican voters support policies that Democratic lawmakers have long prioritized. I hope my GOP colleagues will join me in passing popular, commonsense ideas that voters on both sides of the aisle agree on. pic.twitter.com/cRvYtXIIlS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1612823424514101249",
              text: "House Republicans talk about \u201cfreedom.\u201d But to African Americans, working families, LGBTQ Americans, and legal immigrants it is the opposite of freedom. It is freedom for the wealthy and well-connected to maintain their quasi-antebellum status quo.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497252469725966344",
              text: "As Congress considers upcoming legislation to manage our water resources, I\u2019m leading the push to ensure the bill includes critical environmental justice provisions that redress the challenges facing our nation\u2019s most vulnerable communities.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1626612280354910209",
              text: "Today I reintroduced the Baseball Diplomacy Act with @RepMarkPocan to eliminate barriers to Cuban athletes entering the U.S. on non-immigrant visas during the baseball season and returning home when the season ends.\n\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1631024329566609410",
              text: "The Housing Accountability Act I reintroduced today with @SenRubioPress would survey Section 8 tenants so that residents can speak up about their living conditions and @HUDgov can hold landlords accountable for safe and livable housing.",
            },
          ],
          question:
            "Are there policies to address the status of undocumented immigrants that you advocate?",
        },
      ],
      summary:
        "Steve Cohen advocates for comprehensive immigration reform, including creating a pathway to citizenship for undocumented immigrants living in the United States. He believes in a just and fair immigration system that treats immigrants with dignity and respect. Additionally, he supports a humane approach to border security, and is committed to protecting vulnerable communities. He fully supports measures for reform that includes a pathway to citizenship for Dreamers. Cohen is dedicated to ensuring that the immigration process is secure, efficient, and respectful towards immigrants.",
    },
    "Inflation and Prices": {
      questions: [
        {
          answer:
            "I am committed to fighting for everyday Americans and have proposed several measures to mitigate the impact of inflation. We passed the Inflation Reduction Act, which cracks down on Big Pharma price gouging, combats pollution and makes the wealthiest corporations and individuals pay their fair share. We have also lowered Medicare Rx prices, capped out-of-pocket costs for seniors, lowered health premiums by $800/yr for 13 million Americans, and expanded the child tax credit. I have also authored the President's Billionaire Minimum Income Tax so those with the most pay the most. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558086882046619648",
              text: "The Inflation Reduction Act is a victory for the people over special interests: cracks down on Big Pharma price gouging, combats pollution and makes the wealthiest corporations and individuals pay their fair share.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558208558759550986",
              text: "We PASSED the #InflationReductionAct! It takes bold action to combat the climate crisis and save Americans ?????? on: \n?? Prescription drugs\n?? Health premiums\n?? Energy prices, and much more\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1492245514972192777",
              text: "I happen to believe everyday Americans shouldn\u2019t be padding pharmaceutical companies\u2019 profits by paying higher prescription drug prices than any other country.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1584994426237378560",
              text: "Still haven't heard about how Democrats passed cost-lowering legislation? Here's just a few things we already did:\n?? Lower Medicare Rx prices\n?? Cap out-of-pocket costs for seniors\n?? Lower health premiums by $800/yr for 13 million Americans\n????????Expanded child tax credit",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630240386991165440",
              text: "I authored the President's Billionaire Minimum Income Tax so those with the most pay the most.\n\nMeanwhile, Congressional Republicans want to slash taxes for the ultrarich and impose a massive national sales tax on everyone else.",
            },
          ],
          question:
            "What measures do you propose to mitigate the impact of inflation on everyday Americans?",
        },
        {
          answer:
            "Absolutely. We passed the #InflationReductionAct to slash American families' prescription drug costs, reduce healthcare costs, lower energy prices, and deliver historic climate action. We also passed the Medicare reform bill to bring down costs for seniors and other Medicare beneficiaries. We are also fighting to hold the economy hostage and protect essential programs like Medicare, Medicaid, Social Security, and SNAP. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1622669448619466790",
              text: "House Republicans want to hold the economy hostage unless they get cuts to essential programs like Medicare, Medicaid, Social Security, and SNAP. Absolutely not.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600522145280495620",
              text: "Overwhelming majorities of Republican voters support policies that Democratic lawmakers have long prioritized. I hope my GOP colleagues will join me in passing popular, commonsense ideas that voters on both sides of the aisle agree on. pic.twitter.com/cRvYtXIIlS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558086882046619648",
              text: "The Inflation Reduction Act is a victory for the people over special interests: cracks down on Big Pharma price gouging, combats pollution and makes the wealthiest corporations and individuals pay their fair share.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623765489821310980",
              text: "Democrats took on the special interests to deliver lower prescription drug prices. We will not let Republicans raise them.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1569788274432159745",
              text: "Today, Republican leaders introduced a national abortion ban, while Democrats celebrated the signing of the #InflationReductionAct to slash American families' prescription drug costs, reduce healthcare costs, lower energy costs and deliver historic climate action.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1584994426237378560",
              text: "Still haven't heard about how Democrats passed cost-lowering legislation? Here's just a few things we already did:\n?? Lower Medicare Rx prices\n?? Cap out-of-pocket costs for seniors\n?? Lower health premiums by $800/yr for 13 million Americans\n????????Expanded child tax credit",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558208558759550986",
              text: "We PASSED the #InflationReductionAct! It takes bold action to combat the climate crisis and save Americans ?????? on: \n?? Prescription drugs\n?? Health premiums\n?? Energy prices, and much more\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1581011710395097089",
              text: "The Biden Admin is boosting Social Security by 8.7%\u00a0while lowering Medicare premiums. That\u2019s on top of historic legislation to bring down Medicare prescription prices. We're fighting for seniors and lower costs as the radical right\u00a0works to cut Social Security and Medicare.",
            },
          ],
          question:
            "Are there specific policies you support to stabilize prices and ensure affordability?",
        },
      ],
      summary:
        "Steve Cohen is committed to mitigating the impact of inflation on everyday Americans. He supports the Inflation Reduction Act, which aims to regulate Big Pharma prices, fight pollution, and ensure the wealthiest individuals and corporations pay their fair share. He has helped to pass legislation aimed at lowering Medicare prescription prices, capping out-of-pocket costs for seniors, reducing health premiums, and expanding the child tax credit. Moreover, he authored the President's Billionaire Minimum Income Tax that ensures those with the highest income pay the most tax. In addition, Cohen supports legislation intended to stabilize prices, reduce healthcare and prescription drug costs, ensure economic stability, and protect essential programs like Medicare, Medicaid, Social Security, and SNAP.",
    },
    "Jobs & the Economy": {
      questions: [
        {
          answer:
            "I will continue to support the initiatives of @POTUS to grow the economy from the bottom up and middle out. This includes the #AmericanRescuePlan, Infrastructure Law and Inflation Reduction Act, which have created the most jobs in recorded history, grown the economy faster than in the last 40 years, and are set to shrink the deficit by the largest-ever one-year drop. I will also focus on expanding workforce development and training programs to all the untapped talent in our region, such as high schools, community colleges, and HBCUs. This will help to fill the huge demand for pilots and aviation professionals. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1510969122041081858",
              text: ".@POTUS is focused on growing the economy from the bottom up and middle out. With the #AmericanRescuePlan, we've created the most jobs in recorded history, grown the economy faster than in the last 40 years, and are set to shrink the deficit by the largest-ever one-year drop.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1648786696144580614",
              text: "There's a lot more we need to do to expand workforce development and training programs to all the untapped talent in our region\u2014our high schools, community colleges, and our HBCUs. There is huge demand for pilots & aviation professionals right now, and these students can fill it!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623682795250802691",
              text: "In two years, @POTUS and the Democrats created almost as many jobs as any other president created in four. Not just because of COVID. Unemployment is at its lowest point since 1969.",
            },
          ],
          question:
            "What strategies will you employ to stimulate job growth and reduce unemployment?",
        },
        {
          answer:
            "We are committed to supporting small businesses and entrepreneurs to boost the economy. We have already passed a targeted small business relief bill to replenish restaurant funds and support other hard-hit businesses. We are also focused on growing the economy from the bottom up and middle out with the #AmericanRescuePlan, creating jobs, lowering costs, fighting price gouging, and boosting the economy. We are also making historic progress in lowering prescription drug costs, strengthening Social Security & Medicare, reducing child poverty, and fueling a manufacturing boom in clean energy and infrastructure. We are also introducing the Billionaire Minimum Income Tax so billionaires play by the same rules as everyone else. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1512118534402363392",
              text: "Restaurant relief was a lifeline during the peak of the pandemic, but funds ran out before many restaurants received relief. Today, House Democrats are passing a targeted small business relief bill to replenish the restaurant funds and support other hard-hit businesses.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623143744575795202",
              text: "We created more jobs in the last two years than any other period in history and are building the economy from the bottom up and middle out, instead of from the top down.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1510969122041081858",
              text: ".@POTUS is focused on growing the economy from the bottom up and middle out. With the #AmericanRescuePlan, we've created the most jobs in recorded history, grown the economy faster than in the last 40 years, and are set to shrink the deficit by the largest-ever one-year drop.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623160210591305729",
              text: "Historic progress in lowering prescription drug costs, strengthening Social Security & Medicare, reducing child poverty, and fueling a manufacturing boom in clean energy and infrastructure\u2014all while reducing the deficit with fairer taxes on the wealthiest. Keep it coming @POTUS!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1559887468983353345",
              text: "While billionaires blew trillions of dollars on spaceships and superyachts, working families footed most of the tax bill. That's why I led the introduction of @POTUS\u2019s Billionaire Minimum Income Tax so billionaires play by the same rules as everyone else!\nnewsweek.com/we-can-make-su\u2026",
            },
          ],
          question:
            "How can the government support small businesses and entrepreneurs to boost the economy?",
        },
        {
          answer:
            "Yes, I have a plan to address income inequality and promote wage growth. I authored the President's Billionaire Minimum Income Tax so those with the most pay the most. This bill will ensure that billionaires pay their fair share in taxes, while also providing relief to working families. Additionally, I have introduced the Inflation Reduction Act, which cracks down on Big Pharma price gouging and combats pollution, while making the wealthiest corporations and individuals pay their fair share. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585369155787583488",
              text: "Liberal or conservative, working families in countries around the world are suffering from inflation. Nevertheless, the US has one of the lowest unemployment rates and @POTUS fighting to lower costs, fight price gouging and boost the economy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1634233535698485249",
              text: "For too long the ultra-wealthy have not paid their fair share in taxes while the middle class continues to scrape by. \n\nI\u2019m pleased to see the billionaire minimum income tax is included in @POTUS' new budget, which I introduced last Congress. pic.twitter.com/Yj3nTUWB1a",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1558086882046619648",
              text: "The Inflation Reduction Act is a victory for the people over special interests: cracks down on Big Pharma price gouging, combats pollution and makes the wealthiest corporations and individuals pay their fair share.",
            },
          ],
          question:
            "Do you have a plan to address income inequality and promote wage growth?",
        },
      ],
      summary:
        "Steve Cohen supports initiatives to stimulate job growth from the bottom up and middle out, such as the American Rescue Plan and the Infrastructure Law. He is committed to lowering costs, fighting price gouging, and strengthening social security and Medicare. Cohen believes in supporting small businesses as they play a crucial role in boosting the economy. He aims to expand workforce development and training programs, specifically in high schools, community colleges, and HBCUs. Additionally, Cohen aspires to address income inequality by introducing legislations like the Billionaire Minimum Income Tax to ensure the wealthy pay their fair share of taxes. Lastly, he has brought forward the Inflation Reduction Act to combat price gouging in the pharmaceutical industry and pollution.",
    },
    "National Security": {
      questions: [
        {
          answer:
            "As Chairman of the Subcommittee on the Constitution, Civil Rights, and Civil Liberties, I am committed to protecting the rights and safety of American citizens. I am examining reforms to ensure no President can misuse or abuse emergency authorities, and I am determined to safeguard our elections. I have introduced the #ElectionReformAct to ensure the Electoral College cannot be subverted, and the Civil Rights Legacy Protection Act to help safeguard the legacy and memorials of the Civil Rights Movement. I have also reintroduced the Housing Accountability Act with Senator Rubio to survey Section 8 tenants and hold landlords accountable for safe and livable housing. We must protect the rights of all Americans, and I am committed to doing so. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1551010089275899904",
              text: "\u201cThe beauty of the system\u201d is how he describes states determining privacy of adults re:abortion,marriage between   different races,people of same sex,sex activities beyond missionary or manual, and contraception.Not beauty but horror show.Privacy rights should be enjoyed by all!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1526948741844029446",
              text: "Our democratic system is built on checks and balances. As Chairman of the Subcommittee on the Constitution, Civil Rights, and Civil Liberties, I continue to examine reforms to ensure no President can misuse or abuse emergency authorities. https://t.co/05L5IQci12",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1572696337568743426",
              text: "As Chairman of the Constitution, Civil Rights and Civil Liberties Subcommittee, I'm determined to safeguard our elections. Trump's insurrectionists tried to interfere with the Electoral College, but the #ElectionReformAct passed today aims to ensure it cannot be subverted.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1540364462493949953",
              text: "The Supreme Court is a clear and present danger to the rights and safety of American citizens.  There is no balance or care in their radical decisions.  Just blind devotion to ideological dogma.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625622406491435009",
              text: "We must honor the memory of those who lost their lives to Jim Crow racism and the struggle for freedom. My Civil Rights Legacy Protection Act would help safeguard the legacy and memorials of the movement for Civil Rights.\ncohen.house.gov/media-center/p\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1631024329566609410",
              text: "The Housing Accountability Act I reintroduced today with @SenRubioPress would survey Section 8 tenants so that residents can speak up about their living conditions and @HUDgov can hold landlords accountable for safe and livable housing.",
            },
          ],
          question:
            "How will you ensure the safety and security of the nation while respecting individual privacy?",
        },
        {
          answer:
            "I believe that counterterrorism efforts and cybersecurity protection must be a priority for the United States. We must invest in the latest technology and strengthen our global alliances to ensure that we are prepared to counter any threats. We must also work to root out corruption and autocracy, as these are major sources of instability and insecurity. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1491419986698866689",
              text: "Countering corruption and autocracy is critical to restoring America\u2019s global leadership. With the inclusion of major parts of my Counter-Kleptocracy Act, the #AmericaCOMPETESAct will root out corruption, invest in innovation and bolster U.S. competitiveness on the world stage.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1489703852475617280",
              text: "Proud that the House passed major pieces of my Counter-Kleptocracy Act as part of the #AmericaCOMPETESAct, which will strengthen global order and democracy, bolster American manufacturing, fix our supply chains, and invest in the next generation of cutting-edge technology.",
            },
          ],
          question:
            "What is your approach to counterterrorism efforts and cybersecurity protection?",
        },
        {
          answer:
            "I have sponsored several law enforcement reforms in the George Floyd Justice and Policing Act, and I have advocated for those bills for years. I have also written to congressional leadership urging an immediate vote on the Counter-Kleptocracy Act I introduced with other colleagues. We must become deadly serious about exposing the blood money that fuels Putin\u2019s wars and repression, and the Counter-Kleptocracy Act is the answer. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1579482648762011648",
              text: "Powerful interests including Russian oligarchs use defamation suits to intimidate journalists and critics into silence. We can't let our courts be weaponized by the highest bidders. I introduced the Free Speech Protection Act to deter the powerful from filing bad-faith lawsuits.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1618730965316308992",
              text: "@mrmosesdavis @farmerFRENCHIE @BrokenSubstack Sponsored several law enforcement reforms in the George Floyd Justice and Policing Act. \nFurther I\u2019d advocated for those bills for years .",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497642507177443334",
              text: "I\u2019ve written to congressional leadership urging an immediate vote on the Counter-Kleptocracy Act I introduced with @RepJoeWilson, @RepMalinowski and @RepJohnCurtis. We must expose and purge the blood money fueling Putin and his cronies.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1497642505357172739",
              text: "Corruption is the lifeblood of the Putin regime and he seeks to turn Ukraine into an autocratic kleptocracy dependent on his regime. We must become deadly serious about exposing the blood money that fuels Putin\u2019s wars and repression. The Counter-Kleptocracy Act is the answer.",
            },
          ],
          question:
            "Are there specific measures to strengthen intelligence agencies that you endorse?",
        },
      ],
      summary:
        "Steve Cohen takes a proactive approach towards national security, endorsing comprehensive counterterrorism efforts and robust cybersecurity protection. He advocates for investment in modern technology and enhancement of global alliances to combat threats effectively. Cohen also emphasizes rooting out corruption and autocracy, viewing these as sources of instability and insecurity. Seeking to ensure governmental power is not abused, he is examining reforms to prevent the misuse of emergency authorities by any President.  \n \nOn a similar note, Cohen has supported law enforcement reforms via the George Floyd Justice and Policing Act and has urged for a vote on the Counter-Kleptocracy Act, which he introduced alongside other colleagues. His motive behind this move is to expose and stop the illicit funds supporting wars and autocratic regimes. Cohen also seeks to protect the electoral process via the Election Reform Act and supports the Housing Accountability Act to ensure safe living conditions for citizens. In brief, Cohen's national security strategy is based on a balance of high-tech countermeasures, legislative reforms, and civil rights protection.",
    },
    Taxes: {
      questions: [
        {
          answer:
            "I believe that everyone should pay their fair share of taxes, regardless of their income level. That's why I authored the President's Billionaire Minimum Income Tax, which ensures that those with the most pay the most. I'm also proud to have introduced the Billionaire Minimum Income Tax Act, which President Biden highlighted in his budget. Democrats have passed a law to tax billionaire corporations and are working to pass my minimum billionaire tax. Meanwhile, Congressional Republicans want to slash taxes for the ultrarich and impose a massive national sales tax on everyone else. This is unacceptable and I'm fighting to ensure that billionaires pay taxes like everyone else. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630240386991165440",
              text: "I authored the President's Billionaire Minimum Income Tax so those with the most pay the most.\n\nMeanwhile, Congressional Republicans want to slash taxes for the ultrarich and impose a massive national sales tax on everyone else.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1585323710927450113",
              text: "In 2020, 55 billion-dollar corporations paid $0 in federal income taxes. Neither did Musk or Bezos in a few years. If you paid $1 or more, you paid more than them COMBINED.\n\nDemocrats passed a law to tax billionaire corporations and are working to pass my minimum billionaire tax.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1634233535698485249",
              text: "For too long the ultra-wealthy have not paid their fair share in taxes while the middle class continues to scrape by. \n\nI\u2019m pleased to see the billionaire minimum income tax is included in @POTUS' new budget, which I introduced last Congress. pic.twitter.com/Yj3nTUWB1a",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1622961001921404928",
              text: "During the State of the Union, @POTUS will highlight our work to rebuild for the middle class and urge Congress to pass the Billionaire Minimum Tax I introduced. Thank you President Biden for fighting for families and helping ensure billionaires pay taxes like everyone else.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1559887468983353345",
              text: "While billionaires blew trillions of dollars on spaceships and superyachts, working families footed most of the tax bill. That's why I led the introduction of @POTUS\u2019s Billionaire Minimum Income Tax so billionaires play by the same rules as everyone else!\nnewsweek.com/we-can-make-su\u2026",
            },
          ],
          question:
            "What is your position on tax reform, including individual and corporate taxes?",
        },
        {
          answer:
            "We must ensure that everyone pays their fair share of taxes. That's why I authored the President's Billionaire Minimum Income Tax so those with the most pay the most. We must also close loopholes that allow the ultra-wealthy to avoid taxes on their growing fortunes. We must pass the Billionaire Minimum Tax and the CLEAN Checks Act to keep political self-dealing off federal dollars. We must also remove the cap on Social Security taxes so millionaires pay a fair share to fully fund Social Security. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1612850881992560652",
              text: "Only Republicans in Washington would use their first day to help the wealthy and well-connected evade taxes by slashing funds for audits on people like Donald Trump whose income is reported by lawyers and accountants, unlike everyday Americans whose taxes are mostly withheld.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1612534640627032080",
              text: "For their first acts, House Republicans seek to gut the Office of Congressional Ethics and defund enforcement for ultrawealthy tax cheats.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1590472484796923904",
              text: "The Bezos-owned @washingtonpost may think our rigged tax system more or less works, but like most Americans I believe it only works for billionaires who avoid taxes on their growing fortunes. Here, I explain how we can ensure billionaires pay their share.\nwashingtonpost.com/opinions/2022/\u2026",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600507317795037185",
              text: "If we can put a man on the moon, we can figure out how to make billionaires pay at least the same tax rate as schoolteachers and firefighters.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1612604698426769408",
              text: "House Republicans campaigned on lowering inflation for working people, but instead their first piece of legislation rewards their wealthy and well-connected donors by making it easier for them to cheat on taxes.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630240386991165440",
              text: "I authored the President's Billionaire Minimum Income Tax so those with the most pay the most.\n\nMeanwhile, Congressional Republicans want to slash taxes for the ultrarich and impose a massive national sales tax on everyone else.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1629219421871824896",
              text: "House Democrats want to tax billionaire corporations and the ultrarich.\n\nHouse Republicans want every American to pay 30% more sales tax on all purchases from gas to groceries to medications while eliminating income taxes for billionaires\u2019 advantage.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1622961001921404928",
              text: "During the State of the Union, @POTUS will highlight our work to rebuild for the middle class and urge Congress to pass the Billionaire Minimum Tax I introduced. Thank you President Biden for fighting for families and helping ensure billionaires pay taxes like everyone else.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630974385933762560",
              text: "Except for Trump, no president abused their authority to have their name printed on every government relief check. My CLEAN Checks Act would keep political self-dealing off federal dollars by codifying longstanding precedent that limits names on federal checks to civil servants.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1535355305391005701",
              text: "Proud to cosponsor @RepPeterDeFazio\u2019s bill to remove the indefensible cap on Social Security taxes so millionaires pay a fair share to fully fund Social Security through the 21st century and increase benefits \u2014 all without raising taxes by one penny on over 93% of Americans.",
            },
          ],
          question:
            "How would you address concerns about tax evasion and tax loopholes?",
        },
        {
          answer:
            "We need to prioritize investments in education, climate resilience, health care, jobs, and infrastructure. We must also ensure that essential programs like Medicare, Medicaid, Social Security, and SNAP are not cut. We must also invest in police and violence prevention, and fund cash-strapped local governments and public safety programs. We must also make sure that those with the most pay the most, and that we are fighting inflation, gas prices, and price gouging. ",
          posts: [
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600937579498868736",
              text: "We can't keep writing blank checks for the Pentagon notorious for its wasteful spending on obsolete, unnecessary programs. I voted against the $858 billion defense bill because it is just too much when we have so many unmet domestic needs. We need to reset our priorities.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1502049593508438016",
              text: "House Democrats passed a spending package that leaves behind Trump\u2019s budget and tackles our nation\u2019s most pressing needs, including funding for education, climate resilience, health care and jobs.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1622669448619466790",
              text: "House Republicans want to hold the economy hostage unless they get cuts to essential programs like Medicare, Medicaid, Social Security, and SNAP. Absolutely not.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1509153779702194180",
              text: "The #BidenBudget shows how investing in the middle class has powered the strongest economic growth in nearly 40 years and put the deficit on track to drop by more than $1.3 trillion this year \u2013 the largest-ever one-year drop.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1576931977436897282",
              text: "We don\u2019t want our public schools to become an ideological battleground. We want to supply our classrooms, pay our teachers, and prepare our children for the future.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1623160210591305729",
              text: "Historic progress in lowering prescription drug costs, strengthening Social Security & Medicare, reducing child poverty, and fueling a manufacturing boom in clean energy and infrastructure\u2014all while reducing the deficit with fairer taxes on the wealthiest. Keep it coming @POTUS!",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1625943061250379776",
              text: "Democrats are rebuilding America by fixing our roads and bridges and bringing clean water and high-speed internet to more communities. And we are making the largest climate investments in history to generate clean, secure, American energy.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630240386991165440",
              text: "I authored the President's Billionaire Minimum Income Tax so those with the most pay the most.\n\nMeanwhile, Congressional Republicans want to slash taxes for the ultrarich and impose a massive national sales tax on everyone else.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1630938359546671104",
              text: "If House Republicans default on our debt, they will tank our economy and destroy millions of jobs. And hard-right extremists are willing to use American jobs and lives as a bargaining chip to extract cuts to Medicare, Social Security, SNAP, Medicaid and more.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1600522145280495620",
              text: "Overwhelming majorities of Republican voters support policies that Democratic lawmakers have long prioritized. I hope my GOP colleagues will join me in passing popular, commonsense ideas that voters on both sides of the aisle agree on. pic.twitter.com/cRvYtXIIlS",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1504121450604773382",
              text: "Even before the pandemic, Trump presided over soaring deficits. By the end of this year, Biden will have cut the deficit in half from what it was before he took office \u2014 all while providing historic middle class tax refunds, slashing child poverty and investing in infrastructure.",
            },
            {
              permalink:
                "https://twitter.com/RepCohen/status/1501631350197084163",
              text: "Republicans scream about \u201cDefund the Police\u201d but not one voted for a once-in-a-generation investment in police and violence prevention. Not one Republican voted to fund cash-strapped local governments and public safety programs. Action speaks louder than words. https://t.co/PpDwJQJVYK",
            },
          ],
          question:
            "What government spending priorities would you emphasize to ensure a balanced budget?",
        },
      ],
      summary:
        "Steve Cohen believes in tax reform that ensures everyone, particularly the affluent, pays their fair share. He authored the President's Billionaire Minimum Income Tax and introduced the Billionaire Minimum Income Tax Act, both steps towards ensuring that the wealthy contribute more. He's staunchly opposed to the proposed tax cuts for the ultra-rich by the Congressional Republicans. \n\nIn Cohen's plan to address tax evasion and loopholes, he focuses on closing provisions that allow the ultra-wealthy to avoid taxes on their fortunes. His proposals include the Billionaire Minimum Tax and the CLEAN Checks Act, designed to keep political self-dealing off federal dollars. He also advocates for removing the cap on Social Security taxes so millionaires would contribute more funds to sustain Social Security.\n\nOn government spending, Cohen places priority on education, climate resilience, healthcare, jobs, and infrastructure while safeguarding essential programs like Medicare, Medicaid, Social Security, and SNAP. He also believes in investing in police and violence prevention, and providing funds for local governments and public safety programs. He is also committed to fighting inflation, gas prices, and price gouging.",
    },
  },
  Voters: {
    Guns: {
      questions: [
        {
          answer:
            "I believe that universal background checks, banning mentally ill and felons from accessing firearms, and banning assault weapons are all effective gun control measures that can help reduce gun violence. ",
          posts: [
            {
              permalink:
                "https://twitter.com/kjwithafumanchu/status/1641501274850852864",
              text: "@ToddO243 @CoachHopper14 @MFlynnGT @AndrewMarchand @CoachBrentKey What type of gun control do you propose? What gun control law will 100% prevent criminals getting guns & not just take guns away from law abiding, responsible gun owners? Gun control would not have 100% prevented what happened in TN. Armed security may have prevented it though.",
            },
            {
              permalink:
                "https://twitter.com/ladylei/status/1647950716919595010",
              text: "Covenant had armed teachers. Can we please acknowledge that the action of making more guns available, increasing police, putting more police in schools, teaching kids how to act during mass shootings, putting stronger locks on reinforced doors, etc DOESN'T WORK? Gun control does",
            },
            {
              permalink:
                "https://twitter.com/CorrectsYourBS/status/1644917014874714112",
              text: "@GiffordsCourage Every school shooting happens in a gun free zone. The Tennessee shooter was stopped by a good guy with a gun. Which was more effective? Gun control or a gun? It's not a trick question and if you answer wrong, you are the problem.",
            },
            {
              permalink:
                "https://twitter.com/AisleSeat/status/1640441488176717832",
              text: '@RepMeuser Another school shooting in Tennessee. Several dead children. Will you actually commit to your "pro-life" stance and start supporting an assault weapon ban and other common sense gun control legislation?',
            },
            {
              permalink:
                "https://twitter.com/HunnyRabbit1968/status/1640445906980839446",
              text: "@janmaxwell @TN_Proud1776 @robbystarbuck Okay big shot what's your proposal for gun control how are you going to keep guns out of criminals hands you know the people that don't actually follow gun laws that we already have what's your solution",
            },
            {
              permalink:
                "https://twitter.com/MosesDenton9/status/1640462575317000193",
              text: "@harrisonjaime Pass common sense gun control legislation by universal background checks banning mentally ill and felons from accessing firearms an ban assault weapons, this wouldn't have happened if we did that and Tennessee has one of the least strictest gun control laws in the country",
            },
            {
              permalink:
                "https://twitter.com/learningchem/status/1641957865144328193",
              text: "@Jodyforstaterep Ever thought about doing your job and help keep TN safer by actually listening to people who elected you? Gun Control is needed",
            },
            {
              permalink:
                "https://twitter.com/AlfredSandy/status/1645408930799968256",
              text: "Great point. Guns is the issue that broke through. There are plenty of people in TN that want gun control and many more that don't want their children murdered when they're in school.",
            },
            {
              permalink:
                "https://twitter.com/T_Jsound/status/1644875262822354944",
              text: "@VP More Gun Control is not the answer, that would take guns out of good peoples hands. Security and guards much like banks is the answer. Trans are involved in the last shootings and violence. TN was a Trans with a Hunting riffle. I believe mental health is the issue.",
            },
          ],
          question:
            "What gun control measures do you believe will be effective in reducing gun violence?",
        },
        {
          answer:
            "I believe that it is possible to both protect Second Amendment rights and ensure public safety. We can do this by passing strong regulations on gun ownership, such as background checks, age limits, and limits on certain types of weapons. We should also work to educate Tennesseans that these regulations are constitutionally sound. By taking these steps, we can ensure that gun ownership is safe and responsible while still respecting the Second Amendment. ",
          posts: [
            {
              permalink:
                "https://twitter.com/midniterdr2010/status/1643747879385972736",
              text: "Do people even realize that if you ban guns, you will now have to confront criminals hand to hand. If they have a weapon and you don't, you're screwed. The government will also be free to do whatever they want to the American people. That's why we have the Second Amendment",
            },
            {
              permalink:
                "https://twitter.com/SavRTruthTiger/status/1643328083418845184",
              text: "Standing with gun safety advocates? Makes complete sense to me. \nThe 2nd Amendment needs an amendment. Its current interpretation bucks more than 200 years of precedent. And common sense.",
            },
            {
              permalink:
                "https://twitter.com/MJBondNashville/status/1640432979125084161",
              text: "@GovBillLee @TNDeptofSafety @TNHighwayPatrol Respect the Second Amendment and pass strong regulations on gun-ownership, please.\n\nSo far, you & the General Assembly are, essentially, handing out guns like candy.\n\nAt this rate, today's school shooting will be far from the last one in Tennessee.",
            },
            {
              permalink:
                "https://twitter.com/MJBondNashville/status/1640408399895511047",
              text: "@MarshaBlackburn Respect the Second Amendment and pass strong regulations on gun-ownership, please.\n\nOur current governor & General Assembly are, essentially, handing out guns like candy.\n\nAt this rate, today's school shooting will be far from the last one in Tennessee.",
            },
            {
              permalink:
                "https://twitter.com/MJBondNashville/status/1640434113143361537",
              text: "@tedcruz Respect the Second Amendment and pass strong regulations on gun-ownership, please.\n\nTennessee's governor & General Assembly are, essentially, handing out guns like candy.\n\nAt this rate, today's school shooting will be far from the last one in Tennessee.",
            },
            {
              permalink:
                "https://twitter.com/eternallyfree11/status/1643382554693623811",
              text: "@tnhousegop @GovBillLee I hope TN Republicans will keep in mind that gun control activists rely on overly simplistic arguments based on raw emotion not based in facts, data, or a deeper understanding of the dangers of disarming the citizenry.  The 2nd Amendment protects all the others.",
            },
            {
              permalink:
                "https://twitter.com/Liberals4Liber1/status/1648921605085470724",
              text: "@brotherjones_ @VoteGloriaJ The second amendment refers to a well regulated militia not individuals going around shooting one another with AR-15s because TNGOP politicians have no balls to stand up to gun manufacturers, NRA & the white supremacist TN Firearms Association who supports armed insurrection.",
            },
          ],
          question:
            "How do you plan to balance Second Amendment rights with public safety concerns?",
        },
        {
          answer:
            "I support policies that promote responsible gun ownership, such as universal background checks, banning mentally ill and felons from accessing firearms, and banning assault weapons. These measures will help ensure that guns stay out of the wrong hands and protect the safety of our communities. ",
          posts: [
            {
              permalink:
                "https://twitter.com/AisleSeat/status/1640441488176717832",
              text: '@RepMeuser Another school shooting in Tennessee. Several dead children. Will you actually commit to your "pro-life" stance and start supporting an assault weapon ban and other common sense gun control legislation?',
            },
            {
              permalink:
                "https://twitter.com/MJBondNashville/status/1640408399895511047",
              text: "@MarshaBlackburn Respect the Second Amendment and pass strong regulations on gun-ownership, please.\n\nOur current governor & General Assembly are, essentially, handing out guns like candy.\n\nAt this rate, today's school shooting will be far from the last one in Tennessee.",
            },
            {
              permalink:
                "https://twitter.com/Eden_CJ/status/1645970960649003008",
              text: ".@SarahHuckabee \u201cWoke Liberal\u201d Gov of TN supporting sensible strengthening of gun control laws. Would you like to do the same? \n(Quotations are sarcasm if you can\u2019t infer tone)",
            },
            {
              permalink:
                "https://twitter.com/MJBondNashville/status/1640432979125084161",
              text: "@GovBillLee @TNDeptofSafety @TNHighwayPatrol Respect the Second Amendment and pass strong regulations on gun-ownership, please.\n\nSo far, you & the General Assembly are, essentially, handing out guns like candy.\n\nAt this rate, today's school shooting will be far from the last one in Tennessee.",
            },
            {
              permalink:
                "https://twitter.com/MJBondNashville/status/1640434113143361537",
              text: "@tedcruz Respect the Second Amendment and pass strong regulations on gun-ownership, please.\n\nTennessee's governor & General Assembly are, essentially, handing out guns like candy.\n\nAt this rate, today's school shooting will be far from the last one in Tennessee.",
            },
            {
              permalink:
                "https://twitter.com/saltyseagully/status/1640405631201951757",
              text: "@laurenboebert How about today\u2019s shooting at a Tennessee elementary school? Are you telling parents about common sense gun control ti help keep their kids safe?",
            },
            {
              permalink:
                "https://twitter.com/Kimberl69490204/status/1657050030124023809",
              text: "@justjimandstuff @theserfstv So you're saying we need better gun control? Because Republicans in Tennessee let the last mass shooter buy her guns LEGALLY.",
            },
            {
              permalink:
                "https://twitter.com/MosesDenton9/status/1640462575317000193",
              text: "@harrisonjaime Pass common sense gun control legislation by universal background checks banning mentally ill and felons from accessing firearms an ban assault weapons, this wouldn't have happened if we did that and Tennessee has one of the least strictest gun control laws in the country",
            },
          ],
          question:
            "Are there policies to promote responsible gun ownership that you support?",
        },
      ],
      summary:
        "The voters in Tennessee have expressed a desire for a balanced approach to gun control, respecting both the Second Amendment rights and public safety concerns. They are in favor of universal background checks and feel that certain groups, like the mentally ill and felons, should be restricted from accessing firearms. There's a notable concern in the state towards assault weapons, with proposals for a ban being supported. At the same time, Tennesseans wish to maintain their constitutional rights to bear arms responsibly and safely. Education regarding safe and responsible gun ownership seems to be welcomed.",
    },
  },
};

const comparisonsDb = {
  "Marsha Blackburn (2020)Marsha Blackburn (2024)": {
    Abortion: {
      introduction:
        "Marsha Blackburn's position on abortion seems to have remained consistent between the years 2020 and 2023. In both years, she held a pro-life stance, opposing abortion in most if not all circumstances. ",
      positions: [
        "In 2020 and 2023, Blackburn supported and possibly sponsored legislations aimed at restricting abortion access, particularly regarding late-term abortions. In 2020, she highlighted her involvement in introducing the Title X Abortion Provider Prohibition Act, aiming to defund abortion providers. Her stand on this issue appears consistent in 2023.",
        "She also maintained her advocacy for healthcare policies that would offer alternatives to abortion. In 2023, she also emphasized her support for policies improving overall access to quality healthcare and lowering patient costs.",
      ],
      conclusion:
        "In summary, Blackburn's stance in both years reflect a conservative, pro-life view with a focus on legislation to restrict abortion access and increase alternatives and healthcare quality.",
    },
    "Civil Liberties": {
      introduction:
        "In both 2020 and 2023, Marsha Blackburn remains strongly committed to protecting individual civil liberties, especially in navigating the digital landscape. Her focus on preserving privacy within digital spaces and advocating for transparency by big tech companies is evident in both years.",
      positions: [
        "However, Blackburn's commitments have evolved substantively in terms of legislation by 2023. In 2020, Blackburn was mostly involved in establishing basic privacy and security standards, and heading the Senate Judiciary's Tech Task Force. By 2023, her efforts grow to include more specific and targeted legislative measures such as introducing the BROWSER Act, examining national security risks linked to sister city agreements with cities in Communist China, supporting the Civilian Cyber Security Reserve, and sponsoring the Kids Online Safety Act. Her legislative work to counteract perceived threats to civil liberties from certain left policies also indicates her increased efforts by 2023.",
      ],
      conclusion:
        "Overall, Blackburn's commitment to civil liberties remains consistent over time, but her method of protecting these liberties becomes more focused and actionable, transforming from mere advocacy to direct legislative response.",
    },
    "Civil Rights": {
      introduction:
        "When comparing Marsha Blackburn's views on Civil Rights between 2020 and 2023, it appears many of her stances remained consistent while some may have evolved.",
      positions: [
        "1. LGBTQ+ Rights: In both years, Blackburn reiterates her commitment to treating all Americans equitably, which extends to members of the LGBTQ+ community. While she emphasized equal treatment under the law in 2020, by 2023, she specifically supports legislation like the Equality Act that seeks to combat discrimination in various aspects of life, underscoring a possible evolution in her stance or a more explicit expression of her views.",
        "2. Racial and Gender Equality: Blackburn's position here remains consistent with her advocating for the elimination of laws that inhibit equal opportunities based on race or gender in 2020. By 2023, her stance broadens beyond laws to include addressing subconscious bias and ensuring equal access to resources and opportunities.",
        "3. Voting Rights: Blackburn's position on voting rights appears to shift somewhat over the three years. While she endorses policies to protect election integrity like paper ballots and post-election audits in 2020, by 2023 she focuses more on voter ID laws and preventing federalization of elections - perhaps in reaction to the political context at that time.",
        "  ",
      ],
      conclusion:
        "Overall, the comparison indicates that Blackburn continues to prioritize Civil Rights, with her platform encompassing various topics within that area. The differences over time reflect either a slight shift in perspectives or a response to changing political atmospheres and contexts.",
    },
    Crime: {
      introduction:
        "Marsha Blackburn's positions on crime in 2020 and 2023 both showcase her commitment to enhancing safety within communities, holding criminals accountable, and supporting law enforcement personnel. These continuous objectives can be seen through her focus on decreasing sanctuary cities and increasing resources for law enforcement.",
      positions: [
        "In 2020, Blackburn was all about drafting legislation to combat various types of crime, such as asylum fraud and human trafficking, reiterating her stance on strict immigration laws. She was also firmly committed to addressing the root causes of crimes and proposed the CLEAR Act to provide local law enforcement the necessary resources to deport criminal aliens. Moreover, Blackburn was in favor of legislation to break the incarceration cycle and aimed to reduce recidivism through bipartisan solutions like the #FIRSTSTEPAct.",
        "By 2023, Blackburn's position remained firm on enforcing strict punishments for criminals and continuing to oppose soft-on-crime policies. However, the emphasis appears to have shifted to more directly supporting law enforcement agencies through additional resources and funding. She introduced the Restoring Law and Order Act to increase resources for evidence processing and crime labs. Blackburn also co-sponsored legislation to reauthorize the Debbie Smith Act with a goal to eliminate the rape kit backlog. Furthermore, she proposed the idea of hiring veterans and retired law enforcement officers to bolster school safety. ",
      ],
      conclusion:
        "In conclusion, Blackburn's stance on crime from 2020 to 2023 maintained a singular focus on enhancing community safety, reducing crime, supporting law enforcement, and holding criminals accountable. However, the strategy to achieve these outcomes saw an increased emphasis on resource allocation and legislation that directly impacts law enforcement agencies and processes by 2023.",
    },
    Education: {
      introduction:
        "Marsha Blackburn's positions on education in 2020 and 2023 share a commmon emphasis on improving the quality of public education and increasing access to higher education. She consistently supports legislation that fosters innovation, accountability, and transparency in educational institutions.",
      positions: [
        "However, there seem to be some nuances and evolvement in her views over the years. In 2020, her support was specifically noted for the Higher Education Reform and Opportunity Act, the CHOICE Act, and cybersecurity and computer science skills in students. Attention given to student loan debt centered on college and universities controlling tuition costs and preparing students for job markets.",
        "By 2023, Blackburn's focus on educational choice has broadened and she now advocates for more parental control and the notion that funding should follow the student. Her approach to academic issues now involves support for such initiatives as the SAFE School Act, which offers funds to train veterans and ex-law enforcement officers as school safety officers.",
        "Regarding student loan debt and financial issues, in 2023 she proposes reducing federal spending on non-defense items and decreasing national debt, expressing concerns about increasing the burden of debt on future generations with excessive spending and taxation. ",
      ],
      conclusion:
        "So, while her core principles about education quality, access, and financial responsibility remain the same, the specific policies and areas of interest seem to have evolved over these three years.",
    },
    "Environmental and Climate Policy": {
      introduction:
        "In both 2020 and 2023, Senator Marsha Blackburn advocated for a balanced approach to environmental and climate policy. She emphasized the importance of addressing climate change while also ensuring the economy and livelihoods of Americans are not negatively affected. ",
      positions: [
        "In 2020, Blackburn stressed on the need to invest in clean energy sources, reduce emissions, and support realistic fuel economy standards. She also pushed for a reduction in taxation, regulation, and litigation and wanted a pro-American trade policy to reduce greenhouse gas emissions. In addition to these, she called for policies to modernize the Environmental Protection Agency and rein in its overreach.",
        "In 2023, while she maintained her approach to the economy and environment, her concerns were specifically about existing environment-related decisions and policies. Blackburn disagreed with halting the construction of the Keystone Pipeline, rejoining the Paris Climate Agreement, and she voiced concerns about the potential impact of measures like the Green New Deal. She believed that decisions should prioritize the needs of local communities over the broader environmental aspirations of the federal government.",
      ],
      conclusion:
        "Overall, there's a notable evolution of Senator Blackburn's views from 2020 to 2023. In both scenarios, while she seeks to balance environmental preservation and economic growth, she becomes more skeptical of efforts that, in her view, prioritize environmental concerns at the risk of damaging the economy.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      introduction:
        "Marsha Blackburn's position on Foreign Policy, including the Ukraine conflict, remains consistent over the years. She stands firmly against Russian aggression and supports Ukraine in protecting its territorial integrity.",
      positions: [
        "In 2020, Blackburn expressed support for Ukraine and focused on countering disinformation and applying economic and diplomatic pressure on Russia to decrease the conflict. In contrast, in 2023, her stance became more aggressive, supporting the provision of lethal defensive military aid to Ukraine and imposing stiff sanctions on Russia, including removing Russia from SWIFT and targeting the nation’s energy sector.",
        "Blackburn's concern over China's growing influence was also evident in both periods, with measures put forth to confront China's sway within international organizations and opposing the CCP's global domination agenda. However, her approach seems to have become more targeted by 2023, focusing on not just countering but also assessing potential national security threats stemming from sister city agreements with cities in Communist China.",
        "In terms of alliances, Blackburn emphasized the strengthening of military relationships with countries that uphold American values of freedom and democracy, such as Taiwan, Japan, and Israel. The importance of these alliances was present in both 2020 and 2023.",
        "On trade agreements, both in 2020 and 2023, Blackburn championed pro-American policies that reduce taxation and regulation and that strengthen American manufacturing.",
      ],
      conclusion:
        "Lastly, Blackburn's preference for diplomatic solutions over military intervention remained consistent over the years, with a commitment to maintaining the US's commitments to the Kurdish people and active diplomatic engagement. However, her approach to international conflicts has intensified, focusing on strengthening America's military presence globally by 2023.",
    },
    Guns: {
      introduction:
        "Marsha Blackburn's position on gun rights in both 2020 and 2023 centers around balancing Second Amendment rights with efforts to reduce gun violence. In both years, she emphasized the importance of responsible gun ownership and providing resources for law enforcement and mental health services.",
      positions: [
        "In 2020, she advocated for preventing access to firearms for individuals who pose a threat to themselves or others. She also sought to protect citizens' right to self-defense and stressed the importance of safe gun ownership. She argued for addressing mental illness as a strategy to curb gun violence.",
        "By 2023, Marsha Blackburn's position had further crystallized with a more extensive emphasis on tackling underlying issues related to gun violence such as drug usage, gangs, and violent crime. She introduced amendments to gun control packages to uphold responsible gun ownership and proposed employing veterans and former law enforcement officials in school security. Her advocacy for mental health treatment remained consistent, reflecting her belief in addressing the root causes of gun violence. Additionally, she introduced the Restoring Law and Order Act to support police efforts in combating violent crime.",
      ],
      conclusion:
        "Overall, while Blackburn's commitment to the Second Amendment and addressing the socio-economic factors contributing to gun violence remained consistent, the specific strategies and legislations she proposed evolved over time, reflecting a focus on broader crime reduction and community safety.",
    },
    Healthcare: {
      introduction:
        "In both 2020 and 2023, Marsha Blackburn maintains her commitment to making healthcare more affordable, accessible, and efficient for all Americans. Her stance on increasing competition, encouraging healthcare innovation, and improving access to care for rural communities remains consistent. The introduction of the Rural Healthcare Agenda in 2020, aiming to expand telehealth services, sets the foundation for her continued dedication to this agenda in 2023, when she works on bipartisan legislation to increase access to care and decrease patient costs.",
      positions: [
        "There are also shifts in her focus between 2020 and 2023. In 2020, she placed heavy emphasis on repealing Obamacare and preventing a government takeover of healthcare. By 2023, her focus appears to have shifted somewhat towards keeping rural hospitals open with the Save Rural Hospitals Act, returning decision-making power on healthcare to the states, and eliminating loopholes for big abortion providers' access to Title X family planning funds. Additionally, while the goal of reducing healthcare fraud was a notable part of her 2020 position, there is a broader approach to systemic efficiency and reducing government waste by 2023.",
      ],
      conclusion:
        "The introduction of the Over-the-Counter Hearing Aid legislation in 2023 shows a new area of focus for Blackburn, aiming to make hearing aids more accessible. It also suggests an expanded interest in helping specific vulnerable populations within the healthcare system. Overall, while her core principles remain consistent, Blackburn's specific legislative focuses and strategic approaches show evolution between these time periods.",
    },
    Immigration: {
      introduction:
        "Comparing the stated positions of Marsha Blackburn in 2020 and 2023, it is clear that her basic stance on immigration largely remains consistent. Both in 2020 and 2023, she emphasizes strict enforcement of laws and the prevention of illegal immigration. ",
      positions: [
        "In 2020, Blackburn championed an end to chain migration and the visa lottery system, and she advocated for strong border control measures including physical barriers, more technology, and more agents. ",
        "By 2023, it seems her measures to control illegal immigration became slightly more aggressive. She advocates for maintaining the 'Remain in Mexico' policy and keeping Title 42, which were not mentioned in 2020 platform. ",
        "In both years, she is against amnesty for undocumented immigrants and insists on the importance of rewarding only those who follow the legal immigration process. However, there is even more emphasis on resourcing the Border Patrol and CBP in her 2023 stance.",
      ],
      conclusion:
        "On the whole, both in 2020 and 2023, Blackburn's position is one of strict control on immigration with emphasis on lawful immigration and border security.",
    },
    "Inflation and Prices": {
      introduction:
        "Marsha Blackburn's positions on the issue of inflation and prices from 2020 to 2023 hold consistent key themes but also depict an evolution in tactics used to address these issues during this period.",
      positions: [
        "In both 2020 and 2023, Blackburn remains committed to reducing the impact of inflation on everyday Americans. She consistently supports policies that encourage competition and free markets while reducing taxation and regulation, and she wishes to alleviate the financial burden on American families.",
        "In 2020, Blackburn focused on a targeted approach to mitigating inflation, which included fiscal responsibility and reducing unnecessary regulations. She prioritized transparency at the Federal Reserve, and removing bureaucracy and obstacles that negatively affected business growth and consumer rights.",
      ],
      conclusion:
        "By 2023, her approach evolved notably in terms of tangible legislative actions. She introduced specific legislation, the Stop The Inflationary Spending Spree Act, if an effort to curb excessive governmental spending. Furthermore, she evolved her platforms to address specific causes of inflation such as gas prices and supply chain issues, demonstrating an increased focus on mitigating price-increasing factors. The introduction of the act shows a clear shift towards action-oriented policies and a desire to be more proactive in managing inflation rates.",
    },
    "Jobs & the Economy": {
      introduction:
        "In both 2020 and 2023, Marsha Blackburn has consistently advocated for policies that promote job growth and decrease unemployment. Her stance on reducing taxes, regulations, and the role of government in business have remained consistent, reflecting her belief in free-market principles. ",
      positions: [
        "However, there have been some shifts and expansion in her focus over time. In 2020, she emphasized the role of higher education in preparing students for the job market, which is a point not raised in 2023. In 2023, she broadened her vision to tackle economic issues including increasing access to healthcare, fostering US manufacturing, and boosting American energy; these aspects were not explicitly mentioned in her 2020 positions.",
        "In terms of small businesses, while in 2020 she focused on providing access to the Paycheck Protection Program, in 2023 she did not specifically mention it, but her support for less government intervention and taxation remained the same. ",
      ],
      conclusion:
        "Finally, on income inequality and wage growth, both years she maintained her stance against high federal minimum wage and large stimulus spending. She generally aims to lower everyday costs for Americans and protect American workers through competitive policies. It appears she evolved her view on tax code, as it was a point of conversation in 2020, but not directly addressed by 2023. Rather, in 2023 her focus has expanded to broader quality of life issues such as healthcare access.",
    },
    "National Security": {
      introduction:
        "In both 2020 and 2023, Marsha Blackburn prioritized and stressed the importance of national security in her positions. She maintained a commitment to cybersecurity, data privacy, and the protection of citizens.",
      positions: [
        "In 2020, Blackburn focused on strict privacy standards for data security, eliminating political bias within FBI, counterterrorism efforts, cybersecurity protection, and creation of a more secure technological landscape. She supported educational initiatives, like high school training in computer science and cybersecurity, aimed at nurturing a future generation equipped to protect national cybersecurity.",
        "By 2023, Blackburn's position had evolved to present a more comprehensive approach to national security. While maintaining her 2020 principles, she further integrated the issue of data privacy into national security measures and introduced specific legislation, such as the Civilian Cyber Security Reserve Act and the Kids Online Safety Act. She also emphasized the importance of research and development institutions in the context of national security.",
        "Additionally, in 2023, she expressed concerns over international relations posing potential threats to national security and advocated for legislation to assess such risks. She remained committed to strengthening intelligence agencies by ensuring they had the necessary resources to effectively respond to threats. ",
        "Her philosophy on personal data privacy also evolved as she advocated for her Browser Act, allowing users to choose their level of participation in data collection based on the sensitivity of their information. ",
      ],
      conclusion:
        "Thus, while her core values remained consistent, Blackburn's approach to national security deepened and broadened from 2020 to 2023.",
    },
    Taxes: {
      introduction:
        "Marsha Blackburn's stance on Taxes has been consistent from 2020 to 2023 and her goals remain largely aligned.",
      positions: [
        "1. Tax Reform: Both in 2020 and 2023, Blackburn supports tax reforms and considers it crucial to making the tax code flatter, simpler, and fairer for all Americans.",
        "2. Tax Burden Relief: In both timespans, Blackburn strives to alleviate the tax burden for citizens and advocates for tax relief, especially for hardworking Americans and small businesses.",
        "3. Government Spending: Both in 2020 and 2023, she championed oversight in how taxpayer money is spent and is in favor of reducing federal spending. While she proposed a 1% across-the-board cut in 2020, by 2023, she specifically targets unnecessary programs for cuts and encourages streamlining within the Department of Commerce.",
        "4. Tax Loopholes and IRS Accountability: Blackburn continues her effort to close tax loopholes and hold the IRS accountable, ensuring everyone pays their fair share.",
        "5. Focus on National Debt: While she emphasized fiscal responsibility in 2020, emphasis in 2023 is directly toward addressing the US's enormous federal debt, showing a more targeted focus on fiscal health.",
        "6. Opposition to Left-led Tax Increases: A new point added in 2023 is her opposition against tax hikes to fund what she perceives as left-leaning policies which could spur inflation and job loss, illustrating increased party policy divergence.",
      ],
      conclusion:
        "7. Military Resources: Whereas in her 2023 position, she includes a particular emphasis on providing the military with the necessary financial resources, which was not emphasized in 2020.",
    },
  },
  "Marsha BlackburnSteve Cohen": {
    Abortion: {
      introduction:
        "Steve Cohen and Marsha Blackburn have distinctly opposing views on the issue of abortion. ",
      positions: [
        "Steve Cohen strongly supports a woman's right to abortion. He advocates for the Women's Health Protection Act, which aims to ensure women can make decisions about their own bodies and lives, including access to abortion services. Cohen supports government policies that make healthcare, including abortion, more affordable and accessible, and believes in the separation of government interference in private matters. ",
        "On the other hand, Marsha Blackburn opposes the legality of abortion and has consistently voted against legislation that seeks to expand access to it. She actively supports restrictions on abortion, arguing that they are in the best interest of the safety of women and their unborn children. However, similar to Cohen’s approach with offering alternatives to abortion, she also supports increasing healthcare access and reducing patient costs. ",
      ],
      conclusion:
        "In conclusion, their positions highlight a fundamental disagreement over abortion rights, with Cohen advocating for a woman's right to choose and Blackburn arguing for the protection of the unborn.",
    },
    "Civil Liberties": {
      introduction:
        "Both Steve Cohen and Marsha Blackburn are committed to defending civil liberties, including freedom of speech and privacy. However, they have different focuses and approaches towards these topics.",
      positions: [
        "Cohen's approach tends to focus on the preservation of civil liberties through acts like the Free Speech Protection Act, which is designed to deter lawsuits intended to suppress freedom of speech, and the National Statistics on Deadly Force Transparency Act, that seeks to increase transparency in the use of police force. His policies and bills are geared to defending the rights of marginalized groups and journalists, among others.",
        "On the other hand, Blackburn's initiatives revolve around protecting citizen's privacy and safety online, with a particular focus on children’s online safety and holding big tech companies accountable for handling user data responsibly. She is also concerned with the limitation of governmental overreach and safeguarding first amendment rights.",
      ],
      conclusion:
        "Hence, while both Cohen and Blackburn share a commitment to the protection of civil liberties, their specific areas of focus and legislative approach differ. Cohen's initiatives are more centered on personal civil liberties and protections particularly for marginalized groups, while Blackburn puts more emphasis on privacy concerns and online safety in the age of digital technology.",
    },
    "Civil Rights": {
      introduction:
        "Steve Cohen and Marsha Blackburn both support protecting the rights of all American citizens; however, they hold divergent views on how to best achieve this goal.",
      positions: [
        "Cohen strongly supports expansive federal legislation to protect and advance civil rights. He has actively pushed for marriage equality and safeguards against discrimination for the LGBTQ+ community. Regarding racial and gender equality, Cohen has proposed bills for housing and employment protection. He fiercely advocates for voting rights and has introduced the John Lewis Voting Rights Act and the Freedom to Vote Act. Cohen also supports ending the filibuster to push through these civil rights-oriented legislations. ",
        "Blackburn, on the other hand, supports measures to prevent discrimination against LGBTQ+ individuals and ensure access to healthcare. Unlike Cohen, Blackburn opposes the federalization of elections and supports voter ID laws, framing them as a means of safeguarding the ballot box against potential fraud. She advocates for the rights of military personnel and opposes vaccine mandates. However, there is no specific stance recorded regarding her efforts to promote racial and gender equality across sectors such as employment and housing. ",
      ],
      conclusion:
        "Overall, both politicians advocate for civil rights, albeit through different approaches and legislative emphasis. Cohen's strategies lean towards broad federal legislative efforts, while Blackburn tends to prefer protection of rights through specific measures and emphasis on state rather than federal control of elections.",
    },
    Crime: {
      introduction:
        "Steve Cohen and Marsha Blackburn, while both acknowledging the importance of addressing crime, have very different views about the best strategies to reduce and manage it.",
      positions: [
        "Cohen's approach is more focused on reforming the criminal justice system, identifying and addressing the root causes of crime, and reducing mass incarceration. He shows a deep commitment in ending racial disparities in sentencing and policing. He supports acts such as the George Floyd Justice and Policing Act, advocates for legislation to combat gun violence, and emphasizes the importance of fairness and equity.",
        "On the other hand, Blackburn has a stricter viewpoint on crime, arguing for tough penalties for criminals and increased resources for law enforcement. She believes in the necessity of a robust criminal justice system and fears that 'soft-on-crime' policies could lead to increased crime rates. For Blackburn, addressing root causes of crime significantly involves reinforcing law enforcement resources and ending 'soft-on-crime' policies.",
      ],
      conclusion:
        "To summarize, while both politicians acknowledge the necessity of dealing with crime, Cohen advocates more for systemic reforms and aims to address root causes tied to societal issues, while Blackburn supports stricter penalties, increased resources for law enforcement, and policies that emphasize holding both criminals and authorities accountable. It's worth noting that these points reflect their respective party perspectives on crime: the Democrats' focus on systemic reforms and societal causes, and the Republicans' stress on law and order.",
    },
    Education: {
      introduction:
        "Both Steve Cohen and Marsha Blackburn are committed to improving the quality of public education and increasing access to higher education. They both agree on equipping students with necessary skills and knowledge needed for the workforce and prioritizing resources for teachers.",
      positions: [
        "However, there are significant differences in their positions as well. Cohen puts a high emphasis on diversity and inclusion in schools, something not explicitly highlighted by Blackburn. He also supports robust public funding for education, including measures to forgive student loan debt. This contrasts with Blackburn's stance that opposes any spending that could increase federal debt, including widespread measures to forgive student loan debt.",
        "Both politicians want to avoid turning public schools into a battleground for ideological debates, but their perspectives on what that means are not identical. Blackburn specifically opposes pushing a progressive agenda in schools, while Cohen is focused on stopping the militarization and politicization of educational environments. ",
        "In terms of legislation, Blackburn has specifically endorsed the SAFE School Act to improve educational outcomes, whereas Cohen has spearheaded a multi-billion dollar initiative for workforce development and training programs. ",
      ],
      conclusion:
        "Lastly, Cohen has proposed unique approaches to funding such as removing the cap on Social Security taxes for millionaires, and instituting a Billionaire Minimum Income Tax Act to fully fund education. Blackburn's positions don't include specific strategies for increased funding but are more focused on reducing tuition costs and increasing access to financial aid.",
    },
    "Environmental and Climate Policy": {
      introduction:
        "Steve Cohen and Marsha Blackburn both show a commitment to addressing climate change and advocating for environmental sustainability, although their approaches differ.",
      positions: [
        "Steve Cohen supports more progressive environmental and climate policies. He is a staunch advocate for measures like the Inflation Reduction Act, which is aimed at innovative initiatives to tackle climate pollution. Cohen supports heavily investing in renewable energy and clean transportation. He is in favor of implementing environmental justice provisions and reducing waste and emissions, with actions like phasing out single-use plastics. His main objectives align with President Biden's goal of Zero Carbon by 2035, advocating for the largest climate investments in the nation's history.",
        'On the other hand, Marsha Blackburn, while acknowledging the need for eco-friendly practices, leans towards more conservative policies. She believes in an "all-of-the-above" approach to cleaner energy, which includes a balance of renewable and non-renewable energy sources, to reduce American foreign oil dependence. Blackburn criticizes President Biden\'s environmental and climate policies, believing they are misguided and don\'t fulfill America\'s requirements. She expressed criticism over halting the Keystone pipeline and rejoining the Paris Climate Agreement. She also voiced concerns over President Biden\'s intention to spend $300 billion for climate change and his focus on "inclusion" and "environmental justice." ',
      ],
      conclusion:
        "In conclusion, both politicians agree on the importance of addressing climate change, yet they differ significantly in their policy approaches and their degrees of alignment with current presidential policies.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      introduction:
        "Both Steve Cohen and Marsha Blackburn are in agreement about opposing Russia's aggression towards Ukraine and support providing Ukraine with military aid. They endorse strong sanctions against Russia and agree on the need to provide support to allies. However, their approach to dealing with these international issues varies.",
      positions: [
        "Steve Cohen focuses on a broad internationalist approach. He actively supports international organizations and alliances, emphasizing the role of the United States as a proactive global leader working alongside its allies. Cohen is particularly interested in multilateral diplomacy, the maintenance of western values, and rooting out corruption as demonstrated by his Counter-Kleptocracy Act.",
        "Cohen's emphasis is on using diplomacy and global cooperation for conflict resolution. He believes the USA should have an active role in international organizations, working for security in places like Ukraine and opposing threats to peace.",
        "On the other hand, Marsha Blackburn adopts a more hardline, isolationist approach to foreign policy. She advocates for strengthening American military power and fortifying alliances with like-minded countries. Blackburn supports sanctioning measures against Russia, particularly their energy sector, and advocates for energy independence in America. ",
        "Blackburn's main focus lies in pushing back against potential threats, such as the Chinese Communist Party's global aspirations. She is more hawkish, advocating for a strong military and a rigorous assessment of national security threats related to foreign trade agreements. She also sees a need for a strong U.S. diplomatic presence, particularly in the Pacific Islands, to balance China's growing influence.",
      ],
      conclusion:
        "In summary, while both politicians believe in supporting Ukraine against Russian aggression and promoting American interests, Cohen tends to favor a multinational diplomatic approach, while Blackburn emphasizes strong military backing, a tough stance on potential threats, and unilateral international actions.",
    },
    Guns: {
      introduction:
        "Steve Cohen and Marsha Blackburn have differing views on gun regulation, although there is some overlap.",
      positions: [
        "Both politicians believe in responsible gun ownership and support strengthening the background check system. They're both also against providing firearms access to those who're prohibited from owning them. ",
        "The major difference lies in their views on gun control measures. Steve Cohen is in favor of implementing strict gun control regulations, including a comprehensive weapons ban, background checks, and passing red flag laws. He further supports the regulation of areas where firearms are allowed, such as schools, hospitals, and areas serving alcohol.",
      ],
      conclusion:
        "On the other hand, Marsha Blackburn is against some gun control measures, particularly those she perceives as infringing on Second Amendment rights. She advocates for Second Amendment rights and proposes initiatives to hire veterans and former law enforcement officials for school safety. Instead of gun control measures, her focus diverts to prosecution policies aiming to keep drugs, gangs, and violent criminals out of communities.",
    },
    Healthcare: {
      introduction:
        "Steve Cohen and Marsha Blackburn both display a commitment to making healthcare more accessible and affordable to all Americans, however, they propose notably different approaches to achieve these common goals due to their different political affiliations.",
      positions: [
        "Steve Cohen, a Democrat, actively supports strengthening the Affordable Care Act, targeting high prescription drug prices, and advocating for healthcare measures such as capping out-of-pocket costs for seniors, limiting insulin prices, and expanding child tax credits. He supports a Medicare reform bill intended to pressure pharmaceutical companies to lower costs and opposes potential Republican efforts to maximize prices. He also advocates for the Women's Health Protection Act and believes in availability of abortions regardless of income or location.",
        "Marsha Blackburn, a Republican, is focused on the Rural Healthcare Agenda with the end goal of heightening access to care and reducing patient costs, particularly in rural areas. She advocates for trade policies designed to stimulate competition within the US to help keep healthcare costs down. Blackburn also believes in reducing federal spending for non-defense items. She is working in collaboration with healthcare innovators to enact specific healthcare reforms.",
      ],
      conclusion:
        "In summary, while Cohen prefers to work on existing laws and bring reforms, Blackburn focuses on promoting competition and changing spending dynamics in the healthcare sector. Both the politicians have the same end goals of increased access and affordability, but their strategies and approaches vary.",
    },
    Immigration: {
      introduction:
        "Steve Cohen and Marsha Blackburn have contrasting views on immigration.",
      positions: [
        "Steve Cohen is more inclined towards compassionate immigration reform, advocating for a compassionate, respectful and fair immigration system. He supports creating a pathway to citizenship for undocumented immigrants living in the U.S and believes in treating all immigrants with dignity and respect. Cohen also supports a humane border security system, whereas Blackburn is more focused on increased border security and law enforcement.",
        "Marsha Blackburn's position centres on stricter immigration enforcement. She supports increased border security and law enforcement measures such as building a border wall, increasing technological advancements at the border, stationing more agents and maintaining the remain-in-Mexico policy. She also insists on strict enforcement of existing immigration laws especially against those who enter the country illegally.",
      ],
      conclusion:
        "While both Cohen and Blackburn advocate for a fair and just immigration system, Cohen emphasizes on humaneness and inclusivity, whereas Blackburn emphasizes on national security and strict law enforcement.",
    },
    "Inflation and Prices": {
      introduction:
        "Steve Cohen and Marsha Blackburn have different approaches to dealing with inflation and prices.",
      positions: [
        "Steve Cohen tends to support governmental interventions. He backs legislation such as the Inflation Reduction Act, measures to regulate Big Pharma prices and policies designed to ensure that the wealthiest individuals and corporations contribute their fair share in taxes. He has also helped to pass legislation aimed at lowering Medicare prescription prices, capping out-of-pocket costs for seniors and expanding the child tax credit. In general, he favors strategies that are intended to stabilize prices, reduce healthcare and prescription drug costs and protect essential programs like Medicare, Medicaid and Social Security.",
        "Marsha Blackburn, on the other hand, supports economic approaches to curb inflation. She proposes reductions in government spending and is an advocate for stimulating job creation as a way to strengthen the economy. Blackburn also supports measures to increase access to healthcare and decrease patient costs. She believes that lower gas prices can help fight inflation, supporting the approval of new drilling permits and the development of new energy infrastructure. Blackburn favors technology and market-based policies, such as the Open App Markets Act, and is opposed to left-leaning spending proposals that she believes could lead to job losses and increased inflation.",
      ],
      conclusion:
        "In summary, Steve Cohen appears to focus on legislation that directly controls prices and protects social security provision, whereas Marsha Blackburn places more emphasis on boosting the economy, reducing government spending, and encouraging market competition as measures to combat inflation and control prices.",
    },
    "Jobs & the Economy": {
      introduction:
        "Steve Cohen and Marsha Blackburn have both expressed their commitment to stimulating job growth and reducing unemployment. Their methods, however, show distinct differences consistent with their party affiliations.",
      positions: [
        "Cohen, supporting a bottom-up approach, stresses the importance of implementing various initiatives like the American Rescue Plan and Infrastructure Law for job growth. He emphasizes the need for stronger social security, Medicare, and showcasing concerns over wide income disparity, with an intent to introduce legislation like the Billionaire Minimum Income Tax to bring about a fairer distribution of wealth. He also emphasizes expanding workforce development in high schools, community colleges, and HBCUs, and combating price gouging in the pharmaceutical industry.",
        "On the other hand, Blackburn's approach is largely driven by reducing government involvement and federal spending. She advocates for policies that encourage competition and expresses a strong support for American energy production to support the economy. Her focus on reducing everyday costs aligns with her belief in creating opportunities for everyone to succeed and ensuring continuity of benefits for future generations. Blackburn supports reducing government interference in businesses, strengthening IP protections, and streamlining programs run by the Department of Commerce.",
        "Both Cohen and Blackburn show strong support for small businesses, recognizing their importance in the economy - Cohen through direct support measures and Blackburn through reduced government interference.",
      ],
      conclusion:
        "In summary, both politicians aim to stimulate job growth and invigorate the economy, but their proposed methods are shaped by their respective policy beliefs, with Cohen emphasizing government initiatives and wealth redistribution, while Blackburn stresses on less government interference and competitive policies.",
    },
    "National Security": {
      introduction:
        "Both Steve Cohen and Marsha Blackburn are committed to ensuring national security, taking proactive approaches to counterterrorism efforts and cyber protection. They advocate for comprehensive measures to prevent threats and safeguard the nation.",
      positions: [
        "Cohen stresses the importance of investing in advanced technology, strengthening international alliances, and instituting reforms to limit the misuse of emergency powers by presidential authorities. He also sees the need to tackle systemic issues such as corruption and autocracy, and he champions legislative actions such as the George Floyd Justice and Policing Act and the Counter-Kleptocracy Act to address these. He draws a direct connection between national security and protecting the electoral process and housing safety, which indicates a broad definition of security that encompasses societal stability.",
        "Blackburn underlines individual privacy as a crucial aspect of national security. She has been proactive in initiating and supporting legislation such as the Kids Online Safety Act and a national privacy standard, which aim to protect people, especially minors, from digital threats and corporate misuse of data. She also emphasizes the need to hold companies accountable for cyber protection. Moreover, with the Civilian Cyber Security Reserve Act and a law to explore potential threats from sister city agreements with cities in Communist China, she emphasizes strengthening intelligence agencies and securing national sovereignty.",
      ],
      conclusion:
        "In conclusion, while both are deeply vested in the nation's security and share common concern over threats such as cyber attacks and terrorism, they prioritize differently. Cohen focuses more on systemic and societal issues, while Blackburn emphasizes the importance of privacy protection and holds companies more accountable for security.",
    },
    Taxes: {
      introduction:
        "Steve Cohen and Marsha Blackburn both believe in tax reform, but their approaches differ significantly.",
      positions: [
        "Steve Cohen follows a more progressive stance on taxation. He emphasizes that everyone, especially the wealthy, should pay their fair share of taxes. He has proposed and authored tax legislation aimed at increasing the tax burden of billionaires and is against any tax cuts targeted towards the affluent demographic. He focuses on closing provisions that allow the super-rich to avoid taxes and advocates for removing the cap on Social Security taxes so more affluent individuals can contribute more.",
        "On the other hand, Marsha Blackburn's approach focuses more on reducing federal spending and shrinking bureaucracy. She advocates for tax reform that includes cutting back on what she perceives as unnecessary or unwanted federal programs. She wants to hold the IRS accountable and aims to prevent it from targeting political opponents. Blackburn is against expansion of IRS, viewing it as a threat. She also promotes unleashing American energy to help lower gas prices which she believes would boost the economy.",
        "When it comes to government spending, Cohen prioritizes investment in education, healthcare, jobs, and infrastructure, and efforts to fight inflation and price gouging. He aims to safeguard programs like Medicare, Medicaid, Social Security, and SNAP, while providing funds for local governments and public safety programs. ",
      ],
      conclusion:
        "Blackburn, instead, emphasizes cutting spending, working towards efficiency in government programs, and preventing further tax hikes. She gives priority to strengthening U.S. manufacturing and the defense sector by developing partnerships with private sector and educational institutions. She believes American families' needs should be prioritized over additional spending plans proposed by other political factions.",
    },
  },
  "Marsha BlackburnTim Burchett": {
    "Civil Liberties": {
      introduction:
        "Both Marsha Blackburn and Tim Burchett demonstrate a strong commitment to protecting civil liberties of all Americans, with shared focuses on the right to freedom of speech and privacy. However, they approach the protection of these civil liberties in noticeably different ways.",
      positions: [
        "On the one hand, Marsha Blackburn values a more active legislative role, introducing several legislations like the Civilian Cyber Security Reserve Act, the Kids Online Safety Act, and the Open Technology Fund Authorization Act, which aim to protect civil liberties in varied contexts, ranging from national security to online safety for children. Blackburn particularly emphasizes reducing government overreach, restricting how big technology companies handle user data, and promoting internet freedom internationally.",
        "On the other hand, Tim Burchett focuses more on minimizing the potential for legislations to infringe upon the rights of law-abiding citizens. His approach focuses on the importance of government transparency and improving measures to protect personal data. Moreover, Burchett shows a keen interest in upholding Fourth Amendment rights, which protect citizens from unreasonable searches and seizures. He also notably advocates for the rights of legal gun owners.",
      ],
      conclusion:
        "In summary, while both politicians are devoted to preserving essential civil liberties, Blackburn leans towards a more proactive and legislative approach towards safeguarding these rights, particularly from big tech companies, while Burchett prioritizes preventing potential governmental overreach and prioritizes upholding constitutional protections.",
    },
    "Civil Rights": {
      introduction:
        "Based on the provided information, here are the comparative positions of Marsha Blackburn and Tim Burchett on Civil Rights:",
      positions: [
        "1. LGBTQ+ Rights: Both Blackburn and Burchett show a commitment towards protecting the rights of the LGBTQ+ community. They both support legislation aimed at preventing discrimination in the workplace, housing, and public spaces. Blackburn has taken a specific position on healthcare and access to essential services for the LGBTQ+ community, which has not been mentioned in the information provided on Burchett.",
        "2. Racial and Gender Equality: The issue of racial and gender equality doesn't have clear positions for either of the politicians based on the information provided. Burchett has voiced support for the advocacy of equal opportunities, but there is no specific information provided on Blackburn's position on this issue.",
        "3. Voting Rights: On voting rights, their positions appear to diverge significantly. Blackburn contests attempts to federalize elections, advocates for voter ID laws, and has been proactive in Senate votes regarding military personnel and vaccine mandates. On the other hand, Burchett supports expansive voter access and has co-sponsored the John Lewis Voting Rights Advancement Act, aiming to restore protections from the Voting Rights Act of 1965. ",
      ],
      conclusion:
        "These are broad overviews of their positions based on the information provided. For more specific and updated information, you might want to consider doing further research or reaching out to their respective offices.",
    },
    Crime: {
      introduction:
        "Marsha Blackburn and Tim Burchett approach the issue of crime with very different strategies.",
      positions: [
        "Blackburn prioritizes strengthening law enforcement and being tough on crime. She proposes increasing resources for evidence processing and crime labs, and she advocates for policies that keep criminals behind bars, opposing 'soft-on-crime' strategies. Blackburn also supports hiring veterans and former law enforcement officers as school safety officers.",
        "On the other hand, Burchett focuses more on addressing the root causes of crime. He sees investment in schools, economic opportunities, and mental health services as critical steps in reducing crime rates. Burchett advocates for a more comprehensive approach, emphasizing both security and equity of opportunity.",
      ],
      conclusion:
        "While both acknowledge the importance of effective law enforcement in dealing with crime, Blackburn leans more towards policies that curb crime through stringent law enforcement and increased resources, while Burchett leans towards addressing the societal and economic factors contributing to crime.",
    },
    Education: {
      introduction:
        "Both Marsha Blackburn and Tim Burchett place a strong emphasis on improving the quality of public education and increasing access to higher education. They both appreciate the need for schools to be properly equipped to provide quality education and for students to be provided with the necessary tools for success. ",
      positions: [
        "One notable difference between their positions is on the issue of student loan debt. Blackburn is against any federal spending that would increase federal debt, including broad measures to forgive student loan debt. On the other hand, Burchett proposes a creative solution to address student loan debt by suggesting a pro bono work requirement for lawyers who take out federal student loans.",
        "Both of them show support for teachers, with Blackburn endorsing policies like the SAFE School Act and opposing progressive agendas in public schools, and Burchett emphasizing the provision of resources needed for safe and secure classrooms. However, the specific aspects of teacher support they emphasize differ slightly. ",
      ],
      conclusion:
        "To summarize, while both of them are invested in improving education, their approaches and specific policies differ, particularly on the issue of student loan debt and specific policies to support teachers.",
    },
    "Environmental and Climate Policy": {
      introduction:
        "Both Marsha Blackburn and Tim Burchett are dedicated to addressing climate change, however their approaches are slightly different. ",
      positions: [
        'Marsha Blackburn supports an "all-of-the-above" energy policy, which seeks to reduce reliance on foreign oil. While she supports sustainability efforts and clean energy investment, she disagrees with Joe Biden\'s climate policies, expressing concern about his plans of spending heavily on climate change measures. She is a critic of the halting of the Keystone pipeline and the rejoining of the Paris Climate Agreement, questioning whether these actions serve American interests.',
        "On the other hand, Tim Burchett emphasizes on bipartisan efforts, supporting sustainability through promoting clean energy such as solar and wind power, enhancing energy efficiency and conservation, while also funding research for new technologies to reduce emissions. His approach does not put excess burdens on the economy or the public.",
      ],
      conclusion:
        "In essence, both share the desire for sustainable solutions and reducing emissions, however, Blackburn shows a skepticism towards globally-coordinated efforts, preferring a more independent national approach. Burchett, however, seems open to collaborative approaches as well as independent national strategies and has a heavy focus on science-based regulations and policies.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      introduction:
        "Both Marsha Blackburn and Tim Burchett have strong views on foreign policy, with a particular focus on upholding democratic values and maintaining the security of the United States and its allies.",
      positions: [
        "Both politicians express strong opposition against Russia's aggression on Ukraine. Blackburn is advocating for immediate lethal aid to Ukraine and imposing heavy sanctions on Russia. Burchett also calls for strong opposition to Russia’s actions, suggesting the potential implementation of economic sanctions.",
        "In terms of trade policy, while Blackburn emphasizes sanctioning measures and strategically isolating economies like Russia's, she also proactively encourages the independence of America's energy sector. Conversely, Burchett is more focused on negotiating equal and beneficial trade deals to protect American workers and businesses.",
        "Another significant point is their stance towards China. Blackburn openly criticizes China's support for Russia's invasion and stresses the need for penalties. She is also concerned with countering the Chinese Communist Party's global aspirations. Burchett, on the other hand, does not specifically address China in the brief provided but emphasizes the importance of engaging with international organizations to promote peace and stability.",
      ],
      conclusion:
        "Lastly, both politicians express their commitment to protecting U.S. allies, particular Israel. While Blackburn prioritizes strengthening the U.S relationship with Israel, Burchett emphasizes the need to protect Israel and other allies against threats from hostile countries like Iran.",
    },
    Guns: {
      introduction:
        "Both Marsha Blackburn and Tim Burchett strongly support the Second Amendment, which grants US citizens the right to bear arms, and they advocate for responsible gun ownership. ",
      positions: [
        "Marsha Blackburn opposes some gun control measures, especially those she views as infringing constitutional rights, similar to Burchett who also stands against policies and regulations that infringe upon lawful gun owners' rights. Both support measures that protect the rights of law-abiding gun owners and believe in strengthening background checks.",
        "There is an emphasis on school safety from both politicians, but they propose different solutions to enhance this. Blackburn suggests hiring veterans and former law enforcement officials as a means to increase school safety, whereas Burchett prioritizes resourcing law enforcement agencies appropriately and ensuring the security of schools.",
      ],
      conclusion:
        "In conclusion, both politicians uphold the commitment to preserving the rights of lawful gun owners and ensuring community safety. However, their specific approaches and recommended initiatives vary.",
    },
    Healthcare: {
      introduction:
        "Both Marsha Blackburn and Tim Burchett prioritize making healthcare accessible and affordable for all Americans. They each have a consciousness about health care costs and are working towards reducing patient costs.",
      positions: [
        "Marsha Blackburn emphasizes increasing access to care, particularly in rural areas, and is dedicated to introducing bipartisan legislation geared towards this. She advocates for an increase in competition in the US market for healthcare costs control and suggests budget trims by certain percentages for non-defense items to accomplish this.",
        "Like Blackburn, Tim Burchett is committed to health care accessibility and affordability, but his focus is particularly on patients with pre-existing conditions. He espouses transparency in healthcare costs and supports expanding telehealth services. He also proposes a novel idea of lawyers working pro-bono for low-income individuals if they use federal student loans.",
      ],
      conclusion:
        "In summary, although their perspective strategies differ, both Marsha Blackburn and Tim Burchett are dedicated to the cause of making healthcare more accessible and affordable. They each bring their own unique approach, but their overall goal is the same: to improve the U.S. healthcare system.",
    },
    Immigration: {
      introduction:
        "Marsha Blackburn and Tim Burchett, both, advocate for increased border security and stricter enforcement of existing immigration laws. Both politicians agree on the deployment of advanced technology at the border for surveillance and detection of illegal crossings.",
      positions: [
        "However, there are some key differences in their stance.",
        "Marsha Blackburn takes a more conservative approach, underscoring the need for a secure border. She strongly advocates for measures such as building a border wall and maintaining the remain-in-Mexico policy, which calls for asylum seekers to stay in Mexico while their cases are being processed in the U.S.",
      ],
      conclusion:
        "On the other hand, Tim Burchett, while also focusing on stricter border control, emphasizes comprehensiveness in immigration reform. This includes developing a pathway to citizenship for immigrants already in the country. Unlike Blackburn, Burchett also highlights the need to work closely with Mexico and Central American countries to address the root causes that lead to illegal immigration.",
    },
    "Inflation and Prices": {
      introduction:
        "Both Marsha Blackburn and Tim Burchett recognize the need for prudent fiscal measures to address inflation and stabilize prices. They propose limits on government spending and the promotion of fiscal responsibility. ",
      positions: [
        "Both also advocate for policies that promote domestic energy production. Blackburn believes these measures can lower gas prices, thus helping to control inflation. Burchett views increased domestic energy production as a means to stabilize prices for consumers.",
        "Blackburn and Burchett differ slightly in their other policy priorities. Blackburn focuses on reducing healthcare costs and promoting fair trade policies and competition in the tech industry. She is particularly concerned with policies that increase tax burdens and potentially lead to job loss. ",
        "On the other hand, Burchett emphasizes the need to prevent corporations from exploiting the economic climate to unjustly increase prices. His approach seems to focus more on regulatory measures to keep businesses in check.",
      ],
      conclusion:
        "In summary, both share the common belief that fiscal responsibility, decreased government spending, and increased domestic energy production are key to addressing inflation and price stability. They do, however, put different emphasis on other related policies.",
    },
    "Jobs & the Economy": {
      introduction:
        "Both Marsha Blackburn and Tim Burchett place a strong emphasis on supporting small businesses and entrepreneurs as a key strategy for stimulating job growth and boosting the economy.",
      positions: [
        "Marsha Blackburn focuses on reducing government interference, strengthening Intellectual Property (IP) protections, and streamlining Department of Commerce programs. She also aims to ensure federal funds aid domestic farmers and not foreign entities. She believes lowering everyday costs for Americans and creating growth opportunities contributes to addressing income inequality and wage growth.",
        "Tim Burchett, on the other hand, promotes providing tax credits to small businesses that create jobs and invest in their communities. He also supports offering grants to help these businesses grow. To address income inequality and stimulate wage growth, Burchett prioritizes responsible government spending and aims to create a business environment that promotes investment in employees and job creation with a focus on fair wages.",
      ],
      conclusion:
        "To summarize, both politicians aim to stimulate job growth, support small businesses, and address income inequality, but they propose different methods to achieve these objectives.",
    },
    "National Security": {
      introduction:
        "Both Marsha Blackburn and Tim Burchett hold strong stances on the importance of national security and the need to safeguard individual privacy. They both believe that the government should have clear measures for data protection and be held accountable for their actions. ",
      positions: [
        "Blackburn has been proactive in introducing legislation, such as the Civilian Cyber Security Reserve Act and the Kids Online Safety Act, aimed at strengthening cyber security and protecting individuals, especially children, online. Similarly, Burchett also advocates for stronger cyber security, with an emphasis on providing military and intelligence agencies the necessary resources. ",
        "Blackburn shows a particular concern towards potential threats from abroad, such as her legislation assessing threats from sister city agreements with Communist China. On the other hand, Burchett emphasizes a proactive role for the United States in counter-terrorism actions and underscores the need for public awareness. ",
      ],
      conclusion:
        "Overall, their positions reflect a common emphasis on strengthening national security, privacy standards, and cyber security, while their priorities show slight variations.",
    },
    Taxes: {
      introduction:
        "Marsha Blackburn and Tim Burchett both support tax reform and share the common objective of reducing federal spending.",
      positions: [
        "Blackburn highlights the need to cut any programs she considers unnecessary or unwanted. She advocates strongly for a reduction in the reach of the IRS, as well as closing tax loopholes. She does not support expanding IRS and stands firm on the stance of preventing the IRS from targeting political opponents. Her focus includes cutting spending, shrinking bureaucracy and preventing tax hikes. Also, she sees the need for industry and institutions such as private sector developers and universities to work together with defense, emphasizing on the priority of putting American families' needs first.",
        "On the other hand, Burchett is committed to adjusting the tax code in a way that is simpler and fairer for all Americans. His solutions include lowering corporate taxes and exploring ways to lessen the tax burden on individuals by increasing the standard deduction and expanding the Earned Income Tax Credit. He also supports initiatives to close loopholes and crack down on tax evasion. His approach to managing government spending involves enforcing fiscal responsibility and promoting accountability and transparency to balance the budget. He emphasizes strongly that the wealthy and corporations should pay their fair share under his proposed tax reforms.",
      ],
      conclusion:
        "In summary, while both agree on the broad strokes of tax reform and cutting spending, Blackburn's position leans more toward reducing the role of government, particularly the IRS, and focusing on spending cuts. Burchett, however, prioritizes making tax more fair and simple, closing loopholes and ensuring fiscal responsibility.",
    },
  },
  "Marsha BlackburnVoters": {
    Guns: {
      introduction:
        "Marsha Blackburn and the voters of Tennessee align on many points relating to gun control. Both Blackburn and the voters believe in the importance of the Second Amendment. They agree that responsible firearm ownership should be maintained.",
      positions: [
        "On gun control measures, Blackburn opposes any initiative she views as infringing on constitutional rights, aligning with voters’ desire to maintain their constitutional rights to bear arms responsibly and safely.",
        "As for background checks, both Blackburn and the voters favor strengthening them. Blackburn advocates for closing loopholes and preventing groups prohibited from owning firearms from accessing them. This matches the stance of the voters who support universal background checks and restrictions for certain groups, like the mentally ill and felons.",
      ],
      conclusion:
        "The primary point of divergence lies in the voters' support for a ban on assault weapons, a position that Blackburn has not explicitly espoused. Blackburn’s recommendation to hire veterans and ex-law enforcement officers as school safety officers also does not seem to be a topic that the voters have explicitly expressed an opinion on. Overall, their positions are largely similar, with a few specific major differences.",
    },
  },
  "Steve Cohen (2020)Steve Cohen (2024)": {
    Abortion: {
      introduction:
        "In both 2020 and 2023, Steve Cohen has maintained his strong stance on being pro-choice and supports the right of women to make decisions about their own bodies, including the decision to have an abortion. Throughout both years, he has remained against any efforts aimed at criminalizing abortion or reducing its accessibility. ",
      positions: [
        "Regarding the specific strategies, in 2020 he was fighting for the continued provision of services like contraception and cancer screenings through organizations like Planned Parenthood. Meanwhile, in 2023, in addition to his stand on protecting Roe v. Wade, he focused more actively on legislative measures such as supporting the Women's Health Protection Act which would officially recognize the right to body autonomy. Moreover, he backed the Inflation Reduction Act and an expansion of affordable healthcare as means to provide alternatives to abortion. ",
      ],
      conclusion:
        "Overall, Cohen's position has evolved from simply being pro-choice to more explicitly advocating for specific legislative measures to protect and enhance women's reproductive rights and alternatives.",
    },
    "Civil Liberties": {
      introduction:
        "In both 2020 and 2023, Steve Cohen remained deeply committed to the protection of civil liberties. His main focus remained on aspects like freedom of speech, transparency, accountability and privacy.",
      positions: [
        "In 2020, he showed his commitment towards transparency and accountability with the introduction of the Open Book on Equal Access to Justice Act. He also proposed the Police CAMERA Act to provide funding for local police body camera programs. Cohen had a clear focus on digital privacy with measures like supporting the Online Privacy Act and voting against overturning the FCC's internet privacy rules.",
        "By 2023, Cohen's focus seemed to expand. While he still championed transparency, accountability, and privacy, he introduced additional legislation against the potential misuse of emergency authorities, bias in court systems, and the subversion of elections. He also took a strong stand on government surveillance and data privacy with the National Statistics on Deadly Force Transparency Act. Digital privacy protections continued to be paramount for Cohen, with the introduction of multiple initiatives aimed at protecting civil liberties in the digital realm. These included the Free Speech Protection Act, the Election Reform Act, the Civil Rights Legacy Protection Act, and the Protecting Our Democracy Act. ",
      ],
      conclusion:
        "In short, while both years demonstrate Cohen's strong dedication to civil liberties, his focus in 2023 expanded to include additional issues, and there were more targeted initiatives towards digital privacy and liberties.",
    },
    "Civil Rights": {
      introduction:
        "In both 2020 and 2023, Steve Cohen showcased deep commitment to civil rights and equality. His position remained quite unchanged, but actions taken in the span of three years show further proactive involvement in issues related to LGBTQ+ community, racial and gender equality, and voting rights. ",
      positions: [
        "In terms of LGBTQ+ rights, he consistently supported legislation promoting equal rights in 2020 and 2023. The advancement of the Equality Act was one of his main priorities in both years. ",
        "Regarding racial and gender equality, he maintained his advocacy for legislation that combats discrimination, moving from support of the Violence Against Women Act and the Equal Rights Amendment in 2020 to pushing for the implementation of the CROWN Act and the establishment of equity offices in 2023. ",
        "On voting rights, Cohen widened his scope by backing measures that focus on gerrymandering and campaign finances in 2023, in addition to continuing to champion access to voting. Notably, his position on the Electoral College seems to remain unspecified in 2023, whereas in 2020 he made clear intentions to eliminate it. ",
      ],
      conclusion:
        "Taken together, these actions suggest that both in 2020 and 2023, Steve Cohen steadfastly demonstrated a commitment to civil rights, persisting in his advocacy for the LGBTQ+ community, racial and gender equality, and voting rights, while also nuancedly expanding his commitment to include policies fostering diversity and eliminating more subtle forms of discrimination.",
    },
    Crime: {
      introduction:
        "In both 2020 and 2023, Steve Cohen maintained a notably consistent position on the issue of Crime. He has consistently focused on addressing the root causes of crime and aimed to reform the criminal justice system to reduce mass incarceration and address racial disparities.",
      positions: [
        "In 2020, he emphasized improving community-law enforcement relations, implementing alternative justice models, reforming school-to-prison pipeline, and reforming drug laws. In 2023, while he still maintained these stances, he further underscored the importance of removing profit incentives from the justice system and enhancing access to education.",
        "Sentencing reforms remained significant to him throughout this time, with a continuation of offering clemency to non-violent offenders and supporting acts for establishing fairness in the criminal justice system. He has also consistently endorsed legislation such as the George Floyd Justice in Policing Act.",
      ],
      conclusion:
        "Additionally, in both years, Cohen backed the Fresh Start Act, aimed at giving non-violent offenders a chance to erase their records. However, by 2023, he placed more emphasis on other issues, including environmental justice, discrimination, and gun violence prevention, suggesting a broadening of his focus on crime prevention and social justice.",
    },
    Education: {
      introduction:
        "Both in 2020 and 2023, Steve Cohen placed a high emphasis on improving public education, augmenting access to higher education, and supporting teachers. He was constantly intentional about prioritizing the resources needed for schools to deliver a quality education.",
      positions: [
        "In 2020, Cohen backed the #RebuildAmericasSchools Act which proposed a $100 billion investment over 10 years to renovate public schools. He highlighted the significance of early and high-quality education and addressed the school-to-prison pipeline issue. Cohen also introduced several measures to alleviate student loan debt by insulating students from predatory, for-profit colleges and making it easier to relieve student loans during bankruptcy. ",
        "By 2023, Cohen had advanced his stance to more specific steps, such as the introduction of a bill to enhance access to education at HBCUs and the proposal for school districts to establish equity offices. He worked towards a major educational initiative in Tennessee which involved billions of dollars for scholarships. Cohen focused on predatory debt collection and closure of loopholes used by for-profit colleges. There was an added emphasis on enabling understanding of the Public Service Loan Forgiveness program. ",
      ],
      conclusion:
        "In terms of supporting teachers, both in 2020 and 2023, Cohen upheld appropriate strategies for improving educational outcomes like propagating reasoning and critical thinking skills. As of 2023, Cohen amplified his stance by endorsing the creation of equity offices, boosting diversity in education, expanding workforce development and training programs and prioritizing child care affordability and paid family leave as essential elements for job readiness.",
    },
    "Environmental and Climate Policy": {
      introduction:
        "Overall, Steve Cohen has remained consistent in his commitment to the environment and combating climate change from 2020 to 2023, but his strategies have expanded and become more aggressive.",
      positions: [
        "In both 2020 and 2023, Cohen supports the Green New Deal Resolution that proposes aggressive action against climate change. This indicates his continued dedication to this plan. ",
        "In terms of greenhouse gas emission, in 2020, Cohen was a proponent of reducing them through investing in clean energy, conservation of energy and water, and lessening the use of carbon fuels. By 2023, his strategy remains to reduce greenhouse gas emissions, but he also includes plans of phasing out single-use plastics. ",
        "Cohen's commitment to public health and economic improvement through the EPA's Clean Power Plan in 2020 is consistent with his stance in 2023 where he also plans to implement stricter regulations on polluters, making them financially accountable for their environmental damage.",
        "One of the notable differences in his positions is his approach towards fossil fuels. In 2023, Cohen specifically mentions the strategy of ending government funding to fossil fuel companies, which was not explicitly mentioned in 2020. ",
        "Also, in 2023 Cohen focuses on integrating climate action into all areas of administration, aiming for each new job to contribute positively to climate health - a focus which was not specified in 2020. ",
      ],
      conclusion:
        "In both years, Cohen displays a commitment to climate change policies and environmental protection but in 2023, he significantly intensifies his approach.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      introduction:
        "In both 2020 and 2023, Steve Cohen remains committed to the principles of diplomacy, human rights, and respect for sovereignty in relation to foreign policy.",
      positions: [
        "Specifically, on the issue of the Ukraine conflict, in 2020, Steve Cohen sought to strengthen Ukraine’s sovereignty and independence and work against Russian aggression, advocating for U.S. aid to Ukraine. By 2023, his stance seems to have hardened slightly. He’s firmer on Russian actions in Ukraine and emphasizes the need for serious consequences should any use of chemical, biological, or nuclear weapons take place. His support for Ukraine in combatting Russian aggression becomes more direct, favoring significant military aid for Ukrainian defense.",
        "Cohen’s approach toward international conflicts has remained steadfast. Advocating for diplomacy over warfare in 2020, he supports preventive measures such as sanctions and deterrents. By 2023, his approach did not change, strongly supporting diplomatic approaches and denouncing the use of chemical weapons globally.",
        "In reference to trade agreements, while detailing equitable trade agreements that uphold similar standards for trading partners in 2020, he continues this stance in 2023, asserting that these relationships should respect all parties involved and should benefit American workers and businesses.",
      ],
      conclusion:
        "Lastly, Cohen’s commitment toward global democracy, promotion of human rights, and international collaboration has remained constant between 2020 to 2023, emphasizing partnership with allies and a shared commitment to democracy and human rights.",
    },
    Guns: {
      introduction:
        "Steve Cohen's position on guns between 2020 and 2023 remained primarily consistent with his belief in stricter gun control for the sake of public safety. In both 2020 and 2023, Cohen advocated for regulations such as the expansion of background checks and banning assault weapons.",
      positions: [
        "However, in 2023 his position further evolved to include the institution of Red Flag Laws, which allow authorities to temporarily confiscate firearms from individuals deemed a threat to themselves or others. He also mentioned the regulation of gun use in specific public areas such as schools, government buildings, and places where liquor is sold.",
        "Furthermore, in 2023, he placed more emphasis on the intersection of gun control and mental health, pushing for investment in mental health services.",
      ],
      conclusion:
        "Throughout both time periods, Cohen stressed the importance of implementing these measures without infringing on Second Amendment rights, promoting responsible gun ownership.",
    },
    Healthcare: {
      introduction:
        "Steve Cohen's stance on healthcare in both 2020 and 2023 underlines his commitment to affordable and accessible healthcare for all American citizens.",
      positions: [
        "In 2020, Cohen strongly supported the Medicare for All program, aiming to create a universal healthcare system. He also emphasized expanding the Medicare and Medicaid programs, alongside reducing prescription drug costs. He also actively aimed to protect individuals with pre-existing conditions and supported the Affordable Care Act and the 21st Century Cures Act. His 2020 focus also included fighting the opioid crisis and achieving improvements and reforms in women's health resources and other healthcare areas.",
        "In 2023, Cohen maintained these fundamental stances while including specific measures to support his core goals. He focused on strengthening the Affordable Care Act, limiting out-of-pocket expenses for seniors, and enabling direct negotiation of drug prices with pharmaceutical companies. He proposed broader coverage, including dental, vision, and hearing care. Additionally, he stressed making childcare affordable and ensuring paid family leave, promoting accessibility of supermarkets in underserved areas, and reducing the financial impact of student loans.",
      ],
      conclusion:
        "Throughout both years, Cohen demonstrated his consistent dedication to improving healthcare accessibility, affordability, and diversity of coverage. The main contrast between the two years lies in the level of specificity in his plans, with more precise measures presented in 2023, perhaps indicating the evolution and refinement of his policies according to the changing needs and issues faced by his constituents and the country in general.",
    },
    Immigration: {
      introduction:
        "In both 2020 and 2023, Steve Cohen strongly advocated for comprehensive immigration reform and maintained a focus on the dignity and rights of immigrants. His support for an orderly and humane asylum process system, firm border security, and due process for all immigrants remained consistent over these years.",
      positions: [
        "In 2020, Cohen's focus was largely on introducing pathways for citizenship for Dreamers and other undocumented immigrants. He also sought measures such as early voting to encourage immigrant participation in the democratic process. His interest in curbing the School-to-Prison Pipeline indicated a focus on the future of youth, including immigrant youth, and improving conditions for victims.",
        "By 2023, Cohen's position had expanded to include providing seasonal visas for specific immigrant groups, like Cuban athletes, without forcing them to give up their previous citizenship or separate from their families. He advocated for reducing re-entry barriers for previously incarcerated individuals, suggesting a drive to reform the criminal justice system to better serve the immigrant community. There was an evident emphasis on the provision of necessary resources, like affordable childcare and paid family leave for immigrant families.",
      ],
      conclusion:
        "Regarding legislative action, while in 2020 he had introduced legislation for immigration reform, by 2023, he was focusing on including immigration reform in broader legislative measures like the Build Back Better framework and legislation managing water resources. His re-introduction of bills to increase police accountability and transform policing culture shows a continued push for comprehensive reform that includes immigration issues.",
    },
    "Inflation and Prices": {
      introduction:
        "In both 2020 and 2023, Steve Cohen demonstrated a commitment to combating inflation and controlling prices for the benefit of the American public.",
      positions: [
        "In 2020, Cohen's stance focused on increasing the minimum wage, implementing protective measures against unfair digital goods taxes, advocating for affordable housing, and encouraging the establishment of supermarkets in underserved areas. He also supported lowering drug prices, protecting individuals with preexisting conditions, and introducing a national paid leave policy.",
        "In 2023, his approach to controlling inflation and prices has expanded and evolved, reflecting the changing socio-economic landscape. His support for the Inflation Reduction Act indicates his continued commitment to mitigating the impact of inflation on everyday Americans. This is further underscored by his backing of legislation that reduces health premiums, caps out-of-pocket costs for seniors, expands the child tax credit, and lowers prescription drug costs.",
      ],
      conclusion:
        "Cohen's 2023 position also emphasizes taking action against price gouging by pharmaceutical companies and ensuring that corporations and the wealthy contribute their fair share. Further, he still prioritizes making fresh, affordable produce availability in underserved areas, while his support for the Build Back Better framework indicates a new focus on broad-based socio-economic reform, including negotiated drug prices, paid family leave, and immigration reform.",
    },
    "Jobs & the Economy": {
      introduction:
        "The central priorities of Steve Cohen's position on jobs and the economy have remained consistent from 2020 to 2023, with the focal points being wage increases, support for small businesses, infrastructure development, and fair tax measures.",
      positions: [
        'In 2020, Cohen supported the #RaiseTheWage Act and introduced the "Putting Our Veterans Back to Work Act". He pushed for an infrastructure bill, increased unemployment insurance programs, and advocated for small businesses, especially those owned by minorities, women, and veterans. He also aimed to repeal what he called the Trump tax giveaway for the wealthiest.',
        "By 2023, Cohen's position expanded to also include establishing universal childcare and permanent funding for the Child Tax Credit, providing robust support for initiatives like the American Rescue Plan, Paycheck Protection Program, and Restaurant Revitalization Fund. He also proposed a bipartisan solution to incentivize supermarkets to serve underserved areas and hire local residents, and introduced plans to establish a Billionaire Minimum Income Tax and the Living Wage Now Act.",
      ],
      conclusion:
        "It can be seen that Cohen's focus has shifted somewhat, from broadly increasing wages and supporting small businesses in 2020 to specifically addressing income inequality, promoting wage growth, and supporting businesses in underserved areas by 2023. However, his overall principle of enhancing the economy through increased wages, fair tax measures, and support for small businesses has remained constant.",
    },
    "National Security": {
      introduction:
        "In both 2020 and 2023, Representative Steve Cohen maintained a stance that prioritizes national security, while also stressing the importance of safeguarding individual privacy rights. His commitment to counterterrorism strategies and robust cybersecurity measures is consistent in both years. ",
      positions: [
        "However, there have been shifts in his approach over the three years. In 2020, his focus was on local law enforcement, with bills advocating for more transparency, restraints on use of force, and independent reviews in police departments. He also supported increased funding for the National Institutes of Health, indicating a broader view of national security that includes public health.",
        "By 2023, Cohen's focus shifted to more international concerns. He emphasized the importance of diplomatic and military counterterrorism efforts. He introduced the Counter-Kleptocracy Act, marking a stronger stance against international financial systems that support autocratic regimes. He also urged for immediate action against Russian kleptocracy, indicating an increased focus on international relations and geopolitics as integral parts of national security.",
      ],
      conclusion:
        "In summary, while Cohen consistently prioritizes national security and individual privacy rights, his approach has shifted from local, domestic issues to global, geopolitical concerns over the course of three years.",
    },
    Taxes: {
      introduction:
        "Between 2020 and 2023, Steve Cohen's position on taxes continued to prioritize fairness and ensuring that the wealthy and corporations pay their fair share. Both in 2020 and 2023, he emphasized closing tax loopholes and combating tax evasion. However, his stance became more specific and concrete by 2023, with proposals like the introduction of the President's Billionaire Minimum Income Tax and the ENABLERS Act.",
      positions: [
        "His stance against tax breaks for the wealthiest that he held in 2020 continued in 2023 with his proposal for the ultra-rich to bear the majority of tax obligations. He consistently advocated for tax systems that don't disproportionately affect the non-wealthy, as seen by his 2023 opposition to a national sales tax.",
      ],
      conclusion:
        "In terms of budget, both in 2020 and 2023, Cohen continued to place importance on investing in domestic programs and reducing the advantages of the top 1%. He also expressed an additional focus in 2023 on climate solutions and reducing subsidies for fossil fuel companies. Overall, his positions have held the same principles throughout, but with more specific strategies proposed by 2023.",
    },
  },
  "Steve CohenTim Burchett": {
    "Civil Liberties": {
      introduction:
        "Both Steve Cohen and Tim Burchett strongly express the need for the protection of civil liberties, emphasizing freedom of speech, freedom of press, privacy, and freedom of religion. However, they differ in terms of focal points and proposed policies.",
      positions: [
        "Steve Cohen is primarily focused on creating new legislation to protect civil liberties, such as the Free Speech Protection Act that seeks to shield journalists and critics from bad-faith lawsuits aiming to silence them. He is also focused on the rights of persons with disabilities and preserving the legacy of the Civil Rights Movement. In terms of data privacy and police misconduct, Cohen supports initiatives like the National Statistics on Deadly Force Transparency Act to monitor the use of deadly force.",
        "On the other hand, Tim Burchett is more concentrated on preventing encroachment on existing civil liberties by new legislation. He strongly advocates for improved transparency and better safeguarding of personal data, particularly in terms of government surveillance. Burchett also underscores the importance of civil liberties in the context of the digital age and the Fourth Amendment rights that protect against unreasonable searches and seizures. He is also particularly concerned about preserving the rights of law-abiding gun owners.",
      ],
      conclusion:
        "In summary, both are committed to the protection of civil liberties, but their priorities and strategies exhibit certain differences.",
    },
    "Civil Rights": {
      introduction:
        "From the responses provided, it appears that both Steve Cohen and Tim Burchett have deep commitments to civil rights protection and promotion.",
      positions: [
        "In terms of LGBTQ+ Rights, both Cohen and Burchett express firm support. Cohen has been instrumental in advancing protections against discrimination, while Burchett supports legislation like the Equality Act, which protects these individuals from discrimination.",
        "When it comes to Racial and Gender Equality, both politicians seem to prioritize these issues. Cohen actively pushes to pass legislation like the Housing Accountability Act and focuses on diversity and inclusion training, whereas Burchett promotes the idea of equal opportunities in all aspects of life.",
        "On the topic of Voting Rights, both Cohen and Burchett believe in the protection and expansion of these rights. Cohen introduced policies like the John Lewis Voting Rights Act and the Freedom to Vote Act to enhance these rights, while Burchett backs similar legislative measures and is a co-sponsor of the John Lewis Voting Rights Advancement Act.",
      ],
      conclusion:
        "However, there may be subtle differences in their approach and emphasis on certain aspects of civil rights based on their respective party affiliations. For a complete understanding of their positions, it would be necessary to look at their track records, legislation they have sponsored, voted on, or statements they've made.",
    },
    Crime: {
      introduction:
        "Steve Cohen and Tim Burchett share a common interest in addressing the root causes of crime. Both believe in taking preventive measures, advocating for social equity, and enhancing community safety.",
      positions: [
        "Steve Cohen keeps an emphasis on fairness, equality, and reducing mass incarceration, especially in communities of color. His initiatives aim to reform current law enforcement and judicial practices, reduce gun violence, and support acts like the George Floyd Justice and Policing Act. Cohen's standpoint focuses on ending unfair sentencing practices and providing individuals with nonviolent offenses a chance to reintegrate into society through measures like the #FreshStartAct.",
        "On the other hand, Tim Burchett emphasizes significant investment in schools to provide resources like school safety personnel and mental health services. His approach is more focused on a proactive stance, ensuring safety and equal opportunity, and preventing crime from taking root. Burchett also sees crime prevention in providing economic opportunities, educational resources, and mental health services to those in need.",
      ],
      conclusion:
        "Although both politicians have a distinct focus, they both take comprehensive approaches to dealing with the crime issue, aiming to deal not just with the symptoms but also with the underlying causes.",
    },
    Education: {
      introduction:
        "Steve Cohen and Tim Burchett both prioritize the overall betterment of education quality and increased access to higher education. ",
      positions: [
        "They both believe that it's imperative for teachers to have proper resources to be successful in their role. Cohen advocates for fair wages for teachers while Burchett emphasizes on providing resources essential for safe and secure classrooms. ",
        "On the issue of student loan debt, they have different opinions. Cohen proposes the removal of cap on Social Security taxes, which would mean high-earners will have to pay more taxes. The revenue thus accumulated can then be used to finance education costs. Cohen is also a proponent of the Public Service Loan Forgiveness program.",
        "Contrastingly, Burchett suggests implementing a pro bono work requirement for lawyers who have taken federal student loans. This would benefit low-income families who otherwise can't afford legal support.",
        "Cohen is focused on diversity and inclusion in learning programs and develops initiatives to expand workforce training and development. He opposes the use of public schools for ideological indoctrination. ",
        "Burchett, on the other hand, stresses the necessity of providing proper resources for school security. He supports student advancement by equipping them with the necessary tools for success. ",
      ],
      conclusion:
        "While both Cohen and Burchett are committed to improving education, their methods and focal points vary. Cohen emphasizes diversity, workforce development, and restructuring the financing of education through modifications in taxes. Burchett focuses on legal pro bono service, resources for school security and providing necessary tools for students' success.",
    },
    "Environmental and Climate Policy": {
      introduction:
        "Both Steve Cohen and Tim Burchett are committed to addressing climate change and promoting environmental sustainability. However, the approach they take and how they propose to go about this differs.",
      positions: [
        "Steve Cohen advocates for aggressive legislative measures, such as funding innovative projects to tackle climate pollution via the Inflation Reduction Act. He supports heavy investment in renewable energy and clean transportation infrastructure. Cohen also expresses strong support for environmental justice, waste and emission reductions, including a definite move away from single-use plastics. He aims to align his policy with a zero-carbon future, pushing hard for clean energy access.",
        "On the other hand, Tim Burchett emphasizes local solutions and bipartisan cooperation as part of his climate policy. He supports initiatives that transition waste industries towards being more environmentally friendly. Burchett also believes in hands-on involvement, as evidenced by his local tours of compost, recycling, and waste facilities. He advocates for a balance in reducing greenhouse gas emissions in a way that doesn't jeopardize the economy or lifestyle of his constituents. Burchett also highlights the importance of basing regulations on sound science and is wary of leaning too heavily on green special interest groups. ",
      ],
      conclusion:
        "To summarize, Cohen’s approach leans more towards ambitious, aggressive policy changes with significant financial investment, while Burchett’s approach has a more moderate tone, emphasizing resilience, local involvement, practicality, and economic preservation.",
    },
    "Foreign Policy (Including Ukraine Conflict)": {
      introduction:
        "Both Steve Cohen and Tim Burchett support the notion that the United States should take a strong stand against international aggression, especially with regards to the conflict in Ukraine. They also agree that the U.S. should back Ukraine’s sovereignty, oppose Russia's actions, and provide the resources necessary for Ukraine to defend itself.",
      positions: [
        "However, there are certain differences. Cohen appears more focused on working closely with international organizations, such as the Organization for Security and Co-operation in Europe. He advocates for the role of the U.S. as a leader, pressuring hostile entities while simultaneously promoting peace and stability in collaboration with allies.",
        "Burchett, on the other hand, centers more on the application of economic sanctions as a tool to counter aggressive states and protect allies. Despite sharing a similar objective with Cohen about global peace, he places a greater emphasis on fair trade agreements that favor American workers and businesses and foster economic growth.",
      ],
      conclusion:
        "In conclusion, while both Cohen and Burchett stand firmly against any form of international aggression and advocate for a proactive U.S role in global stability, they differ in their emphasis. Cohen focuses more on allied cooperation and a leading global role, while Burchett prioritizes economic consequences and trade agreement negotiations.",
    },
    Guns: {
      introduction:
        "Steve Cohen and Tim Burchett both believe in balancing the Second Amendment rights with public and community safety. They both support responsible gun ownership, suggesting measures like background checks to be necessary.",
      positions: [
        "However, their opinion varies when it comes to the specifics. Steve Cohen supports stricter gun control regulations such as reinstating the 1994 assault weapons ban and implementing comprehensive background checks. He also supports laws limiting access to firearms in specific public places and using red flag laws to restrict possession by those perceived as a threat to themselves or others.",
        "On the other hand, Tim Burchett focuses on measures that protect the rights of law-abiding gun owners and advocates pouring resources into law enforcement agencies. He does not support policies that he perceives as infringing upon citizens' gun rights. He, however, does support measures aimed at ensuring safety, such as adequate training and proper storage of firearms.",
      ],
      conclusion:
        "In summary, Steve Cohen leans towards more stringent gun control measures, whereas Tim Burchett leans towards empowering law-abiding citizens and law enforcement agencies regarding gun usage while preserving Second Amendment rights.",
    },
    Healthcare: {
      introduction:
        "Both Steve Cohen and Tim Burchett share a commitment to accessibility and affordability in healthcare, however, they differ significantly on how they believe improvements should be achieved.",
      positions: [
        "Cohen focuses on legislative measures to cap and reduce prices. He is a strong supporter of the Affordable Care Act, believes in substantially lowering prescription drug prices, and has suggested reforms of Medicare to achieve that. He also supports the limitation of out-of-pocket costs for seniors and the capping of insulin prices. Cohen highlights the importance of women's health rights, advocating for the Women's Health Protection Act and supports removing the cap on Social Security taxes so higher-income individuals contribute more.",
        "On the other hand, Burchett also advocates for accessibility and affordability of healthcare but through different methods. He emphasizes on the protection of individuals with pre-existing conditions and fiscal responsibility. He believes that issues in healthcare cannot be resolved solely by expanding the budget. Burchett supports the expansion of telehealth services and price transparency in healthcare. He also suggests the introduction of a pro-bono work requirement for lawyers utilizing federal student loans to assist low-income individuals.",
      ],
      conclusion:
        "In summary, while the goals of both politicians align in improving accessibility and affordability of healthcare, Cohen leans towards more direct price control measures and defending the Affordable Care Act, whereas Burchett seems to support expansion of service options and strengthening transparency in the healthcare market.",
    },
    Immigration: {
      introduction:
        "Both Steve Cohen and Tim Burchett express a need for comprehensive immigration reform and believe in creating a pathway to citizenship for undocumented immigrants currently living in the United States. Both also emphasize the importance of enhancing border security.",
      positions: [
        "However, Cohen emphasizes a more humane approach to this issue, voicing strong support for treating immigrants with dignity and respect. He is particularly committed to protecting vulnerable communities and creating a secure, efficient and respectful immigration process.",
        "On the other hand, Burchett highlights the enforcement of existing laws and does not believe in giving preferential treatment to illegal immigrants. He prioritizes increasing the number of border patrol agents and using advanced technology for identifying illegal crossings. Burchett also advocates for stronger international relationships, especially with Mexico and Central American countries, in order to address the root causes of illegal immigration. ",
      ],
      conclusion:
        "In conclusion, while both politicians agree on the need for reform, their emphasis varies. Cohen places more focus on the human rights aspect, while Burchett stresses the importance of law enforcement and technological advancements in border security.",
    },
    "Inflation and Prices": {
      introduction:
        "Steve Cohen and Tim Burchett have different approaches on the issue of inflation and prices.",
      positions: [
        "Steve Cohen is oriented towards the protection of everyday Americans from the negative impacts of inflation. He employs the method of passing legislation that regulates prices, especially in essential sectors like pharmaceuticals and healthcare. Cohen also advocates for a balanced tax system where the wealthiest pay their fair share, consequently generating revenue that helps support essential programs that he believes lessens the impact of inflation on consumers. His approach is tactical and dependent on regulation and taxation.",
        "On the other hand, Tim Burchett's standpoint resides on fiscal responsibility. He believes that by reducing government spending and producing well-scheduled budgets, the government can better manage inflation. He also views domestic energy production as a method to stabilize consumer prices. His approach leans towards prevention of corporate exploitation and is hinged on principles of responsibile governance and increased domestic production.",
      ],
      conclusion:
        "In a nutshell, while both are committed to combating inflation and stabilizing prices, Cohen leans more heavily on regulation, taxation, and social programs, while Burchett relies on fiscal conservatism, energy independence, and market dynamics.",
    },
    "Jobs & the Economy": {
      introduction:
        "Both Steve Cohen and Tim Burchett understand the importance of supporting small businesses and entrepreneurs as key drivers of the economy. They advocate for measures that will stimulate job growth and development within these sectors.",
      positions: [
        "Cohen focuses on bottom-up initiatives, such as the American Rescue Plan and the Infrastructure law, while Burchett is leaning towards implementing tax credits for businesses that invest in their communities and create jobs.",
        "In terms of addressing income inequality, Cohen aims to introduce legislation that would ensure the wealthy pay their fair share of taxes whereas, Burchett leans toward responsible government spending and creating a business environment that encourages investment in employees and fair wage growth.",
        "Workforce and skill development is also a point of agreement between the two politicians, but Cohen explicitly calls out support for specific programs at high schools, community colleges, and HBCUs. Cohen has also shown concern with price gouging in the pharmaceutical industry and pollution, which is not explicitly stated in Burchett's position.",
      ],
      conclusion:
        "It's worth noting that while their ultimate goals of supporting job growth and the economy seem to align, their proposed methods and priorities in achieving these goals show differences based on their respective political ideologies and approaches to governance.",
    },
    "National Security": {
      introduction:
        "Both Steve Cohen and Tim Burchett exhibit a proactive approach towards national security. They advocate for the allotment of necessary resources and the use of modern technology in counter-terrorism and cybersecurity. However, their point of focus differs. ",
      positions: [
        "Steve Cohen, on one hand, emphasizes the importance of international cooperation, believing that global alliances are essential to combat threats effectively. He is also keen on rooting out corruption and autocracy, viewing them as sources of instability and insecurity. Cohen supports implementing strong legislative reforms to prevent the misuse of emergency authorities by any President. Furthermore, he is concerned about social aspects like housing safety and electoral process protection, considering them crucial for overall security.",
        "In contrast, Tim Burchett places his focus primarily on the enforcement of domestic law and the necessity for the government's accountability for its actions. Burchett lays great stress on preserving individual privacy, especially where it concerns citizens' data. He is, particularly, against the use of this data for creating firearm owner registries.",
      ],
      conclusion:
        "In terms of strengthening intelligence agencies, both politicians agree on providing them with significant resources, technology, and personnel. But Burchett also highlights the importance of training within these operations. In conclusion, while both politicians prioritize national security, their approach and areas of emphasis manifest quite differently.",
    },
    Taxes: {
      introduction:
        "Steve Cohen and Tim Burchett both emphasize the importance of fair tax reforms; however, their approaches significantly differ due to their beliefs on how the tax burden should be distributed.",
      positions: [
        "Cohen tends to focus on the affluent paying their fair share and has shown dedication towards making this happen through policy. He has a strong stance against tax cuts for the ultra-rich and believes in closing tax evasion loopholes that predominantly benefit the wealthiest. Cohen also displays concern about social equality issues and pushes for the removal of the cap on Social Security taxes so millionaires would contribute more to fund it. His priority areas for government spending are education, climate resilience, healthcare, jobs, and infrastructure.",
      ],
      conclusion:
        'Burchett, on the other hand, is more focused on simplifying the tax code and reducing taxes for both corporations and individuals. While he advocates for a fair share of taxes, his strategy is lowering the corporate tax rate to make the U.S. more competitive and reducing individual tax burdens through measures like increasing the standard deduction and expanding the Earned Income Tax Credit. On government spending, Burchett emphasizes fiscal responsibility, accountability, and transparency to achieve a balanced budget. Both politicians agree on the need to close tax loopholes, but their standpoints demonstrate different interpretations of what "fair tax reform" should imply.',
    },
  },
  "Steve CohenVoters": {
    Guns: {
      introduction:
        "Steve Cohen and the voters of Tennessee have largely compatible views on the issue of gun control.",
      positions: [
        "Both Cohen and the voters support the implementation of universal background checks for all prospective gun owners. Also, they both express concern about certain groups, such as felons and the mentally ill, having access to firearms. This indicates a shared interest in preventing individuals who could present a risk from obtaining firearms. ",
        "Moreover, both Cohen and the voters are in favor of reinstating the ban on assault weapons. This position highlights a shared desire to limit access to particularly dangerous weapons.",
        "Cohen and the voters also seem to agree on the need for a balanced approach that respects the rights enshrined in the Second Amendment. They both endorse the idea of responsible gun ownership and appear to favor legislation and education to support this principle.",
      ],
      conclusion:
        "Overall, Cohen's stance on gun control measures corresponds well with the views expressed by Tennessee voters. Both advocate for policies which balance individual gun ownership rights and public safety needs.",
    },
  },
  "Tim BurchettVoters": {
    Guns: {
      introduction:
        "Tim Burchett and the Tennessee voters share common ground on the topic of guns in several key areas.",
      positions: [
        "1. Respect for Second Amendment: Both Burchett and the voters believe in preserving the constitutional right to bear arms. They support policies that secure the rights of responsible, law-abiding gun owners.",
        "2. Public Safety: Burchett and the voters agree on the importance of ensuring public safety. They feel the need to balance individual gun rights with the broader community safety, favoring policies that prioritize the security of schools and enhanced resources for law enforcement agencies.",
        "3. Background Checks: Both sides support measures that foster responsible gun ownership. This includes background checks, which the voters see as a universal need and Burchett believes are essential for promoting public safety.",
        "4. Restrictions on Certain Groups: Voters express the need to restrict certain groups, like the mentally ill and felons, from accessing firearms, a sentiment likely shared by Burchett as part of his commitment to maintaining public safety. ",
      ],
      conclusion:
        "A point of divergence might involve the issue of assault weapons; while some Tennessee voters support a ban, Burchett's stance on such a ban is unclear. He has generally opposed regulations that he sees as infringing upon the rights of lawful gun owners, and this might rule out support for an outright ban on assault weapons. It would be beneficial to confirm Burchett's viewpoint more explicitly on this particular matter.",
    },
  },
};

const nicotineTopics = {
  "Youth Access and Marketing Restrictions": [
    "Are you in favor of stricter regulations to reduce youth access to tobacco and nicotine products and to restrict marketing aimed at minors? What specific measures do you propose in this regard?",
    "Do you support flavor bans for e-cigarettes? What specific policies would you advocate for in this regard?",
    "How do you propose to address marketing and advertising strategies that specifically target minors for tobacco and nicotine products? What specific policies would you advocate for in this regard?",
    "What steps would you take to ensure that age restrictions on purchasing tobacco and nicotine products are effectively enforced?",
  ],
  Nicotine: [
    "What are the risks of using nicotine products? How would you address these risks from a policy standpoint?",
    "Are there specific regulations or strategies you support to promote safer nicotine use?",
    "Are there specific initiatives or programs you would support to educate the public about the risks associated with nicotine use?",
    "Tere's been consideration of reducing nicotine levels in cigarettes to make them less addictive. What is your stance on this approach?",
  ],

  "E-cigarettes": [
    "What are the risks of using e-cigarettes? Do you believe these risks outweigh any potential benefits?",

    "How do you view the role of e-cigarettes in harm reduction for adult smokers trying to quit traditional cigarettes? Are there specific regulations or guidelines you endorse for their use in harm reduction efforts?",

    "What are the potential benefits and drawbacks of ecigarettes as a smoking cessation tool for adults?",
    "Do you support a comprehensive framework for regulating e-cigarettes?",
    "Are there specific restrictions or regulations you believe should apply to the marketing and advertising of e-cigarettes?",
  ],
  "Collaboration with Tobacco Industry": [
    "Would you be open to collaborating with the tobacco industry on discussions related to tobacco and nicotine policies?",
    "Do you believe that the tobacco industry should play a role in funding research or public health initiatives related to tobacco and nicotine products?",
  ],
  "Harm Reduction Strategies": [
    "Do you support harm reduction strategies for adults who smoke cigarettes? If so, what harm reduction approaches or products do you endorse?",
    "What is your approach to striking a balance between respecting the choices of adult smokers who may want to switch to better alternatives and preventing youth initiation of tobacco and nicotine products?",
  ],
  "Heat Not Burn Technology": [
    "What is your perspective on the potential benefits and risks of Heat Not Burn products compared to traditional cigarettes?",

    "How do you view the role of Heat Not Burn in harm reduction, and what regulations or guidelines would you endorse to promote their safe use?",
    "What policies or regulations do you believe should apply to the marketing, sales, and taxation of Heat Not Burn products?",
  ],
  "Nicotine Pouches": [
    "How would you assess the potential of nicotine pouches as an alternative to traditional smoking, particularly for adult smokers?",

    "Are there specific regulations or guidelines you support to govern the manufacturing, labeling, and marketing of nicotine pouches?",
    "Do you believe that nicotine pouches should be subject to similar taxation as traditional cigarettes, or do you support different tax structures for these products?",
  ],
  Taxation: [
    "Do you support higher taxes on tobacco products, including cigarettes and potentially e-cigarettes and nicotine pouches?",

    "Do you advocate for equitable taxation across different tobacco and nicotine products, ensuring that e-cigarettes and nicotine pouches are subject to similar tax rates as traditional cigarettes?",

    "How do you view the potential regressive nature of tobacco taxes?",

    "How do you view the equity and fairness aspects of tobacco taxation, and what policies would you support to address potential disparities?",
  ],
};

const pharmaTopics = {
  "Drug Pricing": [
    "How do you plan to address the issue of high drug prices and make medications more affordable for Americans?",
    "What specific legislation or policies have you supported or sponsored to address high drug costs?",
  ],
  "Drug Approval and Regulation": [
    "What is your stance on the FDA's regulatory process for approving new drugs?",
    "Have you advocated for any changes in the drug approval process to expedite the availability of new treatments for patients?",
  ],
  "Generic Drugs and Biosimilars": [
    "How do you view the role of generic drugs and biosimilars?",
    "Have you supported legislation to streamline the approval and market entry of generic drugs and biosimilars?",
  ],
  "Patent Reform": [
    "What are your views on patent reform in the pharmaceutical industry? How do you believe it could impact innovation and access to medications?",
    "Have you sponsored or supported any legislation aimed at addressing patent issues in the industry?",
  ],
  "Research and Development Incentives": [
    "What policies or incentives do you support to encourage pharmaceutical companies to invest in research and development, particularly in critical areas of unmet medical need?",
  ],
  "Opioid Crisis": [
    "What actions have you taken to address the opioid crisis?",
  ],
  "Intellectual Property and Data Exclusivity": [
    "How do you view the balance between protecting intellectual property rights and ensuring timely access to affordable medications?",
    "Have you sponsored or supported legislation related to data exclusivity or patent protections in the pharmaceutical industry?",
  ],
  "Access to Medications": [
    "What steps have you taken to improve patient access to essential medications?",
    "How do you address disparities in drug access?",
  ],
  "International Trade and Drug Imports": [
    "Do you support policies that would allow for the importation of prescription drugs from other countries?",
    "How do you ensure the safety and authenticity of imported medications?",
  ],
  "Healthcare Reform": [
    "How do your views on healthcare reform, including the Affordable Care Act (ACA)?",
  ],
};

const munichTopics = {
  General: [
    "How do you view legislation that promotes recycling and the use of recycled materials in the beverage industry? do you support a specific initiative?",
    "What is your stance on environmental regulations aimed at reducing the carbon footprint of large corporations?",
    "What are your views on policies that facilitate or restrict the expansion of convenience store chains in urban and suburban areas?",
  ],
};

export {
  politicians,
  interviewsDb,
  comparisonsDb,
  nicotineTopics,
  pharmaTopics,
  munichTopics,
};
