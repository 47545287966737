import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select, {
  components,
  ValueContainerProps,
  PlaceholderProps,
} from "react-select";
import { connect } from "react-redux";
import { Tweet } from "react-twitter-widgets";

import AtlasCalendarSelect from "components/AtlasCalendarSelect";
import { API_VOICES_PATIENTS } from "constants/routes";

import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import { ReactComponent as ArrowSubmit } from "assets/icons/arrow-submit.svg";

import { convertToDt } from "utils/convertData";

import ModuleNotAvailable from "components/ModuleNotAvailable";
import PageTemplate from "pages/PageTemplate";
import Typing from "../../components/Typing";

import "./styles.scss";

interface IExamplePost {
  permalink: string;
  text: string;
}
interface IAudienceResponse {
  response: string;
  examplePosts: IExamplePost[];
}

function IndividualAccountScreen({
  topic,
  bots,
  dateRange,
  brands,
  authToken,
}) {
  const [selectedBot, setSelectedBot] = useState<{
    botId: string;
    name: string;
  }>(bots[0]);

  const [question, setQuestion] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [response, setResponse] = useState<IAudienceResponse | undefined>(
    undefined
  );

  const [showInspireMe, setShowInspireMe] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [savedAnswerId, setSavedAnswerId] = useState<string>("");

  const disease = topic.name;
  const treatments = brands.filter((b) => !b.isCompany).map((b) => b.name);
  const companies = brands.filter((b) => b.isCompany).map((b) => b.name);

  const submitQuestion = () => {
    if (submitting) return;

    setResponse(undefined);
    setSubmitting(true);

    const dt = convertToDt(dateRange.from, dateRange.to);
    const apiUrl = API_VOICES_PATIENTS.replace("$1", topic.id); // + `?startDate=${dt.dt_from}&endDate=${dt.dt_to}`
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        topicId: undefined,
        audience: selectedBot.botId,
        question: question,
        dateFrom: dt.dt_from,
        dateTo: dt.dt_to,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Unexpected response code for query: " + apiUrl);
          console.log(res);
          // TODO: what to return and how to redirect?
          setSubmitting(false);
          setResponse({
            response:
              "Sorry, I'm a bit overwhelmed right now, can you ask again later?",
            examplePosts: [],
          });
        }
        return res.json();
      })
      .then((jsonData) => {
        setSubmitting(false);
        setResponse(jsonData);
        setIsSaved(false);
      });
  };

  const permalinkToTweetId = (permalink: string) => {
    const parts = permalink.split("/");
    const tweetId = parts[parts.length - 1];
    return tweetId;
  };

  const handleInputChange = (e) => {
    setQuestion(e.target.value.replace("\n", ""));
  };
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && !submitting) submitQuestion();
  };

  return (
    <PageTemplate title="Voices">
      <div className="voices2-container">
        <div className="voices2-sidebar">
          <div className="voices2-sidebar-tabs">
            <Link
              to={"/hs/voices/audiences-2"}
              className={"voices2-sidebar-tab"}
            >
              Audiences
            </Link>
            <div className="voices2-sidebar-tab active">Individual</div>
          </div>
          <div className="voices2-sidebar-calendar">
            <AtlasCalendarSelect
              style={{ display: "flex", marginLeft: "auto" }}
            />
          </div>
          <div className="voices2-sidebar-bots">
            {bots.map((b) => (
              <div
                key={b.botId}
                className={
                  "voices2-sidebar-bot " +
                  (b.botId === selectedBot.botId ? "selected" : "")
                }
                onClick={() => setSelectedBot(b)}
              >
                {b.name}
              </div>
            ))}
          </div>
        </div>

        <div className="voices2-body">
          <div className="voices2-body-lead">
            Hi, I’m an AI engine representing {selectedBot.name}
          </div>

          <div className="voices2-question-input">
            <input
              type="text"
              disabled={submitting}
              value={question}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder="Ask your question"
            />

            <div
              className="voices2-question-submit"
              onClick={() => {
                if (!submitting) submitQuestion();
              }}
            >
              <ArrowSubmit />
            </div>
          </div>
          {submitting && <Typing label={"typing"} />}
          {response && (
            <div>
              <div className="voices2-body-answer">{response.response}</div>
            </div>
          )}
        </div>
        <div className="voices2-tweets-placeholder">
          {response && response.examplePosts.length > 0 && (
            <div className="voices2-tweets">
              <div className="voices2-tweets-label">ORIGINAL POSTS</div>
              <div className="voices-patients-example-posts">
                {response.examplePosts.map((p) => (
                  <Tweet
                    tweetId={permalinkToTweetId(p.permalink)}
                    options={{ width: 700, theme: "dark" }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </PageTemplate>
  );
}

const mapStateToProps = (state: AtlasMach.StoreState) => {
  if (!state.ui.topic)
    throw new Error("topic must be set to initialize SummaryScreen component");
  if (!state.ui.dateRange)
    throw new Error("Filters must be set to initialize this component");

  const individualBots = state.ui.topic.topicBots
    .map((tb) => tb.bot)
    .filter((b) => !b.topicAudienceId);

  return {
    topic: state.ui.topic,
    bots: individualBots,
    dateRange: state.ui.dateRange,
    brands: state.data.brands,
    authToken: state.data.auth_token,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualAccountScreen);
